import React, { memo } from 'react'
import logo from 'assets/images/bitsy-advisor-logo.png'
import styled from 'styled-components'

const PoweredByBitsy = memo(
  ({ link = 'https://bitsyadvisor.com/', ...props }) => {
    return (
      <Logo {...props}>
        <small>Powered by </small>
        <a
          href={link}
          title="Bitsy Advisor"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} className="logo" alt="Bitsy Advisor" />
        </a>
      </Logo>
    )
  }
)

export default PoweredByBitsy

const Logo = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 15px;
  right: 15px;

  small {
    line-height: 1;
    margin-top: -4px;
    margin-right: 7px;
    opacity: 0.3;
  }

  a {
    display: block;

    img {
      max-width: 60px;
      max-height: 40px;
      opacity: 0.7;
      transition: 0.3s all;
      transform: translateZ(0px);
    }

    &:hover img {
      opacity: 1;
      transition: 0.3s all;
    }
  }
`
