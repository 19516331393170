import React, { memo } from 'react'
import styled from 'styled-components'
import Loader from '../../partials/Loader'
import { useTemplates, useUser } from '../../../contexts'
import { colors, fonts } from '../../../styles'

const TemplateSelector = memo(
  ({ setTemplateID, setTemplate, hideNoneText = false }) => {
    const { userType } = useUser()
    const { templates } = useTemplates()

    if (!templates) {
      return <Loader message="Checking for existing templates..." />
    }

    return (
      <TemplateSelectorWrapper data-id="template-selector">
        {Object.keys(templates) && Object.keys(templates).length ? (
          <>
            {Object.keys(templates).map((templateID) => {
              const template = templates[templateID]
              const { title, inactive } = template

              if (inactive) {
                return ''
              }

              return (
                <div key={templateID}>
                  <TemplateSelectorButton
                    onClick={() => {
                      setTemplateID(templateID)
                      console.log('setTemplateID in selector')
                      setTemplate(template)
                      console.log('setTemplate in selector')
                    }}
                  >
                    {title}
                  </TemplateSelectorButton>
                </div>
              )
            })}
          </>
        ) : !hideNoneText ? (
          <div className="text-center">
            <p>No forms have been set up yet!</p>
            <p>
              Please{userType === 'advisor' ? ' ask your firm admin to' : ''}{' '}
              create one on the Forms tab in
              {userType === 'firm' ? ' your' : ' their'} dashboard.
            </p>
            {userType === 'firm' && (
              <small>
                <em>
                  If you have set up forms and they are not showing here, please
                  contact Bitsy.
                </em>
              </small>
            )}
          </div>
        ) : (
          ''
        )}
      </TemplateSelectorWrapper>
    )
  }
)

export default TemplateSelector

const TemplateSelectorWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`

const TemplateSelectorButton = styled.button`
  ${fonts.headingSmall}
  display: block;
  width: 100%;
  border-color: ${colors.primary};

  &:hover,
  &:focus {
    background: ${colors.primary};
    color: #fff;
  }
`
