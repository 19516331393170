import { firebaseFunctions } from '../../config/firebaseConfig'

const integrationsGetClient = firebaseFunctions.httpsCallable(
  'integrationsGetClient'
)

export async function getClientFromIntegrations(
  client,
  onboardingModuleClient
) {
  try {
    const result = await integrationsGetClient({
      client,
      onboardingModuleClient,
    })
    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
