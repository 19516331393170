import React, { useState, useEffect } from 'react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import styled from 'styled-components'

const isValidDate = (date) => {
  return date && Object.prototype.toString.call(date) === '[object Date]'
}

const getDateValue = (dateString) => {
  if (!dateString) {
    return ''
  }

  const date = new Date(dateString)
  return date
}

const getDateString = (dateValue) => {
  if (!isValidDate(dateValue)) {
    return ''
  }

  const d = dateValue
  const dateString = `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(
    -2
  )}-${('0' + d.getDate()).slice(-2)}`
  return dateString
}

const DatePicker = ({ options }) => {
  const [value, setValue] = useState(getDateValue(options.value))

  const { onChange } = options
  useEffect(() => {
    onChange(options.data.field_name, getDateString(value))
  }, [value, onChange, options.data.field_name])

  const baseClasses = 'SortableItem rfb-item'

  return (
    <StyledDatepicker
      format="MM/DD/YYYY"
      className={baseClasses}
      label={options.data.label}
      id={options.data.field_name}
      name={options.data.field_name}
      value={value}
      onChange={(e, { value }) => setValue(value)}
    />
  )
}

export default DatePicker

const StyledDatepicker = styled(SemanticDatepicker)`
  &&& {
    margin-bottom: -3rem !important;

    i.icon {
      z-index: 9;
    }
  }
`
