function validateInitialsField(field, initials) {
  var errors = []

  if (!initials[field] || !initials[field].length) {
    var desc = ''

    switch (field) {
      case 'adv_receipt':
      case 'adv_receipt_client':
      case 'adv_receipt_second':
        desc = 'confirming ADV receipt'
        break
      case 'advisor_obtained_records':
        desc = "confirming that the Advisor obtained client's financial records"
        break
      case 'advisory_fee_client':
      case 'advisory_fee_second':
        desc = 'confirming advisory fee'
        break
      case 'authorized_withdrawal_client':
      case 'authorized_withdrawal_second':
        desc = 'confirming authorized withdrawal'
        break
      case 'discretionary_client':
      case 'discretionary_second':
        desc = 'confirming Discretionary Jurisdiction'
        break
      case 'electronic_consent':
        desc = 'confirming Consent to Electronic Delivery'
        break
      case 'fee_billing_client':
      case 'fee_billing_second':
        desc = 'on the Fee Billing Addendum'
        break
      case 'fee_schedule_client':
      case 'fee_schedule_second':
        desc = 'confirming fee schedule'
        break
      case 'no_management_services_client':
      case 'no_management_services_second':
        desc =
          'confirming that Advisor will not provide services for selected accounts'
        break
      case 'nondiscretionary_client':
      case 'nondiscretionary_second':
        desc = 'confirming Non-Discretionary Jurisdiction'
        break
      case 'schedule_a_client':
      case 'schedule_a_second':
        desc = 'on Schedule A'
        break
      case 'schedule_b_client':
      case 'schedule_b_second':
        desc = 'on Schedule B'
        break
      case 'schedule_c_client':
      case 'schedule_c_second':
        desc = 'on Schedule C'
        break
      case 'schedule_d_client':
      case 'schedule_d_second':
        desc = 'on Schedule D'
        break
      case 'services_1_client':
      case 'services_1_second':
        desc = 'on Services by IA (II) part (a)'
        break
      case 'services_2_client':
      case 'services_2_second':
        desc = 'on Services by IA (II) part (b)'
        break
      case 'services_3_client':
      case 'services_3_second':
        desc = 'on Services by IA (II) part (c)'
        break
      case 'services_4_client':
      case 'services_4_second':
        desc = 'on Services by IA (II) part (d)'
        break
      default:
        break
    }

    // Formulate error message
    var signer = ''
    if (field.endsWith('_second')) {
      signer = "second investor's "
    }
    const errorMessage = `Please enter ${signer}initials ${desc}.`

    errors.push(errorMessage)
  }

  return errors
}

export function validateInitialsFields(initials, fieldsToValidate = null) {
  var errors = []

  if (!fieldsToValidate) {
    return null
  }

  fieldsToValidate.map((field) => {
    if (!field.skip) {
      var validatedFieldResult = validateInitialsField(field.name, initials)

      if (validatedFieldResult) {
        errors = errors.concat(validatedFieldResult)
      }
    }

    return field
  })

  // Filter out duplicates
  errors = Array.from(new Set(errors))

  return errors
}

function isTextSig(fieldName) {
  if (!fieldName) {
    return null
  }

  if (fieldName.endsWith('_text')) {
    return true
  }

  return false
}

export function validateSignatureFields(signatures, fieldsToValidate = null) {
  var errors = []

  if (!fieldsToValidate) {
    return null
  }

  fieldsToValidate.map((field) => {
    if (!field.skip) {
      var correspondingFieldName = null
      switch (isTextSig(field.name)) {
        case true:
          // Get corresponding draw field
          correspondingFieldName = field.name.slice(0, -5)
          break
        case false:
          // Get corresponding text field
          correspondingFieldName = field.name + '_text'
          break
        default:
          break
      }

      var correspondingFieldValue = null
      var correspondingFieldValidateObject = null
      if (correspondingFieldName) {
        correspondingFieldValue = signatures[correspondingFieldName]
        correspondingFieldValidateObject = fieldsToValidate.filter(
          (obj) => obj.name === correspondingFieldName
        )
      }
      var returnError = null

      if (!signatures[field.name] || !signatures[field.name].length) {
        returnError = true

        // Check if corresponding field is empty
        if (correspondingFieldName && !correspondingFieldValidateObject.skip) {
          if (
            correspondingFieldValue ||
            (correspondingFieldValue && correspondingFieldValue.length)
          ) {
            returnError = false
          }
        }
      }

      if (returnError) {
        // Formulate error message
        var signer = ''
        if (
          field.name.endsWith('_second') ||
          field.name.endsWith('_second_text') ||
          field.name.startsWith('second_')
        ) {
          signer = 'second investor '
        }
        const errorMessage = `Please sign all ${signer}signature areas.`

        errors = errors.concat(errorMessage)
      }
    }

    return field
  })

  // Filter out duplicates
  errors = Array.from(new Set(errors))

  return errors
}
