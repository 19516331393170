import React, { Component, Fragment } from 'react'
import { scrollTo } from '../../actions/helpers'

class NextLink extends Component {
  render() {
    const { next_link, nextLinkRef } = this.props

    return (
      <Fragment>
        {next_link && next_link.length > 1 ? (
          <div className="iac-next-link-container">
            <div className="iac-next-link">
              <button
                ref={nextLinkRef}
                onClick={(event) => scrollTo(event, next_link)}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
      </Fragment>
    )
  }
}

export default NextLink
