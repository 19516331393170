import { firebaseFunctions } from '../../../config/firebaseConfig'

const integrationsWealthboxGetContact = firebaseFunctions.httpsCallable(
  'integrationsWealthboxGetContact'
)

export async function getContact(uid, email) {
  try {
    const result = await integrationsWealthboxGetContact({ uid, email })
    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
