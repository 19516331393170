import React from 'react'
import { useLocation } from '@reach/router'
import qs from 'querystring'
import Loader from '../partials/Loader'
import CheckoutButton from '../partials/CheckoutButton'
import PoweredByBitsy from 'components/partials/PoweredByBitsy'

const CheckoutRedirect = () => {
  const location = useLocation()
  const searchParams = qs.parse(location.search.substr(1))
  const { email, promo, planTier } = searchParams

  const hasValidPromo = promo && promo !== '{field:promo}' ? true : false

  if (!email || (!hasValidPromo && !planTier)) {
    return (
      <div className="checkout-redirect">
        <p>
          It looks like some information is missing. Please click{' '}
          <a href="https://bitsyadvisor.com/go" title="Bitsy Advisor">
            here
          </a>{' '}
          to try again.
        </p>
      </div>
    )
  }

  // TODO: [BTS-513] Add behavior if there is a CheckoutButton error

  return (
    <div className="checkout-redirect">
      <Loader message="Redirecting you to our payments portal..." />
      <CheckoutButton
        activateOnLoad={true}
        message="Click here if you are not redirected"
        customerEmail={email}
        priceId={hasValidPromo ? promo : planTier}
        redirectParams={searchParams}
      />
      <PoweredByBitsy />
    </div>
  )
}

export default CheckoutRedirect
