import { firebaseFunctionsCustomDomain } from '../../../config/firebaseConfig'

const integrationsDocusignListTemplateTabs = firebaseFunctionsCustomDomain.httpsCallable(
  'integrationsDocusignListTemplateTabs'
)

export default async function listTemplates(templateID, documentID, firm) {
  try {
    const result = await integrationsDocusignListTemplateTabs({
      templateID,
      documentID,
      firm,
    })
    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
