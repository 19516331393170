import React from 'react'

const AddSuccessSlideContents = ({ meeting = false }) => (
  <div className="addSuccess">
    Thank you for filling out our questionnaire.{' '}
    {meeting ? 'Please book a meeting with us below!' : "We'll be in touch!"}
  </div>
)

export default AddSuccessSlideContents
