import React, { memo, useMemo, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Message } from 'semantic-ui-react'
import { signInUser } from 'actions/authActions'
import {
  requestNewEmailLoginLink,
  signInEmailLoginUser,
} from 'actions/auth/emailLoginActions'
import { useFirm } from 'contexts'
import styled from 'styled-components'
import { VisuallyHidden } from 'styles/_mixins'
import { validEmailRegex } from 'config/regexPatterns'

const LogInForm = memo(({ isEmailLogin = false, children, setEmail }) => {
  const { firmID } = useFirm()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm()

  const [requestNewEmailLink, setRequestNewEmailLink] = useState(false)
  const [requestNewEmailLinkSent, setRequestNewEmailLinkSent] = useState(false)
  const [loginErrors, setLoginErrors] = useState([])

  const fields = useMemo(() => {
    let fields = [
      {
        type: 'email',
        name: 'email',
        label: 'Email',
        icon: 'mail',
        required: true,
        pattern: {
          value: validEmailRegex,
          message: 'Enter a valid email address',
        },
      },
    ]

    if (!isEmailLogin) {
      fields.push({
        type: 'password',
        name: 'password',
        label: 'Password',
        icon: 'lock',
        required: true,
      })
    }

    return fields
  }, [isEmailLogin])

  useEffect(() => {
    fields.forEach((field) => {
      register(field.name, {
        required: field.required ? `${field.label} is required` : false,
        pattern: field.pattern,
      })
    })
  }, [fields, register])

  const handleChange = (e, { name, value }) => {
    if (name === 'email') {
      setEmail(value)
    }
    return setValue(name, value)
  }

  const handleRequestNewEmailLink = ({ email }) => {
    requestNewEmailLoginLink(
      email,
      setRequestNewEmailLink,
      setRequestNewEmailLinkSent,
      setLoginErrors,
      firmID
    )
  }

  const handleLogin = ({ email, password }) => {
    if (isEmailLogin) {
      signInEmailLoginUser(email, setLoginErrors, setRequestNewEmailLink)
    } else {
      signInUser(email, password, setLoginErrors)
    }
  }

  const onSubmit = (data) => {
    if (requestNewEmailLink) {
      handleRequestNewEmailLink(data)
    } else {
      handleLogin(data)
    }
  }

  if (requestNewEmailLinkSent) {
    return 'A new login link was sent to your email! Please check your inbox.'
  }

  return (
    <>
      {isEmailLogin && <p>Please confirm your email address to continue</p>}
      <StyledForm id="login" size="big" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field) => (
          <Form.Input
            key={field.name}
            type={field.type}
            name={field.name}
            id={field.name}
            label={field.label}
            placeholder={field.placeholder || field.label}
            icon={field.icon}
            iconPosition="left"
            onChange={handleChange}
            error={errors[field.name] ? errors[field.name].message : null}
          />
        ))}
        {children}
        {loginErrors && !!loginErrors.length && (
          <ErrorMessages error>
            <Message.Header>Something went wrong</Message.Header>
            <Message.List>
              {loginErrors.map((error, idx) => (
                <Message.Item key={idx}>{error}</Message.Item>
              ))}
            </Message.List>
          </ErrorMessages>
        )}
        <SubmitButton
          primary
          fluid
          size="big"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {requestNewEmailLink
            ? 'Request a link'
            : isEmailLogin
            ? 'Continue'
            : 'Log in'}
        </SubmitButton>
      </StyledForm>
    </>
  )
})

export default LogInForm

const StyledForm = styled(Form)`
  &&& {
    label {
      ${VisuallyHidden()}
    }

    .ui.prompt[role='alert'] {
      display: block;
      text-align: center;
    }
  }
`

const ErrorMessages = styled(Message)`
  &&& {
    display: block !important;
    margin-top: 2em;
    font-size: 85%;
  }
`

const SubmitButton = styled(Form.Button)`
  &&& {
    margin-top: 2em;
  }
`
