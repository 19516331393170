import React, { memo, useState } from 'react'
import { Button, Popup, Sidebar, Label, Dropdown } from 'semantic-ui-react'
import Avatar from '../Avatar'
import SearchForm from 'components/forms/SearchForm'
import NotificationsList from '../NotificationsList'
import TaskList from '../TaskList'
import LogoutButton from '../LogoutButton'
import logo from 'assets/images/bitsy-advisor-logo.png'
import { useUser, useFirm, useTasks } from 'contexts'
import styled from 'styled-components'
import { colors } from 'styles'

export default memo(function DashboardHeader(props) {
  const { userType, user } = useUser()
  const { firm } = useFirm()
  const { tasks } = useTasks()

  const {
    sidebarCollapsed,
    setSidebarCollapsed,
    setShowAddClientModule,
    searchableItems,
  } = props

  const [showSearchDropdown, setShowSearchDropdown] = useState(false)
  const [showTaskList, setShowTaskList] = useState(false)

  const incompleteTasksCount =
    tasks && Object.keys(tasks).length
      ? Object.keys(tasks).filter((taskID) => {
          const task = tasks[taskID]
          return task.status === 'incomplete'
        }).length
      : 0

  return (
    <Nav>
      <LogoLink href="/">
        <img src={logo} alt="Bitsy Advisor" />
      </LogoLink>

      <SidebarToggleButton
        icon="bars"
        size="massive"
        onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
      />

      <StyledSearchForm items={searchableItems} />

      <RightActions>
        <AddClientButton
          content="Add client"
          icon="plus circle"
          onClick={() => setShowAddClientModule(true)}
          primary
          size="large"
        />

        <SearchDropdownToggle>
          <Button
            icon="search"
            size="big"
            onClick={() => setShowSearchDropdown(!showSearchDropdown)}
          />
        </SearchDropdownToggle>
        <SearchSidebar
          animation="overlay"
          direction="top"
          icon="labeled"
          visible={showSearchDropdown}
          onHide={() => setShowSearchDropdown(false)}
        >
          <SearchForm items={searchableItems} />
        </SearchSidebar>

        <SupportSiteLink>
          <Button
            as="a"
            icon="help circle"
            size="big"
            href="https://support.bitsyadvisor.com"
            target="_blank"
            rel="noopener noreferrer"
            compact
            basic
          />
        </SupportSiteLink>

        <NotificationsList />

        {(userType === 'advisor' ||
          (userType === 'firm' && firm && firm.adminIsAdvisor)) && (
          <Popup
            trigger={
              <TaskListToggle>
                <Button
                  icon="tasks"
                  size="big"
                  onClick={() => {
                    if (showTaskList) {
                      setShowTaskList(false)
                    }
                  }}
                />
                {incompleteTasksCount > 0 && (
                  <Label circular floating color="red" size="mini">
                    {incompleteTasksCount}
                  </Label>
                )}
              </TaskListToggle>
            }
            on="click"
            open={showTaskList}
            // onClose={() => setShowTaskList(false)}
            onOpen={() => setShowTaskList(true)}
            position="bottom right"
          >
            <TaskListDrawer
              tasks={tasks}
              incompleteTasksCount={incompleteTasksCount}
              type="advisor"
              location="advisor-dashboard"
            />
          </Popup>
        )}

        <ProfileNav
          trigger={
            <ProfileNavToggle>
              <Avatar user={user} />
            </ProfileNavToggle>
          }
          options={[{ key: 'logout', text: <LogoutButton /> }]}
        />

        <MobileSidebarToggleButton
          icon="bars"
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
          size="massive"
        />
      </RightActions>
    </Nav>
  )
})

const Nav = styled.nav`
  &&& {
    background: #fff;
    border-bottom: 1px solid #e9e9e9;
    height: 54px;
    padding: 0 0.75em;
    margin: -1em;
    margin-right: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.75em;

    & > * {
      display: inline-block;
      flex-grow: 0;
      flex-shrink: 1;
    }

    @media (min-width: 768px) {
      height: 70px;
      padding: 0;
    }
  }
`

const LogoLink = styled.a`
  width: auto;
  height: auto;

  img {
    max-height: 38px;
  }

  @media (min-width: 768px) {
    display: none !important;
  }
`

const SidebarToggleButton = styled(Button)`
  &&&& {
    display: none;

    @media (min-width: 768px) {
      display: block;
      border-radius: 0;
      height: 100%;
      width: 100%;
      max-width: 64px;
      border-right: 1px solid #e9e9e9;

      &,
      &:hover,
      &:focus,
      &:active {
        background: #f9f9f9;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
`

const StyledSearchForm = styled(SearchForm)`
  display: none !important;

  @media (min-width: 768px) {
    display: block !important;
  }
`

const RightActions = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0.75em;
`

const AddClientButton = styled(Button)`
  &&& {
    display: none;

    @media (min-width: 768px) {
      display: block;
      white-space: nowrap;
    }
  }
`

const SearchDropdownToggle = styled.div`
  &&& {
    .ui.button {
      &,
      &:hover,
      &:focus,
      &:active {
        background: transparent;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    @media (min-width: 768px) {
      display: none;
    }
  }
`

const SearchSidebar = styled(Sidebar)`
  &&& {
    padding: 1em 0;
    box-shadow: none;
    background: #fff;
  }
`

const SupportSiteLink = styled.div`
  &&& {
    a {
      &,
      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
  }
`

const TaskListToggle = styled.div`
  &&& {
    position: relative;

    .ui.button {
      &,
      &:hover,
      &:focus,
      &:active {
        background: transparent;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
`

const TaskListDrawer = styled(TaskList)`
  &&& {
    & > .header,
    & > .feed .event {
      border-bottom: 1px solid #e9e9e9;
    }

    & > .feed .event:nth-last-of-type(1) {
      border-bottom: none;
    }

    & > .header,
    & > .feed .event > .content {
      padding: 15px;
    }

    & > .header {
      margin-bottom: 0;
    }

    & > .feed {
      margin-top: 0;
    }

    & > .feed .event .summary .message + button {
      margin: 8px auto 0 auto;
    }
  }
`

const ProfileNav = styled(Dropdown)`
  &&& {
    padding: 0;
    border: none;

    i.dropdown.icon {
      display: none;
    }

    .menu {
      margin-top: 0.5em;
      left: auto;
      right: 0;
      min-width: 140px;
      padding: 0.25em 0;

      .item {
        padding: 0 !important;
        background: transparent;

        button {
          width: 100%;
          text-align: left;
          border-radius: 0;
          font-weight: normal;
          padding: 0.25rem 1rem;

          &:hover {
            background: ${colors.primary};
            color: #fff;
          }
        }
      }
    }
  }
`

const ProfileNavToggle = styled.div``

const MobileSidebarToggleButton = styled(Button)`
  &&&& {
    display: block;
    border-radius: 0;
    height: 54px;
    width: 100%;
    text-align: center;
    padding: 0;

    &,
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: rgba(0, 0, 0, 0.5);
    }

    @media (min-width: 768px) {
      display: none;
    }
  }
`
