import React, { memo, useMemo, useState } from 'react'
import { Checkbox, Icon } from 'semantic-ui-react'
import ReactTooltip from 'react-tooltip'
import Table from 'components/partials/Table'
import ProfileLinkButton from '../ProfileLinkButton'
import { DeliveryMethodIcon } from 'components/icons'
import { formatFirestoreDateString } from 'actions/db/_utils'
import { formatCompositeInvestorNames, capitalize } from 'actions/helpers'
import { prettyRecipientName, sortRecipients } from 'helpers/envelopes'
import { formatArrayString } from 'helpers/_utils'
import { useEnvelopes, useUser, usePeople, useTemplates } from 'contexts'
import styled from 'styled-components'
import { colors } from 'styles'

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onClick={(e) => {
        e.stopPropagation()
      }}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {capitalize(option)}
        </option>
      ))}
    </select>
  )
}

const EnvelopesStatusTable = memo(
  ({ pendingSigners = [], allowShowCompleted = true }) => {
    const { userType } = useUser()
    const { envelopes } = useEnvelopes()
    const { clients, advisors } = usePeople()
    const { templates } = useTemplates()

    const [showCompleted, setShowCompleted] = useState(false)

    const preppedColumns = [
      {
        Header: 'Client',
        accessor: 'client_name',
        Cell: (d) => {
          const row = d.cell.row.original

          const { client: clientID, client_name } = row
          const profile =
            clients && clients[clientID] ? clients[clientID] : null

          return (
            <StyledProfileLinkButton
              content={client_name}
              profile={{
                type: 'client',
                id: clientID,
                content: profile,
              }}
            />
          )
        },
      },
    ]

    if (userType !== 'advisor') {
      preppedColumns.push({
        Header: 'Advisor',
        accessor: 'advisor_name',
        Cell: (d) => {
          const row = d.cell.row.original

          const { advisor: advisorID, advisor_name } = row
          const profile =
            advisors && advisors[advisorID] ? advisors[advisorID] : null

          return (
            <ProfileLinkButton
              content={advisor_name}
              profile={{
                type: 'advisor',
                id: advisorID,
                content: profile,
              }}
            />
          )
        },
        headerClassName: 'text-center',
        className: 'text-center',
      })
    }

    preppedColumns.push({
      Header: 'Document Type',
      accessor: 'template_name',
      headerClassName: 'text-center',
      className: 'text-center',
    })
    preppedColumns.push({
      Header: 'Date Sent',
      accessor: 'date_sent',
      headerClassName: 'text-center',
      className: 'text-center',
      sortType: useMemo(
        () => (rowA, rowB, columnId) => {
          const a = rowA.values[columnId]
          const b = rowB.values[columnId]
          return new Date(a) > new Date(b) ? 1 : -1
        },
        []
      ),
    })

    preppedColumns.push({
      Header: 'Status',
      Filter: SelectColumnFilter,
      filter: 'includes',
      Cell: (d) => {
        const row = d.cell.row.original

        let icons
        if (
          row.recipients &&
          Object.keys(row.recipients) &&
          Object.keys(row.recipients).length
        ) {
          const sortedRecipients = sortRecipients(row.recipients)

          icons = sortedRecipients.map((recipientID, idx) => {
            const recipient = row.recipients[recipientID]

            const isComplete =
              (recipient.status && recipient.status === 'Signed') ||
              row.status === 'Completed'
            let statusMessage = `${recipient.name}`
            if (isComplete) {
              statusMessage += ` signed`
              if (recipient.dateSigned) {
                statusMessage += ` on ${formatFirestoreDateString(
                  recipient.dateSigned
                )}`
              }
            } else {
              statusMessage += ` still has to sign`
            }

            const userType = recipient.signerID.split('_')[0]
            const userID = row[userType]

            let profile
            switch (userType) {
              case 'client':
                profile = clients && clients[userID] ? clients[userID] : null
                break
              case 'advisor':
                profile = advisors && advisors[userID] ? advisors[userID] : null
                break
              default:
                profile = null
                break
            }

            const recipientIcon = (
              <Icon.Group key={idx}>
                <RecipientIcon
                  name="user"
                  data-tip={statusMessage}
                  isComplete={isComplete}
                />
                {isComplete && <RecipientCompleteIcon name="check" corner />}
              </Icon.Group>
            )

            return (
              <ProfileLinkButton
                key={idx}
                content={recipientIcon}
                profile={{
                  type: userType,
                  id: userID,
                  content: profile,
                }}
              />
            )
          })
        }

        return (
          <div>
            {icons}
            <ReactTooltip place="right" type="info" effect="solid" />
          </div>
        )
      },
      accessor: 'status',
      headerClassName: 'text-center',
      className: 'text-center',
      disableSortBy: true,
    })

    preppedColumns.push({
      Header: 'Delivery Method',
      Filter: SelectColumnFilter,
      filter: 'includes',
      headerClassName: 'text-center',
      accessor: 'deliveryMethod',
      className: 'text-center',
      Cell: (d) => {
        const row = d.cell.row.original
        return <DeliveryMethodIcon deliveryMethod={row.deliveryMethod} />
      },
    })

    const columns = useMemo(
      () => preppedColumns,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const data = useMemo(
      () =>
        envelopes && Object.keys(envelopes) && Object.keys(envelopes).length
          ? Object.keys(envelopes).flatMap((envelopeID) => {
              const envelope = envelopes[envelopeID]
              const envelopeClient =
                clients && clients[envelope.client]
                  ? clients[envelope.client]
                  : null
              const envelopeAdvisor =
                advisors && advisors[envelope.advisor]
                  ? advisors[envelope.advisor]
                  : null
              const envelopeTemplate =
                templates && templates[envelope.type]
                  ? templates[envelope.type]
                  : null

              if (['Cancelled', 'Declined'].includes(envelope.status)) {
                return []
              }

              if ('Completed' === envelope.status && !showCompleted) {
                return []
              }

              // If pendingSigners is passed, only include envelopes for that pending signer
              if (pendingSigners && pendingSigners.length) {
                const sortedRecipients = sortRecipients(envelope.recipients)
                const nextRecipientID = sortedRecipients.find((recipientID) => {
                  const recipient = envelope.recipients[recipientID]
                  return recipient.status && recipient.status !== 'Signed'
                })

                // Convert to recipient type to support legacy envelopes with email IDs
                const nextRecipientType =
                  nextRecipientID &&
                  envelope.recipients[nextRecipientID].signerID
                    ? envelope.recipients[nextRecipientID].signerID
                    : nextRecipientID

                if (
                  nextRecipientType &&
                  !pendingSigners.includes(nextRecipientType)
                ) {
                  return []
                }
              }

              const firstInvestor = Object.values(envelope.recipients).find(
                (recipient) =>
                  recipient.signerID && recipient.signerID === 'client_1'
              )
              const secondInvestor = Object.values(envelope.recipients).find(
                (recipient) =>
                  recipient.signerID && recipient.signerID === 'client_2'
              )

              let clientNameFromRecipients = ''
              if (firstInvestor && firstInvestor.name) {
                clientNameFromRecipients += firstInvestor.name

                if (secondInvestor && secondInvestor.name) {
                  clientNameFromRecipients += ` & ${secondInvestor.name}`
                }
              }

              return {
                ...envelope,
                id: envelopeID,
                client_name: envelopeClient
                  ? formatCompositeInvestorNames({ client: envelopeClient })
                  : clientNameFromRecipients
                  ? clientNameFromRecipients
                  : envelope.client,
                advisor_name: envelopeAdvisor
                  ? envelopeAdvisor.name
                  : envelope.advisor,
                template_name: envelopeTemplate
                  ? envelopeTemplate.title
                  : envelope.type.toUpperCase(),
                date_sent: formatFirestoreDateString(envelope.dateSent),
              }
            })
          : [],
      [envelopes, clients, advisors, templates, showCompleted, pendingSigners]
    )

    const toggleShowCompleted = () => {
      setShowCompleted(!showCompleted)
    }

    const envelopesFound =
      envelopes && Object.keys(envelopes) && Object.keys(envelopes).length
    const prettyPendingSigners = pendingSigners.map((signerID) =>
      prettyRecipientName(signerID)
    )

    return (
      <>
        <TableActionsRow>
          {allowShowCompleted && (
            <ShowCompletedToggle
              label="Show completed"
              checked={showCompleted}
              onClick={toggleShowCompleted}
            />
          )}
        </TableActionsRow>
        <Table
          columns={columns}
          data={data}
          noRowsFoundText={
            envelopesFound && pendingSigners && pendingSigners.length
              ? `No documents are pending ${formatArrayString(
                  prettyPendingSigners,
                  'or'
                )} signature!`
              : envelopesFound
              ? 'All of your documents are completed!'
              : "You haven't sent any documents yet..."
          }
          noRowsFoundAction={
            !showCompleted && allowShowCompleted ? (
              <button className="link" onClick={toggleShowCompleted}>
                Show completed
              </button>
            ) : null
          }
        />
      </>
    )
  }
)

export default EnvelopesStatusTable

const TableActionsRow = styled.div`
  text-align: right;
`

const ShowCompletedToggle = styled(Checkbox)`
  &&& {
    margin-bottom: 3px;
    margin-left: auto;
    display: inline-block;
  }
`

const StyledProfileLinkButton = styled(ProfileLinkButton)`
  &&& {
    font-weight: bold;
    text-align: left;
  }
`

const RecipientIcon = styled(({ isComplete, ...props }) => <Icon {...props} />)`
  color: ${(props) => (props.isComplete ? colors.primary : colors.gray)};
`

const RecipientCompleteIcon = styled(Icon)`
  color: ${colors.primary};
`
