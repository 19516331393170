import React, { memo } from 'react'
import dayjs from 'dayjs'
import { useUser, useFirm, useTasks } from 'contexts'
import { useItemList } from 'hooks'
import { Card } from 'semantic-ui-react'
import styled from 'styled-components'
import { getEnv } from 'helpers/_utils'
import Task from 'components/dashboards/partials/Task'
import {
  EnvelopeCardGroup,
  EnvelopeCard,
  EnvelopeDeliveryMethod,
} from 'components/styled/EnvelopeCard'
import FormReviewersList from '../FormReviewersList'

const InProgressFormsModule = memo(
  ({ templateID = null, template = null, clientID = null }) => {
    const { userType, uid } = useUser()
    const { firmID } = useFirm()
    const { tasks } = useTasks()
    const formReviewTaskIDs =
      tasks &&
      Object.keys(tasks).filter(
        (taskID) => tasks[taskID].type === 'form-review'
      )

    const forms = useItemList({
      itemType: 'form',
      userType: 'firm-admin' === userType ? 'firmID' : `${userType}ID`,
      uid: 'firm-admin' === userType ? firmID : uid,
      where1: 'status',
      where2: 'Sent',
      where3: templateID ? 'templateID' : null,
      where4: templateID || null,
      orderByKey: 'dateSent',
      orderByDirection: 'desc',
      where5: clientID ? 'clientID' : null,
      where6: clientID ? clientID : null,
      where7: 'env',
      where8: getEnv(true),
    })

    return (
      <Container>
        {forms && Object.keys(forms) && !!Object.keys(forms).length && (
          <EnvelopeCardGroup>
            {Object.keys(forms).map((formID) => {
              const form = forms[formID]
              const { dateSent: rawDateSent } = form

              const dateSent = dayjs(rawDateSent.toDate())
              const dateFormatTitle = 'M/D/YYYY'
              const dateFormatFull = 'MM/DD/2020 [at] HH:mm:ss A'

              const matchingActiveTaskIDs =
                formReviewTaskIDs && !!formReviewTaskIDs.length
                  ? formReviewTaskIDs.filter((taskID) => {
                      const task = tasks[taskID]
                      return (
                        task.status !== 'complete' &&
                        task.options &&
                        task.options.templateID &&
                        task.options.templateID === templateID &&
                        task.options.formID &&
                        task.options.formID === formID
                      )
                    })
                  : []

              return (
                <EnvelopeCard key={formID}>
                  <Card.Content>
                    <EnvelopeDeliveryMethod deliveryMethod="form" />
                    <Card.Header>
                      <StyledButton className="link doc-title" disabled>
                        {template && template.title
                          ? `${template.title} `
                          : 'form'}{' '}
                        - {dateSent.format(dateFormatTitle)}
                      </StyledButton>
                    </Card.Header>

                    <Card.Meta>
                      {`Sent ${dateSent.format(dateFormatFull)}`}
                    </Card.Meta>

                    <Card.Description>
                      <h5>Review Status:</h5>
                      <FormReviewersList
                        reviewers={form.reviewers}
                        form={form}
                        formID={formID}
                      />
                    </Card.Description>
                  </Card.Content>

                  {matchingActiveTaskIDs && !!matchingActiveTaskIDs.length && (
                    <Card.Content extra>
                      {matchingActiveTaskIDs.map((taskID) => {
                        const task = tasks[taskID]
                        return (
                          <TaskContainer key={`form-task-${taskID}`}>
                            <Task
                              key={`form-task-${taskID}`}
                              task={task}
                              id={taskID}
                              showMessage={false}
                            />
                          </TaskContainer>
                        )
                      })}
                    </Card.Content>
                  )}
                </EnvelopeCard>
              )
            })}
          </EnvelopeCardGroup>
        )}
      </Container>
    )
  }
)

export default InProgressFormsModule

const Container = styled.div`
  margin-top: 15px;
`

const StyledButton = styled.button`
  &&& {
    color: #000;
    opacity: 1;
  }
`

const TaskContainer = styled.div`
  text-align: center;
`
