import styled from 'styled-components'
import { colors } from 'styles'
import { Card } from 'semantic-ui-react'
import { DeliveryMethodIcon } from 'components/icons'

export const EnvelopeCardGroup = styled(Card.Group)`
  &&& {
    width: auto;

    & > .card {
      width: 100%;
    }
  }
`

export const EnvelopeCard = styled(Card)`
  &&& {
    .header {
      margin-right: calc(16px + 1.25em);
    }

    .header button {
      text-align: left;
      font-size: 80%;
    }

    .meta {
      font-size: 90%;
    }

    .description {
      border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
      padding-top: 1em;
    }

    h5 {
      color: ${colors.grayHeadline};
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      margin-bottom: 7px;
    }
  }
`

export const EnvelopeDeliveryMethod = styled(DeliveryMethodIcon)`
  float: right;
  margin-left: 1em;

  i {
    opacity: 0.3;
  }
`
