import React, { memo } from 'react'

export default memo(function LoaderIcon({ color = '#0dc4b6' }) {
  return (
    <svg
      version="1.1"
      id="L3"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 0 0"
      xmlSpace="preserve"
    >
      <circle
        fill="none"
        stroke={color}
        strokeWidth="4"
        cx="50"
        cy="50"
        r="44"
        style={{ opacity: 0.3 }}
      />
      <circle fill={color} stroke={color} strokeWidth="3" cx="8" cy="54" r="6">
        <animateTransform
          attributeName="transform"
          dur="2s"
          type="rotate"
          from="0 50 48"
          to="360 50 52"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  )
})
