import React, { Component } from 'react'
import {
  saveAPIKey,
  removeAPIKey,
} from '../../../actions/integrations/wealthbox/apiKey'
import { firebaseDB } from '../../../config/firebaseConfig'
import Field from '../Field'

class WealthboxIntegrationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      error: null,
      apiKey: props.integration ? props.integration.apiKey : '',
    }

    this.onApiKeyChange = this.onApiKeyChange.bind(this)
    this.handlePrioritizeOverSalesforceChange = this.handlePrioritizeOverSalesforceChange.bind(
      this
    )
    this.save = this.save.bind(this)
    this.remove = this.remove.bind(this)
  }

  save() {
    this.setState(
      {
        loading: true,
        error: false,
      },
      async () => {
        try {
          const isGood = await saveAPIKey(this.props.uid, this.state.apiKey)

          this.setState({
            loading: false,
            error: isGood ? null : 'invalid',
          })
        } catch (err) {
          this.setState({
            loading: false,
            error: 'unexpected',
          })
        }
      }
    )
  }

  remove() {
    this.setState(
      {
        loading: true,
      },
      async () => {
        let error = null
        try {
          await removeAPIKey(this.props.uid)
        } catch (err) {
          error = 'unexpected'
        }

        this.setState({
          loading: false,
          error,
        })
      }
    )
  }

  onApiKeyChange(e) {
    this.setState({
      apiKey: e.target.value,
    })
  }

  handlePrioritizeOverSalesforceChange(e) {
    firebaseDB
      .collection('integrations')
      .doc(this.props.uid)
      .set(
        { wealthbox: { prioritizeOverSalesforce: e.target.checked } },
        { merge: true }
      )
  }

  render() {
    return (
      <div className="integration-details integration-details-salesforce">
        {this.props.integration && (
          <p>You are integrated with Wealthbox! We'll take it from here.</p>
        )}
        <div className="form-group submit-section">
          {this.props.integration ? (
            <>
              {this.props.salesforceIntegration && (
                <Field
                  label="Prioritize Wealthbox over salesforce"
                  type="switch"
                  value={this.props.integration.prioritizeOverSalesforce}
                  handleInputChange={this.handlePrioritizeOverSalesforceChange}
                />
              )}
              <button
                className="continue"
                type="button"
                onClick={this.remove}
                disabled={this.state.loading}
              >
                {this.state.loading ? 'Loading...' : 'Remove integration'}
              </button>
            </>
          ) : (
            <>
              <p>
                Enter your Wealthbox API token into the field below. To generate
                an API token, visit your{' '}
                <a
                  href="https://app.crmworkspace.com/settings/access_tokens"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Access Tokens Settings
                </a>{' '}
                page, click "Create Access Token", set a label for the token,
                click "Save", then copy the resulting token.
              </p>
              <Field
                label="API Key"
                type="text"
                value={this.state.apiKey}
                handleInputChange={this.onApiKeyChange}
              />
              <button className="continue" type="button" onClick={this.save}>
                {this.state.loading ? 'Loading...' : 'Save'}
              </button>
            </>
          )}
          {!this.props.integration && (
            <ul className="errors bitsy-errors">
              {this.state.error === 'invalid' && (
                <li>Please make sure the provided API Key is valid</li>
              )}
              {this.state.error === 'unexpected' && (
                <li>
                  Something went wrong, please contact Bitsy if the error
                  persists.
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    )
  }
}

export default WealthboxIntegrationForm
