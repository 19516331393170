import React, { Component } from 'react'

export default class SuitabilityColumn extends Component {
  render() {
    const { title, instructional, children } = this.props

    return (
      <div className="column">
        <div className="row header-row">
          <h2>{title}</h2>
          {instructional ? (
            <span className="instructional">{instructional}</span>
          ) : (
            ''
          )}
        </div>
        <div className="row">{children}</div>
      </div>
    )
  }
}
