import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Card, Modal } from 'semantic-ui-react'
import { useEnvelopes } from 'contexts'
import BitsyCard from 'components/dashboards/partials/LegacyCard'
import {
  EnvelopeDownloadList,
  EnvelopeRecipientsList,
  InProgressFormsModule,
  ManualFinalizeOptions,
} from './partials'
import styled from 'styled-components'
import { Col } from 'components/styled'
import {
  EnvelopeCardGroup,
  EnvelopeCard,
  EnvelopeDeliveryMethod,
} from 'components/styled/EnvelopeCard'

const ExecutedContractsModule = ({
  children,
  type,
  title,
  documents,
  document,
  clientID,
  hasPendingSuitabilityForm = null,
  hideIfEmpty = false,
  wrapperClassName = '',
  templateID = null,
  template = null,
}) => {
  const { envelopes: allEnvelopes } = useEnvelopes()

  const [
    showEnvelopeDownloadModalID,
    setShowEnvelopeDownloadModalID,
  ] = useState(null)

  const templateType =
    template && template.legacyDocType ? template.legacyDocType : type
  const matchingEnvelopeIDs =
    allEnvelopes &&
    Object.keys(allEnvelopes) &&
    Object.keys(allEnvelopes).length
      ? Object.keys(allEnvelopes).filter((envelopeID) => {
          const envelope = allEnvelopes[envelopeID]
          return (
            envelope &&
            envelope.client === clientID &&
            envelope.type === templateType
          )
        })
      : []

  let envelopes = {}
  matchingEnvelopeIDs.forEach((envelopeID) => {
    const envelope = allEnvelopes[envelopeID]
    envelopes[envelopeID] = envelope
  })

  // Check if there are envelopes or not
  const noneYet =
    !document &&
    (!documents || !documents.length) &&
    (!envelopes || !Object.keys(envelopes).length)

  if (noneYet && hideIfEmpty) {
    return ''
  }

  // Construct title
  let titleText = ''
  if (title) {
    titleText = title
  } else if (type === 'iac') {
    titleText = 'Executed Contract'
    if (documents && documents.length > 1) {
      titleText += 's'
    }
    titleText += ', Disclosures Delivery'
  }

  return (
    <Wrapper className={wrapperClassName}>
      <BitsyCard
        title={titleText}
        noneYet={noneYet && 'No documents yet!'}
        className="executed-contracts-module"
      >
        {hasPendingSuitabilityForm && (
          <LegacyPendingSuitabilityFormText className="text-center">
            <strong>{hasPendingSuitabilityForm}</strong>
          </LegacyPendingSuitabilityFormText>
        )}

        {template && 'round-trip' === template.routing && (
          <InProgressFormsModule
            templateID={templateID}
            template={template}
            clientID={clientID}
          />
        )}

        {envelopes && !!Object.keys(envelopes).length && (
          <EnvelopeCardGroup>
            {Object.keys(envelopes).map((envelopeID) => {
              const envelope = envelopes[envelopeID]
              const {
                recipients,
                dateSent: rawDateSent,
                dateCompleted: rawDateCompleted,
                dateCancelled: rawDateCancelled,
                dateDeclined: rawDateDeclined,
                deliveryMethod,
              } = envelope

              const dateSent = dayjs(rawDateSent.toDate())
              const dateCompleted = rawDateCompleted
                ? dayjs(rawDateCompleted.toDate())
                : null
              const dateCancelled = rawDateCancelled
                ? dayjs(rawDateCancelled.toDate())
                : null
              const dateDeclined = rawDateDeclined
                ? dayjs(rawDateDeclined.toDate())
                : null

              const dateFormatTitle = 'M/D/YYYY'
              const dateFormatFull = 'MM/DD/YYYY [at] HH:mm:ss A'

              let docTypeName = envelope.type
              switch (envelope.type) {
                case 'iac':
                  docTypeName = 'Investment Advisory Contract'
                  break
                case 'k401':
                  docTypeName = '401(k) Agreement'
                  break
                case 'suitability':
                  docTypeName = 'Suitability Form'
                  break
                default:
                  break
              }
              if (template && template.title) {
                docTypeName = template.title
              }

              const sortOrder = ['client_1', 'client_2', 'advisor', 'firm']

              const sortedRecipients = Object.keys(recipients).sort((a, b) => {
                return (
                  sortOrder.indexOf(recipients[a].signerID) -
                  sortOrder.indexOf(recipients[b].signerID)
                )
              })

              const showRecipients = !['Cancelled', 'Declined'].includes(
                envelope.status
              )

              return (
                <EnvelopeCard key={envelopeID}>
                  <Card.Content>
                    <EnvelopeDeliveryMethod deliveryMethod={deliveryMethod} />
                    <Card.Header>
                      <button
                        onClick={() =>
                          setShowEnvelopeDownloadModalID(envelopeID)
                        }
                        className="link doc-title"
                      >
                        {docTypeName} - {dateSent.format(dateFormatTitle)}
                      </button>
                    </Card.Header>

                    <Card.Meta>
                      {`Sent ${dateSent.format(dateFormatFull)}`}
                    </Card.Meta>
                    {'Completed' === envelope.status && (
                      <Card.Meta>
                        {`Completed${
                          dateCompleted
                            ? ` ${dateCompleted.format(dateFormatFull)}`
                            : ''
                        }`}
                      </Card.Meta>
                    )}
                    {'Cancelled' === envelope.status && (
                      <Card.Meta>
                        {`Cancelled ${
                          dateCancelled && dateCancelled.format(dateFormatFull)
                        }`}
                      </Card.Meta>
                    )}
                    {'Declined' === envelope.status && (
                      <Card.Meta>
                        {`Declined ${
                          dateDeclined
                            ? dateDeclined.format(dateFormatFull)
                            : ''
                        }`}
                      </Card.Meta>
                    )}

                    {showRecipients && (
                      <Card.Description>
                        <h5>Signing Status:</h5>
                        <EnvelopeRecipientsList
                          sortedRecipientIDs={sortedRecipients}
                          recipients={recipients}
                          envelope={envelope}
                          envelopeID={envelopeID}
                        />
                      </Card.Description>
                    )}
                  </Card.Content>

                  {'Sent' === envelope.status && (
                    <Card.Content extra>
                      <ManualFinalizeOptions
                        envelopeID={envelopeID}
                        envelope={envelope}
                      />
                    </Card.Content>
                  )}
                </EnvelopeCard>
              )
            })}
          </EnvelopeCardGroup>
        )}

        {documents ? (
          <ul className="iacs">
            {documents
              .slice(0)
              .reverse()
              .map(function (iac, key) {
                const iacDate = iac.date.toDate()

                return (
                  <li key={'iac-' + key}>
                    <a
                      href={iac.link}
                      title={iac.name}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {iac.name}
                    </a>
                    <br />
                    <em>Sent {iacDate.toLocaleString()}</em>
                  </li>
                )
              })}
          </ul>
        ) : documents ? (
          <div className="subhead">
            <a href={documents} target="_blank" rel="noopener noreferrer">
              View contract
            </a>
          </div>
        ) : (
          ''
        )}

        {document && (
          <div className="subhead">
            <a href={document} target="_blank" rel="noopener noreferrer">
              View uploaded contract
            </a>
          </div>
        )}

        <Modal
          open={Boolean(showEnvelopeDownloadModalID)}
          onClose={() => setShowEnvelopeDownloadModalID(null)}
        >
          <Modal.Content>
            <EnvelopeDownloadList
              envelopeID={showEnvelopeDownloadModalID}
              envelope={envelopes[showEnvelopeDownloadModalID]}
              template={template}
            />
          </Modal.Content>
        </Modal>

        {children}
      </BitsyCard>
    </Wrapper>
  )
}

export default ExecutedContractsModule

const Wrapper = styled(Col)`
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`

const LegacyPendingSuitabilityFormText = styled.p`
  &&& {
    margin: 1em 0em;
    margin-bottom: calc(30px + 1em);
    font-size: 16px;
    line-height: 1.14285714em;
  }
`
