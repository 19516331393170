import React, { memo, useState } from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import BitsyCard from 'components/dashboards/partials/LegacyCard'
import Modal from 'components/partials/LegacyModal'
import ErrorsList from 'components/partials/ErrorsList'
import EnvelopesExportTable from 'components/dashboards/partials/EnvelopesExportTable'
import exportEnvelopes from 'actions/export/envelopes'
import { useFirm, useAdvisor } from 'contexts'
import styled from 'styled-components'
import { Row, ColMd12 } from 'components/styled'

const ExportTab = memo(() => {
  const { firmID } = useFirm()
  const { advisorID } = useAdvisor()

  const [exportType, setExportType] = useState(null)
  const [exporting, setExporting] = useState(false)
  const [exportSuccess, setExportSuccess] = useState(null)
  const [errors, setErrors] = useState([])

  return (
    <>
      <BitsyCard title="Export" type="settings" className="col-md-8 mx-auto">
        <Row>
          <ColMd12 className="text-center">
            <CardGroup>
              <Card>
                <Card.Content>
                  <div className="float-right">
                    <Icon size="large" name="file alternate" />
                  </div>
                  <CardHeader>Contracts</CardHeader>
                  <Card.Description>
                    Export executed contracts in a ZIP file or a CSV Summary.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Button.Group>
                    <Button
                      basic
                      color="violet"
                      icon="zip"
                      content="ZIP"
                      title="Export Envelopes as ZIP"
                      disabled={exporting}
                      loading={exporting && exportType === 'envelopes-zip'}
                      onClick={() => {
                        setExportType('envelopes-zip')
                      }}
                    />
                    <CSVButton
                      basic
                      color="blue"
                      icon="table"
                      content="CSV"
                      title="Export Envelopes as CSV"
                      disabled={exporting}
                      loading={exporting && exportType === 'envelopes-csv'}
                      // TODO: Improve export envelopes handling when firm.adminIsAdvisor
                      onClick={() => {
                        setExportType('envelopes-csv')
                        exportEnvelopes({
                          firmID,
                          advisorID,
                          setExporting,
                          setExportSuccess,
                          setErrors,
                        })
                        setExportType(null)
                      }}
                    />
                  </Button.Group>
                </Card.Content>
              </Card>
            </CardGroup>
          </ColMd12>
        </Row>
        <Row className="text-center">
          <ColMd12>
            {exportSuccess && (
              <p className="text-center text-success mt-4">
                Export successful! Please check your Downloads folder.
              </p>
            )}
            <ErrorsList errors={errors} />
          </ColMd12>
        </Row>
      </BitsyCard>

      <Modal
        title="Export ZIP"
        isOpen={exportType === 'envelopes-zip'}
        fullScreen={true}
        handleClose={() => setExportType(null)}
      >
        <EnvelopesExportTable />
      </Modal>
    </>
  )
})

export default ExportTab

const CardGroup = styled(Card.Group)`
  &&& {
    justify-content: center;
  }
`

const CardHeader = styled(Card.Header)`
  &&& {
    margin-top: 2px !important;
  }
`

const CSVButton = styled(Button)`
  &&& {
    & > .icon {
      color: #2185d0;
    }
  }
`
