import React, { PureComponent } from 'react'
import Modal from 'components/partials/LegacyModal'
import ClientForm from 'components/forms/LegacyClientForm'
import DocumentReviewModal from 'components/partials/LegacyDocumentReviewModal'

export default class LegacyTask extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }

    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal(event) {
    event.preventDefault()

    this.setState({
      showModal: !this.state.showModal,
    })
  }

  render() {
    const { children, task, taskDetails, id } = this.props
    const { showModal } = this.state

    // Construct sender name
    var senderName = null
    if (task.options && task.options.sender && task.options.sender.name) {
      senderName = task.options.sender.name
    }

    // Construct task name
    var message = ''
    if (senderName) {
      message += senderName + ' has sent documents '
    } else {
      message += 'You have new documents '
    }
    message += 'for your signature'

    // Construct action
    var action = this.toggleModal
    if (this.props.action) {
      action = this.props.action
    } else if (task && task.action) {
      action = task.action
    }

    // Construct action text
    var actionText = 'Review/Sign'

    // Set up modal content
    var modalContent = children
    if (task.type) {
      if (task.type === 'suitability') {
        modalContent = (
          <ClientForm
            type="suitability"
            location={taskDetails.location}
            firm={taskDetails.firm}
            firmID={taskDetails.client.firm}
            client={taskDetails.client}
            clientID={taskDetails.uid}
            advisor={taskDetails.advisor}
            advisorID={taskDetails.client.advisor}
            reviewType="client-sign"
            taskDetails={{ id: id }}
          />
        )
      } else if (task.type === 'suitability-review') {
        modalContent = (
          <DocumentReviewModal
            className="task-document-review"
            documentType="suitability"
            documentTypeName="Suitability Form"
            isOpen={showModal}
            handleModalClose={action}
            source="advisor-review"
            location="advisor-dashboard"
            type="advisor-sign"
            doc={task.options.suitability}
            firmID={task.options.client.firm}
            advisor={taskDetails.advisor}
            client={task.options.client}
            uid={task.options.clientID}
            taskDetails={{ uid: task.options.client.advisor, id: id }}
            state={this}
          />
        )
      }
    }

    return (
      <li className={task.status !== 'incomplete' ? 'task completed' : 'task'}>
        <div className="message">{message}</div>
        <button className="btn-sm sm-button-accent" onClick={action}>
          {actionText}
        </button>
        {task.type === 'iac' ? (
          children
        ) : (
          <Modal
            isOpen={showModal}
            handleClose={action}
            className={
              task.type === 'suitability-review' ? 'task-document-review' : ''
            }
          >
            {modalContent}
          </Modal>
        )}
      </li>
    )
  }
}
