import { useAdvisor, usePeople, useUser } from 'contexts'
import React, { memo } from 'react'
import { Dropdown, Form } from 'semantic-ui-react'
import { firmIDs } from 'config/firmConfig'

const AdvisorSelect = memo(
  ({ selectedAdvisorID, handleSelect, client, className = '' }) => {
    const { userType, uid, user } = useUser()
    const { advisor, advisorID } = useAdvisor()
    const { advisors: advisorsContext } = usePeople()

    let advisors = {}
    if (userType === 'advisor' && !advisorsContext) {
      advisors = {
        [uid]: {
          name: user.name,
          email: user.email,
        },
      }
    } else if (
      userType === 'client' &&
      (!advisorsContext || !Object.keys(advisors).length) &&
      advisorID &&
      advisor
    ) {
      advisors = {
        [advisorID]: advisor,
      }
    } else if (advisorsContext) {
      advisors = advisorsContext
    }

    const advisorOptions = Object.keys(advisors).map((advisorID) => {
      const advisor = advisors[advisorID]

      // GWN: append RRN for selected advisor
      let text = advisor.name || advisor.email
      if (
        client &&
        client.firm &&
        client.firm === firmIDs['gwn'] &&
        selectedAdvisorID &&
        advisorID === selectedAdvisorID &&
        client.advisor_gwn_rrn
      ) {
        text += ` (#${client.advisor_gwn_rrn})`
      }

      return {
        key: advisorID,
        value: advisorID,
        text,
      }
    })

    return (
      <Form.Field className={className}>
        <label>Who are you working with?</label>
        <Dropdown
          placeholder="e.g. Kevin Mitchell"
          fluid
          search
          clearable
          wrapSelection
          options={advisorOptions}
          value={selectedAdvisorID}
          onChange={handleSelect}
          noResultsMessage="No advisors found."
          data-id="advisor-select"
        />
      </Form.Field>
    )
  }
)

export default AdvisorSelect
