import { firebaseFunctionsCustomDomain } from '../../../config/firebaseConfig'

const integrationsDocusignDownloadDocument = firebaseFunctionsCustomDomain.httpsCallable(
  'integrationsDocusignDownloadDocument'
)

function base64ToArrayBuffer(data) {
  var binaryString = window.atob(data)
  var binaryLen = binaryString.length
  var bytes = new Uint8Array(binaryLen)
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}

const downloadFileFromBase64String = (base64String, mimetype, fileName) => {
  const base64Data = btoa(base64String)
  const arrBuffer = base64ToArrayBuffer(base64Data)

  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([arrBuffer], { type: mimetype })

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)

  const fileExt = 'pdf'

  var link = document.createElement('a')
  document.body.appendChild(link) //required in FF, optional for Chrome
  link.href = data
  link.download = `${fileName}.${fileExt}`
  link.click()
  window.URL.revokeObjectURL(data)
  link.remove()

  return 'Success'
}

export default async function downloadDocument(
  envelopeID,
  documentID,
  documentName = 'Test',
  firm,
  setDownloadComplete
) {
  try {
    const result = await integrationsDocusignDownloadDocument({
      envelopeId: envelopeID,
      documentId: documentID,
      firm,
    })

    if (result.data && result.data.mimetype && result.data.fileBytes) {
      const { mimetype, fileBytes } = result.data

      const downloadedFile = await downloadFileFromBase64String(
        fileBytes,
        mimetype,
        documentName
      )

      if (downloadedFile) {
        setDownloadComplete(documentID)
      }
    }

    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
