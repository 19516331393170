import React, { Component, Fragment } from 'react'
// import { PDFExport } from '@progress/kendo-react-pdf'
import FirmLogo from '../../../partials/FirmLogo'
// import FeeSchedule from '../../../partials/FeeSchedule'
// import NextLink from '../../../partials/NextLink'
// import '../../../../styles/iac.scss'
import DocumentSubmit from '../../partials/DocumentSubmit'
import {
  calculateClientAge,
  generateAssetsHeldAwayItems,
  generateInvestmentExperienceItems,
} from '../../../../actions/iacHelpers'
import SuitabilityColumn from './partials/SuitabilityColumn'
// import SignatureRow from '../../partials/SignatureRow'

class BitsySuitabilityForm extends Component {
  render() {
    // const { type, firm, advisor, client, client_name, errors, handleSubmit, docRef, doc, handleInputChange, handleSignatureConfirm, handleSignatureRetry } = this.props
    const { firm, advisor, client, errors, handleSubmit } = this.props

    // this.next_link = ''
    // if (type === 'send') {
    //   this.next_link = 'iac-submit'
    // } else if (client.jurisdiction === 'Discretionary' && iac.initials.discretionary.length < 1) {
    //   this.next_link = 'discretionary'
    // } else if (client.jurisdiction === 'Non-Discretionary' && iac.initials.nondiscretionary.length < 1) {
    //   this.next_link = 'nondiscretionary'
    // } else if (iac.initials.adv_receipt.length < 1) {
    //   this.next_link = 'adv_receipt'
    // } else if (iac.initials.electronic_consent.length < 1) {
    //   this.next_link = 'electronic_consent'
    // } else if (!iac.signatures.clientSig1) {
    //   this.next_link = 'client_sig_1'
    // } else if (iac.initials.advisor_obtained_records.length < 1) {
    //   this.next_link = 'advisor_obtained_records'
    // } else if (!iac.signatures.clientSig2) {
    //   this.next_link = 'client_sig_2'
    // } else {
    //   this.next_link = 'iac-submit'
    // }

    if (firm && advisor && client) {
      return (
        <div className="document-container suitability-form-container">
          {/* 
          <NextLink next_link={this.next_link} nextLinkRef={nextLinkRef} /> */}

          {/* <PDFExport paperSize={'Letter'}
            fileName={firm.name + ' IAC - ' + client_name + '.pdf'}
            title={firm.name + ' IAC - ' + client_name}
            author={advisor.name}
            creator={firm.name}
            subject=""
            keywords=""
            margin="2cm"
            scale={0.6}
            ref={docRef}> */}

          <div id="suitability-form" className="document suitability-form">
            <div className="page">
              <div className="masthead">
                <FirmLogo firm={firm} />
                <div className="advisor-info">
                  <span className="label">Advisor: </span>
                  <span className="value">{advisor.name}</span>
                </div>
              </div>

              <h1>Client Information Questionnaire Update</h1>

              <div className="table client-info">
                <div className="column investor-info">
                  <div className="row">
                    <span className="label">Name: </span>
                    <span className="value">{client.legal_name}</span>
                  </div>
                  <div className="row">
                    <span className="label">Age: </span>
                    <span className="value">
                      {calculateClientAge(client.birthdate)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="label">Occupation: </span>
                    <span className="value">
                      {client.occupation ? client.occupation : ''}
                    </span>
                  </div>
                </div>
                <div className="column investor-info">
                  <div className="row">
                    <span className="label">Name:</span>
                    <span className="value">
                      {client.second_investor_legal_name}
                    </span>
                  </div>
                  <div className="row">
                    <span className="label">Age:</span>
                    <span className="value">
                      {client.second_investor_birthdate
                        ? calculateClientAge(client.second_investor_birthdate)
                        : ''}
                    </span>
                  </div>
                  <div className="row">
                    <span className="label">Occupation:</span>
                    <span className="value">
                      {client.second_investor_occupation
                        ? client.second_investor_occupation
                        : ''}
                    </span>
                  </div>
                </div>
              </div>

              <div className="table financials-info">
                <SuitabilityColumn title="Income (All Sources)">
                  {client.income_range}
                </SuitabilityColumn>

                <SuitabilityColumn title="Net Worth (ex Residence)">
                  {client.net_worth}
                </SuitabilityColumn>

                <SuitabilityColumn title="Liquid Net Worth">
                  {client.net_worth_liquid}
                </SuitabilityColumn>
              </div>

              <div className="table tax-assets">
                <SuitabilityColumn title="Tax Bracket">
                  {client.tax_bracket}
                </SuitabilityColumn>

                <SuitabilityColumn title="Assets Held Away ($)">
                  <ul className="informationTable">
                    {generateAssetsHeldAwayItems(client.assets_held_away)}
                  </ul>
                </SuitabilityColumn>
              </div>

              <div className="table expenses">
                <SuitabilityColumn title="Expenses">
                  {client.expenses}
                </SuitabilityColumn>

                <SuitabilityColumn title="Special Expenses">
                  {client.expenses_special}
                </SuitabilityColumn>

                <SuitabilityColumn title="Special Expenses Timeframe">
                  {client.expenses_special_timeframe}
                </SuitabilityColumn>
              </div>

              {/* <div className="address">
                  <span>{client.address_street}</span>
                  <span>{client.address_city}, {client.address_state} {client.address_zip}</span>
                </div> */}
            </div>

            <div className="page-break"></div>
            <div className="page">
              <div className="table investment-experience">
                <SuitabilityColumn title="Investment Experience (# Years)">
                  <ul className="informationTable">
                    {generateInvestmentExperienceItems(
                      client.investment_experience_items
                    )}
                  </ul>
                </SuitabilityColumn>
              </div>

              <div className="table investment-objectives">
                <SuitabilityColumn title="Investment Objectives">
                  {client.investment_objectives}
                  {client.investment_objectives === 'Other' &&
                  client.investment_objectives_other ? (
                    <Fragment>
                      <span>:</span>
                      <div className="description">
                        {client.investment_objectives_other}
                      </div>
                    </Fragment>
                  ) : (
                    ''
                  )}
                </SuitabilityColumn>
              </div>

              <div className="table time-horizon-risk">
                <SuitabilityColumn title="Time Horizon to Achieve Investment Objectives">
                  {client.investment_objectives_time_horizon}
                </SuitabilityColumn>

                <SuitabilityColumn title="Risk Score">
                  {client.risk_score}
                </SuitabilityColumn>
              </div>
            </div>

            {/* <div className="page-break"></div>
              <div className="page">
                <div className="table signatures">
                  <h2>Signature Page</h2>
                  <p>Authorized Signature</p>
                  <p>My signature below certifies that the information provided in this document is complete and accurate.</p>

                  <ul className="parties">
                    <li>
                      <SignatureRow
                        id='client_sig'
                        signerType="Client"
                        description="Authorized Signature"
                        signature={doc.signatures.client_sig}
                        textSignature={{
                          id: 'client_text',
                          value: doc.signatures.client_text,
                          label: '(or) Print Name as your Signature:',
                          placeholder: 'Print Name'
                        }}
                        handleInputChange={handleInputChange}
                        handleSignatureConfirm={handleSignatureConfirm}
                        handleSignatureRetry={handleSignatureRetry}
                        allowEdit={type === 'client-sign'} />
                    </li>
                    <li>
                      <SignatureRow
                        id='second_sig'
                        signerType="Client"
                        description="Authorized Signature"
                        signature={doc.signatures.second_sig}
                        textSignature={{
                          id: 'second_text',
                          value: doc.signatures.second_text,
                          label: '(or) Print Name as your Signature:',
                          placeholder: 'Print Name'
                        }}
                        handleInputChange={handleInputChange}
                        handleSignatureConfirm={handleSignatureConfirm}
                        handleSignatureRetry={handleSignatureRetry}
                        allowEdit={type === 'client-sign'} />
                    </li>
                    {type === 'advisor-sign' ?
                      <li>
                        <SignatureRow
                          id='advisor_sig'
                          signerType="Advisor"
                          description={advisor.name}
                          signature={advisor.signature}
                          handleInputChange={handleInputChange}
                          handleSignatureConfirm={handleSignatureConfirm}
                          handleSignatureRetry={handleSignatureRetry}
                          allowEdit={false} />
                      </li>
                      : ''}
                  </ul>
                </div>
              </div> */}
          </div>
          {/* </PDFExport> */}

          <DocumentSubmit errors={errors} handleSubmit={handleSubmit} />
        </div>
      )
    } else {
      return <h4>Populating...</h4>
    }
  }
}

export default BitsySuitabilityForm
