import React, { memo } from 'react'

export default memo(function ClockIcon({ size = '42px', color = '#0DC4B6' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 42 42"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 20.5882C0 9.21767 9.21767 0 20.5882 0C26.0486 0 31.2853 2.16911 35.1463 6.03015C39.0074 9.8912 41.1765 15.1279 41.1765 20.5882C41.1765 31.9588 31.9588 41.1765 20.5882 41.1765C9.21767 41.1765 0 31.9588 0 20.5882ZM24.893 27.5321C25.623 28.2578 26.802 28.2578 27.5321 27.5321C28.2577 26.8021 28.2577 25.6231 27.5321 24.8931L22.4599 19.8209L22.4599 9.35829C22.4599 8.3246 21.6219 7.48663 20.5882 7.48663C19.5545 7.48663 18.7166 8.3246 18.7166 9.35829L18.7166 20.5882C18.7186 21.0884 18.9208 21.567 19.278 21.9171L24.893 27.5321Z"
        fill={color}
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  )
})
