import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const phoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const numberMoneyMask = createNumberMask({
  prefix: '$',
  suffix: '',
  allowDecimal: true,
})

export const numberMoneyHourMask = createNumberMask({
  prefix: '$',
  suffix: ' /hour',
  allowDecimal: true,
})

export const numberMoneyMonthMask = createNumberMask({
  prefix: '$',
  suffix: ' /month',
  allowDecimal: true,
})

export const numberPercentageMask = createNumberMask({
  prefix: '',
  suffix: '%',
  allowDecimal: true,
})
