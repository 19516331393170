import React, { memo, useState, useEffect } from 'react'
import { Button, Card, Form, Input, Select } from 'semantic-ui-react'
import Loader from '../../../partials/Loader'
import listDsTemplates from '../../../../actions/integrations/docusign/listTemplates'
import listDsTemplateTabs from '../../../../actions/integrations/docusign/listTemplateTabs'
import { useFirm } from '../../../../contexts'
import styled from 'styled-components'
import { fonts } from '../../../../styles'
import VSpacer from '../../../partials/VSpacer'
import FieldMappingTable from '../partials/FieldMappingTable'
import { getEnv } from 'helpers/_utils'

const DocumentPage = memo(
  ({
    template: {
      fields = [],
      config = {},
      dsFieldMapping = {},
      legacyDocType: passedLegacyDocType = '',
    },
    handleSetTemplate,
  }) => {
    const { firm, firmID } = useFirm()

    const [dsTemplates, setDsTemplates] = useState([])
    const [gettingDsTemplates, setGettingDsTemplates] = useState(null)
    const [dsTemplateTabs, setDsTemplateTabs] = useState(null)
    const [gettingDsTemplateTabs, setGettingDsTemplateTabs] = useState(null)
    const [legacyDocType, setLegacyDocType] = useState(passedLegacyDocType)

    const getDsTemplates = async () => {
      const { dsFolderID } = firm
      const dsEnv = getEnv()
      if (dsFolderID && dsFolderID[dsEnv]) {
        console.log(`getting templates from docusign env: ${dsEnv}`)
        setGettingDsTemplates(true)
        const response = await listDsTemplates(dsFolderID[dsEnv], firmID)
        if (response && response.envelopeTemplates) {
          setDsTemplates(response.envelopeTemplates)
        }
        setGettingDsTemplates(null)
      }
    }

    useEffect(() => {
      getDsTemplates()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setDsTemplateId = (value) => {
      const env = getEnv()
      handleSetTemplate({
        config: {
          ...config,
          [env]: { ...config[env], dsTemplateId: value },
        },
      })
      setDsTemplateTabs(null)
    }

    const setDsFieldMapping = (value) => {
      handleSetTemplate({ dsFieldMapping: value })
    }

    const setTemplateLegacyDocType = () => {
      handleSetTemplate({ legacyDocType })
    }

    const getDsDocumentTabs = async (templateID, documentID, firmID) => {
      setGettingDsTemplateTabs(true)
      const response = await listDsTemplateTabs(templateID, documentID, firmID)
      if (response) {
        setDsTemplateTabs(response)
      }
      setGettingDsTemplateTabs(null)
    }

    const account = getEnv()
    const dsTemplateId = config[account].dsTemplateId

    useEffect(() => {
      // TODO: [BTS-608] Support multi-document DocuSign templates?
      if (dsTemplateId && !dsTemplateTabs) {
        getDsDocumentTabs(dsTemplateId, '1', firmID)
      }
    }, [dsTemplateId, dsTemplateTabs, firmID])

    if (gettingDsTemplates) {
      return <Loader message="Checking for existing templates..." />
    }

    const handleSetDsFieldMapping = (dsTabLabel, bitsyFieldKey) => {
      setDsFieldMapping({
        ...dsFieldMapping,
        [dsTabLabel]: `$.client.${bitsyFieldKey}`,
      })
    }

    return (
      <StyledCard>
        {passedLegacyDocType ? (
          <>
            <Header>
              This form uses your custom{' '}
              <span className="uppercase">{legacyDocType}</span> document
              template.
            </Header>
            <p className="instructional text-center">
              <small>
                We are working on allowing you to edit this right here in your
                dashboard!
                <br />
                In the meantime, please contact Bitsy if you need to make any
                changes to the generated document.
              </small>
            </p>
          </>
        ) : dsTemplates && dsTemplates.length ? (
          <>
            <Form.Field>
              <label>Please select the document for this form:</label>
              <Select
                placeholder="Select a Document Template"
                fluid
                search
                selection
                clearable
                value={dsTemplateId}
                options={dsTemplates.map((dsTemplate) => {
                  const { templateId, name } = dsTemplate
                  return {
                    key: templateId,
                    value: templateId,
                    text: name,
                  }
                })}
                onChange={(e, { value }) => setDsTemplateId(value)}
              />
            </Form.Field>
            <VSpacer />
            {dsTemplateId && (
              <>
                {gettingDsTemplateTabs && (
                  <Loader message="Getting template tabs..." />
                )}
                {dsTemplateTabs &&
                  Object.keys(dsTemplateTabs) &&
                  Object.keys(dsTemplateTabs).length &&
                  fields &&
                  fields.length && (
                    <>
                      Now, map this document's fields to the form fields you
                      created:
                      <VSpacer />
                      <FieldMappingTable
                        dsTemplateTabs={dsTemplateTabs}
                        dsFieldMapping={dsFieldMapping}
                        fields={fields}
                        handleSetDsFieldMapping={handleSetDsFieldMapping}
                      />
                    </>
                  )}
              </>
            )}
          </>
        ) : (
          <>
            <Header>
              No document templates were found. Please contact Bitsy.
            </Header>
            <p className="instructional text-center">
              <small>
                If you have a legacy document with Bitsy, please enter the ID
                below <em>(e.g. iac, suitability, or k401)</em>
              </small>
            </p>
            <Form.Field>
              <Input
                placeholder="iac"
                fluid
                value={legacyDocType}
                onChange={(e, { value }) => setLegacyDocType(value)}
                action={
                  <Button
                    icon="save"
                    primary
                    onClick={setTemplateLegacyDocType}
                  />
                }
              />
            </Form.Field>
          </>
        )}
      </StyledCard>
    )
  }
)

export default DocumentPage

const Header = styled.div`
  ${fonts.heading};
  text-align: center;
  margin: 20px;
`

const StyledCard = styled(Card)`
  &&& {
    max-width: 600px;
    margin: auto;
  }
`
