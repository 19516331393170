import React, { memo, useState, useEffect } from 'react'
import _ from 'lodash'
import { Search } from 'semantic-ui-react'
import { useUser, useProfile } from 'contexts'
import styled from 'styled-components'

const categoryNames = {
  firm: 'Firms',
  advisor: 'Advisors',
  client: 'Clients',
}

const resultRenderer = ({ id, title, description }) => {
  return (
    <div className="content" key={id}>
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </div>
  )
}

const SearchForm = memo(({ items = [], className = '' }) => {
  const { userType } = useUser()
  const { setProfile } = useProfile()

  const [source, setSource] = useState({})
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const [results, setResults] = useState([])

  useEffect(() => {
    if (items && items.length) {
      const categories = items
        .map((item) => item.type)
        .filter((value, index, self) => self.indexOf(value) === index)

      let newSource = {}

      categories.forEach((category) => {
        const categoryName = categoryNames[category] || category

        newSource[categoryName] = {
          name: categoryName,
          results: items
            .filter((item) => item.type === category)
            .map((item) => ({
              id: item.id,
              title: item.name,
              description: item.object.email,
              type: item.type,
            })),
        }
      })

      setSource(newSource)
    }
  }, [items])

  let placeholder = 'Search client or advisor...'
  if (userType === 'admin' || userType === 'super-admin') {
    placeholder = 'Search firm, advisor, or client...'
  }

  const handleResultSelect = (e, { result }) => {
    setProfile({
      type: result.type,
      id: result.id,
    })
  }

  useEffect(() => {
    if (value.length < 1) {
      setValue('')
      setLoading(false)
    }
  }, [value])

  const handleSearchChange = (e, { value }) => {
    setLoading(true)
    setValue(value)

    setTimeout(() => {
      const re = new RegExp(_.escapeRegExp(value), 'i')
      const isMatch = (result) =>
        re.test(result.title) || re.test(result.description)

      const filteredResults = _.reduce(
        source,
        (memo, data, name) => {
          const results = _.filter(data.results, isMatch)
          console.log('results', results)
          if (results.length) memo[name] = { name, results } // eslint-disable-line no-param-reassign

          return memo
        },
        {}
      )

      setLoading(false)
      setResults(filteredResults)
    }, 300)
  }

  return (
    <StyledForm className={`nav-search-form form-inline ${className}`}>
      <Search
        input={{
          iconPosition: 'left',
          placeholder,
          fluid: true,
        }}
        category
        loading={loading}
        onResultSelect={handleResultSelect}
        onSearchChange={_.debounce(handleSearchChange, 500, {
          leading: true,
        })}
        results={results}
        resultRenderer={resultRenderer}
        value={value}
      />
    </StyledForm>
  )
})

export default SearchForm

const StyledForm = styled.form`
  &&& {
    width: 100%;
    max-width: 420px;
    display: block;

    .results.transition {
      max-height: 80vh;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    @media (max-width: 767.98px) {
      max-width: calc(100% - 24px);
      margin: 0 12px !important;
    }
  }
`
