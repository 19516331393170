import React, { useState } from 'react'
import useStateWithCallback from 'use-state-with-callback'
import { Button, Icon } from 'semantic-ui-react'
import Form from './BitsyForm'
import Field from './Field'
import { updatePassword } from '../../actions/auth/authActions'
import { requestNewEmailLoginLink } from '../../actions/auth/emailLoginActions'
import ErrorsList from '../partials/ErrorsList'
import './UpdatePasswordForm.scss'
import { useUser, useFirm } from '../../contexts'
import { usePrevious } from '../../actions/helpers'

const UpdatePasswordForm = (props) => {
  const { userType } = useUser()
  const { firmID } = useFirm()

  const { firstTime } = props

  const [password, setPassword] = useState('')
  const [password_confirm, setPasswordConfirm] = useState('')
  const [editMode, setEditMode] = useState(true)
  const [requestNewEmailLink, setRequestNewEmailLink] = useState(false)
  const [requestNewEmailLinkSent, setRequestNewEmailLinkSent] = useState(false)
  const [errors, setErrors] = useStateWithCallback([], (errors) => {
    if (!editMode && !requestNewEmailLink && !requestNewEmailLinkSent) {
      updatePassword(
        userType,
        password,
        setRequestNewEmailLink,
        setErrors,
        firmID
      )
    }
  })

  const prevRequestNewEmailLink = usePrevious(requestNewEmailLink)

  if (
    prevRequestNewEmailLink !== requestNewEmailLink &&
    requestNewEmailLink &&
    !requestNewEmailLinkSent
  ) {
    // Send password reset email
    requestNewEmailLoginLink(
      null,
      setRequestNewEmailLink,
      setRequestNewEmailLinkSent,
      setErrors,
      firmID
    )
  }

  const attemptSubmit = (event) => {
    event.preventDefault()
    validate()
  }

  const validate = () => {
    var newErrors = []

    // Password
    if (password.length < 8) {
      newErrors.push('Passwords must be at least 8 characters.')
    }

    // Confirm Password
    if (password !== password_confirm) {
      newErrors.push('Please make sure passwords match.')
    }

    setErrors(newErrors)

    if (!newErrors.length) {
      setEditMode(false)
    }
  }

  return (
    <Form
      id="update-password"
      onSubmit={attemptSubmit}
      title={firstTime ? 'Create a Password' : 'Update Your Password'}
    >
      <Field
        type="password"
        id="password"
        label="Password"
        value={password}
        handleInputChange={(e) => setPassword(e.target.value)}
        tooltip={firstTime ? null : 'Passwords must be at least 8 characters!'}
      />
      <Field
        type="password"
        id="password_confirm"
        label="Confirm Password"
        value={password_confirm}
        handleInputChange={(e) => setPasswordConfirm(e.target.value)}
      />

      <ErrorsList errors={errors} />

      <Button type="submit" animated primary>
        <Button.Content visible>Submit</Button.Content>
        <Button.Content hidden>
          <Icon name="arrow right" />
        </Button.Content>
      </Button>
    </Form>
  )
}

export default UpdatePasswordForm
