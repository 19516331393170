import React, { Component, Fragment } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Collapse from 'react-collapse'
import qs from 'querystring'
import Table from '../partials/LegacyTable'
import Field from './Field'
import AddressFields from './AddressFields'
import AdvisorInviteForm from './AdvisorInviteForm'
import getStripeCustomer from '../../actions/integrations/stripe/getCustomer'
import { addAccount } from '../../actions/authActions'
import { validEmailRegex, validZipRegex } from '../../config/regexPatterns'
import { custodian_options } from '../../config/options'
import ErrorsList from '../partials/ErrorsList'
import './LegacyRegisterForm.scss'

const exampleFeeScheduleRows = [
  { asset_range: '$1 - $200,000', fee: '1.40%' },
  { asset_range: '$200,001 - $500,000', fee: '1.30%' },
  { asset_range: '$500,001 - $800,000', fee: '1.20%' },
  { asset_range: '$800,001 - $1,500,000', fee: '1.10%' },
  { asset_range: '$1,500,001+', fee: '1.00%' },
]

class RegisterFirmForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
      accordion_fee_schedule_example: false,
      show_password: false,
      email: '',
      password: '',
      accept_legal: false,
      firmID: '',
      firmCPS: '',
      firm: {
        contact_name: '',
        name: '',
        phone: '',
        crd: '',
        hasServices: {
          onboardingModule: true,
          autoADV: false,
          suitabilityForm: true,
          k401: true,
        },
        hasIntegrations: {
          acuity: false,
          box: false,
          calendly: false,
          dropbox: false,
          google_drive: false,
          practifi: false,
          redtail: false,
          riskalyze: false,
          salesforce: false,
          tdameritrade: false,
          wealthbox: false,
          zapier: false,
        },
        custodian: {
          id: '',
          preferred_name: '',
          legal_name: '',
          address_street: '',
          address_city: '',
          address_state: '',
          address_zip: '',
          phone: '',
        },
        authorized_third_party: {
          legal_name: '',
          preferred_name: '',
          abbreviation: '',
          address_street: '',
          address_city: '',
          address_state: '',
          address_zip: '',
          phone: '',
        },
        address_street: '',
        address_city: '',
        address_state: '',
        address_zip: '',
        signing_party: {
          name: '',
          title: '',
          credentials: '',
        },
        legal_name: '',
        executed_contract_email: '',
        executed_contract_email_body: '',
        iac_template: '',
        account_minimum: '',
        annual_fees: [{ portfolio_min: '', portfolio_max: '', fee: '' }],
        fee_schedule_minimum: '',
        fee_schedule_maximum: '',
        hourly_fee: '',
        support_fee: '',
        support_fee_type: 'hourly',
        store_pdfs: false,
        adminIsAdvisor: false,
        admin_advisor_email: '',
        admin_advisor_crd: '',
        advisors: [{ email: '' }],
        payment_period: '',
        isInGoodStanding: false,
        subscriptionID: '',
        currentPeriodEnd: '',
        customerID: '',
        planTier: '',
        firstLogin: true,
        module: {
          embedded: false,
          color_button: 'rgb(9, 225, 192)',
          color_button_border: 'rgb(10, 255, 206)',
          color_button_hover: 'rgb(252, 15, 192)',
          color_button_hover_border: 'transparent',
        },
        signatureType: 'both',
      },
      // copySuccess: '',
      gettingStripeCustomer: false,
      hasCheckedStripeCustomer: false,
      errors: [],
    }

    this.addFirm = this.addFirm.bind(this)
    this.attemptContinue = this.attemptContinue.bind(this)
    // this.copyToClipboard = this.copyToClipboard.bind(this)
    // this.generatePlan = this.generatePlan.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTextMultipleChange = this.handleTextMultipleChange.bind(this)
    this.increaseStep = this.increaseStep.bind(this)
    this.processURLParams = this.processURLParams.bind(this)
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.togglePassword = this.togglePassword.bind(this)
    this.validateStep = this.validateStep.bind(this)
  }

  componentDidMount() {
    this.processURLParams()
  }

  componentDidUpdate(prevProps, prevState) {
    // Continue on Stripe customer update
    if (
      !prevState.hasCheckedStripeCustomer &&
      this.state.hasCheckedStripeCustomer &&
      !this.state.errors.length
    ) {
      this.setState({
        gettingStripeCustomer: false,
        tabIndex: prevState.tabIndex + 1,
      })
    }
  }

  addFirm() {
    if (this.state.errors.length === 0) {
      // Add line breaks to executed contract email
      var emailBodyWithBreaks = ''

      this.state.firm.executed_contract_email_body
        .split('\n')
        .map(function (item) {
          emailBodyWithBreaks += '<span>' + item + '<br /></span>'

          return emailBodyWithBreaks
        })

      this.setState(
        {
          firm: {
            ...this.state.firm,
            executed_contract_email_body: emailBodyWithBreaks,
          },
        },
        () => {
          // Add firm to auth and database
          addAccount(
            'firm',
            this.state.email,
            this.state.password,
            this.state.firm,
            this,
            this.state.firmCPS
          )
        }
      )
    }
  }

  attemptContinue(event) {
    event.preventDefault()

    this.validateStep(this.state.tabIndex)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (
      name === 'email' ||
      name === 'password' ||
      name === 'accept_legal' ||
      name === 'firmCPS'
    ) {
      // Top level state changes
      this.setState({
        [name]: value,
      })
    } else if (name === 'custodian') {
      // Custodian state changes
      this.setState({
        firm: {
          ...this.state.firm,
          custodian: custodian_options[value],
        },
      })
    } else if (name === 'signing_party_name') {
      // Signing party name state changes
      this.setState({
        firm: {
          ...this.state.firm,
          signing_party: {
            ...this.state.firm.signing_party,
            name: value,
          },
        },
      })
    } else if (name === 'signing_party_title') {
      // Signing party title state changes
      this.setState({
        firm: {
          ...this.state.firm,
          signing_party: {
            ...this.state.firm.signing_party,
            title: value,
          },
        },
      })
    } else if (name === 'signing_party_credentials') {
      // Signing party credentials state changes
      this.setState({
        firm: {
          ...this.state.firm,
          signing_party: {
            ...this.state.firm.signing_party,
            credentials: value,
          },
        },
      })
    } else if (name === 'adminIsAdvisor') {
      // Advisor email state changes
      this.setState(
        {
          firm: {
            ...this.state.firm,
            [name]: value,
          },
        },
        function () {
          if (value === true) {
            this.setState({
              firm: {
                ...this.state.firm,
                admin_advisor_email: this.state.email,
              },
            })
          } else {
            this.setState({
              firm: {
                ...this.state.firm,
                admin_advisor_email: '',
                admin_advisor_crd: '',
              },
            })
          }
        }
      )
    } else {
      // Firm state changes
      this.setState({
        firm: {
          ...this.state.firm,
          [name]: value,
        },
      })
    }
  }

  handleTextMultipleChange(id, fields) {
    this.setState({
      firm: {
        ...this.state.firm,
        [id]: fields,
      },
    })
  }

  increaseStep() {
    if (this.state.errors.length === 0) {
      // Final firm adding
      if (this.state.tabIndex === 2) {
        this.addFirm()
        return
      }

      // Find firm email customer
      if (this.state.tabIndex === 0) {
        this.setState({ gettingStripeCustomer: true })
        getStripeCustomer({
          customerEmail: this.state.email,
          registrationState: this,
        })
        return
      }

      this.setState((prevState) => {
        return { tabIndex: prevState.tabIndex + 1 }
      })
    }
  }

  processURLParams() {
    const searchParams = qs.parse(window.location.search.substr(1))

    if (
      searchParams &&
      Object.keys(searchParams) &&
      Object.keys(searchParams).length
    ) {
      const { name, firm_name, email, phone, planTier, promo } = searchParams

      let passedFirmFields = {}

      Object.keys(searchParams).forEach((param) => {
        switch (param) {
          case 'name':
            passedFirmFields['contact_name'] = name
            break
          case 'firm_name':
            passedFirmFields['name'] = firm_name
            break
          case 'email':
            this.setState({
              email: email,
            })
            break
          case 'phone':
            // TODO: [BTS-512] Improve phone formatting
            passedFirmFields['phone'] = phone
            break
          case 'planTier':
            // Set plan tier to default to promo if one is provided
            if (!searchParams['promo']) {
              passedFirmFields['planTier'] = planTier
            }
            break
          case 'promo':
            this.hasPassedPromo = promo
            passedFirmFields['planTier'] = promo
            break
          default:
            break
        }
      })

      if (Object.keys(passedFirmFields).length) {
        this.setState({
          firm: {
            ...this.state.firm,
            ...passedFirmFields,
          },
        })
      }
    }
  }

  toggleAccordion(event) {
    event.preventDefault()

    const target = event.target
    const name = target.name

    this.setState({
      [name]: !this.state[name],
    })
  }

  togglePassword(event) {
    event.preventDefault()

    this.setState({
      show_password: !this.state.show_password,
    })
  }

  validateStep(step) {
    const { email, password, accept_legal, firm, firmCPS } = this.state

    var errors = []

    switch (step) {
      case 0:
        // Name
        if (firm.name.length < 1) {
          errors.push('Please enter firm name.')
        }

        // Email
        if (!validEmailRegex.test(email)) {
          errors.push('Please enter a valid email address.')
        }

        // Password
        if (password.length < 8) {
          errors.push('Password must be at least 8 characters.')
        }

        // Accept Legal
        if (!accept_legal) {
          errors.push(
            'Please accept the Terms of Service, Fee Arrangement & Privacy Policy.'
          )
        }

        break
      case 1:
        // Company Information

        // Contact Name
        if (firm.contact_name.length < 1) {
          errors.push('Please enter a contact name.')
        }

        // Phone
        if (firm.phone.length < 1) {
          errors.push('Please enter a phone number.')
        }

        // Firm CRD
        if (firm.crd.length < 1) {
          errors.push('Please enter firm CRD.')
        }

        // Custodian
        if (firm.custodian.id.length < 1) {
          errors.push('Please select a current custodian.')
        }

        // Home Office Address
        if (firm.address_street.length < 1) {
          errors.push('Please enter home office address.')
        }

        // City
        if (firm.address_city.length < 1) {
          errors.push('Please enter a city.')
        }

        // State
        if (firm.address_state.length < 1) {
          errors.push('Please enter a state.')
        }

        // Zip
        if (!validZipRegex.test(firm.address_zip)) {
          errors.push('Please enter a valid zip code.')
        }

        // Plan selection
        if (!firm.planTier) {
          errors.push('Please select how many clients you onboard per year.')
        }

        // Signing Party

        // Name
        if (firm.signing_party.name.length < 1) {
          errors.push("Please enter signing party's name.")
        }

        // Title
        if (firm.signing_party.title.length < 1) {
          errors.push("Please enter signing party's title.")
        }

        // Emails

        // Email address
        if (firm.executed_contract_email.length < 1) {
          errors.push(
            'Please enter an email address where executed contracts should be sent.'
          )
        }

        // Title
        if (firm.executed_contract_email_body.length < 1) {
          errors.push(
            'Please enter a message for executed contract emails to prevent them from going to spam.'
          )
        }

        // IAC
        if (firm.iac_template.length < 1) {
          errors.push('Please select an IAC template.')
        }

        // Account Size Minimum
        if (firm.iac_template === 'bitsy' && firm.account_minimum.length < 1) {
          errors.push('Please enter a minimum account size.')
        }

        // Fee Schedule
        if (firm.iac_template === 'bitsy' && firm.annual_fees.length < 1) {
          errors.push('Please enter at least one fee schedule line.')
        }

        if (firm.iac_template === 'bitsy') {
          firm.annual_fees.map(function (annualFee) {
            if (
              annualFee.portfolio_min.length < 1 ||
              annualFee.portfolio_max.length < 1 ||
              annualFee.fee.length < 1
            ) {
              errors.push(
                'Please make sure each fee line has a minimum, maximum and fee. Enter $0 for + in the maximum column.'
              )
            }

            // Check that all fees are within the min/max range
            const feeScheduleMinimum =
              firm && firm.fee_schedule_minimum
                ? parseFloat(firm.fee_schedule_minimum.replace(/%/g, ''))
                : null
            const feeScheduleMaximum =
              firm && firm.fee_schedule_maximum
                ? parseFloat(firm.fee_schedule_maximum.replace(/%/g, ''))
                : null

            let feesBelowMinimum
            let feesAboveMaximum

            if (feeScheduleMinimum) {
              feesBelowMinimum = firm.annual_fees.filter(
                (line) =>
                  parseFloat(line.fee.replace(/%/g, '')) < feeScheduleMinimum
              )
            }

            if (feeScheduleMaximum) {
              feesAboveMaximum = firm.annual_fees.filter(
                (line) =>
                  parseFloat(line.fee.replace(/%/g, '')) > feeScheduleMaximum
              )
            }

            if (
              (feesBelowMinimum && feesBelowMinimum.length) ||
              (feesAboveMaximum && feesAboveMaximum.length)
            ) {
              errors.push(
                `Please make sure all fee schedule fees are within the range of ${
                  feeScheduleMinimum || '0'
                }% to ${
                  feeScheduleMaximum + '%' || 'unlimited'
                } that you specified.`
              )
            }

            return errors
          })
        }

        // Additional Services Fee
        if (firm.iac_template === 'bitsy' && firm.hourly_fee.length < 1) {
          errors.push('Please enter an hourly fee for additional services.')
        }

        // Client Password
        if (firmCPS.length < 8) {
          errors.push('Client Password must be at least 8 characters.')
        }

        break
      case 2:
        // Admin Advisor CRD
        if (firm.adminIsAdvisor && firm.admin_advisor_crd.length < 1) {
          errors.push('Please enter firm admin individual advisor CRD.')
        }

        // Team Members
        firm.advisors.map(function (teamMember) {
          if (
            teamMember.email.length < 1 ||
            !validEmailRegex.test(teamMember.email)
          ) {
            errors.push('Please enter a valid email for each advisor.')
          }

          if (firm.adminIsAdvisor && teamMember.email === email) {
            errors.push(
              'Admin advisors will be added automatically. Please remove them from the list of team members.'
            )
          }

          return errors
        })

        break
      // case 3:

      //   // Payment - no validation needed

      //   break
      default:
        break
    }

    // Set state and increase step
    this.setState(
      {
        errors: errors,
      },
      () => {
        this.increaseStep()
      }
    )
  }

  render() {
    const {
      firm,
      email,
      password,
      firmCPS,
      errors,
      tabIndex,
      show_password,
    } = this.state

    this.custodian_choices = Object.keys(custodian_options).map(function (
      custodian,
      key
    ) {
      return (
        <option key={custodian} value={custodian}>
          {custodian_options[custodian].preferred_name}
        </option>
      )
    })

    return (
      <form id="register-firm">
        <Tabs
          selectedIndex={this.state.tabIndex}
          onSelect={(tabIndex) => this.setState({ tabIndex })}
        >
          <TabList>
            <Tab disabled={true}>Basics</Tab>
            <Tab disabled={true}>Company</Tab>
            <Tab disabled={true}>Team</Tab>
          </TabList>

          <TabPanel>
            <div className="step-contents">
              <Field
                type="text"
                id="name"
                label="Firm Name"
                value={firm.name}
                handleInputChange={this.handleInputChange}
              />
              <Field
                type="email"
                id="email"
                label="Firm Email"
                value={email}
                handleInputChange={this.handleInputChange}
              />

              <div className="form-group">
                <Field
                  type={show_password ? 'text' : 'password'}
                  id="password"
                  label="Password"
                  value={password}
                  handleInputChange={this.handleInputChange}
                  tooltip="At least 8 characters"
                />
                <button
                  type="button"
                  id="show-password"
                  className="primary btn-sm"
                  onClick={this.togglePassword}
                >
                  {show_password ? 'Hide' : 'Display'}
                </button>
              </div>
              <div className="form-group agreement">
                <small>
                  I am a U.S. Resident, at least 18 years of age and agree to
                  the{' '}
                  <a
                    target="_blank"
                    href="https://bitsyadvisor.com/terms-of-service/"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  ,{' '}
                  <a
                    href="https://bitsyadvisor.com/fee-arrangement/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Fee Arrangement
                  </a>{' '}
                  &{' '}
                  <a
                    target="_blank"
                    href="https://bitsyadvisor.com/privacy-policy/"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                  of Bitsy Advisor®
                </small>

                <input
                  value={this.state.accept_legal}
                  name="accept_legal"
                  id="accept_legal"
                  type="checkbox"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <h2>Please input your details</h2>

            <div className="step-contents main-form">
              <Field
                type="text"
                id="contact_name"
                label="Name"
                value={firm.contact_name}
                handleInputChange={this.handleInputChange}
              />
              <Field
                type="text"
                id="name_2"
                label="Firm Name"
                value={firm.name}
                handleInputChange={this.handleInputChange}
                readOnly={true}
              />
              <Field
                type="email"
                id="email_2"
                label="Email Address"
                value={email}
                handleInputChange={this.handleInputChange}
                readOnly={true}
              />
              <Field
                type="phone"
                id="phone"
                label="Phone"
                value={firm.phone}
                handleInputChange={this.handleInputChange}
              />
              <Field
                type="text"
                id="crd"
                label="Firm CRD"
                value={firm.crd}
                handleInputChange={this.handleInputChange}
              />

              <div className="form-group bitsy-form-group">
                <label htmlFor="custodian" className="visually-hidden">
                  Current Custodian
                </label>
                <select
                  value={firm.custodian.id}
                  name="custodian"
                  id="custodian"
                  onChange={this.handleInputChange}
                >
                  <option value="" disabled>
                    Current Custodian
                  </option>
                  {this.custodian_choices}
                </select>
              </div>

              <AddressFields
                labelFor="firm-address"
                label="Home Office Address"
                required={true}
                values={{
                  street: {
                    id: 'address_street',
                    value: firm.address_street,
                  },
                  city: {
                    id: 'address_city',
                    value: firm.address_city,
                  },
                  state: {
                    id: 'address_state',
                    value: firm.address_state,
                  },
                  zip: {
                    id: 'address_zip',
                    value: firm.address_zip,
                  },
                }}
                handleInputChange={this.handleInputChange}
              />

              {!this.hasPassedPromo && (
                <Fragment>
                  <h4>Firm Requirements</h4>
                  <Field
                    type="select"
                    label="How many clients does your firm onboard per year?"
                    id="planTier"
                    value={firm.planTier}
                    options={[
                      { value: 'tier-1', label: '< 250' },
                      { value: 'tier-2', label: '250 - 750' },
                      { value: 'tier-3', label: '750+' },
                    ]}
                    handleInputChange={this.handleInputChange}
                  />
                </Fragment>
              )}

              <h4>Signing Party</h4>
              <Field
                type="text"
                id="signing_party_name"
                label="Name"
                value={firm.signing_party.name}
                handleInputChange={this.handleInputChange}
              />
              <Field
                type="text"
                id="signing_party_title"
                label="Title"
                value={firm.signing_party.title}
                handleInputChange={this.handleInputChange}
              />
              <Field
                type="text"
                id="signing_party_credentials"
                label="Credentials"
                placeholder="Credentials (e.g. AWMA)"
                value={firm.signing_party.credentials}
                handleInputChange={this.handleInputChange}
              />
              <Field
                type="text"
                id="legal_name"
                label="Firm Legal Name"
                value={firm.legal_name}
                handleInputChange={this.handleInputChange}
              />

              <h4>Email Settings</h4>
              <Field
                type="email"
                id="executed_contract_email"
                label="Where should copies of executed IACs be sent?"
                placeholder="Email Address"
                showLabel={true}
                instructional="Copies will be sent to the client and servicing advisor automatically."
                value={firm.executed_contract_email}
                handleInputChange={this.handleInputChange}
              />
              <Field
                type="textarea"
                id="executed_contract_email_body"
                label="Email Body"
                placeholder="Hello,&#10;&#10;Please find your executed IAC attached...."
                showLabel={true}
                value={firm.executed_contract_email_body}
                handleInputChange={this.handleInputChange}
              />

              <Field
                type="select"
                options={[
                  { value: 'bitsy', label: "Yes, use Bitsy Advisor's IAC." },
                  {
                    value: 'custom',
                    label: "No, we'll send a custom template.",
                  },
                ]}
                id="iac_template"
                label="IAC"
                placeholder="Use standard compliant IAC?"
                showLabel={true}
                value={firm.iac_template}
                handleInputChange={this.handleInputChange}
              />

              {firm.iac_template === 'bitsy' && (
                <Fragment>
                  <Field
                    type="money"
                    id="account_minimum"
                    label="Client Account Size Minimum"
                    instructional="Enter 0 for no minimum"
                    value={firm.account_minimum}
                    handleInputChange={this.handleInputChange}
                  />

                  <h4>Fee Schedule</h4>

                  <button
                    type="button"
                    className={
                      this.state.accordion_fee_schedule_example
                        ? 'd-block accordion-toggle active link fee-schedule-toggle'
                        : 'd-block accordion-toggle link fee-schedule-toggle'
                    }
                    name="accordion_fee_schedule_example"
                    onClick={this.toggleAccordion}
                  >
                    {this.state.accordion_fee_schedule_example ? 'Hide' : 'See'}{' '}
                    example
                  </button>
                  <Collapse
                    isOpened={this.state.accordion_fee_schedule_example}
                  >
                    <div className="fee-schedule">
                      <Table
                        type="columns"
                        className="fee-schedule"
                        headerRow={[
                          { id: 'asset_range', label: 'Managed Assets' },
                          { id: 'fee', label: 'Annual Fee' },
                        ]}
                        rows={exampleFeeScheduleRows}
                      />
                    </div>
                  </Collapse>

                  <Field
                    type="text-multiple"
                    id="annual_fees"
                    label="Fee Schedule"
                    value={firm.annual_fees}
                    handleInputChange={this.handleTextMultipleChange}
                    listClassName="fee-schedule"
                    textMultipleFields={[
                      {
                        id: 'portfolio_min',
                        label: 'Asset Range Minimum',
                        placeholder: '(ex. $1)',
                        type: 'money',
                        showLabel: true,
                      },
                      {
                        id: 'portfolio_max',
                        label: 'Asset Range Maximum',
                        placeholder: '(ex. $200,000 or +)',
                        type: 'money',
                        showLabel: true,
                        instructional: 'Enter $0 for +',
                      },
                      {
                        id: 'fee',
                        label: 'Fee %',
                        placeholder: '(ex. 1.4%)',
                        type: 'percentage',
                      },
                    ]}
                    // textMultipleInitialFields={
                    //   <li className="header">
                    //     <div className="asset-range">Asset Range</div>
                    //     <div className="fee">Fee</div>
                    //   </li>
                    // }
                  />

                  <h4>Fee Schedule Limits</h4>
                  <Field
                    type="percentage"
                    id="fee_schedule_minimum"
                    label="Minimum Fee %"
                    placeholder="(ex 1%)"
                    showLabel={true}
                    value={firm.fee_schedule_minimum}
                    handleInputChange={this.handleInputChange}
                  />
                  <Field
                    type="percentage"
                    id="fee_schedule_maximum"
                    label="Maximum Fee %"
                    placeholder="(ex 5%)"
                    showLabel={true}
                    value={firm.fee_schedule_maximum}
                    handleInputChange={this.handleInputChange}
                  />

                  <div className="form-group additional-fees">
                    <Field
                      type="money-hour"
                      id="hourly_fee"
                      label="Additional Services Fee"
                      placeholder="Hourly Fee"
                      showLabel={true}
                      value={firm.hourly_fee}
                      handleInputChange={this.handleInputChange}
                    />

                    <div className="back-office-support-cost">
                      <Field
                        type="money"
                        id="support_fee"
                        label="Back Office Support Cost"
                        placeholder="(ex $60)"
                        showLabel={true}
                        value={firm.support_fee}
                        handleInputChange={this.handleInputChange}
                      />

                      <Field
                        type="select"
                        options={[
                          { value: 'hourly', label: '/hour' },
                          { value: 'salary', label: '/year' },
                        ]}
                        id="support_fee_type"
                        label="Per"
                        value={firm.support_fee_type}
                        handleInputChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </Fragment>
              )}

              <Field
                type="text"
                id="firmCPS"
                label="Set a password clients will use to sign up"
                placeholder="Client Password"
                showLabel={true}
                value={firmCPS}
                handleInputChange={this.handleInputChange}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <h2>Your Team</h2>

            <div className="step-contents">
              <Field
                type="email"
                id="admin_email"
                label="Admin Email"
                showLabel={true}
                value={email}
                handleInputChange={this.handleInputChange}
                readOnly={true}
              />
              <Field
                type="checkbox"
                id="adminIsAdvisor"
                label="Are you also an advisor?"
                showLabel={true}
                value={firm.adminIsAdvisor}
                handleInputChange={this.handleInputChange}
              />

              {firm.adminIsAdvisor && (
                <Field
                  type="text"
                  id="admin_advisor_crd"
                  label="Admin Individual Advisor CRD"
                  value={firm.admin_advisor_crd}
                  handleInputChange={this.handleInputChange}
                />
              )}

              <h4>Advisors</h4>
              <AdvisorInviteForm
                isInRegistrationFlow={true}
                advisors={firm.advisors}
                handleInputChange={this.handleTextMultipleChange}
                firmAdminAdvisorEmail={firm.adminIsAdvisor ? email : null}
              />
            </div>
          </TabPanel>

          <div className="form-group submit-section">
            <ErrorsList errors={errors} sticky={true} />

            <button
              className="continue"
              onClick={this.attemptContinue}
              type="button"
              disabled={
                tabIndex === 0 && this.state.gettingStripeCustomer
                  ? true
                  : false
              }
            >
              {tabIndex === 0
                ? 'Create Account'
                : tabIndex === 2
                ? 'Finish'
                : 'Continue'}
            </button>
          </div>
        </Tabs>
      </form>
    )
  }
}

export default RegisterFirmForm
