import React, { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { TemplateFormFields } from '../partials'
import { usePeople, useUser } from '../../../../contexts'

const DetailsPage = memo(
  ({
    template,
    clientID,
    client,
    additionalDocuments,
    handleSetClient,
    handleSetAdvisor,
    nextStep,
    label,
    saveAsPendingChanges,
    flow,
    finalizingAtEnd,
  }) => {
    const { userType, uid, user } = useUser()
    const { advisors } = usePeople()

    const [acceptDisclosures, setAcceptDisclosures] = useState(false)

    const handleSelectAdvisor = (e, { value }) => {
      handleSetClient({ advisor: value })

      if (!value) {
        handleSetAdvisor(null)
      } else if (advisors && advisors[value]) {
        handleSetAdvisor(advisors[value])
      } else if (userType === 'advisor' && value === uid) {
        handleSetAdvisor(user)
      }
    }

    // Set initial client advisor
    useEffect(() => {
      if (!client.advisor) {
        return
      }

      if (advisors && advisors[client.advisor]) {
        handleSetAdvisor(advisors[client.advisor])
      } else if (userType === 'advisor' && client.advisor === uid) {
        handleSetAdvisor(user)
      }
    }, [client.advisor, advisors, userType, uid, handleSetAdvisor, user])

    const handleDisclosuresAgreement = (e, { value }) => {
      setAcceptDisclosures((acceptDisclosures) => !acceptDisclosures)
    }

    const handleInputBlur = (input) => {
      const fieldName =
        input.props.uiSchema &&
        input.props.uiSchema['ui:options'] &&
        input.props.uiSchema['ui:options'].data &&
        input.props.uiSchema['ui:options'].data.field_name
          ? input.props.uiSchema['ui:options'].data.field_name
          : input.props.options && input.props.options.data.field_name
          ? input.props.options.data.field_name
          : input.fieldName
          ? input.fieldName
          : null
      const value = input.state.value

      if (fieldName) {
        console.log(`setting ${fieldName}: ${value}`)
        handleSetClient({ [fieldName]: value })
      }
    }

    const handleInputChange = (fieldName, value) => {
      if (fieldName) {
        console.log(`setting ${fieldName}: ${value}`)
        handleSetClient({ [fieldName]: value })
      }
    }

    // const handleContinue = () => {
    //   let validationErrors = []

    //   // Validate fields for legacy firms
    //   validationErrors = validationErrors.concat(
    //     legacyValidateFields(templateID, firmID, firm, client)
    //   )

    //   // Validate fields
    //   validationErrors = validationErrors.concat(
    //     validateFields(templateID, firmID, firm, client)
    //   )

    //   // Accept Disclosures
    //   if (!acceptDisclosures) {
    //     validationErrors.push('Please accept disclosures.')
    //   }

    //   setErrors(validationErrors)

    //   if (!validationErrors.length) {
    //     nextStep()
    //   }
    // }

    return (
      <Wrapper data-step={label}>
        <TemplateFormFields
          template={template}
          clientID={clientID}
          value={client}
          acceptDisclosures={acceptDisclosures}
          additionalDocuments={additionalDocuments}
          selectedAdvisorID={client.advisor || ''}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          handleContinue={nextStep}
          handleSelectAdvisor={handleSelectAdvisor}
          handleDisclosuresAgreement={handleDisclosuresAgreement}
          handleSetLocalClient={handleSetClient}
          saveAsPendingChanges={saveAsPendingChanges}
          flow={flow}
          finalizingAtEnd={finalizingAtEnd}
        />

        {/*
        <ErrorsList errors={errors} />
        <Button
          circular
          icon="right arrow"
          onClick={handleContinue}
          data-id="client-details-submit"
        /> */}
      </Wrapper>
    )
  }
)

export default DetailsPage

const Wrapper = styled.div`
  margin: auto;
  justify-content: center;
  align-content: center;
  max-width: 600px;
  align-self: center;
  height: 100%;
  padding: 25px 0;
`
