// ? This file is a replica of https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/semantic-ui/src/DescriptionField/DescriptionField.js for internal use

/* eslint-disable react/prop-types */
import React from 'react'

function DescriptionField({ className, description }) {
  if (description) {
    return <p className={className || 'sui-description'}>{description}</p>
  }
}

export default DescriptionField
