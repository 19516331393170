import { useRef, useEffect } from 'react'
import { numberWithCommasRegex } from '../config/regexPatterns'

export function camelCase(string) {
  return string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export function capitalize(string) {
  if (typeof string !== 'string') {
    return string
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const downloadBlob = ({
  blob,
  fileName = 'Download',
  fileExt = 'txt',
}) => {
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob)
    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob)

  var link = document.createElement('a')
  document.body.appendChild(link) // Required in FF, optional for Chrome
  link.href = data
  link.download = `${fileName}.${fileExt}`
  link.click()
  window.URL.revokeObjectURL(data)
  link.remove()
  return
}

export function formatAddress(object, prefix = null) {
  var address = ''

  var { address_street, address_city, address_state, address_zip } = object
  if (prefix) {
    address_street = object[prefix + '_street']
    address_city = object[prefix + '_city']
    address_state = object[prefix + '_state']
    address_zip = object[prefix + '_zip']
  }

  if (address_street) {
    address += address_street

    if (address_city || address_state || address_zip) {
      address += '\n'
    }
  }

  if (address_city) {
    address += address_city

    if (address_state) {
      address += ', '
    }
  }

  if (address_state) {
    address += address_state

    if (address_zip) {
      address += ' '
    }
  }

  if (address_zip) {
    address += address_zip
  }

  return address
}

export const formatLegalName = ({ client, investor = 'first' }) => {
  // First Investors
  const { legal_name_first, legal_name_mi, legal_name_last } = client
  let firstName = legal_name_first
  let middleInitial = legal_name_mi
  let lastName = legal_name_last

  // Second Investors
  if (investor === 'second') {
    const {
      second_investor_legal_name_first,
      second_investor_legal_name_mi,
      second_investor_legal_name_last,
    } = client
    firstName = second_investor_legal_name_first
    middleInitial = second_investor_legal_name_mi
    lastName = second_investor_legal_name_last
  }

  let formattedLegalName = `${firstName ? firstName : ''} ${
    middleInitial ? middleInitial : ''
  } ${lastName ? lastName : ''}`

  formattedLegalName = formattedLegalName.replace(/ +(?= )/g, '').trim()

  return formattedLegalName
}

export const formatCompositeInvestorNames = ({ client }) => {
  let name = ''

  const firstInvestorName = formatLegalName({ client, investor: 'first' })
  name += firstInvestorName

  const secondInvestorName = formatLegalName({ client, investor: 'second' })
  if (secondInvestorName) {
    name += ` & ${secondInvestorName}`
  }

  return name
}

export function generateRandomString(length) {
  var s = ''
  do {
    s += Math.random().toString(36).substr(2)
  } while (s.length < length)
  s = s.substr(0, length)

  return s
}

export function getFirmAccentColor(firm) {
  if (firm && firm.styles && firm.styles.colors && firm.styles.colors.accent) {
    return firm.styles.colors.accent
  }
  return null
}

export function getFirmStatus(firm, service = null) {
  const dateNow = Math.round(new Date().getTime() / 1000)

  if (!firm) {
    return null
  }

  var firmStatus = false

  if (firm) {
    if (firm.currentPeriodEnd && firm.currentPeriodEnd >= dateNow) {
      firmStatus = true
    }

    if (service && !firm.hasServices[service]) {
      firmStatus = false
    }
  }

  return firmStatus
}

export function getInitials(name) {
  var initials = name.match(/\b\w/g) || []
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()

  return initials
}

export function numberWithCommas(x) {
  var parts = x.toString().split('.')
  parts[0] = parts[0].replace(numberWithCommasRegex, ',')
  return parts.join('.')
}

export function numberRangeWithCommas(range) {
  const parts = range.split(' - ')
  const min = parseFloat(parts[0].replace('$', ''))
  const max = parseFloat(parts[1].replace('$', ''))
  return '$' + numberWithCommas(min) + ' - $' + numberWithCommas(max)
}

export function prettyNumber(x) {
  if (isNaN(x)) return x

  if (x < 9999) {
    return x
  }

  if (x < 1000000) {
    return Math.round(x / 1000) + 'K'
  }
  if (x < 10000000) {
    return (x / 1000000).toFixed(1) + 'M'
  }

  if (x < 1000000000) {
    return Math.round(x / 1000000) + 'M'
  }

  if (x < 1000000000000) {
    return Math.round(x / 1000000000) + 'B'
  }

  return '1T+'
}

export function compare(a, b) {
  var splitA = a.name.split(' ')
  var splitB = b.name.split(' ')
  var lastA = splitA[splitA.length - 1]
  var lastB = splitB[splitB.length - 1]

  if (lastA < lastB) return -1
  if (lastA > lastB) return 1
  return 0
}

export const dateNow = Math.round(new Date().getTime() / 1000)

export function scrollTo(event, id) {
  event.preventDefault()

  var element = document.getElementById(id)
  element.scrollIntoView({ behavior: 'smooth' })
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
