export const generateDisclosuresArray = ({
  firmDisclosures,
  advisorDisclosures,
}) => {
  let disclosuresTemp = []

  // ? Documents must come in as { documentID: { documentContents } }
  let additionalDocumentsObject = {}
  if (firmDisclosures) {
    additionalDocumentsObject = { ...firmDisclosures }
  }
  if (advisorDisclosures) {
    additionalDocumentsObject = {
      ...additionalDocumentsObject,
      ...advisorDisclosures,
    }
  }

  if (
    additionalDocumentsObject &&
    Object.keys(additionalDocumentsObject) &&
    Object.keys(additionalDocumentsObject).length
  ) {
    Object.keys(additionalDocumentsObject).forEach((documentID) => {
      disclosuresTemp.push({
        documentID: documentID,
        document: additionalDocumentsObject[documentID],
      })
    })
  }

  return disclosuresTemp
}
