import { firebaseDB } from 'config/firebaseConfig'

export const markNotificationAsRead = (userType, uid, notificationID) => {
  return firebaseDB
    .collection(`${userType}s`)
    .doc(uid)
    .collection(`notifications`)
    .doc(notificationID)
    .update({ read: true })
}
