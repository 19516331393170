import { navigate } from '@reach/router'
import { firebaseAuth, secondaryAuth } from '../config/firebaseConfig'
import {
  addFirmToDB,
  addAdvisorToDB,
  addClientToDB,
  addCPSToDB,
  updateUserInfo,
} from './dbActions'

export function startListeningToAuth(setUid) {
  const unsubscribeAuth = firebaseAuth.onAuthStateChanged(async (user) => {
    if (user) {
      // User is signed in.
      const { uid } = user
      setUid(uid)
    } else {
      setUid('')

      if (window.location.pathname === '/') {
        navigate(`/login${window.location.search}`)
      }
    }
  })

  return () => {
    console.log('Stopped listening to auth changes')
    unsubscribeAuth && unsubscribeAuth()
  }
}

export function customSignInUser(token, redirect) {
  firebaseAuth.signInWithCustomToken(token).then(() => {
    navigate(`/${redirect && `?${redirect}`}`)
  })
}

export function signInUser(email, password, setErrors) {
  firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .catch(function (error) {
      // Handle errors
      var errors = []
      var errorMessage = error.message

      if (error.code === 'auth/user-not-found') {
        errorMessage = 'No account was found with that email.'
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'Wrong password.'
      }

      errors.push(errorMessage)

      setErrors(errors)
    })
}

export function signOutUser() {
  firebaseAuth
    .signOut()
    .then(function () {
      // Sign-out successful.

      // Remove email sign-in cookie if one is present
      const emailSignInCookie = window.localStorage.getItem(
        'bitsyEmailForSignIn'
      )
      if (emailSignInCookie) {
        window.localStorage.removeItem('bitsyEmailForSignIn')
      }

      // Remove tabIndex cookie if one is present
      const tabIndexCookie = sessionStorage.getItem('tabIndex')
      if (tabIndexCookie) {
        sessionStorage.removeItem('tabIndex')
      }
    })
    .catch(function (error) {
      // An error happened.
    })
}

export function resetPassword(
  email,
  buttonState = null,
  type = null,
  setSuccess = null,
  setErrors = null
) {
  var currentUser = firebaseAuth.currentUser

  var emailAddress = email
  if (currentUser) {
    emailAddress = currentUser.email
  }

  firebaseAuth
    .sendPasswordResetEmail(emailAddress)
    .then(() => {
      // Email sent.
      if (buttonState) {
        buttonState.setState({
          success: true,
        })
      }

      if (setSuccess) {
        setSuccess(true)
      }

      // Set that client has reset password after 3 seconds
      if (type && type === 'client-reset') {
        setTimeout(() => {
          updateUserInfo('client', currentUser.uid, 'hasSetPassword', true)
        }, 3000)
      }
    })
    .catch((error) => {
      // Handle errors
      var errors = []
      var errorMessage = error.message

      if (error.code === 'auth/user-not-found') {
        errorMessage = 'No account was found with that email.'
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'Wrong password.'
      }

      errors.push(errorMessage)

      if (buttonState) {
        buttonState.setState({
          success: false,
          errors: errors,
        })
      }

      if (setErrors) {
        setErrors(errors)
      }
    })
}

// CLIENTS
export function addAccount(
  type,
  email,
  password,
  user,
  itemState = null,
  cps = null
) {
  if (type === 'client') {
    secondaryAuth
      .createUserWithEmailAndPassword(email, password)
      .then(function (data) {
        // Add client to database
        addClientToDB(user, data.user.uid, itemState)
      })
      .catch(function (error) {
        // Handle errors
        // console.log(error)
        var errors = itemState.state.errors

        if (error) {
          errors.push(error.message)
        }

        itemState.setState({
          errors: errors,
        })
      })
  } else {
    firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then(function (data) {
        if (type === 'firm' && itemState) {
          // Add firm ID to RegisterFirm state
          itemState.setState(
            {
              firmID: data.user.uid,
            },
            function () {
              // Add firm to database
              addFirmToDB(user, data.user.uid, itemState, email)

              // Add firm CPS to database
              addCPSToDB(data.user.uid, cps)
            }
          )
        }

        if (type === 'advisor') {
          // Add advisor to database
          addAdvisorToDB(user, data.user.uid, itemState)
        }
      })
      .catch(function (error) {
        // Handle errors
        // console.log(error)
        var errors = itemState.state.errors

        if (error) {
          errors.push(error.message)
        }

        itemState.setState({
          errors: errors,
        })
      })
  }
}
