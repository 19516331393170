import React, { memo, useState, Fragment } from 'react'
import { Button } from 'semantic-ui-react'
import ReactTooltip from 'react-tooltip'
import { RetryButton } from '../'
import { RetryIcon } from 'components/icons'
import { getSigningUrl } from 'actions/integrations/docusign'
import { getRecipientSignerID } from 'actions/integrations/docusign/_utils'
import { useUser } from 'contexts'
import styled from 'styled-components'
import { colors } from 'styles'
import { copyToClipboard } from 'helpers/_utils'

const RetrySigningButton = memo(
  ({ envelopeID, signerRole, recipient, signerStatus, disabledByOrder }) => {
    const { userType } = useUser()

    const [retryRequested, setRetryRequested] = useState(false)
    const [signingUrl, setSigningUrl] = useState(null)
    const [signingWindow, setSigningWindow] = useState()

    let recipientObject = recipient
    recipientObject['recipientId'] =
      recipient.recipientId || getRecipientSignerID(signerRole)

    const openSigningWindow = () => {
      const currentWindow = window.open(
        signingUrl,
        recipient.email,
        'scrollbars,width=' +
          parseInt(window.innerWidth) * 0.8 +
          ',height=' +
          parseInt(window.innerHeight) * 0.8
      )
      setSigningWindow(currentWindow)
    }

    if (signerStatus === 'Signed' && signingWindow && !signingWindow.closed) {
      signingWindow.close()
    }

    const requestSigningUrl = () => {
      setRetryRequested(true)
      getSigningUrl({ envelopeID, recipient: recipientObject, setSigningUrl })
    }

    const copySigningUrl = () => {
      copyToClipboard(signingUrl)
    }

    if (
      signerStatus === 'Signed' ||
      (userType === 'client' &&
        signerRole !== 'client_1' &&
        signerRole !== 'client_2')
    ) {
      return ''
    }

    return (
      <Fragment>
        <RetryButton
          onClick={signingUrl ? openSigningWindow : requestSigningUrl}
          disabled={disabledByOrder || (retryRequested && !signingUrl)}
          data-tip={
            signingUrl
              ? `Click here for ${recipient.name} to sign now`
              : `Lost or expired signing link for ${recipient.name}? Click here to regenerate a new one.`
          }
          loading={retryRequested && !signingUrl}
          compact
          basic
        >
          {signingUrl ? 'Sign' : <RetryIcon />}
        </RetryButton>

        {signingUrl && (
          <>
            <CopyButton
              className="link"
              icon="copy outline"
              data-tip={`Click here to copy the link to your clipboard which you can send directly to ${recipient.name}. Anyone with this link will be able to sign as ${recipient.name} so please treat it like a password.`}
              onClick={copySigningUrl}
              compact
              basic
            />
            <ReactTooltip place="right" type="info" effect="solid" />
          </>
        )}
        <ReactTooltip place="right" type="info" effect="solid" />
      </Fragment>
    )
  }
)

export default RetrySigningButton

const CopyButton = styled(Button)`
  &&& {
    box-shadow: none;
    padding: 0 !important;
    margin-left: 12px;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    i {
      color: ${colors.primary};
    }
  }
`
