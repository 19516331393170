import React, { memo, useState } from 'react'
import { Modal } from 'semantic-ui-react'
import {
  CalendlyDetailsForm,
  PractifiIntegrationForm,
  RedtailAuthenticationForm,
  RedtailDeauthenticationForm,
  RiskalyzeIntegrationForm,
  SalesforceIntegrationForm,
  WealthboxIntegrationForm,
} from 'components/forms/integrations'
import {
  integrationServices,
  renderServiceLogo,
} from '../../../config/integrationsConfig'
import {
  authenticateRedtailUser,
  deauthenticateRedtailUser,
} from '../../../actions/integrations/redtail/authActions'
import { useUser, useFirm } from '../../../contexts'
import { useItem } from '../../../hooks'
import styled from 'styled-components'
import { colors } from 'styles'

export default memo(function IntegrationModal({
  service = null,
  handleClose = null,
  passedIntegrations = null,
}) {
  const { uid } = useUser()
  const { firm } = useFirm()

  const [activeService, setActiveService] = useState(service)
  const [errors, setErrors] = useState([])

  let integrations = useItem({
    itemType: 'integration',
    uid,
    load: !passedIntegrations,
  })
  if (passedIntegrations) {
    integrations = passedIntegrations
  }

  const handleCloseModal = () => {
    setErrors([])
    setActiveService(null)
    if (handleClose) {
      handleClose()
    }
  }
  const integrationLoginUser = (type, email, password) => {
    switch (type) {
      case 'redtail':
        authenticateRedtailUser(email, password, uid, setErrors)
        break
      default:
        break
    }
  }

  const integrationLogoutUser = (type) => {
    switch (type) {
      case 'redtail':
        deauthenticateRedtailUser(uid)
        break
      default:
        break
    }
  }

  const renderIntegrationModal = (type) => {
    switch (type) {
      case 'calendly':
        return <CalendlyDetailsForm uid={uid} userType="firm" user={firm} />
      case 'practify':
        return (
          <PractifiIntegrationForm
            uid={uid}
            salesforce={integrations && integrations.salesforce}
          />
        )
      case 'redtail':
        if (
          integrations &&
          integrations.redtail &&
          integrations.redtail.userKey
        ) {
          return (
            <RedtailDeauthenticationForm
              handleDeauth={() => integrationLogoutUser(type)}
            />
          )
        }
        return (
          <RedtailAuthenticationForm
            type={activeService}
            handleLogin={integrationLoginUser}
            loginErrors={errors}
          />
        )
      case 'riskalyze':
        return (
          <RiskalyzeIntegrationForm
            uid={uid}
            integration={integrations && integrations.riskalyze}
          />
        )
      case 'salesforce':
        return (
          <SalesforceIntegrationForm
            uid={uid}
            integration={integrations && integrations.salesforce}
          />
        )
      case 'wealthbox':
        return (
          <WealthboxIntegrationForm
            uid={uid}
            integration={integrations && integrations.wealthbox}
            salesforceIntegration={integrations && integrations.salesforce}
          />
        )
      default:
        return ''
    }
  }

  return (
    <StyledModal
      closeIcon
      onClose={handleCloseModal}
      open={Boolean(activeService)}
    >
      <Modal.Content>
        <ServiceLogo>
          {renderServiceLogo(integrationServices[activeService])}
        </ServiceLogo>
        <h2>
          Integrate with{' '}
          {integrationServices[activeService] &&
          integrationServices[activeService].name
            ? integrationServices[activeService].name
            : 'a service'}
        </h2>
        {renderIntegrationModal(activeService)}
      </Modal.Content>
    </StyledModal>
  )
})

const StyledModal = styled(Modal)`
  max-width: 480px;
  padding: 2em;

  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
  }

  button {
    border-radius: 4px;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    margin-top: 2em;
    width: 100%;
    display: block;

    &.remove,
    &.deauthenticate {
      background: ${colors.warning};

      &:hover {
        opacity: 0.7;
        transition: 0.3s all;
      }
    }
  }

  p {
    text-align: center;
  }

  .submit-section {
    margin-bottom: 0;
  }
`

const ServiceLogo = styled.div`
  text-align: center;
  margin-bottom: 2em;

  img {
    max-width: 300px;
    max-height: 160px;
  }
`
