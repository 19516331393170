import firebase from 'firebase/app'

import { firebaseDB, firebaseFunctions } from '../../../config/firebaseConfig'

const checkAPIKeyFn = firebaseFunctions.httpsCallable(
  'integrationsWealthboxCheckApiKey'
)

export const saveAPIKey = async (uid, apiKey) => {
  const result = await checkAPIKeyFn({ uid, apiKey })

  return result.data
}

export const removeAPIKey = (uid) =>
  firebaseDB
    .collection('integrations')
    .doc(uid)
    .update({ wealthbox: firebase.firestore.FieldValue.delete() })
