import React, { Component } from 'react'
import FirmLogo from '../../../partials/FirmLogo'
import DocumentSubmit from '../../partials/DocumentSubmit'
import {
  calculateClientAge,
  generateAssetsHeldAwayItems,
  generateInvestmentExperienceItems,
} from '../../../../actions/iacHelpers'
import SuitabilityColumn from './partials/SuitabilityColumn'
import Table from '../../../partials/LegacyTable'

// TODO: [BTS-573] Dynamic suitability form document templates
class JalinskiSuitabilityForm extends Component {
  render() {
    const { firm, advisor, client, errors, handleSubmit } = this.props

    if (firm && advisor && client) {
      return (
        <div className="document-container suitability-form-container">
          <div id="suitability-form" className="document suitability-form">
            <div className="page">
              <div className="masthead">
                <FirmLogo firm={firm} />
                <div className="advisor-info">
                  <span className="label">Advisor: </span>
                  <span className="value">{advisor.name}</span>
                </div>
              </div>

              <h1>Client Information Questionnaire Update</h1>

              <div className="table client-info">
                <div className="column investor-info">
                  <div className="row">
                    <span className="label">Name: </span>
                    <span className="value">{client.legal_name}</span>
                  </div>
                  <div className="row">
                    <span className="label">Age: </span>
                    <span className="value">
                      {calculateClientAge(client.birthdate)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="label">Occupation: </span>
                    <span className="value">
                      {client.occupation ? client.occupation : ''}
                    </span>
                  </div>
                </div>
                <div className="column investor-info">
                  <div className="row">
                    <span className="label">Name:</span>
                    <span className="value">
                      {client.second_investor_legal_name}
                    </span>
                  </div>
                  <div className="row">
                    <span className="label">Age:</span>
                    <span className="value">
                      {client.second_investor_birthdate
                        ? calculateClientAge(client.second_investor_birthdate)
                        : ''}
                    </span>
                  </div>
                  <div className="row">
                    <span className="label">Occupation:</span>
                    <span className="value">
                      {client.second_investor_occupation
                        ? client.second_investor_occupation
                        : ''}
                    </span>
                  </div>
                </div>
              </div>

              <div className="table financials-info">
                <SuitabilityColumn title="Annual Income">
                  {client.income_range}
                </SuitabilityColumn>

                <SuitabilityColumn title="Net Worth (ex Residence)">
                  {client.net_worth}
                </SuitabilityColumn>

                <SuitabilityColumn title="Liquid Net Worth">
                  {client.net_worth_liquid}
                </SuitabilityColumn>
              </div>

              <div className="table tax-assets">
                <SuitabilityColumn title="Tax Bracket">
                  {client.tax_bracket_input || ''}
                </SuitabilityColumn>

                <SuitabilityColumn title="Assets Held Away ($)">
                  <ul className="informationTable">
                    {generateAssetsHeldAwayItems(client.assets_held_away)}
                  </ul>
                </SuitabilityColumn>
              </div>

              <div className="table expenses">
                <SuitabilityColumn title="Expenses">
                  {client.expenses}
                </SuitabilityColumn>

                <SuitabilityColumn title="Special Expenses">
                  {client.expenses_special}
                </SuitabilityColumn>

                <SuitabilityColumn title="Special Expenses Timeframe">
                  {client.expenses_special_timeframe}
                </SuitabilityColumn>
              </div>
            </div>

            <div className="page-break"></div>
            <div className="page">
              <div className="table investment-experience">
                <SuitabilityColumn title="Investment Experience (# Years)">
                  <ul className="informationTable">
                    {generateInvestmentExperienceItems(
                      client.investment_experience_items
                    )}
                  </ul>
                </SuitabilityColumn>
              </div>

              <div className="table investment-objectives">
                {/* <SuitabilityColumn title="Investment Objectives">
                  {client.investment_objectives}
                  {client.investment_objectives === 'Other' && client.investment_objectives_other ?
                    <Fragment><span>:</span><div className="description">{client.investment_objectives_other}</div></Fragment>
                    : ''}
                </SuitabilityColumn> */}
                {/* <SuitabilityColumn title="Current Investment Assets that will be managed by Wealth Quarterback, LLC">
                  {client.current_investment_assets_managed}
                </SuitabilityColumn> */}
              </div>

              <div className="table time-horizon-risk">
                <SuitabilityColumn title="Time Horizon to Achieve Investment Objectives">
                  {client.investment_objectives_time_horizon}
                </SuitabilityColumn>
              </div>

              <div className="table custom-fields">
                <SuitabilityColumn title="Electronic Delivery to a different email address?">
                  {client.email_notifications}
                </SuitabilityColumn>

                <SuitabilityColumn title="Strategic Asset Allocation">
                  {client.strategic_asset_allocation}
                </SuitabilityColumn>

                <SuitabilityColumn title="Client Strategic Notes">
                  {client.notes_strategic}
                </SuitabilityColumn>

                <SuitabilityColumn title="Tactical Asset Allocation">
                  {client.tactical_asset_allocation}
                </SuitabilityColumn>

                <SuitabilityColumn title="Tactical Strategy">
                  {client.tactical_strategy}
                </SuitabilityColumn>

                <SuitabilityColumn title="Client Tactical Notes">
                  {client.notes_tactical}
                </SuitabilityColumn>
              </div>

              <div className="table dependents">
                <SuitabilityColumn title="Number of Dependents">
                  {client.dependents_count}
                </SuitabilityColumn>

                <SuitabilityColumn title="Dependents Age(s)">
                  {client.dependents_ages}
                </SuitabilityColumn>
              </div>

              <div className="table professional-contacts">
                <SuitabilityColumn title="Attorney Name">
                  {client.attorney_name}
                </SuitabilityColumn>

                <SuitabilityColumn title="CPA Name">
                  {client.cpa_name}
                </SuitabilityColumn>

                <SuitabilityColumn title="Executor Name">
                  {client.executor_name}
                </SuitabilityColumn>
              </div>

              <div className="table conflicts">
                <SuitabilityColumn title="Are you or an immediate family member an officer or director of a public company?">
                  {client.conflict_public}
                </SuitabilityColumn>

                <SuitabilityColumn title="Are you or an immediate family member employed by a FINRA Firm/Broker Dealer?">
                  {client.conflict_finra}
                </SuitabilityColumn>

                <SuitabilityColumn title="If yes, indicate relationship, firm and occupation">
                  {client.conflict_finra_details}
                </SuitabilityColumn>
              </div>

              <div className="table other">
                <SuitabilityColumn title="Do you depend on the assets in your portfolio to pay for your general routine expenses?">
                  {client.depends_on_portfolio_assets}
                </SuitabilityColumn>

                <SuitabilityColumn title="If yes, please explain">
                  {client.depends_on_portfolio_assets_details}
                </SuitabilityColumn>
              </div>

              <div className="table other">
                <SuitabilityColumn title="Which of the following best describes your long-term investment objective(s)?">
                  {client.investment_objectives}
                </SuitabilityColumn>

                <SuitabilityColumn title="Client notes for further explanation">
                  {client.meeting_notes}
                </SuitabilityColumn>

                <SuitabilityColumn title="Please select your investment Risk Tolerance for your accounts managed by WQL">
                  {client.risk_tolerance}
                </SuitabilityColumn>

                <SuitabilityColumn title="What is your INTENDED investment horizon for your accounts managed by WQL?">
                  {client.intended_investment_horizon}
                </SuitabilityColumn>

                <SuitabilityColumn title="I/We can withstand short-term (1-3 years) declines of 10% or more in my/our Investment Assets in order to achieve my/our long-term investment objective:">
                  {client.can_withstand_market_declines}
                </SuitabilityColumn>

                <SuitabilityColumn title="Please provide details">
                  {client.can_withstand_market_declines_details}
                </SuitabilityColumn>

                <SuitabilityColumn title="Investment Restrictions / Other Important Information">
                  {client.investment_restrictions_other_important_information}
                </SuitabilityColumn>
              </div>

              <div>
                <h2>Trusted Contacts</h2>
                <Table
                  type="columns"
                  headerRow={[
                    { id: 'name', label: 'Name' },
                    { id: 'address', label: 'Address' },
                    { id: 'phone', label: 'Phone' },
                    { id: 'email', label: 'Email' },
                  ]}
                  rows={client.authorized_contacts}
                />
              </div>
            </div>
          </div>

          <DocumentSubmit errors={errors} handleSubmit={handleSubmit} />
        </div>
      )
    } else {
      return <h4>Populating...</h4>
    }
  }
}

export default JalinskiSuitabilityForm
