import FormElements from 'components/forms/tf-fields/FormElements'
import {
  customFieldElements,
  definitions,
  requiredBitsyFields,
} from 'components/forms/tf-fields/_config'
import { legacyProcessSchemaDependenciesForClientAccounts } from 'helpers/legacy'
import { deepSearchByKey, formatArrayString } from 'helpers/_utils'
import { capitalize } from 'actions/helpers'

export const generateFieldJsonSchemaProperties = (
  field,
  bitsyFieldElement,
  allowUserToBypassValidation = false
) => {
  const {
    label,
    text,
    content,
    jsonSchemaDefinition,
    jsonSchemaFormat: fieldJsonSchemaFormat,
    jsonSchemaType: fieldJsonSchemaType,
    readOnly,
    required,
    minItems,
    minimum,
    maximum,
  } = field

  const {
    jsonSchemaFormat,
    jsonSchemaType: bitsyFieldJsonSchemaType,
    jsonSchemaProperties,
  } = bitsyFieldElement

  let fieldJsonSchemaProperties = {}

  const jsonSchemaType = fieldJsonSchemaType || bitsyFieldJsonSchemaType

  switch (jsonSchemaType) {
    case 'array':
      fieldJsonSchemaProperties = {
        type: jsonSchemaType,
        title: label || content || text || '',
        items: field.items || {},
      }
      break
    case 'object':
      fieldJsonSchemaProperties = {
        type: jsonSchemaType,
        title: label || '',
        properties: jsonSchemaProperties,
      }
      break
    case 'number':
      fieldJsonSchemaProperties = {
        type: jsonSchemaType,
        title: label || '',
        minimum: minimum ?? undefined,
        maximum: maximum ?? undefined,
      }
      break
    case 'boolean':
      fieldJsonSchemaProperties = {
        type: jsonSchemaType,
        title: label || '',
        enum: [true, false],
        enumNames: ['true', 'false'],
        default: false,
      }
      break
    default:
      fieldJsonSchemaProperties = {
        type: jsonSchemaType,
        title: label || '',
      }
      break
  }

  // Don't require field.items for advisors/firm in edit/create of
  if (field.items && field.items.required && allowUserToBypassValidation) {
    fieldJsonSchemaProperties.items.required = []
  }

  // Don't pass format if one isn't specified
  if (fieldJsonSchemaFormat || jsonSchemaFormat) {
    fieldJsonSchemaProperties['format'] =
      fieldJsonSchemaFormat || jsonSchemaFormat
  }

  // General rjsf definitions logic
  if (jsonSchemaDefinition && definitions[jsonSchemaDefinition]) {
    fieldJsonSchemaProperties['$ref'] = `#/definitions/${jsonSchemaDefinition}`

    if (definitions[jsonSchemaDefinition].type) {
      fieldJsonSchemaProperties['type'] = definitions[jsonSchemaDefinition].type
    }
  }

  // Readonly fields
  if (readOnly) {
    fieldJsonSchemaProperties['readOnly'] = readOnly
  }

  // Default value
  if (field.defaultValue) {
    fieldJsonSchemaProperties['default'] = field.defaultValue
  }

  // Field options logic
  const hasFieldOptions =
    field.jsonSchemaHasOptions || bitsyFieldElement.jsonSchemaHasOptions
  if (field.options && hasFieldOptions) {
    if ('string' === typeof field.options) {
      // Standard field options if field.options is passed as a string - DEPRECATED
      fieldJsonSchemaProperties['$ref'] = `#/definitions/${field.options}`
    } else if (field.options.length) {
      // Custom field options logic if field.options is passed as an array
      let fieldOptionValues = []
      let fieldOptionLabels = []

      field.options.forEach((option) => {
        const { text, value } = option
        fieldOptionValues.push(value)
        fieldOptionLabels.push(text)
      })

      // Generate schema items for Checkboxes with specified array jsonSchemaType
      if ('Checkboxes' === field.element && 'array' === jsonSchemaType) {
        if (fieldOptionValues.length === 1) {
          fieldJsonSchemaProperties.type = 'boolean'
          fieldJsonSchemaProperties.title = label || ''
          fieldJsonSchemaProperties.enum = [true, false]
          fieldJsonSchemaProperties.enumNames = ['true', 'false']
          fieldJsonSchemaProperties.default = false
        } else {
          fieldJsonSchemaProperties.uniqueItems = true
          fieldJsonSchemaProperties.items = {
            type: 'string',
            enum: fieldOptionValues,
            enumNames: fieldOptionLabels,
          }

          if (required) {
            fieldJsonSchemaProperties.minItems = minItems || 1
          }
        }
      } else {
        fieldJsonSchemaProperties['enum'] = fieldOptionValues
        fieldJsonSchemaProperties['enumNames'] = fieldOptionLabels
      }
    }
  }

  return fieldJsonSchemaProperties
}

export const generateFieldCustomUiSchema = (
  existingUiSchema = {},
  bitsyFieldElement,
  field,
  value,
  handleInputBlur,
  handleInputChange
) => {
  let fieldCustomUiSchema = existingUiSchema || {}

  const {
    jsonSchemaWidget: bitsyFieldJsonSchemaWidget,
    jsonSchemaType,
  } = bitsyFieldElement

  // Add UI schema for default field elements
  const jsonSchemaWidget = field.jsonSchemaWidget || bitsyFieldJsonSchemaWidget
  if (jsonSchemaWidget === 'checkboxes' && field.options?.length > 1) {
    fieldCustomUiSchema['ui:widget'] = jsonSchemaWidget
  } else if (jsonSchemaWidget && jsonSchemaWidget !== 'checkboxes') {
    fieldCustomUiSchema['ui:widget'] = jsonSchemaWidget
  } else if (
    field.element === 'TextArea' ||
    bitsyFieldElement.key === 'TextArea'
  ) {
    fieldCustomUiSchema['ui:widget'] = 'textarea'
  }

  // Add additional options to standard field elements if passed
  if (field.jsonSchemaOptions) {
    fieldCustomUiSchema['ui:options'] = field.jsonSchemaOptions
  }

  // Make select fields searchable
  if ('Dropdown' === field.element) {
    const existingOptions = fieldCustomUiSchema['ui:options'] || {}
    const existingSemanticOptions = existingOptions.semantic || {}
    fieldCustomUiSchema['ui:options'] = {
      ...existingOptions,
      semantic: {
        ...existingSemanticOptions,
        search: true,
      },
    }
  }

  // Add specified subfield order if one is passed (repeater fields)
  if (field.items && field.items.jsonSchemaOrder) {
    if (fieldCustomUiSchema.items) {
      fieldCustomUiSchema.items = {
        ...fieldCustomUiSchema.items,
        'ui:order': field.items.jsonSchemaOrder,
      }
    } else {
      fieldCustomUiSchema.items = {
        'ui:order': field.items.jsonSchemaOrder,
      }
    }
  }

  // Add UI schema for custom field elements
  const staticFieldElements = ['Header', 'Label', 'Paragraph']
  if (
    Object.keys(customFieldElements).includes(bitsyFieldElement.key) ||
    staticFieldElements.includes(bitsyFieldElement.key)
  ) {
    const CustomWidget = FormElements[bitsyFieldElement.key]
    if (CustomWidget) {
      fieldCustomUiSchema = {
        ...fieldCustomUiSchema,
        'ui:widget': CustomWidget,
        'ui:options': {
          data: {
            ...field,
          },
          value: value,
          onBlur: handleInputBlur,
          onChange: handleInputChange,
        },
        validateOnBlur: true,
      }

      if ('object' === jsonSchemaType) {
        fieldCustomUiSchema = {
          ...fieldCustomUiSchema,
          'ui:field': CustomWidget,
        }
      }
    }
  }

  return fieldCustomUiSchema
}

const prettyFieldName = (rjsfProperty) => {
  let fieldLabel = ''

  const fieldNameParts = rjsfProperty.replace().split('_')

  fieldNameParts.forEach((part, pidx) => {
    let processedPart = ''

    // Remove leading period in field name
    if (0 === pidx && part.startsWith('.')) {
      processedPart = processedPart.replace('.', '')
    }

    // Handle repeater lines
    const partParts = part.split(/[[\d+\].]/g)
    if (partParts.length > 1) {
      // It is a field within a repeater
      partParts.forEach((partPart, ppidx) => {
        processedPart += capitalize(partPart)
        if (partPart && ppidx < partParts.length - 1) {
          processedPart += ' '
        }

        if (!partPart && !processedPart.includes(' - ') && 0 !== pidx) {
          processedPart += ' - '
        }
      })
    } else {
      processedPart = part
    }

    fieldLabel += capitalize(processedPart)

    // Add space
    if (pidx < fieldNameParts.length - 1) {
      fieldLabel += ' '
    }
  })

  return fieldLabel
}

export const processCustomDefinitions = () => {
  let customDefinitions = definitions

  return customDefinitions
}

export const processSchemaDependencies = ({
  properties,
  dependencies,
  client,
}) => {
  let customSchemaDependencies = dependencies

  // Legacy: remove investment_profiles and investment_profiles_quizzes account field options when client has selected accounts_non_retirement or accounts_non_retirement
  customSchemaDependencies = legacyProcessSchemaDependenciesForClientAccounts({
    properties,
    dependencies: customSchemaDependencies,
    client,
  })

  return customSchemaDependencies
}

export const transformCustomErrors = ({ errors, stepProperties }) => {
  let newErrors = {}

  if (Object.keys(errors).length) {
    Object.keys(errors).forEach((fieldID) => {
      // Only include root errors and field errors if field is on this page
      if (
        fieldID === '__errors' ||
        (Object.keys(stepProperties) &&
          Object.keys(stepProperties).length &&
          Object.keys(stepProperties).includes(fieldID))
      ) {
        newErrors[fieldID] = errors[fieldID]
      }
    })
  }

  return newErrors
}

export const transformErrors = ({
  errors,
  stepProperties,
  stepDependencies,
  allowUserToBypassValidation = false,
  value,
}) => {
  return errors.flatMap((error) => {
    let fieldLabel
    switch (error.name) {
      case 'anyOf':
        if (
          allowUserToBypassValidation &&
          !requiredBitsyFields.includes(prettyFieldName(error.property))
        ) {
          return []
        }

        fieldLabel = prettyFieldName(error.property)

        error.message = `Should be one of the allowed values`
        error.stack = `<strong>${fieldLabel}</strong> should be one of the allowed values`

        break
      case 'enum':
        fieldLabel = prettyFieldName(error.property)

        if (error.params && error.params.allowedValues) {
          error.message = `Should be ${formatArrayString(
            error.params.allowedValues,
            'or'
          )}`
          error.stack = `<strong>${fieldLabel}</strong> should be one of the allowed values`
        }
        break
      case 'format':
        fieldLabel = prettyFieldName(error.property)
        const fieldID = error.property.replace('.', '')
        const fieldValue = value[fieldID]

        // Don't show format errors for values coming in as empty strings (behave the same way as if the field was null)
        if (fieldValue === '') {
          return []
        }

        const formatNames = {
          phone: 'a U.S. phone number',
          money: 'a currency amount',
        }

        const formatExamples = {
          phone: '555-555-1234',
          money: '$500,000.00',
        }

        error.message = `Should be formatted as ${
          formatNames[error.params.format] || error.params.format
        }${
          formatExamples[error.params.format]
            ? `, e.g. ${formatExamples[error.params.format]}`
            : ''
        }` // Inline validation message
        error.stack = `<strong>${fieldLabel}</strong> should be formatted as ${
          formatNames[error.params.format] || error.params.format
        }` // Errors list message
        break
      case 'required':
        const fieldName = error.params.missingProperty
        fieldLabel = fieldName

        if (
          allowUserToBypassValidation &&
          !requiredBitsyFields.includes(fieldName)
        ) {
          return []
        }

        // Check for pretty field name
        if (
          stepProperties &&
          stepProperties[fieldName] &&
          stepProperties[fieldName].title
        ) {
          // Check for label from step properties
          fieldLabel = stepProperties[fieldName].title
        } else if (stepDependencies) {
          // Check for label from step dependencies
          const matchingResults = deepSearchByKey(stepDependencies, fieldName)
          if (matchingResults && matchingResults.length) {
            fieldLabel = matchingResults[0][fieldName].title
          }
        }

        error.message = `<strong>${fieldLabel}</strong> is required` // Inline validation message
        error.stack = `Please enter <strong>${fieldLabel}</strong>` // Errors list message
        break
      case 'type':
        if (
          allowUserToBypassValidation &&
          !requiredBitsyFields.includes(prettyFieldName(error.property))
        ) {
          return []
        }

        if (error.params.type === 'boolean') {
          // Check that enum errors haven't been thrown
          const fieldHasEnumError = errors.find(
            (e) => e.property === error.property && e.name === 'enum'
          )
          if (fieldHasEnumError) {
            error = []
          } else {
            fieldLabel = prettyFieldName(error.property)

            const typeNames = {
              boolean: 'true or false',
            }

            error.message = `Should be ${
              typeNames[error.params.type] || error.params.type
            }` // Inline validation message
            error.stack = `<strong>${fieldLabel}</strong> should be ${
              typeNames[error.params.type] || error.params.type
            }` // Errors list message
          }
        }
        break
      default:
        break
    }

    return error
  })
}
