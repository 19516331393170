import React, { memo, useState } from 'react'
import { Input, List, Confirm, Message } from 'semantic-ui-react'
import Loader from 'components/partials/Loader'
import ErrorsList from 'components/partials/ErrorsList'
import updateClientEmail from 'actions/db/clients/updateEmail'
import { validEmailRegex } from 'config/regexPatterns'
import styled from 'styled-components'

const UpdateClientEmailConfirm = memo(({ open, handleClose, clientID }) => {
  const [showEmailInput, setShowEmailInput] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [processing, setProcessing] = useState(false)
  const [success, setSuccess] = useState(null)
  const [errors, setErrors] = useState([])

  const handleCloseConfirm = () => {
    handleClose()
    resetState()
  }

  const resetState = () => {
    setShowEmailInput(false)
    setNewEmail('')
    setProcessing(false)
    setSuccess(null)
    setErrors([])
  }

  const submitNewEmail = async () => {
    setErrors([])
    setProcessing(true)

    if (newEmail && validEmailRegex.test(newEmail)) {
      const updateRes = await updateClientEmail({
        clientID,
        email: newEmail,
      })

      if (updateRes && updateRes.data) {
        switch (updateRes.data.type) {
          case 'success':
            setSuccess(true)
            break
          case 'failure':
            setErrors([
              "There was an error updating the client's email. Please make sure this email is not already in use by another client, and contact Bitsy if the problem persists.",
            ])
            break
          case 'error':
            setErrors([updateRes.data.error.message])
            break
          default:
            break
        }
      }
    } else {
      setErrors(['Please enter a valid email address.'])
    }

    setProcessing(false)
  }

  return (
    <UpdateEmailConfirm
      open={open}
      content={
        <ConfirmContents>
          <p>
            <strong>Updating this client's email address:</strong>
          </p>
          <List bulleted>
            <List.Item>
              <strong>will</strong> send all future correspondence to this
              client through Bitsy to the new email
            </List.Item>
            <List.Item>
              <strong>will</strong> change the client's Bitsy dashboard login
              details
            </List.Item>
            <List.Item>
              <strong>will</strong> only get/update the client in integrations
              if the outside service is using the new email address
            </List.Item>
            <List.Item>
              <strong>will not</strong> affect existing forms and documents out
              to the client for signing
            </List.Item>
          </List>
          <p>
            Please note you will have to let your client know they should now
            log in with this email. Their password will not change.
          </p>
          {showEmailInput ? (
            <div style={{ textAlign: 'center' }}>
              <p>
                <strong>Please enter the client's new email below:</strong>
              </p>
              <Input
                type="email"
                placeholder="e.g. newemail@example.com"
                onChange={(e, { value }) => {
                  setNewEmail(value)
                }}
                value={newEmail}
                style={{
                  display: 'block',
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
                disabled={processing}
              />
            </div>
          ) : (
            <>
              <p>Are you sure you want to do this?</p>
            </>
          )}
          {processing && <Loader message="Updating email..." />}
          <ErrorsList errors={errors} />
          {success && (
            <Message
              success
              header="We've updated this client's email!"
              content={`Please be sure to let them know they can log into Bitsy with ${newEmail} and their current password.`}
            />
          )}
        </ConfirmContents>
      }
      confirmButton={
        success ? null : showEmailInput ? 'Update email' : "Yes, I'm sure"
      }
      onConfirm={
        showEmailInput ? submitNewEmail : () => setShowEmailInput(true)
      }
      cancelButton={success ? 'OK!' : 'Never mind!'}
      onCancel={handleCloseConfirm}
    />
  )
})

export default UpdateClientEmailConfirm

const UpdateEmailConfirm = styled(Confirm)``

const ConfirmContents = styled.div`
  padding: 30px;
`
