import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import Modal from './LegacyModal'
import InvestmentAdvisoryContract from '../documents/iacs/InvestmentAdvisoryContract'
import SuitabilityForm from '../documents/suitability/SuitabilityForm'

class DocumentReviewModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      instructionsOpen: false,
    }
  }

  componentDidMount() {
    const { type, isOpen } = this.props

    if (isOpen) {
      if (
        type === 'send' ||
        type === 'client-sign' ||
        type === 'advisor-sign'
      ) {
        this.setState({
          instructionsOpen: true,
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { type, isOpen } = this.props

    if (isOpen) {
      if (prevProps.type !== type && type === 'send') {
        this.setState({
          instructionsOpen: true,
        })
      }
    }
  }

  render() {
    const {
      documentType,
      documentTypeName,
      isOpen,
      handleModalClose,
      source,
      location,
      type,
      firmID,
      firm,
      advisor,
      client,
      firmDisclosures,
      advisorDisclosures,
      uid,
      state,
      doc,
      taskDetails,
    } = this.props
    const { instructionsOpen } = this.state

    return (
      <Modal isOpen={isOpen} handleClose={handleModalClose} fullScreen={true}>
        {documentType === 'iac' ? (
          <InvestmentAdvisoryContract
            source={source}
            location={location}
            type={type}
            firmID={firmID}
            firm={firm}
            advisor={advisor}
            client={client}
            firmDisclosures={firmDisclosures}
            advisorDisclosures={advisorDisclosures}
            uid={uid}
            state={state}
          />
        ) : documentType === 'suitability' ? (
          <SuitabilityForm
            doc={doc}
            source={source}
            location={location}
            type={type}
            firmID={firmID}
            firm={firm}
            advisor={advisor}
            client={client}
            firmDisclosures={firmDisclosures}
            advisorDisclosures={advisorDisclosures}
            uid={uid}
            state={state}
            taskDetails={taskDetails}
          />
        ) : (
          ''
        )}

        <Modal isOpen={instructionsOpen} className="instructions-modal">
          Please confirm everything is correct before sending.
          <br />
          Click Submit at the bottom of the {documentTypeName} when you are
          ready to send.
          <Button
            content="Got it, let's review!"
            primary
            onClick={() => this.setState({ instructionsOpen: false })}
          />
        </Modal>
      </Modal>
    )
  }
}

export default DocumentReviewModal
