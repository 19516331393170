import React from 'react'
import { functionsBase } from '../../../config/firebaseConfig'
import { deauthenticateRiskalyze } from '../../../actions/integrations/riskalyze/deauthenticate'

const functionLink = functionsBase + '/integrationsRiskalyzeAuthOauth'

const RiskalyzeIntegrationForm = ({ uid, integration }) => (
  <div className={'integration-details integration-details-riskalyze'}>
    {integration && (
      <p>You are authenticated with Riskalyze! We'll take it from here.</p>
    )}
    <div className="form-group submit-section">
      {integration ? (
        <button
          className="continue"
          type="button"
          onClick={() => deauthenticateRiskalyze(uid)}
        >
          Remove integration
        </button>
      ) : (
        <a
          className="continue"
          type="button"
          href={functionLink + `?state=${uid}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Continue
        </a>
      )}
    </div>
  </div>
)

export default RiskalyzeIntegrationForm
