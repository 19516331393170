import firebase from 'firebase/app'

import { functionsBase, firebaseDB } from '../../../config/firebaseConfig'
import { navigate } from '@reach/router'

export async function authenticateSalesforce(userID) {
  const functionLink = functionsBase + '/integrationsSalesforceAuthOauth2Auth'

  const res = await fetch(functionLink, {
    method: 'POST',
    body: JSON.stringify({
      userID,
    }),
  })

  const data = await res.json()

  if (data && data.body && data.body.authUrl) {
    navigate(data.body.authUrl)
  }
}

export function deauthenticateSalesforce(userID) {
  firebaseDB
    .collection('integrations')
    .doc(userID)
    .update({ salesforce: firebase.firestore.FieldValue.delete() })
}
