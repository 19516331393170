import React, { memo, useState, useEffect } from 'react'
import { Button, Confirm } from 'semantic-ui-react'
import Loader from 'components/partials/Loader'
import { deleteDocFile } from 'actions/db/_helpers'
import styled from 'styled-components'
import { colors } from 'styles'

const DeleteDocFileButton = memo(({ userType, uid, property, ...props }) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (success) {
      setLoading(false)
      setConfirmModalOpen(false)
    }
  }, [success])

  const handleDeleteFile = async () => {
    setLoading(true)
    deleteDocFile({ userType, uid, property, setSuccess })
  }

  return (
    <>
      <Button
        negative
        content="Delete file"
        size="tiny"
        icon="trash"
        onClick={() =>
          setConfirmModalOpen((confirmModalOpen) => !confirmModalOpen)
        }
        {...props}
      />
      <NegativeConfirm
        open={confirmModalOpen}
        content={
          <ConfirmContents>
            {loading ? (
              <Loader message="Deleting..." />
            ) : (
              <>
                <p>
                  <strong>This will permanently delete this file.</strong>
                </p>
                <p>Are you sure?</p>
              </>
            )}
          </ConfirmContents>
        }
        confirmButton="Yes, delete it."
        onConfirm={handleDeleteFile}
        cancelButton="Never mind!"
        onCancel={() => setConfirmModalOpen(false)}
      />
    </>
  )
})

export default DeleteDocFileButton

const NegativeConfirm = styled(Confirm)`
  &&& {
    button.primary {
      background: ${colors.negative};

      &:hover,
      &:focus {
        background: ${colors.negativeHover};
      }
    }
  }
`

const ConfirmContents = styled.div`
  padding: 30px;
`
