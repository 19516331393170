import React, { memo } from 'react'
import logo from 'assets/images/bitsy-advisor-logo.png'

const BitsyLogo = memo(() => (
  <a href="https://bitsyadvisor.com" title="Bitsy Advisor">
    <img src={logo} alt="Bitsy" />
  </a>
))

export default BitsyLogo
