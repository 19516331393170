import { firebaseDB, prospectDB } from '../../config/firebaseConfig'
// import crypto from 'crypto'

export function addProspectToDB(client, state) {
  return firebaseDB
    .collection('prospects')
    .add(client)
    .then(function (docRef) {
      state.setState({
        addSuccess: true,
        addedUID: docRef.id,
      })

      // console.log(crypto)

      // console.log("Prospect added with ID: ", docRef.id)

      return docRef.id
    })
    .catch(function (error) {
      // console.error("Error adding client: ", error)
      state.setState({
        errors: ['Error adding client: ' + error],
      })

      return null
    })
}

export function prospectAddProspectToDB(client, state) {
  return prospectDB
    .collection('prospects')
    .add(client)
    .then(function (docRef) {
      state.setState({
        addSuccess: true,
        addedUID: docRef.id,
      })

      // console.log("Prospect added with ID: ", docRef.id)

      return docRef.id
    })
    .catch(function (error) {
      // console.error("Error adding client: ", error)
      state.setState({
        errors: ['Error adding client: ' + error],
      })

      return null
    })
}

let unsubscribeFirmProspects
export function getFirmProspects(firm, state) {
  unsubscribeFirmProspects = firebaseDB
    .collection('prospects')
    .where('firm', '==', firm)
    .onSnapshot(function (querySnapshot) {
      const changes = querySnapshot.docChanges()

      if (changes.length > 0) {
        var prospects = {}

        querySnapshot.forEach(function (doc) {
          prospects[doc.id] = doc.data()
        })

        state.setState({
          prospects: prospects,
        })
      }
    })
}
export function stopListeningToFirmProspects() {
  // Stop listening to changes
  unsubscribeFirmProspects()
}

export function prospectUpdateProspectInDB(client, uid, state) {
  prospectDB
    .collection('prospects')
    .doc(uid)
    .update(client)
    .then(function (docRef) {
      state.setState({
        addSuccess: true,
      })

      console.log('Prospect updated with ID: ', docRef)
    })
    .catch(function (error) {
      // console.error("Error adding prospect: ", error)
      state.setState({
        errors: ['Error adding prospect: ' + error],
      })
    })
}
