import React from 'react'
import { Checkbox, Form } from 'semantic-ui-react'
import DisclosuresList from 'components/partials/DisclosuresList'

const DisclosuresAgreement = ({
  acceptDisclosures,
  additionalDocuments,
  handleDisclosuresAgreement,
}) => {
  return (
    <>
      <DisclosuresList disclosures={additionalDocuments} />
      <Form.Field>
        <Checkbox
          defaultChecked={acceptDisclosures}
          label="I agree that I have read and received these disclosures."
          onClick={handleDisclosuresAgreement}
          data-id="disclosures-agreement"
        />
      </Form.Field>
    </>
  )
}
export default DisclosuresAgreement
