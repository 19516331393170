export const appUrlBase = 'https://app.bitsyadvisor.com'
export const pentestAppUrlBase = 'https://pentest.bitsyadvisor.com'

export const availableServices = {
  onboardingModule: 'Onboarding Module',
  suitabilityForm: 'Suitability Form',
  k401: '401(k) Agreement',
  autoADV: 'Auto-ADV',
}

export const bitsyEmails = {
  sales: 'sales@bitsyadvisor.com',
}
