import { firebaseDB, prospectDB } from '../../config/firebaseConfig'

export function getAdvisorDetails(advisor, state = null, source = null) {
  var db = firebaseDB
  if (source === 'prospect') {
    db = prospectDB
  }

  return db
    .collection('advisors')
    .doc(advisor)
    .get()
    .then((doc) => {
      if (doc.exists) {
        if (state) {
          state.setState({
            ...state.state,
            advisor: doc.data(),
          })
        }

        return doc.data()
      } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!")
        return null
      }
    })
    .catch(function (error) {
      // console.error("Error getting document:", error)
      return null
    })
}
