import React, { memo, useState, useEffect } from 'react'
import Field from '../../Field'
import { getTemplate } from '../../../../actions/db/templates'
import { firmIDs } from '../../../../config/firmConfig'
import '../forms/IACForm.scss'

function compare(operator, operand1, operand2) {
  switch (operator) {
    case '<':
      return operand1 < operand2
    case '<=':
      return operand1 <= operand2
    case '>=':
      return operand1 >= operand2
    case '>':
      return operand1 > operand2
    case '!==':
      return String(operand1) !== String(operand2)
    case '===':
      return String(operand1) === String(operand2)
    default:
      return false
  }
}

const FormFields = memo(
  ({ firmID, templateID, value, reviewType = null, handleInputChange }) => {
    const [template, setTemplate] = useState()

    useEffect(() => {
      console.log('In form fields, templateID' + templateID)

      getTemplate({ firmID, templateID, setTemplate })
    }, [firmID, templateID])

    if (!template || !template.fields || !template.fields.length) {
      return 'No fields found for this template. Please make sure some are set in the Form Builder.'
    }
    console.log('template.fields', template.fields)
    return (
      <>
        {template.fields.map((field, idx) => {
          const {
            key,
            type,
            label,
            options,
            required,
            showLabel,
            instructional,
            displayRule,
          } = field

          let display = true
          if (displayRule) {
            display = compare(
              displayRule.operator,
              value[displayRule.key],
              displayRule.value
            )
          }

          if (!display) {
            return ''
          }

          if (type === 'heading') {
            return <h3 key={key || idx}>{label}</h3>
          }

          if (type === 'disclaimer') {
            return <p key={key || idx}>{label}</p>
          }

          let filteredOptions = options

          // Jalinski customizations
          if (
            firmID === firmIDs['jalinski'] &&
            key === 'risk_tolerance' &&
            value['tactical_strategy'] &&
            value['tactical_strategy'] !== 'None'
          ) {
            filteredOptions = ['Moderate', 'Moderate-Aggressive', 'Aggressive']
          }

          let adjustedRequired = required
          if (required === 'client-sign') {
            if (reviewType && reviewType === 'client-sign') {
              adjustedRequired = true
            } else {
              adjustedRequired = false
            }
          }

          return (
            <Field
              key={key || idx}
              type={type}
              id={key}
              label={label}
              value={value[key]}
              options={filteredOptions}
              required={adjustedRequired}
              showLabel={showLabel}
              instructional={instructional}
              handleInputChange={handleInputChange}
            />
          )
        })}
      </>
    )
  }
)

export default FormFields
