import React, { memo, useState } from 'react'
import { Form, Checkbox, Input } from 'semantic-ui-react'
import { useFirm } from 'contexts'
import { updateItemInfo } from 'actions/db/dbActions'
import styled from 'styled-components'

const SigningReminderSettings = memo(() => {
  const { firm, firmID } = useFirm()

  const [enabled, setEnabled] = useState(
    firm.signingReminderSettings?.enabled || false
  )
  const [delay, setDelay] = useState(firm.signingReminderSettings?.delay || 3)
  const [frequency, setFrequency] = useState(
    firm.signingReminderSettings?.frequency || 3
  )

  const handleToggleEnabled = async () => {
    let newSettings
    if (firm.signingReminderSettings) {
      newSettings = firm.signingReminderSettings
      newSettings.enabled = !enabled
    } else {
      // Default settings
      newSettings = {
        enabled: !enabled,
        delay,
        frequency,
      }
    }

    await updateItemInfo('firm', firmID, 'signingReminderSettings', newSettings)

    setEnabled(!enabled)
  }

  const handleInputChange = async (key, value) => {
    const numValue = parseInt(value)

    await updateItemInfo(
      'firm',
      firmID,
      `signingReminderSettings.${key}`,
      numValue
    )

    switch (key) {
      case 'delay':
        setDelay(numValue)
        break
      case 'frequency':
        setFrequency(numValue)
        break
      default:
        break
    }
  }

  return (
    <Form>
      <Form.Field>
        <Checkbox
          toggle
          checked={enabled}
          label="Use custom settings"
          onChange={handleToggleEnabled}
        />
      </Form.Field>

      {enabled && (
        <>
          <NumberField inline>
            <label>How long to wait before sending first reminder?</label>
            <NumberInput
              value={delay}
              label="days"
              labelPosition="right"
              type="number"
              fluid
              placeholder="3"
              onChange={(e, { value }) => handleInputChange('delay', value)}
            />
          </NumberField>
          <NumberField inline>
            <label>How often to send reminders?</label>
            <NumberInput
              value={frequency}
              label="days"
              labelPosition="right"
              type="number"
              fluid
              placeholder="3"
              onChange={(e, { value }) => handleInputChange('frequency', value)}
            />
          </NumberField>
        </>
      )}
    </Form>
  )
})

export default SigningReminderSettings

const NumberField = styled(Form.Field)`
  &&& {
    display: flex;
    justify-content: space-between;

    & > label {
      flex-grow: 1;
    }

    & > .input {
      flex-grow: 0;
    }
  }
`

const NumberInput = styled(Input)`
  &&& {
    display: inline-flex;

    & > input {
      width: auto !important;
      min-width: 16px;
      max-width: 60px;
      text-align: center;
      padding-right: 0;
    }
  }
`
