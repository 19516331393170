import React, { PureComponent, Fragment } from 'react'
import { Button } from 'semantic-ui-react'
import MaskedInput from 'react-text-mask'
import ReactTooltip from 'react-tooltip'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import FundAllocator from './tf-fields/FundAllocator'
import { CopyIcon, RemoveIcon } from '../icons'
import {
  phoneMask,
  numberMoneyMask,
  numberMoneyHourMask,
  numberMoneyMonthMask,
  numberPercentageMask,
} from '../../config/maskConfig'
import { custodian_options } from '../../config/options'
import './Field.scss'
import styled from 'styled-components'
import { copyToClipboard } from 'helpers/_utils'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
})

const getRangeOfValue = (value, rangeOptions) => {
  if (!value || !rangeOptions || typeof value !== 'string') {
    return null
  }

  const valueNumber = parseFloat(value.replace('$', '').replace(/,/g, ''))

  if (!valueNumber) {
    return null
  }

  let containingRange

  rangeOptions.forEach((range) => {
    let rangeMin
    let rangeMax
    if (range.includes(' - ')) {
      const rangeParts = range.split(' - ')
      rangeMin = parseFloat(rangeParts[0].replace('$', '').replace(/,/g, ''))
      rangeMax = parseFloat(rangeParts[1].replace('$', '').replace(/,/g, ''))
    } else if (range.includes('<')) {
      // Range is the lowest range
      rangeMin = 0
      rangeMax = parseFloat(
        range.replace('<', '').replace('$', '').replace(/,/g, '')
      )
    } else if (range.includes('+')) {
      // Range is the highest range
      rangeMin = parseFloat(
        range.replace('+', '').replace('$', '').replace(/,/g, '')
      )
      rangeMax = 99999999999999999999999999999999999999999999999999999999999999999999999999999999
    }

    if (valueNumber >= rangeMin && valueNumber <= rangeMax) {
      containingRange = range
    }
  })

  return containingRange
}

const account_type_options = ['Joint TOD', 'Inherited IRA']
const age_range_options = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
const communication_options = ['Call', 'Text', 'Email']
const custodian_name_options = Object.keys(custodian_options).map(
  (custodianID) => custodian_options[custodianID].preferred_name
)
const experience_options = [
  'No Experience',
  'Minimal Experience',
  'Moderately Experienced',
  'Highly Experienced',
]
const fee_type_options = ['Percentage Fee', 'Fixed Fee']
const investment_objective_options = [
  'Capital Preservation',
  'Income',
  'Income and Growth',
  'Capital Growth',
  'Aggressive Growth',
]
const investment_profile_options = [
  'Conservative',
  'Moderate',
  'Aggressive',
  'Speculative',
]
const jurisdiction_options = ['Discretionary', 'Non-Discretionary']
const marital_status_options = [
  'Married',
  'Unmarried',
  'Domestic Partner',
  'Other',
]
const money_5_10_options = [
  '$1 - $5,000',
  '$5,001 - $10,000',
  '$10,001 - $20,000',
  '$20,001+',
]
const money_20_50_options = [
  '<$20,000',
  '$20,001 - $49,999',
  '$50,000 - $99,999',
  '$100,000 - $249,999',
  '$250,000 - $500,000',
  '$500,001+',
]
const money_10_25_options = [
  '$1 - $10,000',
  '$10,001 - $25,000',
  '$25,001 - $50,000',
  '$50,001+',
]
const money_25_50_options = [
  '$0 - $25,000',
  '$25,001 - $50,000',
  '$50,001 - $100,000',
  '$100,001 - $250,000',
  '$250,001+',
]
const money_25_100_options = [
  '$0 - $25,000',
  '$25,001 - $100,000',
  '$100,001 - $500,000',
  '$500,001 - $1,000,000',
  '$1,000,001+',
]
const money_50_100_options = [
  '$1 - $50,000',
  '$50,001 - $100,000',
  '$100,001 - $250,000',
  '$250,001 - $500,000',
  '$500,001+',
]
const money_250_500_options = [
  '<$250,000',
  '$250,001 - $500,000',
  '$500,001 - $1,000,000',
  '$1,000,001 - $2,000,000',
  '$2,000,001+',
]
const one_through_five_options = [1, 2, 3, 4, 5]
const retirement_age_options = ['<45', '45-54', '55-64', '65-74', '75+']
const state_options = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]
const tax_bracket_options = ['10%', '12%', '22%', '24%', '32%', '35%', '37%']
const tax_bracket_range_options = [
  '15% or below',
  '16% - 28%',
  '29% - 35%',
  'over 35%',
]
const yes_no_options = ['Yes', 'No']
const yes_no_maybe_options = ['Yes', 'No', 'Maybe So']
const field_options = [
  'heading',
  'disclaimer',
  'text',
  'radio',
  'radio-group',
  'checkbox-group',
  'checkbox',
  'select',
  'select-group',
  'select-multiple',
  'switch',
  'combo',
  'money',
  'phone',
  'fund-allocator',
  'money-hour',
  'numberMoneyMonth',
  'percentage',
  'date',
  'number',
  'password',
  'email',
  'url',
  'text-multiple',
  'text-area',
]
const option_types = [
  '1-5',
  'account-type',
  'age-range',
  'communication',
  'custodian',
  'experience',
  'fee-type',
  'investment-objective',
  'investment-profile',
  'jurisdiction',
  'marital-status',
  'money-5-10',
  'money-10-25',
  'money-20-50',
  'money-25-50',
  'money-25-100',
  'money-50-100',
  'money-250-500',
  'retirement-age',
  'state',
  'tax-bracket',
  'tax-bracket-range',
  'yes-no',
  'yes-no-maybe',
  'custom',
]

class Field extends PureComponent {
  constructor(props) {
    super(props)

    this.handleAddMultiLine = this.handleAddMultiLine.bind(this)
    this.handleMultiLineChange = this.handleMultiLineChange.bind(this)
    this.handleRemoveMultiLine = this.handleRemoveMultiLine.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  handleAddMultiLine(event) {
    event.preventDefault()

    const { id, value, textMultipleFields, handleInputChange } = this.props

    var newLine = {}
    textMultipleFields.map((field, idx) => (newLine[field.id] = ''))

    var joinedFields = value.concat([newLine])

    handleInputChange(id, joinedFields)
  }

  handleMultiLineChange = (idx) => (event) => {
    const { id, value, handleInputChange } = this.props

    const target = event.target
    const name = target.name
    const targetValue = target.value

    const newFields = value.map((line, sidx) => {
      if (idx !== sidx) return line
      return { ...line, [name]: targetValue }
    })

    handleInputChange(id, newFields)
  }

  handleRemoveMultiLine = (idx) => (event) => {
    event.preventDefault()

    const { id, value, handleInputChange } = this.props

    var newFields = value.filter((s, sidx) => idx !== sidx)

    handleInputChange(id, newFields)
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const { id, value, handleInputChange } = this.props

    const newFields = reorder(
      value,
      result.source.index,
      result.destination.index
    )

    handleInputChange(id, newFields)
  }

  renderTextMultipleFields(textMultipleFields, value, idx, _this) {
    return textMultipleFields.map((field, fidx) => (
      <Field
        key={'field-' + fidx}
        className={'field field-' + field.id}
        id={field.id}
        type={field.type}
        label={field.label}
        value={field.value || value[idx][field.id] || field.defaultValue}
        handleInputChange={_this.handleMultiLineChange(idx)}
        options={field.options}
        showLabel={
          field.type === 'percentage' || field.showLabel ? true : false
        }
        placeholder={field.placeholder}
        instructional={field.instructional}
        readOnly={field.readOnly || false}
        required={field.required}
      />
    ))
  }

  render() {
    const {
      type,
      id,
      label,
      instructional,
      placeholder,
      value,
      required,
      options,
      textMultipleFields,
      handleInputChange,
      showLabel,
      readOnly,
      tooltip,
      listClassName,
      formGroupClassName,
      textMultipleInitialFields,
      enableCopy,
      sortable,
      skipRangeLogic,
      errors,
      style,
    } = this.props

    // Set up placeholder
    var placeholderText = label
    if (placeholder && placeholder.length) {
      placeholderText = placeholder
    }

    if ((type === 'radio-group' || type === 'checkbox-group') && options) {
      var inputType = 'radio'
      if (type === 'checkbox-group') {
        inputType = 'checkbox'
      }

      this.radio_checkbox_group_fields = options.map((option, idx) => {
        var isChecked = false
        if (type === 'radio-group' && value === option.title) {
          isChecked = true
        }
        if (type === 'checkbox-group' && value.includes(option.title)) {
          isChecked = true
        }

        return (
          <div key={idx} className="form-group">
            <label htmlFor={id + '-' + idx}>
              <input
                type={inputType}
                id={id + '-' + idx}
                name={id}
                value={option.title}
                checked={isChecked}
                onChange={handleInputChange}
                required={required}
              />
              <span className="label">
                {option.title}
                {option.instructional ? (
                  <small className="instructional">
                    {option.instructional}
                  </small>
                ) : (
                  ''
                )}
              </span>
              {option.description &&
              option.description === 'allowInput' &&
              option.inputId &&
              option.inputValue !== null &&
              option.inputValue !== undefined ? (
                <span className="description">
                  <Field
                    type="textarea"
                    id={option.inputId}
                    label={option.title}
                    value={option.inputValue}
                    handleInputChange={handleInputChange}
                  />
                </span>
              ) : option.description ? (
                <span className="description">
                  <small className="instructional">{option.description}</small>
                </span>
              ) : (
                ''
              )}
            </label>
          </div>
        )
      })
    }

    let selectedValue = value
    if (type === 'select' || type === 'select-multiple' || type === 'combo') {
      var optionChoices = options
      switch (options) {
        case '1-5':
          optionChoices = one_through_five_options
          break
        case 'account-type':
          optionChoices = account_type_options
          break
        case 'age-range':
          optionChoices = age_range_options
          break
        case 'communication':
          optionChoices = communication_options
          break
        case 'custodian':
          optionChoices = custodian_name_options
          break
        case 'experience':
          optionChoices = experience_options
          break
        case 'fee-type':
          optionChoices = fee_type_options
          break
        case 'investment-objective':
          optionChoices = investment_objective_options
          break
        case 'investment-profile':
          optionChoices = investment_profile_options
          break
        case 'jurisdiction':
          optionChoices = jurisdiction_options
          break
        case 'marital-status':
          optionChoices = marital_status_options
          break
        case 'money-5-10':
          optionChoices = money_5_10_options
          break
        case 'money-10-25':
          optionChoices = money_10_25_options
          break
        case 'money-20-50':
          optionChoices = money_20_50_options
          break
        case 'money-25-50':
          optionChoices = money_25_50_options
          break
        case 'money-25-100':
          optionChoices = money_25_100_options
          break
        case 'money-50-100':
          optionChoices = money_50_100_options
          break
        case 'money-250-500':
          optionChoices = money_250_500_options
          break
        case 'retirement-age':
          optionChoices = retirement_age_options
          break
        case 'state':
          optionChoices = state_options
          break
        case 'tax-bracket':
          optionChoices = tax_bracket_options
          break
        case 'tax-bracket-range':
          optionChoices = tax_bracket_range_options
          break
        case 'yes-no':
          optionChoices = yes_no_options
          break
        case 'yes-no-maybe':
          optionChoices = yes_no_maybe_options
          break
        case 'field':
          optionChoices = field_options
          break
        case 'options':
          optionChoices = option_types
          break
        default:
          optionChoices = options
      }

      var optionsList = ''
      if (optionChoices && optionChoices.length) {
        optionsList = optionChoices.map((option, i) => {
          var optionValue = option
          var optionLabel = option
          if (
            typeof option === 'object' &&
            option !== null &&
            option.value &&
            option.value.length &&
            option.label &&
            option.label.length
          ) {
            optionValue = option.value
            optionLabel = option.label
          }

          return (
            <option key={i} value={optionValue}>
              {optionLabel}
            </option>
          )
        })
      }

      selectedValue = !skipRangeLogic
        ? getRangeOfValue(value, optionChoices) || value
        : value
    }

    if (type === 'text-multiple' && textMultipleFields) {
      const _this = this

      this.text_multiple_fields = ''
      if (value) {
        if (sortable) {
          this.text_multiple_fields = (
            <DragDropContext onDragEnd={_this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {value.map((line, index) => {
                      console.log(line)
                      console.log(value)
                      this.fields = this.renderTextMultipleFields(
                        textMultipleFields,
                        value,
                        index,
                        _this
                      )
                      return (
                        <Draggable
                          key={line.key}
                          draggableId={line.key}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="remove-button-container">
                                <button
                                  className="remove-button"
                                  onClick={_this.handleRemoveMultiLine(index)}
                                >
                                  <RemoveIcon />
                                </button>
                              </div>
                              {this.fields}
                            </li>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )
        } else {
          this.text_multiple_fields = value.map((line, idx) => {
            this.fields = this.renderTextMultipleFields(
              textMultipleFields,
              value,
              idx,
              _this
            )

            return (
              <li key={idx}>
                <div className="remove-button-container">
                  <button
                    className="remove-button"
                    onClick={_this.handleRemoveMultiLine(idx)}
                  >
                    <RemoveIcon />
                  </button>
                </div>
                {this.fields}
              </li>
            )
          })
        }
      }
    }

    var generatedFormGroupClassName = 'form-group bitsy-form-group'
    if (formGroupClassName) {
      generatedFormGroupClassName += ` ${formGroupClassName}`
    }
    if (enableCopy) {
      generatedFormGroupClassName += ' has-copy-button'
    }

    return (
      <div
        className={generatedFormGroupClassName}
        key={id}
        style={{ ...style }}
      >
        <label
          htmlFor={id}
          className={
            (type === 'money' && showLabel !== false) ||
            type === 'numberMoneyMonth' ||
            showLabel
              ? 'visually-shown'
              : 'visually-hidden'
          }
        >
          {label}
          {instructional ? (
            <small className="instructional">{instructional}</small>
          ) : (
            ''
          )}
        </label>

        <div
          className={
            required && (!value || !value.length)
              ? 'bitsy-input-wrapper to-do'
              : 'bitsy-input-wrapper'
          }
          key={id}
        >
          {type === 'fund-allocator' && (
            <FundAllocator
              id={id}
              value={value}
              setClientFundsAllocationFields={handleInputChange}
            />
          )}

          {type === 'phone' ? (
            <MaskedInput
              mask={phoneMask}
              value={value}
              placeholder={placeholderText}
              name={id}
              id={id}
              onChange={handleInputChange}
              required={required}
            />
          ) : (
            ''
          )}

          {type === 'money' ? (
            <MaskedInput
              mask={numberMoneyMask}
              value={value}
              placeholder={
                placeholder && placeholder.length
                  ? placeholderText
                  : '(ex. $10,000)'
              }
              name={id}
              id={id}
              onChange={handleInputChange}
              required={required}
            />
          ) : (
            ''
          )}

          {type === 'money-hour' && (
            <MaskedInput
              mask={numberMoneyHourMask}
              value={value}
              placeholder={
                placeholder && placeholder.length
                  ? placeholderText
                  : '(ex. $60)'
              }
              name={id}
              id={id}
              onChange={handleInputChange}
              required={required}
            />
          )}

          {type === 'numberMoneyMonth' ? (
            <MaskedInput
              mask={numberMoneyMonthMask}
              value={value}
              placeholder="(ex. $10,000)"
              name={id}
              id={id}
              onChange={handleInputChange}
              required={required}
            />
          ) : (
            ''
          )}

          {type === 'percentage' ? (
            <MaskedInput
              mask={numberPercentageMask}
              value={value}
              placeholder={placeholder ? placeholder : '(ex. 30%)'}
              name={id}
              id={id}
              onChange={handleInputChange}
              required={required}
            />
          ) : (
            ''
          )}

          {type === 'radio-group' || type === 'checkbox-group' ? (
            <Fragment>
              <ul className={type + ' ' + id}>
                {this.radio_checkbox_group_fields}
              </ul>
            </Fragment>
          ) : (
            ''
          )}

          {type === 'select' || type === 'select-multiple' ? (
            <select
              value={selectedValue}
              name={id}
              id={id}
              onChange={handleInputChange}
              multiple={type === 'select-multiple' ? true : false}
              required={required}
            >
              <option value="" disabled>
                {placeholder ? placeholder : label}
              </option>
              {optionsList}
            </select>
          ) : (
            ''
          )}

          {type === 'select-multiple' ? (
            <small className="instructional instructional-select-multiple">
              Check all that apply. CTRL + click to select multiple.
            </small>
          ) : (
            ''
          )}

          {type === 'switch' && (
            <Fragment>
              <div>{label}</div>
              <label htmlFor={id} className="switch">
                <input
                  name={id}
                  id={id}
                  value={value}
                  defaultChecked={value}
                  type="checkbox"
                  onChange={handleInputChange}
                />
                <span className="slider round"></span>
              </label>
            </Fragment>
          )}

          {type === 'checkbox' && (
            <input
              name={id}
              id={id}
              value={value}
              defaultChecked={value}
              type="checkbox"
              onChange={handleInputChange}
            />
          )}

          {(type === 'text' ||
            type === 'date' ||
            type === 'number' ||
            type === 'password' ||
            type === 'email' ||
            type === 'url' ||
            type === 'combo') && (
            <Fragment key={id}>
              <input
                key={id}
                value={value}
                type={type === 'combo' ? 'text' : type}
                name={id}
                id={id}
                placeholder={placeholderText}
                onChange={handleInputChange}
                readOnly={readOnly}
                data-tip={tooltip ? tooltip : ''}
                list={type === 'combo' ? `${id}-datalist` : ''}
                required={required}
              />
              {tooltip && (
                <ReactTooltip place="right" type="info" effect="solid" />
              )}
              {type === 'combo' && options && (
                <datalist id={`${id}-datalist`}>{optionsList}</datalist>
              )}
            </Fragment>
          )}

          {type === 'text-multiple' ? (
            <Fragment>
              <ul
                className={
                  listClassName
                    ? 'text-multiple ' + listClassName
                    : 'text-multiple investment-profiles'
                }
              >
                {textMultipleInitialFields && textMultipleInitialFields}
                {this.text_multiple_fields}
              </ul>

              <div className="add-button">
                <AddLineButton
                  content="Add Line"
                  onClick={this.handleAddMultiLine}
                  primary
                  compact
                  icon="plus"
                />
              </div>
            </Fragment>
          ) : (
            ''
          )}

          {type === 'textarea' ? (
            <textarea
              value={value}
              name={id}
              id={id}
              placeholder={placeholderText}
              onChange={handleInputChange}
              required={required}
            />
          ) : (
            ''
          )}

          {enableCopy && (
            <button
              className="copy"
              onClick={() => {
                copyToClipboard(value)
              }}
            >
              {CopyIcon}
            </button>
          )}
        </div>

        {required && (!value || !value.length) && (
          <div className="to-do">
            <span className="visually-hidden">Required</span>
          </div>
        )}
        {errors &&
          errors.length > 0 &&
          errors.map((e) => <ErrorSpan>{e}</ErrorSpan>)}
      </div>
    )
  }
}

export default Field

const AddLineButton = styled(Button)`
  &&& {
    margin: 30px 0;
    margin-left: auto;
    display: block;
    font-size: 90%;
  }
`

const ErrorSpan = styled.span`
  color: red;
`
