import { firebaseFunctions } from '../../../config/firebaseConfig'

const apiEnvelopesUpdate = firebaseFunctions.httpsCallable('apiEnvelopesUpdate')

export default async function updateEnvelope({ envelopeID, envelope }) {
  try {
    const result = await apiEnvelopesUpdate({ envelopeID, envelope })
    console.log(result)
    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
