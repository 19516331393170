import { firebaseDB } from 'config/firebaseConfig'

export const getDoc = (docType, docID) => {
  console.log(`Getting doc ${docType}s/${docID}...`)

  return firebaseDB
    .collection(`${docType}s`)
    .doc(docID)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data()
      } else {
        return null
      }
    })
    .catch((error) => {
      console.error(error)
      return null
    })
}

export const addDocFile = ({
  file,
  userType,
  uid,
  property,
  setUploadComplete = null,
  setErrors = null,
}) => {
  console.log(`Adding file to ${userType}s/${uid} property ${property}...`)

  return firebaseDB
    .collection(`${userType}s`)
    .doc(uid)
    .update({ [property]: file })
    .then((doc) => {
      if (setUploadComplete) {
        setUploadComplete()
      }
    })
    .catch((error) => {
      console.error(error)
      if (setErrors) {
        setErrors([error.message])
      }
    })
}

export const deleteDocFile = ({
  userType,
  uid,
  property,
  setSuccess = null,
  setErrors = null,
}) => {
  console.log(`Deleting property ${property} from ${userType}s/${uid}...`)

  return firebaseDB
    .collection(`${userType}s`)
    .doc(uid)
    .update({ [property]: null })
    .then((doc) => {
      if (setSuccess) {
        setSuccess(true)
      }
    })
    .catch((error) => {
      console.error(error)
      if (setErrors) {
        setErrors([error.message])
      }
    })
}
