import React, { useEffect, useState } from 'react'
import { LocationProvider, Location, Router } from '@reach/router'
import * as Sentry from '@sentry/browser'
import { startListeningToAuth } from './actions/authActions'
import { checkEmailLogin } from './actions/auth/emailLoginActions'
import Dashboard from './components/dashboards/Dashboard'
import PreMeetingQuestionnaire from './components/pmq/PreMeetingQuestionnaire'
import NavigationTracker from './components/utils/NavigationTracker.ts'
import 'normalize.css'
import './App.scss'

import { UserContext } from './contexts/UserContext'
import { FirmContext } from './contexts/FirmContext'
import { AdvisorContext } from './contexts/AdvisorContext'
import {
  CheckoutRedirect,
  EsignCallback,
  EsignAccessDocuments,
  LogIn,
  NotFound,
  Register,
} from './components/pages'
import { useItem } from 'hooks'
import { getDoc } from 'actions/db/_helpers'

const App = () => {
  const [isEmailLogin, setIsEmailLogin] = useState(false)
  const [uid, setUid] = useState('')
  const [userType, setUserType] = useState('')
  const [firmID, setFirmID] = useState('')
  const [advisorID, setAdvisorID] = useState('')

  useEffect(() => {
    const unsubscribe = startListeningToAuth(setUid)
    checkEmailLogin(setIsEmailLogin)
    return unsubscribe
  }, [])

  const firm = useItem({
    itemType: 'firm',
    uid: userType === 'firm' ? uid : firmID,
    load:
      ['firm', 'advisor', 'client', 'firm-admin'].includes(userType) ||
      (!userType && firmID),
  })
  const advisor = useItem({
    itemType: 'advisor',
    uid:
      userType === 'advisor' ||
      (userType === 'firm' && firm && firm.adminIsAdvisor)
        ? uid
        : advisorID,
    load:
      ['advisor', 'client'].includes(userType) ||
      (userType === 'firm' && firm && firm.adminIsAdvisor) ||
      (!userType && advisorID),
  })
  const client = useItem({
    itemType: 'client',
    uid: uid,
    load: userType === 'client',
  })

  const firmAdmin = useItem({
    itemType: 'firm-admin',
    uid: uid,
    load: ['firm-admin'].includes(userType),
  })

  useEffect(() => {
    const getUser = async () => {
      const user = await getDoc('user', uid)

      if (user?.type) setUserType(user.type)
    }

    if (uid && !userType) {
      getUser()
    }

    if (!uid && userType) {
      setUserType('')
      setFirmID('')
      setAdvisorID('')
    }
  }, [uid, userType])

  useEffect(() => {
    if (!uid || !userType) {
      return
    }

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: uid,
        userType: userType,
      })
    })
  }, [uid, userType])

  useEffect(() => {
    if (!userType) {
      return
    }

    if (userType === 'firm' && !firmID) {
      setFirmID(uid)
    }

    if (userType === 'firm-admin' && firmAdmin && !firmID) {
      setFirmID(firmAdmin.firm)
    }

    if (
      userType === 'firm' &&
      firm &&
      firm.adminIsAdvisor &&
      (!advisorID || (advisorID && advisorID !== uid))
    ) {
      setAdvisorID(uid)
    }

    // Remove advisorID from after login redirection if needed
    if (userType === 'firm' && advisorID && advisorID !== uid) {
      setAdvisorID(null)
    }

    if (userType === 'advisor' && !advisorID) {
      setAdvisorID(uid)
    }

    if (userType === 'advisor' && advisor && advisor.firm && !firmID) {
      setFirmID(advisor.firm)
    }

    if (userType === 'client' && client && client.firm && !firmID) {
      setFirmID(client.firm)
    }

    if (userType === 'client' && client && client.advisor && !advisorID) {
      setAdvisorID(client.advisor)
    }
  }, [userType, firm, advisor, client, firmID, advisorID, uid, firmAdmin])

  const getUserFromState = () => {
    if (userType === 'firm') {
      return firm
    }

    if (userType === 'advisor') {
      return advisor
    }

    if (userType === 'client') {
      return client
    }

    if (userType === 'firm-admin') {
      return firmAdmin
    }
  }

  return (
    <UserContext.Provider value={{ uid, userType, user: getUserFromState() }}>
      <FirmContext.Provider value={{ firmID, firm, setFirmID }}>
        <AdvisorContext.Provider value={{ advisorID, advisor, setAdvisorID }}>
          <LocationProvider>
            <Router>
              <Dashboard path="/" />
              <LogIn path="login" isEmailLogin={isEmailLogin} />
              <LogIn path="login/:brandID" isEmailLogin={isEmailLogin} />
              <Register type="firm" path="register-firm" />
              <Register type="advisor" path="radv/:inviteCode" />
              <CheckoutRedirect path="go" />
              <EsignCallback path="esign-callback" />
              <EsignAccessDocuments path="access-documents" />
              <PreMeetingQuestionnaire path="pmq/:inviteCode" />
              <NotFound default />
            </Router>
            <Location
              children={(context) => (
                <NavigationTracker
                  pathname={context.location.pathname}
                  uid={uid}
                />
              )}
            />
          </LocationProvider>
        </AdvisorContext.Provider>
      </FirmContext.Provider>
    </UserContext.Provider>
  )
}

export default App
