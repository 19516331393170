import React from 'react'
import {
  redtailLogo,
  wealthboxLogo,
  // tdameritradeLogo,
  calendlyLogo,
  salesforceLogo,
  // zapierLogo,
  // riskalyzeLogo,
  // googledriveLogo,
  // dropboxLogo,
  // boxLogo,
  // practifiLogo,
  // acuityLogo,
} from '../assets/images/integrations'

export const integrationServices = {
  redtail: {
    name: 'Redtail',
    logo: redtailLogo,
    available: true,
  },
  wealthbox: {
    name: 'Wealthbox',
    logo: wealthboxLogo,
  },
  salesforce: {
    name: 'Salesforce',
    logo: salesforceLogo,
    available: true,
  },
  calendly: {
    name: 'Calendly',
    logo: calendlyLogo,
    available: true,
  },
  // tdameritrade: {
  //   name: 'TDAmeritrade',
  //   logo: tdameritradeLogo,
  // },
  // practifi: {
  //   name: 'Practifi',
  //   logo: practifiLogo,
  // },
  // riskalyze: {
  //   name: 'Riskalyze',
  //   logo: riskalyzeLogo,
  // },
  // google_drive: {
  //   name: 'Google Drive',
  //   logo: googledriveLogo,
  // },
  // dropbox: {
  //   name: 'Dropbox',
  //   logo: dropboxLogo,
  // },
  // box: {
  //   name: 'Box',
  //   logo: boxLogo,
  // },
  // zapier: {
  //   name: 'Zapier',
  //   logo: zapierLogo,
  // },
  // squarespace: {
  //   name: 'Acuity',
  //   logo: acuityLogo,
  // },
}

export const renderServiceLogo = (service) => {
  if (!service) {
    return ''
  }

  if (service.logo) {
    return <img src={service.logo} alt={service.name} />
  }
  return <div className="service-name">{service.name}</div>
}
