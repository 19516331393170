import React, { memo } from 'react'

export default memo(function PersonIcon() {
  return (
    <svg
      width="39px"
      height="42px"
      viewBox="0 0 39 42"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M10.6061 21.2121C16.4636 21.2121 21.2121 16.4636 21.2121 10.6061C21.2121 4.7485 16.4636 0 10.6061 0C4.7485 0 0 4.7485 0 10.6061C0 16.4636 4.7485 21.2121 10.6061 21.2121Z"
            transform="translate(8.244028 0)"
            fill="#0DC4B6"
            stroke="none"
          />
          <g transform="translate(0 24.95544)">
            <path
              d="M0 2.97843L3.10446 14.9221L17.8531 14.9221C17.8531 6.69617 11.1981 0.0268271 2.97843 0L0 2.97843Z"
              transform="translate(19.63868 0.05115864)"
              fill="#0DC4B6"
              stroke="none"
            />
            <path
              d="M18.0334 3.01025L15.0232 0L14.9234 0C6.68119 0 0 6.68119 0 14.9234L15.1018 14.9234L18.0334 3.01025Z"
              transform="translate(0.2083779 0.04991087)"
              fill="#0DC4B6"
              stroke="none"
            />
          </g>
        </g>
      </g>
    </svg>
  )
})
