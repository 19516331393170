import React from 'react'
import { ReactFormBuilder } from '@bitsyadvisor/bitsy-form-builder'
import { availableFields } from '../../tf-fields/_config'
import '@bitsyadvisor/bitsy-form-builder/dist/app.css'
import { useItem } from 'hooks'
import { updateItemInfo } from 'actions/db/dbActions'
import styled from 'styled-components'
import { colors } from 'styles'
import { CardBoxShadow, SmallHeading } from 'styles/_mixins'

const FieldsPage = ({ template, firmID, handleSetTemplate }) => {
  const presets = useItem({ itemType: 'preset', uid: firmID }) || {}

  const handleLoad = async () => {
    if (!template || !template.fields || !template.fields.length) {
      return []
    }

    // Replace null field values with undefined (for importing from Firestore)
    const templateFields = await JSON.parse(
      JSON.stringify(template.fields, (key, value) => {
        if (value === null) {
          return
        }
        return value
      })
    )

    return templateFields
  }

  const handleSave = (data) => {
    if (!data || !data.task_data) {
      return null
    }

    // Replace undefined field values with null (for saving to Firestore)
    const newFields = JSON.parse(
      JSON.stringify(data.task_data, (key, value) => {
        if (value === undefined) {
          return null
        }
        return value
      })
    )

    handleSetTemplate({ fields: newFields })
  }

  return (
    <FormBuilderWrapper>
      <ReactFormBuilder
        onLoad={handleLoad}
        onPost={handleSave}
        toolbarItems={availableFields}
        variables={{}}
        presets={Object.entries(presets).map(([name, values]) => ({
          name,
          values,
        }))}
        lockedFields={[
          'legal_name_first',
          'legal_name_last',
          'includeSecondInvestor',
          'second_investor_legal_name_first',
          'second_investor_legal_name_last',
          'email',
          'second_investor_email',
        ]}
        onSavePreset={(name, values) => {
          updateItemInfo('preset', firmID, name, values)
        }}
      />
    </FormBuilderWrapper>
  )
}

export default FieldsPage

const FormBuilderWrapper = styled.div`
  &&& {
    .form-control {
      height: auto;
      font-size: 1em;
    }

    .react-form-builder > div {
      display: flex;
    }

    .react-form-builder > div > div {
      float: none !important;
    }

    .react-form-builder .react-form-builder-toolbar {
      background: transparent !important;
      position: sticky;
      top: 0;
      height: 100%;
    }

    .react-form-builder-toolbar h4 {
      ${SmallHeading()};
    }

    .react-form-builder-toolbar ul li {
      ${SmallHeading()};
      color: ${colors.grayAlt};

      &:hover,
      &:focus {
        color: ${colors.primary};
        border-color: ${colors.primary};
        transition: 0.3s all;
      }
    }

    .react-form-builder .react-form-builder-preview {
      ${CardBoxShadow()};
      border: 0;
      background: #fff;
    }

    .react-form-builder .react-form-builder-preview .Sortable .rfb-item {
      padding-top: calc(2rem + 2px);
      padding-bottom: calc(2rem + 2px);
      position: relative;
    }

    .react-form-builder .react-form-builder-preview .edit-form {
      width: 960px;
    }

    .react-form-builder label {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 15px;
      display: block;
    }

    .react-form-builder .edit-form label {
      font-size: 20px;
    }

    .react-form-builder .edit-form .form-group {
      margin-bottom: 4rem;
    }

    .react-form-builder .edit-form > div > .form-group:last-child {
      margin-bottom: 0;
    }

    .react-form-builder .badge {
      display: inline;
      padding: 0.3em 0.6em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25em;
      top: auto;
      right: auto;
      width: auto;
      height: auto;
    }

    .react-form-builder .badge-secondary {
      background-color: ${colors.primary};
      text-transform: uppercase;
      letter-spacing: 1px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: calc(-2rem + 6px);
    }

    .react-form-builder .label-required {
      width: 0;
    }

    /*
    .react-form-builder
      .react-form-builder-preview
      .Sortable
      .rfb-item.SortableItem
      .form-group
      .label-required {
      margin-left: 10px;
      background: transparent;
      color: ${colors.warning};
      padding-left: calc(0.6em + 8px);
      position: relative;

      &::before {
        @include badge(${colors.warning}, '', 8px);
        position: absolute;
        left: 2px;
        top: calc(50% - 5px);
        bottom: 0;
      }

      &:hover {
        width: auto;
      }
    }
    */
    .react-form-builder
      .react-form-builder-preview
      .Sortable
      .rfb-item
      .toolbar-header
      .toolbar-header-buttons {
      right: -14px;
      top: -6px;

      .btn {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }

    .react-form-builder .fas {
      display: inline-block;
      opacity: 1;
      margin: 0 0.25rem 0 0;
      width: 1.18em;
      height: 1em;
      font-family: Icons;
      font-style: normal;
      font-weight: 400;
      text-decoration: inherit;
      text-align: center;
      speak: none;
      font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      font-size: 1.25em;

      &:hover {
        opacity: 0.7;
      }
    }

    .react-form-builder .fa-edit::before {
      content: '\f044';
      color: ${colors.primaryAlt};
    }

    .react-form-builder .fa-clone::before {
      content: '\f24d';
      color: ${colors.primaryAlt};
    }

    .react-form-builder .fa-trash::before {
      content: '\f2ed';
      color: ${colors.warning};
    }

    .react-form-builder .fa-grip-vertical::before {
      content: '\f58e';
      color: ${colors.gray};
    }

    .react-form-builder .dynamic-option-list li > .row > * {
      display: flex;
      align-items: center;
    }

    .react-form-builder .dynamic-option-list input[type='checkbox'] {
      transform: scale(1.5);
    }

    .react-form-builder .btn.btn-success {
      background: ${colors.primary};
      border-color: ${colors.primary};

      &:hover {
        background: ${colors.primaryAlt};
        border-color: ${colors.primaryAlt};
      }
    }

    .react-form-builder .dynamic-options-actions-buttons .btn {
      background: transparent;
      border: none;
      padding: 0;
      font-size: 1.6rem;
    }

    .react-form-builder .dynamic-options-actions-buttons .btn-success {
      color: ${colors.primary};

      &:hover {
        background: none;
        border-color: none;
      }
    }

    .react-form-builder .dynamic-options-actions-buttons .btn-danger {
      color: ${colors.warning};
    }

    .react-form-builder .fa-cogs {
      text-align: center;
      margin-right: 0;
      width: auto;
    }

    .react-form-builder .fa-cogs::before {
      content: '\f085';
      color: #fff;
    }

    .react-form-builder .fa-plus-circle::before {
      content: '\f055';
    }

    .react-form-builder .fa-minus-circle::before {
      content: '\f056';
    }

    .react-form-builder
      .react-form-builder-preview
      .edit-form
      .dismiss-edit::before {
      content: '\f00d';
    }

    .react-form-builder label.static {
      ${SmallHeading()};
      margin-bottom: 0 !important;
    }

    .react-form-builder
      .react-form-builder-preview
      > .Sortable
      > div
      > .rfb-item
      > .form-group {
      margin-bottom: 0;
    }

    .react-form-builder hr {
      padding-bottom: 30px;
      // background: #f9f9f9;
      padding-bottom: 30px;
      border-top-width: 0;
    }

    .react-form-builder hr::before {
      content: '';
      display: block;
      position: absolute;
      // top: calc(-2rem - 26px);
      top: 0;
      left: -10px;
      right: -10px;
      height: 30px;
      box-shadow: 0px 16px 24px #ededed;
      margin-bottom: 30px;
      z-index: 99;
    }

    .react-form-builder hr::after {
      content: '';
      display: block;
      background: #f9f9f9;
      height: 30px;
      position: absolute;
      left: -24px;
      right: -24px;
      z-index: 1;
    }

    .react-form-builder input[disabled] {
      opacity: 0.4;
    }

    .react-form-builder
      .edit-form
      .rdw-editor-main
      .public-DraftEditor-content {
      padding: 0 10px;
      height: calc(100% - 2em);
    }

    .react-form-builder
      .edit-form
      .rdw-editor-main
      .public-DraftEditor-content
      > div
      > div {
      & > div {
        padding: 0.5em 0;
        margin: 0;
      }

      &:first-child > div {
        padding-top: 1em;
      }

      &:last-child > div {
        padding-bottom: 1em;
      }
    }
  }
`
