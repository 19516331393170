import styled from 'styled-components'
import { colors } from 'styles'

const InputBar = styled.div`
  display: block;
  position: relative;
  top: 0;
  margin-bottom: 1rem;

  &::before,
  &::after {
    background: ${colors.blue};
    bottom: 0;
    content: '';
    height: 0.125rem;
    position: absolute;
    transition: all 0.2s ease;
    width: 0;
  }

  &::before {
    left: 50%;
  }

  &::after {
    right: 50%;
  }

  @media screen and (min-width: 46.25em) {
    margin-bottom: 2rem;
  }
`

export default InputBar
