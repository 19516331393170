import { sendDocument, signDocument } from 'actions/integrations/docusign'
import updateClientInDB from 'actions/db/clients/update'
import { addTaskToDB } from 'actions/db/taskActions'
import { prepClientForDB } from 'helpers/db'
import { updateForm } from 'actions/db/forms'
import { updateUserTaskStatus } from 'actions/db/userTaskActions'
import integrationsAsyncProcess from 'actions/integrations/async'

export const handleDocusignFinalize = async (props) => {
  const {
    type,
    envelopeIDFromPreview = null,
    updateEnvelope = null,
    templateID,
    template,
    flow,
    client,
    advisor,
    firm,
    setFinalizeType,
    setProcessing,
    setProcessingMessage,
    setErrors,
    setDocSigningSessionSignersLoading,
  } = props

  setFinalizeType(type)
  setProcessing(true)
  setErrors([])

  if ('preview' !== type) {
    await handleSave(props)
  }

  let envelopeId = envelopeIDFromPreview
  if (!envelopeId) {
    switch (type) {
      case 'send': {
        setDocSigningSessionSignersLoading(true)
        const envelopeRes = await handleSendForSigning(props)
        envelopeId = envelopeRes?.envelopeId
        break
      }
      case 'sign': {
        const envelopeRes = await handleSignNow(props)
        envelopeId = envelopeRes?.envelopeId
        break
      }
      case 'preview': {
        const envelopeRes = await handleSendForSigning({
          ...props,
          preview: true,
        })
        envelopeId = envelopeRes?.envelopeId
        break
      }
      case 'save':
        break
      default:
        break
    }
  } else if (updateEnvelope) {
    setProcessingMessage('Finalizing DocuSign document...')
    await updateEnvelope()
  }

  if (envelopeId && type !== 'preview') {
    setProcessingMessage('Sending data to additional integrations...')
    await integrationsAsyncProcess({
      client,
      advisor,
      firm,
      firmID: client.firm,
      advisorID: client.advisor,
      envelopeId,
      templateID,
    })
  }

  if (
    'round-trip' === template.routing &&
    'final-review' === flow &&
    type !== 'preview'
  ) {
    await handleRoundTripFinalize(props)
  }

  setProcessing(false)
}

const handleSendForSigning = async ({
  preview = false,
  setProcessingMessage,
  template,
  templateID,
  clientID,
  client,
  advisor,
  firm,
  additionalDocuments,
  setErrors,
  setDocSigningSessionEnvelopeID,
  setDocSubmitSuccess,
}) => {
  // Start the signing email process
  setProcessingMessage('Sending document to DocuSign...')
  const sendDocumentRes = await sendDocument(
    template && template.legacyDocType ? null : templateID,
    template && template.legacyDocType ? template.legacyDocType : null, // TODO: [BTS-643] Update sendDocument to not need second argument
    clientID,
    client,
    advisor,
    firm,
    additionalDocuments,
    null,
    preview,
    setErrors
  )

  if (sendDocumentRes) {
    const { status: resStatus } = sendDocumentRes
    if (preview && resStatus === 'created') {
      setDocSigningSessionEnvelopeID(sendDocumentRes.envelopeId)
    } else if (resStatus === 'sent') {
      setDocSubmitSuccess(true)
    } else {
      setDocSubmitSuccess(false)
    }
  }

  return sendDocumentRes
}

const handleSignNow = async ({
  preview = false,
  setProcessingMessage,
  template,
  templateID,
  clientID,
  client,
  advisor,
  firm,
  additionalDocuments,
  setDocSigningSessionEnvelopeID,
  setErrors,
}) => {
  // Start the in-person signing session
  setProcessingMessage('Sending document to DocuSign...')
  const signDocumentRes = await signDocument(
    template && template.legacyDocType ? null : templateID,
    template && template.legacyDocType ? template.legacyDocType : null, // TODO: [BTS-644] Update signDocument to not need second argument
    clientID,
    client,
    advisor,
    firm,
    additionalDocuments,
    preview,
    setDocSigningSessionEnvelopeID,
    setErrors
  )

  return signDocumentRes
}

const handleSave = async ({
  setProcessingMessage,
  client,
  removePendingChanges,
  template,
  clientID,
  setSaveSuccess,
  setErrors,
}) => {
  // Save to the database
  console.log('Saving final client to the database...')
  setProcessingMessage('Saving final client to the database...')

  // Remove tax ID and repeaters before saving to DB/upserting
  const dbClient = prepClientForDB(client)

  if (removePendingChanges) {
    console.log('Removing pending changes...')
    dbClient['pendingChanges'] = null
  }

  if (template && template.useForOnboardingDate && !dbClient.dateAdded) {
    dbClient.dateAdded = new Date()
  }

  const updateClientInDBRes = await updateClientInDB({
    clientID,
    client: dbClient,
  })

  if (updateClientInDBRes) {
    const { type: resType, error: resError } = updateClientInDBRes
    if ('success' === resType) {
      console.log('Success!')

      // Add task to upsert client in integrations
      console.log('Adding integrations upsert client task...')
      setProcessingMessage('Sending updated client to active integrations...')
      const taskOptions = {
        firmID: dbClient.firm,
        advisorID: dbClient.advisor,
        client: dbClient,
      }
      const addUpsertTaskToDBResID = await addTaskToDB(
        'integrationsUpsertClient',
        new Date(),
        taskOptions
      )

      if (addUpsertTaskToDBResID) {
        console.log(
          `Success! Integrations upsert client task added with ID ${addUpsertTaskToDBResID}`
        )
        setSaveSuccess(true)
      } else {
        setSaveSuccess(false)
        setErrors(['Error adding integrations upsert client task'])
      }
    }

    if ('error' === resType) {
      setSaveSuccess(false)
      if (resError) {
        console.error(`Error updating client in DB: ${resError}`)
        setErrors([resError])
      }
    }
  }

  return updateClientInDBRes
}

const handleRoundTripFinalize = async ({
  setProcessingMessage,
  formID,
  setErrors,
  setRoundTripFinalizeSuccess,
  client,
  taskID,
}) => {
  // Update
  console.log('Advisor completed their review and have sent for finalization')

  // Update signer status in DB
  const formDoc = {
    currentReviewer: null,
    'reviewers.advisor.status': 'Reviewed',
    status: 'Completed',
  }

  setProcessingMessage('Updating form status...')
  const updateFormRes = await updateForm({
    formID,
    formDoc,
    setFormSendSuccess: null,
    setErrors,
  })

  if (updateFormRes) {
    setRoundTripFinalizeSuccess(true)

    // TODO: [BTS-806] Improve marking user task as complete
    // Mark task as complete
    setTimeout(async () => {
      setProcessingMessage('Marking task as complete...')
      await updateUserTaskStatus('advisor', client.advisor, taskID, 'complete')
    }, 10000)

    return true
  } else {
    setErrors([
      'Error updating form reviewer status. Please try again and contact Bitsy if the problem persists.',
    ])
  }
}
