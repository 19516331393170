import EnvelopeDownloadList from './EnvelopeDownloadList'
import EnvelopeRecipientsList from './EnvelopeRecipientsList'
import InProgressFormsModule from './InProgressFormsModule'
import ManualFinalizeOptions from './ManualFinalizeOptions'

import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { colors } from 'styles'

export const RetryButton = styled(Button)`
  &&& {
    margin-left: auto;
    box-shadow: none;
    padding: 0;
    font-weight: bold;
    color: ${colors.primary} !important;
    opacity: 1;

    &:hover,
    &:focus {
      box-shadow: none;
      opacity: 0.8;
      color: ${colors.primary} !important;
      transition: 0.3s all;
    }

    svg g {
      fill: ${colors.primary};
    }
  }
`

export {
  EnvelopeDownloadList,
  EnvelopeRecipientsList,
  InProgressFormsModule,
  ManualFinalizeOptions,
}
