import { firebaseFunctions } from '../../../config/firebaseConfig'

const apiClientsUpdateEmail = firebaseFunctions.httpsCallable(
  'apiClientsUpdateEmail'
)

const updateClientEmail = async ({ clientID, email }) => {
  try {
    const result = await apiClientsUpdateEmail({
      clientID,
      email,
    })
    console.log(result)
    return {
      type: 'success',
      data: result.data,
    }
  } catch (err) {
    console.error(err)
    return {
      type: 'error',
      error: err,
    }
  }
}

export default updateClientEmail
