import React, { PureComponent } from 'react'

class Prompt extends PureComponent {
  render() {
    const { prompt, handleSelect, isActive } = this.props

    const options = [{ value: true, answer: "Yes! Let's do it." }]

    const optionsList = options.map((option, i) => {
      var value = option.answer
      if (option.value) {
        value = option.value
      }

      return (
        <button
          key={i}
          onClick={(e) => handleSelect(e, 'prompt_continue', value)}
        >
          {option.answer}
        </button>
      )
    })

    return (
      <div className={isActive ? 'slideContents active' : 'slideContents'}>
        <div className="question">{prompt}</div>
        <div className="form-group">{optionsList}</div>
      </div>
    )
  }
}

export default Prompt
