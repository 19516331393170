import React, { memo, useState } from 'react'
import { DownloadIcon, RemoveIcon } from '../icons'
import { useFirm, useUser } from '../../contexts'
import { useItemList } from '../../hooks'
import { uploadDisclosure } from '../../actions/storage/disclosureActions'
import { deleteDisclosure } from '../../actions/db/disclosureActions'
import './DisclosureUploadForm.scss'

export default memo(function DisclosureUploadForm({ type }) {
  const { uid, userType } = useUser()
  const { firmID } = useFirm()
  const [uploading, setUploading] = useState(false)

  const disclosures = useItemList({
    itemType: 'disclosure',
    userType: type,
    uid: userType === 'firm-admin' ? firmID : uid,
    isUserItem: true,
  })

  var disclosureFile = null

  var uploadedDisclosures = ''
  if (disclosures && Object.keys(disclosures).length) {
    uploadedDisclosures = Object.keys(disclosures).map((disclosureID, key) => (
      <li key={disclosureID}>
        <a
          title="Download"
          target="_blank"
          rel="noopener noreferrer"
          href={disclosures[disclosureID].link}
        >
          <DownloadIcon />
          {disclosures[disclosureID].name}
        </a>

        <button
          className="remove-button"
          onClick={(event) => handleDisclosureDelete(event, disclosureID)}
        >
          <RemoveIcon />
        </button>
      </li>
    ))
  }

  const setDisclosureRef = (ref) => {
    disclosureFile = ref
  }

  const setUploadComplete = () => {
    disclosureFile = null
    setUploading(false)
    document.getElementById(`${type}-disclosures-upload`).reset()
  }

  const handleDisclosureDelete = (event, disclosure) => {
    event.preventDefault()

    // TODO: [BTS-271] Add confirmation?
    deleteDisclosure(type, userType === 'firm-admin' ? firmID : uid, disclosure)
  }

  const handleDisclosureUpload = (event) => {
    event.preventDefault()

    const disclosureFiles =
      disclosureFile && disclosureFile.files && disclosureFile.files.length
        ? disclosureFile.files
        : null

    if (disclosureFiles) {
      setUploading(true)

      Array.from(disclosureFiles).forEach((file) => {
        uploadDisclosure(
          type,
          userType === 'firm-admin' ? firmID : uid,
          file,
          setUploadComplete
        )
      })
    }
  }

  return (
    <form
      id={`${type}-disclosures-upload`}
      className="upload-form disclosures-upload"
    >
      <div className="current current-disclosures">
        {disclosures && Object.keys(disclosures).length ? (
          <ul className="disclosure-documents">
            <strong>Uploaded documents:</strong>
            {uploadedDisclosures}
          </ul>
        ) : (
          <small className="instructional">No documents uploaded yet!</small>
        )}
      </div>

      <div className="new disclosure-upload">
        <label htmlFor="disclosure-upload">Upload new document(s):</label>
        <input
          id="disclosure-upload"
          name="disclosure-upload"
          type="file"
          ref={setDisclosureRef}
          multiple
        />

        <button onClick={handleDisclosureUpload} disabled={uploading}>
          {uploading ? 'Please wait...' : 'Upload'}
        </button>
      </div>
    </form>
  )
})
