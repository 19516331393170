import { useEffect } from 'react'

const useScript = (url, load = true, async = true) => {
  useEffect(() => {
    if (load) {
      const script = document.createElement('script')

      script.src = url
      script.async = async

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [url, load, async])
}

export default useScript
