import { Registry } from '@bitsyadvisor/bitsy-form-builder'

const FundAllocator = () => <div>Fund Allocator</div>
const Repeater = () => <div>Repeater</div>

Registry.register('Repeater', Repeater)
Registry.register('FundAllocator', FundAllocator)

export const availableFields = [
  {
    key: 'Header',
    name: 'Header Text',
    icon: 'icon heading',
    static: true,
    content: 'Placeholder Text...',
    jsonSchemaType: 'string',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
  },
  {
    key: 'Label',
    name: 'Label',
    static: true,
    icon: 'icon font',
    content: 'Placeholder Text...',
    jsonSchemaType: 'string',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
  },
  {
    key: 'Paragraph',
    name: 'Paragraph',
    static: true,
    icon: 'icon paragraph',
    content: 'Placeholder Text...',
    jsonSchemaType: 'string',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
  },
  // TODO: [BTS-675] Add section break field to FieldsPage
  {
    key: 'LineBreak',
    name: 'Section Break',
    static: true,
    icon: 'icon arrows alternate horizontal',
    jsonSchemaType: 'string',
  },
  {
    key: 'TextInput',
    canHaveAnswer: true,
    canReadOnly: true,
    name: 'Text Input',
    label: 'Placeholder Label',
    icon: 'icon font',
    field_name: 'text_input_',
    jsonSchemaType: 'string',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
  },
  {
    key: 'NumberInput',
    canHaveAnswer: true,
    name: 'Number Input',
    label: 'Placeholder Label',
    icon: 'icon plus',
    field_name: 'number_input_',
    jsonSchemaType: 'number',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
  },
  {
    key: 'TextArea',
    canHaveAnswer: true,
    name: 'Multi-line Input',
    label: 'Placeholder Label',
    icon: 'icon text height',
    field_name: 'text_area_',
    jsonSchemaType: 'string',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
  },
  {
    key: 'DatePicker',
    canDefaultToday: true,
    canReadOnly: true,
    dateFormat: 'MM/dd/yyyy',
    timeFormat: 'hh:mm aa',
    showTimeSelect: false,
    showTimeSelectOnly: false,
    name: 'Date',
    icon: 'icon calendar alternate',
    label: 'Placeholder Label',
    field_name: 'date_picker_',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    jsonSchemaType: 'string',
    jsonSchemaFormat: 'date',
  },
  {
    key: 'HyperLink',
    name: 'Website',
    icon: 'icon linkify',
    static: true,
    content: 'Placeholder web site link ...',
    href: 'http://www.example.com',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    jsonSchemaType: 'string',
  },
  {
    key: 'Dropdown',
    canHaveAnswer: true,
    canReadOnly: true,
    showCorrectColumn: false,
    name: 'Dropdown',
    icon: 'icon caret square down',
    label: 'Placeholder Label',
    field_name: 'dropdown_',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    options: [],
    jsonSchemaType: 'string',
    jsonSchemaHasOptions: true,
  },
  {
    key: 'RadioButtons',
    canHaveAnswer: true,
    name: 'Multiple Choice',
    icon: 'icon dot circle',
    label: 'Placeholder Label',
    field_name: 'radiobuttons_',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    options: [],
    jsonSchemaType: 'string',
    jsonSchemaWidget: 'radio',
    jsonSchemaHasOptions: true,
  },
  {
    key: 'Checkboxes',
    canHaveAnswer: true,
    name: 'Checkboxes',
    icon: 'icon check square',
    label: 'Placeholder Label',
    field_name: 'checkboxes_',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    options: [],
    jsonSchemaType: 'array',
    jsonSchemaHasOptions: true,
    jsonSchemaWidget: 'checkboxes',
  },
  {
    key: 'Range',
    name: 'Range',
    icon: 'icon sliders horizontal',
    label: 'Placeholder Label',
    field_name: 'range_',
    step: 1,
    default_value: 3,
    min_value: 1,
    max_value: 5,
    min_label: '1 year',
    max_label: '5 years',
    jsonSchemaType: 'integer',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
  },
  // TODO: [BTS-679] Add tags field
  // {
  //   key: 'Tags',
  //   canHaveAnswer: true,
  //   name: 'Tags',
  //   icon: 'icon tags',
  //   label: 'Placeholder Label',
  //   field_name: 'tags_',
  //   options: [],
  //   jsonSchemaType: 'array',
  // },
  {
    key: 'FundAllocator',
    element: 'CustomElement',
    component: FundAllocator,
    static: false,
    type: 'custom',
    canHaveAnswer: true,
    name: 'Fund Allocator',
    icon: 'icon money bill alternate outline',
    label: 'Placeholder Label',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    field_name: 'fund_allocator_',
    jsonSchemaType: 'object',
  },
  {
    key: 'Repeater',
    element: 'CustomElement',
    component: Repeater,
    static: false,
    type: 'custom',
    canHaveAnswer: true,
    name: 'Repeater',
    icon: 'icon list',
    label: 'Placeholder Label',
    field_name: 'repeater_',
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    jsonSchemaType: 'array',
    items: {
      jsonSchemaOrder: [],
      properties: {},
    },
  },
]

export const customFieldElements = {
  FundAllocator,
  Repeater,
}

export const definitions = {
  address: {
    type: 'object',
    properties: {
      street: { type: 'string', title: 'Street' },
      city: { type: 'string', title: 'City' },
      state: {
        type: 'string',
        title: 'State',
        $ref: '#/definitions/stateAbbr',
      },
      zip: { type: 'string', title: 'Zip' },
    },
    required: ['street', 'city', 'state', 'zip'],
  },
  clientAccounts: {
    type: 'string',
    enum: ['All Accounts'],
  },
  stateAbbr: {
    type: 'string',
    enum: [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DE',
      'DC',
      'FL',
      'GA',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MD',
      'MA',
      'MI',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ],
  },
}

export const requiredBitsyFields = [
  'legal_name_first',
  'legal_name_last',
  'email',
  'includeSecondInvestor',
  'second_investor_legal_name_first',
  'second_investor_legal_name_last',
]
