import { firebaseFunctionsCustomDomain } from '../../../config/firebaseConfig'

const integrationsDocusignResendSigningEmail = firebaseFunctionsCustomDomain.httpsCallable(
  'integrationsDocusignResendSigningEmail'
)

export default async function resendSigningEmail({
  envelopeID,
  recipient,
  firm,
}) {
  try {
    const result = await integrationsDocusignResendSigningEmail({
      envelopeId: envelopeID,
      recipient,
      firm,
    })

    if (
      result.data &&
      result.data.recipientUpdateResults &&
      result.data.recipientUpdateResults.length
    ) {
      return true
    }

    return false
  } catch (err) {
    console.error(err)
  }

  return false
}
