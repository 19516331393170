import { useEffect } from 'react'

const useBodyClass = (className, load = true) => {
  useEffect(() => {
    if (load) {
      document.body.className = className
      return () => {
        document.body.className = ''
      }
    }
  }, [className, load])
}

export default useBodyClass
