const colors = {
  primary: '#0dc4b6',
  primaryAlt: '#09ab9f',
  accent: '#f90f90',
  blue: '#0056b3',
  warning: '#dd4b39',
  success: '#09914E',
  negative: '#DB2828',
  negativeHover: '#d01919',
  positive: '#21BA45',
  positiveHover: '#16ab39',
  gray: '#ced4da',
  grayAlt: '#495057',
  graySecondary: '#97A1A8',
  grayHeadline: '#717171',
  dark: '#00273f',
  black: '#212529',
}

export default colors
