import React, { memo } from 'react'
import { Input } from 'semantic-ui-react'
import { copyToClipboard } from 'helpers/_utils'
import styled from 'styled-components'

const CopyableInput = memo(({ text = '', ...props }) => (
  <StyledInput
    value={text}
    action={{
      color: 'teal',
      icon: 'copy',
      onClick: () => copyToClipboard(text),
    }}
    readOnly
    {...props}
  />
))

export default CopyableInput

const StyledInput = styled(Input)`
  &&& {
    & > input {
      max-width: calc(100% - 38px);
    }
  }
`
