import { useState, useEffect } from 'react'
import { listenToItems } from '../actions/db/dbActions'

const useItemList = ({
  itemType,
  userType,
  uid,
  isUserItem = null,
  where1 = null,
  where2 = null,
  load = true,
  where3 = null,
  where4 = null,
  orderByKey = null,
  orderByDirection = null,
  where5 = null,
  where6 = null,
  where7 = null,
  where8 = null,
  where = null,
  orderBy = null,
}) => {
  const [items, setItems] = useState(null)

  useEffect(() => {
    if (load && uid) {
      function handleItemsChange(newItems) {
        setItems(newItems)
      }
      const unsubscribe = listenToItems(
        itemType,
        userType,
        uid,
        handleItemsChange,
        isUserItem,
        where1,
        where2,
        where3,
        where4,
        orderByKey,
        orderByDirection,
        where5,
        where6,
        where7,
        where8,
        where,
        orderBy
      )
      // Specify how to clean up after this effect:
      return function cleanup() {
        unsubscribe && unsubscribe()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    itemType,
    userType,
    uid,
    isUserItem,
    where1,
    where2,
    load,
    where3,
    where4,
    orderByKey,
    orderByDirection,
    where5,
    where6,
    where7,
    where8,
  ])

  useEffect(() => {
    if (!uid && items) {
      setItems(null)
    }
  }, [uid, items])

  return items
}

export default useItemList
