import React, { PureComponent, Fragment } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Field from '../../Field'
import AddressFields from '../../AddressFields'
import WidgetToggle from '../../../partials/WidgetToggle'
import 'react-tabs/style/react-tabs.css'
import DownloadIcon from '../../../icons/DownloadIcon'
import ErrorsList from '../../../partials/ErrorsList'
import {
  startListeningToClientAuth,
  stopListeningToClientAuth,
  signInClientUser,
  signOutClientUser,
} from '../../../../actions/clientAuthActions'
import {
  getFirmDetails,
  stopListeningToFirmDetails,
  getFirmAdvisors,
  stopListeningToFirmAdvisors,
} from '../../../../actions/clientDbActions'
import { validEmailRegex } from '../../../../config/regexPatterns'
import { clientInitialState } from '../../../../config/iacConfig'
import { validateClientFields } from '../../../../actions/iacHelpers'
import AddNewFirmClientsModule from '../../../dashboards/partials/AddNewFirmClientsModule'
// import { firmIDs } from '../../../config/firmConfig'

class K401Form extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
      accept_disclosures: false,
      firm: {
        advisors: null,
        name: '',
        email: '',
        isInGoodStanding: null,
        currentPeriodEnd: null,
      },
      advisors: null,
      password: '',
      uid: '',
      client: clientInitialState,
      advisor: this.props.advisor,
      errors: [],
      addSuccess: false,
      userAddSuccess: false,
      docSubmitSuccess: false,
    }

    this.attemptContinue = this.attemptContinue.bind(this)
    // this.getAdvisorDetails = this.getAdvisorDetails.bind(this)
    this.handleClientLogin = this.handleClientLogin.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.increaseStep = this.increaseStep.bind(this)
    this.validateStep = this.validateStep.bind(this)
  }

  componentDidMount() {
    const {
      location,
      firm,
      firmObject,
      firmAdvisors,
      advisor,
      advisorID,
      client,
      clientID,
    } = this.props

    if (location !== 'dashboard' && location !== 'client-profile') {
      signOutClientUser()
      startListeningToClientAuth(this)
    }

    if (firm.length) {
      const firmID = firm

      this.setState({
        client: {
          ...this.state.client,
          firm: firm,
        },
      })

      if (!firmObject) {
        getFirmDetails(firmID, this)
      } else {
        this.setState({
          firm: firmObject,
        })
      }

      if (!firmAdvisors) {
        getFirmAdvisors(firmID, this)
      } else {
        this.setState({
          advisors: firmAdvisors,
        })
      }

      // if (!firmDisclosures) {
      //   getFirmDisclosures(firmID, this)
      // } else {
      //   this.setState({
      //     firmDisclosures: firmDisclosures
      //   })
      // }
    }

    if (advisorID && advisorID.length) {
      this.setState({
        client: {
          ...this.state.client,
          advisor: advisorID,
        },
      })
    }

    if (advisor) {
      this.setState({
        advisor: advisor,
      })
    }

    if (location === 'client-profile' && client && clientID) {
      var clientObject = client
      delete clientObject['id']

      this.setState(
        {
          userAddSuccess: true,
          uid: clientID,
          client: Object.assign({}, this.state.client, clientObject),
        },
        () => {
          this.setState({
            tabIndex: 1,
          })
        }
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, setSavingClientID, setClientAdvisorID } = this.props

    // Update ClientForm with new UID on client add
    if (!prevState.uid && this.state.uid) {
      setSavingClientID(this.state.uid)
    }

    // Set advisor ID to listen to details/disclosures in ClientForm
    if (!prevState.client.advisor && this.state.client.advisor) {
      setClientAdvisorID(this.state.client.advisor)
    }

    // Update advisor details on props change
    if (this.props.advisor !== this.state.advisor) {
      this.setState({
        advisor: this.props.advisor,
      })
    }

    // Increase step on successful user add
    if (
      location === 'dashboard' &&
      !prevState.userAddSuccess &&
      this.state.userAddSuccess
    ) {
      this.setState((prevState) => {
        return { tabIndex: prevState.tabIndex + 1 }
      })
    }

    // Sign out client user after 10 seconds on add success
    if (this.state.addSuccess) {
      setTimeout(function () {
        signOutClientUser()
      }, 10000)
    }

    // Add firm to client user if it is overwritten
    if (!this.state.client.firm && this.props.firm.length) {
      this.setState({
        client: {
          ...this.state.client,
          firm: this.props.firm,
        },
      })
    }

    // Add email to client user if it is overwritten
    if (
      prevState.client.email &&
      prevState.client.email.length &&
      !this.state.client.email.length
    ) {
      this.setState({
        client: {
          ...this.state.client,
          email: prevState.client.email,
        },
      })
    }
  }

  componentWillUnmount() {
    const { location, firm, firmObject, firmAdvisors } = this.props

    if (location !== 'dashboard' && location !== 'client-profile') {
      signOutClientUser()

      stopListeningToClientAuth()
    }

    if (firm.length) {
      if (!firmObject) {
        stopListeningToFirmDetails()
      }

      if (!firmAdvisors) {
        stopListeningToFirmAdvisors()
      }

      // if (!firmDisclosures) {
      //   stopListeningToFirmDisclosures()
      // }
    }

    // if ((this.state.advisor && !this.props.advisor) || this.state.client.advisor) {
    //   stopListeningToAdvisorDetails()
    // }

    // if (this.state.client.advisor) {
    //   stopListeningToAdvisorDisclosures()
    // }
  }

  attemptContinue(event) {
    event.preventDefault()

    this.validateStep(this.state.tabIndex)
  }

  // getAdvisorDetails() {
  // if (this.state.client.advisor.length > 0) {
  //   getAdvisorDetails(this.state.client.advisor, this)
  //   getAdvisorDisclosures(this.state.client.advisor, this)
  // }
  // }

  handleClientLogin() {
    if (this.state.errors.length === 0) {
      signInClientUser(this.state.client.email, this.state.password, this)
    }
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'email' && !this.state.client.email.length) {
      // Start signup timer and update email
      this.setState({
        client: {
          ...this.state.client,
          [name]: value,
          dateStarted: new Date(),
        },
      })
    } else if (name === 'accept_disclosures' || name === 'password') {
      // Top level state changes
      this.setState({
        [name]: value,
      })
    } else if (name === 'advisor') {
      // Advisor state change
      this.setState({
        client: {
          ...this.state.client,
          [name]: value,
        },
      })
    } else {
      // Client state changes
      this.setState(
        {
          client: {
            ...this.state.client,
            [name]: value,
          },
        },
        function () {
          // Client legal name changes
          if (
            name === 'legal_name_first' ||
            name === 'legal_name_mi' ||
            name === 'legal_name_last'
          ) {
            var fullLegalName = this.state.client.legal_name_first + ' '
            if (this.state.client.legal_name_mi.length > 0) {
              fullLegalName += this.state.client.legal_name_mi + ' '
            }
            fullLegalName += this.state.client.legal_name_last

            this.setState({
              client: {
                ...this.state.client,
                legal_name: fullLegalName,
              },
            })
          }

          // // Second Investor legal name changes
          // if (name === 'second_investor_legal_name_first' || name === 'second_investor_legal_name_mi' || name === 'second_investor_legal_name_last') {
          //   var fullSecondLegalName = this.state.client.second_investor_legal_name_first + ' '
          //   if (this.state.client.second_investor_legal_name_mi.length > 0) {
          //     fullSecondLegalName += this.state.client.second_investor_legal_name_mi + ' '
          //   }
          //   fullSecondLegalName += this.state.client.second_investor_legal_name_last

          //   this.setState({
          //     client: {
          //       ...this.state.client,
          //       'second_investor_legal_name': fullSecondLegalName
          //     }
          //   })
          // }
        }
      )
    }
  }

  increaseStep() {
    const {
      saveClientToDB,
      addAdditionalDocuments,
      firmDisclosures,
      advisorDisclosures,
    } = this.props
    const { uid, tabIndex, errors, client } = this.state

    if (tabIndex === 0) {
      this.handleClientLogin()
      return
    }

    if (!errors.length) {
      if (tabIndex === 1) {
        saveClientToDB(client, uid)
        addAdditionalDocuments([firmDisclosures, advisorDisclosures])
      }

      this.setState((prevState) => {
        return { tabIndex: prevState.tabIndex + 1 }
      })
    }
  }

  validateStep(step) {
    const { client, password } = this.state

    // const firmID = this.props.firm

    var errors = []

    switch (step) {
      case 0:
        // Email
        if (!validEmailRegex.test(client.email)) {
          errors.push('Please enter a valid email address.')
        }

        // Password
        if (password.length < 1) {
          errors.push(
            'Please enter the password given to you by ' +
              this.state.firm.name +
              '.'
          )
        }

        break
      case 1:
        // Set validation to include certain fields for certain firms
        var fieldsToInclude = [
          'legal_name',
          'legal_name_first',
          'legal_name_last',
          // 'second_investor_legal_name',
          // 'second_investor_legal_name_first',
          // 'second_investor_legal_name_last',
          'address_street',
          'address_city',
          'address_state',
          'address_zip',
          // 'second_investor_address_street',
          // 'second_investor_address_city',
          // 'second_investor_address_state',
          // 'second_investor_address_zip',
          // 'second_investor_email',
          'advisor',
          'fee_401k',
        ]

        // Set validation to exclude certain fields for certain firms
        var fieldsToExclude = null
        // switch (firmID) {
        //   case firmIDs['centennial']:
        //     // Centennial
        //     fieldsToInclude = ['retirement_needs', 'retirement_income']
        //     break
        //   default:
        //     break
        // }

        // Client fields
        errors = errors.concat(
          validateClientFields(
            client,
            this.state.firm.name,
            fieldsToInclude,
            fieldsToExclude
          )
        )

        // // Accept Disclosures
        // if (!accept_disclosures) {
        //   errors.push("Please accept disclosures.")
        // }

        break
      default:
        break
    }

    // Set state and increase step
    this.setState(
      {
        errors: errors,
      },
      () => {
        this.increaseStep()
      }
    )
  }

  render() {
    const {
      location,
      children,
      advisorID,
      firmDisclosures,
      advisorDisclosures,
    } = this.props
    const {
      uid,
      password,
      firm,
      advisors,
      client,
      errors,
      docSubmitSuccess,
    } = this.state

    var mainClass = 'add-iac'
    if (
      location &&
      (location === 'dashboard' || location === 'client-profile')
    ) {
      mainClass += ' embedded'
    } else if (firm && firm.module && firm.module.embedded) {
      mainClass += ' embedded'
    } else {
      mainClass += ' widget'
    }

    if (docSubmitSuccess) {
      return (
        <div className={mainClass}>
          {location !== 'dashboard' && location !== 'client-profile' ? (
            <WidgetToggle />
          ) : (
            ''
          )}
          <div className="register-wrapper">
            <div className="register-container">
              <div className="signup-success">
                <h2>Welcome to {firm.name}!</h2>
                <p>Signup was successful. Please check your email!</p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    var advisorsObject = {}
    if (advisors && Object.keys(advisors).length) {
      advisorsObject = advisors
    }

    this.firm_disclosure_documents_list_items = ''
    if (firmDisclosures && Object.keys(firmDisclosures).length) {
      this.firm_disclosure_documents_list_items = Object.keys(
        firmDisclosures
      ).map((disclosure, key) => (
        <li key={disclosure}>
          <a
            title="Download"
            target="_blank"
            rel="noopener noreferrer"
            href={firmDisclosures[disclosure].link}
          >
            <DownloadIcon />
            {firmDisclosures[disclosure].name}
          </a>
        </li>
      ))
    }

    this.advisor_disclosure_documents_list_items = ''
    if (advisorDisclosures && Object.keys(advisorDisclosures).length) {
      this.advisor_disclosure_documents_list_items = Object.keys(
        advisorDisclosures
      ).map((disclosure, key) => (
        <li key={disclosure}>
          <a
            title="Download"
            target="_blank"
            rel="noopener noreferrer"
            href={advisorDisclosures[disclosure].link}
          >
            <DownloadIcon />
            {advisorDisclosures[disclosure].name}
          </a>
        </li>
      ))
    }

    if (firmDisclosures === null && advisorDisclosures === null) {
      this.firm_disclosure_documents_list_items = 'No documents!'
    }

    if (firmDisclosures === undefined && advisorDisclosures === undefined) {
      this.firm_disclosure_documents_list_items = 'No documents!'
    }

    const tabs = ['1. Profile', '2. Info', '3. Agreement']

    return (
      <Tabs
        selectedIndex={this.state.tabIndex}
        onSelect={(tabIndex) => this.setState({ tabIndex })}
      >
        <TabList>
          {tabs.map((tab, idx) => (
            <Tab key={idx} disabled={true}>
              {tab}
            </Tab>
          ))}
        </TabList>

        <TabPanel>
          {location !== 'dashboard' && location !== 'client-profile' ? (
            <h2>
              Get set up with <br />
              {firm.name} <br />
              in minutes!
            </h2>
          ) : (
            ''
          )}

          <div className="step-contents">
            {location !== 'dashboard' && location !== 'client-profile' ? (
              <Fragment>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    value={client.email}
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="email"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    value={password}
                    type="password"
                    placeholder="Password"
                    name="password"
                    id="password"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group submit-section">
                  <ErrorsList errors={errors} />

                  <button
                    className="continue"
                    onClick={this.attemptContinue}
                    type="button"
                  >
                    Create account
                  </button>
                </div>
              </Fragment>
            ) : (
              <AddNewFirmClientsModule
                firmID={this.props.firm}
                advisorID={advisorID}
                type="Client"
                onboardingModule={this}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          {uid.length > 0 ? (
            <form id="register-client">
              <div className="step-contents">
                <h3>First Investor</h3>

                <Field
                  type="text"
                  id="legal_name_first"
                  label="Legal First Name"
                  required={true}
                  value={client.legal_name_first}
                  handleInputChange={this.handleInputChange}
                />
                <Field
                  type="text"
                  id="legal_name_mi"
                  label="Middle Initial"
                  placeholder="MI"
                  value={client.legal_name_mi}
                  handleInputChange={this.handleInputChange}
                />
                <Field
                  type="text"
                  id="legal_name_last"
                  label="Legal Last Name"
                  required={true}
                  value={client.legal_name_last}
                  handleInputChange={this.handleInputChange}
                />
                <Field
                  type="email"
                  id="email_2"
                  label="Email"
                  value={client.email}
                  readOnly={true}
                />

                <AddressFields
                  labelFor="client-address"
                  label="Legal Address"
                  required={true}
                  values={{
                    street: {
                      id: 'address_street',
                      value: client.address_street,
                    },
                    city: {
                      id: 'address_city',
                      value: client.address_city,
                    },
                    state: {
                      id: 'address_state',
                      value: client.address_state,
                    },
                    zip: {
                      id: 'address_zip',
                      value: client.address_zip,
                    },
                  }}
                  handleInputChange={this.handleInputChange}
                />
                {/* 
                <Field type='select' options="yes-no" id='second_investor_has_same_address' label='Is the above address the same for second investor (if applicable)?' value={client.second_investor_has_same_address} showLabel={true} handleInputChange={this.handleInputChange} />

                <h3>Second Investor</h3>

                <Field type='text' id='second_investor_legal_name_first' label='Legal First Name' required={client.second_investor_legal_name.length > 0} value={client.second_investor_legal_name_first} handleInputChange={this.handleInputChange} />
                <Field type='text' id='second_investor_legal_name_mi' label='Middle Initial' placeholder="MI" value={client.second_investor_legal_name_mi} handleInputChange={this.handleInputChange} />
                <Field type='text' id='second_investor_legal_name_last' label='Legal Last Name' required={client.second_investor_legal_name.length > 0} value={client.second_investor_legal_name_last} handleInputChange={this.handleInputChange} />
                <Field type='email' id='second_investor_email' label='Email' required={client.second_investor_legal_name.length > 0} value={client.second_investor_email} handleInputChange={this.handleInputChange} /> */}
                {/* 
                {client.second_investor_has_same_address !== 'Yes' &&
                  <AddressFields
                    labelFor="client-second-investor-address"
                    label="Legal Address"
                    required={true}
                    values={{
                      street: {
                        id: 'second_investor_address_street',
                        value: client.second_investor_address_street
                      },
                      city: {
                        id: 'second_investor_address_city',
                        value: client.second_investor_address_city
                      },
                      state: {
                        id: 'second_investor_address_state',
                        value: client.second_investor_address_state
                      },
                      zip: {
                        id: 'second_investor_address_zip',
                        value: client.second_investor_address_zip
                      }
                    }}
                    handleInputChange={this.handleInputChange} />} */}

                {/* TODO: [BTS-246] Only on non-advisor dashboards? */}
                <h3>Advisor</h3>
                <Field
                  type="select"
                  id="advisor"
                  required={true}
                  options={Object.keys(advisorsObject).map((advisor) => {
                    const advisorObject = {
                      value: advisor,
                      label: advisorsObject[advisor].name,
                    }
                    return advisorObject
                  })}
                  label="Who are you working with?"
                  value={client.advisor}
                  handleInputChange={this.handleInputChange}
                />

                <h3>Fee</h3>
                <Field
                  type="numberMoneyMonth"
                  id="fee_401k"
                  label="401(k) Fee"
                  required={true}
                  value={client.fee_401k}
                  placeholder="(ex. $200 /month)"
                  handleInputChange={this.handleInputChange}
                />
              </div>

              <div className="form-group submit-section">
                <ErrorsList errors={errors} sticky={true} />
                <ul className="errors">{this.errors}</ul>

                <div className="instructional info-correct">
                  <small>
                    I agree all of the information is correct to the best of my
                    knowledge.
                  </small>
                </div>
                <button
                  className="continue"
                  onClick={this.attemptContinue}
                  type="button"
                >
                  Continue
                </button>
              </div>
            </form>
          ) : (
            'There was an error. Please contact Bitsy. [RC-NUID]'
          )}
        </TabPanel>
        <TabPanel>{children}</TabPanel>
      </Tabs>
    )
  }
}

export default K401Form
