import React from 'react'
// import HeaderBar from './header-bar'

const ComponentHeader = (props) => {
  if (props.mutable) {
    return null
  }
  return (
    <div>
      {/* {props.options.data.pageBreakBefore && (
        <div className="preview-page-break">Page Break</div>
      )} */}
      {/* <HeaderBar
        parent={props.parent}
        editModeOn={props.editModeOn}
        data={props.options.data}
        onDestroy={props._onDestroy}
        onEdit={props.onEdit}
        static={props.options.data.static}
        required={props.options.data.required}
      /> */}
    </div>
  )
}

export default ComponentHeader
