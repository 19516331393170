import React, { memo, useState, useEffect, Fragment } from 'react'
import { Form, Input, Button } from 'semantic-ui-react'
import Tip from 'components/partials/Tip'
import ErrorsList from 'components/partials/ErrorsList'
import RichTextEditor from 'components/partials/RichTextEditor'
import { useFirm } from 'contexts'
import { updateItemInfo } from 'actions/db/dbActions'
import styled from 'styled-components'

const CHARACTERS = [
  'ADVISOR_NAME',
  'ADVISOR_EMAIL',
  'FIRM_NAME',
  'FIRM_EMAIL',
  'CLIENT_NAME',
  'CLIENT_EMAIL',
]

const defaultEmailSettings = {
  disclosures: {
    title: 'Disclosure Emails',
    description:
      'Applies to disclosure emails sent from the client profile or during the form-filling/contract execution process.',
    subject: 'Disclosure Documents',
    body:
      '<p>Hi <mention>CLIENT_NAME</mention>,</p><p></p><p><mention>FIRM_NAME</mention> has sent disclosures for your review. Please see the files attached!</p><p></p><p>No further action is required on your part.</p>',
  },
}

const EmailSettingsEditor = memo(() => {
  const { firm, firmID } = useFirm()

  const initialEmailSettings =
    firm && firm.emailSettings
      ? Object.assign({}, defaultEmailSettings, firm.emailSettings)
      : defaultEmailSettings

  const [emailSettings, setEmailSettings] = useState(initialEmailSettings)
  const [saving, setSaving] = useState(null)
  const [saveSuccess, setSaveSuccess] = useState(null)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (saveSuccess) {
      setTimeout(() => setSaveSuccess(null), 3000)
    }
  }, [saveSuccess])

  const handleSettingChange = ({ emailType, setting, value }) => {
    setEmailSettings((prevSettings) => ({
      ...prevSettings,
      [emailType]: { ...prevSettings[emailType], [setting]: value },
    }))
  }

  const handleSaveSetting = async ({ emailType, setting }) => {
    setSaving(`${emailType}-${setting}`)

    const updateRes = await updateItemInfo(
      'firm',
      firmID,
      'emailSettings',
      emailSettings,
      null,
      true
    )

    let errorMessage = `There was an error updating ${setting} for ${emailSettings[emailType].title}. Please try again.`
    if (updateRes) {
      if (updateRes.type === 'success') {
        setSaveSuccess(`${emailType}-${setting}`)
      } else if (updateRes.type === 'error') {
        if (updateRes.error) {
          errorMessage = updateRes.error.message
        }
        setErrors([errorMessage])
      }
    } else {
      setErrors([errorMessage])
    }

    setSaving(null)
  }

  return (
    <Form>
      {Object.keys(emailSettings).map((emailType) => {
        const email = emailSettings[emailType]
        const { title, description } = email

        return (
          <Fragment key={emailType}>
            <TitleContainer>
              <h4>{title}</h4>
              <Tip header={null} content={description} />
            </TitleContainer>
            <Form.Field>
              <label htmlFor={`${emailType}-subject`}>Subject</label>
              <Input
                id={`${emailType}-subject`}
                placeholder="iac"
                fluid
                value={emailSettings[emailType].subject}
                onChange={(e, { value }) =>
                  handleSettingChange({ emailType, setting: 'subject', value })
                }
                action={
                  firm &&
                  (saveSuccess === `${emailType}-subject` ||
                    !firm.emailSettings?.[emailType]?.subject ||
                    firm.emailSettings[emailType].subject !==
                      emailSettings[emailType].subject) ? (
                    <Button
                      icon={
                        saveSuccess && saveSuccess === `${emailType}-subject`
                          ? 'check'
                          : 'save'
                      }
                      primary
                      onClick={() =>
                        handleSaveSetting({ emailType, setting: 'subject' })
                      }
                      disabled={saving && saving === `${emailType}-subject`}
                      loading={saving && saving === `${emailType}-subject`}
                    />
                  ) : null
                }
              />
            </Form.Field>
            <ErrorsList errors={errors} />
            <Form.Field>
              <label htmlFor={`${emailType}-body`}>Body</label>
              <StyledRichTextEditor
                initialValue={emailSettings[emailType].body}
                CHARACTERS={CHARACTERS}
                setHtml={(html) =>
                  handleSettingChange({
                    emailType,
                    setting: 'body',
                    value: html,
                  })
                }
              />
              {firm &&
                (saveSuccess === `${emailType}-body` ||
                  !firm.emailSettings?.[emailType]?.body ||
                  firm.emailSettings[emailType].body !==
                    emailSettings[emailType].body) && (
                  <ButtonContainer>
                    <Button
                      icon={
                        saveSuccess && saveSuccess === `${emailType}-body`
                          ? 'check'
                          : 'save'
                      }
                      primary
                      onClick={() =>
                        handleSaveSetting({ emailType, setting: 'body' })
                      }
                      disabled={saving && saving === `${emailType}-body`}
                      loading={saving && saving === `${emailType}-body`}
                    />
                  </ButtonContainer>
                )}
            </Form.Field>
          </Fragment>
        )
      })}
    </Form>
  )
})

export default EmailSettingsEditor

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;

  h4 {
    margin-bottom: 0;
    margin-right: 8px;
  }
`

const StyledRichTextEditor = styled(RichTextEditor)`
  padding-bottom: calc(0.678571em + 36px);
`

const ButtonContainer = styled.div`
  &&& {
    text-align: right;
    margin-top: 9px;
    position: absolute;
    bottom: 0;
    right: 0;

    & button {
      margin-right: 0;
    }
  }
`
