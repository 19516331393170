import React, { memo } from 'react'
import { Card, Statistic } from 'semantic-ui-react'
import LegacyCard from '../LegacyCard'
import TabHeader from '../TabHeader'
import TabContents from '../TabContents'
import PeopleTable from '../PeopleTable'
import EnvelopesStatusTable from '../EnvelopesStatusTable'
import AdvisorInviteForm from 'components/forms/AdvisorInviteForm'
import {
  DocumentsTab,
  FormsTab,
  IntegrationsTab,
  SettingsTab,
  ExportTab,
  StatusTab,
} from './tabs'
import {
  calculateHoursGained,
  calculatePeopleOnboarded,
  calculateTotalAssets,
} from 'actions/dashboardHelpers'
import { useUser } from 'contexts'
import { Row, ColMd12 } from 'components/styled'
import styled from 'styled-components'
import { ClockIcon, MoneyBagIcon, PersonIcon } from 'components/icons'
import PoweredByBitsy from 'components/partials/PoweredByBitsy'

function userFirstNameString(
  textToPrepend = null,
  user = null,
  textToAppend = null
) {
  var string = ''

  // Prepend text
  if (textToPrepend && textToPrepend.length) {
    string += textToPrepend
  }

  // Add name if it exists
  var name = null
  if (user) {
    if (user.preferred_name) {
      name = user.preferred_name
    } else if (user.legal_name_first && user.legal_name_first.length) {
      name = user.legal_name_first
    } else if (user.contact_name) {
      if (user.contact_name.split(' ') && user.contact_name.split(' ').length) {
        name = user.contact_name.split(' ')[0]
      } else {
        name = user.contact_name
      }
    } else if (user.name) {
      name = user.name
    }
  }
  if (name) {
    string += name
  }
  if (string.endsWith(', ')) {
    string = string.slice(0, -2)
  }

  // Append text
  if (textToAppend) {
    string += textToAppend
  }

  return string
}

const NoneYet = () => <NoneYetText>None yet!</NoneYetText>

const NoneYetText = styled.span`
  text-transform: none;
  font-style: italic;
  font-size: 80%;
  line-height: 80%;
  opacity: 0.7;
`

export default memo(function DashboardContents({
  tab,
  firmID,
  clients,
  advisors,
  firms,
  goToTab,
  isAdmin,
}) {
  const { user } = useUser()

  const renderTabHeader = (tab) => {
    switch (tab) {
      case 'Home':
        return (
          <TabHeader
            heading={userFirstNameString('Hi, ', user, '!')}
            subhead="Welcome to your dashboard"
          />
        )
      default:
        return <TabHeader />
    }
  }

  function renderTab(tab) {
    switch (tab) {
      case 'Home':
        const totalAssetsGathered = calculateTotalAssets(clients)
        const totalHoursGained = calculateHoursGained(clients)
        const totalClientsEnrolled = calculatePeopleOnboarded(
          clients,
          'clients'
        )

        // Admin stats
        var totalFirmsEnrolled = null
        var totalAdvisorsEnrolled = null
        if (isAdmin) {
          totalFirmsEnrolled = calculatePeopleOnboarded(firms, 'firms')
          totalAdvisorsEnrolled = calculatePeopleOnboarded(advisors, 'advisors')
        }

        return (
          <>
            <Row>
              <ColMd12>
                <StatisticCardGroup>
                  <Card>
                    <StatisticCardContent>
                      <StatisticIcon>
                        <MoneyBagIcon />
                      </StatisticIcon>
                      <Statistic size="small">
                        <Statistic.Label>Assets Gathered</Statistic.Label>
                        <Statistic.Value>
                          {totalAssetsGathered === '0' ? (
                            <NoneYet />
                          ) : (
                            totalAssetsGathered
                          )}
                        </Statistic.Value>
                      </Statistic>
                    </StatisticCardContent>
                  </Card>

                  <Card>
                    <StatisticCardContent>
                      <StatisticIcon>
                        <ClockIcon />
                      </StatisticIcon>
                      <Statistic size="small">
                        <Statistic.Label>Hours Gained</Statistic.Label>
                        <Statistic.Value>
                          {totalHoursGained === '0' ? (
                            <NoneYet />
                          ) : (
                            totalHoursGained
                          )}
                        </Statistic.Value>
                      </Statistic>
                    </StatisticCardContent>
                  </Card>

                  {!isAdmin && (
                    <Card link onClick={() => goToTab('Clients')}>
                      <StatisticCardContent>
                        <StatisticIcon>
                          <PersonIcon />
                        </StatisticIcon>
                        <Statistic size="small">
                          <Statistic.Label>Clients Enrolled</Statistic.Label>
                          <Statistic.Value>
                            {totalClientsEnrolled === '0' ? (
                              <NoneYet />
                            ) : (
                              totalClientsEnrolled
                            )}
                          </Statistic.Value>
                        </Statistic>
                      </StatisticCardContent>
                    </Card>
                  )}

                  {totalFirmsEnrolled && (
                    <Card>
                      <StatisticCardContent>
                        <StatisticIcon>
                          <PersonIcon />
                        </StatisticIcon>
                        <Statistic size="small">
                          <Statistic.Label>Firms Enrolled</Statistic.Label>
                          <Statistic.Value>
                            {totalFirmsEnrolled === '0' ? (
                              <NoneYet />
                            ) : (
                              totalFirmsEnrolled
                            )}
                          </Statistic.Value>
                        </Statistic>
                      </StatisticCardContent>
                    </Card>
                  )}

                  {totalAdvisorsEnrolled && (
                    <Card>
                      <StatisticCardContent>
                        <StatisticIcon>
                          <PersonIcon />
                        </StatisticIcon>
                        <Statistic size="small">
                          <Statistic.Label>Advisors Enrolled</Statistic.Label>
                          <Statistic.Value>
                            {totalAdvisorsEnrolled === '0' ? (
                              <NoneYet />
                            ) : (
                              totalAdvisorsEnrolled
                            )}
                          </Statistic.Value>
                        </Statistic>
                      </StatisticCardContent>
                    </Card>
                  )}

                  {isAdmin && (
                    <Card>
                      <StatisticCardContent>
                        <StatisticIcon>
                          <PersonIcon />
                        </StatisticIcon>
                        <Statistic size="small">
                          <Statistic.Label>Clients Enrolled</Statistic.Label>
                          <Statistic.Value>
                            {totalClientsEnrolled === '0' ? (
                              <NoneYet />
                            ) : (
                              totalClientsEnrolled
                            )}
                          </Statistic.Value>
                        </Statistic>
                      </StatisticCardContent>
                    </Card>
                  )}
                </StatisticCardGroup>
              </ColMd12>
            </Row>

            {!isAdmin && (
              <>
                <LegacyCard title="Signing Status" type="table">
                  <EnvelopesStatusTable className="table dt-responsive nowrap clients-list w-100" />
                </LegacyCard>
                <LegacyCard
                  title="Clients list"
                  headerActionLink={{
                    title: 'View All',
                    action: () => goToTab('Clients'),
                    className: 'd-md-none',
                  }}
                  type="table"
                  className="mb-0"
                >
                  <PeopleTable type="clients" pageSize={5} />
                </LegacyCard>
              </>
            )}

            {/* {isAdmin && firms &&
              <LegacyCard
                title="Firms list"
                headerActionLink={{
                  title: "View All",
                  action: () => goToTab('Firms'),
                  className: "d-md-none"
                }}
                type="table"
                className="mb-0"
                headerClassName="d-flex align-items-center justify-content-between border-0"
              >
                <PeopleTable
                  type="firms"
                  people={firms}
                  // advisors={advisors}
                  pageSize={5}
                  className="table dt-responsive nowrap clients-list w-100"
                />
              </LegacyCard>
            } */}
          </>
        )
      case 'Status':
        return <StatusTab />
      case 'Clients':
        return (
          <LegacyCard title="Clients list" type="table" className="mb-0">
            <PeopleTable type="clients" />
          </LegacyCard>
        )
      // case 'Suitability':
      //   return <SuitabilityTab location="advisor-dashboard" />
      case 'Advisors':
        return (
          <>
            <LegacyCard title="Advisors list" type="table">
              <PeopleTable type="advisors" />
            </LegacyCard>
            {!isAdmin && (
              <LegacyCard title="Invite advisors" className="mx-auto">
                <AdvisorInviteForm
                  firmID={firmID}
                  firmName={user.name}
                  allowedAdvisors={user.advisors}
                />
              </LegacyCard>
            )}
          </>
        )
      case 'Forms':
        return <FormsTab />
      case 'Documents':
        return <DocumentsTab />
      case 'Integrations':
        return <IntegrationsTab />
      case 'Settings':
        return <SettingsTab />
      case 'Export':
        return <ExportTab />
      default:
        return ''
    }
  }

  return (
    <ContentWrapper role="tabpanel" aria-label={tab}>
      {renderTabHeader(tab)}
      <TabContents>
        {renderTab(tab)}

        <Footer>
          <StyledPoweredByBitsy link="https://support.bitsyadvisor.com/" />
        </Footer>
      </TabContents>
    </ContentWrapper>
  )
})

const ContentWrapper = styled.div`
  background: #f9f9f9;
  margin: 0 -15px;
  flex-grow: 1;
`

const StatisticCardGroup = styled(Card.Group)`
  &&& {
    margin: -0.875em -1em;
    justify-content: center;
    flex-wrap: nowrap;
    margin-bottom: calc(30px - 0.875em);

    & > .ui.card {
      margin: 0.875em 1em;
      flex-basis: calc(33.333% - 2em);
      flex-grow: 0;
      flex-shrink: 0;
    }

    @media screen and (max-width: 1099px) {
      flex-wrap: wrap;

      & > .ui.card {
        flex-basis: calc(100% - 2em);
      }
    }
  }
`

const StatisticCardContent = styled(Card.Content)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;

    & > .statistic {
      flex-grow: 1;
    }

    & > .statistic > .label,
    & > .statistic > .value {
      text-align: left;
    }
  }
`

const StatisticIcon = styled.div`
  &&& {
    background: #e9f1f0;
    display: inline-block;
    border-radius: 50% !important;
    width: auto;
    width: 70px;
    height: 70px;
    margin-right: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Footer = styled.div`
  position: relative;
  margin-top: 30px;
  margin-bottom: -0.25rem;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
`

const StyledPoweredByBitsy = styled(PoweredByBitsy)`
  position: relative !important;
  margin-left: auto;
  bottom: 0;
`
