import { firebaseDB } from '../config/firebaseConfig'

let unsubscribeAllClients
export function getAllClients(state) {
  unsubscribeAllClients = firebaseDB
    .collection('clients')
    .onSnapshot(function (querySnapshot) {
      var clients = {}
      querySnapshot.forEach(function (doc) {
        clients[doc.id] = doc.data()
      })

      state.setState({
        clients: clients,
      })
    })
}
export function stopListeningToAllClients() {
  // Stop listening to changes
  unsubscribeAllClients()
}

let unsubscribeAllAdvisors
export function getAllAdvisors(state) {
  unsubscribeAllAdvisors = firebaseDB
    .collection('advisors')
    .onSnapshot(function (querySnapshot) {
      var advisors = {}
      querySnapshot.forEach(function (doc) {
        advisors[doc.id] = doc.data()
      })

      state.setState({
        advisors: advisors,
      })
    })
}
export function stopListeningToAllAdvisors() {
  // Stop listening to changes
  unsubscribeAllAdvisors()
}

let unsubscribeAllFirms
export function getAllFirms(state) {
  unsubscribeAllFirms = firebaseDB
    .collection('firms')
    .onSnapshot(function (querySnapshot) {
      var firms = {}
      querySnapshot.forEach(function (doc) {
        firms[doc.id] = doc.data()
      })

      state.setState({
        firms: firms,
      })
    })
}
export function stopListeningToAllFirms() {
  // Stop listening to changes
  unsubscribeAllFirms()
}

export function updateFirmServiceStatus(field, value, uid) {
  var fieldName = 'hasServices.' + field

  firebaseDB
    .collection('firms')
    .doc(uid)
    .update({
      [fieldName]: value,
    })
    .then(function () {
      // console.log("Firm status updated!")
    })
    .catch(function (error) {
      // console.error("Error updating status: ", error)
    })
}

export function updateADVDefaultCopy(type, value) {
  firebaseDB
    .collection('default')
    .doc('advCopy')
    .update({
      [type]: value,
    })
    .then(function () {
      // console.log("Default copy updated!")
    })
    .catch(function (error) {
      // console.error("Error updating default copy: ", error)
    })
}
