export const calculateGwnClient = (client) => {
  let calculatedClient = client

  // Time Horizon
  let timeHorizonPoints = 0

  // 1
  switch (client.profile_time_horizon_withdrawing) {
    case 'Less than 3 years':
      timeHorizonPoints += 1
      break
    case '3-5 years':
      timeHorizonPoints += 3
      break
    case '6-10 years':
      timeHorizonPoints += 7
      break
    case '11 years or more':
      timeHorizonPoints += 10
      break
    default:
      break
  }

  // 2
  switch (client.profile_time_horizon_spending) {
    case 'Less than 2 years':
      timeHorizonPoints += 0
      break
    case '2 - 5 years':
      timeHorizonPoints += 1
      break
    case '6 - 10 years':
      timeHorizonPoints += 4
      break
    case '11 years or more':
      timeHorizonPoints += 8
      break
    default:
      break
  }

  calculatedClient['subtotal_time_horizon'] = timeHorizonPoints

  // Risk Tolerance
  let riskTolerancePoints = 0

  // 1
  switch (client.profile_risk_tolerance_knowledge) {
    case 'None':
      riskTolerancePoints += 0
      break
    case 'Limited':
      riskTolerancePoints += 2
      break
    case 'Good':
      riskTolerancePoints += 4
      break
    case 'Extensive':
      riskTolerancePoints += 6
      break
    default:
      break
  }

  // 2
  switch (client.profile_risk_tolerance_concern) {
    case 'Most concerned about my investment losing money':
      riskTolerancePoints += 0
      break
    case 'Equally concerned about my investment losing or gaining value':
      riskTolerancePoints += 4
      break
    case 'Most concerned about my investment gaining value':
      riskTolerancePoints += 8
      break
    default:
      break
  }

  // 3
  switch (client.profile_risk_tolerance_owned_investments) {
    case 'Money Market Funds or Cash Equivalents':
      riskTolerancePoints += 0
      break
    case 'Bonds and/or Bond Funds':
      riskTolerancePoints += 3
      break
    case 'Stocks and/or Stock Funds':
      riskTolerancePoints += 6
      break
    case 'International Securities and/or International Funds':
      riskTolerancePoints += 8
      break
    default:
      break
  }

  // 4
  switch (client.profile_risk_tolerance_scenario) {
    case 'Sell all of my shares':
      riskTolerancePoints += 0
      break
    case 'Sell some of my shares':
      riskTolerancePoints += 2
      break
    case 'Do nothing':
      riskTolerancePoints += 5
      break
    case 'Buy more shares':
      riskTolerancePoints += 8
      break
    default:
      break
  }

  // 5
  switch (client.profile_risk_tolerance_chart) {
    case 'Plan A':
      riskTolerancePoints += 0
      break
    case 'Plan B':
      riskTolerancePoints += 3
      break
    case 'Plan C':
      riskTolerancePoints += 6
      break
    case 'Plan D':
      riskTolerancePoints += 8
      break
    case 'Plan E':
      riskTolerancePoints += 10
      break
    default:
      break
  }

  calculatedClient['subtotal_risk_tolerance'] = riskTolerancePoints

  // Investment Objective
  let investmentObjective = ''
  if (timeHorizonPoints >= 3 && timeHorizonPoints <= 4) {
    if (riskTolerancePoints >= 0 && riskTolerancePoints <= 18) {
      investmentObjective = 'Conservative'
    } else if (riskTolerancePoints >= 19 && riskTolerancePoints <= 31) {
      investmentObjective = 'Moderately Conservative'
    } else if (riskTolerancePoints >= 32 && riskTolerancePoints <= 40) {
      investmentObjective = 'Moderate'
    }
  } else if (timeHorizonPoints >= 5 && timeHorizonPoints <= 6) {
    if (riskTolerancePoints >= 0 && riskTolerancePoints <= 15) {
      investmentObjective = 'Conservative'
    } else if (riskTolerancePoints >= 16 && riskTolerancePoints <= 24) {
      investmentObjective = 'Moderately Conservative'
    } else if (riskTolerancePoints >= 25 && riskTolerancePoints <= 35) {
      investmentObjective = 'Moderate'
    } else if (riskTolerancePoints >= 36 && riskTolerancePoints <= 40) {
      investmentObjective = 'Moderately Aggressive'
    }
  } else if (timeHorizonPoints >= 7 && timeHorizonPoints <= 9) {
    if (riskTolerancePoints >= 0 && riskTolerancePoints <= 12) {
      investmentObjective = 'Conservative'
    } else if (riskTolerancePoints >= 13 && riskTolerancePoints <= 20) {
      investmentObjective = 'Moderately Conservative'
    } else if (riskTolerancePoints >= 21 && riskTolerancePoints <= 28) {
      investmentObjective = 'Moderate'
    } else if (riskTolerancePoints >= 29 && riskTolerancePoints <= 37) {
      investmentObjective = 'Moderately Aggressive'
    } else if (riskTolerancePoints >= 38 && riskTolerancePoints <= 40) {
      investmentObjective = 'Aggressive'
    }
  } else if (timeHorizonPoints >= 10 && timeHorizonPoints <= 13) {
    if (riskTolerancePoints >= 0 && riskTolerancePoints <= 11) {
      investmentObjective = 'Conservative'
    } else if (riskTolerancePoints >= 12 && riskTolerancePoints <= 18) {
      investmentObjective = 'Moderately Conservative'
    } else if (riskTolerancePoints >= 19 && riskTolerancePoints <= 26) {
      investmentObjective = 'Moderate'
    } else if (riskTolerancePoints >= 27 && riskTolerancePoints <= 34) {
      investmentObjective = 'Moderately Aggressive'
    } else if (riskTolerancePoints >= 35 && riskTolerancePoints <= 40) {
      investmentObjective = 'Aggressive'
    }
  } else if (timeHorizonPoints >= 14 && timeHorizonPoints <= 18) {
    if (riskTolerancePoints >= 0 && riskTolerancePoints <= 10) {
      investmentObjective = 'Conservative'
    } else if (riskTolerancePoints >= 11 && riskTolerancePoints <= 17) {
      investmentObjective = 'Moderately Conservative'
    } else if (riskTolerancePoints >= 18 && riskTolerancePoints <= 24) {
      investmentObjective = 'Moderate'
    } else if (riskTolerancePoints >= 25 && riskTolerancePoints <= 31) {
      investmentObjective = 'Moderately Aggressive'
    } else if (riskTolerancePoints >= 32 && riskTolerancePoints <= 40) {
      investmentObjective = 'Aggressive'
    }
  }

  calculatedClient['investment_objective'] = investmentObjective

  // Prefill client.phone_home with client.phone
  if (!client.phone_home && client.phone) {
    calculatedClient.phone_home = client.phone
  }

  return calculatedClient
}

export const generateGwnInvestmentExperienceItems = (client) => {
  let investment_experience_items = []

  const accountTypes = [
    { type: 'Mutual Funds/ETFs', id: 'mutual_funds_etfs' },
    { type: 'Individual Stocks', id: 'individual_stocks' },
    { type: 'Bonds', id: 'bonds' },
    { type: 'Options', id: 'options' },
    { type: 'Securities Futures', id: 'securities_futures' },
    { type: 'Annuities', id: 'annuities' },
    { type: 'Alternative', id: 'alternative' },
    { type: 'Margin', id: 'margin' },
  ]

  accountTypes.forEach((account) => {
    const fieldName = `investment_experience_${account.id}`
    const years = client[`${fieldName}_years`]
    const transactions = client[`${fieldName}_transactions`]

    investment_experience_items.push(
      <li key={account.id}>
        <div className="label">{account.type}</div>
        <div className="value">{years} years</div>
        <div className="value">{transactions} transactions per year</div>
      </li>
    )
  })

  return investment_experience_items
}

export const validateGwnClient = (client, errors, templateId) => {
  const {
    decisions_consult,
    decisions_family_friends,
    decisions_own,
    gwn_funds_allocated,
    gwn_fund_allocation_individual_information,
    beneficiary_designations,
    generate_income_for_current_or_future_expenses,
    partially_fund_my_retirement,
    wholly_fund_my_retirement,
    steadily_accumulate_wealth_over_the_long_term,
    preserve_wealth_and_pass_it_on_to_my_heirs,
    pay_for_education,
    pay_for_a_house,
    account_use_plan_other,
  } = client

  // DECISION-MAKING OPTIONS
  if (
    !decisions_consult &&
    !decisions_family_friends &&
    !decisions_own &&
    errors.decisions_consult
  ) {
    errors.decisions_consult.addError(
      'Please select at least 1 Decision-Making option'
    )
  }

  // I PLAN TO USE THIS ACCOUNT FOR THE FOLLOWING REASONS
  if (
    !generate_income_for_current_or_future_expenses &&
    !partially_fund_my_retirement &&
    !wholly_fund_my_retirement &&
    !steadily_accumulate_wealth_over_the_long_term &&
    !preserve_wealth_and_pass_it_on_to_my_heirs &&
    !pay_for_education &&
    !pay_for_a_house &&
    !account_use_plan_other &&
    errors.account_use_plan_other
  ) {
    errors.account_use_plan_other.addError(
      'Please select at least one account use'
    )
  }

  // ASSET BASED ADVISORY FEE %
  if (
    client['mf_asset_based_advisory_fee_%'] &&
    errors['mf_asset_based_advisory_fee_%']
  ) {
    const advisoryFeePercentNum = parseFloat(
      client['mf_asset_based_advisory_fee_%'].replace('%', '')
    )
    if (advisoryFeePercentNum > 2) {
      errors['mf_asset_based_advisory_fee_%'].addError(
        'Asset Based Advisory Fee % cannot be more than 2%'
      )
    }
  }

  // MODEL/FUND ALLOCATION
  if (errors.gwn_fund_allocation_individual_information) {
    let allocatedSum = 0

    // Add up models (if they exist)
    if (
      templateId === 'GWN_MAP_SHORT_KIT' &&
      errors.gwn_funds_allocated &&
      gwn_funds_allocated &&
      Object.keys(gwn_funds_allocated)
    ) {
      Object.keys(gwn_funds_allocated).forEach((platform) => {
        const models = gwn_funds_allocated[platform]
        Object.keys(models).forEach((modelID) => {
          const percentageAllocated = models[modelID]
          const percentageNum = parseFloat(percentageAllocated.replace('%', ''))
          allocatedSum += percentageNum

          if (percentageNum < 0) {
            errors.gwn_funds_allocated[platform][modelID].addError(
              'Percentage must be greater than 0'
            )
          }
        })
      })
    }

    // Add up individual funds
    if (
      gwn_fund_allocation_individual_information &&
      gwn_fund_allocation_individual_information.length
    ) {
      gwn_fund_allocation_individual_information.forEach((fund, idx) => {
        allocatedSum += fund.percentage

        if (fund.percentage < 0) {
          errors.gwn_fund_allocation_individual_information[
            idx
          ].percentage.addError('Percentage must be greater than 0')
        }
      })
    }

    if (allocatedSum !== 100) {
      errors.gwn_fund_allocation_individual_information.addError(
        `Allocated ${
          errors.gwn_funds_allocated ? 'models and ' : ''
        }funds must equal 100%. Currently allocated: ${allocatedSum}%`
      )
    }
  }

  // BENEFICIARIES
  if (
    errors.beneficiary_designations &&
    beneficiary_designations &&
    beneficiary_designations.length
  ) {
    let beneficiarySumPrimary = 0
    let beneficiarySumContingent = 0

    let hasContingentBeneficiaries = false
    beneficiary_designations.forEach((beneficiary, idx) => {
      if (beneficiary.type_of_beneficiary) {
        if (beneficiary.type_of_beneficiary.toLowerCase() === 'primary') {
          beneficiarySumPrimary += beneficiary.share_percentage
        }

        if (beneficiary.type_of_beneficiary.toLowerCase() === 'contingent') {
          hasContingentBeneficiaries = true
          beneficiarySumContingent += beneficiary.share_percentage
        }
      }

      if (beneficiary.share_percentage < 0) {
        errors.beneficiary_designations[idx].share_percentage.addError(
          'Share % must be greater than 0'
        )
      }

      if (beneficiary.ssn && beneficiary.ssn.length > 12) {
        errors.beneficiary_designations[idx].ssn.addError(
          'SSN can be no longer than 12 characters'
        )
      }
    })

    if (beneficiarySumPrimary !== 100) {
      errors.beneficiary_designations.addError(
        `Share percentages for all primary beneficiaries must add up to 100%. Current total: ${beneficiarySumPrimary}%`
      )
    }

    if (hasContingentBeneficiaries && beneficiarySumContingent !== 100) {
      errors.beneficiary_designations.addError(
        `Share percentages for all contingent beneficiaries must add up to 100%. Current total: ${beneficiarySumContingent}%`
      )
    }
  }
}
