import { numberWithCommas, prettyNumber } from './helpers'

export function calculateHoursGained(clients) {
  var hoursGained = ''

  if (clients && Object.keys(clients).length) {
    var clientsOnboarded = parseFloat(
      calculatePeopleOnboarded(clients, 'clients')
    )
    var totalExpectedMinutes = clientsOnboarded * 60
    var totalActualMinutes = clientsOnboarded * 6

    var minutes = totalExpectedMinutes - totalActualMinutes

    var hours = minutes / 60

    var roundedHours = Math.round(hours * 100) / 100

    hoursGained = '' + numberWithCommas(roundedHours)
  } else {
    hoursGained = '0'
  }

  return hoursGained
}

export function calculatePeopleOnboarded(people, type) {
  var peopleOnboarded = ''

  if (people && Object.keys(people).length) {
    var peopleOnboardedNumber = Object.keys(people).length

    if (type === 'clients') {
      peopleOnboardedNumber = 0

      Object.keys(people).map(function (personID) {
        var person = people[personID]

        // Only include if the client was onboarded
        if (person.dateAdded) {
          peopleOnboardedNumber++
        }

        return peopleOnboardedNumber
      })
    }

    peopleOnboarded = '' + numberWithCommas(peopleOnboardedNumber)
  } else {
    peopleOnboarded = '0'
  }

  return peopleOnboarded
}

export function calculateTotalAssets(clients) {
  var totalAssets = null
  var total_assets_min = 0

  if (clients && Object.keys(clients).length) {
    Object.keys(clients).map(function (client) {
      const portfolio_value_range = clients[client].portfolio_value

      if (portfolio_value_range) {
        const portfolio_value_parts = portfolio_value_range.split(' - ')
        const client_portfolio_min = parseFloat(
          portfolio_value_parts[0].replace('$', '').replace(',', '')
        )

        total_assets_min += client_portfolio_min
      }

      return client
    })
  }

  if (total_assets_min > 0) {
    totalAssets = '$' + numberWithCommas(prettyNumber(total_assets_min))
  }

  return totalAssets
}

export function calculateYearsToRetirement(birthdate, retirementAge) {
  var yearsToRetirement = ''

  if (birthdate && retirementAge) {
    // Get age
    var today = new Date()
    var birthdateDate = new Date(birthdate)
    var age = today.getFullYear() - birthdateDate.getFullYear()
    var m = today.getMonth() - birthdateDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthdateDate.getDate())) {
      age--
    }

    var years = retirementAge - age

    yearsToRetirement = '' + numberWithCommas(years) + ' year'

    if (years > 1) {
      yearsToRetirement += 's'
    }
  }

  return yearsToRetirement
}
