import React from 'react'
import myxss from './myxss'

const ComponentLabel = (props) => {
  const hasRequiredLabel =
    props.options.data.hasOwnProperty('required') &&
    props.options.data.required === true &&
    !props.read_only

  return (
    <label className={props.className || ''}>
      <span
        dangerouslySetInnerHTML={{
          __html: myxss.process(props.options.data.label),
        }}
      />
      {hasRequiredLabel && (
        <span className="label-required badge badge-danger">Required</span>
      )}
    </label>
  )
}

export default ComponentLabel
