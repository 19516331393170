import React, { memo, useState, useEffect, useRef } from 'react'
import { Button } from 'semantic-ui-react'
import Modal from 'components/partials/LegacyModal'
import TemplateForm from 'components/forms/TemplateForm'
import { useClient, useUser } from 'contexts'
import { getDoc } from 'actions/db/_helpers'

const Task = memo(
  ({
    children,
    task,
    id,
    action: passedAction = null,
    showMessage = true,
    automaticOpen = false,
  }) => {
    const { userType } = useUser()

    const [showModal, setShowModal] = useState(false)
    const [taskClientID, setTaskClientID] = useState(null)
    const [taskClient, setTaskClient] = useState(null)

    const { client, clientID } = useClient()
    useEffect(() => {
      if ('form-review' === task.type) {
        if ('client' === userType) {
          // Use context for client details and ID
          setTaskClientID(clientID)
          setTaskClient(client)
        } else {
          // Get client details from DB
          const getClientDetails = async () => {
            let formClient

            const formDoc = await getDoc('form', task.options.formID)
            if (formDoc) {
              setTaskClientID(formDoc.clientID)

              const clientDoc = await getDoc('client', formDoc.clientID)
              if (clientDoc) {
                formClient = clientDoc
                setTaskClient(clientDoc)
              }
            }

            return formClient
          }

          getClientDetails()
        }
      }
    }, [client, clientID, task.options.formID, task.type, userType])

    // Get client details if the user is an advisor

    const toggleModal = (e) => {
      e.preventDefault()

      setShowModal(!showModal)
    }

    // Construct sender name
    let senderName = null
    if (task.options && task.options.sender && task.options.sender.name) {
      senderName = task.options.sender.name
    }

    // Construct task name
    let message = ''
    if (senderName) {
      message += senderName + ' has sent items '
    } else {
      message += 'You have new items '
    }
    message += 'for your review'

    // Use email subjects on newer tasks
    if (task.emailOptions && task.emailOptions.subject) {
      message = task.emailOptions.subject
    }

    // Construct action
    let action = toggleModal
    if (passedAction) {
      action = passedAction
    } else if (task && task.action) {
      action = task.action
    }

    // Construct action text
    let actionText = 'Review/Sign'
    if ('client' === userType) {
      actionText = 'Click here to Review/Sign'
    }

    // Set up modal content
    let modalContent = children
    switch (task.type) {
      case 'form-review':
        const { templateID, formID } = task.options

        modalContent = (
          <TemplateForm
            templateID={templateID}
            flow={'client' === userType ? 'client-review' : 'final-review'}
            clientID={taskClientID}
            client={
              taskClient && taskClient['pendingChanges']
                ? taskClient['pendingChanges']
                : null
            }
            formID={formID}
            taskID={id}
          />
        )
        break
      default:
        modalContent = children
        break
    }

    // Automatically open task
    const buttonRef = useRef(null)
    useEffect(() => {
      if (
        automaticOpen &&
        buttonRef.current &&
        buttonRef.current.ref &&
        buttonRef.current.ref.current
      ) {
        buttonRef.current.ref.current.click()
      }
    }, [automaticOpen])

    return (
      <div className={task.status !== 'incomplete' ? 'task completed' : 'task'}>
        {showMessage && <div className="message">{message}</div>}
        {task.status !== 'complete' && (
          <Button secondary compact onClick={action} ref={buttonRef}>
            {actionText}
          </Button>
        )}
        <Modal
          isOpen={showModal}
          handleClose={action}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          className={
            task.type === 'suitability-review' ? 'task-document-review' : ''
          }
          fullScreen={true}
        >
          {modalContent}
        </Modal>
      </div>
    )
  }
)

export default Task
