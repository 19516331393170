import React, { Component } from 'react'
import Field from './Field'
import RemoveIcon from '../icons/RemoveIcon'

class InvestmentProfileFields extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleAddInvestmentProfileLine = this.handleAddInvestmentProfileLine.bind(
      this
    )
    this.handleInvestmentProfileLineChange = this.handleInvestmentProfileLineChange.bind(
      this
    )
    this.handleRemoveInvestmentProfileLine = this.handleRemoveInvestmentProfileLine.bind(
      this
    )
  }

  handleAddInvestmentProfileLine(event) {
    event.preventDefault()

    const { state } = this.props

    var joinedInvestmentProfiles = state.state.client.investment_profiles.concat(
      [{ account: '', profile: '', time_frame: '' }]
    )

    state.setState({
      client: {
        ...state.state.client,
        investment_profiles: joinedInvestmentProfiles,
      },
    })
  }

  handleInvestmentProfileLineChange = (idx) => (event) => {
    const target = event.target
    const name = target.name
    const value = target.value

    const { state } = this.props

    const newInvestmentProfiles = state.state.client.investment_profiles.map(
      (investment_profile, sidx) => {
        if (idx !== sidx) return investment_profile
        return { ...investment_profile, [name]: value }
      }
    )

    state.setState({
      client: {
        ...state.state.client,
        investment_profiles: newInvestmentProfiles,
      },
    })
  }

  handleRemoveInvestmentProfileLine = (idx) => (event) => {
    event.preventDefault()

    const { state } = this.props

    var newInvestmentProfiles = state.state.client.investment_profiles.filter(
      (s, sidx) => idx !== sidx
    )

    state.setState({
      client: {
        ...state.state.client,
        investment_profiles: newInvestmentProfiles,
      },
    })
  }

  renderRiskalyzeScore() {
    return (
      <li>
        <Field
          type="number"
          label="My Risk Score from Riskalyze"
          showLabel={true}
          value={this.props.riskalyze.riskScore}
        />
      </li>
    )
  }

  render() {
    const { client, riskalyze } = this.props
    const riskalyzeScore = riskalyze && riskalyze.riskScore

    const selectedAccounts = client.accounts_non_retirement.concat(
      client.accounts_retirement
    )

    var accountOptions = ['All Accounts']
    selectedAccounts.map((account) => {
      accountOptions.push(account)
      return account
    })

    this.investment_profile_fields = client.investment_profiles.map(
      (investment_profile, idx) => (
        <li key={idx}>
          <button
            className="remove-button"
            onClick={this.handleRemoveInvestmentProfileLine(idx)}
          >
            <RemoveIcon />
          </button>
          <Field
            formGroupClassName="for-account"
            type="select"
            options={accountOptions}
            required={true}
            id="account"
            label="For my..."
            showLabel={true}
            placeholder="Select account"
            value={investment_profile.account}
            handleInputChange={this.handleInvestmentProfileLineChange(idx)}
          />
          <Field
            formGroupClassName="fee"
            type="select"
            options="investment-profile"
            required={true}
            id="profile"
            label="I am..."
            showLabel={true}
            placeholder="Select profile"
            value={investment_profile.profile}
            handleInputChange={this.handleInvestmentProfileLineChange(idx)}
          />
        </li>
      )
    )

    return (
      <div className="form-group">
        <div className="form-group">
          <label htmlFor="investment_profiles">Investment Profile</label>
        </div>

        <ul className="investment-profiles">
          {riskalyzeScore
            ? this.renderRiskalyzeScore()
            : this.investment_profile_fields}
        </ul>

        <div className="add-button">
          {!riskalyzeScore && (
            <button
              className="add-investment-profile-line"
              onClick={this.handleAddInvestmentProfileLine}
            >
              Add Line
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default InvestmentProfileFields
