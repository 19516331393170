import { firebaseFunctionsCustomDomain } from '../../../config/firebaseConfig'

const integrationsDocusignGetSigningUrl = firebaseFunctionsCustomDomain.httpsCallable(
  'integrationsDocusignGetSigningUrl'
)

export default async function getSigningUrl({
  envelopeID,
  recipient,
  setSigningUrl,
  preview = false,
}) {
  try {
    const result = await integrationsDocusignGetSigningUrl({
      envelopeId: envelopeID,
      recipient,
      firm: recipient.firmId,
      preview,
    })

    if (result.data && result.data.url) {
      const { url } = result.data

      setSigningUrl(url)

      return url
    }

    return null
  } catch (err) {
    console.error(err)
  }

  return null
}
