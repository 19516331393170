import React, { useState, useEffect } from 'react'
import { Form, Radio } from 'semantic-ui-react'

const RadioButtons = ({ options }) => {
  const [value, setValue] = useState(options.value)

  const { onChange } = options
  useEffect(() => {
    onChange(options.data.field_name, value)
  }, [value, onChange, options.data.field_name])

  return (
    <Form.Field>
      <label htmlFor={options.data.field_name}>{options.data.label}</label>
      {options.data.options.map((option) => (
        <Radio
          key={option.key}
          label={option.text}
          name={options.data.field_name}
          value={option.text}
          checked={option.text === value}
          onChange={(e, { value }) => setValue(value)}
        />
      ))}
    </Form.Field>
  )
}

export default RadioButtons
