import styled from 'styled-components'

export const FluidContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -15px;
  margin-left: -15px;
`

export const Col = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`

export const ColSm6 = styled(Col)`
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`

export const ColSm12 = styled(Col)`
  @media (min-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

export const ColMd4 = styled(Col)`
  @media (min-width: 768px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`

export const ColMd8 = styled(Col)`
  @media (min-width: 768px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`

export const ColMd12 = styled(Col)`
  @media (min-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

export const ColSm6ColMd3 = styled(ColSm6)`
  @media (min-width: 768px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`

export const ColSm6ColMd4 = styled(ColSm6)`
  @media (min-width: 768px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`

export const ColSm6ColLg4 = styled(ColSm6)`
  @media (min-width: 1200px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`

export const ColSm12ColMd8 = styled(ColSm12)`
  @media (min-width: 768px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`

export const ColSm12ColLg8 = styled(ColSm12)`
  @media (min-width: 1200px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`
