import { firebaseFunctions } from '../../../config/firebaseConfig'

const integrationsStripeGetCustomer = firebaseFunctions.httpsCallable(
  'integrationsStripeGetCustomer'
)

export default async function getCustomer({
  customerEmail,
  registrationState,
}) {
  try {
    const result = await integrationsStripeGetCustomer({
      customerEmail,
    })

    if (result.data && result.data.length) {
      console.log(result.data)

      const customer = result.data[0]

      const name = customer.name || ''
      const phone = customer.phone || ''

      // Address
      let address_street = ''
      let address_city = ''
      let address_state = ''
      let address_zip = ''
      if (customer.address) {
        const address = customer.address

        address_street = address.line1

        if (customer.address.line2) {
          address_street += ', ' + address.line2
        }

        address_city = address.city
        address_state = address.state
        address_zip = address.postal_code
      }

      // Subscription data
      let subscriptionID = ''
      let currentPeriodEnd = ''
      if (customer.subscriptions.data && customer.subscriptions.data.length) {
        const subscription = customer.subscriptions.data[0]

        subscriptionID = subscription.id
        currentPeriodEnd = subscription.current_period_end
      }

      // Set registration state
      registrationState.setState(
        {
          firm: {
            ...registrationState.state.firm,
            customerID: customer.id,
            contact_name: name,
            phone: phone,
            address_street: address_street,
            address_city: address_city,
            address_state: address_state,
            address_zip: address_zip,
            subscriptionID: subscriptionID,
            currentPeriodEnd: currentPeriodEnd,
          },
        },
        () => {
          registrationState.setState({
            hasCheckedStripeCustomer: true,
          })
        }
      )

      return result.data
    } else {
      // No customer with that email
      registrationState.setState(
        {
          errors: [
            'No customer found with that email. Please make sure you are using the same email address as your payments account.',
          ],
        },
        () => {
          registrationState.setState({
            hasCheckedStripeCustomer: true,
          })
        }
      )
    }

    return null
  } catch (err) {
    console.error(err)

    // There was an error
    registrationState.setState(
      {
        errors: [
          'There was an error getting information from Stripe. Please try again and contact Bitsy if the problem persists.',
        ],
      },
      () => {
        registrationState.setState({
          hasCheckedStripeCustomer: true,
        })
      }
    )
  }

  return null
}
