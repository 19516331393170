import React, { memo } from 'react'
import ReactTable from 'react-table-6'
import Avatar from '../../../components/dashboards/partials/Avatar'
import { formatFirestoreDateString } from '../../../actions/db/_utils'
import { useProfile } from 'contexts'
import styled from 'styled-components'

const ViewDetailsButton = (props) => {
  const { profileType, row, setProfile } = props
  return (
    <button
      className="btn table-btn"
      onClick={() =>
        setProfile({
          type: profileType,
          id: row.original.id,
          content: row.original,
        })
      }
    >
      View Details
    </button>
  )
}

export default memo(function PeopleTable(props) {
  const { type, people, advisors, firms, pageSize, location, className } = props

  const { setProfile } = useProfile()

  const generatePeopleArray = (firms = null) => {
    var array = []
    if (people && Object.keys(people).length) {
      Object.keys(people).map(function (person, key) {
        var personObject = people[person]
        personObject['id'] = person

        if (type === 'clients' || type === 'prospects') {
          if (
            advisors &&
            advisors[personObject.advisor] &&
            advisors[personObject.advisor].name
          ) {
            personObject['advisor_name'] = advisors[personObject.advisor].name
          }

          if (
            firms &&
            Object.keys(firms).length &&
            personObject.firm &&
            firms[personObject.firm] &&
            firms[personObject.firm].name
          ) {
            personObject['firmName'] = firms[personObject.firm].name
          } else if (firms && Object.keys(firms).length) {
            console.log('Incorrect firm:')
            console.log('ClientID: %o', person)
            console.log('Client Email: %o', personObject.email)
            console.log('Firm ID: %o', personObject.firm)
            personObject['firmName'] = (
              <span className="red">
                WRONG FIRM
                <br />
                <small>{personObject.firm}</small>
              </span>
            )
          }
        }

        array.push(personObject)

        return personObject
      })
    }

    return array
  }

  const generatePeopleColumns = (type) => {
    var profileType = type.substring(0, type.length - 1)

    var columns = null

    switch (type) {
      case 'advisors':
        if (location === 'admin-dashboard') {
          columns = [
            {
              Header: 'Onboarding Date',
              id: 'dateAdded',
              accessor: (c) => c.dateAdded,
              sort: 'desc',
              Cell: (c) =>
                c.original && c.original.dateAdded
                  ? formatFirestoreDateString(c.original.dateAdded)
                  : '',
              className: 'date',
              headerClassName: 'date',
              maxWidth: 130,
              filterable: false,
              canFilter: false,
            },
            {
              Header: 'Name',
              accessor: 'name',
              className: 'name',
              headerClassName: 'name',
            },
            {
              Header: 'Firm',
              accessor: 'firmName',
              className: 'firm',
              headerClassName: 'firm',
            },
            {
              Header: 'Details',
              accessor: 'details',
              className: 'full-details',
              headerClassName: 'full-details',
              Cell: (row) => (
                <ViewDetailsButton
                  profileType={profileType}
                  row={row}
                  setProfile={setProfile}
                />
              ),
              sortable: false,
              maxWidth: 70,
              filterable: false,
              canFilter: false,
            },
          ]
        } else {
          columns = [
            {
              Header: 'Name',
              accessor: 'name',
              className: 'name',
              headerClassName: 'name',
            },
            {
              Header: 'Details',
              accessor: 'details',
              className: 'full-details',
              headerClassName: 'full-details',
              Cell: (row) => (
                <ViewDetailsButton
                  profileType={profileType}
                  row={row}
                  setProfile={setProfile}
                />
              ),
              sortable: false,
              filterable: false,
            },
          ]
        }
        break
      case 'clients':
      case 'prospects':
        // Set up initial columns
        columns = [
          {
            Header: 'Name',
            accessor: 'legal_name',
            Cell: (c) => {
              const values = c.original
              const legal_name = values.legal_name

              return (
                <div className="table-avatar d-flex align-items-center">
                  <StyledAvatar user={values} />
                  <Name>{legal_name && legal_name}</Name>
                </div>
              )
            },
            className: 'name',
            headerClassName: 'name',
            filter: 'includes',
          },
          {
            Header: 'Email',
            accessor: 'email',
            className: 'email',
            headerClassName: 'email',
          },
          {
            Header: 'Onboarding Date',
            id: 'dateAdded',
            accessor: (c) => c.dateAdded,
            sort: 'desc',
            Cell: (c) =>
              c.original && c.original.dateAdded
                ? formatFirestoreDateString(c.original.dateAdded)
                : '',
            className: 'date',
            headerClassName: 'date',
            filterable: false,
            canFilter: false,
          },
          {
            Header: 'Servicing Advisor',
            accessor: 'advisor_name',
            className: 'advisor',
            headerClassName: 'advisor',
          },
          {
            Header: 'Details',
            accessor: 'details',
            className: 'full-details',
            headerClassName: 'full-details',
            Cell: (row) => (
              <ViewDetailsButton
                profileType={profileType}
                row={row}
                setProfile={setProfile}
              />
            ),
            sortable: false,
            filterable: false,
            canFilter: false,
          },
        ]

        if (type === 'clients') {
          if (location === 'admin-dashboard') {
            columns = [
              {
                Header: 'Name',
                accessor: 'legal_name',
                className: 'name',
                headerClassName: 'name',
              },
              {
                Header: 'Email',
                accessor: 'email',
                className: 'email',
                headerClassName: 'email',
              },
              {
                Header: 'Onboarding Date',
                id: 'dateAdded',
                accessor: (c) => c.dateAdded,
                sort: 'desc',
                Cell: (c) =>
                  c.original && c.original.dateAdded
                    ? formatFirestoreDateString(c.original.dateAdded)
                    : '',
                className: 'date',
                headerClassName: 'date',
                filterable: false,
                canFilter: false,
              },
              {
                Header: 'Firm',
                accessor: 'firmName',
                className: 'firm',
                headerClassName: 'firm',
              },
              {
                Header: 'Details',
                accessor: 'details',
                className: 'full-details',
                headerClassName: 'full-details',
                Cell: (row) => (
                  <ViewDetailsButton
                    profileType={profileType}
                    row={row}
                    setProfile={setProfile}
                  />
                ),
                sortable: false,
                filterable: false,
                canFilter: false,
              },
            ]
          } else if (!advisors) {
            var clientColumnsVar = []
            columns.map((column) => {
              if (column.accessor !== 'advisor_name') {
                clientColumnsVar.push(column)
              }
              return column
            })
            columns = clientColumnsVar
          }
        } else if (type === 'prospects') {
          var prospectColumnsVar = []
          columns.map((column) => {
            if (column.accessor !== 'portfolio_value') {
              prospectColumnsVar.push(column)
            }
            return column
          })
          var prospectColumns = prospectColumnsVar
          columns = prospectColumns

          if (!advisors) {
            var peopleColumnsVar = []
            prospectColumns.map((column) => {
              if (column.accessor !== 'advisor_name') {
                peopleColumnsVar.push(column)
              }
              return column
            })
            columns = peopleColumnsVar
          }
        }
        break
      default:
        break
    }

    return columns
  }

  // Set up page size
  var tablePageSize = 10
  if (pageSize) {
    tablePageSize = pageSize
  }

  // Set up sort method
  var sortMethod = [{ id: 'name', desc: false }]
  if (
    type === 'clients' ||
    type === 'prospects' ||
    location === 'admin-dashboard'
  ) {
    sortMethod = [{ id: 'dateAdded', desc: true }]
  }

  // Set up array and columns
  const peopleArray = firms ? generatePeopleArray(firms) : generatePeopleArray()
  const peopleColumns = generatePeopleColumns(type)

  return (
    <div className={`people-list ${type}-list`}>
      {peopleArray.length ? (
        <ReactTable
          className={className}
          data={peopleArray}
          columns={peopleColumns}
          pageSize={tablePageSize}
          filterable={true}
          defaultFilterMethod={(filter, row) => {
            if (!row[filter.id]) {
              return
            }
            return row[filter.id]
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          }}
          defaultSorted={sortMethod}
        />
      ) : (
        <NoneYetMessage className={`instructional no-people no-${type}`}>
          No {type} yet!
        </NoneYetMessage>
      )}
    </div>
  )
})

const NoneYetMessage = styled.small`
  text-align: center;
  display: block;
`

const StyledAvatar = styled(Avatar)`
  flex-basis: 38px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  background-color: #e9f1f0;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  color: #000;
`
