import React, { Component } from 'react'
import {
  iacInitialState,
  centennialIacInitialState,
  hobartIacInitialState,
} from '../../../config/iacConfig'
import { saveDocument } from '../../../actions/storage/docActions'
import { emailDocumentPending } from '../../../actions/email/docActions'
import {
  clientAddOnboardedClientToDB,
  clientUpdateClient,
} from '../../../actions/clientDbActions'
import {
  addOnboardedClientToDB,
  updateClient,
} from '../../../actions/dbActions'
import { BitsyIAC, CentennialIAC, HobartIAC, MentzerIAC } from './templates'
import { firmIDs } from '../../../config/firmConfig'
import {
  validateInitialsFields,
  validateSignatureFields,
} from '../../../actions/docHelpers'
import './InvestmentAdvisoryContract.scss'

class InvestmentAdvisoryContract extends Component {
  constructor(props) {
    super(props)
    this.state = {
      iac: null,
      errors: [],
      addSuccess: false,
      iacGenerating: false,
      iacErrors: false,
    }

    this.nextLinkRef = React.createRef()

    this.attemptContinue = this.attemptContinue.bind(this)
    this.handleIACInputChange = this.handleIACInputChange.bind(this)
    this.handleIACSend = this.handleIACSend.bind(this)
    this.handleIACSubmit = this.handleIACSubmit.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleSignatureConfirm = this.handleSignatureConfirm.bind(this)
    this.handleSignatureRetry = this.handleSignatureRetry.bind(this)
    this.validate = this.validate.bind(this)
  }

  componentDidMount() {
    const { firmID } = this.props

    // Set initial IAC state
    var initialState = iacInitialState
    switch (firmID) {
      case firmIDs['centennial']:
        initialState = centennialIacInitialState
        break
      case firmIDs['hobart']:
      case firmIDs['mtg']:
        initialState = hobartIacInitialState
        break
      default:
    }
    this.setState({
      iac: initialState,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this.props

    // Set addSuccess on parent
    if (state && this.state.addSuccess && !prevState.addSuccess) {
      state.setState({
        addSuccess: true,
      })
    }
  }

  attemptContinue(event) {
    event.preventDefault()

    const { type } = this.props

    if (type !== 'send') {
      this.validate()
    } else {
      // Skip validation,
      this.handleIACSend()
    }
  }

  handleIACInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name.startsWith('ini_')) {
      var iniName = name.slice(4)

      // Initials state changes
      this.setState({
        iac: {
          ...this.state.iac,
          initials: {
            ...this.state.iac.initials,
            [iniName]: value,
          },
        },
      })
    } else if (
      name === 'adv_receipt' ||
      name === 'electronic_consent' ||
      name === 'advisor_obtained_records' ||
      name === 'discretionary' ||
      name === 'nondiscretionary' ||
      name === 'services_1_client' ||
      name === 'services_1_second' ||
      name === 'services_2_client' ||
      name === 'services_2_second' ||
      name === 'services_3_client' ||
      name === 'services_3_second' ||
      name === 'services_4_client' ||
      name === 'services_4_second' ||
      name === 'discretionary_client' ||
      name === 'discretionary_second' ||
      name === 'nondiscretionary_client' ||
      name === 'nondiscretionary_second' ||
      name === 'adv_receipt_client' ||
      name === 'adv_receipt_second' ||
      name === 'authorized_withdrawal_client' ||
      name === 'authorized_withdrawal_second' ||
      name === 'fee_schedule_client' ||
      name === 'fee_schedule_second' ||
      name === 'advisory_fee_client' ||
      name === 'advisory_fee_second'
    ) {
      // Initials state changes
      this.setState({
        iac: {
          ...this.state.iac,
          initials: {
            ...this.state.iac.initials,
            [name]: value,
          },
        },
      })
    } else if (
      name === 'client_1_text' ||
      name === 'second_1_text' ||
      name === 'client_2_text' ||
      name === 'second_2_text' ||
      name === 'client_3_text' ||
      name === 'second_3_text' ||
      name.startsWith('sig_')
    ) {
      // Text signature state changes
      this.setState({
        iac: {
          ...this.state.iac,
          signatures: {
            ...this.state.iac.signatures,
            [name]: value,
          },
        },
      })
    } else {
      // IAC state changes
      this.setState({
        iac: {
          ...this.state.iac,
          [name]: value,
        },
      })
    }
  }

  handleIACSend() {
    const {
      firm,
      advisor,
      client,
      firmDisclosures,
      advisorDisclosures,
      uid,
      source,
      location,
      firmID,
    } = this.props

    if (this.state.errors.length === 0) {
      // Set up firm name
      var firmName = firm.name
      if (firmID && firmID === firmIDs['hobart']) {
        firmName = 'Hobart Wealth'
      }

      // Email pending document to client
      emailDocumentPending(
        'iac',
        client.firm,
        client.legal_name,
        client.email,
        advisor.name,
        advisor.email,
        firmName,
        firm.executed_contract_email,
        firmDisclosures,
        advisorDisclosures
      )

      var clientObject = client

      clientObject['hasPendingIAC'] = true

      if (source === 'register') {
        // Add client to database
        if (location !== 'dashboard') {
          clientAddOnboardedClientToDB(clientObject, this, uid)
        } else {
          addOnboardedClientToDB(clientObject, this, uid)
        }
      } else {
        // Update client in database
        if (location !== 'dashboard') {
          clientUpdateClient(clientObject, this, uid)
        } else {
          updateClient(clientObject, this, uid)
        }
      }
    }
  }

  handleIACSubmit() {
    const {
      firm,
      advisor,
      client,
      firmDisclosures,
      advisorDisclosures,
      uid,
      source,
      location,
      firmID,
    } = this.props

    if (this.state.errors.length === 0) {
      var database = 'main'
      if (location !== 'dashboard') {
        database = 'clientDB'
      }

      // Set up firm name
      var firmName = firm.name
      if (firmID && firmID === firmIDs['hobart']) {
        firmName = 'Hobart Wealth'
      }

      // Save PDF
      saveDocument(
        'iac',
        this,
        client.legal_name,
        client.email,
        advisor.name,
        advisor.email,
        firmName,
        firm.executed_contract_email,
        firm.executed_contract_email_body,
        firmDisclosures,
        advisorDisclosures,
        client.firm,
        uid,
        database,
        client.iacs,
        null,
        client.second_investor_legal_name,
        client.second_investor_email
      )

      if (source === 'register') {
        // Add client to database
        if (location !== 'dashboard') {
          clientAddOnboardedClientToDB(client, this, uid)
        } else {
          addOnboardedClientToDB(client, this, uid)
        }
      } else {
        // Update client in database
        if (location !== 'dashboard') {
          clientUpdateClient(client, this, uid)
        } else {
          updateClient(client, this, uid)
        }
      }
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.nextLinkRef.current.click()
    }
  }

  handleSignatureConfirm(refName, image) {
    this.setState({
      iac: {
        ...this.state.iac,
        signatures: {
          ...this.state.iac.signatures,
          [refName]: image,
        },
      },
    })
  }

  handleSignatureRetry(refName) {
    this.setState({
      iac: {
        ...this.state.iac,
        signatures: {
          ...this.state.iac.signatures,
          [refName]: null,
        },
      },
    })
  }

  validate() {
    const { client, firmID, firm } = this.props
    const { iac } = this.state

    var errors = []

    // Set up whether there is a second investor or not
    var noSecondInvestor = true
    if (client.second_investor_legal_name.length) {
      noSecondInvestor = false
    }

    // Set up whether there are text or draw signatures (defaults to both)
    var noTextSig = false
    var noDrawSig = false
    if (firm.signatureType === 'draw') {
      noTextSig = true
    }
    if (firm.signatureType === 'text') {
      noDrawSig = true
    }

    // Set validation to exclude certain fields for certain firms
    var initialsToValidate = null
    var signaturesToValidate = null
    switch (firmID) {
      case firmIDs['centennial']:
        // case firmIDs['mtg']:
        initialsToValidate = [
          { name: 'services_1_client' },
          { name: 'services_1_second', skip: noSecondInvestor },
          { name: 'services_2_client' },
          { name: 'services_2_second', skip: noSecondInvestor },
          { name: 'services_3_client' },
          { name: 'services_3_second', skip: noSecondInvestor },
          { name: 'services_4_client' },
          { name: 'services_4_second', skip: noSecondInvestor },
          {
            name: 'discretionary_client',
            skip: client.jurisdiction !== 'Discretionary',
          },
          {
            name: 'discretionary_second',
            skip: client.jurisdiction !== 'Discretionary' || noSecondInvestor,
          },
          {
            name: 'nondiscretionary_client',
            skip: client.jurisdiction !== 'Non-Discretionary',
          },
          {
            name: 'nondiscretionary_second',
            skip:
              client.jurisdiction !== 'Non-Discretionary' || noSecondInvestor,
          },
          { name: 'adv_receipt_client' },
          { name: 'adv_receipt_second', skip: noSecondInvestor },
          { name: 'authorized_withdrawal_client' },
          { name: 'authorized_withdrawal_second', skip: noSecondInvestor },
          { name: 'fee_schedule_client' },
          { name: 'fee_schedule_second', skip: noSecondInvestor },
          { name: 'advisory_fee_client' },
          { name: 'advisory_fee_second', skip: noSecondInvestor },
        ]

        signaturesToValidate = [
          { name: 'client_1_text' },
          { name: 'second_1_text', skip: noSecondInvestor },
          { name: 'client_2_text' },
          { name: 'second_2_text', skip: noSecondInvestor },
          { name: 'client_3_text' },
          { name: 'second_3_text', skip: noSecondInvestor },
        ]
        break
      case firmIDs['hobart']:
      case firmIDs['mtg']:
        initialsToValidate = [
          {
            name: 'discretionary_client',
            skip: client.jurisdiction !== 'Discretionary',
          },
          {
            name: 'discretionary_second',
            skip: client.jurisdiction !== 'Discretionary' || noSecondInvestor,
          },
          {
            name: 'nondiscretionary_client',
            skip: client.jurisdiction !== 'Non-Discretionary',
          },
          {
            name: 'nondiscretionary_second',
            skip:
              client.jurisdiction !== 'Non-Discretionary' || noSecondInvestor,
          },
          { name: 'advisory_fee_client' },
          { name: 'advisory_fee_second', skip: noSecondInvestor },
          { name: 'schedule_a_client' },
          { name: 'schedule_a_second', skip: noSecondInvestor },
          { name: 'no_management_services_client' },
          { name: 'no_management_services_second', skip: noSecondInvestor },
          { name: 'schedule_b_client' },
          { name: 'schedule_b_second', skip: noSecondInvestor },
          { name: 'schedule_c_client' },
          { name: 'schedule_c_second', skip: noSecondInvestor },
          { name: 'schedule_d_client' },
          { name: 'schedule_d_second', skip: noSecondInvestor },
          { name: 'fee_billing_client' },
          { name: 'fee_billing_second', skip: noSecondInvestor },
        ]

        signaturesToValidate = [
          { name: 'sig_1_client', skip: noDrawSig },
          { name: 'sig_1_client_text', skip: noTextSig },
          { name: 'sig_1_second', skip: noDrawSig || noSecondInvestor },
          { name: 'sig_1_second_text', skip: noTextSig || noSecondInvestor },
          { name: 'sig_2_client', skip: noDrawSig },
          { name: 'sig_2_client_text', skip: noTextSig },
          { name: 'sig_2_second', skip: noDrawSig || noSecondInvestor },
          { name: 'sig_2_second_text', skip: noTextSig || noSecondInvestor },
          { name: 'sig_3_client', skip: noDrawSig },
          { name: 'sig_3_client_text', skip: noTextSig },
          { name: 'sig_3_second', skip: noDrawSig || noSecondInvestor },
          { name: 'sig_3_second_text', skip: noTextSig || noSecondInvestor },
        ]
        break
      default:
        initialsToValidate = [
          { name: 'adv_receipt' },
          { name: 'electronic_consent' },
          { name: 'advisor_obtained_records' },
          {
            name: 'discretionary',
            skip: client.jurisdiction !== 'Discretionary',
          },
          {
            name: 'nondiscretionary',
            skip: client.jurisdiction !== 'Non-Discretionary',
          },
        ]

        signaturesToValidate = [{ name: 'clientSig1' }, { name: 'clientSig2' }]
        break
    }

    // Validate initials fields
    errors = errors.concat(
      validateInitialsFields(iac.initials, initialsToValidate)
    )

    // Validate signature fields
    errors = errors.concat(
      validateSignatureFields(iac.signatures, signaturesToValidate)
    )

    // Set state and increase step
    this.setState(
      {
        errors: errors,
      },
      () => {
        this.handleIACSubmit()
      }
    )
  }

  render() {
    const { type, firmID, firm, advisor, client } = this.props
    const { iac, errors, iacGenerating, iacErrors, addSuccess } = this.state

    if (!firm || !iac) {
      return ''
    }

    var client_name = ''
    if (client) {
      client_name = client.legal_name

      if (
        client.second_investor_legal_name &&
        client.second_investor_legal_name.length > 0
      ) {
        client_name += ' & ' + client.second_investor_legal_name
      }
    }

    var clientIAC = (
      <BitsyIAC
        type={type}
        firm={firm}
        advisor={advisor}
        client={client}
        client_name={client_name}
        iac={iac}
        errors={errors}
        handleIACSubmit={this.attemptContinue}
        handleInputChange={this.handleIACInputChange}
        handleSignatureConfirm={this.handleSignatureConfirm}
        handleSignatureRetry={this.handleSignatureRetry}
        iacRef={(r) => (this.iac = r)}
        nextLinkRef={this.nextLinkRef}
        handleKeyPress={this.handleKeyPress}
      />
    )
    switch (firmID) {
      case firmIDs['centennial']:
        // case firmIDs['mtg']:
        clientIAC = (
          <CentennialIAC
            type={type}
            firm={firm}
            advisor={advisor}
            client={client}
            client_name={client_name}
            doc={iac}
            errors={errors}
            handleIACSubmit={this.attemptContinue}
            handleInputChange={this.handleIACInputChange}
            handleSignatureConfirm={this.handleSignatureConfirm}
            handleSignatureRetry={this.handleSignatureRetry}
            iacRef={(r) => (this.iac = r)}
            nextLinkRef={this.nextLinkRef}
            handleKeyPress={this.handleKeyPress}
          />
        )
        break
      case firmIDs['hobart']:
      case firmIDs['mtg']:
        clientIAC = (
          <HobartIAC
            type={type}
            firm={firm}
            advisor={advisor}
            client={client}
            client_name={client_name}
            doc={iac}
            errors={errors}
            handleIACSubmit={this.attemptContinue}
            handleInputChange={this.handleIACInputChange}
            handleSignatureConfirm={this.handleSignatureConfirm}
            handleSignatureRetry={this.handleSignatureRetry}
            nextLinkRef={this.nextLinkRef}
            handleKeyPress={this.handleKeyPress}
          />
        )
        break
      case firmIDs['mentzer']:
        clientIAC = (
          <MentzerIAC
            type={type}
            firm={firm}
            advisor={advisor}
            client={client}
            client_name={client_name}
            iac={iac}
            errors={errors}
            handleIACSubmit={this.attemptContinue}
            handleInputChange={this.handleIACInputChange}
            handleSignatureConfirm={this.handleSignatureConfirm}
            handleSignatureRetry={this.handleSignatureRetry}
            iacRef={(r) => (this.iac = r)}
            nextLinkRef={this.nextLinkRef}
            handleKeyPress={this.handleKeyPress}
          />
        )
        break
      default:
        break
    }

    return (
      <div className="document-overlay">
        {addSuccess ? (
          <div className="document-submit-success">
            <h2>Welcome to {firm.name}!</h2>
            <p>Signup was successful. Please check your email!</p>
          </div>
        ) : (
          clientIAC
        )}

        {iacGenerating ? (
          <div className="document-saving">
            <div className="message">
              Saving IAC... Please do not leave this window!
            </div>
          </div>
        ) : (
          ''
        )}

        {iacErrors ? (
          <div className="document-submit-error">
            <div className="message">
              There was an error generating your IAC. Please try again.
              <button onClick={this.setState({ iacErrors: false })}>OK</button>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default InvestmentAdvisoryContract
