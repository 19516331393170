import React, { memo, useEffect } from 'react'
import Loader from 'components/partials/Loader'
import { useFirm, useUser, useAdvisor } from 'contexts'
import styled from 'styled-components'

const NotFound = memo(({ navigate }) => {
  const { uid } = useUser()
  const { firmID } = useFirm()
  const { advisorID } = useAdvisor()

  useEffect(() => {
    setTimeout(() => {
      if (uid) {
        navigate('/')
      } else {
        navigate(
          `/login${firmID && `/${firmID}${advisorID ? `--${advisorID}` : ''}`}`
        )
      }
    }, 5000)
  }, [uid, firmID, advisorID, navigate])

  return (
    <PageWrap>
      <Loader
        message={`Page not found. Redirecting to ${
          uid ? 'your dashboard' : 'login'
        } in 5 seconds...`}
        fullScreen
      />
    </PageWrap>
  )
})

export default NotFound

const PageWrap = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
