import React, { memo } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { SmallHeading } from 'styles/_mixins'

const Card = memo(
  ({
    children,
    type,
    title,
    icon,
    counter,
    headerActionLink,
    loginBoxMsg,
    registerBoxMsg,
    className,
    noneYet,
    collapsible,
  }) => {
    return (
      <Container
        className={`card ${type ? `${type}-card` : ''} ${
          className ? className : ''
        } ${collapsible ? 'collapsible' : ''}`}
      >
        {type !== 'statistic' && type !== 'login' && type !== 'register' && (
          <CardHeader>
            {title && <h3 className="card-title">{title}</h3>}
            {headerActionLink && (
              <HeaderActionLink
                className={
                  headerActionLink.className ? headerActionLink.className : ''
                }
                onClick={headerActionLink.action}
              >
                {headerActionLink.title}
              </HeaderActionLink>
            )}
          </CardHeader>
        )}

        <div className={`card-body ${type ? `${type}-card-body` : ''}`}>
          {icon && <div className="statistic-icon">{icon}</div>}
          {type === 'statistic' && (
            <div>
              {title && type === 'statistic' && (
                <div className={type ? type + '-text' : 'text'}>{title}</div>
              )}
              {counter !== undefined && (
                <div className={type ? type + '-counter' : 'counter'}>
                  {counter && counter !== 0 && counter !== '0' ? (
                    counter
                  ) : (
                    <small className="instructional">None yet!</small>
                  )}
                </div>
              )}
            </div>
          )}

          {type === 'login' && loginBoxMsg && (
            <p className="login-box-msg">{loginBoxMsg}</p>
          )}
          {type === 'register' && registerBoxMsg && (
            <p className="register-box-msg">{registerBoxMsg}</p>
          )}

          {noneYet && noneYet.length && (
            <NoneYetMessage className="instructional none-yet">
              <small>{noneYet}</small>
            </NoneYetMessage>
          )}

          {children}
        </div>
      </Container>
    )
  }
)

export default Card

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 16px 24px #ededed;
  margin-bottom: 30px;

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 20px 30px;
  }

  &.statistic-card {
    padding: 30px 15px;
    height: calc(100% - 30px);

    .statistic-card-body {
      display: flex;
      align-items: center;
      padding: 0;
    }

    .statistic-icon {
      background-color: #e9f1f0;
      border-radius: 50%;
      line-height: 70px;
      text-align: center;
      width: 70px;
      height: 70px;
      margin-right: 20px;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 40px;
        display: inline-block;
      }
    }

    .statistic-text {
      ${SmallHeading()};
    }

    .statistic-counter {
      font-size: 36px;
      font-weight: 700;
      line-height: 47px;
    }

    @media (min-width: 1350px) {
      padding: 40px 20px 40px 40px;
    }
  }
`

const CardHeader = styled.div`
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 28px 30px 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  .card-title {
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
  }
`

const HeaderActionLink = styled.div`
  color: ${colors.primary};
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: ${colors.blue};
  }
`

const NoneYetMessage = styled.p`
  text-align: center;
  max-width: 320px;
  margin: 0 auto;

  &:hover {
    background: transparent;
  }
`
