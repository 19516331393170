import React, { useState, useEffect, useCallback } from 'react'
import qs from 'querystring'
import { Sidebar, Segment, Ref, Modal } from 'semantic-ui-react'
// import Modal from 'components/partials/LegacyModal'
import Profile from './profiles/Profile'
import ClientDashboard from './ClientDashboard'
import DashboardHeader from './partials/DashboardHeader'
import DashboardSidebar from './partials/DashboardSidebar'
import DashboardContents from './partials/DashboardContents'
import ClientForm from '../forms/LegacyClientForm'
import TemplateForm from '../forms/TemplateForm'
import IntegrationModal from './partials/IntegrationModal'
import { useUser, useFirm, useAdvisor } from '../../contexts'
import { PeopleContext } from '../../contexts/PeopleContext'
import { TemplatesContext } from '../../contexts/TemplatesContext'
import { ProfileContext } from '../../contexts/ProfileContext'
import { EnvelopesContext } from '../../contexts/EnvelopesContext'
import { DisclosuresContext } from 'contexts/DisclosuresContext'
import { TasksContext } from '../../contexts/TasksContext'
import useBodyClass from '../../hooks/useBodyClass'
import useScript from '../../hooks/useScript'
import useItemList from '../../hooks/useItemList'
import { capitalize, compare } from 'actions/helpers'
import { isTrueParam } from 'helpers/_utils'
import { updateItemInfo } from '../../actions/db/dbActions'
import CheckoutButton from '../partials/CheckoutButton'
import Loader from '../partials/Loader'
import { bitsyEmails } from '../../config/appConfig'
import { isLegacyFirm } from '../../config/firmConfig'
import { getEnv } from 'helpers/_utils'
import { userIsFirm } from 'helpers/users'
import styled from 'styled-components'
import { colors } from 'styles'
import { SmallHeading } from 'styles/_mixins'

const Dashboard = (props) => {
  const { userType, uid, user } = useUser()
  const { firm, firmID } = useFirm()
  const { advisor, advisorID } = useAdvisor()

  const { location } = props
  const searchParams = qs.parse(location.search.substr(1))

  const isFirm = userIsFirm(userType)
  const isAdmin = userType === 'admin' || userType === 'super-admin'

  const emptyRef = React.useRef()

  const getTabs = () => {
    return [
      'Home',
      'Status',
      ...(!isAdmin || (isAdmin && userType === 'super-admin')
        ? ['Clients']
        : []),
      ...(isAdmin ? ['Firms'] : []),
      ...(userType !== 'advisor' ? ['Advisors'] : []),
      ...(isFirm ? ['Forms'] : []), // TODO: [BTS-599] Add Form Builder on behalf of other firms to admin dashboard
      ...(!isAdmin ? ['Documents'] : []),
      ...(!isAdmin ? ['Integrations'] : []),
      ...(isAdmin ? ['Add Clients'] : []),
      'Settings',
      'Export',
    ]
  }

  const [tabIndex, setTabIndex] = useState(
    props.integrationType
      ? getTabs().indexOf('Integrations')
      : searchParams.t && getTabs().indexOf(capitalize(searchParams.t))
      ? getTabs().indexOf(capitalize(searchParams.t))
      : parseInt(sessionStorage.getItem('tabIndex') ?? '0')
  )

  const setTab = useCallback((tabIndex) => {
    sessionStorage.setItem('tabIndex', tabIndex)
    setTabIndex(tabIndex)
  }, [])

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  const [showAddClientModule, setShowAddClientModule] = useState(
    isTrueParam(searchParams['template_form']) ? true : false
  )
  const [profile, setProfile] = useState({
    type: null,
    id: null,
    content: null,
  })
  const [showFirstTimeLogin, setShowFirstTimeLogin] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  // Add adminLTE body class and scripts
  var loadAdminLTE = false
  // if (uid && userType !== 'client') {
  //   loadAdminLTE = true
  // }
  // AdminLTE Styles / Scripts
  useBodyClass(
    sidebarCollapsed ? 'sidebar-mini sidebar-collapse' : 'sidebar-mini',
    loadAdminLTE
  )
  useScript(
    'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js',
    loadAdminLTE,
    false
  )
  // // useScript('https://cdn.datatables.net/1.10.21/js/jquery.dataTables.min.js', loadAdminLTE, false)
  // useScript('https://cdnjs.cloudflare.com/ajax/libs/datatables/1.10.20/js/dataTables.bootstrap4.min.js', loadAdminLTE, false)
  // useScript('https://cdn.datatables.net/responsive/2.2.5/js/dataTables.responsive.min.js', loadAdminLTE, false)
  // useScript('https://cdn.datatables.net/responsive/2.2.5/js/responsive.bootstrap4.min.js', loadAdminLTE, false)
  useScript(
    'https://cdnjs.cloudflare.com/ajax/libs/jquery-nice-select/1.1.0/js/jquery.nice-select.min.js',
    loadAdminLTE,
    false
  )
  useScript(
    'https://cdnjs.cloudflare.com/ajax/libs/admin-lte/3.0.5/js/adminlte.min.js',
    loadAdminLTE,
    false
  )
  useScript('/scripts/custom-dashboard.js', loadAdminLTE, false)

  // Get clients, advisors and firms
  var idForAdvisors = firmID
  var loadFirms = false
  if (isAdmin) {
    idForAdvisors = uid
    loadFirms = true
  }

  let userTypeForDBQueries = userType
  let uidForDBQueries = uid
  if ('firm-admin' === userType) {
    userTypeForDBQueries = 'firm'
    uidForDBQueries = firmID
  }

  let userTypeForAdvisors = userTypeForDBQueries
  if ('client' === userType || 'advisor' === userType) {
    userTypeForAdvisors = 'firm'
    idForAdvisors = firmID
  }

  const clients = useItemList({
    itemType: 'client',
    userType: userTypeForDBQueries,
    uid: uidForDBQueries,
    load: userType !== 'client',
    where:
      getEnv() === 'prod'
        ? [{ field: 'env', comparator: '==', value: getEnv() }]
        : [],
  })
  const advisors = useItemList({
    itemType: 'advisor',
    userType: userTypeForAdvisors,
    uid: idForAdvisors,
    load: isFirm,
  })
  const firms = useItemList({
    itemType: 'firm',
    userType,
    uid,
    load: loadFirms,
  })

  const templates = useItemList({
    itemType: 'template',
    userType: 'template',
    uid: firmID,
    isUserItem: true,
  })

  const firmDisclosures = useItemList({
    itemType: 'disclosure',
    userType: 'firm',
    uid: firmID,
    isUserItem: true,
  })

  const advisorDisclosures = useItemList({
    itemType: 'disclosure',
    userType: 'advisor',
    uid: userType === 'client' ? advisorID : uid,
    isUserItem: true,
    load: userType === 'advisor' || (userType === 'client' && advisorID),
  })

  const envelopes = useItemList({
    itemType: 'envelope',
    userType: userType === 'firm-admin' ? 'firm' : userType,
    uid: userType === 'firm-admin' ? firmID : uid,
    where1: 'env',
    where2: getEnv(),
    where: [{ field: 'status', comparator: '!=', value: 'draft' }],
    orderBy: [{ field: 'status', direction: 'desc' }],
    orderByKey: 'dateSent',
    orderByDirection: 'desc',
  })

  const tasks = useItemList({
    itemType: 'task',
    userType,
    uid,
    isUserItem: true,
    load:
      userType === 'client' ||
      userType === 'advisor' ||
      (userType === 'firm' && firm && firm.adminIsAdvisor),
    orderBy: [{ field: 'dateSent', direction: 'desc' }],
  })

  useEffect(() => {
    // this means modal is open and client has changed
    if (profile.id && clients && clients[profile.id]) {
      setProfile({
        type: 'client',
        id: profile.id,
        content: clients[profile.id],
      })
    } else if ((profile.id && !clients) || (clients && !clients[profile.id])) {
      // Modal is open and client does not exist anymore (was deleted), close profile modal
      setProfile({
        type: null,
        id: null,
        content: null,
      })
    }
  }, [clients, profile.id])

  // Check for first time login
  useEffect(() => {
    if (userType === 'firm' && firm && firm.firstLogin) {
      setShowFirstTimeLogin(true)
    }
  }, [userType, firm])

  useEffect(() => {
    if (!userType || !uid || !user) {
      setShowLoader(true)
    } else if (showLoader) {
      setShowLoader(false)
    }
  }, [userType, uid, user, showLoader])

  if (showLoader) {
    return <Loader message="Loading your dashboard..." fullScreen />
  }

  if (!userType) {
    return ''
  }

  // Client Dashboard
  if (userType === 'client') {
    return (
      <DisclosuresContext.Provider
        value={{ firmDisclosures, advisorDisclosures }}
      >
        <TemplatesContext.Provider value={{ templates }}>
          <EnvelopesContext.Provider value={{ envelopes }}>
            <TasksContext.Provider value={{ tasks }}>
              <div className="dashboard">
                <ClientDashboard />
              </div>
            </TasksContext.Provider>
          </EnvelopesContext.Provider>
        </TemplatesContext.Provider>
      </DisclosuresContext.Provider>
    )
  }

  // Hold if firm.adminIsAdvisor advisor doc hasn't been found/created yet
  if (
    userType === 'firm' &&
    firm &&
    firm.adminIsAdvisor &&
    !advisor &&
    advisor === undefined
  ) {
    return <Loader message="We are setting up your dashboard! Please wait..." />
  }

  // Don't allow free trials on production
  if (firm && firm.isFreeTrial && getEnv() === 'prod') {
    return (
      <FreeTrialMessage>
        <span>Please upgrade to access this.</span>
        <a
          href="https://staging.bitsyadvisor.com"
          title="Bitsy Advisor Sandbox"
        >
          Go to the sandbox >
        </a>
      </FreeTrialMessage>
    )
  }

  // Advisor, Firm & Admin Dashboards
  const salesforceCallback =
    searchParams.callback === 'true' &&
    searchParams.callbackType === 'Salesforce'

  const exploreDashboard = (event) => {
    event.preventDefault()

    setShowFirstTimeLogin(false)
    updateItemInfo('firm', uid, 'firstLogin', false)
  }

  const goToTab = (tab) => {
    const destinationTabIndex = getTabs().indexOf(tab)
    if (destinationTabIndex) {
      setTab(destinationTabIndex)
    }
  }

  const handleAdvisorStatusChange = (event) => {
    if (userType !== 'firm') {
      return null
    }

    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value

    if (profile && profile.id) {
      updateItemInfo('advisor', profile.id, 'inactive', value)
    }
  }

  const handleAddClientModuleClose = () => {
    setShowAddClientModule(false)

    // Remove params on close
    if (Object.keys(searchParams).length) {
      window.history.replaceState(null, null, location.pathname + location.hash)
    }
  }

  // Set up tabs
  const tabs = getTabs()

  // Set up searchable items
  var searchableItems = []
  if (clients && Object.keys(clients).length) {
    Object.keys(clients).map((clientID, key) => {
      const client = clients[clientID]
      if (client.legal_name || client.email) {
        return searchableItems.push({
          id: clientID,
          name: client.legal_name || '',
          object: client,
          type: 'client',
        })
      } else {
        return ''
      }
    })
  }
  if (advisors && Object.keys(advisors).length) {
    Object.keys(advisors).map((advisorID, key) => {
      const advisor = advisors[advisorID]
      return searchableItems.push({
        id: advisorID,
        name: advisor.name,
        object: advisor,
        type: 'advisor',
      })
    })
  }
  searchableItems = searchableItems.sort(compare)

  return (
    <PeopleContext.Provider value={{ clients, advisors }}>
      <DisclosuresContext.Provider
        value={{ firmDisclosures, advisorDisclosures }}
      >
        <TemplatesContext.Provider value={{ templates }}>
          <EnvelopesContext.Provider value={{ envelopes }}>
            <ProfileContext.Provider value={{ profile, setProfile }}>
              <TasksContext.Provider value={{ tasks }}>
                <DashboardWrapper>
                  <Sidebar.Pushable>
                    <Sidebar
                      as={Segment}
                      animation="push"
                      inverted
                      onHide={() => setSidebarCollapsed(true)}
                      visible={!sidebarCollapsed}
                      target={emptyRef}
                    >
                      <DashboardSidebar
                        tabs={tabs}
                        tabIndex={tabIndex}
                        setTabIndex={setTab}
                        setShowAddClientModule={setShowAddClientModule}
                      />
                    </Sidebar>

                    <Ref innerRef={emptyRef}>
                      <div style={{ display: 'none' }}></div>
                    </Ref>

                    <Sidebar.Pusher as={MainSegment}>
                      <DashboardHeader
                        sidebarCollapsed={sidebarCollapsed}
                        setSidebarCollapsed={setSidebarCollapsed}
                        setShowAddClientModule={setShowAddClientModule}
                        searchableItems={searchableItems}
                      />
                      <DashboardContents
                        tab={tabs[tabIndex]}
                        firmID={firmID}
                        clients={clients}
                        advisors={advisors}
                        firms={firms}
                        goToTab={goToTab}
                        isAdmin={isAdmin}
                      />
                    </Sidebar.Pusher>
                  </Sidebar.Pushable>

                  <div
                    id="sidebar-overlay"
                    onClick={() => setSidebarCollapsed(true)}
                  ></div>

                  <AddClientModal
                    closeIcon
                    open={showAddClientModule}
                    size={isLegacyFirm(firmID) ? null : 'fullscreen'}
                    onClose={handleAddClientModuleClose}
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                  >
                    <Modal.Header>Add a Client</Modal.Header>
                    <Modal.Content scrolling>
                      {isLegacyFirm(firmID) ? (
                        <ClientForm
                          firm={firm}
                          firmID={firmID}
                          firmAdvisors={advisors}
                          advisor={advisor}
                          advisorID={userType === 'advisor' ? uid : null}
                          location="dashboard"
                          reviewType="send"
                        />
                      ) : (
                        <TemplateForm
                          clientID={
                            isTrueParam(searchParams['client_id'])
                              ? searchParams['client_id']
                              : null
                          }
                          handleClose={handleAddClientModuleClose}
                        />
                      )}
                    </Modal.Content>
                  </AddClientModal>

                  <ProfileModal
                    closeIcon
                    open={profile.type && profile.id && !!profile.content}
                    size="fullscreen"
                    onClose={() =>
                      setProfile({ type: null, id: null, content: null })
                    }
                  >
                    <Modal.Header>
                      {profile.content && profile.content.name
                        ? profile.content.name
                        : 'Profile'}
                    </Modal.Header>
                    <Modal.Content scrolling>
                      <Profile
                        profile={profile}
                        firm={firm}
                        advisor={advisor}
                        advisors={advisors}
                        onAdvisorStatusChange={handleAdvisorStatusChange}
                        userType={userType}
                        firmDisclosures={firmDisclosures}
                        advisorDisclosures={advisorDisclosures}
                      />
                    </Modal.Content>
                  </ProfileModal>

                  {userType === 'firm' && firm && (
                    <>
                      <Modal open={!firm.currentPeriodEnd} dimmer="blurring">
                        <Modal.Header>Finalize your Bitsy Plan</Modal.Header>
                        <Modal.Content scrolling>
                          <div className="text-center">
                            <h4>It looks like we need your payment info!</h4>
                            <p>
                              Please click below to finalize your plan and
                              continue using Bitsy, or contact our{' '}
                              <a
                                href={`mailto:${bitsyEmails['sales']}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                sales team
                              </a>{' '}
                              to learn more.
                            </p>
                            <p>
                              <small>
                                We partner with Stripe to give you the best and
                                most secure payment experience.
                              </small>
                            </p>
                            <CheckoutButton
                              customerEmail={firm.email}
                              priceId={firm.planTier}
                              className="mx-auto"
                            />
                          </div>
                        </Modal.Content>
                      </Modal>

                      <Modal open={showFirstTimeLogin} dimmer="blurring">
                        <Modal.Header>Welcome to Bitsy Advisor!</Modal.Header>
                        <Modal.Content>
                          <div className="text-center">
                            <h4>Welcome to your dashboard!</h4>
                            <p>
                              {firm && firm.iac_template !== 'bitsy'
                                ? "We're customizing your experience and we'll be in touch when you're ready to go."
                                : 'Take a look around and let us know of any questions!'}
                            </p>
                            <button
                              onClick={exploreDashboard}
                              className="primary mx-auto"
                            >
                              Start exploring
                            </button>
                          </div>
                        </Modal.Content>
                      </Modal>
                    </>
                  )}

                  {salesforceCallback && (
                    <IntegrationModal
                      service={salesforceCallback ? 'salesforce' : null}
                    />
                  )}
                </DashboardWrapper>
              </TasksContext.Provider>
            </ProfileContext.Provider>
          </EnvelopesContext.Provider>
        </TemplatesContext.Provider>
      </DisclosuresContext.Provider>
    </PeopleContext.Provider>
  )
}

export default Dashboard

const DashboardWrapper = styled.div`
  min-height: 100vh;
  position: relative;
`

const MainSegment = styled(Segment)`
  &&&& {
    margin-top: 0;
    border: none;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .ui.sidebar.visible ~ & {
      max-width: calc(100vw - 260px);
    }
  }
`

const AddClientModal = styled(Modal)`
  /* div[data-id='template-selector'] {
    height: 100%;
  } */
`

const ProfileModal = styled(Modal)``

const FreeTrialMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;

  span,
  a {
    ${SmallHeading()};
  }

  a,
  a:focus,
  a:hover,
  a:visited,
  a:active {
    color: ${colors.primary};
  }

  a:hover,
  a:focus {
    color: ${colors.primaryAlt};
  }
`
