import React, { memo } from 'react'
import { Popup, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const Tip = memo((props) => {
  return (
    <Popup
      trigger={
        <IconContainer>
          <Icon name="question circle" color="grey" />
        </IconContainer>
      }
      header="TIP"
      {...props}
    />
  )
})

export default Tip

const IconContainer = styled.div`
  display: inline-block;
  cursor: help;
`
