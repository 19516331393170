import { firebaseFunctions } from '../../config/firebaseConfig'

const emailSendDocuments = firebaseFunctions.httpsCallable('emailSendDocuments')

export default async function sendDocuments({
  client,
  firm,
  advisor,
  forms,
  subject,
  body,
}) {
  try {
    const result = await emailSendDocuments({
      client,
      firm,
      advisor,
      forms,
      subject,
      body,
    })

    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
