import React, { memo } from 'react'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

const LegacyModal = memo(
  ({
    children,
    isOpen,
    handleClose,
    closeOnDimmerClick = true,
    closeOnEscape = true,
    fullScreen,
    title,
    className = '',
  }) => {
    return (
      <StyledModal
        onClose={handleClose}
        open={Boolean(isOpen)}
        size={fullScreen ? 'fullscreen' : null}
        className={className}
        closeOnDimmerClick={closeOnDimmerClick}
        closeOnEscape={closeOnEscape}
        closeIcon={Boolean(handleClose)}
      >
        {title && (
          <Modal.Header>
            <h4 className="modal-title">{title}</h4>
          </Modal.Header>
        )}

        <Modal.Content scrolling className="modal-body">
          {children}
        </Modal.Content>
      </StyledModal>
    )
  }
)

export default LegacyModal

const StyledModal = styled(Modal)`
  &&& {
    .content {
      max-height: calc(100vh - 14em);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      overflow-x: hidden;
    }

    &.fullscreen {
      top: 0;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: calc(100% - 4em) !important;
      height: calc(100% - 4em);
      margin: 2em auto !important;
      overflow: hidden;
      position: relative;
    }
  }
`
