import React, { useEffect } from 'react'
import { navigate, Link } from '@reach/router'
import {
  AuthPage,
  AuthBox,
  AuthLogo,
  AuthCard,
  AuthCardContent,
  AuthHeader,
  AuthExtra,
} from 'components/styled/AuthPage'
import BitsyLogo from 'components/partials/BitsyLogo'
import LegacyRegisterFirmForm from 'components/forms/LegacyRegisterFirmForm'
import LegacyRegisterAdvisorForm from 'components/forms/LegacyRegisterAdvisorForm'
import { useUser } from 'contexts'

const Register = ({ type = null, inviteCode = null }) => {
  const { uid } = useUser()

  useEffect(() => {
    if (uid) {
      navigate('/')
    }
  }, [uid])

  return (
    <AuthPage>
      <AuthBox>
        <AuthLogo>
          <BitsyLogo />
        </AuthLogo>

        <AuthCard>
          <AuthCardContent>
            {type === 'firm' && (
              <>
                <AuthHeader>
                  <h1>Create your Bitsy account</h1>
                  <p>Start expediting your onboarding process in minutes!</p>
                </AuthHeader>
                <LegacyRegisterFirmForm />
              </>
            )}

            {type === 'advisor' && (
              <LegacyRegisterAdvisorForm inviteCode={inviteCode} />
            )}
          </AuthCardContent>
        </AuthCard>

        <AuthExtra>
          Already a user? <Link to="/login">Log In Here</Link>
        </AuthExtra>
      </AuthBox>
    </AuthPage>
  )
}

export default Register
