import React, { memo } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import Loader from 'components/partials/Loader'
import styled from 'styled-components'

const SuccessPage = memo(
  ({
    successPageDetails = {
      heading: null,
      message: 'Signup was successful. Please check your email!',
      showWelcomeToFirm: false,
      showLoader: false,
    },
    firm = null,
    isActive,
    handleClose,
  }) => {
    const {
      heading,
      message,
      showWelcomeToFirm,
      showLoader,
    } = successPageDetails

    const content = (
      <>
        <Icon circular inverted color="teal" size="massive" name="thumbs up" />
        {showWelcomeToFirm && firm && firm.name && (
          <Header as="h2">
            Welcome to
            <br />
            {firm.name}
          </Header>
        )}
        {heading && <Header as="h2">{heading}</Header>}
        {message && <Paragraph>{message}</Paragraph>}
      </>
    )

    return (
      <Wrapper data-step="Success">
        {showLoader ? (
          <Loader
            message="Please wait for this window to close..."
            fullScreen={isActive}
          >
            {content}
          </Loader>
        ) : (
          <>
            {content}
            {handleClose && (
              <ReturnToDashboardButton
                content="Return to Dashboard"
                icon="check"
                primary
                circular
                size="big"
                onClick={handleClose}
              />
            )}
          </>
        )}
      </Wrapper>
    )
  }
)

export default SuccessPage

const Header = styled.div`
  text-align: center;
  margin: 20px;
`

const Paragraph = styled.p`
  margin-top: 20px;
`

const Wrapper = styled.div`
  margin: auto;
  justify-content: center;
  align-content: center;
  max-width: 600px;
  align-self: center;
  height: 100%;
  padding: 25px 0;
  text-align: center;
`

const ReturnToDashboardButton = styled(Button)`
  &&& {
    margin: 30px 0;
  }
`
