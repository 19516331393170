import { firebaseAuth } from '../../config/firebaseConfig'

export const checkEmailLogin = (setIsEmailLogin) => {
  // Confirm the link is a sign-in with email link.
  if (firebaseAuth.isSignInWithEmailLink(window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    var email = window.localStorage.getItem('bitsyEmailForSignIn')
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      // email = window.prompt('Please provide your email for confirmation')
      setIsEmailLogin(true)
    } else {
      signInEmailLoginUser(email)
    }
  }
}

export function requestNewEmailLoginLink(
  email = null,
  setRequestNewEmailLink = null,
  setRequestNewEmailLinkSent = null,
  setErrors = null,
  firmID = null
) {
  var redirectURL = 'https://app.bitsyadvisor.com/login'
  if (firmID) {
    redirectURL += '/' + firmID
  }

  var actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: redirectURL,
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'example.page.link'
  }

  if (!email) {
    email = firebaseAuth.currentUser.email
  }

  firebaseAuth
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(function () {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('bitsyEmailForSignIn', email)

      if (setRequestNewEmailLink) {
        setRequestNewEmailLink(false)
      }

      if (setRequestNewEmailLinkSent) {
        setRequestNewEmailLinkSent(true)
      }
    })
    .catch(function (error) {
      // Handle errors
      var errors = []
      var errorMessage = error.message

      if (error.code === 'auth/user-not-found') {
        errorMessage = 'No account was found with that email.'
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'Wrong password.'
      }

      errors.push(errorMessage)

      if (setErrors) {
        setErrors(errors)
      }
    })
}

export function signInEmailLoginUser(
  email,
  setErrors = null,
  setRequestNewEmailLink = null
) {
  firebaseAuth
    .signInWithEmailLink(email, window.location.href)
    .catch(function (error) {
      // Handle errors
      var errors = []
      var errorMessage = error.message

      if (error.code === 'auth/user-not-found') {
        errorMessage = 'No account was found with that email.'
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'Wrong password.'
      } else if (
        error.code === 'auth/invalid-action-code' ||
        error.code === 'auth/expired-action-code'
      ) {
        errorMessage =
          'The link you used is expired! Please request another link be sent to your email address by clicking below.'
      }

      errors.push(errorMessage)

      if (setErrors) {
        setErrors(errors)
      }

      if (
        (error.code === 'auth/invalid-action-code' ||
          error.code === 'auth/expired-action-code') &&
        setRequestNewEmailLink
      ) {
        setRequestNewEmailLink(true)
      }
    })
}
