import React, { useState, useEffect } from 'react'
import { Message } from 'semantic-ui-react'
import Error from 'components/partials/Error'
import Loader from 'components/partials/Loader'
import { downloadDocument, listDocuments } from 'actions/integrations/docusign'
import { useFirm, usePeople } from 'contexts'
import styled from 'styled-components'
import { formatCompositeInvestorNames } from 'actions/helpers'
import { formatFirestoreDateString } from 'actions/db/_utils'

const EnvelopeDownloadList = ({ envelopeID, envelope, template }) => {
  const [documents, setDocuments] = useState(null)
  const [errors, setErrors] = useState([])
  const [downloading, setDownloading] = useState([])
  const { firmID, firm } = useFirm()
  const { clients } = usePeople()

  useEffect(() => {
    listDocuments(envelopeID, setDocuments, firmID, setErrors)
  }, [envelopeID, firmID])

  useEffect(() => {}, [downloading])

  if (errors && errors.length) {
    return errors.map((error, eidx) => (
      <Message key={eidx} negative>
        <Message.Header>We're sorry, something went wrong.</Message.Header>
        <p>Please try again and contact Bitsy if the problem persists.</p>

        <p>
          <small>
            <strong>Details:</strong>
          </small>
        </p>
        <Error
          message={error.message}
          code={`ENVELOPENOTFOUND`}
          level="error"
          errorInfo={{
            Location: 'Envelope Download List',
            Details: error,
          }}
          userInfo={{ Firm: firm.name, 'Firm ID': firmID }}
        />
      </Message>
    ))
  }

  if (!documents) {
    return <Loader message="Loading contents! Please wait..." />
  }

  const requestDocumentDownload = (documentID, documentName) => {
    setDownloading([...downloading, documentID])
    downloadDocument(
      envelopeID,
      documentID,
      documentName,
      firmID,
      (downloadedDocId) => {
        setDownloading((newDownloading) =>
          newDownloading.filter(
            (downloadingDocumentID) => downloadingDocumentID !== downloadedDocId
          )
        )
      }
    )
  }

  return (
    <div>
      <p className="text-center">
        The following documents were sent in this envelope:
      </p>
      <p className="text-center instructional">
        <CompositeDocumentButton
          className="link"
          onClick={() =>
            requestDocumentDownload(
              'combined',
              clients && envelope && envelope.client && clients[envelope.client]
                ? `${formatCompositeInvestorNames({
                    client: clients[envelope.client],
                  })}${
                    template && template.title ? ` - ${template.title}` : ''
                  } - ${formatFirestoreDateString(
                    envelope.dateSent
                  )} - Combined PDF`
                : 'Combined PDF'
            )
          }
          disabled={downloading.includes('combined')}
        >
          Combined PDF
        </CompositeDocumentButton>
        <br />
        <small>or click individual files to download each as a PDF:</small>
      </p>

      <StyledList>
        {documents &&
          documents.length &&
          documents.map((document) => {
            const documentName = document.name
            const documentId = document.documentId

            return (
              <li key={document.documentId}>
                <DocumentButton
                  className="link"
                  onClick={() =>
                    requestDocumentDownload(documentId, documentName)
                  }
                  disabled={downloading.includes(documentId)}
                >
                  {document.name}
                </DocumentButton>
                {downloading.includes(documentId) && <Loader />}
              </li>
            )
          })}
      </StyledList>

      {/* TODO: [BTS-239] Add download envelope as zip */}
    </div>
  )
}

export default EnvelopeDownloadList

const StyledList = styled.ul`
  li {
    position: relative;
    box-sizing: border-box;

    .loader {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      margin: 0;

      svg {
        max-height: 100%;
        max-width: 100%;
        margin: 0;
      }
    }
  }
`

const DocumentButton = styled.button`
  &&& {
    display: block;
    margin: 8px auto;
    font-size: 16px;

    &[disabled] {
      opacity: 0.2;
    }
  }
`

const CompositeDocumentButton = styled(DocumentButton)`
  &&& {
    margin-top: 26px;
    font-size: 20px;
    font-weight: 700;
  }
`
