import { firebaseFunctions } from '../../config/firebaseConfig'

const integrationsGetFields = firebaseFunctions.httpsCallable(
  'integrationsGetFields'
)

export default async function getIntegrationsFields(uid) {
  try {
    const result = await integrationsGetFields({
      uid,
    })

    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
