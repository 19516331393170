import React, { PureComponent, Fragment } from 'react'
import Field from '../../forms/Field'
import { calculateYearsToRetirement } from '../../../actions/dashboardHelpers'

class InfoPrompt extends PureComponent {
  render() {
    const {
      depth,
      client,
      handleInputChange,
      handleTextMultipleChange,
      handleSubmit,
    } = this.props

    this.personal_advisor_fields = Object.keys(client.personal_advisors).map(
      (advisorType) => {
        const advisorTypeName = advisorType
          .toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')
          .replace(/_/g, ' ')
        const idBase = 'personal_advisors-' + advisorType + '-'

        return (
          <div
            key={advisorType}
            className="form-group personal-advisor-form-group"
          >
            <strong className="personal-advisor-heading">
              {advisorTypeName}
            </strong>
            <Field
              label="Name"
              id={idBase + 'name'}
              value={client.personal_advisors[advisorType].name}
              type="text"
              handleInputChange={handleInputChange}
            />
            <Field
              label="Firm"
              id={idBase + 'firm'}
              value={client.personal_advisors[advisorType].firm}
              type="text"
              handleInputChange={handleInputChange}
            />
            <Field
              label="Commitment Level"
              id={idBase + 'commitment_level'}
              value={client.personal_advisors[advisorType].commitment_level}
              type="select"
              options="1-5"
              handleInputChange={handleInputChange}
            />
          </div>
        )
      }
    )

    return (
      <div className="slideContents infoPrompt active">
        <div className="question">
          {'Great, we just need some ' + depth + ' info to schedule.'}
        </div>
        <div className="form-group">
          <Field
            label="Legal First Name"
            id="legal_name_first"
            value={client.legal_name_first}
            type="text"
            handleInputChange={handleInputChange}
          />
          <Field
            label="Legal Last Name"
            id="legal_name_last"
            value={client.legal_name_last}
            type="text"
            handleInputChange={handleInputChange}
          />

          {depth === 'basic' ? (
            <Fragment>
              <Field
                label="Age Range"
                id="age_range"
                value={client.age_range}
                options="age-range"
                type="select"
                handleInputChange={handleInputChange}
              />
              <Field
                label="Occupation"
                id="occupation"
                value={client.occupation}
                type="text"
                handleInputChange={handleInputChange}
              />
              <Field
                label="Email"
                id="email"
                value={client.email}
                type="text"
                handleInputChange={handleInputChange}
              />
              <Field
                label="Mobile"
                id="phone_mobile"
                value={client.phone_mobile}
                type="phone"
                handleInputChange={handleInputChange}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Field
                label="Preferred Name"
                id="preferred_name"
                value={client.preferred_name}
                type="text"
                handleInputChange={handleInputChange}
              />
              <Field
                label="Birthdate"
                id="birthdate"
                value={client.birthdate}
                type="date"
                handleInputChange={handleInputChange}
              />
              <Field
                label="Spouse/Partner"
                id="spouse_partner"
                value={client.spouse_partner}
                type="text"
                handleInputChange={handleInputChange}
              />

              <Field
                label="Children?"
                id="children"
                value={client.children}
                type="text-multiple"
                textMultipleFields={[
                  { id: 'name', label: 'Name', type: 'text' },
                ]}
                handleInputChange={handleTextMultipleChange}
              />

              <Field
                label="Close Friends/Relatives"
                id="close_friends_relatives"
                value={client.close_friends_relatives}
                type="text-multiple"
                textMultipleFields={[
                  { id: 'name', label: 'Name', type: 'text' },
                  { id: 'relation', label: 'Relation', type: 'text' },
                ]}
                handleInputChange={handleTextMultipleChange}
              />

              <strong>Address</strong>
              <Field
                label="Home Address"
                id="address_street"
                value={client.address_street}
                type="text"
                handleInputChange={handleInputChange}
              />
              <Field
                label="City"
                id="address_city"
                value={client.address_city}
                type="text"
                handleInputChange={handleInputChange}
              />
              <Field
                label="State"
                id="address_state"
                value={client.address_state}
                options="state"
                type="select"
                handleInputChange={handleInputChange}
              />
              <Field
                label="Zip"
                id="address_zip"
                value={client.address_zip}
                type="text"
                handleInputChange={handleInputChange}
              />

              <Field
                label="Preferred Method of Communication"
                id="preferred_communication"
                value={client.preferred_communication}
                options="communication"
                type="select"
                handleInputChange={handleInputChange}
              />

              <Field
                label="How do you like to spend your time?"
                id="spend_time"
                value={client.spend_time}
                type="textarea"
                handleInputChange={handleInputChange}
              />

              <strong>Occupation</strong>
              <Field
                label="Title"
                id="occupation_details-title"
                value={client.occupation_details.title}
                type="text"
                handleInputChange={handleInputChange}
              />
              <Field
                label="Employer"
                id="occupation_details-employer"
                value={client.occupation_details.employer}
                type="text"
                handleInputChange={handleInputChange}
              />
              <Field
                label="# of Years"
                id="occupation_details-years"
                value={client.occupation_details.years}
                type="number"
                handleInputChange={handleInputChange}
              />

              <strong>Personal Advisors & Commitment Level</strong>
              {this.personal_advisor_fields}

              <Field
                label="How much would you like to have per month in retirement?"
                id="retirement_income"
                value={client.retirement_income}
                type="numberMoneyMonth"
                handleInputChange={handleInputChange}
              />
              <Field
                label="Desired retirement age"
                id="retirement_age_exact"
                value={client.retirement_age_exact}
                type="number"
                handleInputChange={handleInputChange}
              />
              <Field
                label="Work during retirement?"
                id="retirement_work"
                value={client.retirement_work}
                options="yes-no-maybe"
                type="select"
                handleInputChange={handleInputChange}
              />

              <Field
                label="Currently saving?"
                id="current_saving"
                value={client.current_saving}
                options={[
                  'IRA, 401(k), or something similar',
                  'Checking account',
                  'Other',
                ]}
                type="select-multiple"
                handleInputChange={handleInputChange}
              />
              <Field
                label="How much per month?"
                id="current_saving_per_month"
                value={client.current_saving_per_month}
                type="numberMoneyMonth"
                handleInputChange={handleInputChange}
              />

              <div className="form-group form-group-years-to-retirement">
                <span>Years to retirement:</span>{' '}
                {calculateYearsToRetirement(
                  client.birthdate,
                  client.retirement_age_exact
                )}
              </div>

              <Field
                label="What are you currently contributing to?"
                id="current_contributions"
                value={client.current_contributions}
                options={['IRA', 'Company Retirement Plan', 'None']}
                type="select-multiple"
                handleInputChange={handleInputChange}
              />

              <Field
                label="How extensive is your current insurance plan?"
                id="current_insurance_plan_status"
                value={client.current_insurance_plan_status}
                options={[
                  'I have adequately planned, and have:',
                  'I have some coverage but want to discuss:',
                  'I have no coverage and want to discuss:',
                ]}
                type="select"
                handleInputChange={handleInputChange}
              />

              <Field
                label={
                  client.current_insurance_plan_status &&
                  client.current_insurance_plan_status.length > 0
                    ? client.current_insurance_plan_status
                    : 'Insurance Plans'
                }
                id="current_insurance_plans"
                value={client.current_insurance_plans}
                options={[
                  'Disability Insurance',
                  'Life Insurance',
                  'Business Insurance',
                  'Long-term Care Insurance',
                ]}
                type="select-multiple"
                handleInputChange={handleInputChange}
              />
            </Fragment>
          )}

          <button onClick={(e) => handleSubmit(e)} name="infoprompt-submit">
            Submit
          </button>
        </div>
      </div>
    )
  }
}

export default InfoPrompt
