import React, { memo, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Button, Confirm } from 'semantic-ui-react'
import Loader from 'components/partials/Loader'
import { downloadBlob } from 'actions/helpers'
import styled from 'styled-components'
import { colors } from 'styles'

const ExportTemplateButton = memo(({ template }) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (success) {
      setLoading(false)
      setConfirmModalOpen(false)
    }
  }, [success])

  const handleExportTemplate = async () => {
    setLoading(true)
    const fileData = JSON.stringify(template)
    const blob = new Blob([fileData], { type: 'text/plain' })
    downloadBlob({
      blob,
      fileName: `BitsyFormTemplate_${template.title}_${
        template.id
      }_${dayjs().format('YYYY-MM-DDTHHmmssZ[Z]')}`,
      fileExt: 'json',
    })
    setSuccess(true)
  }

  return (
    <>
      <Button
        type="button"
        content="Export template"
        size="tiny"
        icon="download"
        onClick={() =>
          setConfirmModalOpen((confirmModalOpen) => !confirmModalOpen)
        }
      />
      <PositiveConfirm
        open={confirmModalOpen}
        content={
          <ConfirmContents>
            {loading ? (
              <Loader message="Downloading..." />
            ) : (
              <>
                <p>
                  <strong>
                    This will download a JSON file to your computer.
                  </strong>
                </p>
                <p>Are you sure?</p>
              </>
            )}
          </ConfirmContents>
        }
        confirmButton="Yes, download it."
        onConfirm={handleExportTemplate}
        cancelButton="Never mind!"
        onCancel={() => setConfirmModalOpen(false)}
      />
    </>
  )
})

export default ExportTemplateButton

const PositiveConfirm = styled(Confirm)`
  &&& {
    button.primary {
      background: ${colors.primary};

      &:hover,
      &:focus {
        background: ${colors.primaryAlt};
      }
    }
  }
`

const ConfirmContents = styled.div`
  padding: 30px;
`
