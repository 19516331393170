import React, { PureComponent, Fragment } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import Field from '../../forms/Field'
import RetryIcon from '../../icons/RetryIcon'
import CheckIcon from '../../icons/CheckIcon'
import { currentDate } from '../../../actions/iacHelpers'
import './SignatureRow.scss'

export default class SignatureRow extends PureComponent {
  constructor(props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSignatureConfirm = this.handleSignatureConfirm.bind(this)
    this.handleSignatureRetry = this.handleSignatureRetry.bind(this)
  }

  sig = {}

  handleInputChange(event) {
    const { handleInputChange, state } = this.props

    if (handleInputChange) {
      handleInputChange(event)
    } else if (state) {
      const target = event.target
      const name = target.name
      const value = target.value

      state.setState({
        [name]: value,
      })
    }
  }

  handleSignatureConfirm(event, ref, refName) {
    event.preventDefault()

    const { signature } = this.props

    var image = null

    if (!signature) {
      image = ref.getCanvas().toDataURL('image/png')
    }

    this.props.handleSignatureConfirm(refName, image)
  }

  handleSignatureRetry(event, refName) {
    event.preventDefault()

    if (this.sig) {
      this.sig.clear()
    }

    this.props.handleSignatureRetry(refName)
  }

  render() {
    const {
      title,
      description,
      signerType,
      signature,
      id,
      textSignature,
      allowEdit,
      signatureType,
      hideDate,
    } = this.props

    this.currentDate = <div className="value">{currentDate()}</div>

    // Construct id
    var signatureID = id
    if (!signatureID) {
      signatureID = ''
    }

    // Construct text signature label
    if (textSignature && textSignature.label) {
      var textSignatureLabel = textSignature.label
      if (signatureType && signatureType === 'text') {
        textSignatureLabel = textSignatureLabel.replace('(or) ', '')
      }
    }

    return (
      <div className="row signature-row">
        <h6>{title ? title : signerType}</h6>

        <div className="signature" id={signatureID}>
          {(!signatureType || signatureType !== 'text') && (
            <Fragment>
              {signature && signature.length > 1 ? (
                <img src={signature} alt={description} />
              ) : !allowEdit ? (
                <div className="blank-signature"></div>
              ) : (
                <SignatureCanvas
                  ref={(ref) => {
                    this.sig = ref
                  }}
                  className="to-do"
                />
              )}

              {allowEdit && (
                <div className="signature-buttons">
                  <button
                    className="signatureRetry"
                    onClick={(event) => this.handleSignatureRetry(event, id)}
                  >
                    <RetryIcon />
                  </button>
                  <button
                    className={
                      signature && signature.length
                        ? 'signatureConfirm disabled'
                        : 'signatureConfirm'
                    }
                    onClick={(event) =>
                      this.handleSignatureConfirm(event, this.sig, id)
                    }
                  >
                    <CheckIcon />
                  </button>
                </div>
              )}
            </Fragment>
          )}

          <div className="label">{description}</div>
        </div>

        {textSignature && (!signatureType || signatureType !== 'draw') && (
          <Field
            label={textSignatureLabel}
            showLabel={true}
            placeholder={textSignature.placeholder}
            id={textSignature.id}
            value={textSignature.value}
            type="text"
            handleInputChange={this.handleInputChange}
            readOnly={allowEdit ? false : true}
          />
        )}

        {!hideDate && (
          <div className="date">
            {this.currentDate}
            <div className="label">Date</div>
          </div>
        )}
      </div>
    )
  }
}
