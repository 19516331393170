import React, { memo } from 'react'
import ReactTooltip from 'react-tooltip'
import { useFirm, useUser } from 'contexts'
import styled from 'styled-components'

const availableDocumentTypes = {
  iac: 'Investment Advisory Agreement (IAC)',
  k401: '401(k) Agreement',
  suitability: 'Suitability Form',
}

export default memo(function LegacyTemplateSelector({ setDocType }) {
  const { userType } = useUser()
  const { firm } = useFirm()

  return Object.keys(availableDocumentTypes).map((typeID) => {
    const typeName = availableDocumentTypes[typeID]
    let dbTypeName = typeID
    switch (typeID) {
      case 'iac':
        dbTypeName = 'onboardingModule'
        break
      case 'suitability':
        dbTypeName = 'suitabilityForm'
        break
      default:
        break
    }

    let firmHasService = false
    if (firm && firm.hasServices && firm.hasServices[dbTypeName]) {
      firmHasService = true
    }

    let noServiceMessage =
      "You don't have this service! Please contact Bitsy to subscribe."
    if (userType === 'advisor') {
      noServiceMessage =
        "Your firm doesn't have this service! Please talk to your firm admin about subscribing."
    }

    return (
      <div key={typeID} data-tip={!firmHasService ? noServiceMessage : ''}>
        <StyledButton
          className={`doc-selector ${typeID}`}
          onClick={() => setDocType(typeID)}
          disabled={!firmHasService}
        >
          {typeName}
        </StyledButton>
        {!firmHasService && (
          <ReactTooltip place="bottom" type="info" effect="solid" />
        )}
      </div>
    )
  })
})

const StyledButton = styled.button`
  &&& {
    width: 100%;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
`
