import { firebaseAuth } from '../../config/firebaseConfig'
// import { requestNewEmailLoginLink } from './emailLoginActions'
import { updateItemInfo } from '../db/dbActions'

export const updatePassword = (
  userType,
  password,
  setRequestNewEmailLink = null,
  setErrors = null,
  firmID = null
) => {
  var auth = firebaseAuth

  var user = auth.currentUser

  user
    .updatePassword(password)
    .then(() => {
      // Update hasSetPassword in client doc
      if ('firm-admin' !== userType) {
        updateItemInfo(userType, user.uid, 'hasSetPassword', true)
      }
    })
    .catch((error) => {
      // Handle errors
      var errors = []
      var errorMessage = error.message

      if (error.code === 'auth/requires-recent-login') {
        errorMessage =
          'This operation is sensitive. Please click the link in your email to log in again and retry.'
        setRequestNewEmailLink(true)
      }

      errors.push(errorMessage)

      if (setErrors) {
        setErrors(errors)
      }
    })
}

export const deleteUser = (uid) => {
  var auth = firebaseAuth

  const user = auth.currentUser

  console.log(user)
  // // Delete auth user
  // user.delete().then(() => {
  //   // User deleted.
  //   console.log("User deleted")
  // }).catch((error) {
  //   // An error happened.
  //   console.error("Error deleting user: ", error)
  // })
}
