import React, { useState, useEffect } from 'react'
import RemoveIcon from '../icons/RemoveIcon'
import './ErrorsList.scss'

const ErrorsList = (props) => {
  const { errors, title, sticky } = props
  const [show, setShow] = useState(true)

  useEffect(() => {
    // Show on new validation request
    setShow(true)
  }, [errors])

  if (!errors || !errors.length || !show) {
    return ''
  }

  const errorsList = errors.map((error, key) => <li key={key}>{error}</li>)

  return (
    <ul
      className={
        sticky ? 'errors bitsy-errors sticky-errors' : 'errors bitsy-errors'
      }
    >
      {title && <li className="title">{title}</li>}
      {errorsList}
      {sticky && (
        <button className="button-close" onClick={() => setShow(false)}>
          <span className="visually-hidden">Close</span>
          <RemoveIcon />
        </button>
      )}
    </ul>
  )
}

export default ErrorsList
