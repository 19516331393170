import React, { memo } from 'react'
import ClientProfile from './ClientProfile'
import AdvisorProfile from './AdvisorProfile'
import FirmProfile from './FirmProfile'
import './Profile.scss'

export default memo(function Profile(props) {
  const {
    advisors,
    advisor,
    firm,
    profile,
    onClientSelect,
    onAdvisorStatusChange,
    userType,
    firmDisclosures,
    advisorDisclosures,
  } = props

  if (!profile || !profile.type || !profile.id || !profile.content) {
    return ''
  }

  const profileType = profile.type
  const profileID = profile.id
  const profileContent = profile.content

  switch (profileType) {
    case 'advisor':
      return (
        <AdvisorProfile
          profile={profileContent}
          uid={profileID}
          onClientSelect={onClientSelect}
          onAdvisorStatusChange={onAdvisorStatusChange}
        />
      )
    case 'client':
      return (
        <ClientProfile
          profile={profileContent}
          uid={profileID}
          advisors={advisors}
          firm={firm}
          advisor={advisor}
          userType={userType}
          firmDisclosures={firmDisclosures}
          advisorDisclosures={advisorDisclosures}
        />
      )
    case 'firm':
      return (
        <FirmProfile
          profile={profileContent}
          uid={profileID}
          onClientSelect={onClientSelect}
        />
      )
    default:
      return ''
  }
})
