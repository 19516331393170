import { firebaseDB, clientDB } from '../../config/firebaseConfig'
import { updateUserTaskStatus } from './userTaskActions'

export function addDocToDB(
  type,
  file,
  uid,
  title,
  date,
  allDocs = null,
  source = null,
  taskDetails = null
) {
  var db = firebaseDB
  if (source && source === 'client') {
    db = clientDB
  }

  var allDocsKey = type + 's'
  if (type === 'suitability-form') {
    allDocsKey = 'suitability_forms'
  }

  var allDocsArray = []
  if (allDocs) {
    allDocsArray = allDocs
  }

  allDocsArray.push({
    link: file,
    name: title,
    date: date,
    // type: type
  })

  var updatedFields = {
    [allDocsKey]: allDocsArray,
  }
  if (type === 'iac') {
    updatedFields['hasPendingIAC'] = false
  } else if (type === 'suitability-form') {
    updatedFields['hasPendingSuitabilityForm'] = false
    updatedFields['pendingChanges'] = null
  }

  var collection = type + 's'
  if (type === 'iac' || type === 'suitability-form') {
    collection = 'clients'
  }

  db.collection(collection)
    .doc(uid)
    .update(updatedFields)
    .then(() => {
      // console.log("Client updated")
      if (taskDetails) {
        // console.log(taskDetails)

        var userType = null
        if (type === 'suitability-form') {
          userType = 'advisor'
        }

        if (userType) {
          updateUserTaskStatus(
            userType,
            taskDetails.uid,
            taskDetails.id,
            'complete'
          )
        }
      }
    })
}
