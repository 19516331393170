import React, { Component } from 'react'
import Field from './Field'
// import SignatureRow from '../documents/partials/SignatureRow'
import FirmLogo from '../partials/FirmLogo'
import ErrorsList from '../partials/ErrorsList'
import Error from '../partials/Error'
import { addAccount } from '../../actions/authActions'
import {
  getFirmDetails,
  stopListeningToFirmDetails,
} from '../../actions/dbActions'
import { validEmailRegex } from '../../config/regexPatterns'
import { getFirmStatus } from '../../actions/helpers'
import { firmIDs } from '../../config/firmConfig'
import './LegacyRegisterForm.scss'

class RegisterAdvisorForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
      show_password: false,
      firmID: '',
      firm: null,
      password: '',
      advisor: {
        firm: this.props.inviteCode.split('-')[0],
        email: this.props.inviteCode.split('-')[1],
        name: '',
        crd: '',
        inactive: false,
      },
      errors: [],
    }

    this.addAdvisor = this.addAdvisor.bind(this)
    this.attemptContinue = this.attemptContinue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    // this.handleSignatureConfirm = this.handleSignatureConfirm.bind(this)
    // this.handleSignatureRetry = this.handleSignatureRetry.bind(this)
    this.increaseStep = this.increaseStep.bind(this)
    this.togglePassword = this.togglePassword.bind(this)
    this.validateStep = this.validateStep.bind(this)
  }

  componentDidMount() {
    const { inviteCode } = this.props

    // Parse invite code
    var firmID = ''
    if (inviteCode.length) {
      firmID = inviteCode.split('-')[0]
      this.setState({
        firmID: firmID,
      })
    }

    // Get firm details
    if (firmID.length) {
      getFirmDetails(firmID, this)
    }

    // // Get advisor details
    // if (advisorID.length) {
    // 	getAdvisorDetails(advisorID, this)
    // }
  }

  componentWillUnmount() {
    stopListeningToFirmDetails()
  }

  addAdvisor() {
    if (this.state.errors.length === 0) {
      // Add advisor to auth and database
      addAccount(
        'advisor',
        this.state.advisor.email,
        this.state.password,
        this.state.advisor,
        this
      )
    }
  }

  attemptContinue(event) {
    event.preventDefault()

    this.validateStep(this.state.tabIndex)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'password') {
      // Top level state changes
      this.setState({
        [name]: value,
      })
    } else {
      // Advisor state changes
      this.setState({
        advisor: {
          ...this.state.advisor,
          [name]: value,
        },
      })
    }
  }

  // handleSignatureConfirm(refName, image) {
  //   this.setState({
  //     advisor: {
  //       ...this.state.advisor,
  //       signature: image
  //     }
  //   })
  // }

  // handleSignatureRetry(refName) {
  //   this.setState({
  //     advisor: {
  //       ...this.state.advisor,
  //       signature: null
  //     }
  //   })
  // }

  increaseStep() {
    if (this.state.tabIndex === 0) {
      this.addAdvisor()
      return
    }
  }

  togglePassword(event) {
    event.preventDefault()

    this.setState({
      show_password: !this.state.show_password,
    })
  }

  validateStep(step) {
    const { firmID, firm, password, advisor } = this.state

    var errors = []

    switch (step) {
      case 0:
        // Email
        if (!validEmailRegex.test(advisor.email)) {
          errors.push('Please enter a valid email address.')
        }

        if (firm && firm.advisors) {
          var emailNotIncluded = true
          firm.advisors.map(function (teamMember) {
            if (teamMember.email === advisor.email) {
              emailNotIncluded = false
            }

            return emailNotIncluded
          })

          if (emailNotIncluded) {
            errors.push(
              'That email has not been approved by ' + firm.name + '.'
            )
          }
        }

        if (firm && firm.team && firm.team.includes(advisor.email)) {
          errors.push('That email is not approved by this firm.')
        }

        // Name
        if (advisor.name.length < 1) {
          errors.push('Please enter a name.')
        }

        // Password
        if (password.length < 8) {
          errors.push('Password must be at least 8 characters.')
        }

        // CRD
        if (advisor.crd.length < 1 && firmIDs['gwn'] !== firmID) {
          errors.push('Please enter a CRD.')
        }

        // // Signature
        // if (!advisor.signature) {
        //   errors.push("Please complete your signature.")
        // }

        break
      default:
        break
    }

    // Set state and increase step
    this.setState(
      {
        errors: errors,
      },
      () => {
        this.increaseStep()
      }
    )
  }

  render() {
    const {
      firm,
      advisor,
      errors,
      firmID,
      password,
      show_password,
    } = this.state

    const firmInGoodStanding = getFirmStatus(firm)

    if (!firmInGoodStanding && firmInGoodStanding !== null) {
      return (
        <Error
          message="We couldn't load this! Please contact your administrator."
          code="RADV-FNGS"
          level="info"
          errorInfo={{
            Location: 'Register Advisor',
            Details: 'Firm not in good standing',
          }}
          userInfo={{
            username: advisor.name,
            email: advisor.email,
            Firm: firm.name,
            'Firm ID': firmID,
          }}
        />
      )
    }

    if (!firmInGoodStanding) {
      return ''
    }

    return (
      <form id="register-advisor">
        {firm && firm.logo && <FirmLogo firm={firm} />}

        <h2>
          Get set up with <br />
          {firm.name} <br />
          in minutes!
        </h2>

        <div className="step-contents">
          <Field
            type="email"
            id="email"
            label="Email"
            value={advisor.email}
            handleInputChange={this.handleInputChange}
            readOnly={true}
          />
          <Field
            type="text"
            id="name"
            label="Name"
            value={advisor.name}
            handleInputChange={this.handleInputChange}
          />
          <div className="form-group">
            <Field
              type={show_password ? 'text' : 'password'}
              id="password"
              label="Password"
              value={password}
              handleInputChange={this.handleInputChange}
              tooltip="At least 8 characters"
            />
            <button
              type="button"
              id="show-password"
              className="primary btn-sm"
              onClick={this.togglePassword}
            >
              {show_password ? 'Hide' : 'Display'}
            </button>
          </div>
          <Field
            type="text"
            id="crd"
            label="CRD"
            value={advisor.crd}
            handleInputChange={this.handleInputChange}
          />

          {/* <div className="form-group bitsy-form-group">
            <SignatureRow
              title="Signature"
              id='advisorSig'
              signature={advisor.signature}
              allowEdit={true}
              handleSignatureConfirm={this.handleSignatureConfirm}
              handleSignatureRetry={this.handleSignatureRetry}
              signatureType="draw"
              hideDate={true} />
          </div> */}

          <div className="form-group submit-section">
            <ErrorsList errors={errors} />

            <button
              className="continue"
              onClick={this.attemptContinue}
              type="button"
            >
              Create account
            </button>
          </div>
        </div>
      </form>
    )
  }
}

export default RegisterAdvisorForm
