import React, { memo, useState, useEffect } from 'react'
import { Button, Confirm, Form, TextArea, Message } from 'semantic-ui-react'
import Loader from 'components/partials/Loader'
import { isValidJson } from 'helpers/_utils'
import { updateFormFields } from 'actions/db/templates'
import { useFirm } from 'contexts'
import styled from 'styled-components'
import { colors } from 'styles'

const TemplateJsonEditor = memo(
  ({ templateID, template, handleSetTemplate }) => {
    const { firmID } = useFirm()

    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errors, setErrors] = useState([])
    const [json, setJson] = useState('')

    useEffect(() => {
      if (template) {
        const newJson = JSON.stringify(template, null, 2)
        if (newJson) {
          if (!isValidJson(newJson)) {
            setErrors([
              'Initial JSON is invalid. Please contact Bitsy or make required changes below.',
            ])
          }
          setJson(newJson)
        }
      }
    }, [template])

    useEffect(() => {
      if (success) {
        setLoading(false)
        setConfirmModalOpen(false)
      }
    }, [success])

    useEffect(() => {
      if (errors && errors.length) {
        setLoading(false)
      }
    }, [errors])

    const handleSaveJsonTemplate = async () => {
      if (json) {
        setLoading(true)

        if (!isValidJson(json)) {
          setErrors(['Invalid JSON. Please review and try again.'])
        } else {
          handleSetTemplate(JSON.parse(json))
          await updateFormFields({
            firmID,
            templateID,
            template: JSON.parse(json),
            setSuccess,
          })
        }
      } else {
        setErrors(['Please enter some JSON before continuing.'])
      }
    }

    return (
      <>
        <Button
          type="button"
          content="JSON Editor"
          size="tiny"
          icon="upload"
          onClick={() =>
            setConfirmModalOpen((confirmModalOpen) => !confirmModalOpen)
          }
        />
        <PositiveConfirm
          open={confirmModalOpen}
          content={
            <ConfirmContents>
              {loading ? (
                <Loader message="Saving your changes..." fullScreen />
              ) : (
                <>
                  <p>
                    <strong>
                      This will replace this form's details with the JSON below.
                      This has the potential to break things, so only do this if
                      you know what you're doing!
                    </strong>
                  </p>
                  <StyledForm>
                    <StyledTextArea
                      placeholder="Paste raw JSON here"
                      value={json}
                      onChange={(e, { value }) => setJson(value)}
                    />
                  </StyledForm>
                  <p>
                    Are you sure you want to save the JSON above and replace all
                    existing data?
                  </p>
                  {errors && !!errors.length && (
                    <Message error>
                      <Message.Header>Something went wrong</Message.Header>
                      <Message.List>
                        {errors.map((error, idx) => (
                          <Message.Item key={idx}>{error}</Message.Item>
                        ))}
                      </Message.List>
                    </Message>
                  )}
                </>
              )}
            </ConfirmContents>
          }
          confirmButton="Yes, save it."
          onConfirm={handleSaveJsonTemplate}
          cancelButton="Never mind!"
          onCancel={() => setConfirmModalOpen(false)}
        />
      </>
    )
  }
)

export default TemplateJsonEditor

const PositiveConfirm = styled(Confirm)`
  &&& {
    button.primary {
      background: ${colors.primary};

      &:hover,
      &:focus {
        background: ${colors.primaryAlt};
      }
    }
  }
`

const ConfirmContents = styled.div`
  padding: 30px;
`

const StyledForm = styled(Form)`
  &&& {
    margin: 1em auto;
  }
`

const StyledTextArea = styled(TextArea)`
  &&& {
    resize: none;
    min-height: 200px;
  }
`
