import * as React from 'react'
import { PageView } from '../../actions/trackingActions'

interface NavigationTrackerProps {
  pathname: string;
  uid: string;
  children?: React.ReactNode;
}

class NavigationTracker extends React.Component<
  NavigationTrackerProps
  > {
  componentDidMount() {
    this.pageview(this.props.pathname, this.props.uid)
  }

  componentDidUpdate(prevProps: NavigationTrackerProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.pageview(this.props.pathname, this.props.uid)
    }
  }

  pageview(pathname: string, uid: string) {
    PageView(pathname, uid)
  }

  render(): React.ReactNode {
    return this.props.children || null
  }
}

export default NavigationTracker
