import { firebaseDB as db } from '../../../config/firebaseConfig'

export const addForm = async ({ formDoc, setFormSendSuccess, setErrors }) => {
  try {
    const docRef = await db.collection('forms').add(formDoc)
    setFormSendSuccess && setFormSendSuccess(true)
    return docRef.id
  } catch (err) {
    console.error(err)
    console.log('Could not add in-progress form, see error above.')
    if (setErrors) {
      setErrors([err])
    }
  }
}

export const updateForm = async ({
  formID,
  formDoc,
  setFormSendSuccess,
  setErrors,
}) => {
  try {
    console.log(`Updating form ${formID}...`, formDoc)
    await db.collection('forms').doc(formID).update(formDoc)
    setFormSendSuccess && setFormSendSuccess(true)
    return formID
  } catch (err) {
    console.error(err)
    console.error(err.message)
    console.log('Could not update in-progress form, see error above.')
    if (setErrors) {
      setErrors([err])
    }
  }
}
