import React, { PureComponent, Fragment } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Collapse from 'react-collapse'
import Field from '../../Field'
import AddressFields from '../../AddressFields'
import WidgetToggle from '../../../partials/WidgetToggle'
// import BitsyRegisterFooter from '../partials/BitsyRegisterFooter'
import InvestmentProfileQuiz from '../../../InvestmentProfileQuiz'
import 'react-tabs/style/react-tabs.css'
import './IACForm.scss'
import DownloadIcon from '../../../icons/DownloadIcon'
import RemoveIcon from '../../../icons/RemoveIcon'
import ErrorsList from '../../../partials/ErrorsList'
import {
  startListeningToClientAuth,
  stopListeningToClientAuth,
  signInClientUser,
  signOutClientUser,
} from '../../../../actions/clientAuthActions'
import {
  getFirmDetails,
  stopListeningToFirmDetails,
  getFirmAdvisors,
  stopListeningToFirmAdvisors,
} from '../../../../actions/clientDbActions'
import { validEmailRegex } from '../../../../config/regexPatterns'
import { custodian_options } from '../../../../config/options'
import { clientInitialState } from '../../../../config/iacConfig'
// import IACModal from '../partials/IACModal'
import { validateClientFields } from '../../../../actions/iacHelpers'
import InvestmentExperienceFields from '../../InvestmentExperienceFields'
import InvestmentProfileFields from '../../InvestmentProfileFields'
import AddNewFirmClientsModule from '../../../dashboards/partials/AddNewFirmClientsModule'
import FeeSchedule from '../../../partials/FeeSchedule'
import { firmIDs } from '../../../../config/firmConfig'
// import { sendDocument } from '../../actions/integrations/docusign'
import { updateClient } from '../../../../actions/dbActions'

class IACForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
      accordion_basic: false,
      accordion_financial: false,
      accordion_disclosures: false,
      accordion_investment_profile: false,
      accordion_investment_profile_quiz: false,
      accordion_investment_profile_know: false,
      show_iac: false,
      iacReviewType: null,
      accept_disclosures: false,
      firm: {
        advisors: null,
        name: '',
        email: '',
        isInGoodStanding: null,
        currentPeriodEnd: null,
      },
      advisors: null,
      password: '',
      uid: '',
      client: clientInitialState,
      advisor: this.props.advisor,
      errors: [],
      addSuccess: false,
      userAddSuccess: false,
      docSubmitSuccess: false,
      includeSecondInvestor: '',
      saveOnlySuccess: false,
    }

    this.attemptContinue = this.attemptContinue.bind(this)
    // this.getAdvisorDetails = this.getAdvisorDetails.bind(this)
    this.handleClientLogin = this.handleClientLogin.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTextMultipleChange = this.handleTextMultipleChange.bind(this)
    this.handleAddInvestmentProfileLine = this.handleAddInvestmentProfileLine.bind(
      this
    )
    this.handleInvestmentProfileLineChange = this.handleInvestmentProfileLineChange.bind(
      this
    )
    this.handleInvestmentProfileQuizResult = this.handleInvestmentProfileQuizResult.bind(
      this
    )
    this.handleRemoveInvestmentProfileLine = this.handleRemoveInvestmentProfileLine.bind(
      this
    )
    this.hideIAC = this.hideIAC.bind(this)
    this.increaseStep = this.increaseStep.bind(this)
    // this.showIAC = this.showIAC.bind(this)
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.validateStep = this.validateStep.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  componentDidMount() {
    const {
      location,
      firm,
      firmObject,
      firmAdvisors,
      advisor,
      advisorID,
      client,
      clientID,
    } = this.props

    if (location !== 'dashboard' && location !== 'client-profile') {
      signOutClientUser()
      startListeningToClientAuth(this)
    }

    if (firm.length) {
      const firmID = firm

      this.setState({
        client: {
          ...this.state.client,
          firm: firm,
        },
      })

      if (!firmObject) {
        getFirmDetails(firmID, this)
      } else {
        this.setState({
          firm: firmObject,
        })
      }

      if (!firmAdvisors) {
        getFirmAdvisors(firmID, this)
      } else {
        this.setState({
          advisors: firmAdvisors,
        })
      }

      // if (!firmDisclosures) {
      //   getFirmDisclosures(firmID, this)
      // } else {
      //   this.setState({
      //     firmDisclosures: firmDisclosures
      //   })
      // }
    }

    if (advisorID && advisorID.length) {
      this.setState({
        client: {
          ...this.state.client,
          advisor: advisorID,
        },
      })
    }

    if (advisor) {
      this.setState({
        advisor: advisor,
      })
    }

    if (location === 'client-profile' && client && clientID) {
      var clientObject = client
      delete clientObject['id']

      this.setState(
        {
          userAddSuccess: true,
          uid: clientID,
          client: Object.assign({}, this.state.client, clientObject),
        },
        () => {
          this.setState({
            tabIndex: 1,
          })
        }
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, setSavingClientID, setClientAdvisorID } = this.props

    // Update ClientForm with new UID on client add
    if (!prevState.uid && this.state.uid) {
      setSavingClientID(this.state.uid)
    }

    // Set advisor ID to listen to details/disclosures in ClientForm
    if (!prevState.client.advisor && this.state.client.advisor) {
      setClientAdvisorID(this.state.client.advisor)
    }

    // Update advisor details on props change
    if (this.props.advisor !== this.state.advisor) {
      this.setState({
        advisor: this.props.advisor,
      })
    }

    // Increase step on successful user add
    if (
      location === 'dashboard' &&
      !prevState.userAddSuccess &&
      this.state.userAddSuccess
    ) {
      this.setState((prevState) => {
        return { tabIndex: prevState.tabIndex + 1 }
      })
    }

    // Sign out client user after 10 seconds on add success
    if (this.state.addSuccess) {
      setTimeout(function () {
        signOutClientUser()
      }, 10000)
    }

    // Add firm to client user if it is overwritten
    if (!this.state.client.firm && this.props.firm.length) {
      this.setState({
        client: {
          ...this.state.client,
          firm: this.props.firm,
        },
      })
    }

    // Add email to client user if it is overwritten
    if (
      prevState.client.email &&
      prevState.client.email.length &&
      !this.state.client.email.length
    ) {
      this.setState({
        client: {
          ...this.state.client,
          email: prevState.client.email,
        },
      })
    }
  }

  componentWillUnmount() {
    const { location, firm, firmObject, firmAdvisors } = this.props

    if (location !== 'dashboard' && location !== 'client-profile') {
      signOutClientUser()

      stopListeningToClientAuth()
    }

    if (firm.length) {
      if (!firmObject) {
        stopListeningToFirmDetails()
      }

      if (!firmAdvisors) {
        stopListeningToFirmAdvisors()
      }

      // if (!firmDisclosures) {
      //   stopListeningToFirmDisclosures()
      // }
    }

    // if ((this.state.advisor && !this.props.advisor) || this.state.client.advisor) {
    //   stopListeningToAdvisorDetails()
    // }

    // if (this.state.client.advisor) {
    //   stopListeningToAdvisorDisclosures()
    // }
  }

  attemptContinue(event) {
    event.preventDefault()

    this.validateStep(this.state.tabIndex)
  }

  handleSave(event) {
    event.preventDefault()

    // Save to the database
    console.log('Saving client to the database...')
    updateClient(this.state.client, this, this.state.uid, false, false, true)
  }

  // getAdvisorDetails() {
  //   if (this.state.client.advisor.length > 0) {
  //     getAdvisorDetails(this.state.client.advisor, this)
  //     getAdvisorDisclosures(this.state.client.advisor, this)
  //   }
  // }

  handleClientLogin() {
    if (this.state.errors.length === 0) {
      signInClientUser(this.state.client.email, this.state.password, this)
    }
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'email' && !this.state.client.email.length) {
      // Start signup timer and update email
      this.setState({
        client: {
          ...this.state.client,
          [name]: value,
          dateStarted: new Date(),
        },
      })
    } else if (
      name === 'accounts_non_retirement' ||
      name === 'accounts_retirement'
    ) {
      // Checkbox changes
      var newValues = this.state.client[name]
      if (newValues.includes(target.value)) {
        // Remove value
        var index = newValues.indexOf(target.value)

        if (index > -1) {
          newValues.splice(index, 1)
        }
      } else {
        // Add value
        newValues.push(target.value)
      }

      this.setState({
        client: {
          ...this.state.client,
          [name]: newValues,
        },
      })
    } else if (name.startsWith('annual_fees-')) {
      // Custom annual fee changes
      const feeIndex = parseFloat(name.split('annual_fees-')[1])

      var annualFeeFields = this.state.firm.annual_fees
      if (this.state.client.annual_fees) {
        annualFeeFields = this.state.client.annual_fees
      }

      annualFeeFields[feeIndex]['fee'] = value

      this.setState({
        client: {
          ...this.state.client,
          annual_fees: annualFeeFields,
        },
      })
    } else if (name === 'custodian') {
      // Custodian state changes
      this.setState({
        client: {
          ...this.state.client,
          custodian: custodian_options[value],
        },
      })
    } else if (
      name === 'accept_disclosures' ||
      name === 'password' ||
      name === 'includeSecondInvestor'
    ) {
      // Top level state changes
      this.setState({
        [name]: value,
      })
    } else if (name === 'advisor') {
      // Advisor state change
      this.setState({
        client: {
          ...this.state.client,
          [name]: value,
        },
      })
    } else {
      // Client state changes
      this.setState(
        {
          client: {
            ...this.state.client,
            [name]: value,
          },
        },
        function () {
          // Client legal name changes
          if (
            name === 'legal_name_first' ||
            name === 'legal_name_mi' ||
            name === 'legal_name_last'
          ) {
            var fullLegalName = this.state.client.legal_name_first + ' '
            if (this.state.client.legal_name_mi.length > 0) {
              fullLegalName += this.state.client.legal_name_mi + ' '
            }
            fullLegalName += this.state.client.legal_name_last

            this.setState({
              client: {
                ...this.state.client,
                legal_name: fullLegalName,
              },
            })
          }

          // Second Investor legal name changes
          if (
            name === 'second_investor_legal_name_first' ||
            name === 'second_investor_legal_name_mi' ||
            name === 'second_investor_legal_name_last'
          ) {
            var fullSecondLegalName =
              this.state.client.second_investor_legal_name_first + ' '
            if (this.state.client.second_investor_legal_name_mi.length > 0) {
              fullSecondLegalName +=
                this.state.client.second_investor_legal_name_mi + ' '
            }
            fullSecondLegalName += this.state.client
              .second_investor_legal_name_last

            this.setState({
              client: {
                ...this.state.client,
                second_investor_legal_name: fullSecondLegalName,
              },
            })
          }
        }
      )
    }
  }

  handleTextMultipleChange(id, fields) {
    this.setState({
      client: {
        ...this.state.client,
        [id]: fields,
      },
    })
  }

  handleAddInvestmentProfileLine(event) {
    event.preventDefault()

    var joinedInvestmentProfiles = this.state.client.investment_profiles.concat(
      [{ account: '', profile: '', time_frame: '' }]
    )

    this.setState({
      client: {
        ...this.state.client,
        investment_profiles: joinedInvestmentProfiles,
      },
    })
  }

  handleInvestmentProfileLineChange = (idx) => (event) => {
    const target = event.target
    const name = target.name
    const value = target.value

    const newInvestmentProfiles = this.state.client.investment_profiles.map(
      (investment_profile, sidx) => {
        if (idx !== sidx) return investment_profile
        return { ...investment_profile, [name]: value }
      }
    )

    this.setState({
      client: {
        ...this.state.client,
        investment_profiles: newInvestmentProfiles,
      },
    })
  }

  handleInvestmentProfileQuizResult(quiz_state, idx) {
    const newInvestmentProfiles = this.state.client.investment_profiles.map(
      (investment_profile, sidx) => {
        if (idx !== sidx) return investment_profile
        return {
          ...investment_profile,
          profile: quiz_state[idx + 'profile'],
          time_frame: quiz_state[idx + 'time_frame'],
          answers: quiz_state[idx + 'answers'],
        }
      }
    )

    this.setState({
      client: {
        ...this.state.client,
        investment_profiles: newInvestmentProfiles,
      },
    })
  }

  handleRemoveInvestmentProfileLine = (idx) => (event) => {
    event.preventDefault()

    var newInvestmentProfiles = this.state.client.investment_profiles.filter(
      (s, sidx) => idx !== sidx
    )

    this.setState({
      client: {
        ...this.state.client,
        investment_profiles: newInvestmentProfiles,
      },
    })
  }

  hideIAC(event) {
    event.preventDefault()

    this.setState({
      show_iac: false,
    })
  }

  increaseStep() {
    const {
      saveClientToDB,
      addAdditionalDocuments,
      firmDisclosures,
      advisorDisclosures,
      firm: firmID,
    } = this.props
    const { uid, tabIndex, errors, client } = this.state

    if (tabIndex === 0) {
      this.handleClientLogin()
      return
    }

    if (!errors.length) {
      if (tabIndex === 1) {
        saveClientToDB(client, uid)

        if (firmIDs['jalinski'] !== firmID) {
          addAdditionalDocuments([firmDisclosures, advisorDisclosures])
        }
      }

      this.setState((prevState) => {
        return { tabIndex: prevState.tabIndex + 1 }
      })
    }
  }

  // showIAC(event, type) {
  //   event.preventDefault()

  //   const { uid, client } = this.state

  //   switch (type) {
  //     case 'sign':
  //       break
  //     case 'send':
  //       sendDocument(uid, client)
  //       break
  //     default:
  //       this.setState({
  //         docSubmitSuccess: true
  //       })
  //       break
  //   }

  //   // this.setState({
  //   //   show_iac: true,
  //   //   iacReviewType: type
  //   // })
  // }

  toggleAccordion(event) {
    event.preventDefault()

    const target = event.target
    const name = target.name

    this.setState({
      [name]: !this.state[name],
    })
  }

  validateStep(step) {
    const { client, password, accept_disclosures } = this.state

    const firmID = this.props.firm

    var errors = []

    switch (step) {
      case 0:
        // Email
        if (!validEmailRegex.test(client.email)) {
          errors.push('Please enter a valid email address.')
        }

        // Password
        if (password.length < 1) {
          errors.push(
            'Please enter the password given to you by ' +
              this.state.firm.name +
              '.'
          )
        }

        break
      case 1:
        // Set validation to exclude certain fields for certain firms
        var fieldsToExclude = null
        switch (firmID) {
          case firmIDs['centennial']:
            // Centennial
            fieldsToExclude = ['retirement_needs', 'retirement_income']
            break
          case firmIDs['jalinski']:
            // Jalinski
            fieldsToExclude = [
              'fee_type',
              'fee_fixed',
              'retirement_needs',
              'retirement_income',
              'current_investment_assets_managed',
              'investment_profiles',
              'portfolio_value',
              'portfolio_value_qualified',
              'portfolio_value_non_qualified',
              'tax_bracket',
            ]
            break
          default:
            break
        }

        // Client fields
        errors = errors.concat(
          validateClientFields(
            client,
            this.state.firm.name,
            null,
            fieldsToExclude,
            this.state.firm
          )
        )

        // Jalinski customizations
        if (firmID === firmIDs['jalinski']) {
          if (
            client.email_notifications &&
            !validEmailRegex.test(client.email_notifications)
          ) {
            errors.push('Please enter a valid email for notifications.')
          }

          if (!client.conflict_public) {
            errors.push(
              'Please select whether client or family member is an officer or director of a public company.'
            )
          }

          if (!client.conflict_finra) {
            errors.push(
              'Please select whether client or family member is employed by a FINRA firm/Broker Dealer.'
            )
          }

          if (
            client.conflict_finra &&
            client.conflict_finra === 'Yes' &&
            !client.conflict_finra_details
          ) {
            errors.push(
              "Please provide details for client or family member's employment by a FINRA firm/Broker Dealer."
            )
          }

          if (!client.dependents_count) {
            errors.push('Please enter number of dependents.')
          }

          if (
            client.dependents_count &&
            client.dependents_count > 0 &&
            !client.dependents_ages
          ) {
            errors.push("Please enter dependents' ages.")
          }

          if (client.authorized_contacts && client.authorized_contacts.length) {
            client.authorized_contacts.forEach((authorized_contact) => {
              if (
                (!authorized_contact.name ||
                  !authorized_contact.address ||
                  !authorized_contact.phone ||
                  !authorized_contact.email ||
                  !validEmailRegex.test(authorized_contact.email)) &&
                !errors.includes(
                  'Please enter a name, address, phone, and valid email address for all trusted contacts.'
                )
              ) {
                errors.push(
                  'Please enter a name, address, phone, and valid email address for all trusted contacts.'
                )
              }
            })
          }

          if (!client.attorney_name) {
            errors.push(`Please enter attorney's name or "NONE."`)
          }

          if (!client.cpa_name) {
            errors.push(`Please enter CPA's name or "NONE."`)
          }

          if (!client.executor_name) {
            errors.push(`Please enter executor's name or "NONE."`)
          }

          if (!client.annual_income) {
            errors.push('Please enter annual income.')
          }

          if (!client.depends_on_portfolio_assets) {
            errors.push(
              'Please select whether or not client depends on portfolio assets to pay for general expenses.'
            )
          }

          if (
            client.depends_on_portfolio_assets &&
            client.depends_on_portfolio_assets === 'Yes' &&
            !client.depends_on_portfolio_assets_details
          ) {
            errors.push(
              "Please provide details for client's dependence on portfolio assets."
            )
          }

          if (
            client.account_registrations &&
            client.account_registrations.length
          ) {
            client.account_registrations.forEach((account) => {
              if (
                (!account.account_type || !account.estimated_value) &&
                !errors.includes(
                  'Please select an account type and estimated value for each account registration.'
                )
              ) {
                errors.push(
                  'Please select an account type and estimated value for each account registration.'
                )
              }
            })
          }

          if (!client.tactical_strategy) {
            errors.push('Please select tactical strategy.')
          }

          if (
            client.tactical_strategy &&
            client.tactical_strategy !== 'None' &&
            client.risk_tolerance &&
            (client.risk_tolerance === 'Conservative' ||
              client.risk_tolerance === 'Moderate-Conservative')
          ) {
            errors.push(
              'You cannot select a Conservative, or Moderate-Conservative Risk Tolerance with a Tactical Strategy.'
            )
          }

          if (!client.can_withstand_market_declines) {
            errors.push(
              'Please select whether or not client is able to withstand market declines.'
            )
          }

          if (
            client.can_withstand_market_declines &&
            client.can_withstand_market_declines === 'No' &&
            !client.can_withstand_market_declines_details
          ) {
            errors.push(
              "Please provide details on client's ability to withstand market declines."
            )
          }
        }

        // Accept Disclosures
        if (!accept_disclosures) {
          errors.push('Please accept disclosures.')
        }

        break
      default:
        break
    }

    // Set state and increase step
    this.setState(
      {
        errors: errors,
      },
      () => {
        this.increaseStep()
      }
    )
  }

  render() {
    const {
      location,
      children,
      advisorID,
      firmDisclosures,
      advisorDisclosures,
    } = this.props
    const {
      uid,
      password,
      firm,
      advisors,
      client,
      errors,
      docSubmitSuccess,
      includeSecondInvestor,
    } = this.state

    var mainClass = 'add-iac'
    if (
      location &&
      (location === 'dashboard' || location === 'client-profile')
    ) {
      mainClass += ' embedded'
    } else if (firm && firm.module && firm.module.embedded) {
      mainClass += ' embedded'
    } else {
      mainClass += ' widget'
    }

    if (docSubmitSuccess) {
      return (
        <div className={mainClass}>
          {location !== 'dashboard' && location !== 'client-profile' ? (
            <WidgetToggle />
          ) : (
            ''
          )}
          <div className="register-wrapper">
            <div className="register-container">
              <div className="signup-success">
                <h2>Welcome to {firm.name}!</h2>
                <p>Signup was successful. Please check your email!</p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    var portfolio_value_options = []
    if (this.props.firm && this.props.firm.length && firm) {
      portfolio_value_options = firm.annual_fees
    }

    var advisorsObject = {}
    if (advisors && Object.keys(advisors).length) {
      advisorsObject = advisors
    }

    const selectedAccounts = client.accounts_non_retirement.concat(
      client.accounts_retirement
    )

    this.investment_profile_quiz_fields = this.state.client.investment_profiles.map(
      (investment_profile, idx) => (
        <li key={idx}>
          <button
            className="remove-button"
            onClick={this.handleRemoveInvestmentProfileLine(idx)}
          >
            <RemoveIcon />
          </button>
          <div className="for-account">
            <label htmlFor="account">For my...</label>
            <select
              value={investment_profile.account}
              name="account"
              id="account"
              onChange={this.handleInvestmentProfileLineChange(idx)}
            >
              <option value="" disabled>
                Select account
              </option>
              <option value="All Accounts">All Accounts</option>
              {selectedAccounts.map((account, idx) => (
                <option key={idx} value={account}>
                  {account}
                </option>
              ))}
            </select>
          </div>

          <InvestmentProfileQuiz
            idx={idx}
            account={investment_profile.account}
            onResult={this.handleInvestmentProfileQuizResult}
          />
        </li>
      )
    )

    this.firm_disclosure_documents_list_items = ''
    if (firmDisclosures && Object.keys(firmDisclosures).length) {
      this.firm_disclosure_documents_list_items = Object.keys(
        firmDisclosures
      ).map((disclosure, key) => (
        <li key={disclosure}>
          <a
            title="Download"
            target="_blank"
            rel="noopener noreferrer"
            href={firmDisclosures[disclosure].link}
          >
            <DownloadIcon />
            {firmDisclosures[disclosure].name}
          </a>
        </li>
      ))
    }

    this.advisor_disclosure_documents_list_items = ''
    if (advisorDisclosures && Object.keys(advisorDisclosures).length) {
      this.advisor_disclosure_documents_list_items = Object.keys(
        advisorDisclosures
      ).map((disclosure, key) => (
        <li key={disclosure}>
          <a
            title="Download"
            target="_blank"
            rel="noopener noreferrer"
            href={advisorDisclosures[disclosure].link}
          >
            <DownloadIcon />
            {advisorDisclosures[disclosure].name}
          </a>
        </li>
      ))
    }

    if (firmDisclosures === null && advisorDisclosures === null) {
      this.firm_disclosure_documents_list_items = 'No documents!'
    }

    if (firmDisclosures === undefined && advisorDisclosures === undefined) {
      this.firm_disclosure_documents_list_items = 'No documents!'
    }

    const firmID = this.props.firm

    const tabs = ['1. Profile', '2. Info', '3. Agreement']

    return (
      // <div id="bitsy-register-client" className={mainClass}>

      //   {location !== 'dashboard' && location !== 'client-profile' ?
      //     <WidgetToggle />
      //     : ''}

      //   <div className="register-wrapper">

      //     <div className="register-container">

      //       {location !== 'dashboard' && location !== 'client-profile' ?
      //         <FirmLogo firm={firm} />
      //         : ''}

      //       {firm && firm.isInGoodStanding === null ?
      //         ''
      //         : ''
      //       }

      //       {firm && firm.hasServices && !firm.hasServices.onboardingModule ?
      //         'You don\'t currently have the onboarding module service. Please contact Bitsy Advisor to get set up!'
      //         : ''
      //       }

      //       {firm && firm.currentPeriodEnd < dateNow ?
      //         'We couldn\'t load this! Please contact your administrator.'
      //         : ''
      //       }

      //       {firm && firm.hasServices && firm.hasServices.onboardingModule && firm.currentPeriodEnd >= dateNow ?

      <Tabs
        selectedIndex={this.state.tabIndex}
        onSelect={(tabIndex) => this.setState({ tabIndex })}
      >
        <TabList>
          {tabs.map((tab, idx) => (
            <Tab key={idx} disabled={true}>
              {tab}
            </Tab>
          ))}
        </TabList>

        <TabPanel>
          {location !== 'dashboard' && location !== 'client-profile' ? (
            <h2>
              Get set up with <br />
              {firm.name} <br />
              in minutes!
            </h2>
          ) : (
            ''
          )}

          <div className="step-contents">
            {location !== 'dashboard' && location !== 'client-profile' ? (
              <Fragment>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    value={client.email}
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="email"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    value={password}
                    type="password"
                    placeholder="Password"
                    name="password"
                    id="password"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group submit-section">
                  <ErrorsList errors={errors} />

                  <button
                    className="continue"
                    onClick={this.attemptContinue}
                    type="button"
                  >
                    Create account
                  </button>
                </div>
              </Fragment>
            ) : (
              <AddNewFirmClientsModule
                firmID={this.props.firm}
                advisorID={advisorID}
                type="Client"
                onboardingModule={this}
                sendDocumentsOnAdd={this.props.firm === firmIDs['jalinski']}
                firm={this.state.firm}
                firmDisclosures={firmDisclosures}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          {uid.length > 0 ? (
            <form id="register-client">
              <h2>
                {location !== 'dashboard' && location !== 'client-profile'
                  ? 'Your'
                  : 'Client'}{' '}
                Details
              </h2>

              <div className="step-contents">
                <div className="form-group">
                  <button
                    className={
                      this.state.accordion_basic
                        ? 'accordion-toggle active'
                        : 'accordion-toggle'
                    }
                    name="accordion_basic"
                    onClick={this.toggleAccordion}
                  >
                    Basic Information
                  </button>
                  <Collapse isOpened={this.state.accordion_basic}>
                    <h3>First Investor</h3>

                    <Field
                      type="text"
                      id="legal_name_first"
                      label="Legal First Name"
                      required={true}
                      value={client.legal_name_first}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="text"
                      id="legal_name_mi"
                      label="Middle Initial"
                      placeholder="MI"
                      value={client.legal_name_mi}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="text"
                      id="legal_name_last"
                      label="Legal Last Name"
                      required={true}
                      value={client.legal_name_last}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="text"
                      id="preferred_name"
                      label="Preferred Name"
                      value={client.preferred_name}
                      handleInputChange={this.handleInputChange}
                    />

                    <Field
                      type="date"
                      id="birthdate"
                      label="Birthdate"
                      required={true}
                      value={client.birthdate}
                      handleInputChange={this.handleInputChange}
                      showLabel={true}
                    />
                    <Field
                      type="email"
                      id="email_2"
                      label="Email"
                      value={client.email}
                      readOnly={true}
                    />
                    {(firmID === firmIDs['jalinski'] ||
                      firmID === firmIDs['mtg']) && (
                      <Field
                        type="email"
                        id="email_notifications"
                        label="Email for notifications"
                        instructional="(if different)"
                        value={client.email_notifications}
                        readOnly={true} // TODO: [BTS-690] Separate client email for notifications logic
                      />
                    )}

                    <Field
                      type="phone"
                      id="phone"
                      label="Phone"
                      required={true}
                      value={client.phone}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="select"
                      options="marital-status"
                      id="marital_status"
                      label="Marital Status"
                      required={true}
                      value={client.marital_status}
                      handleInputChange={this.handleInputChange}
                    />

                    <AddressFields
                      labelFor="client-address"
                      label="Legal Address"
                      required={true}
                      values={{
                        street: {
                          id: 'address_street',
                          value: client.address_street,
                        },
                        city: {
                          id: 'address_city',
                          value: client.address_city,
                        },
                        state: {
                          id: 'address_state',
                          value: client.address_state,
                        },
                        zip: {
                          id: 'address_zip',
                          value: client.address_zip,
                        },
                      }}
                      handleInputChange={this.handleInputChange}
                    />

                    <Field
                      type="select"
                      options="yes-no"
                      id="has_same_mailing_address"
                      label="Is the above address the same as the mailing address?"
                      value={client.has_same_mailing_address}
                      showLabel={true}
                      handleInputChange={this.handleInputChange}
                    />
                    {client.has_same_mailing_address !== 'Yes' && (
                      <AddressFields
                        labelFor="client-mailing-address"
                        label="Mailing Address"
                        required={true}
                        values={{
                          street: {
                            id: 'address_mailing_street',
                            value: client.address_mailing_street,
                          },
                          city: {
                            id: 'address_mailing_city',
                            value: client.address_mailing_city,
                          },
                          state: {
                            id: 'address_mailing_state',
                            value: client.address_mailing_state,
                          },
                          zip: {
                            id: 'address_mailing_zip',
                            value: client.address_mailing_zip,
                          },
                        }}
                        handleInputChange={this.handleInputChange}
                      />
                    )}

                    <Field
                      type="select"
                      options="yes-no"
                      id="includeSecondInvestor"
                      label="Include a second investor?"
                      value={includeSecondInvestor}
                      handleInputChange={this.handleInputChange}
                    />
                    {includeSecondInvestor === 'Yes' && (
                      <Fragment>
                        <Field
                          type="select"
                          options="yes-no"
                          id="second_investor_has_same_address"
                          required={includeSecondInvestor === 'Yes'}
                          label="Is the above address the same for second investor (if applicable)?"
                          value={client.second_investor_has_same_address}
                          showLabel={true}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="select"
                          options="yes-no"
                          id="second_investor_has_same_mailing_address"
                          required={includeSecondInvestor === 'Yes'}
                          label="Is the above address the same as the second investor's mailing address (if applicable)?"
                          value={
                            client.second_investor_has_same_mailing_address
                          }
                          showLabel={true}
                          handleInputChange={this.handleInputChange}
                        />

                        <Field
                          type="select"
                          options="marital-status"
                          id="second_investor_relation"
                          required={includeSecondInvestor === 'Yes'}
                          label="Relation to Second Investor"
                          value={client.second_investor_relation}
                          handleInputChange={this.handleInputChange}
                        />
                        {client.second_investor_relation &&
                          client.second_investor_relation === 'Other' && (
                            <Field
                              type="textarea"
                              id="second_investor_relation_details"
                              label="Please provide details"
                              placeholder="Sibling, Business Partner, etc."
                              required={true}
                              value={client.second_investor_relation_details}
                              handleInputChange={this.handleInputChange}
                            />
                          )}

                        <h3>Second Investor</h3>

                        <Field
                          type="text"
                          id="second_investor_legal_name_first"
                          label="Legal First Name"
                          required={includeSecondInvestor === 'Yes'}
                          value={client.second_investor_legal_name_first}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="text"
                          id="second_investor_legal_name_mi"
                          label="Middle Initial"
                          required={includeSecondInvestor === 'Yes'}
                          placeholder="MI"
                          value={client.second_investor_legal_name_mi}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="text"
                          id="second_investor_legal_name_last"
                          label="Legal Last Name"
                          required={includeSecondInvestor === 'Yes'}
                          value={client.second_investor_legal_name_last}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="text"
                          id="second_investor_preferred_name"
                          label="Preferred Name"
                          value={client.second_investor_preferred_name}
                          handleInputChange={this.handleInputChange}
                        />

                        <Field
                          type="date"
                          id="second_investor_birthdate"
                          label="Birthdate"
                          required={includeSecondInvestor === 'Yes'}
                          value={client.second_investor_birthdate}
                          handleInputChange={this.handleInputChange}
                          showLabel={true}
                        />
                        <Field
                          type="email"
                          id="second_investor_email"
                          label="Email"
                          required={includeSecondInvestor === 'Yes'}
                          value={client.second_investor_email}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="phone"
                          id="second_investor_phone"
                          label="Phone"
                          required={includeSecondInvestor === 'Yes'}
                          value={client.second_investor_phone}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="select"
                          options="marital-status"
                          id="second_investor_marital_status"
                          label="Marital Status"
                          required={includeSecondInvestor === 'Yes'}
                          value={client.second_investor_marital_status}
                          handleInputChange={this.handleInputChange}
                        />

                        {client.second_investor_has_same_address !== 'Yes' && (
                          <AddressFields
                            labelFor="client-second-investor-address"
                            label="Legal Address"
                            required={includeSecondInvestor === 'Yes'}
                            values={{
                              street: {
                                id: 'second_investor_address_street',
                                value: client.second_investor_address_street,
                              },
                              city: {
                                id: 'second_investor_address_city',
                                value: client.second_investor_address_city,
                              },
                              state: {
                                id: 'second_investor_address_state',
                                value: client.second_investor_address_state,
                              },
                              zip: {
                                id: 'second_investor_address_zip',
                                value: client.second_investor_address_zip,
                              },
                            }}
                            handleInputChange={this.handleInputChange}
                          />
                        )}

                        {client.second_investor_has_same_mailing_address !==
                          'Yes' && (
                          <AddressFields
                            labelFor="client-second-investor-mailing-address"
                            label="Mailing Address"
                            required={includeSecondInvestor === 'Yes'}
                            values={{
                              street: {
                                id: 'second_investor_address_mailing_street',
                                value:
                                  client.second_investor_address_mailing_street,
                              },
                              city: {
                                id: 'second_investor_address_mailing_city',
                                value:
                                  client.second_investor_address_mailing_city,
                              },
                              state: {
                                id: 'second_investor_address_mailing_state',
                                value:
                                  client.second_investor_address_mailing_state,
                              },
                              zip: {
                                id: 'address_mailing_zip',
                                value:
                                  client.second_investor_address_mailing_zip,
                              },
                            }}
                            handleInputChange={this.handleInputChange}
                          />
                        )}
                      </Fragment>
                    )}

                    {(firmID === firmIDs['jalinski'] ||
                      firmID === firmIDs['mtg']) && (
                      <Fragment>
                        <Field
                          type="select"
                          options="yes-no"
                          id="conflict_public"
                          label="Are you or an immediate family member an officer or director of a public company?"
                          value={client.conflict_public}
                          required={true}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="select"
                          options="yes-no"
                          id="conflict_finra"
                          label="Are you or an immediate family member employed by a FINRA firm /Broker Dealer?"
                          value={client.conflict_finra}
                          required={true}
                          handleInputChange={this.handleInputChange}
                        />
                        {client.conflict_finra === 'Yes' && (
                          <Field
                            type="textarea"
                            id="conflict_finra_details"
                            label="Indicate relationship, firm and occupation"
                            value={client.conflict_finra_details}
                            required={true}
                            handleInputChange={this.handleInputChange}
                          />
                        )}

                        <h3>Dependents</h3>
                        <Field
                          type="number"
                          id="dependents_count"
                          label="Number of Dependents"
                          value={client.dependents_count}
                          required={true}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="text"
                          id="dependents_ages"
                          label="Dependents' Ages"
                          value={client.dependents_ages}
                          required={
                            client.dependents_count &&
                            client.dependents_count > 0
                              ? true
                              : false
                          }
                          handleInputChange={this.handleInputChange}
                        />

                        <h3>Trusted Contacts</h3>
                        <Field
                          type="text-multiple"
                          id="authorized_contacts"
                          label="Trusted Contacts"
                          value={client.authorized_contacts}
                          handleInputChange={this.handleTextMultipleChange}
                          showLabel={true}
                          listClassName="authorized-contacts"
                          textMultipleFields={[
                            {
                              id: 'name',
                              label: 'Name',
                              type: 'text',
                              required: true,
                            },
                            {
                              id: 'address',
                              label: 'Address',
                              type: 'text',
                              required: true,
                            },
                            {
                              id: 'phone',
                              label: 'Phone',
                              type: 'phone',
                              required: true,
                            },
                            {
                              id: 'email',
                              label: 'Email',
                              type: 'email',
                              required: true,
                            },
                          ]}
                        />

                        <h3>Other Professionals</h3>

                        <h4>Attorney</h4>
                        <Field
                          type="combo"
                          id="attorney_name"
                          label="Attorney Name"
                          value={client.attorney_name}
                          options={['NONE']}
                          required={true}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="email"
                          id="attorney_email"
                          label="Attorney Email"
                          value={client.attorney_email}
                          required={
                            firmID !== firmIDs['jalinski'] &&
                            client.attorney_name &&
                            !client.attorney_phone
                              ? true
                              : false
                          }
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="phone"
                          id="attorney_phone"
                          label="Attorney Phone"
                          value={client.attorney_phone}
                          required={
                            firmID !== firmIDs['jalinski'] &&
                            client.attorney_name &&
                            !client.attorney_email
                              ? true
                              : false
                          }
                          handleInputChange={this.handleInputChange}
                        />

                        <h4>CPA</h4>
                        <Field
                          type="combo"
                          id="cpa_name"
                          label="CPA Name"
                          value={client.cpa_name}
                          options={['NONE']}
                          required={true}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="email"
                          id="cpa_email"
                          label="CPA Email"
                          value={client.cpa_email}
                          required={
                            firmID !== firmIDs['jalinski'] &&
                            client.cpa_name &&
                            !client.cpa_phone
                              ? true
                              : false
                          }
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="phone"
                          id="cpa_phone"
                          label="CPA Phone"
                          value={client.cpa_phone}
                          required={
                            firmID !== firmIDs['jalinski'] &&
                            client.cpa_name &&
                            !client.cpa_email
                              ? true
                              : false
                          }
                          handleInputChange={this.handleInputChange}
                        />

                        <h4>Executor</h4>
                        <Field
                          type="combo"
                          id="executor_name"
                          label="Executor Name"
                          value={client.executor_name}
                          options={['NONE']}
                          required={true}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="email"
                          id="executor_email"
                          label="Executor Email"
                          value={client.executor_email}
                          required={
                            firmID !== firmIDs['jalinski'] &&
                            client.executor_name &&
                            !client.executor_phone
                              ? true
                              : false
                          }
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="phone"
                          id="executor_phone"
                          label="Executor Phone"
                          value={client.executor_phone}
                          required={
                            firmID !== firmIDs['jalinski'] &&
                            client.executor_name &&
                            !client.executor_email
                              ? true
                              : false
                          }
                          handleInputChange={this.handleInputChange}
                        />
                      </Fragment>
                    )}
                  </Collapse>

                  <button
                    className={
                      this.state.accordion_financial
                        ? 'accordion-toggle active'
                        : 'accordion-toggle'
                    }
                    name="accordion_financial"
                    onClick={this.toggleAccordion}
                  >
                    Financial Information
                  </button>
                  <Collapse isOpened={this.state.accordion_financial}>
                    <h3>Assets & Income</h3>
                    <div className="financial-group">
                      <Field
                        type="select"
                        id="income_range"
                        options={
                          firmID === firmIDs['centennial'] ||
                          firmID === firmIDs['mtg']
                            ? 'money-250-500'
                            : 'money-20-50'
                        }
                        label="Income Range"
                        value={client.income_range}
                        required={true}
                        handleInputChange={this.handleInputChange}
                      />

                      {(firmID === firmIDs['jalinski'] ||
                        firmID === firmIDs['mtg']) && (
                        <Fragment>
                          <Field
                            type="money"
                            id="annual_income"
                            label="Current Gross Annual Income"
                            value={client.annual_income}
                            required={true}
                            handleInputChange={this.handleInputChange}
                          />
                          {/* <Field type='money' id='current_investment_assets_managed' label={`Current Investment Assets that will be managed by ${firm.legal_name}`} showLabel={true} value={client.current_investment_assets_managed} required={true} handleInputChange={this.handleInputChange} /> */}
                        </Fragment>
                      )}

                      <Field
                        type="select"
                        id="net_worth"
                        options={
                          firmID === firmIDs['centennial'] ||
                          firmID === firmIDs['mtg']
                            ? 'money-250-500'
                            : 'money-20-50'
                        }
                        label="Net Worth (excl. residence)"
                        value={client.net_worth}
                        required={true}
                        handleInputChange={this.handleInputChange}
                      />
                      <Field
                        type="select"
                        id="net_worth_liquid"
                        options={
                          firmID === firmIDs['centennial'] ||
                          firmID === firmIDs['mtg']
                            ? 'money-250-500'
                            : 'money-20-50'
                        }
                        label="Liquid Net Worth"
                        value={client.net_worth_liquid}
                        required={true}
                        handleInputChange={this.handleInputChange}
                      />
                      <Field
                        type="select"
                        id="tax_bracket"
                        options="tax-bracket-range"
                        label="Federal Income Tax Rate"
                        value={client.tax_bracket}
                        required={firmID === firmIDs['jalinski'] ? false : true}
                        handleInputChange={this.handleInputChange}
                      />
                      <Field
                        type="select"
                        id="investable_assets"
                        options={
                          firmID === firmIDs['centennial'] ||
                          firmID === firmIDs['mtg']
                            ? 'money-250-500'
                            : 'money-20-50'
                        }
                        label="Investable Assets"
                        value={client.investable_assets}
                        required={true}
                        handleInputChange={this.handleInputChange}
                      />

                      {firmID !== firmIDs['jalinski'] && (
                        <>
                          <Field
                            type="select"
                            id="portfolio_value"
                            options={
                              portfolio_value_options &&
                              portfolio_value_options.map((annualFee) => {
                                var range =
                                  annualFee.portfolio_min +
                                  ' - ' +
                                  annualFee.portfolio_max

                                if (annualFee.portfolio_max === '+') {
                                  range = annualFee.portfolio_min + '+'
                                }

                                return range
                              })
                            }
                            label={`Value of Investments with ${firm.name}`}
                            value={client.portfolio_value}
                            required={true}
                            handleInputChange={this.handleInputChange}
                          />

                          <Collapse
                            isOpened={client.portfolio_value.length > 0}
                          >
                            <Field
                              type="money"
                              id="portfolio_value_qualified"
                              label="How much in qualified?"
                              instructional=" (IRAs, 401(k)s, etc.)"
                              placeholder="(ex. $800,000)"
                              value={client.portfolio_value_qualified}
                              required={true}
                              handleInputChange={this.handleInputChange}
                            />
                            <Field
                              type="money"
                              id="portfolio_value_non_qualified"
                              label="How much in non-qualified?"
                              instructional=" (Brokerage)"
                              placeholder="(ex. $400,000)"
                              value={client.portfolio_value_non_qualified}
                              required={true}
                              handleInputChange={this.handleInputChange}
                            />
                          </Collapse>
                        </>
                      )}

                      <Field
                        type="money"
                        id="residence_value"
                        label="Residence Value"
                        placeholder="Residence Value"
                        showLabel={false}
                        value={client.residence_value}
                        required={true}
                        handleInputChange={this.handleInputChange}
                      />

                      {(firmID === firmIDs['jalinski'] ||
                        firmID === firmIDs['mtg']) && (
                        <Fragment>
                          <Field
                            type="select"
                            options="yes-no"
                            id="depends_on_portfolio_assets"
                            label="Do you depend on the assets in your portfolio to pay for your general routine expenses?"
                            value={client.depends_on_portfolio_assets}
                            required={true}
                            handleInputChange={this.handleInputChange}
                          />
                          {client.depends_on_portfolio_assets === 'Yes' && (
                            <Field
                              type="textarea"
                              id="depends_on_portfolio_assets_details"
                              label="Please explain"
                              value={client.depends_on_portfolio_assets_details}
                              required={true}
                              handleInputChange={this.handleInputChange}
                            />
                          )}
                        </Fragment>
                      )}
                    </div>

                    <h3>Accounts</h3>
                    <div className="form-group">
                      <Field
                        type="checkbox-group"
                        id="accounts_non_retirement"
                        label="Non Retirement Accounts"
                        value={client.accounts_non_retirement}
                        handleInputChange={this.handleInputChange}
                        showLabel={true}
                        options={[
                          { title: 'Individual' },
                          { title: 'Joint' },
                          {
                            title: 'Trust',
                            description: '(Living Revocable/Irrevocable)',
                          },
                          {
                            title: 'Estate',
                            description:
                              '(need letters of Testamentary dated within last 60 days & death certificate for the decedent)',
                          },
                          {
                            title: 'UTMA/UGMA State of Establishment',
                            description: '(*notes: make this state dropdown*)',
                          },
                          {
                            title: 'C-Corp or S-Corp',
                            description: '(need Articles of Incorporation)',
                          },
                          { title: 'Sole Proprietor' },
                          {
                            title: 'Partnership/Limited Partnership',
                            description: '(need copy of Partnership Agreement)',
                          },
                          {
                            title: 'LLC',
                            description:
                              '(need copy of LLC Operating Agreement)',
                          },
                          {
                            title: 'Non-Incorporated Organization',
                            description: '(need copy of formation documents)',
                          },
                          {
                            title: 'Other',
                            instructional: '(Describe):',
                            description: 'allowInput',
                            inputId: 'accounts_non_retirement_other',
                            inputValue: client.accounts_non_retirement_other,
                          },
                        ]}
                      />

                      <Field
                        type="checkbox-group"
                        id="accounts_retirement"
                        label="Retirement Accounts"
                        value={client.accounts_retirement}
                        handleInputChange={this.handleInputChange}
                        showLabel={true}
                        options={[
                          { title: 'Traditional IRA' },
                          { title: 'Roth IRA' },
                          { title: 'Rollover IRA' },
                          { title: 'SEP IRA' },
                          { title: 'Simple IRA' },
                          { title: '401(k)' },
                          {
                            title: 'Other',
                            instructional: '(Describe):',
                            description: 'allowInput',
                            inputId: 'accounts_retirement_other',
                            inputValue: client.accounts_retirement_other,
                          },
                        ]}
                      />

                      {(client.accounts_non_retirement &&
                        client.accounts_non_retirement.length) ||
                      (client.accounts_retirement &&
                        client.accounts_retirement.length) ? (
                        <Fragment>
                          {firmID === firmIDs['jalinski'] ||
                          firmID === firmIDs['mtg'] ? (
                            <Field
                              type="text-multiple"
                              id="account_registrations"
                              label="Account Registrations"
                              value={client.account_registrations}
                              handleInputChange={this.handleTextMultipleChange}
                              showLabel={true}
                              listClassName="account-registrations"
                              textMultipleFields={[
                                {
                                  id: 'registration_name',
                                  label: 'Registration Name',
                                  type: 'text',
                                },
                                {
                                  id: 'custodian',
                                  label: 'Custodian',
                                  type: 'combo',
                                  options: 'custodian',
                                },
                                {
                                  id: 'account_type',
                                  label: 'Account Type',
                                  type: 'combo',
                                  options: 'account-type',
                                  required: true,
                                },
                                {
                                  id: 'account_number',
                                  label: 'Account Number',
                                  type: 'text',
                                  options: client.accounts_non_retirement.concat(
                                    client.accounts_retirement
                                  ),
                                },
                                {
                                  id: 'estimated_value',
                                  label: 'Estimated Account Value',
                                  type: 'money',
                                  required: true,
                                },
                                {
                                  id: 'fee_strategic',
                                  label: 'Strategic Fee',
                                  type: 'combo',
                                  options: [
                                    '1.00%',
                                    '1.25%',
                                    '1.50%',
                                    '1.75%',
                                    '2.00%',
                                  ],
                                },
                                {
                                  id: 'or',
                                  label: 'Or',
                                  type: 'text',
                                  value: 'or',
                                  readOnly: true,
                                },
                                {
                                  id: 'fee_tactical',
                                  label: 'Tactical Fee',
                                  type: 'combo',
                                  options: [
                                    '1.00%',
                                    '1.25%',
                                    '1.50%',
                                    '1.75%',
                                    '2.00%',
                                  ],
                                },
                              ]}
                            />
                          ) : (
                            <Field
                              type="text-multiple"
                              id="account_registrations"
                              label="Account Registrations"
                              value={client.account_registrations}
                              handleInputChange={this.handleTextMultipleChange}
                              showLabel={true}
                              listClassName="account-registrations"
                              textMultipleFields={[
                                {
                                  id: 'account_number',
                                  label: 'Account Number',
                                  type: 'text',
                                  options: client.accounts_non_retirement.concat(
                                    client.accounts_retirement
                                  ),
                                },
                                {
                                  id: 'registration_name',
                                  label: 'Registration Name',
                                  type: 'text',
                                },
                                {
                                  id: 'custodian',
                                  label: 'Custodian',
                                  type: 'select',
                                  options: 'custodian',
                                },
                                { id: 'notes', label: 'Notes', type: 'text' },
                              ]}
                            />
                          )}

                          <Field
                            type="text-multiple"
                            id="account_designations"
                            label="Account Designations"
                            value={client.account_designations}
                            handleInputChange={this.handleTextMultipleChange}
                            showLabel={true}
                            listClassName="account-designations"
                            textMultipleFields={[
                              {
                                id: 'account',
                                label: 'Account',
                                type: 'select',
                                options: client.accounts_non_retirement.concat(
                                  client.accounts_retirement
                                ),
                              },
                              {
                                id: 'designation',
                                label: 'Designation',
                                type: 'text',
                              },
                              {
                                id: 'fee_iar_platform',
                                label: 'IAR Fee + Platform Fee',
                                type: 'percentage',
                              },
                              {
                                id: 'fee_model_manager',
                                label: 'Model Manager Fee',
                                type: 'percentage',
                              },
                              {
                                id: 'fee_total_advisory',
                                label: 'Total Advisory Fee',
                                type: 'percentage',
                              },
                              {
                                id: 'transactional',
                                label: 'Transactional',
                                type: 'select',
                                options: 'yes-no',
                              },
                            ]}
                          />
                        </Fragment>
                      ) : (
                        ''
                      )}
                    </div>

                    <h3>Retirement</h3>
                    <div className="form-group">
                      <div className="form-group">
                        <label htmlFor="first_investor">First Investor</label>
                      </div>
                      <Field
                        type="select"
                        id="retirement_age"
                        required={true}
                        options="retirement-age"
                        label="When do you plan to retire?"
                        value={client.retirement_age}
                        handleInputChange={this.handleInputChange}
                      />

                      {client.second_investor_legal_name.length ? (
                        <Fragment>
                          <div className="form-group">
                            <label htmlFor="second_investor">
                              Second Investor
                            </label>
                          </div>
                          <Field
                            type="select"
                            id="second_investor_retirement_age"
                            required={true}
                            options="retirement-age"
                            label="When do you plan to retire?"
                            value={client.second_investor_retirement_age}
                            handleInputChange={this.handleInputChange}
                          />
                        </Fragment>
                      ) : (
                        ''
                      )}

                      <Field
                        type="select"
                        id="investment_objectives"
                        required={true}
                        options={
                          firmID === firmIDs['jalinski'] ||
                          firmID === firmIDs['mtg']
                            ? [
                                'Preservation of capital and focus on current income',
                                'A balance between capital appreciation and current income',
                                'Capital appreciation with little or no need for current income',
                              ]
                            : 'investment-objective'
                        }
                        label="Primary Investment Objective?"
                        value={client.investment_objectives}
                        handleInputChange={this.handleInputChange}
                      />

                      {(firmID === firmIDs['jalinski'] ||
                        firmID === firmIDs['mtg']) && (
                        <Fragment>
                          <Field
                            type="textarea"
                            id="investment_objective_details"
                            label="Further Explanation"
                            value={client.investment_objective_details}
                            handleInputChange={this.handleInputChange}
                          />
                          <Field
                            type="select"
                            id="intended_investment_horizon"
                            required={true}
                            options={[
                              '1 to 3 years',
                              '4 to 10 years',
                              '11 to 20 years',
                              '20+ years',
                            ]}
                            label="Intended Investment Horizon"
                            value={client.intended_investment_horizon}
                            handleInputChange={this.handleInputChange}
                          />
                        </Fragment>
                      )}

                      {firmID !== firmIDs['centennial'] &&
                        firmID !== firmIDs['jalinski'] && (
                          <Fragment>
                            <Field
                              type="money"
                              id="retirement_needs"
                              label="What did you and your advisor discuss you needed in retirement?"
                              placeholder="(ex. $2,000,000)"
                              value={client.retirement_needs}
                              required={true}
                              handleInputChange={this.handleInputChange}
                            />
                            <Field
                              type="money"
                              id="retirement_income"
                              label="Desired Monthly Retirement Income"
                              value={client.retirement_income}
                              required={true}
                              handleInputChange={this.handleInputChange}
                            />
                          </Fragment>
                        )}
                    </div>

                    <h3>Experience</h3>
                    <Field
                      type="select"
                      id="investment_experience_general"
                      required={true}
                      options="experience"
                      label="General Investment Experience"
                      value={client.investment_experience_general}
                      handleInputChange={this.handleInputChange}
                    />
                    <InvestmentExperienceFields
                      client={client}
                      state={this}
                      location="iac"
                    />

                    {/* // TODO: [BTS-336] Add advisor selection on earlier step? */}
                    <h3>Advisor</h3>
                    <Field
                      type="select"
                      id="advisor"
                      required={true}
                      options={Object.keys(advisorsObject).map((advisor) => {
                        const advisorObject = {
                          value: advisor,
                          label: advisorsObject[advisor].name,
                        }
                        return advisorObject
                      })}
                      label="Who are you working with?"
                      value={client.advisor}
                      handleInputChange={this.handleInputChange}
                    />

                    <h3>Fee Schedule</h3>
                    {firmID === firmIDs['jalinski'] ? (
                      <>
                        <Field
                          type="combo"
                          options={[
                            '1.00%',
                            '1.25%',
                            '1.50%',
                            '1.75%',
                            '2.00%',
                          ]}
                          id="fee_strategic"
                          label="Strategic Fee"
                          value={client.fee_strategic}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="combo"
                          options={[
                            '1.00%',
                            '1.25%',
                            '1.50%',
                            '1.75%',
                            '2.00%',
                          ]}
                          id="fee_tactical"
                          label="Tactical Fee"
                          value={client.fee_tactical}
                          handleInputChange={this.handleInputChange}
                        />
                      </>
                    ) : (
                      <>
                        <Field
                          type="select"
                          options="fee-type"
                          required={true}
                          id="fee_type"
                          label="Fee Type"
                          value={client.fee_type}
                          handleInputChange={this.handleInputChange}
                        />
                        {client.fee_type === 'Percentage Fee' && (
                          <FeeSchedule
                            rows={firm.annual_fees}
                            editable={true}
                            handleInputChange={this.handleInputChange}
                          />
                        )}
                        {client.fee_type === 'Fixed Fee' && (
                          <Field
                            type="percentage"
                            placeholder="(ex. 1.5%)"
                            id="fee_fixed"
                            required={true}
                            label="Fixed Fee Rate"
                            value={client.fee_fixed}
                            handleInputChange={this.handleInputChange}
                          />
                        )}
                      </>
                    )}

                    <h3>Third Party Fees</h3>
                    <Field
                      type="text-multiple"
                      id="third_party_fees"
                      label="Third Party Fees"
                      value={client.third_party_fees}
                      handleInputChange={this.handleTextMultipleChange}
                      listClassName="third-party-fees"
                      textMultipleFields={[
                        { id: 'name', label: 'Name', type: 'text' },
                        { id: 'fee', label: 'Fee', type: 'percentage' },
                      ]}
                    />

                    <h3>Account Management</h3>
                    <Field
                      type="select"
                      options="jurisdiction"
                      required={true}
                      id="jurisdiction"
                      label="Who's managing the account?"
                      value={client.jurisdiction}
                      handleInputChange={this.handleInputChange}
                    />

                    {client.jurisdiction === 'Non-Discretionary' ? (
                      <div className="custodian-group">
                        <h3>Custodian</h3>
                        <Field
                          type="select"
                          options={Object.keys(custodian_options).map(
                            (custodian) => {
                              const custodianObject = {
                                value: custodian,
                                label:
                                  custodian_options[custodian].preferred_name,
                              }
                              return custodianObject
                            }
                          )}
                          required={true}
                          id="custodian"
                          label="Who do you use?"
                          value={client.custodian.id}
                          handleInputChange={this.handleInputChange}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    <h3>Other</h3>
                    <Field
                      type="textarea"
                      id="other_information"
                      label="Other Relevant Information Regarding the Needs and Circumstances of the Account Owner(s)"
                      value={client.other_information}
                      handleInputChange={this.handleInputChange}
                    />

                    {(firmID === firmIDs['jalinski'] ||
                      firmID === firmIDs['mtg']) && (
                      <Fragment>
                        <Field
                          type="textarea"
                          id="meeting_notes"
                          label="Meeting Notes"
                          value={client.meeting_notes}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="textarea"
                          id="investment_restrictions_other_important_information"
                          label="Investment Restrictions / Other Important Information"
                          value={
                            client.investment_restrictions_other_important_information
                          }
                          handleInputChange={this.handleInputChange}
                        />
                      </Fragment>
                    )}
                  </Collapse>

                  <button
                    className={
                      this.state.accordion_investment_profile
                        ? 'accordion-toggle active'
                        : 'accordion-toggle'
                    }
                    name="accordion_investment_profile"
                    onClick={this.toggleAccordion}
                  >
                    Investor Profile
                  </button>
                  <Collapse
                    isOpened={this.state.accordion_investment_profile}
                    hasNestedCollapse={true}
                  >
                    {(firmID === firmIDs['jalinski'] ||
                      firmID === firmIDs['mtg']) && (
                      <Fragment>
                        <Field
                          type="textarea"
                          id="notes_strategic"
                          label="Strategic Notes"
                          value={client.notes_strategic}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="textarea"
                          id="notes_tactical"
                          label="Tactical Strategy Notes"
                          value={client.notes_tactical}
                          handleInputChange={this.handleInputChange}
                        />
                        <Field
                          type="select"
                          options={[
                            'None',
                            'Tactical–Moderate',
                            'Tactical-Moderate-Aggressive',
                            'Tactical-Aggressive',
                          ]}
                          required={true}
                          id="tactical_strategy"
                          label="Tactical Strategy"
                          value={client.tactical_strategy}
                          handleInputChange={this.handleInputChange}
                        />
                      </Fragment>
                    )}

                    <div className="investor-profile">
                      <h3>What is your investor profile?</h3>

                      {firmID === firmIDs['jalinski'] ||
                      firmID === firmIDs['mtg'] ? (
                        <Fragment>
                          <small className="instructional">
                            (Conservative, Moderate-Conservative, Moderate,
                            Moderate-Aggressive, or Aggressive)
                          </small>
                          <Field
                            type="select"
                            options={
                              client.tactical_strategy &&
                              client.tactical_strategy !== 'None'
                                ? [
                                    'Moderate',
                                    'Moderate-Aggressive',
                                    'Aggressive',
                                  ]
                                : [
                                    'Conservative',
                                    'Moderate-Conservative',
                                    'Moderate',
                                    'Moderate-Aggressive',
                                    'Aggressive',
                                  ]
                            }
                            required={true}
                            id="risk_tolerance"
                            label="What is your investment Risk Tolerance for your accounts managed with WQL?"
                            value={client.risk_tolerance}
                            handleInputChange={this.handleInputChange}
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <small className="instructional">
                            (Conservative, Moderate, Aggressive, or Speculative)
                          </small>

                          {!this.state.accordion_investment_profile_know ? (
                            <button
                              className={
                                this.state.accordion_investment_profile_quiz
                                  ? 'accordion-toggle active'
                                  : 'accordion-toggle'
                              }
                              name="accordion_investment_profile_quiz"
                              onClick={this.toggleAccordion}
                            >
                              I'd like to take a quiz
                            </button>
                          ) : (
                            ''
                          )}
                          <Collapse
                            isOpened={
                              this.state.accordion_investment_profile_quiz
                            }
                          >
                            <div className="form-group">
                              <div className="form-group">
                                <label htmlFor="investment_profiles">
                                  Investment Profile
                                </label>
                              </div>

                              <ul className="investment-profiles">
                                {this.investment_profile_quiz_fields}
                              </ul>

                              <div className="add-button">
                                <div className="add-quiz-question">
                                  Would you like to add a risk profile for a
                                  different account?
                                </div>
                                <div className="add-quiz-example">
                                  For example: Would you like to be conservative
                                  for college savings but aggressive for
                                  retirement? If so, please "Add Quiz" below!
                                </div>
                                <button
                                  className="add-investment-profile-line"
                                  onClick={this.handleAddInvestmentProfileLine}
                                >
                                  Add Quiz
                                </button>
                              </div>
                            </div>
                          </Collapse>

                          {!this.state.accordion_investment_profile_quiz ? (
                            <button
                              className={
                                this.state.accordion_investment_profile_know
                                  ? 'accordion-toggle active'
                                  : 'accordion-toggle'
                              }
                              name="accordion_investment_profile_know"
                              onClick={this.toggleAccordion}
                            >
                              I already know what I am
                            </button>
                          ) : (
                            ''
                          )}
                          <Collapse
                            isOpened={
                              this.state.accordion_investment_profile_know
                            }
                          >
                            <InvestmentProfileFields
                              client={client}
                              state={this}
                            />
                          </Collapse>
                        </Fragment>
                      )}
                    </div>

                    {(firmID === firmIDs['jalinski'] ||
                      firmID === firmIDs['mtg']) && (
                      <Fragment>
                        <Field
                          type="select"
                          options="yes-no"
                          required={true}
                          id="can_withstand_market_declines"
                          label="Can you withstand short-term (1-3 years) declines of 10% or more in your Investment Assets in order to achieve your long-term investment objective?"
                          placeholder="Can you withstand market declines of 10% or more?"
                          showLabel={true}
                          value={client.can_withstand_market_declines}
                          handleInputChange={this.handleInputChange}
                        />
                        {client.can_withstand_market_declines === 'No' && (
                          <Field
                            type="textarea"
                            id="can_withstand_market_declines_details"
                            label="Further Explanation"
                            required={true}
                            value={client.can_withstand_market_declines_details}
                            handleInputChange={this.handleInputChange}
                          />
                        )}
                      </Fragment>
                    )}
                  </Collapse>

                  <button
                    className={
                      this.state.accordion_disclosures
                        ? 'accordion-toggle active'
                        : 'accordion-toggle'
                    }
                    name="accordion_disclosures"
                    onClick={this.toggleAccordion}
                  >
                    Disclosures
                  </button>
                  <Collapse isOpened={this.state.accordion_disclosures}>
                    {firmDisclosures && Object.keys(firmDisclosures).length ? (
                      <ul className="disclosure-documents">
                        {this.firm_disclosure_documents_list_items}
                        {this.advisor_disclosure_documents_list_items}
                      </ul>
                    ) : (
                      <small className="no-clients">
                        No disclosures uploaded!
                      </small>
                    )}

                    <div className="form-inline">
                      <div className="form-group disclosures-accept">
                        <input
                          value={this.state.accept_disclosures}
                          name="accept_disclosures"
                          id="accept_disclosures"
                          type="checkbox"
                          onChange={this.handleInputChange}
                        />
                        <label htmlFor="accept_disclosures">
                          <small>
                            I agree that I have read and received these
                            disclosures.
                          </small>
                        </label>
                      </div>
                    </div>
                  </Collapse>
                </div>

                <div className="form-group submit-section">
                  <ErrorsList errors={errors} sticky={true} />

                  <div className="instructional info-correct">
                    <small>
                      I agree all of the information is correct to the best of
                      my knowledge.
                    </small>
                  </div>
                  <button
                    className="continue"
                    onClick={this.attemptContinue}
                    type="button"
                  >
                    Continue
                  </button>
                  <button
                    className="continue"
                    onClick={this.handleSave}
                    type="button"
                  >
                    Save only
                  </button>
                  {this.state.saveOnlySuccess ? (
                    <div className="save-only-succses">
                      Client saved! You may now close this window.
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </form>
          ) : (
            'There was an error. Please contact Bitsy. [RC-NUID]'
          )}
        </TabPanel>
        <TabPanel>{children}</TabPanel>
      </Tabs>

      //         : ''
      //       }

      //     </div>

      //     {/* {location !== 'dashboard' ?
      //       <BitsyRegisterFooter />
      //       : ''} */}

      //   </div>
      // </div>
    )
  }
}

export default IACForm
