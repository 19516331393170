import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

class RedtailDeauthenticationForm extends Component {
  render() {
    const { handleDeauth } = this.props

    return (
      <DeAuthForm>
        <p>You are authenticated with Redtail! We'll take it from here.</p>

        <Button
          content="Remove integration"
          negative
          onClick={handleDeauth}
          className="deauthenticate"
          size="big"
        />
      </DeAuthForm>
    )
  }
}

export default RedtailDeauthenticationForm

const DeAuthForm = styled.div`
  text-align: center;

  button {
    margin-top: 30px;
    margin-bottom: 1rem;
  }
`
