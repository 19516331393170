import { functionsBase } from 'config/firebaseConfig'

export function emailPMQPending(
  inviterID,
  inviterName,
  inviterEmail,
  firmName,
  clientEmail,
  state
) {
  const functionLink = `${functionsBase}/emailSendPremeetingQuestionnaireInvite`

  fetch(functionLink, {
    method: 'POST',
    body: JSON.stringify({
      inviterID: inviterID,
      inviterName: inviterName,
      inviterEmail: inviterEmail,
      firmName: firmName,
      clientEmail: clientEmail,
    }),
  })
    .then((res) => res.text()) // convert to plain text
    .then((text) => {
      // Handle addSuccess
      if (text === 'Sent') {
        state.setState({
          errors: [],
          addSuccess: true,
        })
      } else {
        state.setState({
          errors: [text],
        })
      }

      return text
    })
}
