import React, { memo, useState } from 'react'
import { Form, Checkbox } from 'semantic-ui-react'
import { useUser } from 'contexts'
import { updateItemInfo } from 'actions/db/dbActions'

const defaultEmailNotificationSettings = {
  'envelope-signed': true,
  'envelope-completed': true,
  'envelope-declined': true,
}

const EmailSettings = memo(() => {
  const { userType, uid, user } = useUser()

  const initialEmailNotificationSettings =
    user && user.emailNotificationSettings
      ? Object.assign(
          {},
          defaultEmailNotificationSettings,
          user.emailNotificationSettings
        )
      : defaultEmailNotificationSettings

  const [emailNotificationSettings, setEmailNotificationSettings] = useState(
    initialEmailNotificationSettings
  )

  const handleToggle = async (notificationType) => {
    const newSettings = {
      ...emailNotificationSettings,
      [notificationType]: !emailNotificationSettings[notificationType],
    }

    await updateItemInfo(
      userType,
      uid,
      'emailNotificationSettings',
      newSettings
    )

    setEmailNotificationSettings(newSettings)
  }

  return (
    <Form>
      {Object.keys(emailNotificationSettings).map((notificationType) => {
        let title = 'Email me when '
        switch (notificationType) {
          case 'envelope-completed':
            title += 'a document is completed'
            break
          case 'envelope-declined':
            title += 'a document is declined'
            break
          case 'envelope-signed':
            title += 'someone signs a document'
            break
          default:
            break
        }

        return (
          <Form.Field key={notificationType}>
            <Checkbox
              toggle
              checked={emailNotificationSettings[notificationType]}
              label={title}
              onChange={() => handleToggle(notificationType)}
            />
          </Form.Field>
        )
      })}
    </Form>
  )
})

export default EmailSettings
