import { firebaseFunctions } from '../../../config/firebaseConfig'

const integrationsExportEnvelopesFunction = firebaseFunctions.httpsCallable(
  'integrationsExportEnvelopes'
)

export default async function integrationsExportEnvelopes({
  firmID,
  advisorID = null,
  envelopesToExport,
  setExporting,
  setExportSuccess,
  setErrors,
}) {
  setExporting(true)

  try {
    const result = await integrationsExportEnvelopesFunction({
      firm: firmID,
      advisor: advisorID,
      envelopesToExport,
    })

    let exportID
    if (result && result.data) {
      exportID = result.data
      console.log(`Export file was created successfully with ID ${exportID}!`)
    }

    if (exportID && setExportSuccess) {
      setExportSuccess(exportID)
      setExporting(false)
    }

    return exportID
  } catch (err) {
    console.error(err)
    if (setErrors) {
      setErrors([err.message])
    }
    setExporting(false)
  }

  return null
}
