import React, { memo, useState, useEffect } from 'react'
import _ from 'lodash'
import { List } from 'semantic-ui-react'
import { generateDisclosuresArray } from 'helpers/disclosures'
import styled from 'styled-components'

const DisclosuresList = memo(
  ({
    disclosures: passedDisclosures = [],
    firmDisclosures,
    advisorDisclosures,
    hideIfEmpty = false,
  }) => {
    const [disclosures, setDisclosures] = useState([])

    useEffect(() => {
      if (passedDisclosures && passedDisclosures.length) {
        if (!_.isEqual(passedDisclosures, disclosures)) {
          setDisclosures(passedDisclosures)
        }
      } else if (
        (!passedDisclosures || !passedDisclosures.length) &&
        (firmDisclosures || advisorDisclosures)
      ) {
        const newDisclosures = generateDisclosuresArray({
          firmDisclosures,
          advisorDisclosures,
        })

        if (!_.isEqual(newDisclosures, disclosures)) {
          setDisclosures(newDisclosures)
        }
      }
    }, [disclosures, passedDisclosures, firmDisclosures, advisorDisclosures])

    if (hideIfEmpty && (!disclosures || !disclosures.length)) {
      return ''
    }

    return (
      <>
        {disclosures && disclosures.length ? (
          <List data-id="disclosures-list">
            {disclosures.map((disclosure) => (
              <List.Item
                key={disclosure.documentID}
                as="a"
                href={disclosure.document.link}
                title="Download"
                target="_blank"
                rel="noopener noreferrer"
              >
                <List.Icon name="download" />
                <ListContent>
                  <List.Header>{disclosure.document.name}</List.Header>
                </ListContent>
              </List.Item>
            ))}
          </List>
        ) : (
          <p className="text-center">
            <small>No disclosures uploaded!</small>
          </p>
        )}
      </>
    )
  }
)

export default DisclosuresList

const ListContent = styled(List.Content)`
  &&& {
    max-height: none !important;
    overflow-x: visible !important;

    .header {
      font-size: 120%;
      line-height: 120%;
    }
  }
`
