import { functionsBase } from '../../config/firebaseConfig'

export async function emailDocument(
  type,
  firmID = null,
  clientName,
  clientEmail,
  advisorName,
  advisorEmail,
  firmName,
  firmEmail,
  emailBody,
  pdf,
  firmDisclosures,
  advisorDisclosures,
  secondInvestorName = null,
  secondInvestorEmail = null
) {
  var functionLink = functionsBase
  if (type === 'iac') {
    functionLink += '/emailSendIac'
  } else if (type === 'suitability-form') {
    functionLink += '/emailSendSuitabilityForm'
  }

  if (functionLink !== functionsBase) {
    fetch(functionLink, {
      method: 'POST',
      body: JSON.stringify({
        clientName: clientName,
        clientEmail: clientEmail,
        advisorName: advisorName,
        advisorEmail: advisorEmail,
        firmName: firmName,
        firmEmail: firmEmail,
        emailBody: emailBody,
        pdf: pdf,
        firmDisclosures: firmDisclosures,
        advisorDisclosures: advisorDisclosures,
        firmID: firmID,
        secondInvestorName: secondInvestorName,
        secondInvestorEmail: secondInvestorEmail,
      }),
    }).then((res) => res.text()) // convert to plain text
    // .then(text => console.log(text))
    // const data = await res.json()
    // data.body = JSON.parse(data.body)

    // console.log(data.body)

    // return data
  } else {
    return null
  }
}

export function emailDocumentPending(
  type,
  firmID = null,
  clientName,
  clientEmail,
  advisorName,
  advisorEmail,
  firmName,
  firmEmail,
  firmDisclosures,
  advisorDisclosures
) {
  var functionLink = functionsBase
  if (type === 'iac') {
    functionLink += '/emailSendIacPending'
  } else if (type === 'suitability-form') {
    functionLink += '/emailSendSuitabilityFormPending'
  }

  if (functionLink !== functionsBase) {
    fetch(functionLink, {
      method: 'POST',
      body: JSON.stringify({
        clientName: clientName,
        clientEmail: clientEmail,
        advisorName: advisorName,
        advisorEmail: advisorEmail,
        firmName: firmName,
        firmEmail: firmEmail,
        firmDisclosures: firmDisclosures,
        advisorDisclosures: advisorDisclosures,
        firmID: firmID,
      }),
    }).then((res) => res.text()) // convert to plain text
    // .then(text => console.log(text))
  } else {
    return null
  }
}
