import React, { memo } from 'react'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { LoaderIcon } from 'components/icons'
import { SmallHeading } from 'styles/_mixins'

const Loader = memo(({ message = null, fullScreen = false, children = '' }) => {
  if (fullScreen) {
    return (
      <StyledModal open={true} dimmer="blurring">
        <ModalContainer>
          {children}
          <BitsyLoader src="/images/loader-bitsy.gif" />
          {message && <ModalMessage>{message}</ModalMessage>}
        </ModalContainer>
      </StyledModal>
    )
  }

  return (
    <LoaderContainer>
      <LoaderIcon />
      {message && <Message>{message}</Message>}
    </LoaderContainer>
  )
})

export default Loader

const LoaderContainer = styled.div`
  text-align: center;
  margin: 30px 0;

  svg {
    max-width: 40px;
    display: block;
    margin: 10px auto;
  }
`

const Message = styled.div`
  ${SmallHeading()};
`

const StyledModal = styled(Modal)`
  &&& {
    height: calc(100vh - 2em);
    width: calc(100vw - 2em);
    background: transparent;
    box-shadow: none;
    margin: 0 !important;
  }
`

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const BitsyLoader = styled.img`
  max-width: 180px;
  margin: 15px 0;
`

const ModalMessage = styled(Message)`
  margin: 15px 0;
  transition: all 1s ease-in-out;
  transition-delay: 2s;
  animation: blink normal 7s infinite ease-in-out;

  @keyframes blink {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
`
