const months = {
  short: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  long: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
}

export const formatFirestoreDateString = (
  date,
  format = { month: 'short', day: '2-digit', year: 'numeric' }
) => {
  if (date.constructor.name === 't') {
    return date.toDate().toLocaleString('default', format)
  }

  if (date.constructor.name === 'Object' && date.seconds) {
    const d = new Date(1970, 0, 1)
    d.setSeconds(date.seconds)

    let dateString = ''

    if (format.month) {
      dateString += months[format.month][d.getMonth()]
    }

    if (format.day) {
      dateString += ` ${d.getDate()}`
    }

    if (format.year) {
      if (format.month && format.day) {
        dateString += ', '
      }
      dateString += d.getFullYear()
    }

    return dateString.trim()
  }

  return new Date(date.seconds * 1000).toLocaleDateString('en-US', format)
}
