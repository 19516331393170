import React, { Component } from 'react'
// import { mergeDeepWith, mergeDeepRight, propOr } from 'ramda'
import { validEmailRegex } from '../../../config/regexPatterns'
import { addAccount } from '../../../actions/authActions'
import { getCPS } from '../../../actions/dbActions'
import { addProspectToDB } from '../../../actions/db/prospectActions'
import { emailPMQPending } from '../../../actions/emailActions'
import Error from '../../partials/Error'
import ErrorsList from '../../partials/ErrorsList'
import Loader from '../../partials/Loader'
import { generateRandomString } from '../../../actions/helpers'
import { getContact as getWealthboxContact } from '../../../actions/integrations/wealthbox/getContact'
// import { firebaseDB } from '../../../config/firebaseConfig'
import { getClient as getPractifiClient } from '../../../actions/integrations/practifi/getClient'
import { getClientFromIntegrations } from '../../../actions/integrations'
import sendDocuments from 'actions/email/sendDocuments'
import { getEnv } from 'helpers/_utils'

// TODO: [BTS-333] Replace Wealthbox getClient
// TODO: [BTS-334] Replace Practifi getClient

const addNewFirmClientsModuleInitialState = {
  client: {
    firm: '',
    advisor: '',
    email: '',
    isProspect: false,
    hasPendingPMQ: false,
  },
  password: '',
  integrationsLoading: false,
  disclosuresSending: false,
  // wealthboxLoading: false,
  errors: [],
  addSuccess: false,
  addedUID: null,
}

class AddNewFirmClientsModule extends Component {
  constructor(props) {
    super(props)
    this.state = addNewFirmClientsModuleInitialState

    this.handleAdd = this.handleAdd.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.mergeWithWealthboxContact = this.mergeWithWealthboxContact.bind(this)
    this.mergeWithPractifiClient = this.mergeWithPractifiClient.bind(this)
  }

  componentDidMount() {
    const { type, firmID, advisorID } = this.props
    const { client } = this.state

    var newClient = client

    if (type === 'Prospect') {
      newClient['isProspect'] = true
      newClient['hasPendingPMQ'] = true
    }

    if (firmID) {
      newClient['firm'] = firmID
    }

    if (advisorID) {
      newClient['advisor'] = advisorID
    }

    newClient.env = getEnv()

    this.setState({
      client: newClient,
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    const { onboardingModule } = this.props

    // Update onboarding module tab state on successful add
    if (onboardingModule) {
      // Update addSuccess and client email
      if (!prevState.addSuccess && this.state.addSuccess) {
        this.setState({
          integrationsLoading: true,
        })

        const clientFromIntegrations = await getClientFromIntegrations(
          this.state.client,
          onboardingModule.state.client
        )

        this.setState({
          integrationsLoading: false,
        })

        let clientObject = clientFromIntegrations
        if (!clientFromIntegrations) {
          // The client was not found in any integrations, continue with the current onboardingModule state
          clientObject = onboardingModule.state.client
        }

        if (this.props.sendDocumentsOnAdd) {
          this.setState({
            disclosuresSending: true,
          })

          const { firmID, firm, firmDisclosures } = this.props
          const { client } = this.state

          const forms = Object.values(firmDisclosures || {}).map((d) => ({
            name: d.name,
            link: d.link,
          }))

          await sendDocuments({
            client: {
              name: client.legal_name || client.email,
              email: client.email,
              uid: this.state.addedUID,
            },
            advisor: null,
            firm: {
              name: firm.name,
              email: firm.email,
              uid: firmID,
            },
            forms,
            subject: firm.emailSettings?.disclosures?.subject,
            body: firm.emailSettings?.disclosures?.body,
          })

          this.setState({
            disclosuresSending: false,
          })
        }

        // Update onboardingModule with the new client object
        onboardingModule.setState({
          userAddSuccess: true,
          client: {
            ...clientObject,
            email: this.state.client.email,
          },
        })

        // const [
        //   wealthboxClient,
        //   practifiClient,
        //   integrations
        // ] = await Promise.all([
        //   this.mergeWithWealthboxContact(),
        //   this.mergeWithPractifiClient(),
        //   firebaseDB
        //     .collection('integrations')
        //     .doc(this.props.firmID)
        //     .get()
        // ])

        // const prioritizeWealthboxOverSalesforce = propOr(
        //   false,
        //   'prioritizeOverSalesforce',
        //   integrations.get('wealthbox') || {}
        // )

        // client = mergeDeepWith(
        //   (wealthBoxValue, salesforceValue) => {
        //     if (prioritizeWealthboxOverSalesforce) {
        //       return wealthBoxValue || salesforceValue
        //     }

        //     return salesforceValue || wealthBoxValue
        //   },
        //   wealthboxClient,
        //   salesforceClient
        // )
        // if (practifiClient) {
        //   client = mergeDeepRight(client, practifiClient)
        // }
      }

      // Update UID
      if (!prevState.addedUID && this.state.addedUID) {
        onboardingModule.setState({
          uid: this.state.addedUID,
        })
      }
    }
  }

  handleAdd(event) {
    event.preventDefault()

    if (this.state.integrationsLoading) {
      return
    }

    const {
      type,
      firmID,
      firmName,
      advisorID,
      advisorName,
      inviterEmail,
      generatePassword,
    } = this.props

    this.setState(
      {
        errors: [],
      },
      async function () {
        // Validate
        const { client } = this.state

        if (client.firm.length < 1) {
          this.setState({
            errors: [
              'There was an error. Please contact Bitsy Advisor. [NC-NF]',
            ],
          })
        } else if (
          client.email.length < 1 ||
          !validEmailRegex.test(client.email)
        ) {
          this.setState({
            errors: ['Please make sure client has a valid email address.'],
          })
        } else if (type === 'Prospect') {
          // Add prospect
          const prospectID = await addProspectToDB(this.state.client, this)

          if (prospectID) {
            // Set up inviterID
            var inviteCode = firmID
            inviteCode += '-'
            if (advisorID) {
              inviteCode += advisorID
            }
            inviteCode += '-'
            inviteCode += prospectID

            // Set up inviterName
            var inviterName = firmName
            if (advisorName) {
              inviterName = advisorName
            }

            // Send prospect email
            emailPMQPending(
              inviteCode,
              inviterName,
              inviterEmail,
              firmName,
              this.state.client.email,
              this
            )
          }
        } else if (type === 'Client') {
          if (!generatePassword) {
            // Get CPS
            const uidCPSToGet = this.props.advisorID
              ? this.props.advisorID
              : this.props.firmID

            let password = await getCPS(uidCPSToGet, this)

            if (password) {
              // Add client to auth and database
              addAccount(
                'client',
                this.state.client.email,
                password,
                this.state.client,
                this
              )
            } else {
              this.setState({
                errors: [
                  'There was an error getting the Client Password. Please ensure you have set it under "Settings" and contact Bitsy Advisor if the problem persists. [NC-CPS]',
                ],
              })
            }
          } else {
            // Generate password
            let password = generateRandomString(12)

            // Add client to auth and database
            addAccount(
              'client',
              this.state.client.email,
              password,
              this.state.client,
              this
            )
          }
        } else {
          this.setState({
            errors: [
              'There was an error. Please contact Bitsy Advisor. [NC-NT]',
            ],
          })
        }
      }
    )
  }

  mergeWithPractifiClient() {
    return new Promise((resolve) => {
      this.setState(
        {
          practifiLoading: true,
        },
        async () => {
          try {
            const practifiData = await getPractifiClient(
              this.props.firmID,
              this.state.client.email
            )
            this.setState({
              practifiLoading: false,
            })

            if (practifiData) {
              resolve({
                ...this.props.onboardingModule.state.client,
                legal_name_first: practifiData.primaryContactDetails.firstName,
                legal_name_last: practifiData.primaryContactDetails.lastName,
                address_street: practifiData.locationAddress.street,
                address_city: practifiData.locationAddress.city,
                address_state: practifiData.locationAddress.state,
                address_zip: practifiData.locationAddress.postalCode,
                birthdate: practifiData.primaryContactDetails.birthDate,
                phone: practifiData.primaryContactDetails.homePhone,
                phone_mobile: practifiData.primaryContactDetails.mobilePhone,
              })
              return
            }
          } catch (err) {
            // ignored
          }

          // Either we couldn't find anything in salesforce, or user has no Salesforce integration,
          // or some unexpected error in the request (like a connection error)
          resolve(this.props.onboardingModule.state.client)
        }
      )
    })
  }

  async mergeWithWealthboxContact() {
    return new Promise((resolve) => {
      this.setState(
        {
          wealthboxLoading: true,
        },
        async () => {
          try {
            const contact = await getWealthboxContact(
              this.props.firmID,
              this.state.client.email
            )
            this.setState({
              wealthboxLoading: false,
            })

            if (contact) {
              const fullName = [
                contact.first_name,
                contact.middle_name,
                contact.last_name,
              ]
                // Cut off nulls or empty strings
                .filter((name) => name)
                .join(' ')

              const principalAddress =
                contact.street_addresses.find((addr) => addr.principal) ||
                contact.street_addresses[0] // Get first if no principal

              let phoneMobile = this.props.onboardingModule.state.client
                .phone_mobile
              const wealthboxPhoneMobile = contact.phone_numbers.find(
                (phone) => phone.kind === 'Mobile'
              )

              if (wealthboxPhoneMobile) {
                phoneMobile = wealthboxPhoneMobile.address
              }

              resolve({
                ...this.props.onboardingModule.state.client,
                legal_name: fullName,
                legal_name_first: contact.first_name,
                legal_name_last: contact.last_name,
                phone_mobile: phoneMobile,
                birthdate: contact.birth_date,
                ...(principalAddress
                  ? {
                      address_street: `${principalAddress.street_line_1} ${principalAddress.street_line_2}`,
                      address_city: principalAddress.city,
                      address_state: principalAddress.state,
                      address_zip: principalAddress.zip_code,
                    }
                  : {}),
              })
              return
            }
          } catch (err) {
            // ignored
          }

          // Either we couldn't find anything in wealthbox, or user has no Wealthbox integration,
          // or some unexpected error in the request (like a connection error)
          resolve(this.props.onboardingModule.state.client)
        }
      )
    })
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name
    const value = target.value

    this.setState({
      client: {
        ...this.state.client,
        [name]: value,
      },
    })
  }

  render() {
    const { firmID, advisorID, type } = this.props
    const {
      client,
      addSuccess,
      errors,
      integrationsLoading,
      disclosuresSending,
    } = this.state

    var invitee = 'Client'
    if (type) {
      invitee = type
    }

    if (addSuccess && !integrationsLoading) {
      return (
        <>
          {invitee + ' added successfully!'}
          {disclosuresSending && <Loader message="Sending disclosures..." />}
        </>
      )
    }

    this.errors = errors.map((error, idx) => {
      var errorCode = ''
      var errorSplit = error.split(' [')
      if (errorSplit && errorSplit[1]) {
        errorCode = errorSplit[1].replace(']', '')
      }

      if (errorCode === '') {
        return error
      }

      return (
        <Error
          key={idx}
          message={error}
          code={errorCode}
          level="warning"
          errorInfo={{
            Location: 'Add New Firm Clients Module',
            Details: error,
          }}
          userInfo={{ 'Firm ID': firmID, 'Advisor ID': advisorID }}
        />
      )
    })

    if (firmID && client.firm) {
      return (
        <form onSubmit={this.handleAdd}>
          <strong>Invite a new {invitee.toLowerCase()}:</strong>
          <div className="email">
            <label htmlFor="email">{invitee} Email</label>
            <input
              value={client.email}
              type="email"
              placeholder="Email"
              name="email"
              id="email"
              onChange={this.handleInputChange}
            />
          </div>

          <ErrorsList errors={this.errors} />

          <div className="save-changes">
            <button
              className="primary d-block mt-3 ml-auto add-client"
              onClick={this.handleAdd}
              disabled={integrationsLoading}
            >
              {integrationsLoading ? 'Loading...' : `Add ${invitee}`}
            </button>

            {(integrationsLoading || disclosuresSending) && (
              <Loader
                message={
                  disclosuresSending
                    ? 'Sending disclosures...'
                    : 'Checking integrated services for existing clients...'
                }
              />
            )}
          </div>
        </form>
      )
    }

    return ''
  }
}

export default AddNewFirmClientsModule
