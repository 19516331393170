import { clientDB } from '../config/firebaseConfig'

// CLIENTS

export function clientAddOnboardedClientToDB(client, state, uid) {
  client.dateAdded = new Date()

  clientDB
    .collection('clients')
    .doc(uid)
    .update(client)
    .then(function (docRef) {
      state.setState({
        addSuccess: true,
      })

      // console.log(docRef)
      //  console.log("Client added with ID: ", docRef)
    })
    .catch(function (error) {
      // console.error("Error adding client: ", error)
    })
}

export function clientAddIACToDB(file, uid, iacTitle, iacDate, allIACs) {
  var allIACsArray = []
  if (allIACs) {
    allIACsArray = allIACs
  }

  allIACsArray.push({
    link: file,
    name: iacTitle,
    date: iacDate,
  })

  clientDB
    .collection('clients')
    .doc(uid)
    .update({
      hasPendingIAC: false,
      iac: file,
      iacs: allIACsArray,
    })
    .then(function (docRef) {
      // console.log("Logo added with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding logo: ", error)
    })
}

export function getClientDoc(uid) {
  return clientDB
    .collection('clients')
    .doc(uid)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        var docData = doc.data()

        if (!docData.legal_name) {
          docData['legal_name'] =
            docData.legal_name_first + ' ' + docData.legal_name_last
        }

        return docData
      } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!")
        return null
      }
    })
    .catch(function (error) {
      console.error('Error getting clientdoc:', error)
      return null
    })
}

export function clientUpdateClient(client, state, uid) {
  client.dateUpdated = new Date()

  clientDB
    .collection('clients')
    .doc(uid)
    .update(client)
    .then(function (docRef) {
      state.setState({
        addSuccess: true,
      })
      // console.log("Client updated with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding client: ", error)
    })
}

// ADVISORS

let unsubscribeFirmAdvisors
export function getFirmAdvisors(firm, state) {
  unsubscribeFirmAdvisors = clientDB
    .collection('advisors')
    .where('firm', '==', firm)
    .onSnapshot(function (querySnapshot) {
      var advisors = {}
      querySnapshot.forEach(function (doc) {
        advisors[doc.id] = doc.data()
      })

      state.setState({
        ...state.state,
        advisors: advisors,
      })
    })
}
export function stopListeningToFirmAdvisors() {
  // Stop listening to changes
  unsubscribeFirmAdvisors()
}

let unsubscribeAdvisorDetails
export function getAdvisorDetails(advisor, state) {
  unsubscribeAdvisorDetails = clientDB
    .collection('advisors')
    .doc(advisor)
    .onSnapshot(function (doc) {
      state.setState({
        ...state.state,
        advisor: doc.data(),
      })
    })
}
export function stopListeningToAdvisorDetails() {
  // Stop listening to changes
  unsubscribeAdvisorDetails()
}

let unsubscribeAdvisorDisclosures
export function getAdvisorDisclosures(advisor, state) {
  unsubscribeAdvisorDisclosures = clientDB
    .collection('advisors')
    .doc(advisor)
    .collection('disclosures')
    .onSnapshot(function (querySnapshot) {
      var disclosures = {}
      querySnapshot.forEach(function (doc) {
        disclosures[doc.id] = doc.data()
      })

      state.setState({
        advisorDisclosures: disclosures,
      })
    })
}
export function stopListeningToAdvisorDisclosures() {
  // Stop listening to changes
  unsubscribeAdvisorDisclosures()
}

// FIRMS

let unsubscribeFirmDetails
export function getFirmDetails(firm, state) {
  unsubscribeFirmDetails = clientDB
    .collection('firms')
    .doc(firm)
    .onSnapshot(function (doc) {
      state.setState({
        ...state.state,
        firm: doc.data(),
      })
    })
}
export function stopListeningToFirmDetails() {
  // Stop listening to changes
  unsubscribeFirmDetails()
}

let unsubscribeFirmDisclosures
export function getFirmDisclosures(firm, state) {
  unsubscribeFirmDisclosures = clientDB
    .collection('firms')
    .doc(firm)
    .collection('disclosures')
    .onSnapshot(function (querySnapshot) {
      var disclosures = {}
      querySnapshot.forEach(function (doc) {
        disclosures[doc.id] = doc.data()
      })

      state.setState({
        firmDisclosures: disclosures,
      })
    })
}
export function stopListeningToFirmDisclosures() {
  // Stop listening to changes
  unsubscribeFirmDisclosures()
}
