export const standardFields = [
  {
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    static: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    bold: false,
    italic: false,
    required: false,
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    showDescription: null,
    inline: null,
    id: 'bitsy__section_break_1',
    text: 'Section Break',
    element: 'LineBreak',
  },
  {
    dirty: false,
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    static: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    bold: false,
    italic: false,
    required: false,
    content: 'First Investor ',
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    id: 'bitsy__label_first_investor',
    text: 'Label',
    element: 'Label',
  },
  {
    dirty: false,
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    label: 'Legal First Name ',
    required: true,
    field_name: 'legal_name_first',
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    canHaveAnswer: true,
    id: 'bitsy__legal_name_first',
    text: 'Text Input',
    element: 'TextInput',
  },
  {
    dirty: false,
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    label: 'Middle Initial ',
    required: false,
    field_name: 'legal_name_mi',
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    canHaveAnswer: true,
    id: 'bitsy__legal_name_mi',
    text: 'Text Input',
    element: 'TextInput',
  },
  {
    dirty: false,
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    label: 'Legal Last Name ',
    required: true,
    field_name: 'legal_name_last',
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    canHaveAnswer: true,
    id: 'bitsy__legal_name_last',
    text: 'Text Input',
    element: 'TextInput',
  },
  {
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    static: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    bold: false,
    italic: false,
    required: false,
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    showDescription: null,
    inline: null,
    id: 'bitsy__section_break_2',
    text: 'Section Break',
    element: 'LineBreak',
  },
  {
    dirty: false,
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    static: true,
    pageBreakBefore: false,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    bold: false,
    italic: false,
    required: false,
    content: 'Second Investor ',
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    id: 'bitsy__label_second_investor',
    text: 'Label',
    element: 'Label',
  },
  {
    dirty: false,
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    label: 'Include a Second Investor? ',
    required: true,
    field_name: 'includeSecondInvestor',
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    canHaveAnswer: true,
    options: [
      {
        text: 'No',
        value: 'No',
        key: 'dropdown_option_88E0D800-12C8-4A5D-9389-1B1889CB94FF',
      },
      {
        text: 'Yes',
        value: 'Yes',
        key: 'dropdown_option_D0685FDB-28BA-4BAD-B9AC-5BE91E25CD3B',
      },
    ],
    id: 'bitsy__includeSecondInvestor',
    text: 'Dropdown',
    element: 'Dropdown',
    defaultValue: 'No',
  },
  {
    dirty: false,
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    label: 'Legal First Name ',
    required: true,
    field_name: 'second_investor_legal_name_first',
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    canHaveAnswer: true,
    id: 'bitsy__second_investor_legal_name_first',
    text: 'Text Input',
    element: 'TextInput',
    displayRule: {
      key: 'includeSecondInvestor',
      comparator: '===',
      value: 'Yes',
    },
  },
  {
    dirty: false,
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    label: 'Middle Initial ',
    required: false,
    field_name: 'second_investor_legal_name_mi',
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    canHaveAnswer: true,
    id: 'bitsy__second_investor_legal_name_mi',
    text: 'Text Input',
    element: 'TextInput',
    displayRule: {
      key: 'includeSecondInvestor',
      comparator: '===',
      value: 'Yes',
    },
  },
  {
    dirty: false,
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    label: 'Legal Last Name ',
    required: true,
    field_name: 'second_investor_legal_name_last',
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    canHaveAnswer: true,
    id: 'bitsy__second_investor_legal_name_last',
    text: 'Text Input',
    element: 'TextInput',
    displayRule: {
      key: 'includeSecondInvestor',
      comparator: '===',
      value: 'Yes',
    },
  },
  {
    canHaveDisplayHorizontal: true,
    canHaveOptionCorrect: true,
    static: true,
    canHavePageBreakBefore: false,
    canHaveAlternateForm: false,
    bold: false,
    italic: false,
    required: false,
    canHaveOptionValue: true,
    canPopulateFromApi: false,
    showDescription: null,
    inline: null,
    id: 'bitsy__section_break_3',
    text: 'Section Break',
    element: 'LineBreak',
  },
]
