import colors from './colors'

const fonts = {
  h1: {},
  h2: {},
  h3: {},
  h4: {},
  h5: {},
  headingUppercase: {
    fontFamily: 'DM Sans',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '23px',
    textTransform: 'uppercase',
    letterSpacing: '0.08px',
    color: colors.black,
  },
  heading: {
    fontFamily: 'DM Sans',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '23px',
    letterSpacing: '0.08px',
    color: colors.black,
  },
  headingSmall: {
    fontFamily: 'DM Sans',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    marginBottom: '7px',
    color: colors.grayHeadline,
  },
}

export default fonts
