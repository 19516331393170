import React from 'react'
import colors from '../../../styles/colors'
import styled from 'styled-components'
import fonts from '../../../styles/fonts'

const FormBuilderTopBar = ({ SW, steps }) => {
  if (!SW) return null

  const TopBarItem = ({ idx, icon, label }) => {
    const color =
      SW.currentStep === idx + 1
        ? colors.primary
        : SW.currentStep > idx + 1
        ? colors.grayHeadline
        : colors.gray
    return (
      <OuterWrapper>
        <StepWrapper onClick={() => SW.goToStep(idx + 1)}>
          <IconWrapper>{icon(color)}</IconWrapper>
          <IconLabel style={{ color }}>{label}</IconLabel>
        </StepWrapper>
      </OuterWrapper>
    )
  }

  return (
    <>
      <Wrapper>
        {steps.map((step) => (
          <TopBarItem {...step} key={`topBarItem-${step.idx}`} />
        ))}
      </Wrapper>
    </>
  )
}

export default FormBuilderTopBar

const OuterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 600px;
  margin: auto;
  padding: 20px;
  justify-content: center;
`

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  justify-content: center;
  align-content: center;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const IconLabel = styled.span`
  font-size: larger;
  text-align: center;
  padding: 20px;
  ${fonts.headingUppercase}
`
