import React, { useState, useEffect } from 'react'
import { parse, stringify } from 'query-string'
import { navigate, useParams, Link } from '@reach/router'
import { Modal } from 'semantic-ui-react'
import BrandedButton from 'components/partials/BrandedButton'
import Loader from 'components/partials/Loader'
import BrandLogo from 'components/partials/BrandLogo'
import LogInForm from 'components/forms/LogInForm'
import ResetPasswordForm from 'components/forms/ResetPasswordForm'
import CalendlyWidget from '../partials/CalendlyWidget'
import PoweredByBitsy from 'components/partials/PoweredByBitsy'
import { customSignInUser } from 'actions/authActions'
import { useUser, useFirm, useAdvisor } from 'contexts'
import styled from 'styled-components'
import { colors } from 'styles'
import {
  AuthPage,
  AuthBox,
  AuthLogo,
  AuthCard,
  AuthCardContent,
  AuthCardActions,
  AuthHeader,
  AuthButton,
  AuthExtra,
} from 'components/styled/AuthPage'
import BitsyLogo from 'components/partials/BitsyLogo'

const LogIn = (props) => {
  const params = useParams() || { brandID: '' }
  const { uid } = useUser()
  const { firmID, firm, setFirmID } = useFirm()
  const { advisor, advisorID, setAdvisorID } = useAdvisor()
  const { isEmailLogin, location } = props

  const [showResetPassword, setShowResetPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false)

  const { token, ...callbackParams } = parse(location.search)

  const redirect = stringify(callbackParams)

  // Set firm and advisor IDs
  useEffect(() => {
    if (params.brandID) {
      // ? params.brandID can be in the format `FIRM_ID` or `FIRM_ID--ADVISOR_ID`
      const brandIDs = params.brandID.split('--')

      // Set firm
      if (!firmID) {
        setFirmID(brandIDs[0])
      }

      // Set advisor
      if (!advisorID && brandIDs[1]) {
        setAdvisorID(brandIDs[1])
      }
    }
  }, [firmID, advisorID, params.brandID, setFirmID, setAdvisorID])

  useEffect(() => {
    if (token) {
      customSignInUser(token, redirect)
    } else if (uid) {
      navigate(`../${redirect && `?${redirect}`}`)
    }
  }, [token, uid, redirect])

  const toggleResetPassword = () => {
    setShowResetPassword((prevShowResetPassword) => !prevShowResetPassword)
  }

  if (token) {
    return <Loader message={`Signing you in...`} fullScreen />
  }

  if (
    (params.brandID && !firm) ||
    (params.brandID &&
      params.brandID.split('--').length > 1 &&
      !advisor &&
      !firm)
  ) {
    return <Loader fullScreen />
  }

  return (
    <AuthPage className={firm ? 'firm-login-page' : ''}>
      <AuthBox>
        <AuthLogo>
          {firm || advisor ? (
            <StyledBrandLogo brand={advisor && advisor.logo ? advisor : firm} />
          ) : (
            <BitsyLogo />
          )}
        </AuthLogo>

        <AuthCard>
          <AuthCardContent>
            {showResetPassword ? (
              <>
                <AuthHeader>
                  <h1>Reset Password</h1>
                  <p>
                    What's the email you used to sign up or your firm invited
                    you with?
                  </p>
                </AuthHeader>
                <StyledResetPasswordForm email={email} />
              </>
            ) : (
              <>
                <AuthHeader>
                  {firm ? (
                    <h1>Log in</h1>
                  ) : (
                    <>
                      <p>Welcome back</p>
                      <h1>Log in to your Bitsy account</h1>
                    </>
                  )}
                </AuthHeader>
                <LogInForm
                  showResetPassword={showResetPassword}
                  setEmail={setEmail}
                  isEmailLogin={isEmailLogin}
                >
                  <ResetPasswordToggle
                    type="button"
                    className="link"
                    onClick={toggleResetPassword}
                  >
                    Forgot or don't have a password?
                  </ResetPasswordToggle>
                </LogInForm>
              </>
            )}
          </AuthCardContent>

          {firm && firm.calendly && firm.calendly.url && (
            <AuthCardActions extra>
              <Modal
                onClose={() => setCalendlyModalOpen(false)}
                onOpen={() => setCalendlyModalOpen(true)}
                open={calendlyModalOpen}
                trigger={
                  <BrandedButton firm={firm} compact>
                    Schedule Meeting
                  </BrandedButton>
                }
              >
                <CalendlyWidget url={firm.calendly.url} />
              </Modal>
            </AuthCardActions>
          )}
        </AuthCard>

        {showResetPassword ? (
          <AuthExtra>
            <button
              type="button"
              className="link"
              onClick={toggleResetPassword}
            >
              {'<'} Back to Login
            </button>
          </AuthExtra>
        ) : (
          !firm && (
            <AuthExtra>
              New firm? <Link to="/register-firm">Register Here</Link>
            </AuthExtra>
          )
        )}
      </AuthBox>

      {firm && <PoweredByBitsy />}
    </AuthPage>
  )
}

export default LogIn

const StyledBrandLogo = styled(BrandLogo)`
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  color: ${colors.grayAlt};
  display: block;
  line-height: 1;
`

const StyledResetPasswordForm = styled(ResetPasswordForm)`
  &&& {
    h2 {
      display: none;
    }
  }
`

const ResetPasswordToggle = styled(AuthButton)``
