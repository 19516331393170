import React, { memo, useState, useEffect, useCallback } from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import Loader from '../../../partials/Loader'
import { SigningIcon } from './FinalizePage'
import { ToDoIcon } from '../../../icons'
import {
  getSigningUrl,
  finalizeEnvelope,
} from '../../../../actions/integrations/docusign'
import { useFirm } from '../../../../contexts'
import { useItem } from '../../../../hooks'
import { colors, fonts } from '../../../../styles'

const getSignersToFinalizeDraft = (signers) => {
  return Object.values(signers)
    .filter((signerAttr) => signerAttr.signerID !== 'firm')
    .sort((sa, sb) => {
      return parseInt(sa.recipientId) - parseInt(sb.recipientId)
    })
    .map((signerAttr) => ({
      email: signerAttr.email,
      recipientId: signerAttr.recipientId,
      name: signerAttr.name,
    }))
}

const getDescriptionFromSignerID = (id) => {
  let name

  switch (id) {
    case 'client_1':
      name = 'First Investor'
      break
    case 'client_2':
      name = 'Second Investor'
      break
    case 'advisor':
      name = 'Advisor'
      break
    case 'firm':
      name = 'Firm'
      break
    default:
      name = 'Signer'
      break
  }

  return name
}

const signingOrder = {
  client_1: 1,
  client_2: 2,
  advisor: 3,
}

const FinalizeSigningPage = memo(
  ({
    envelopeID = null,
    signersLoading,
    handleSetDocSignSuccess,
    preview,
    nextStep,
    handleFinalizePageFinalize,
  }) => {
    const { firmID } = useFirm()

    const [signers, setSigners] = useState(null)
    const [signingWindows, setSigningWindow] = useState({})
    const [currentSigner, setCurrentSigner] = useState()
    const [urlLoading, setUrlLoading] = useState(false)
    const [successfulSigners, setSuccessfulSigners] = useState({})
    const [processing, setProcessing] = useState({})

    // Get envelope details
    const envelopeDoc = useItem({
      itemType: 'envelope',
      uid: envelopeID,
      load: envelopeID,
    })

    useEffect(() => {
      if (envelopeDoc && envelopeDoc.recipients) {
        setSigners(envelopeDoc.recipients)
      }
    }, [envelopeDoc])

    // Set doc sign success if all signers have signed
    useEffect(() => {
      if (envelopeDoc && envelopeDoc.recipients) {
        const signers = envelopeDoc.recipients
        const signerKeys = Object.keys(signers)
        const signersCount = signerKeys.length
        let signersSigned = signerKeys.filter(
          (signerID) => signers[signerID].status === 'Signed'
        )

        const successfulSignersCount = Object.keys(successfulSigners).length

        signersSigned = Math.max(signersSigned.length, successfulSignersCount)

        const firmSigner = signerKeys.filter(
          (signerID) => signers[signerID].signerID === 'firm'
        )

        if (signersSigned === signersCount) {
          handleSetDocSignSuccess(true)
        } else if (
          signersSigned === signersCount - 1 &&
          firmSigner.length === 1
        ) {
          // Set doc submit success with awaiting firm signing message
          // setAwaitingFirmSignature(true)
          handleSetDocSignSuccess(true)
        }
      }
    }, [envelopeDoc, successfulSigners, handleSetDocSignSuccess])

    const handleFinalize = async ({ deliveryMethod }) => {
      setProcessing({ inFlight: true, finalizeType: deliveryMethod })
      handleFinalizePageFinalize('finalize-preview', envelopeID, async () => {
        console.log('Finalizing envelope...')
        const finalizeRes = await finalizeEnvelope({
          deliveryMethod,
          envelopeID,
          signers: getSignersToFinalizeDraft(signers),
          firmID,
          setProcessing,
          nextStep: 'email' === deliveryMethod ? nextStep : null,
        })

        return finalizeRes
      })
    }

    const openSigningWindow = (signerKey, name, signerId) => {
      setUrlLoading(true)
      setCurrentSigner(signerId)

      const signerEmailKey = signers[signerKey].email || signerKey // Legacy email signer keys
      const recipient = {
        recipientId: signers[signerKey].recipientId,
        name,
        signerId: signerKey,
        email: signerEmailKey,
        firmId: firmID,
        advisorId: envelopeDoc.advisor,
      }
      getSigningUrl({
        envelopeID,
        recipient,
        preview: preview && !processing['finalizeType'],
        setSigningUrl: (url) => {
          const currentWindow = window.open(
            url,
            signerKey,
            'scrollbars,width=' +
              parseInt(window.innerWidth) * 0.8 +
              ',height=' +
              parseInt(window.innerHeight) * 0.8
          )
          setSigningWindow((oldSigningWindows) => ({
            ...oldSigningWindows,
            [signerKey]: currentWindow,
          }))
          setUrlLoading(false)
        },
      })
    }

    const closePopup = useCallback(
      (event) => {
        const { data, origin: popupOrigin } = event
        if (
          popupOrigin !== window.origin ||
          !data ||
          data.source === 'react-devtools-bridge' ||
          data.source === 'react-devtools-content-script'
        ) {
          return
        }

        try {
          const { signerId } = JSON.parse(data)
          if (signingWindows[signerId] && !signingWindows[signerId].closed) {
            signingWindows[signerId].close()
          }

          setSuccessfulSigners((oldSuccessfulSigner) => ({
            ...oldSuccessfulSigner,
            [signerId]: true,
          }))
        } catch (err) {
          console.error(err)
        }
      },
      [signingWindows]
    )

    useEffect(() => {
      window.addEventListener('message', closePopup)

      return () => {
        window.removeEventListener('message', closePopup)
      }
    }, [closePopup])

    if (currentSigner && signers && Object.keys(signers).length) {
      Object.keys(signers).forEach((signerKey) => {
        const signer = signers[signerKey]
        if (signer.signerID === currentSigner && signer.status === 'Signed') {
          if (signingWindows[signerKey] && !signingWindows[signerKey].closed) {
            signingWindows[signerKey].close()
          }
          setCurrentSigner(null)
        }
      })
    }

    let signerButtons = null
    if (signers && Object.keys(signers).length) {
      let enable = true
      signerButtons = Object.keys(signers)
        .filter((signerID) => {
          const signer = signers[signerID]
          return signer.signerID !== 'firm'
        })
        .sort((emailA, emailB) => {
          const signerA = signers[emailA]
          const signerB = signers[emailB]
          return signingOrder[signerA.signerID] - signingOrder[signerB.signerID]
        })
        .map((signerID) => {
          const signer = signers[signerID]
          const completedSigner =
            signer.status === 'Signed' || successfulSigners[signerID]
          let disableSigner = true
          if (!completedSigner && enable) {
            disableSigner = false
            enable = false
          }

          if (preview && !processing['finalizeType']) {
            disableSigner = false
          }
          return (
            <SigningButton
              key={signerID}
              loading={
                urlLoading && signer.signerID === currentSigner ? true : false
              }
              content={
                <>
                  <strong>{signer.name}</strong>
                  <br />
                  <small>{getDescriptionFromSignerID(signer.signerID)}</small>
                </>
              }
              icon={
                <i aria-hidden="true" className="icon">
                  <SigningStatusIcon
                    complete={
                      signer.status === 'Signed' || successfulSigners[signerID]
                    }
                  />
                </i>
              }
              size="big"
              disabled={disableSigner || processing['inFlight']}
              onClick={() => {
                openSigningWindow(signerID, signer.name, signer.signerID)
              }}
            />
          )
        })
    }

    if (signersLoading || processing['inFlight']) {
      return <Loader message="Loading! Please wait..." />
    }

    return (
      <Wrapper data-step="Finalize Signing">
        <Header>
          {preview && (processing['inFlight'] || !processing['finalizeType'])
            ? 'Preview documents for:'
            : 'Please have the following parties click below to sign:'}
        </Header>
        <Subhead>
          Please be sure to allow popups in your web browser.{' '}
          <a
            href="https://support.bitsyadvisor.com/docs/managing-documents/signing-documents"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </Subhead>
        <ButtonWrapper>
          <ButtonGroup basic vertical labeled icon data-id="preview-send-embed">
            {signerButtons}
          </ButtonGroup>
        </ButtonWrapper>

        {preview && (processing['inFlight'] || !processing['finalizeType']) && (
          <>
            <Header>Document looks good? Choose the delivery option:</Header>
            <ButtonWrapper>
              <ButtonGroup
                basic
                vertical
                labeled
                icon
                data-id="finalize-signing-buttons"
              >
                <Button
                  content="Send for signing"
                  icon="send"
                  primary
                  size="big"
                  onClick={() => {
                    handleFinalize({ deliveryMethod: 'email' })
                  }}
                  disabled={processing['inFlight']}
                  loading={
                    processing['inFlight'] &&
                    processing['finalizeType'] === 'email'
                  }
                  data-tip="Email the final document for signing and update the client in any integrations"
                />
                <Button
                  content="Sign now"
                  icon={
                    <i aria-hidden="true" className="icon">
                      <SigningIcon />
                    </i>
                  }
                  primary
                  size="big"
                  onClick={() => {
                    handleFinalize({ deliveryMethod: 'embedded' })
                  }}
                  disabled={processing['inFlight']}
                  loading={
                    processing['inFlight'] &&
                    processing['finalizeType'] === 'embedded'
                  }
                  data-tip="Initiate an in-person signing session and update the client in any integrations"
                />
              </ButtonGroup>
            </ButtonWrapper>
          </>
        )}
      </Wrapper>
    )
  }
)

export default FinalizeSigningPage

const Header = styled.div`
  ${fonts.heading};
  font-style: italic;
  text-align: center;
  margin: 20px;
`

const Subhead = styled.div`
  margin: 20px;
  text-align: center;
  font-style: italic;
  font-size: 90%;
  opacity: 1;
`

const Wrapper = styled.div`
  margin: auto;
  justify-content: center;
  align-content: center;
  max-width: 600px;
  align-self: center;
  height: 100%;
  padding: 25px 0;
`

const ButtonWrapper = styled.div`
  text-align: center;
`

const ButtonGroup = styled(Button.Group)`
  margin: 0 auto !important;
`

const SigningStatusIcon = styled(ToDoIcon)`
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  height: 18px;
  max-width: 100%;
  margin: 0 auto;

  & g#Core {
    fill: ${(props) => (props.complete ? colors.primary : colors.warning)};
  }
`

const SigningButton = styled(Button)`
  & > i.icon {
    background-color: rgba(0, 0, 0, 0.02) !important;
  }
`
