// Client Initial State
export const clientInitialState = {
  firm: '',
  dateStarted: '',
  email: '',
  legal_name: '',
  legal_name_first: '',
  legal_name_mi: '',
  legal_name_last: '',
  preferred_name: '',
  birthdate: '',
  address_street: '',
  address_city: '',
  address_state: '',
  address_zip: '',
  has_same_mailing_address: 'Yes',
  address_mailing_street: '',
  address_mailing_city: '',
  address_mailing_state: '',
  address_mailing_zip: '',
  second_investor_relation: '',
  second_investor_relation_details: '',
  second_investor_legal_name: '',
  second_investor_legal_name_first: '',
  second_investor_legal_name_mi: '',
  second_investor_legal_name_last: '',
  second_investor_preferred_name: '',
  second_investor_birthdate: '',
  second_investor_email: '',
  second_investor_occupation: '',
  second_investor_has_same_address: 'Yes',
  second_investor_address_street: '',
  second_investor_address_city: '',
  second_investor_address_state: '',
  second_investor_address_zip: '',
  second_investor_has_same_mailing_address: 'Yes',
  second_investor_address_mailing_street: '',
  second_investor_address_mailing_city: '',
  second_investor_address_mailing_state: '',
  second_investor_address_mailing_zip: '',
  second_investor_retirement_age: '',
  second_investor_phone: '',
  income_range: '',
  accounts_non_retirement: [],
  accounts_non_retirement_other: '',
  accounts_retirement: [],
  accounts_retirement_other: '',
  investment_experience_general: '',
  investment_experience_items: [
    { type: 'Mutual Funds', years: '', details: '', knowledge: '' },
    { type: 'Stocks', years: '', details: '', knowledge: '' },
    { type: 'Bonds', years: '', details: '', knowledge: '' },
    { type: 'Annuities', years: '', details: '', knowledge: '' },
    { type: 'ETFs', years: '', details: '', knowledge: '' },
    { type: 'REITs', years: '', details: '', knowledge: '' },
    { type: 'Limited Partnerships', years: '', details: '', knowledge: '' },
    { type: 'Other', years: '', details: '', knowledge: '' },
  ],
  net_worth: '',
  net_worth_liquid: '',
  investable_assets: '',
  portfolio_value: '',
  portfolio_value_qualified: '',
  portfolio_value_non_qualified: '',
  residence_value: '',
  retirement_age: '',
  retirement_income: '',
  retirement_needs: '',
  advisor: '',
  jurisdiction: '',
  custodian: {
    id: '',
    preferred_name: '',
    legal_name: '',
    address_street: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    phone: '',
  },
  investment_profiles: [
    { account: 'All Accounts', profile: '', time_frame: '' },
  ],
  prospect: {
    interest_level: null,
    referral: '',
    referred_by: '',
    money_importance: '',
    vision: '',
    experience: '',
    current_feelings: null,
    expectations_option: null,
    expectations: '',
    move_forward: null,
    interested_in: [],
    how_serve: null,
    booked_meeting_type: null,
  },
  age_range: '',
  occupation: '',
  occupation_details: {
    title: '',
    employer: '',
    years: '',
  },
  phone: '',
  phone_mobile: '',
  spouse_partner: '',
  children: [{ name: '' }],
  close_friends_relatives: [{ name: '', relation: '' }],
  preferred_communication: '',
  spend_time: '',
  personal_advisors: {
    attorney: { name: '', firm: '', commitment_level: '' },
    accountant: { name: '', firm: '', commitment_level: '' },
    current_financial_advisor: { name: '', firm: '', commitment_level: '' },
    insurance_agent: { name: '', firm: '', commitment_level: '' },
  },
  retirement_age_exact: '',
  retirement_work: '',
  current_saving: [],
  current_saving_per_month: '',
  current_contributions: [],
  current_insurance_plan_status: '',
  current_insurance_plans: [],
  tax_bracket: '',
  assets_held_away: {
    stocks: '',
    cash_value_life: '',
    bonds: '',
    annuities: '',
    options: '',
    commodities: '',
    mutual_funds: '',
    other: '',
  },
  expenses: '',
  expenses_special: '',
  expenses_special_timeframe: '',
  investment_objectives: '',
  investment_objectives_other: '',
  investment_objectives_time_horizon: '',
  risk_score: '',
  other_information: '',
  marital_status: '',
  second_investor_marital_status: '',
  fee_type: '',
  fee_fixed: '',
  fee_401k: '',
  annual_fees: null,
  account_registrations: [],
  account_designations: [],
  third_party_fees: [],
  attorney_name: '',
  attorney_email: '',
  attorney_phone: '',
  cpa_name: '',
  cpa_email: '',
  cpa_phone: '',
  executor_name: '',
  executor_email: '',
  executor_phone: '',
  // Hobart fields:
  schedule_a: '',
  assets_excepted_from_advisory_fee: [],
  assets_subject_to_custom_fee: [],
  third_party_advisers: [],
  authorized_third_party: '',
  // Jalinski fields:
  email_notifications: '',
  authorized_contacts: [],
  conflict_public: '',
  conflict_finra: '',
  conflict_finra_details: '',
  depends_on_portfolio_assets: '',
  depends_on_portfolio_assets_details: '',
  annual_income: '',
  notes_strategic: '',
  notes_tactical: '',
  meeting_notes: '',
  investment_restrictions_other_important_information: '',
  dependents_count: '',
  dependents_ages: '',
  current_investment_assets_managed: '',
  risk_tolerance: '',
  can_withstand_market_declines: '',
  can_withstand_market_declines_details: '',
  tactical_strategy: '',
  investment_objective_details: '',
  intended_investment_horizon: '',
  strategic_asset_allocation: '0%',
  tactical_asset_allocation: '0%',
  fee_strategic: '1.25%',
  fee_tactical: '1.75%',
}

// IAC Initial State
export const iacInitialState = {
  initials: {
    adv_receipt: '',
    electronic_consent: '',
    advisor_obtained_records: '',
    discretionary: '',
    nondiscretionary: '',
  },
  signatures: {
    clientSig1: null,
    secondSig1: null,
    clientSig2: null,
    secondSig2: null,
  },
}

export const centennialIacInitialState = {
  initials: {
    services_1_client: '',
    services_1_second: '',
    services_2_client: '',
    services_2_second: '',
    services_3_client: '',
    services_3_second: '',
    services_4_client: '',
    services_4_second: '',
    discretionary_client: '',
    discretionary_second: '',
    nondiscretionary_client: '',
    nondiscretionary_second: '',
    adv_receipt_client: '',
    adv_receipt_second: '',
    authorized_withdrawal_client: '',
    authorized_withdrawal_second: '',
    fee_schedule_client: '',
    fee_schedule_second: '',
    advisory_fee_client: '',
    advisory_fee_second: '',
  },
  signatures: {
    client_1_text: '',
    second_1_text: '',
    client_2_text: '',
    second_2_text: '',
    client_3_text: '',
    second_3_text: '',
  },
}

export const hobartIacInitialState = {
  initials: {
    discretionary_client: '',
    discretionary_second: '',
    nondiscretionary_client: '',
    nondiscretionary_second: '',
    advisory_fee_client: '',
    advisory_fee_second: '',
    schedule_a_client: '',
    schedule_a_second: '',
    no_management_services_client: '',
    no_management_services_second: '',
    schedule_b_client: '',
    schedule_b_second: '',
    schedule_c_client: '',
    schedule_c_second: '',
    schedule_d_client: '',
    schedule_d_second: '',
    fee_billing_client: '',
    fee_billing_second: '',
  },
  signatures: {
    sig_1_client: '',
    sig_1_client_text: '',
    sig_1_second: '',
    sig_1_second_text: '',
    sig_2_client: '',
    sig_2_client_text: '',
    sig_2_second: '',
    sig_2_second_text: '',
    sig_3_client: '',
    sig_3_client_text: '',
    sig_3_second: '',
    sig_3_second_text: '',
  },
}
