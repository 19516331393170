import { firebaseFunctions } from '../../../config/firebaseConfig'

const integrationsStripeCheckout = firebaseFunctions.httpsCallable(
  'integrationsStripeCheckout'
)

export default async function fetchCheckoutSession({
  customerEmail,
  priceId,
  redirectParams,
}) {
  try {
    const result = await integrationsStripeCheckout({
      customerEmail,
      priceId,
      redirectParams,
    })

    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}
