import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { firebaseStorage } from '../../config/firebaseConfig'
// import { clientAddDocToDB } from './clientDbActions'
import { addDocToDB } from '../db/docActions'
import { emailDocument } from '../email/docActions'

export function saveDocument(
  type,
  state,
  clientName,
  clientEmail,
  advisorName,
  advisorEmail,
  firmName,
  firmEmail,
  emailBody,
  firmDisclosures,
  advisorDisclosures,
  firmID,
  clientID,
  db,
  allDocs,
  taskDetails,
  secondInvestorName = null,
  secondInvestorEmail = null
) {
  const doc = document.getElementById(type)

  // Set up formatted date
  const date = new Date()
  var year = date.getFullYear()
  var month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month
  var day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day
  const dateFormatted = month + '/' + day + '/' + year
  const dateFormattedFileName = year + '-' + month + '-' + day

  // Set up document name
  var documentName = ''
  if (type === 'iac') {
    documentName = 'IAC'
  } else if (type === 'suitability-form') {
    documentName = 'Suitability Form'
  }

  let pdfOptions = {
    paperSize: 'Letter',
    forcePageBreak: '.page-break',
    fileName:
      firmName +
      ' ' +
      documentName +
      ' - ' +
      clientName +
      ' - ' +
      dateFormatted +
      '.pdf',
    title:
      firmName +
      ' ' +
      documentName +
      ' - ' +
      clientName +
      ' - ' +
      dateFormatted,
    author: advisorName,
    creator: firmName,
    subject: '',
    keywords: '',
    margin: '2cm',
    scale: 0.6,
  }

  state.setState({
    show_iac: true,
    docGenerating: true,
    docError: false,
  })

  if (doc) {
    drawDOM(doc, pdfOptions)
      .then((group) => {
        return exportPDF(group, pdfOptions)
      })
      .then((data) => {
        var pdfData = data.split(';base64,')[1]

        var storagePath = type + 's'

        // Upload to server
        const storageRef = firebaseStorage
          .ref()
          .child(firmID)
          .child('clients')
          .child(clientID)
          .child(storagePath)
        const docFile = storageRef.child(
          firmName +
            ' ' +
            documentName +
            ' - ' +
            clientName +
            ' - ' +
            dateFormattedFileName +
            '.pdf'
        )

        docFile
          .putString(pdfData, 'base64', { contentType: 'application/pdf' })
          .then((snapshot) => {
            docFile
              .getDownloadURL()
              .then((url) => {
                // Email documents
                emailDocument(
                  type,
                  firmID,
                  clientName,
                  clientEmail,
                  advisorName,
                  advisorEmail,
                  firmName,
                  firmEmail,
                  emailBody,
                  url,
                  firmDisclosures,
                  advisorDisclosures,
                  secondInvestorName,
                  secondInvestorEmail
                )

                state.setState({
                  show_iac: false,
                  docGenerating: false,
                })

                // Add doc to client database entry
                if (db === 'clientDB') {
                  addDocToDB(
                    type,
                    url,
                    clientID,
                    pdfOptions.title,
                    date,
                    allDocs,
                    'client',
                    taskDetails
                  )
                } else {
                  addDocToDB(
                    type,
                    url,
                    clientID,
                    pdfOptions.title,
                    date,
                    allDocs,
                    null,
                    taskDetails
                  )
                }
              })
              .catch((error) => {})
          })
      })
  } else {
    state.setState({
      docGenerating: false,
      docError: true,
    })
  }
}
