import React, { useEffect, useState } from 'react'
import { Header, Message } from 'semantic-ui-react'
import Table from '../../partials/Table'
import styled from 'styled-components'
import { useItemList } from '../../../hooks'
import { FieldSelector } from './partials'
import { useUser } from '../../../contexts'
import { updateIntegrationFieldMapping } from '../../../actions/db/templates'
import getIntegrationFields from 'actions/integrations/getFields'

const serviceKeyDetails = {
  redtail: {
    contact: { title: 'Contact', icon: 'user' },
    internets: { title: 'Internet', icon: 'globe' },
    networth: { title: 'Net Worth', icon: 'dollar' },
    tax: { title: 'Tax', icon: 'money' },
    sam: { title: 'SAM', icon: 'briefcase' },
    notes: { title: 'Notes', icon: 'sticky note' },
    phones: { title: 'Phone', icon: 'phone' },
    addresses: { title: 'Address', icon: 'home' },
    accounts: { title: 'Accounts', icon: 'folder open' },
    udf: { title: 'UDF', icon: 'file alternate' },
  },
  salesforce: {
    Lead: { icon: 'user plus' },
    Contact: { icon: 'user' },
  },
}

const isAuthenticationUsable = (serviceId, serviceData) => {
  if (serviceId === 'redtail' && serviceData && serviceData.userKey) {
    return true
  }

  if (serviceId === 'salesforce' && serviceData && serviceData.token) {
    return true
  }

  return false
}

const CRMFieldMapper = ({ firmID, integrations }) => {
  const { uid } = useUser()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState({})
  const [authenticatedIntegrations, setAuthenticatedIntegrations] = useState([])
  const [fieldMappings, setFieldMappings] = useState({})

  const bitsyFields = useItemList({
    itemType: 'field',
    userType: 'template',
    uid: firmID,
    isUserItem: true,
    load: firmID,
  })

  useEffect(() => {
    const fetchOptions = async () => {
      setLoading(true)
      const { redtail, salesforce } = await getIntegrationFields(uid)
      const redtailOptions = []

      for (let key of Object.keys(redtail)) {
        const prettyKey = serviceKeyDetails.redtail[key]?.title || key
        for (let [field, values] of Object.entries(redtail[key].fields)) {
          if (values.visible) {
            redtailOptions.push({
              key: `${key}.${field}`,
              value: `${key}.${field}`,
              text: `${prettyKey} - ${field}`,
            })
          }
        }
      }

      const salesforceOptions = []

      for (let key of Object.keys(salesforce)) {
        const prettyKey = serviceKeyDetails.salesforce[key]?.title || key
        for (let value of salesforce[key]) {
          salesforceOptions.push({
            key: `${key}.${value.name}`,
            value: `${key}.${value.name}`,
            text: `${prettyKey} - ${value.label}`,
          })
        }
      }

      setOptions({
        redtail: redtailOptions,
        salesforce: salesforceOptions,
      })
      setLoading(false)
    }
    fetchOptions()
  }, [uid])

  useEffect(() => {
    const localFieldMapping = {}
    const localAuthenticatedPipelines = []
    for (const [serviceID, serviceData] of Object.entries(integrations)) {
      if (serviceData && serviceData.fields) {
        localFieldMapping[serviceID] = serviceData.fields
      }

      if (isAuthenticationUsable(serviceID, serviceData)) {
        localAuthenticatedPipelines.push(serviceID)
      }
    }

    setFieldMappings(localFieldMapping)
    setAuthenticatedIntegrations(localAuthenticatedPipelines.sort())
  }, [integrations])

  const columns = React.useMemo(() => {
    let tableColumns = [
      {
        Header: 'Bitsy',
        accessor: 'bitsy',
        Cell: ({ row }) => {
          const original = row.original
          return (
            <>
              {original.bitsy}
              {original.fieldName !== original.bitsy && (
                <>
                  <br />
                  <StyledSmall>{original.fieldName}</StyledSmall>
                </>
              )}
            </>
          )
        },
      },
    ]

    for (const serviceID of authenticatedIntegrations) {
      tableColumns.push({
        Header: serviceID.toUpperCase(),
        id: serviceID,
        Cell: ({ row }) => {
          let selectedValue = ''
          if (
            fieldMappings[serviceID] &&
            fieldMappings[serviceID][row.original.fieldName]
          ) {
            selectedValue = fieldMappings[serviceID][row.original.fieldName]
          }

          return (
            <FieldSelector
              key={serviceID}
              handleChange={(service, value) => {
                handleSetFieldMapping(row.original.fieldName, service, value)
                updateIntegrationFieldMapping({
                  uid,
                  newValue: value,
                  fieldId: row.original.fieldName,
                  service,
                })
              }}
              service={serviceID}
              value={selectedValue}
              options={options[serviceID] || []}
              loading={loading}
            />
          )
        },
      })
    }

    return tableColumns
  }, [authenticatedIntegrations, options, fieldMappings, loading, uid])

  const data = React.useMemo(() => {
    let dataRows = []

    if (
      bitsyFields &&
      Object.keys(bitsyFields) &&
      Object.keys(bitsyFields).length
    ) {
      dataRows = Object.keys(bitsyFields).map((bitsyFieldName) => {
        const bitsyField = bitsyFields[bitsyFieldName]
        const { label, field_name, element, id } = bitsyField

        return {
          bitsy: label || field_name,
          type: element,
          fieldId: id,
          fieldName: field_name,
        }
      })
    }

    return dataRows
  }, [bitsyFields])

  const handleSetFieldMapping = (bitsy, service, value) => {
    setFieldMappings((fieldMapping) => ({
      ...fieldMapping,
      [service]: {
        ...fieldMapping[service],
        [bitsy]: value,
      },
    }))
  }

  return (
    <Wrapper>
      <StyledHeader
        content="Map fields to your integrated services to onboard clients even faster!"
        textAlign="center"
      />
      {columns && columns.length > 1 ? (
        <StyledTable columns={columns} data={data} />
      ) : (
        <Message
          compact
          info
          header="We couldn't find any active integrations!"
          content="Please go to the Integrations tab to set some up."
        />
      )}
    </Wrapper>
  )
}

export default CRMFieldMapper

const Wrapper = styled.div`
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
`

const StyledHeader = styled(Header)`
  &&& {
    max-width: 400px;
    margin: 30px auto;
  }
`

const StyledTable = styled(Table)`
  &&& {
    & td[role='cell'] {
      overflow: visible;
    }
  }
`

const StyledSmall = styled.small`
  opacity: 0.5;
`
