import React, { memo } from 'react'
import Card from 'components/dashboards/partials/LegacyCard'
import EnvelopesStatusTable from 'components/dashboards/partials/EnvelopesStatusTable'
import { Row, ColMd12 } from 'components/styled'

const StatusTab = memo(() => {
  return (
    <>
      <Card title="Awaiting Client Signature">
        <Row>
          <ColMd12 className="text-center">
            <EnvelopesStatusTable
              pendingSigners={['client_1', 'client_2']}
              allowShowCompleted={false}
            />
          </ColMd12>
        </Row>
      </Card>

      <Card title="Awaiting Advisor Signature">
        <Row>
          <ColMd12 className="text-center">
            <EnvelopesStatusTable
              pendingSigners={['advisor']}
              allowShowCompleted={false}
            />
          </ColMd12>
        </Row>
      </Card>
    </>
  )
})

export default StatusTab
