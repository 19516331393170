import React, { Component } from 'react'
import { Form, Button } from 'semantic-ui-react'
import ErrorsList from 'components/partials/ErrorsList'
import Loader from 'components/partials/Loader'
import styled from 'styled-components'
import { VisuallyHidden } from 'styles/_mixins'

class RedtailAuthenticationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      username: '',
      password: '',
      errors: [],
      submitting: false,
    }

    this.attemptLogin = this.attemptLogin.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.loginUser = this.loginUser.bind(this)
    this.validate = this.validate.bind(this)
  }

  attemptLogin(event) {
    event.preventDefault()

    this.setState(
      {
        submitting: true,
      },
      () => {
        this.validate()
      }
    )
  }

  handleInputChange(event, { value }) {
    const target = event.target
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  loginUser() {
    if (this.state.errors.length === 0) {
      this.props.handleLogin(
        'redtail',
        this.state.username,
        this.state.password
      )
    }
  }

  validate() {
    const { username, password } = this.state

    var errors = []

    // Username
    if (username.length < 1) {
      errors.push('Please enter a username.')
    }

    // Password
    if (password.length < 1) {
      errors.push('Please enter a password.')
    }

    // Set state and login
    this.setState(
      {
        errors: errors,
        loginErrors: [],
        submitting: false,
      },
      () => {
        this.loginUser()
      }
    )
  }

  render() {
    const { type, loginErrors } = this.props
    const { username, password, errors, submitting } = this.state

    // const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1)

    // var headerText = null
    // if (type === 'Review IAC') {
    //   headerText = 'Review your IAC'
    // }

    return (
      <StyledForm
        id={'login login-' + type}
        className="authForm"
        onSubmit={this.attemptLogin}
        size="big"
      >
        <Form.Field>
          <label htmlFor="username">Username</label>
          <Form.Input
            value={username}
            type="text"
            placeholder="Username"
            name="username"
            id="username"
            onChange={this.handleInputChange}
            icon="user"
            iconPosition="left"
          />
        </Form.Field>

        <Form.Field>
          <label htmlFor="password">Password</label>
          <Form.Input
            value={password}
            type="password"
            placeholder="Password"
            name="password"
            id="password"
            onChange={this.handleInputChange}
            icon="lock"
            iconPosition="left"
          />
        </Form.Field>

        <Form.Field className="submit-section">
          <ErrorsList errors={errors.concat(loginErrors)} />

          {submitting ? (
            <Loader message="Please wait..." />
          ) : (
            <Button
              className="primary continue"
              type="submit"
              disabled={submitting}
              loading={submitting}
              size="big"
            >
              Connect
            </Button>
          )}
        </Form.Field>
      </StyledForm>
    )
  }
}

export default RedtailAuthenticationForm

const StyledForm = styled(Form)`
  &&& {
    margin-top: 30px;

    label {
      ${VisuallyHidden()};
    }
  }
`
