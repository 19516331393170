import React, { Component, Fragment } from 'react'
import * as Sentry from '@sentry/browser'
// import './Error.scss'

export default class Error extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventId: null,
    }
  }

  componentDidMount() {
    const { code, level, errorInfo, userInfo } = this.props

    if (code) {
      Sentry.withScope((scope) => {
        var errorMessage = '[' + code + ']'

        if (level) {
          scope.setLevel(level)
        }
        if (userInfo) {
          scope.setUser(userInfo)
        }
        if (errorInfo) {
          scope.setExtras(errorInfo)

          if (errorInfo.Details) {
            errorMessage += ' ' + errorInfo.Details
          }
        }

        const eventId = Sentry.captureException(errorMessage)
        this.setState({ eventId })
      })
    }
  }

  render() {
    const { message, code, userInfo, className } = this.props
    const { eventId } = this.state

    var sentryReportData = {
      eventId: eventId,
      title: "It looks like there's an issue.",
      subtitle: 'Our team has been notified.',
      subtitle2: "If you'd like to help, please tell us what happened below.",
    }

    if (userInfo) {
      if (userInfo.username) {
        sentryReportData['name'] = userInfo.username
      }

      if (userInfo.email) {
        sentryReportData['email'] = userInfo.email
      }
    }

    return (
      <div className={`bitsy-error-message${className ? ' ' + className : ''}`}>
        {message}

        {eventId ? (
          <div className="bitsy-error-report text-right">
            <small>
              <span className="report-intro">
                Not sure why this is happening?
              </span>{' '}
              <button
                className="link report-error-link"
                onClick={() => Sentry.showReportDialog(sentryReportData)}
              >
                Report feedback
              </button>
            </small>
          </div>
        ) : (
          <Fragment>
            {code && <div className="bitsy-error-code">[{code}]</div>}
          </Fragment>
        )}
      </div>
    )
  }
}
