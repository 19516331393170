import React, { memo, useState } from 'react'
import { Accordion, Feed, Header, Icon } from 'semantic-ui-react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Task from 'components/dashboards/partials/Task'
import LegacyTask from 'components/dashboards/partials/LegacyTask'
import { useUser, useFirm, useAdvisor } from 'contexts'
import styled from 'styled-components'

export default memo(function TaskList({
  tasks = null,
  incompleteTasksCount = null,
  location = null,
  client = null,
  className = '',
  ariaLabelledby = '',
  automaticOpenTask = false,
}) {
  const { uid } = useUser()
  const { firm } = useFirm()
  const { advisor } = useAdvisor()

  const [showCompletedTasks, setShowCompletedTasks] = useState(false)

  const toggleAccordion = (e) => {
    setShowCompletedTasks(!showCompletedTasks)
  }

  let incompleteTaskIDs = []
  let completedTaskIDs = []
  let TaskComponents = {}
  if (tasks && Object.keys(tasks).length) {
    incompleteTaskIDs = Object.keys(tasks).filter((taskID) => {
      const task = tasks[taskID]
      return task.status === 'incomplete'
    })

    completedTaskIDs = Object.keys(tasks).filter((taskID) => {
      const task = tasks[taskID]
      return task.status === 'complete'
    })

    Object.keys(tasks).forEach((taskID) => {
      const task = tasks[taskID]
      const { type } = task

      const legacyTaskTypes = ['suitability', 'suitability-review', 'iac']
      if (legacyTaskTypes.includes(type)) {
        TaskComponents[taskID] = (
          <LegacyTask
            key={'task-' + taskID}
            task={tasks[taskID]}
            id={taskID}
            taskDetails={{
              uid: uid,
              firm: firm,
              advisor: advisor,
              client: client,
              location: location,
            }}
          />
        )
      } else {
        TaskComponents[taskID] = (
          <Task
            key={`task-${taskID}`}
            task={task}
            id={taskID}
            automaticOpen={
              automaticOpenTask &&
              incompleteTaskIDs.includes(taskID) &&
              incompleteTaskIDs[0] === taskID
            }
          />
        )
      }
    })
  }

  let TaskFeedComponents = {}
  if (
    TaskComponents &&
    Object.keys(TaskComponents) &&
    Object.keys(TaskComponents).length
  ) {
    Object.keys(TaskComponents).forEach((taskID) => {
      const task = tasks[taskID]

      dayjs.extend(relativeTime)

      let dateSent
      if (tasks[taskID].dateSent) {
        dateSent = dayjs(tasks[taskID].dateSent.toDate())
        const oneWeekBefore = dateSent.subtract(1, 'year')

        if (dateSent.isAfter(oneWeekBefore)) {
          dateSent = dateSent.fromNow()
        } else {
          dateSent = dateSent.format('MMMM D, YYYY')
        }
      }

      let dateCompleted
      if (tasks[taskID].dateCompleted) {
        dateCompleted = dayjs(tasks[taskID].dateCompleted.toDate())
      }

      let completedText
      switch (task.type) {
        case 'form-review':
          completedText = 'You completed and returned'
          break
        default:
          completedText = 'Completed'
          break
      }

      TaskFeedComponents[taskID] = (
        <Feed.Event key={taskID}>
          {location === 'client-dashboard' && (
            <Feed.Label>
              <Icon
                name={
                  task.status === 'complete'
                    ? 'check circle'
                    : 'exclamation circle'
                }
                color={task.status === 'complete' ? 'green' : 'red'}
              />
            </Feed.Label>
          )}
          <Feed.Content>
            {dateSent && <Feed.Date>Sent {dateSent}</Feed.Date>}
            <Feed.Summary>{TaskComponents[taskID]}</Feed.Summary>
            {dateCompleted && (
              <Feed.Meta>
                {completedText} on{' '}
                {dateCompleted.format('dddd, MMMM D, YYYY [at] h:mm a')}
              </Feed.Meta>
            )}
          </Feed.Content>
        </Feed.Event>
      )
    })
  }

  if (!incompleteTasksCount) {
    incompleteTasksCount = incompleteTaskIDs.length
  }

  return (
    <StyledTaskList
      className={`tasks ${className}`}
      aria-labelledby={ariaLabelledby}
    >
      {location !== 'client-dashboard' && (
        <Header>
          You have {incompleteTasksCount > 0 ? incompleteTasksCount : 'no'}{' '}
          outstanding task
          {(!tasks || incompleteTasksCount === 0 || incompleteTasksCount > 1) &&
            's'}
        </Header>
      )}
      {tasks && Object.keys(tasks).length ? (
        <>
          <Feed>
            {incompleteTaskIDs.length ? (
              incompleteTaskIDs.map((taskID) => TaskFeedComponents[taskID])
            ) : (
              <div className="none-yet text-center instructional">
                You're all caught up!
                <br />
                We'll email you with any updates.
              </div>
            )}
          </Feed>
          {!!completedTaskIDs.length && (
            <Accordion fluid>
              <Accordion.Title
                active={showCompletedTasks}
                index={0}
                onClick={toggleAccordion}
              >
                <Icon name="dropdown" />
                {showCompletedTasks ? 'Hide Completed' : 'Show Completed'}
              </Accordion.Title>
              <Accordion.Content active={showCompletedTasks}>
                <Feed>
                  {completedTaskIDs.map((taskID) => TaskFeedComponents[taskID])}
                </Feed>
              </Accordion.Content>
            </Accordion>
          )}
        </>
      ) : location !== 'client-dashboard' ? (
        <div className="none-yet">
          <small className="instructional">
            We'll email you with any updates!
          </small>
        </div>
      ) : (
        ''
      )}
    </StyledTaskList>
  )
})

const StyledTaskList = styled.div`
  div {
    &.header {
      text-align: center;
    }

    &.completed {
      padding: 0 !important;
      border: none !important;

      & > .message,
      & > button {
        @include visually-hidden;
      }
    }
  }

  & .meta {
    margin-top: 0 !important;
  }

  & .none-yet {
    text-align: center;
    max-width: 320px;
    margin: 0 auto;
    padding: 15px;
  }
`
