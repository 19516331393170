import { firebaseFunctions } from '../../config/firebaseConfig'
import dayjs from 'dayjs'
import { downloadBlob } from '../helpers'

const exportEnvelopesFunction = firebaseFunctions.httpsCallable(
  'exportEnvelopes'
)

export default async function exportEnvelopes({
  firmID,
  advisorID,
  format = 'csv',
  setExporting,
  setExportSuccess,
  setErrors,
}) {
  setExporting(true)

  try {
    const result = await exportEnvelopesFunction({
      firmID,
      advisorID,
      format,
    })

    switch (format) {
      case 'csv':
        const blob = new Blob([result.data], { type: 'text/csv' })
        downloadBlob({
          blob,
          fileName: `Bitsy_Envelopes_${dayjs().format(
            'YYYY-MM-DDTHHmmssZ[Z]'
          )}`,
          fileExt: 'csv',
        })
        break
      default:
        break
    }

    if (result.data && setExportSuccess) {
      setExportSuccess(true)
      setExporting(false)
    }

    return result.data
  } catch (err) {
    console.error(err)
    if (setErrors) {
      setErrors([err.message])
    }
    setExporting(false)
  }

  return null
}
