import React, { Component } from 'react'
// import './BitsyForm.scss'

export default class Form extends Component {
  render() {
    const {
      id,
      className,
      title,
      instructions,
      children,
      onSubmit,
    } = this.props

    var generatedClassName = 'bitsy-form'
    if (className) {
      generatedClassName += ' ' + className
    }

    return (
      <form id={id} className={generatedClassName} onSubmit={onSubmit}>
        {title && <div className="title">{title}</div>}
        {instructions && (
          <div className="instructional">
            <small>{instructions}</small>
          </div>
        )}
        {children}
      </form>
    )
  }
}
