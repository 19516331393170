import { firebaseFunctionsCustomDomain } from '../../../config/firebaseConfig'

const integrationsDocusignListDocuments = firebaseFunctionsCustomDomain.httpsCallable(
  'integrationsDocusignListDocuments'
)

export default async function listDocuments(
  envelopeID,
  setDocuments,
  firm,
  setErrors
) {
  try {
    const result = await integrationsDocusignListDocuments({
      envelopeId: envelopeID,
      firm,
    })

    setDocuments(result.data.envelopeDocuments)

    return result.data
  } catch (err) {
    console.error(err)
    setErrors([err])
  }

  return null
}
