import React, { memo } from 'react'
import { Button } from 'semantic-ui-react'
import { useTemplates } from '../../../contexts'
import ExecutedContractsModule from './ExecutedContractsModule'
import styled from 'styled-components'

const TemplateFormExecutedContractsModule = memo(
  ({
    clientID,
    templateID,
    template,
    setEditingTemplate,
    wrapperClassName,
    hideIfEmpty = false,
  }) => {
    return (
      <ExecutedContractsModule
        type={templateID}
        title={`${template.title}s`}
        clientID={clientID}
        hideIfEmpty={template.inactive || hideIfEmpty}
        wrapperClassName={wrapperClassName}
        template={template}
        templateID={templateID}
      >
        {setEditingTemplate && (
          <StyledButton
            primary
            floated="right"
            onClick={() => setEditingTemplate({ templateID, template })}
          >
            Add/Edit
          </StyledButton>
        )}
      </ExecutedContractsModule>
    )
  }
)

const TemplateFormsExecutedContractsModules = memo(
  ({ clientID, setEditingTemplate, wrapperClassName, hideIfEmpty = false }) => {
    const { templates } = useTemplates()

    if (
      !templates ||
      !Object.keys(templates) ||
      !Object.keys(templates).length
    ) {
      return ''
    }

    return Object.keys(templates).map((templateID) => (
      <TemplateFormExecutedContractsModule
        key={templateID}
        clientID={clientID}
        templateID={templateID}
        template={templates[templateID]}
        setEditingTemplate={setEditingTemplate}
        wrapperClassName={wrapperClassName}
        hideIfEmpty={hideIfEmpty}
      />
    ))
  }
)

export default TemplateFormsExecutedContractsModules

const StyledButton = styled(Button)`
  &&& {
    margin-top: 30px;
  }
`
