import React, { memo, useState } from 'react'
import { Button, Confirm, TextArea } from 'semantic-ui-react'
import styled from 'styled-components'
import { colors } from 'styles'
import updateEnvelope from 'actions/db/envelopes/update'
import { useUser } from 'contexts'

const ManualFinalizeOptions = memo(({ envelope, envelopeID }) => {
  const { userType } = useUser()
  const [openConfirmModal, setOpenConfirmModal] = useState()
  const [voidReason, setVoidReason] = useState('')

  const completedPlaceholder = `Manually marked as completed by ${
    userType === 'firm-admin' ? 'firm' : userType
  }`
  const cancelledPlaceholder = `Cancelled by ${
    userType === 'firm-admin' ? 'firm' : userType
  }`

  const handleComplete = async () => {
    const completeRes = await updateEnvelope({
      envelopeID,
      envelope: {
        status: 'Completed',
        voidReason: voidReason || completedPlaceholder,
      },
    })

    if (completeRes) {
      setOpenConfirmModal(null)
    }
  }

  const handleCancel = async () => {
    const cancelRes = await updateEnvelope({
      envelopeID,
      envelope: {
        status: 'Cancelled',
        voidReason: voidReason || cancelledPlaceholder,
      },
    })

    if (cancelRes) {
      setOpenConfirmModal(null)
    }
  }

  return (
    <>
      <ButtonGroup size="tiny" compact>
        <Button
          content="Complete"
          icon="check"
          positive
          onClick={() => setOpenConfirmModal('complete')}
          basic
        />
        <Button
          content="Cancel"
          icon="x"
          negative
          onClick={() => setOpenConfirmModal('cancel')}
          basic
        />
      </ButtonGroup>

      <CompleteConfirm
        open={'complete' === openConfirmModal}
        content={
          <ConfirmContents>
            <p>
              <strong>
                Are you sure you want to mark this document as completed?
              </strong>
            </p>
            <p>
              If so, you can enter additional details here. Please note that
              these details will be included in an email to all signing parties
              to notify that the document has been marked as complete.
            </p>
            <p>
              <TextArea
                placeholder={completedPlaceholder}
                onChange={(e, { value }) => setVoidReason(value)}
              />
            </p>
          </ConfirmContents>
        }
        confirmButton="Yes, this has been completed."
        onConfirm={handleComplete}
        cancelButton="Go back"
        onCancel={() => setOpenConfirmModal(null)}
      />

      <CancelConfirm
        open={'cancel' === openConfirmModal}
        content={
          <ConfirmContents>
            <p>
              <strong>Are you sure you want to cancel this document?</strong>
            </p>
            <p>
              If so, you can enter additional details here. Please note that
              these details will be included in an email to all signing parties
              to notify that the document has been cancelled.
            </p>
            <p>
              <TextArea
                placeholder={cancelledPlaceholder}
                onChange={(e, { value }) => setVoidReason(value)}
              />
            </p>
          </ConfirmContents>
        }
        confirmButton="Yes, this is no longer relevant."
        onConfirm={handleCancel}
        cancelButton="Go back"
        onCancel={() => setOpenConfirmModal(null)}
      />
    </>
  )
})

export default ManualFinalizeOptions

const ButtonGroup = styled(Button.Group)`
  &&& {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    @media (max-width: 1406px) and (min-width: 1200px) {
      & > * {
        border-radius: 0.28571429rem;
        margin-bottom: 0.28571429rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @media (max-width: 702px) and (min-width: 576px) {
      & > * {
        border-radius: 0.28571429rem;
        margin-bottom: 0.28571429rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @media (max-width: 388px) {
      & > * {
        border-radius: 0.28571429rem;
        margin-bottom: 0.28571429rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`

const CompleteConfirm = styled(Confirm)`
  &&& {
    .actions .ui.primary {
      background: ${colors.positive};

      &:hover {
        background: ${colors.positiveHover};
      }
    }
  }
`

const CancelConfirm = styled(Confirm)`
  &&& {
    .actions .ui.primary {
      background: ${colors.negative};

      &:hover {
        background: ${colors.negativeHover};
      }
    }
  }
`

const ConfirmContents = styled.div`
  padding: 30px;
`
