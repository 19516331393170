import React, { Component } from 'react'

class CheckIcon extends Component {
  render() {
    return (
      <svg
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 14 L5 10 L13 18 L27 4 L31 8 L13 26 z" />
      </svg>
    )
  }
}

export default CheckIcon
