import { firebaseDB, prospectDB } from '../../config/firebaseConfig'

export function addTaskToDB(
  worker,
  performAt,
  options = {},
  state = null,
  source = null,
  setSuccess = null
) {
  var db = firebaseDB
  if (source === 'prospect') {
    db = prospectDB
  }

  const task = {
    status: 'scheduled',
    worker: worker,
    performAt: performAt,
    options: options,
  }

  return db
    .collection('tasks')
    .add(task)
    .then((docRef) => {
      // state.setState({
      //   addSuccess: true
      // })
      // console.log("Task added with ID: ", docRef.id)

      if (setSuccess) {
        setSuccess(true)
      }

      return docRef.id
    })
    .catch((error) => {
      // console.error("Error adding task: ", error)

      if (state) {
        state.setState({
          errors: ['Error adding task: ' + error + '. Please contact Bitsy.'],
        })
      }

      if (setSuccess) {
        setSuccess(false)
      }

      return null
    })
}
