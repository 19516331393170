import React, { memo, useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Checkbox } from 'semantic-ui-react'
import Table from 'components/partials/Table'
import ProfileLinkButton from '../ProfileLinkButton'
import { formatFirestoreDateString } from 'actions/db/_utils'
import { formatCompositeInvestorNames } from 'actions/helpers'
import { useUser, usePeople } from 'contexts'
import styled from 'styled-components'
import { colors } from 'styles'
import { getEnv } from 'helpers/_utils'

const PeopleTable = memo(({ type, pageSize }) => {
  const { userType } = useUser()
  const { clients, advisors } = usePeople()

  const [showProductionClients, setShowProductionClients] = useState(false)

  const sortType = useMemo(
    () => (rowA, rowB, columnId) => {
      const a = rowA.values[columnId]
      const b = rowB.values[columnId]
      return new Date(a) > new Date(b) ? 1 : -1
    },
    []
  )

  const columns = useMemo(() => {
    const preppedColumns = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (d) => {
          const row = d.cell.row.original
          const { type, id, name } = row

          return (
            <StyledProfileLinkButton
              content={name}
              profile={{
                type,
                id,
                content: row,
              }}
            />
          )
        },
      },
    ]

    if (type === 'clients') {
      preppedColumns.push({
        Header: 'Email',
        accessor: 'email',
        Cell: (d) => {
          const row = d.cell.row.original
          const { email } = row

          return <small>{email}</small>
        },
      })
      preppedColumns.push({
        Header: 'Onboarding Date',
        accessor: 'onboardingDate',
        headerClassName: 'text-center',
        className: 'text-center',
        sortType,
      })

      if (userType !== 'advisor') {
        preppedColumns.push({
          Header: 'Servicing Advisor',
          accessor: 'advisor_name',
          headerClassName: 'text-center',
          className: 'text-center',
        })
      }
    }

    preppedColumns.push({
      Header: 'Details',
      Cell: (d) => {
        const row = d.cell.row.original
        const { type, id } = row

        return (
          <ViewDetailsProfileLinkButton
            content="View Details"
            profile={{
              type,
              id,
              content: row,
            }}
          />
        )
      },
      headerClassName: 'text-center',
      className: 'text-center',
    })

    return preppedColumns
  }, [sortType, type, userType])

  const data = useMemo(() => {
    let preppedData = []
    switch (type) {
      case 'advisors':
        if (advisors && Object.keys(advisors) && Object.keys(advisors).length) {
          preppedData = Object.keys(advisors).map((advisorID) => {
            const advisor = advisors[advisorID]

            return {
              ...advisor,
              type: 'advisor',
              id: advisorID,
            }
          })
        }
        break
      case 'clients':
        if (clients && Object.keys(clients) && Object.keys(clients).length) {
          preppedData = Object.keys(clients).flatMap((clientID) => {
            const client = clients[clientID]

            if (
              getEnv() !== 'prod' &&
              !showProductionClients &&
              client.env === 'prod'
            ) {
              return []
            }

            return {
              ...client,
              type: 'client',
              id: clientID,
              name: formatCompositeInvestorNames({ client }),
              onboardingDate: client.dateAdded
                ? formatFirestoreDateString(client.dateAdded)
                : '',
              advisor_name:
                advisors && advisors[client.advisor]
                  ? advisors[client.advisor].name
                  : client.advisor,
            }
          })
        }
        break
      default:
        preppedData = []
        break
    }

    return preppedData
  }, [advisors, clients, type, showProductionClients])

  return (
    <>
      {getEnv() !== 'prod' && type === 'clients' && (
        <FilterSettings>
          <Checkbox
            label="Show production clients"
            checked={showProductionClients}
            onClick={() =>
              setShowProductionClients(
                (prevShowProductionClients) => !prevShowProductionClients
              )
            }
            data-tip="Any changes to production clients that are made here will affect production!"
          />
          <ReactTooltip place="right" type="error" effect="solid" />
        </FilterSettings>
      )}
      <Table
        columns={columns}
        data={data}
        sortBy={
          type === 'clients'
            ? [{ id: 'dateAdded', desc: true }]
            : [{ id: 'name', desc: false }]
        }
        pageSize={pageSize}
        noRowsFoundText={`You don't have any ${type} yet...`}
      />
    </>
  )
})

export default PeopleTable

const FilterSettings = styled.div`
  text-align: right;
`

const StyledProfileLinkButton = styled(ProfileLinkButton)`
  &&& {
    font-weight: bold;
  }
`

const ViewDetailsProfileLinkButton = styled(ProfileLinkButton)`
  &&& {
    border-radius: 15px;
    background-color: #e9f1f0;
    color: ${colors.primary};
    font-size: 13px;
    line-height: 17px;
    padding: 5px 16px;
    border-color: #e9f1f0;
    white-space: nowrap;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      color: #fff;
      background-color: ${colors.primary};
      border-color: ${colors.primary};
    }
  }
`
