import React, { Component } from 'react'
// import { PDFExport } from '@progress/kendo-react-pdf'
import Table from '../../../partials/LegacyTable'
import FirmLogo from '../../../partials/FirmLogo'
import FeeSchedule from '../../../partials/FeeSchedule'
import InitialsField from '../../../forms/InitialsField'
import NextLink from '../../../partials/NextLink'
// import '../../../../styles/iac.scss'
import DocumentSubmit from '../../partials/DocumentSubmit'
import { formatAddress } from '../../../../actions/helpers'
import {
  calculateAcceptableLoss,
  calculateROR,
  calculateTimeHorizon,
  calculateTotalAdvisoryFee,
  currentDate,
  generateInvestmentProfileItems,
} from '../../../../actions/iacHelpers'
import SignatureRow from '../../partials/SignatureRow'
import './CentennialIAC.scss'

class CentennialIAC extends Component {
  constructor(props) {
    super(props)
    this.state = {
      advisor: this.props.advisor,
      updateDate: '7/29/2019',
      clientSig1: false,
      secondSig1: false,
      clientSig2: false,
      secondSig2: false,
    }

    this.handleSignatureConfirm = this.handleSignatureConfirm.bind(this)
    this.handleSignatureRetry = this.handleSignatureRetry.bind(this)
  }

  clientSig1 = {}
  secondSig1 = {}
  clientSig2 = {}
  secondSig2 = {}

  handleSignatureConfirm(event, ref, refName) {
    event.preventDefault()

    const { doc } = this.props

    var image = null

    if (!doc.signatures[refName]) {
      image = ref.getCanvas().toDataURL('image/png')
    }

    this.props.handleSignatureConfirm(refName, image)
  }

  handleSignatureRetry(event, ref, refName) {
    event.preventDefault()

    if (this[refName]) {
      this[refName].clear()
    }

    this.props.handleSignatureRetry(refName)
  }

  render() {
    const {
      type,
      firm,
      advisor,
      client,
      client_name,
      doc,
      errors,
      handleIACSubmit,
      handleInputChange,
      nextLinkRef,
      handleKeyPress,
      handleSignatureConfirm,
      handleSignatureRetry,
    } = this.props
    const { updateDate } = this.state

    this.currentDate = <div className="value">{currentDate()}</div>

    var custodian = firm.custodian

    if (
      client &&
      client.jurisdiction &&
      client.jurisdiction === 'Non-Discretionary' &&
      client.custodian
    ) {
      custodian = client.custodian
    }

    if (
      firm.account_minimum &&
      firm.account_minimum !== '0' &&
      firm.account_minimum !== '$0'
    ) {
      this.account_minimum_text =
        'There is an account minimum of <strong>' +
        firm.account_minimum +
        '</strong>.'
    } else {
      this.account_minimum_text = 'There is no account minimum.'
    }

    this.risk_tolerance_boxes = client.investment_profiles.map(
      (investment_profile, idx) => (
        <div key={idx} className="risk-tolerance-box">
          <h6>{investment_profile.account}</h6>
          <ul className="inline">
            <li>
              <div className="label">
                Acceptable Percentage of Principal Investment Loss in a Short
                Term Period:
              </div>
              <div className="value">
                {calculateAcceptableLoss(investment_profile.profile)}
              </div>
            </li>
            <li>
              <div className="label">Target Rate of Return:</div>
              <div className="value">
                {calculateROR(investment_profile.profile)}
              </div>
            </li>
          </ul>

          <div className="investment-profile">
            <span className="label">Account Type: </span>
            <span className="value">{investment_profile.profile}</span>
          </div>
        </div>
      )
    )

    this.third_party_fee_rows = []
    if (client.third_party_fees && client.third_party_fees.length) {
      client.third_party_fees.map((row) => {
        this.third_party_fee_rows.push({
          header: row.name,
          content: row.fee,
        })

        return row
      })
    }

    this.next_link = ''
    if (type === 'send') {
      this.next_link = 'iac-submit'
    } else if (doc.initials.services_1_client.length < 1) {
      this.next_link = 'services_1_client'
    } else if (
      doc.initials.services_1_client &&
      client.second_investor_legal_name.length &&
      doc.initials.services_1_second.length < 1
    ) {
      this.next_link = 'services_1_second'
    } else if (doc.initials.services_2_client.length < 1) {
      this.next_link = 'services_2_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.services_2_second.length < 1
    ) {
      this.next_link = 'services_2_second'
    } else if (doc.initials.services_3_client.length < 1) {
      this.next_link = 'services_3_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.services_3_second.length < 1
    ) {
      this.next_link = 'services_3_second'
    } else if (doc.initials.services_4_client.length < 1) {
      this.next_link = 'services_4_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.services_4_second.length < 1
    ) {
      this.next_link = 'services_4_second'
    } else if (
      client.jurisdiction === 'Discretionary' &&
      doc.initials.discretionary_client.length < 1
    ) {
      this.next_link = 'discretionary_client'
    } else if (
      client.second_investor_legal_name.length &&
      client.jurisdiction === 'Discretionary' &&
      doc.initials.discretionary_second.length < 1
    ) {
      this.next_link = 'discretionary_second'
    } else if (
      client.jurisdiction === 'Non-Discretionary' &&
      doc.initials.nondiscretionary_client.length < 1
    ) {
      this.next_link = 'nondiscretionary_client'
    } else if (
      client.second_investor_legal_name.length &&
      client.jurisdiction === 'Non-Discretionary' &&
      doc.initials.nondiscretionary_second.length < 1
    ) {
      this.next_link = 'nondiscretionary_second'
    } else if (doc.initials.adv_receipt_client.length < 1) {
      this.next_link = 'adv_receipt_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.adv_receipt_second.length < 1
    ) {
      this.next_link = 'adv_receipt_second'
    } else if (doc.signatures.client_1_text.length < 1) {
      this.next_link = 'client_1_text'
    } else if (
      client.second_investor_legal_name.length &&
      doc.signatures.second_1_text.length < 1
    ) {
      this.next_link = 'second_1_text'
    } else if (doc.initials.authorized_withdrawal_client.length < 1) {
      this.next_link = 'authorized_withdrawal_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.authorized_withdrawal_second.length < 1
    ) {
      this.next_link = 'authorized_withdrawal_second'
    } else if (doc.initials.fee_schedule_client.length < 1) {
      this.next_link = 'fee_schedule_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.fee_schedule_second.length < 1
    ) {
      this.next_link = 'fee_schedule_second'
    } else if (doc.initials.advisory_fee_client.length < 1) {
      this.next_link = 'advisory_fee_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.advisory_fee_second.length < 1
    ) {
      this.next_link = 'advisory_fee_second'
    } else if (doc.signatures.client_2_text.length < 1) {
      this.next_link = 'client_2_text'
    } else if (
      client.second_investor_legal_name.length &&
      doc.signatures.second_2_text.length < 1
    ) {
      this.next_link = 'second_2_text'
    } else if (doc.signatures.client_3_text.length < 1) {
      this.next_link = 'client_3_text'
    } else if (
      client.second_investor_legal_name.length &&
      doc.signatures.second_3_text.length < 1
    ) {
      this.next_link = 'second_3_text'
    } else {
      this.next_link = 'iac-submit'
    }

    if (firm && advisor && client) {
      return (
        <div className="document-container iac-container">
          <NextLink next_link={this.next_link} nextLinkRef={nextLinkRef} />
          {/* 
          <PDFExport paperSize={'Letter'}
            fileName={firm.name + ' IAC - ' + client_name + '.pdf'}
            title={firm.name + ' IAC - ' + client_name}
            author={advisor.name}
            creator={firm.name}
            subject=""
            keywords=""
            margin="2cm"
            scale={0.6}
            ref={iacRef}> */}

          <div id="iac" className="document iac centennial-iac">
            <div className="page cover">
              {firm.logo ? <FirmLogo firm={firm} /> : ''}

              <h1>{firm.legal_name}</h1>
              <h2>Investment Advisory Contract</h2>

              <div className="client-info">
                <h3>Contract for:</h3>
                <div className="name">{client_name}</div>
                <div className="address">
                  <span>{client.address_street}</span>
                  <span>
                    {client.address_city}, {client.address_state}{' '}
                    {client.address_zip}
                  </span>
                </div>
              </div>

              <div className="update-date">{updateDate}</div>
            </div>

            <div className="page-break"></div>
            <div className="contract">
              <h3>Investment Agreement</h3>

              <p>
                The undersigned ("Client"), being duly authorized, has
                established an account (the "Account") and hereby agrees to
                engage {firm.legal_name} ("CA") on the following terms and
                conditions.
              </p>

              <ol className="upper-roman terms">
                <li>
                  <h4>Appointment of CA</h4>
                  <p>
                    Client hereby appoints CA as investment advisor for the
                    Account. Client agrees to promptly notify CA in writing of
                    any changes to the information contained on the Investment
                    Policy Statement or other information pertinent to the
                    Account and to provide CA with prior written notice of any
                    changes in the identity of persons authorized to act on
                    behalf of Client with respect to the Account.
                  </p>
                </li>
                <li>
                  <h4>Services by IA.</h4>
                  <p>
                    By execution of this Agreement, CA hereby accepts the
                    appointment as investment advisor for the Account and
                    agrees, as of the effective date set forth in the signature
                    page below, to provide the services indicated below:
                  </p>
                  <ol className="lower-alpha-parens">
                    <li>
                      supervise and direct the investments of the Account in
                      accordance with the investment objectives of Client
                      <InitialsField
                        signer="First Investor"
                        required={true}
                        id="services_1_client"
                        value={doc.initials.services_1_client}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                      <InitialsField
                        signer="Second Investor"
                        required={client.second_investor_legal_name.length}
                        id="services_1_second"
                        value={doc.initials.services_1_second}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                    </li>
                    <li>
                      appraise and review investments of the Account
                      <InitialsField
                        signer="First Investor"
                        required={true}
                        id="services_2_client"
                        value={doc.initials.services_2_client}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                      <InitialsField
                        signer="Second Investor"
                        required={client.second_investor_legal_name.length}
                        id="services_2_second"
                        value={doc.initials.services_2_second}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                    </li>
                    <li>
                      recommend and select third-party investment advisors in
                      accordance with the investment objectives of Client
                      <InitialsField
                        signer="First Investor"
                        required={true}
                        id="services_3_client"
                        value={doc.initials.services_3_client}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                      <InitialsField
                        signer="Second Investor"
                        required={client.second_investor_legal_name.length}
                        id="services_3_second"
                        value={doc.initials.services_3_second}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                    </li>
                    <li>
                      monitor the investments of the Account managed by other
                      third-party investment advisors
                      <InitialsField
                        signer="First Investor"
                        required={true}
                        id="services_4_client"
                        value={doc.initials.services_4_client}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                      <InitialsField
                        signer="Second Investor"
                        required={client.second_investor_legal_name.length}
                        id="services_4_second"
                        value={doc.initials.services_4_second}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                    </li>
                  </ol>
                  <p>
                    It is understood and agreed that CA, in the maintenance of
                    records for its own purposes, or in making such records or
                    the information contained therein available to Client or any
                    other person at the direction of Client, does not assume
                    responsibility for the accuracy of information furnished by
                    Client or any other person.
                  </p>
                </li>
                <li>
                  <h4>Authority.</h4>
                  <div className="management-options">
                    <h5>(Discretionary Investment Management)</h5>
                    <p>
                      Except as otherwise set forth in this Agreement, Client
                      authorizes CA to investigate, purchase, and sell on behalf
                      of Client, various securities and investments. CA is
                      authorized to execute purchases and sales of securities on
                      Client's behalf without consulting Client regarding each
                      sale or purchase. Client may, however, terminate the
                      discretionary authority of CA immediately upon written
                      notice.
                    </p>
                    <InitialsField
                      signer="First Investor"
                      required={client.jurisdiction === 'Discretionary'}
                      id="discretionary_client"
                      value={doc.initials.discretionary_client}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    <InitialsField
                      signer="Second Investor"
                      required={
                        client.second_investor_legal_name.length &&
                        client.jurisdiction === 'Discretionary'
                      }
                      id="discretionary_second"
                      value={doc.initials.discretionary_second}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />

                    <p className="uppercase">
                      <strong>or</strong>
                    </p>

                    <h5>(Non-Discretionary Investment Management)</h5>
                    <p>
                      CA is authorized to execute purchases and sales of
                      securities only after securing permission from Client
                      regarding each transaction.
                    </p>
                    <InitialsField
                      signer="First Investor"
                      required={client.jurisdiction === 'Non-Discretionary'}
                      id="nondiscretionary_client"
                      value={doc.initials.nondiscretionary_client}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    <InitialsField
                      signer="Second Investor"
                      required={
                        client.second_investor_legal_name.length &&
                        client.jurisdiction === 'Non-Discretionary'
                      }
                      id="nondiscretionary_second"
                      value={doc.initials.nondiscretionary_second}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                  </div>
                </li>
                <li>
                  <h4>Client Accounts.</h4>
                  <p>
                    Client has opened or will open an account with a custodian
                    or other authorized third party (the "Custodian") for the
                    execution of securities transactions and custodial services.
                    The Custodian at the time this Agreement is executed is
                    identified in Exhibit III hereto. All funds/securities will
                    be delivered between Client and the Custodian only. Client
                    hereby authorizes CA to receive from the Custodian a copy of
                    any agreement between Client and the Custodian in effect at
                    any time with respect to the Account. If the identity of
                    Client's Custodian changes, then Client will provide CA with
                    prompt, written notice of the change. If Client elects to
                    use a custodian other than the custodian suggested by CA,
                    then CA may not be able to negotiate the best commission
                    rates.
                  </p>
                </li>
                <li>
                  <div className="page-break"></div>
                  <h4>Service to Other Clients.</h4>
                  <p>
                    It is understood that CA may perform investment advisory
                    services for various clients and that the services provided
                    by CA are rendered on a non-exclusive basis. Client agrees
                    that CA may give advice and take action in the performance
                    of its duties with respect to any of its other clients which
                    may differ with the advice given or action taken with
                    respect to the Account. Nothing in this Agreement shall be
                    deemed to confer upon CA any obligation to acquire for the
                    Account a position in any security which CA, its principals,
                    or its employees may acquire for its or their own accounts
                    or for the account of any other client, if in the sole and
                    absolute discretion of CA it is not for any reason practical
                    or desirable to acquire a position in such security for the
                    Account.
                  </p>
                </li>
                <li>
                  <h4>Inside Information.</h4>
                  <p>
                    CA shall have no obligation to seek to obtain any material
                    nonpublic ("inside") information about any issuer of
                    securities and shall not purchase, sell, or recommend for
                    the Account the securities of any issuer on the basis of any
                    such information as may come into its possession.
                  </p>
                </li>
                <li>
                  <h4>Liability.</h4>
                  <p>
                    CA shall not be liable to Client for any independent acts or
                    omissions by third parties. A person who is not a party to
                    this Agreement has no rights to enforce any term of this
                    Agreement and this Agreement shall not be deemed to create
                    any third-party beneficiary rights.
                  </p>
                </li>
                <li>
                  <h4>Proxies.</h4>
                  <p>
                    CA will not ask for, nor accept voting authority for client
                    securities. Clients will receive proxies directly from the
                    issuer of the security or the custodian. Clients should
                    direct all proxy questions to the issuer of the security.
                  </p>
                </li>
                <li>
                  <h4>Fees.</h4>
                  <p>
                    The compensation of CA for its services rendered hereunder
                    shall be calculated in accordance with the Schedule of Fees
                    attached hereto as Exhibit II. Client shall be given thirty
                    (30) days' prior written notice of any proposed increase in
                    fees. Any increase in fees shall be accompanied by an
                    amendment or the execution of a new contract, with
                    signatures from both parties evidencing acceptance of the
                    new fees.
                  </p>
                </li>
                <li>
                  <h4>Valuation.</h4>
                  <p>
                    In computing the market value of any investment of the
                    Account, the securities in the Account listed on a national
                    securities exchange or otherwise subject to current
                    last-sale reporting shall be valued at the amount reported
                    on the statement that Client receives from the Custodian.
                    Such securities which are not traded nor subject to
                    last-sale reporting shall be valued at the latest available
                    bid price reflected by quotations furnished to CA by such
                    sources as it may deem appropriate. Any other security shall
                    be valued in such manner as shall be determined in good
                    faith by CA and Client to reflect its fair market value.
                  </p>
                </li>
                <li>
                  <h4>Representations by Client.</h4>
                  <p>
                    The execution and delivery of this Agreement by Client shall
                    constitute the representations by Client that the terms
                    hereof do not violate any obligation by which Client is
                    bound, whether arising by contract, operation of law or
                    otherwise; that if Client is an entity other than a natural
                    person (a) this Agreement has been duly authorized by
                    appropriate action and is binding upon Client in accordance
                    with its terms and (b) Client will deliver to CA such
                    evidence of such authority as CA may reasonably require,
                    whether by way of a certified corporate resolution or
                    otherwise; CA is responsible only for the Account and not
                    for the diversification or prudent investment of any outside
                    assets or holdings of Client.
                  </p>

                  <p className="italic">
                    The following language of this section applies only if your
                    Account is for a (a) pension or other employee benefit plan
                    (including a 401(k) plan) governed by the Employee
                    Retirement Income Security Act of 1974, as amended
                    ("ERISA"); (b) tax-qualified retirement plan under section
                    401(a) of the Internal Revenue Code of 1986, as amended (the
                    "Code"), and not covered by ERISA; or (c) an individual
                    retirement account under the Code.
                  </p>

                  <p>
                    Client represents that CA has been furnished true and
                    complete copies of all documents establishing and governing
                    the plan and evidencing Client authority to retain CA.
                    Client acknowledges that Client is a "named fiduciary" with
                    respect to the control or management of the assets in the
                    Account. Client will furnish promptly to CA the governing
                    plan documents, any amendment to the plan, and Client agrees
                    that, if any amendment affects CA's rights or obligations,
                    then the amendment will be binding on CA only when agreed to
                    by CA in writing. If the Account contains only a part of the
                    assets of the plan, then Client understands that CA will
                    have no responsibility for the diversification of all of the
                    plan's investments and that CA will have no duty,
                    responsibility, or liability for Client assets that are not
                    in the Account. If the Employee Retirement Income Security
                    Act of 1974, as amended ("ERISA") or other applicable law
                    requires bonding with respect to the assets in the Account,
                    then upon written request by CA, Client will obtain and
                    maintain at Client expense bonding that satisfies the
                    requirements of Section 412 of ERISA and covers CA and
                    affiliated persons of CA.
                  </p>
                </li>
                <li>
                  <h4>Representations by CA.</h4>
                  <p>
                    By execution of this Agreement, CA represents and confirms
                    that it is registered as an investment advisor or exempt
                    from registration pursuant to applicable laws and
                    regulations.
                  </p>
                </li>
                <li>
                  <h4>Amendment; Termination.</h4>
                  <p>
                    This Agreement contains the entire agreement between the
                    parties, may not be modified or amended except in writing as
                    executed by both parties, and remains in force and effect
                    unless terminated by either party as discussed herein.
                    Client may terminate the Agreement within five (5) business
                    days of signing the Agreement, without penalty or fee.
                    Thereafter, this Agreement shall continue in effect until
                    terminated by either party by giving to the other party
                    written notice.
                  </p>
                </li>
                <li>
                  <h4>Notices.</h4>
                  <p>
                    All notices and other communications contemplated by this
                    Agreement shall be deemed duly given if transmitted to CA at
                    the address set forth on the cover page of this Agreement to
                    the attention of its Chief Compliance Officer, and to Client
                    at the address appearing below, or at such other address or
                    addresses as shall be specified, in each case, in a written
                    notice similarly given.
                  </p>
                </li>
                <li>
                  <h4>Governing Law.</h4>
                  <p>
                    The validity of this Agreement and the rights and
                    liabilities of the parties hereunder shall be determined in
                    accordance with the laws of the state in which Client
                    resides except to the extent preempted by ERISA or other
                    federal or state laws or regulations.
                  </p>
                </li>
                <li>
                  <h4>Exhibits.</h4>
                  <p>
                    The following Exhibits are attached hereto and incorporated
                    as part of this Agreement:
                  </p>

                  <ol className="exhibit-upper-roman">
                    <li>Identification of Accounts</li>
                    <li>Schedule of Fees</li>
                    <li>Identification of Custodian</li>
                    <li>Third Party Disclosure</li>
                  </ol>
                </li>
                <li>
                  <h4>Receipt.</h4>
                  <p>
                    Client acknowledges receipt of CA's Privacy Policy
                    Statement, together with Parts 2A and 2B of Form ADV; a
                    disclosure statement containing the equivalent information;
                    or a disclosure statement containing at least the
                    information required by Part 2A Appendix 1 of Form ADV, if
                    Client is entering into a wrap fee program sponsored by the
                    investment advisor. If the appropriate disclosures statement
                    was not delivered to Client at least 48 hours prior to
                    Client entering into any written or oral advisory contract
                    with this investment advisor, then Client has the right to
                    terminate the contract without penalty within five (5)
                    business days after entering into the contract. For the
                    purposes of this provision, a contract is considered entered
                    into when all parties to the contract have signed the
                    contract, or, in the case of an oral contract, otherwise
                    signified their acceptance, any other provisions of this
                    contract notwithstanding.
                  </p>
                  <InitialsField
                    signer="First Investor"
                    required={true}
                    id="adv_receipt_client"
                    value={doc.initials.adv_receipt_client}
                    handleInputChange={handleInputChange}
                    handleKeyPress={handleKeyPress}
                  />
                  <InitialsField
                    signer="Second Investor"
                    required={client.second_investor_legal_name.length}
                    id="adv_receipt_second"
                    value={doc.initials.adv_receipt_second}
                    handleInputChange={handleInputChange}
                    handleKeyPress={handleKeyPress}
                  />
                </li>
                <li>
                  <h4>Consent to Electronic Delivery.</h4>
                  <p>
                    Client hereby consents to receive via e-mail or other
                    electronic delivery method for various communications,
                    documents, and notifications from CA. These items may
                    include but are not limited to: all statements or reports
                    produced by CA; trade confirmations; billing invoices; all
                    Form ADV brochures; privacy policy statements; and any other
                    notices or documentation that CA chooses to provide on an
                    ongoing or occasional basis. Client agrees to immediately
                    notify CA of any changes to Client's e-mail address shown
                    below or other electronic delivery address.
                  </p>
                </li>
                <li>
                  <h4>Assignment.</h4>
                  <p>
                    No assignment of this Agreement may be made by any party to
                    this Agreement without the prior written consent of the
                    other party hereto. Subject to the foregoing, this Agreement
                    shall inure to the benefit and be binding upon the parties
                    hereto, and each of their respective successors and
                    permitted assigns.
                  </p>
                </li>
                <li>
                  <h4>Confidential Relationship.</h4>
                  <p>
                    All information and advice furnished by either party to the
                    other shall be treated as confidential and shall not be
                    disclosed to third parties except as required by law and as
                    described in CA's Privacy Policy Statement.
                  </p>
                </li>
                <li>
                  <h4>Death or Disability.</h4>
                  <p>
                    If Client is a natural person, then Client's death,
                    incapacity, disability, or incompetence will not terminate
                    or change the terms of this Agreement. However, Client's
                    guardian, executor, attorney-in-fact, or other authorized
                    representative may terminate this Agreement by giving CA
                    written notice in accordance with the termination provisions
                    of this Agreement.
                  </p>
                </li>
                <li>
                  <h4>Title to Assets.</h4>
                  <p>
                    Except to the extent Client has notified, or in the future
                    notifies, CA in writing, Client represents that assets in
                    the Account belong to Client free and clear of any lien or
                    encumbrances.
                  </p>
                </li>
                <li>
                  <h4>Market Conditions.</h4>
                  <p>
                    Client acknowledges that CA's past performance and advice
                    regarding client accounts cannot guarantee future results.{' '}
                    <strong>
                      <em>
                        <span className="red">
                          AS WITH ALL MARKET INVESTMENTS, CLIENT INVESTMENTS CAN
                          APPRECIATE OR DEPRECIATE.
                        </span>
                      </em>
                    </strong>{' '}
                    CA does not guarantee or warrant that services offered will
                    result in profit.
                  </p>
                </li>
              </ol>

              <div className="page-break"></div>
              <div className="signatures">
                <p>
                  IN WITNESS THEREOF, the parties have executed this Agreement
                  on the date stated below.
                </p>

                <ul className="parties">
                  <li>
                    <SignatureRow
                      title="Client Signature"
                      id="client_sig"
                      signerType="Client"
                      description={client.legal_name + ', First Investor'}
                      signature={doc.signatures.client_sig}
                      textSignature={{
                        id: 'client_1_text',
                        value: doc.signatures.client_1_text,
                        label: '(or) Print Name as your Signature:',
                        placeholder: 'Print Name',
                      }}
                      handleInputChange={handleInputChange}
                      handleSignatureConfirm={handleSignatureConfirm}
                      handleSignatureRetry={handleSignatureRetry}
                      allowEdit={type !== 'send'}
                      signatureType={firm.signatureType}
                    />
                  </li>
                  <li>
                    <SignatureRow
                      title="Second Signature"
                      id="second_sig"
                      signerType="Client"
                      description={
                        client.second_investor_legal_name.length
                          ? client.second_investor_legal_name +
                            ', Second Investor'
                          : 'Second Investor'
                      }
                      signature={doc.signatures.second_sig}
                      textSignature={{
                        id: 'second_1_text',
                        value: doc.signatures.second_1_text,
                        label: '(or) Print Name as your Signature:',
                        placeholder: 'Print Name',
                      }}
                      handleInputChange={handleInputChange}
                      handleSignatureConfirm={handleSignatureConfirm}
                      handleSignatureRetry={handleSignatureRetry}
                      allowEdit={type !== 'send'}
                      signatureType={firm.signatureType}
                    />
                  </li>
                  <li>
                    <SignatureRow
                      title={firm.legal_name}
                      id="advisor_sig"
                      signerType="Advisor"
                      description={advisor.name + ', Advisor'}
                      signature={advisor.signature}
                      // textSignature={{
                      //   id: 'second_text',
                      //   value: doc.signatures.second_text,
                      //   label: '(or) Print Name as your Signature:',
                      //   placeholder: 'Print Name'
                      // }}
                      handleInputChange={handleInputChange}
                      handleSignatureConfirm={handleSignatureConfirm}
                      handleSignatureRetry={handleSignatureRetry}
                    />
                  </li>
                </ul>

                <div className="page-break"></div>
                <div className="client-address">
                  {client_name}
                  <br />
                  {client.address_street}
                  <br />
                  {client.address_city}, {client.address_state}{' '}
                  {client.address_zip}
                  <br />
                  <br />
                  {client.phone}
                  <br />
                  {client.email}
                </div>
              </div>
            </div>

            <div className="page-break"></div>
            <div className="articles">
              <ol>
                <li>
                  <h3>Exhibit I</h3>
                  <h3>Identification of Accounts</h3>
                  <p>
                    Below are the accounts (collectively, the "Account")
                    included under this Agreement:
                  </p>

                  <Table
                    type="columns"
                    headerRow={[
                      { id: 'account_number', label: 'Account Number' },
                      { id: 'registration_name', label: 'Registration Name' },
                      { id: 'custodian', label: 'Custodian' },
                      { id: 'notes', label: 'Notes' },
                    ]}
                    rows={client.account_registrations}
                  />
                </li>
                <li className="fee-schedule">
                  <div className="page-break"></div>
                  <h3>Exhibit II</h3>
                  <h3>Fee Schedule</h3>

                  <p>
                    The following are the fees charged by {firm.legal_name} for
                    services provided:
                  </p>

                  <p className="italic">
                    CA may be compensated on the basis of a share of capital
                    gains upon or capital appreciation of the funds or any
                    portion of the funds of the Account. There is no account
                    minimum.
                  </p>

                  <h4>Portfolio Management Fees</h4>
                  <p>
                    The following fee schedule applies to managed portfolios,
                    including traditional ETFs, institutional mutual fund, and
                    limited partnership accounts.
                  </p>
                  <FeeSchedule
                    rows={
                      client.annual_fees ? client.annual_fees : firm.annual_fees
                    }
                    assetsTitle="Total Assets Under Management"
                    fixedFee={
                      client.fee_type === 'Fixed Fee' ? client.fee_fixed : null
                    }
                  />
                  <p>
                    Regardless of whether wrap fee pricing was selected on the
                    Client Agreement, accounts marked as transactional on the
                    table below will be billed on a transactional basis (this
                    means that brokerage commissions and/or transaction fees
                    will be separately charged by the custodian per transaction
                    and charged directly to Client by the custodian).
                    Transactional pricing is set by the custodian and may be
                    changed pursuant to the custodian's policies.
                  </p>

                  <Table
                    type="columns"
                    headerRow={[
                      { id: 'account', label: 'Account' },
                      { id: 'designation', label: 'Designation' },
                      {
                        id: 'fee_iar_platform',
                        label: 'IAR Fee + Platform Fee',
                      },
                      { id: 'fee_model_manager', label: 'Model Manager Fee' },
                      { id: 'fee_total_advisory', label: 'Total Advisory Fee' },
                      {
                        id: 'transactional',
                        label: 'Transactional (Yes or No)',
                      },
                    ]}
                    rows={client.account_designations}
                  />

                  <p>
                    Fees are paid monthly in arrears. CA uses an average of the
                    daily balance in the client's account throughout the billing
                    period, after taking into account deposits and withdrawals,
                    for purposes of determining the market value of the assets
                    upon which the advisory fee is based.
                  </p>

                  <p>
                    <InitialsField
                      signer="First Investor"
                      required={true}
                      id="authorized_withdrawal_client"
                      value={doc.initials.authorized_withdrawal_client}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    <InitialsField
                      signer="Second Investor"
                      required={client.second_investor_legal_name.length}
                      id="authorized_withdrawal_second"
                      value={doc.initials.authorized_withdrawal_second}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    CA is authorized to withdraw management fees directly from
                    the Account on a monthly basis.
                  </p>
                  <p className="italic">
                    <InitialsField
                      signer="First Investor"
                      required={true}
                      id="fee_schedule_client"
                      value={doc.initials.fee_schedule_client}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    <InitialsField
                      signer="Second Investor"
                      required={client.second_investor_legal_name.length}
                      id="fee_schedule_second"
                      value={doc.initials.fee_schedule_second}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    By initialing here, Client agrees to the fee schedule listed
                    above.
                  </p>

                  <h4>Selection of Other Advisor Fees</h4>
                  <p>
                    The following third-party fees are billed separately from
                    the Portfolio Management Fees listed on page 8. Details are
                    listed below.
                  </p>

                  <Table
                    title="Third Party Manager Fees"
                    rows={this.third_party_fee_rows}
                  />

                  <p className="italic">
                    <InitialsField
                      signer="First Investor"
                      required={true}
                      id="advisory_fee_client"
                      value={doc.initials.advisory_fee_client}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    <InitialsField
                      signer="Second Investor"
                      required={client.second_investor_legal_name.length}
                      id="advisory_fee_second"
                      value={doc.initials.advisory_fee_second}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    By initialing here, Client agrees to an advisory fee of:{' '}
                    <strong>{calculateTotalAdvisoryFee(client, firm)}</strong>
                  </p>
                </li>
                <li>
                  <h3>Exhibit III</h3>
                  <h3>Identification of Custodian</h3>

                  <p>Custodian or Other Authorized Third Party:</p>

                  <div className="custodian-info">
                    <div className="row">
                      <div className="label">Name:</div>
                      <div className="value">{custodian.preferred_name}</div>
                    </div>
                    <div className="row">
                      <div className="label">Mailing Address:</div>
                      <div className="value">
                        <span>{custodian.address_street}</span>
                        <span>
                          {custodian.address_city}, {custodian.address_state}{' '}
                          {custodian.address_zip}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Telephone:</div>
                      <div className="value">{custodian.phone}</div>
                    </div>
                  </div>
                  <p className="exhibit-3-disclaimer">
                    A copy of the custodian's agreement is not attached as part
                    of this Exhibit III.
                  </p>
                </li>
                <li>
                  <div className="page-break"></div>
                  <h3>Exhibit IV</h3>
                  <h3>Third Party Disclosure</h3>

                  <p>
                    <strong>WHEREAS</strong>, the undersigned client ("Client")
                    has entered into an agreement with {firm.legal_name}{' '}
                    ("Adviser") to provide investment management services for
                    client;
                  </p>
                  <p>
                    <strong>WHEREAS</strong>, Adviser has elected to utilize the
                    investment management services of{' '}
                    {firm.authorized_third_party.legal_name} ("
                    {firm.authorized_third_party.abbreviation}") and its
                    platform provider, {firm.platform_provider_legal_name} ("
                    {firm.platform_provider_name}"), to assist in the provision
                    of investment management services for client; and
                  </p>
                  <p>
                    <strong>WHEREAS</strong>, Client desires to appoint Adviser
                    as Client's agent to receive{' '}
                    {firm.authorized_third_party.abbreviation}'s Form ADV Part
                    2A, Firm Brochure or Form ADV Part 2A Brochure
                  </p>
                  <p>
                    Appendix 1, Wrap Fee Program Brochure (whichever is
                    applicable) and {firm.platform_provider_name}'s Form ADV
                    Part 2A, Firm Brochure (the "Brochure Documents") on behalf
                    of client.
                  </p>
                  <p>
                    <strong>NOW THEREFORE</strong>, Client states, affirms, and
                    acknowledges the following:
                  </p>
                  <ol>
                    <li>
                      Client hereby acknowledges that Client has entered into an
                      agreement with Adviser for the provision of discretionary
                      investment management services by Adviser on behalf of
                      Client.
                    </li>
                    <li>
                      Client hereby acknowledges that{' '}
                      {firm.authorized_third_party.abbreviation} and its
                      platform provider, {firm.platform_provider_name}, have
                      been appointed as sub-advisers to assist Adviser in the
                      provision of asset management services for Client.
                    </li>
                    <li>
                      Client hereby acknowledges that despite any provisions to
                      the contrary contained in Client's investment management
                      agreement with Adviser,{' '}
                      {firm.authorized_third_party.abbreviation} will bill all
                      fees related to any investment management services
                      provided by {firm.authorized_third_party.abbreviation} and{' '}
                      {firm.platform_provider_name} in arrears on a monthly
                      basis and calculated based on the average daily balance of
                      the account for the preceding calendar month.
                    </li>
                    <li>
                      Client hereby acknowledges receipt of the Privacy Policy
                      Notice of {firm.authorized_third_party.abbreviation} and
                      the Privacy Policy Notice of {firm.platform_provider_name}
                      .
                    </li>
                    <li>
                      Client hereby acknowledges that client has the choice of:
                      (1) directly receiving delivery of the Brochure Documents
                      of {firm.authorized_third_party.abbreviation} and{' '}
                      {firm.platform_provider_name} or (2) appointing Adviser as
                      Client's agent to receive such Brochure Documents on
                      behalf of Client.
                    </li>
                    <li>
                      Client hereby appoints Adviser as his/her agent to receive
                      delivery of the Brochure Documents of{' '}
                      {firm.authorized_third_party.abbreviation} and{' '}
                      {firm.platform_provider_name}.
                    </li>
                    <li>
                      Client hereby acknowledges that, upon Client's request,
                      Adviser will provide client with copies of any applicable
                      Brochure Documents of{' '}
                      {firm.authorized_third_party.abbreviation} and{' '}
                      {firm.platform_provider_name}.
                    </li>
                    <li>
                      Client hereby acknowledges that Client may, at any time,
                      change his/her mind and request, for no additional cost,
                      that the Brochure Documents of{' '}
                      {firm.authorized_third_party.abbreviation} and{' '}
                      {firm.platform_provider_name} be delivered to Client
                      directly.
                    </li>
                  </ol>
                </li>
              </ol>

              <div className="page-break"></div>
              <div className="signatures">
                <p>
                  <strong>ACKNOWLEDGED AND AFFIRMED BY CLIENT:</strong>
                </p>

                <ul className="parties">
                  <li>
                    <SignatureRow
                      id="client_sig"
                      signerType="Client"
                      description={client.legal_name + ', First Investor'}
                      signature={doc.signatures.client_sig}
                      textSignature={{
                        id: 'client_2_text',
                        value: doc.signatures.client_2_text,
                        label: '(or) Print Name as your Signature:',
                        placeholder: 'Print Name',
                      }}
                      handleInputChange={handleInputChange}
                      handleSignatureConfirm={handleSignatureConfirm}
                      handleSignatureRetry={handleSignatureRetry}
                      allowEdit={type !== 'send'}
                      signatureType={firm.signatureType}
                    />
                  </li>
                  <li>
                    <SignatureRow
                      id="second_sig"
                      signerType="Client"
                      description={
                        client.second_investor_legal_name.length
                          ? client.second_investor_legal_name +
                            ', Second Investor'
                          : 'Second Investor'
                      }
                      signature={doc.signatures.second_sig}
                      textSignature={{
                        id: 'second_2_text',
                        value: doc.signatures.second_2_text,
                        label: '(or) Print Name as your Signature:',
                        placeholder: 'Print Name',
                      }}
                      handleInputChange={handleInputChange}
                      handleSignatureConfirm={handleSignatureConfirm}
                      handleSignatureRetry={handleSignatureRetry}
                      allowEdit={type !== 'send'}
                      signatureType={firm.signatureType}
                    />
                  </li>
                </ul>

                {/* <ul className="parties">
                    <li>
                      <h6>Client</h6>
                      <div className="row signature-row">
                        <div className="signature" id="client_sig_1">
                          {doc.signatures.clientSig1 && doc.signatures.clientSig1.length > 1 ?
                            <img src={doc.signatures.clientSig1} alt="Client #1 Signature" />
                            :
                            <SignatureCanvas ref={(ref) => { this.clientSig1 = ref }} className='to-do' />
                          }

                          <div className="signature-buttons">
                            <button className="signatureRetry" onClick={(event) => this.handleSignatureRetry(event, this.clientSig1, 'clientSig1')}>
                              <RetryIcon />
                            </button>
                            <button className="signatureConfirm" onClick={(event) => this.handleSignatureConfirm(event, this.clientSig1, 'clientSig1')}>
                              <CheckIcon />
                            </button>
                          </div>
                          <div className="label">Client Signature</div>
                        </div>
                        <div className="date">
                          {this.currentDate}
                          <div className="label">Date</div>
                        </div>
                      </div>
                      <div className="row title">
                        <div className="value">{client.legal_name}</div>
                        <div className="label">First Investor</div>
                      </div>

                      <div className="row signature-row">
                        <div className="signature">
                          {doc.signatures.secondSig1 && doc.signatures.secondSig1.length > 1 ?
                            <img src={doc.signatures.secondSig1} alt="Client #2 Signature" />
                            :
                            <SignatureCanvas ref={(ref) => { this.secondSig1 = ref }} />
                          }
                          <div className="signature-buttons">
                            <button className="signatureRetry" onClick={(event) => this.handleSignatureRetry(event, this.secondSig1, 'secondSig1')}>
                              <RetryIcon />
                            </button>
                            <button className="signatureConfirm" onClick={(event) => this.handleSignatureConfirm(event, this.secondSig1, 'secondSig1')}>
                              <CheckIcon />
                            </button>
                          </div>
                          <div className="label">Second Signature</div>
                        </div>
                        <div className="date">
                          {this.currentDate}
                          <div className="label">Date</div>
                        </div>
                      </div>
                      <div className="row title">
                        <div className="value">{client.second_investor_legal_name ? client.second_investor_legal_name : ''}</div>
                        <div className="label">Second Investor</div>
                      </div>
                    </li>
                  </ul> */}

                <Table
                  title="Identification of Authorized Third Party"
                  rows={[
                    {
                      header: 'The Authorized Third Party for the Account is',
                      content: firm.authorized_third_party.preferred_name,
                    },
                    {
                      header: 'Mailing Address',
                      content: formatAddress(firm.authorized_third_party),
                    },
                    {
                      header: 'Telephone',
                      content: firm.authorized_third_party.phone,
                    },
                  ]}
                />
              </div>

              <div className="page-break"></div>
              <div className="addendum-2">
                <h3>Addendum II</h3>
                <h3>Client Profile and Suitability Questionnaire</h3>

                <p>
                  Name of Investment Adviser Representative:{' '}
                  <span className="underline">
                    <strong>{advisor.name}</strong>
                  </span>
                </p>

                <h4>
                  Section I. This Profile/Suitability Questionnaire applies to
                  the following:
                </h4>

                <h5>Non-Retirement Accounts</h5>
                <ul className="accounts">
                  {client.accounts_non_retirement &&
                    client.accounts_non_retirement.map((account, idx) => (
                      <li key={idx}>
                        {account}
                        {account === 'Other' &&
                          client.accounts_non_retirement_other &&
                          `: ${client.accounts_non_retirement_other}`}
                      </li>
                    ))}
                </ul>

                <h5>Retirement Accounts</h5>
                <ul className="accounts">
                  {client.accounts_retirement &&
                    client.accounts_retirement.map((account, idx) => (
                      <li key={idx}>
                        {account}
                        {account === 'Other' &&
                          client.accounts_retirement_other &&
                          `: ${client.accounts_retirement_other}`}
                      </li>
                    ))}
                </ul>

                <h4>Section II. Account Owner Information</h4>

                <Table title="Account Owner Information" />

                <Table
                  title="First Account Owner"
                  rows={[
                    { header: 'Name', content: client.legal_name },
                    {
                      header: 'Marital Status',
                      content: client.marital_status,
                    },
                    { header: 'SSN', content: '' },
                    { header: 'Date of Birth', content: client.birthdate },
                    {
                      header: 'Anticipated Retirement Date',
                      content: client.retirement_age,
                    },
                    { header: 'Email Address', content: client.email },
                    { header: 'Phone Number', content: client.phone },
                    { header: 'Legal Address', content: formatAddress(client) },
                    {
                      header: 'Mailing Address',
                      headerNotes: '(if different from legal address)',
                      content: formatAddress(client, 'address_mailing'),
                    },
                  ]}
                />

                <Table
                  title="Second Account Owner"
                  rows={[
                    {
                      header: 'Name',
                      headerNotes: '(if applicable)',
                      content: client.second_investor_legal_name,
                    },
                    {
                      header: 'Marital Status',
                      content: client.second_investor_marital_status,
                    },
                    { header: 'SSN', content: '' },
                    {
                      header: 'Date of Birth',
                      content: client.second_investor_birthdate,
                    },
                    {
                      header: 'Anticipated Retirement Date',
                      content: client.second_investor_retirement_age,
                    },
                    {
                      header: 'Email Address',
                      content: client.second_investor_email,
                    },
                    {
                      header: 'Phone Number',
                      content: client.second_investor_phone,
                    },
                    {
                      header: 'Legal Address',
                      headerNotes:
                        "(if different from First Investor's legal address)",
                      content: formatAddress(client, 'second_investor_address'),
                    },
                    {
                      header: 'Mailing Address',
                      headerNotes: '(if different from legal address)',
                      content: formatAddress(
                        client,
                        'second_investor_address_mailing'
                      ),
                    },
                  ]}
                />

                <Table
                  title="Additional Information"
                  rows={[
                    {
                      header: 'Relationship of Account Owners',
                      headerNotes: '(if applicable)',
                      content:
                        client.second_investor_relation === 'Other'
                          ? client.second_investor_relation +
                            ': ' +
                            client.second_investor_relation_details
                          : client.second_investor_relation,
                    },
                    {
                      header: 'Annual Household Income from All Sources',
                      content: client.income_range,
                    },
                    {
                      header: 'Estimated Net Worth',
                      headerNotes: '(excluding primary residence)',
                      content: client.net_worth,
                    },
                    {
                      header: 'Liquid Net Worth',
                      headerNotes: '(cash, stocks, bonds, etc.)',
                      content: client.net_worth_liquid,
                    },
                    {
                      header: 'Federal Income Tax Rate',
                      content: client.tax_bracket,
                    },
                    {
                      header: 'Primary Investment Objective',
                      content:
                        client.investment_objectives === 'Other'
                          ? client.investment_objectives +
                            ': ' +
                            client.investment_objectives_other
                          : client.investment_objectives,
                    },
                    {
                      header: 'Risk Tolerance',
                      content: (
                        <ul className="investment-profiles">
                          {generateInvestmentProfileItems(
                            client.investment_profiles
                          )}
                        </ul>
                      ),
                    },
                    {
                      header: 'Time Horizon',
                      content: calculateTimeHorizon(
                        client.birthdate,
                        client.retirement_age
                      ),
                    },
                    {
                      header: 'General Investment Experience',
                      content: client.investment_experience_general,
                    },
                    {
                      header:
                        'Other Relevant Information Regarding the Needs and Circumstances of the Account Owner(s)',
                      content: client.other_information,
                    },
                  ]}
                />

                <p>
                  The account owner(s) acknowledge(s) that the information in
                  this Client Profile and Suitability Questionnaire is a
                  reasonable picture of the financial situation, investment
                  goals, objectives and risk tolerance of the account owner(s).
                </p>

                <ul className="parties">
                  <li>
                    <SignatureRow
                      id="client_sig"
                      signerType="Client"
                      description={client.legal_name + ', First Investor'}
                      signature={doc.signatures.client_sig}
                      textSignature={{
                        id: 'client_3_text',
                        value: doc.signatures.client_3_text,
                        label: '(or) Print Name as your Signature:',
                        placeholder: 'Print Name',
                      }}
                      handleInputChange={handleInputChange}
                      handleSignatureConfirm={handleSignatureConfirm}
                      handleSignatureRetry={handleSignatureRetry}
                      allowEdit={type !== 'send'}
                      signatureType={firm.signatureType}
                    />
                  </li>
                  <li>
                    <SignatureRow
                      id="second_sig"
                      signerType="Client"
                      description={
                        client.second_investor_legal_name.length
                          ? client.second_investor_legal_name +
                            ', Second Investor'
                          : 'Second Investor'
                      }
                      signature={doc.signatures.second_sig}
                      textSignature={{
                        id: 'second_3_text',
                        value: doc.signatures.second_3_text,
                        label: '(or) Print Name as your Signature:',
                        placeholder: 'Print Name',
                      }}
                      handleInputChange={handleInputChange}
                      handleSignatureConfirm={handleSignatureConfirm}
                      handleSignatureRetry={handleSignatureRetry}
                      allowEdit={type !== 'send'}
                      signatureType={firm.signatureType}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* </PDFExport> */}

          <DocumentSubmit errors={errors} handleSubmit={handleIACSubmit} />
        </div>
      )
    } else {
      return <h4>Populating...</h4>
    }
  }
}

export default CentennialIAC
