import React, { Component } from 'react'
import { checkIntegration } from '../../../actions/integrations/practifi/checkIntegration'

class SalesforceIntegrationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      hasIntegration: false,
    }

    this.checkIntegration = this.checkIntegration.bind(this)
  }

  componentWillMount() {
    if (this.props.salesforce) {
      this.checkIntegration()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.salesforce !== prevProps.salesforce) {
      this.checkIntegration()
    }
  }

  checkIntegration() {
    this.setState(
      {
        loading: true,
      },
      async () => {
        let hasIntegration = false
        try {
          hasIntegration = await checkIntegration(this.props.uid)
        } catch (err) {
          // TODO: Handle failure to authenticate with salesforce
        }

        this.setState({
          loading: false,
          hasIntegration,
        })
      }
    )
  }

  render() {
    let message = 'Your Practifi integration is ready.'
    if (this.state.loading) {
      message = 'Checking up Practifi integration...'
    } else if (!this.props.salesforce) {
      message =
        'You need to enable Salesforce integration first in order to activate Practifi integration.'
    } else if (!this.state.hasIntegration) {
      message = 'Could not find Practifi in your Salesforce integration'
    }

    return (
      <div className={'integration-details integration-details-salesforce'}>
        <p>{message}</p>
        <div className="form-group submit-section">
          {this.props.salesforce &&
            !this.state.loading &&
            !this.state.hasIntegration && (
              <button
                className="continue"
                type="button"
                onClick={this.checkIntegration}
              >
                Check again
              </button>
            )}
        </div>
      </div>
    )
  }
}

export default SalesforceIntegrationForm
