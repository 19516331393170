import React, { PureComponent, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import AddNewFirmClientsModule from '../../../dashboards/partials/AddNewFirmClientsModule'
import { FormFields } from '../partials'
import Field from '../../Field'
import InvestmentExperienceFields from '../../InvestmentExperienceFields'
import ErrorsList from '../../../partials/ErrorsList'
import DocumentReviewModal from '../../../partials/LegacyDocumentReviewModal'
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa'
import { clientInitialState } from '../../../../config/iacConfig'
import { assetsHeldAwayLabels } from '../../../../config/clientConfig'
import { validateClientFields } from '../../../../actions/iacHelpers'
import { getItemDetails } from '../../../../actions/db/dbActions'
import {
  getFirmAdvisors,
  stopListeningToFirmAdvisors,
} from '../../../../actions/clientDbActions'
import { firmIDs } from '../../../../config/firmConfig'
import './SuitabilityForm.scss'

const initialSuitabilityState = {
  userAddSuccess: false,
  uid: null,
  firm: null,
  client: clientInitialState,
  advisors: null,
  addMore: false,
  showReviewModal: false,
  addSuccess: false,
  errors: [],
}

export default class SuitabilityForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = initialSuitabilityState

    this.handleContinue = this.handleContinue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTextMultipleChange = this.handleTextMultipleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.hideReviewModal = this.hideReviewModal.bind(this)
    this.validate = this.validate.bind(this)
  }

  componentDidMount() {
    const { advisorID, firmID, client } = this.props
    const { firm } = this.state

    if (this.props.firm && !firm) {
      this.setState({
        firm: this.props.firm,
      })
    }

    // Get firm info and advisors
    if (!firm && firmID) {
      getItemDetails('firm', firmID, this)
      getFirmAdvisors(firmID, this)
    }

    // // Get advisor info (if firm admin is advisor)
    // if (!advisor && advisorID) {
    //   getItemDetails('advisor', advisorID, this)
    // } else {
    //   this.setState({
    //     advisor: advisor
    //   })
    // }

    // Add firm ID to client object
    this.setState({
      client: {
        ...this.state.client,
        firm: firmID,
      },
    })

    // Add advisor ID to client object
    if (advisorID) {
      this.setState({
        client: {
          ...this.state.client,
          advisor: advisorID,
        },
      })
    }

    // Add existing client information
    if (client) {
      this.setState({
        client: Object.assign({}, clientInitialState, client),
      })
    }

    // Add existing uid
    if (this.props.clientID) {
      this.setState({
        uid: this.props.clientID,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { firmID, reviewType, setAddSuccess, setClientAdvisorID } = this.props
    const { client, addSuccess } = this.state

    if (client.firm.length < 1) {
      this.setState({
        client: {
          ...this.state.client,
          firm: firmID,
        },
      })
    }

    // Set advisor ID to listen to details/disclosures in ClientForm
    if (!prevState.client.advisor && this.state.client.advisor) {
      setClientAdvisorID(this.state.client.advisor)
    }

    // Update advisor details on props change
    if (this.props.advisor !== this.state.advisor) {
      this.setState({
        advisor: this.props.advisor,
      })
    }

    // Hide review modal & reset state 3 seconds after add success
    if (addSuccess && !prevState.addSuccess) {
      // Reset state to add another
      if (reviewType !== 'client-sign' && setAddSuccess) {
        setAddSuccess(true)
      } else {
        // Close review modal
        setTimeout(this.hideReviewModal, 3000)
      }
    }
  }

  componentWillUnmount() {
    // Stop listening to firm advisors
    if (!this.props.firm && this.props.firmID) {
      stopListeningToFirmAdvisors()
    }
  }

  handleContinue() {
    const { errors } = this.state

    if (!errors || !errors.length) {
      this.setState({
        showReviewModal: true,
      })
    }
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name
    const value = target.value

    if (name.startsWith('assets_held_away-')) {
      var subName = name.replace('assets_held_away-', '')

      this.setState({
        client: {
          ...this.state.client,
          assets_held_away: {
            ...this.state.client.assets_held_away,
            [subName]: value,
          },
        },
      })
    } else {
      this.setState(
        {
          client: {
            ...this.state.client,
            [name]: value,
          },
        },
        () => {
          if (name === 'legal_name_first' || name === 'legal_name_last') {
            this.setState({
              client: {
                ...this.state.client,
                legal_name:
                  this.state.client.legal_name_first +
                  ' ' +
                  this.state.client.legal_name_last,
              },
            })
          }

          if (
            name === 'second_investor_legal_name_first' ||
            name === 'second_investor_legal_name_last'
          ) {
            this.setState({
              client: {
                ...this.state.client,
                second_investor_legal_name:
                  this.state.client.second_investor_legal_name_first +
                  ' ' +
                  this.state.client.second_investor_legal_name_last,
              },
            })
          }
        }
      )
    }
  }

  handleTextMultipleChange(id, fields) {
    this.setState({
      client: {
        ...this.state.client,
        [id]: fields,
      },
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.validate()
  }

  hideReviewModal(event = null) {
    if (event) {
      event.preventDefault()
    }

    this.setState({
      showReviewModal: false,
    })
  }

  validate() {
    const { reviewType, firmID } = this.props
    const { client, firm } = this.state

    var errors = []

    var firmName = 'this firm'
    if (firm && firm.name) {
      firmName = firm.name
    }

    var fieldsToValidate = [
      'legal_name_first',
      'legal_name_last',
      'legal_name',
      ...(firmID !== firmIDs['jalinski'] ? ['birthdate'] : []),
      ...(firmID !== firmIDs['jalinski'] ? ['risk_score'] : []),
      'advisor',
      'second_investor_legal_name',
      'second_investor_legal_name_first',
      'second_investor_legal_name_last',
      'second_investor_birthdate',
    ]
    if (reviewType === 'client-sign') {
      fieldsToValidate = fieldsToValidate.concat([
        ...(firmID === firmIDs['jalinski'] ? ['birthdate'] : []),
        'occupation',
        'second_investor_occupation',
        'income_range',
        'net_worth',
        'net_worth_liquid',
        'tax_bracket',
        'expenses',
        'expenses_special',
        'expenses_special_timeframe',
        'investment_experience_items',
        'investment_objectives',
        'investment_objectives_time_horizon',
        'investment_objectives_other',
      ])
    }

    errors = errors.concat(
      validateClientFields(client, firmName, fieldsToValidate)
    )

    // Jalinski customizations
    if (firmID === firmIDs['jalinski']) {
      // Trusted contacts
      if (reviewType === 'client-sign') {
        if (!client.authorized_contacts || !client.authorized_contacts.length) {
          errors.push('Please enter at least one trusted contact.')
        }
        if (client.authorized_contacts && client.authorized_contacts.length) {
          let hasAuthorizedContactErrors = false
          if (!hasAuthorizedContactErrors) {
            client.authorized_contacts.forEach((authorizedContact) => {
              if (
                !authorizedContact.name ||
                !authorizedContact.address ||
                !authorizedContact.phone ||
                !authorizedContact.email
              ) {
                hasAuthorizedContactErrors = true
              }
            })
          }
          if (hasAuthorizedContactErrors) {
            errors.push(
              'Please enter a name, address, phone, and email for all trusted contacts.'
            )
          }
        }

        if (!client.attorney_name) {
          errors.push(`Please enter attorney's name or "NONE."`)
        }

        if (!client.cpa_name) {
          errors.push(`Please enter CPA's name or "NONE."`)
        }

        if (!client.executor_name) {
          errors.push(`Please enter executor's name or "NONE."`)
        }
      }

      if (
        client.tactical_strategy &&
        client.tactical_strategy !== 'None' &&
        client.risk_tolerance &&
        client.risk_tolerance === 'Conservative'
      ) {
        errors.push(
          'You cannot select a Conservative Risk Tolerance with a Tactical Strategy.'
        )
      }
    }

    // Set state and continue
    this.setState(
      {
        errors: errors,
      },
      () => {
        this.handleContinue()
      }
    )
  }

  render() {
    const { firmID, advisorID, location, reviewType, taskDetails } = this.props
    const {
      userAddSuccess,
      uid,
      client,
      firm,
      advisor,
      advisors,
      addMore,
      errors,
      showReviewModal,
      addSuccess,
    } = this.state

    var firmName = 'this firm'
    if (firm && firm.name) {
      firmName = firm.name
    }

    var advisorsObject = {}
    if (advisors && Object.keys(advisors).length) {
      advisorsObject = advisors
    }

    const requireClientInput =
      (firmID === firmIDs['jalinski'] || firmID === firmIDs['mtg']) &&
      reviewType === 'client-sign'

    return (
      <Fragment>
        {reviewType !== 'client-sign' && location !== 'client-profile' ? (
          <AddNewFirmClientsModule
            firmID={firmID}
            advisorID={advisorID}
            type="Client"
            onboardingModule={this}
            generatePassword={true}
          />
        ) : !addSuccess ? (
          <div className="instructions suitability-instructions">
            Please review/update your information below and click "Submit" to
            continue!
          </div>
        ) : addSuccess ? (
          <div className="instructions suitability-instructions">
            Success! You may now close this window.
          </div>
        ) : (
          ''
        )}

        {!addSuccess &&
        (userAddSuccess ||
          reviewType === 'client-sign' ||
          location === 'client-profile') ? (
          <form className="suitability-form" onSubmit={this.handleSubmit}>
            <Field
              type="text"
              id="legal_name_first"
              label="First Name"
              value={client.legal_name_first}
              handleInputChange={this.handleInputChange}
              readOnly={reviewType === 'client-sign' ? true : false}
              tooltip={
                reviewType === 'client-sign'
                  ? 'Only advisors can make this change!'
                  : ''
              }
            />
            <Field
              type="text"
              id="legal_name_last"
              label="Last Name"
              value={client.legal_name_last}
              handleInputChange={this.handleInputChange}
              readOnly={reviewType === 'client-sign' ? true : false}
              tooltip={
                reviewType === 'client-sign'
                  ? 'Only advisors can make this change!'
                  : ''
              }
            />
            <Field
              formGroupClassName={
                firmID === firmIDs['jalinski'] && reviewType === 'send'
                  ? 'visually-hidden'
                  : ''
              }
              type="date"
              id="birthdate"
              label="Date of Birth"
              value={client.birthdate}
              showLabel={true}
              handleInputChange={this.handleInputChange}
              readOnly={
                firmID !== firmIDs['jalinski'] && reviewType === 'client-sign'
                  ? true
                  : false
              }
              tooltip={
                firmID !== firmIDs['jalinski'] && reviewType === 'client-sign'
                  ? 'Only advisors can make this change!'
                  : ''
              }
            />

            {firmID !== firmIDs['jalinski'] && (
              <Field
                type="number"
                id="risk_score"
                label="Risk Score"
                value={client.risk_score}
                handleInputChange={this.handleInputChange}
                readOnly={reviewType === 'client-sign' ? true : false}
                tooltip={
                  reviewType === 'client-sign'
                    ? 'Only advisors can make this change!'
                    : ''
                }
              />
            )}

            {/* // TODO: [BTS-301] Only on firm dashboard? */}
            <h3>Advisor</h3>
            <Field
              type="select"
              id="advisor"
              required={true}
              options={Object.keys(advisorsObject).map((advisor) => {
                const advisorObject = {
                  value: advisor,
                  label: advisorsObject[advisor].name,
                }
                return advisorObject
              })}
              label="Who are you working with?"
              value={client.advisor}
              handleInputChange={this.handleInputChange}
              readOnly={reviewType === 'client-sign' ? true : false}
              tooltip={
                reviewType === 'client-sign'
                  ? 'Only advisors can make this change!'
                  : ''
              }
            />

            {reviewType !== 'client-sign' ? (
              <Fragment>
                <button
                  data-tip={addMore ? 'Add less info' : 'Add more info'}
                  type="button"
                  className="icon-button"
                  onClick={() => this.setState({ addMore: !addMore })}
                >
                  {addMore ? (
                    <Fragment>
                      <FaMinusCircle />
                      <span className="visually-hidden">Add less info</span>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <FaPlusCircle />
                      <span className="visually-hidden">Add more info</span>
                    </Fragment>
                  )}
                </button>
                <ReactTooltip place="right" type="info" effect="solid" />
              </Fragment>
            ) : (
              ''
            )}

            {addMore ||
            reviewType === 'client-sign' ||
            location === 'client-profile' ? (
              <Fragment>
                <Field
                  type="text"
                  id="occupation"
                  label="Occupation"
                  value={client.occupation}
                  handleInputChange={this.handleInputChange}
                  required={requireClientInput}
                />

                <h4>Second Investor</h4>
                <Field
                  type="text"
                  id="second_investor_legal_name_first"
                  label="Second Investor First Name"
                  value={client.second_investor_legal_name_first}
                  handleInputChange={this.handleInputChange}
                  readOnly={reviewType === 'client-sign' ? true : false}
                  tooltip={
                    reviewType === 'client-sign'
                      ? 'Only advisors can make this change!'
                      : ''
                  }
                />
                <Field
                  type="text"
                  id="second_investor_legal_name_last"
                  label="Second Investor Last Name"
                  value={client.second_investor_legal_name_last}
                  handleInputChange={this.handleInputChange}
                  readOnly={reviewType === 'client-sign' ? true : false}
                  tooltip={
                    reviewType === 'client-sign'
                      ? 'Only advisors can make this change!'
                      : ''
                  }
                />
                <Field
                  type="date"
                  id="second_investor_birthdate"
                  label="Second Investor Date of Birth"
                  value={client.second_investor_birthdate}
                  handleInputChange={this.handleInputChange}
                  readOnly={reviewType === 'client-sign' ? true : false}
                  tooltip={
                    reviewType === 'client-sign'
                      ? 'Only advisors can make this change!'
                      : ''
                  }
                />
                <Field
                  type="text"
                  id="second_investor_occupation"
                  label="Second Investor Occupation"
                  value={client.second_investor_occupation}
                  handleInputChange={this.handleInputChange}
                  required={
                    requireClientInput &&
                    (client.second_investor_legal_name_first ||
                      client.second_investor_legal_name_last)
                  }
                />

                <h4>Financials</h4>
                <Field
                  type="select"
                  options="money-25-50"
                  id="income_range"
                  label="Income (All Sources)"
                  value={client.income_range}
                  handleInputChange={this.handleInputChange}
                  required={requireClientInput}
                />
                <Field
                  type="select"
                  options="money-25-100"
                  id="net_worth"
                  label="Net Worth (ex Residence)"
                  value={client.net_worth}
                  handleInputChange={this.handleInputChange}
                  required={requireClientInput}
                />
                <Field
                  type="select"
                  options="money-25-100"
                  id="net_worth_liquid"
                  label="Liquid Net Worth"
                  value={client.net_worth_liquid}
                  handleInputChange={this.handleInputChange}
                  required={requireClientInput}
                />

                <Field
                  type="select"
                  options="tax-bracket"
                  id="tax_bracket"
                  label="Tax Bracket"
                  value={client.tax_bracket}
                  handleInputChange={this.handleInputChange}
                  required={requireClientInput}
                />

                {client.assets_held_away ? (
                  <div>
                    <h4>Assets outside of {firmName}</h4>
                    <Field
                      type="money"
                      id="assets_held_away-stocks"
                      label={assetsHeldAwayLabels['stocks']}
                      value={client.assets_held_away.stocks}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="money"
                      id="assets_held_away-cash_value_life"
                      label={assetsHeldAwayLabels['cash_value_life']}
                      value={client.assets_held_away.cash_value_life}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="money"
                      id="assets_held_away-bonds"
                      label={assetsHeldAwayLabels['bonds']}
                      value={client.assets_held_away.bonds}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="money"
                      id="assets_held_away-annuities"
                      label={assetsHeldAwayLabels['annuities']}
                      value={client.assets_held_away.annuities}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="money"
                      id="assets_held_away-options"
                      label={assetsHeldAwayLabels['options']}
                      value={client.assets_held_away.options}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="money"
                      id="assets_held_away-commodities"
                      label={assetsHeldAwayLabels['commodities']}
                      value={client.assets_held_away.commodities}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="money"
                      id="assets_held_away-mutual_funds"
                      label={assetsHeldAwayLabels['mutual_funds']}
                      value={client.assets_held_away.mutual_funds}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="money"
                      id="assets_held_away-other"
                      label={assetsHeldAwayLabels['other']}
                      value={client.assets_held_away.other}
                      handleInputChange={this.handleInputChange}
                    />
                  </div>
                ) : (
                  ''
                )}

                <div>
                  <h4>Monthly Expenses</h4>
                  <Field
                    type="select"
                    options="money-5-10"
                    id="expenses"
                    label="General Expenses"
                    value={client.expenses}
                    handleInputChange={this.handleInputChange}
                    required={requireClientInput}
                  />
                  <Field
                    type="select"
                    options="money-5-10"
                    id="expenses_special"
                    label="Special Expenses"
                    value={client.expenses_special}
                    handleInputChange={this.handleInputChange}
                    required={requireClientInput}
                  />
                  <Field
                    type="select"
                    id="expenses_special_timeframe"
                    label="Special Expenses Timeframe"
                    value={client.expenses_special_timeframe}
                    handleInputChange={this.handleInputChange}
                    options={[
                      '0 - 2 Years',
                      '2 - 5 Years',
                      '5 - 10 Years',
                      '10 - 20 Years',
                      '20+ Years',
                    ]}
                    required={requireClientInput}
                  />
                </div>

                <div className="form-group bitsy-form-group">
                  <label
                    htmlFor="investment_experience"
                    className="visually-shown"
                  >
                    Investment Experience
                  </label>
                  <InvestmentExperienceFields client={client} state={this} />
                </div>

                {firmID !== firmIDs['jalinski'] && (
                  <Field
                    type="radio-group"
                    id="investment_objectives"
                    label="Investment Objectives"
                    instructional="(Choose 1)"
                    value={client.investment_objectives}
                    handleInputChange={this.handleInputChange}
                    showLabel={true}
                    options={[
                      {
                        title: 'Profile 1',
                        description:
                          'I want to preserve my initial principal with minimal risk and volatility. I understand this may provide lower returns which may not keep pace with inflation or be able to provide required income needed.',
                      },
                      {
                        title: 'Profile 2',
                        description:
                          'I am willing to accept a low level of risk to my initial principal, including low volatility. I am willing to accept lower returns as the portfolio is providing income to meet my needs. I understand I could lose a portion of my principal.',
                      },
                      {
                        title: 'Profile 3',
                        description:
                          'I am willing to take some risk with my initial principal and can tolerate some volatility. I seek to balance risk as the portfolio may now, or in the near future, be providing a portion of my income. I understand I could lose a portion of my principal.',
                      },
                      {
                        title: 'Profile 4',
                        description:
                          'I am willing to take high risk with my initial principal, including high volatility, to seek higher returns over time. I understand I could lose a substantial amount of my principal',
                      },
                      {
                        title: 'Profile 5',
                        description:
                          'I am willing to take maximum risk with my initial principal to aggressively seek maximum returns. I understand I could lose most, or all, of my principal',
                      },
                      {
                        title: 'Other',
                        instructional: '(Describe):',
                        description: 'allowInput',
                        inputId: 'investment_objectives_other',
                        inputValue: client.investment_objectives_other,
                      },
                    ]}
                  />
                )}

                {firmID === firmIDs['jalinski'] && (
                  <>
                    <Field
                      type="select"
                      id="retirement_age"
                      required={requireClientInput}
                      options="retirement-age"
                      label="When do you plan to retire?"
                      value={client.retirement_age}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="select"
                      id="investment_experience_general"
                      required={requireClientInput}
                      options="experience"
                      label="General Investment Experience"
                      value={client.investment_experience_general}
                      handleInputChange={this.handleInputChange}
                    />
                    <Field
                      type="select"
                      id="investment_objectives"
                      required={requireClientInput}
                      options={[
                        'Preservation of capital and focus on current income',
                        'A balance between capital appreciation and current income',
                        'Capital appreciation with little or no need for current income',
                      ]}
                      label="Primary Investment Objective?"
                      value={client.investment_objectives}
                      handleInputChange={this.handleInputChange}
                    />
                  </>
                )}

                <Field
                  type="select"
                  id="investment_objectives_time_horizon"
                  label="How long do you need your money to last?"
                  value={client.investment_objectives_time_horizon}
                  handleInputChange={this.handleInputChange}
                  options={['5 - 10 Years', '10 - 20 Years', '20+ Years']}
                  required={requireClientInput}
                />

                <FormFields
                  templateID="suitability"
                  firmID={firmID}
                  value={client}
                  reviewType={reviewType}
                  handleInputChange={this.handleInputChange}
                />

                {firmID === firmIDs['jalinski'] && (
                  <Field
                    type="text-multiple"
                    id="authorized_contacts"
                    label="Trusted Contacts"
                    value={client.authorized_contacts}
                    handleInputChange={this.handleTextMultipleChange}
                    showLabel={true}
                    listClassName="authorized-contacts"
                    textMultipleFields={[
                      {
                        id: 'name',
                        label: 'Name',
                        type: 'text',
                        required: true,
                      },
                      {
                        id: 'address',
                        label: 'Address',
                        type: 'text',
                        required: true,
                      },
                      {
                        id: 'phone',
                        label: 'Phone',
                        type: 'phone',
                        required: true,
                      },
                      {
                        id: 'email',
                        label: 'Email',
                        type: 'email',
                        required: true,
                      },
                    ]}
                  />
                )}
              </Fragment>
            ) : (
              ''
            )}

            <div className="form-group submit-section">
              <ErrorsList errors={errors} />
              <button onClick={this.attemptContinue} type="submit">
                Submit
              </button>
            </div>
          </form>
        ) : (
          ''
        )}

        <DocumentReviewModal
          documentType="suitability"
          documentTypeName="Suitability Form"
          isOpen={showReviewModal}
          handleModalClose={this.hideReviewModal}
          source={location === 'client-profile' ? 'update' : 'register'}
          location={location}
          type={reviewType}
          firmID={firmID}
          firm={firm}
          advisor={advisor}
          client={client}
          uid={uid}
          state={this}
          taskDetails={taskDetails}
        />
      </Fragment>
    )
  }
}
