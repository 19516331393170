import { firebaseFunctions } from '../../../config/firebaseConfig'

const integrationsPractifiGetClient = firebaseFunctions.httpsCallable(
  'integrationsPractifiGetClient'
)

export async function getClient(uid, email) {
  try {
    const result = await integrationsPractifiGetClient({ uid, email })
    return result.data
  } catch (err) {
    return null
  }
}
