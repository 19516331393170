import React, { useEffect } from 'react'
import { useLocation, Link } from '@reach/router'
import qs from 'querystring'
import PoweredByBitsy from 'components/partials/PoweredByBitsy'
import Loader from '../partials/Loader'
import { SuccessIcon, ClockIcon } from '../icons'
import { useFirm } from '../../contexts'
import styled from 'styled-components'

const EsignCallback = () => {
  const location = useLocation()
  const { firm, firmID, setFirmID } = useFirm()

  const searchParams = qs.parse(location.search.substr(1))
  const isEmailFlow = searchParams.emailFlow === 'true'
  const signerId = searchParams.signerId
  const failure =
    searchParams.failure === 'true' || // failed id verification questions
    searchParams.event === 'cancel' || // clicked "finish later"
    searchParams.event === 'session_timeout' // signing session timed out

  // Set firm ID
  if ((!firmID || !firmID.length) && searchParams.firmId) {
    setFirmID(searchParams.firmId)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const msg = JSON.stringify({
        firmID: searchParams.firmId,
        email: searchParams.email,
        signerId,
      })
      if (!isEmailFlow && window && window.opener && !failure)
        window.opener.postMessage(msg)
    }, 1500)
    return () => {
      clearTimeout(timer)
    }
  })

  if (failure) {
    return (
      <div className="esign-page esign-callback">
        <div className="content">
          {searchParams.event === 'cancel' ? (
            <>
              <div className="icon-container">{SuccessIcon}</div>
              <h1>See you later!</h1>
              <h2>
                You can try signing again from the link in your email
                <br />
                or contact your advisor with any questions.
              </h2>
            </>
          ) : searchParams.event === 'session_timeout' ? (
            <>
              <div className="icon-container">
                <ClockIcon size="100px" color="#fff" />
              </div>
              <h1>Session timed out</h1>
              <h2>Please try again from the link in your email.</h2>
            </>
          ) : (
            <>
              <FlippedIcon className="icon-container">
                {SuccessIcon}
              </FlippedIcon>
              <h1>Something went wrong</h1>
              <h2>Please contact your advisor for next steps.</h2>
            </>
          )}
        </div>
      </div>
    )
  }

  if (isEmailFlow) {
    return (
      <div className="esign-page esign-callback">
        <div className="content">
          <div className="icon-container">{SuccessIcon}</div>
          {signerId === 'firm' ? (
            <h1>
              {firm && firm.name ? `Hi ${firm.name}!` : "You're all set!"}
            </h1>
          ) : (
            <h1>
              {firm && firm.name
                ? `Welcome to ${firm.name}!`
                : "You're all set!"}
            </h1>
          )}

          {signerId === 'firm' ? (
            <h2>
              The client is finalized. We are now generating final versions of
              signed documents and will email them to all parties.
            </h2>
          ) : (
            <h2>
              Signing was successful. You'll receive an email with the final
              documents once all parties have signed.
            </h2>
          )}
          <p>
            You don't have to do anything else right now. If you'd like, you can
            explore your Bitsy dashboard to see previous contracts and more
            {firm && firm.name && ` with ${firm.name}`}.
          </p>

          <Link
            className="button"
            to={
              firmID
                ? `../login/${firmID}${
                    searchParams.advisorId ? `--${searchParams.advisorId}` : ''
                  }`
                : '../login'
            }
          >
            Log In
          </Link>
        </div>

        <PoweredByBitsy />
      </div>
    )
  }

  return (
    <div className="esign-page esign-callback">
      <Loader message="We have successfully captured your signature, please wait for the window to auto-close." />
    </div>
  )
}

export default EsignCallback

const FlippedIcon = styled.div`
  svg {
    transform: rotate(180deg);
    margin-bottom: -8px;
  }
`
