import React, { Component, Fragment } from 'react'
import Field from './Field'
import './InvestmentExperienceFields.scss'

class InvestmentExperienceFields extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleInvestmentExperienceLineChange = this.handleInvestmentExperienceLineChange.bind(
      this
    )
  }

  handleInvestmentExperienceLineChange = (event) => {
    const target = event.target
    const name = target.name
    const value = target.value

    // Break down name and idx
    const nameSplit = name.split('-')
    const idx = parseFloat(nameSplit[0])
    const fieldName = nameSplit[1]

    const { state } = this.props

    const newInvestmentExperiences = state.state.client.investment_experience_items.map(
      (investment_experience, sidx) => {
        if (idx !== sidx) return investment_experience
        return { ...investment_experience, [fieldName]: value }
      }
    )

    state.setState({
      client: {
        ...state.state.client,
        investment_experience_items: newInvestmentExperiences,
      },
    })
  }

  render() {
    const { client, location } = this.props

    this.investment_experience_fields = client.investment_experience_items.map(
      (investment_experience_item, idx) => (
        <li key={idx}>
          <div className="for-account">{investment_experience_item.type}</div>

          <Field
            className="how-long"
            type="select"
            id={idx + '-years'}
            label="For how long?"
            value={investment_experience_item.years}
            handleInputChange={this.handleInvestmentExperienceLineChange}
            options={['0 - 5 Years', '6 - 20 Years', '20+ Years']}
          />

          {location === 'iac' && (
            <Fragment>
              <Field
                className="knowledge"
                type="select"
                id={idx + '-knowledge'}
                label="Level of Knowledge"
                value={investment_experience_item.knowledge}
                handleInputChange={this.handleInvestmentExperienceLineChange}
                options={['1', '2', '3', '4', '5']}
              />
              <div className="instructional">
                <small>1 - Low, 5 - High</small>
              </div>
            </Fragment>
          )}

          {investment_experience_item.type === 'Other' && (
            <Field
              type="textarea"
              id={idx + '-details'}
              label="Please provide details"
              value={investment_experience_item.details}
              handleInputChange={this.handleInvestmentExperienceLineChange}
            />
          )}
        </li>
      )
    )

    return (
      <div className="form-group investment-experiences-form-group">
        <ul className="investment-experiences">
          {this.investment_experience_fields}
        </ul>
      </div>
    )
  }
}

export default InvestmentExperienceFields
