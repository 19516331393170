import React, { Component } from 'react'
import ErrorsList from '../../partials/ErrorsList'

class DocumentSubmit extends Component {
  render() {
    const { errors, handleSubmit } = this.props

    return (
      <div className="iac-submit-container">
        <div className="error-container">
          <ErrorsList
            errors={errors}
            title="Please correct the following errors:"
            sticky={true}
          />
        </div>
        <button id="iac-submit" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    )
  }
}

export default DocumentSubmit
