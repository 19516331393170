import React, { Component } from 'react'
import { signOutUser } from '../../../actions/authActions'

class LogoutButton extends Component {
  logoutUser(event) {
    event.preventDefault()

    signOutUser()
  }

  render() {
    const { className, color } = this.props

    // Construct classes
    var classes = 'logout'
    if (className) {
      classes += ' ' + className
    }

    // Construct inline styles
    var style = {}
    if (color) {
      style.backgroundColor = color
      classes += ' custom-color'
    }

    return (
      <button style={style} className={classes} onClick={this.logoutUser}>
        Log out
      </button>
    )
  }
}

export default LogoutButton
