import React, { memo, useMemo, useState } from 'react'
import Form from 'components/forms/Form'
import { resetPassword } from 'actions/authActions'
import { validEmailRegex } from 'config/regexPatterns'

const ResetPasswordForm = memo(({ email = '', ...props }) => {
  const [success, setSuccess] = useState()

  const fields = useMemo(() => {
    let fields = [
      {
        type: 'email',
        name: 'email',
        label: 'Email',
        icon: 'mail',
        required: true,
        pattern: {
          value: validEmailRegex,
          message: 'Enter a valid email address',
        },
        defaultValue: email,
      },
    ]

    return fields
  }, [email])

  const handleSubmit = ({ email }, setSubmitErrors) => {
    resetPassword(email, null, null, setSuccess, setSubmitErrors)
  }

  if (success) {
    return 'Please check your email to reset your password!'
  }

  return (
    <Form
      id="reset-password"
      fields={fields}
      submitButtonText="Reset password"
      onSubmit={handleSubmit}
      {...props}
    />
  )
})

export default ResetPasswordForm
