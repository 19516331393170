import { firebaseFunctionsCustomDomain } from '../../../config/firebaseConfig'

const integrationsDocusignListTemplates = firebaseFunctionsCustomDomain.httpsCallable(
  'integrationsDocusignListTemplates'
)

export default async function listTemplates(folderID, firm) {
  try {
    const result = await integrationsDocusignListTemplates({ folderID, firm })
    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
