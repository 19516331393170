import { css } from 'styled-components'

export const ButtonsGrid = () => css`
  .row {
    margin: 30px 0;
    display: flex;
  }

  button.button-grid-button {
    ${CardBoxShadow()};
    min-width: 100%;
    min-height: 100%;
    padding: 30px;

    img {
      max-width: 100%;
      max-height: 80px;
      min-height: 34px;
      margin: 0 auto;
      object-fit: contain;
    }

    &:hover {
      ${CardBoxShadow('#c7c7c7')};
      transition: 0.3s all;
    }

    &.activated {
      position: relative;

      .badge {
        padding: 0.125em 0.2em;
        padding-top: calc(0.125em + 2px);
      }
    }
  }
`

export const CardBoxShadow = (color = '#ededed') => css`
  box-shadow: 0px 16px 24px ${color};
`

export const SmallHeading = () => css`
  color: #717171;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 7px;
`

export const VisuallyHidden = () => css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`
