import React from 'react'
import { Select } from 'semantic-ui-react'
import Table from 'components/partials/Table'
import styled from 'styled-components'

const FieldMappingTable = ({
  dsTemplateTabs,
  dsFieldMapping,
  fields,
  handleSetDsFieldMapping,
}) => {
  const bitsyFields = [
    { field_name: 'tax_id', id: 'bitsy__tax_id', label: 'Tax ID' },
    ...fields,
  ]

  const bitsyFieldOptions = bitsyFields.flatMap((templateField) => {
    const { field_name, id, label } = templateField
    const fieldId = field_name || id
    if (!fieldId || !label) {
      return []
    }
    return {
      key: fieldId,
      value: fieldId,
      text: label,
    }
  })

  const columns = React.useMemo(
    () => [
      {
        Header: 'DocuSign Document Tab',
        accessor: 'tab',
      },
      {
        Header: 'Bitsy Form Field',
        id: 'field',
        Cell: ({ row }) => {
          const { tab } = row.original
          return (
            <Select
              placeholder="Select a Field"
              fluid
              search
              clearable
              value={
                dsFieldMapping && dsFieldMapping[tab]
                  ? dsFieldMapping[tab].replace('$.client.', '')
                  : ''
              }
              options={bitsyFieldOptions}
              onChange={(e, { value }) => {
                handleSetDsFieldMapping(tab, value)
              }}
            />
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bitsyFieldOptions, dsFieldMapping]
  )

  const data = React.useMemo(() => {
    let dataRows = []

    Object.keys(dsTemplateTabs).forEach((dsTemplateTabType) => {
      if (
        dsTemplateTabType === 'dateSignedTabs' ||
        dsTemplateTabType === 'signHereTabs'
      ) {
        return
      }

      let dsTemplateTabTypeName = dsTemplateTabType.replace(/([A-Z])/g, ' $1')
      dsTemplateTabTypeName =
        dsTemplateTabTypeName.charAt(0).toUpperCase() +
        dsTemplateTabTypeName.slice(1, dsTemplateTabTypeName.length - 5)

      const dsTemplateTabTypeTabs = dsTemplateTabs[dsTemplateTabType]

      if (dsTemplateTabTypeTabs && dsTemplateTabTypeTabs.length) {
        dsTemplateTabTypeTabs.forEach((dsTemplateTabObject, dstidx) => {
          const { tabLabel, groupName } = dsTemplateTabObject

          dataRows.push({
            tab: tabLabel || groupName,
            type: dsTemplateTabTypeName,
          })
        })
      }
    })

    return dataRows
  }, [dsTemplateTabs])

  return <StyledTable columns={columns} data={data} />
}

export default FieldMappingTable

const StyledTable = styled(Table)`
  &&& {
    td {
      overflow: visible !important;
    }
  }
`
