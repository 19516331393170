import React, { PureComponent } from 'react'

class Question extends PureComponent {
  render() {
    const { type, name, question, handleContinue } = this.props

    if (type === 'select' || type === 'select_multi') {
      const { options, handleSelect, currentSelections } = this.props

      const optionsList = options.map((option, i) => {
        var value = option.answer
        if (option.value) {
          value = option.value
        }

        var selected = false
        if (currentSelections) {
          currentSelections.map((selection) => {
            if (selection === value) {
              selected = true
            }

            return selected
          })
        }

        return (
          <button
            className={selected ? 'selected' : ''}
            key={i}
            onClick={(e) => handleSelect(e, name, value)}
          >
            {option.answer}
          </button>
        )
      })

      return (
        <div
          className={
            name === 'interest_level' ? 'slideContents active' : 'slideContents'
          }
        >
          <div className="question">
            {question}
            {type === 'select_multi' ? (
              <span className="instructional"> (check all that apply)</span>
            ) : (
              ''
            )}
          </div>
          <div className="form-group">
            {optionsList}
            {type === 'select_multi' ? (
              <button onClick={(e) => handleContinue(e, name)}>Continue</button>
            ) : (
              ''
            )}
          </div>
        </div>
      )
    }

    if (type === 'text' || type === 'paragraph') {
      const { handleInputChange } = this.props

      return (
        <div className="slideContents">
          <div className="question">{question}</div>
          <div className="form-group">
            {type === 'paragraph' ? (
              <textarea name={name} onChange={(e) => handleInputChange(e)} />
            ) : (
              ''
            )}

            {type === 'text' ? (
              <input
                type="text"
                id={name}
                name={name}
                onChange={(e) => handleInputChange(e)}
              />
            ) : (
              ''
            )}

            <button onClick={(e) => handleContinue(e, name)}>Continue</button>
          </div>
        </div>
      )
    }
  }
}

export default Question
