import React, { memo, useState } from 'react'
import { Form, Button, Label } from 'semantic-ui-react'
import styled from 'styled-components'

const FileUpload = memo(({ id, handleUpload, ...props }) => {
  const [uploading, setUploading] = useState(false)

  let hiddenInput = null

  const setUploadComplete = () => {
    hiddenInput = null
    setUploading(false)
    document.getElementById(id).reset()
  }

  const handleChange = () => {
    const file =
      hiddenInput && hiddenInput.files && hiddenInput.files.length
        ? hiddenInput.files[0]
        : null

    if (file && handleUpload) {
      setUploading(true)
      const reader = new FileReader()
      reader.onloadend = () => {
        handleUpload(reader.result, setUploadComplete)
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <StyledForm id={id} {...props}>
      <Button
        as="div"
        labelPosition="right"
        onClick={() => hiddenInput.click()}
      >
        <Button
          icon="upload"
          title="Upload file"
          disabled={uploading}
          loading={uploading}
        />
        <Label
          as="a"
          content="Upload file"
          basic
          pointing="left"
          disabled={uploading}
        />
      </Button>

      <input
        ref={(element) => (hiddenInput = element)}
        hidden
        onChange={handleChange}
        type="file"
        disabled={uploading}
      />
    </StyledForm>
  )
})

export default FileUpload

const StyledForm = styled(Form)`
  &&& {
    & > * {
      margin-right: 0;
    }
  }
`
