import React, { memo, useState, Fragment } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import fetchCheckoutSession from '../../actions/integrations/stripe/checkout'
import { stripePublishableKey } from '../../config/stripeKeys'
import ErrorsList from './ErrorsList'

const stripePromise = loadStripe(stripePublishableKey)

export default memo(function CheckoutButton({
  message = null,
  customerEmail,
  priceId,
  redirectParams,
  className,
  activateOnLoad = false,
}) {
  const [errors, setErrors] = useState([])

  const redirectToCheckout = async (event = null) => {
    if (event) {
      event.preventDefault()
    }

    // Call your backend to create the Checkout session.
    const { id: sessionId } = await fetchCheckoutSession({
      customerEmail,
      priceId,
      redirectParams,
    })

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    })
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    if (error) {
      setErrors([error.message])
    }
  }

  if (activateOnLoad) {
    redirectToCheckout()
    return ''
  }

  return (
    <Fragment>
      <button className={`primary ${className}`} onClick={redirectToCheckout}>
        {message ? message : 'Continue to Payments'}
      </button>
      <ErrorsList errors={errors} />
    </Fragment>
  )
})
