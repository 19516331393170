import React, { useState, useEffect } from 'react'
import { Dropdown, Form, Input } from 'semantic-ui-react'
import Loader from '../../../partials/Loader'
import { getParams, isTrueParam } from 'helpers/_utils'
import { getClientFromIntegrations } from '../../../../actions/integrations'
import styled from 'styled-components'

const TaxIDField = ({
  client,
  handleTaxIDInputChange,
  handleTaxIDTypeInputChange,
}) => {
  const [taxID, setTaxID] = useState(client.tax_id)
  const [taxIDType, setTaxIDType] = useState(client.tax_id_type || 'SSN')
  const [checkingIntegrations, setCheckingIntegrations] = useState(false)

  const params = getParams(window)
  const taxIDParam = params['client_tax_id']

  useEffect(() => {
    if (client.tax_id_type !== taxIDType) handleTaxIDTypeInputChange(taxIDType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxIDType, client.tax_id_type])

  useEffect(() => {
    const checkIntegrationsForTaxID = async () => {
      setCheckingIntegrations(true)

      const clientFromIntegrations = await getClientFromIntegrations(
        client,
        client
      )

      if (clientFromIntegrations && clientFromIntegrations.tax_id) {
        console.log('A tax ID was found in an integration!')
        handleTaxIDInputChange(clientFromIntegrations.tax_id)
        if (!taxID) setTaxID(clientFromIntegrations.tax_id)
      } else {
        console.log('No tax ID was found in an integration')
        handleTaxIDInputChange('')
      }

      // TODO: [BTS-848] Add tax ID type to checkIntegrationsForTaxID?

      setCheckingIntegrations(false)
    }

    if (isTrueParam(taxIDParam)) {
      handleTaxIDInputChange(taxIDParam)
      if (!taxID) setTaxID(taxIDParam)
    } else {
      checkIntegrationsForTaxID()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {checkingIntegrations ? (
        <Loader message="Checking integrations for a Tax ID" />
      ) : (
        <Form.Group className="grouped equal width form-group object">
          <StyledField className="fields grouped equal width form-group string">
            <label htmlFor="tax_id">SSN / TIN</label>
            <Input
              name="tax_id"
              id="tax_id"
              value={taxID || ''}
              onChange={(e, { value }) => setTaxID(value)}
              data-id="tax-id"
              onBlur={() => {
                if (client.tax_id !== taxID) handleTaxIDInputChange(taxID)
              }}
              loading={checkingIntegrations}
            />
          </StyledField>
          <StyledField className="fields grouped equal width form-group string">
            <label htmlFor="tax_id_type">Type</label>
            <Dropdown
              placeholder="Select one"
              fluid
              selection
              scrolling
              name="tax_id_type"
              id="tax_id_type"
              value={taxIDType}
              onChange={(e, { value }) => setTaxIDType(value)}
              onBlur={() => {
                if (client.tax_id_type !== taxIDType)
                  handleTaxIDTypeInputChange(taxIDType)
              }}
              data-id="tax-id-type"
              loading={checkingIntegrations}
              options={[
                {
                  key: 'SSN',
                  text: 'SSN',
                  value: 'SSN',
                },
                {
                  key: 'TIN',
                  text: 'TIN',
                  value: 'TIN',
                },
                {
                  key: 'EIN',
                  text: 'EIN',
                  value: 'EIN',
                },
              ]}
            />
          </StyledField>
        </Form.Group>
      )}
    </>
  )
}
export default TaxIDField

const StyledField = styled(Form.Field)`
  &&& {
    @media screen and (min-width: 46.25em) {
      margin-bottom: 2rem !important;
    }
  }
`
