import firebase from 'firebase/app'
import { firebaseFunctions, firebaseDB } from '../../../config/firebaseConfig'

const integrationsRedtailAuthGetUserKey = firebaseFunctions.httpsCallable(
  'integrationsRedtailAuthGetUserKey'
)

// Function to send the authentication data to Firebase function
export async function authenticateRedtailUser(
  email,
  password,
  firmID,
  setErrors
) {
  try {
    const result = await integrationsRedtailAuthGetUserKey({
      email,
      password,
      firmID,
    })

    return result.data
  } catch (err) {
    console.error(err)
    setErrors([err.message])
  }
}

// Deauthenticate user
export async function deauthenticateRedtailUser(firmID) {
  firebaseDB
    .collection('integrations')
    .doc(firmID)
    .update({ redtail: firebase.firestore.FieldValue.delete() })
}
