import React, { memo } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import ResendSigningEmailButton from '../ResendSigningEmailButton'
import RetrySigningButton from '../RetrySigningButton'
import { ToDoIcon } from 'components/icons'
import { useFirm } from 'contexts'

const dateFormatFull = 'MM/DD/YYYY [at] HH:mm:ss A'

const EnvelopeRecipientsList = memo(
  ({ sortedRecipientIDs = [], recipients = {}, envelope = {}, envelopeID }) => {
    const { firmID } = useFirm()

    const { deliveryMethod } = envelope

    const envelopeRecipientIDs = sortedRecipientIDs.map(
      (envelopeRecipientID) => {
        const envelopeRecipient = recipients[envelopeRecipientID]
        return envelopeRecipient.signerID
      }
    )

    let enableOne = true

    return (
      <StyledList>
        {sortedRecipientIDs.map((recipientID) => {
          const recipient = recipients[recipientID]

          let dateSigned = null
          if (recipient.dateSigned) {
            dateSigned = dayjs(recipient.dateSigned.toDate())
          }

          let disabled = true
          if (enableOne && (recipient.status !== 'Signed' || !dateSigned)) {
            disabled = false
            enableOne = false
          }

          let awaitingMsg = 'Awaiting signature'

          if (deliveryMethod === 'email') {
            awaitingMsg = `${awaitingMsg} (via email)`
          }

          if (envelope.status === 'Completed') {
            awaitingMsg = 'Completed manually'
          }

          return (
            <li key={envelopeID + recipientID}>
              <ToDoIcon
                complete={
                  recipient.status === 'Signed' ||
                  envelope.status === 'Completed'
                }
              />
              <small>
                <strong>{recipient.name}</strong>
                <br />
                <em>
                  {recipient.status === 'Signed' && dateSigned
                    ? `Signed ${dateSigned.format(dateFormatFull)}`
                    : recipient.status === 'Signed'
                    ? 'Signed'
                    : awaitingMsg}
                </em>
              </small>
              {'Sent' === envelope.status && deliveryMethod === 'email' ? (
                <ResendSigningEmailButton
                  envelopeID={envelopeID}
                  disabledByOrder={disabled}
                  signerRole={recipient.signerID}
                  recipient={{
                    name: recipient.name,
                    recipientId: recipient.recipientId,
                    email: recipient.email,
                    firmId: firmID,
                  }}
                  signerStatus={recipient.status}
                  envelopeRecipientIDs={envelopeRecipientIDs}
                />
              ) : 'Sent' === envelope.status ? (
                <RetrySigningButton
                  envelopeID={envelopeID}
                  disabledByOrder={disabled}
                  signerRole={recipient.signerID}
                  recipient={{
                    name: recipient.name,
                    recipientId: recipient.recipientId,
                    email: recipient.email || recipientID, // Pass recipient ID to not break legacy envelopes
                    firmId: firmID,
                    advisorId: envelope.advisor,
                    signerId: recipient.signerID,
                  }}
                  signerStatus={recipient.status}
                  envelopeRecipientIDs={envelopeRecipientIDs}
                />
              ) : (
                ''
              )}
            </li>
          )
        })}
      </StyledList>
    )
  }
)

export default EnvelopeRecipientsList

const StyledList = styled.ul`
  padding-left: 15px;

  li {
    display: flex;
    align-items: center;
    margin: 8px 0;
    line-height: 1.3;

    .to-do {
      margin: 6px 0;
      margin-right: 6px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
`
