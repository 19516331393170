import React, { memo } from 'react'
import { Icon } from 'semantic-ui-react'
import { FaFileSignature } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

const DeliveryMethodIcon = memo(({ deliveryMethod, ...props }) => {
  let icon
  switch (deliveryMethod) {
    case 'form':
      icon = <Icon name="tasks" data-tip="Form" />
      break
    case 'email':
      icon = <Icon name="send" data-tip="Email" />
      break
    default:
      icon = (
        <i aria-hidden="true" className="icon" data-tip="In-Person Signing">
          <FaFileSignature />
        </i>
      )
      break
  }

  return (
    <Container {...props}>
      {icon}
      <ReactTooltip place="right" type="info" effect="solid" />
    </Container>
  )
})

export default DeliveryMethodIcon

const Container = styled.div`
  &&& {
    text-align: center;

    & i.icon {
      width: auto;
    }
  }
`
