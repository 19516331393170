import React, { memo, useState, useEffect } from 'react'
import { Button, Message } from 'semantic-ui-react'
import { FaFileSignature } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { fonts } from 'styles'
import FinalizeSigningEventOptions from '../partials/FinalizeSigningEventOptions'
import FinalizeSigningPage from './FinalizeSigningPage'
import Loader from 'components/partials/Loader'
import Error from 'components/partials/Error'
import { handleDocusignFinalize } from 'actions/forms/handleFinalize'

const FinalizePage = memo(
  ({
    templateID,
    template,
    clientID,
    client,
    advisor,
    firm,
    additionalDocuments,
    nextStep,
    label,
    removePendingChanges = false,
    flow,
    formID,
    taskID,
    docSigningSessionEnvelopeID,
    setDocSigningSessionEnvelopeID,
  }) => {
    const [processing, setProcessing] = useState(false)
    const [processingMessage, setProcessingMessage] = useState('Please wait...')
    const [finalizeType, setFinalizeType] = useState(null)
    const [saveSuccess, setSaveSuccess] = useState(null)
    const [docSubmitSuccess, setDocSubmitSuccess] = useState(null)
    const [
      docSigningSessionSignersLoading,
      setDocSigningSessionSignersLoading,
    ] = useState(null)
    const [docSignSuccess, setDocSignSuccess] = useState(null)
    const [roundTripFinalizeSuccess, setRoundTripFinalizeSuccess] = useState(
      null
    )
    const [errors, setErrors] = useState([])

    // Handle signers loading for signing sessions
    useEffect(() => {
      if (docSigningSessionEnvelopeID) {
        setDocSigningSessionSignersLoading(false)
      }
    }, [docSigningSessionEnvelopeID])

    // Increase step to confirmation page
    useEffect(() => {
      if (
        'round-trip' !== template.routing ||
        ('round-trip' === template.routing &&
          flow &&
          'final-review' === flow &&
          roundTripFinalizeSuccess)
      ) {
        switch (finalizeType) {
          case 'send':
            if (saveSuccess && docSubmitSuccess) {
              nextStep()
            }
            break
          case 'sign':
          case 'finalize-preview':
            if (saveSuccess && docSignSuccess) {
              nextStep()
            }
            break
          case 'save':
            if (saveSuccess) {
              nextStep()
            }
            break
          default:
            break
        }
      }
    }, [
      finalizeType,
      saveSuccess,
      docSubmitSuccess,
      docSignSuccess,
      roundTripFinalizeSuccess,
      template.routing,
      flow,
      nextStep,
    ])

    const handleFinalize = (
      type,
      envelopeIDFromPreview = null,
      updateEnvelope = null
    ) => {
      handleDocusignFinalize({
        type,
        envelopeIDFromPreview,
        updateEnvelope,
        templateID,
        template,
        flow,
        clientID,
        client,
        advisor,
        firm,
        formID,
        taskID,
        additionalDocuments,
        setFinalizeType,
        setProcessing,
        setProcessingMessage,
        setErrors,
        setDocSigningSessionSignersLoading,
        setDocSigningSessionEnvelopeID,
        setDocSubmitSuccess,
        setRoundTripFinalizeSuccess,
        setSaveSuccess,
        removePendingChanges,
      })
    }

    const handleSetDocSignSuccess = (data) => {
      setDocSignSuccess(data)
    }

    if (docSigningSessionEnvelopeID) {
      return (
        <FinalizeSigningPage
          envelopeID={docSigningSessionEnvelopeID}
          signersLoading={docSigningSessionSignersLoading}
          handleSetDocSignSuccess={handleSetDocSignSuccess}
          preview={finalizeType === 'preview'}
          nextStep={nextStep}
          handleFinalizePageFinalize={handleFinalize}
        />
      )
    }

    return (
      <Wrapper data-step={label}>
        {processing ? (
          <>
            <Loader message={processingMessage} fullScreen>
              <LoaderHeader as="h2">You did it!</LoaderHeader>
              <p>
                We are generating your document now. This might take a minute.
              </p>
            </Loader>
          </>
        ) : (
          <>
            <Header>How would you like to finalize this document?</Header>

            <ButtonWrapper>
              <ButtonGroup vertical labeled icon data-id="finalize-buttons">
                {template.signingEvent && (
                  <FinalizeSigningEventOptions
                    processing={processing}
                    finalizeType={finalizeType}
                    handleFinalize={handleFinalize}
                  />
                )}
                <Button
                  content="Save and exit"
                  icon="check"
                  primary
                  size="big"
                  onClick={() => handleFinalize('save')}
                  disabled={processing}
                  loading={
                    processing && finalizeType && finalizeType === 'save'
                  }
                  data-tip="Update the client in any integrations (skip generating a final document for signing)"
                />
              </ButtonGroup>
              <ReactTooltip place="right" type="info" effect="solid" />
            </ButtonWrapper>
          </>
        )}

        {!!(errors && errors.length) &&
          errors.map((error, eidx) => (
            <Message key={eidx} negative>
              <Message.Header>
                We're sorry, something went wrong.
              </Message.Header>
              <p>Please try again and contact Bitsy if the problem persists.</p>

              <p>
                <small>
                  <strong>Details:</strong>
                </small>
              </p>
              <Error
                message={error.message}
                code={`TMPL-${templateID}`}
                level="error"
                errorInfo={{
                  Location: 'Template Form Finalization',
                  Details: error,
                }}
                userInfo={{
                  'Template ID': templateID,
                  'Firm ID': client.firm,
                  'Advisor ID': client.advisor,
                  'Client ID': clientID,
                  Client: client,
                }}
              />
            </Message>
          ))}
      </Wrapper>
    )
  }
)

export default FinalizePage

const Header = styled.div`
  ${fonts.heading};
  font-style: italic;
  text-align: center;
  margin: 20px;
`

export const LoaderHeader = styled.div`
  text-align: center;
  margin: 20px;
`

const Wrapper = styled.div`
  margin: auto;
  justify-content: center;
  align-content: center;
  max-width: 600px;
  align-self: center;
  height: 100%;
  padding: 25px 0;
  text-align: center;
`

const ButtonWrapper = styled.div`
  text-align: center;
`

const ButtonGroup = styled(Button.Group)`
  margin: 0 auto !important;
`

export const SigningIcon = styled(FaFileSignature)`
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
`
