import React, { memo } from 'react'
import './ToDoIcon.scss'

export default memo(function ToDoIcon({ complete = false, className = '' }) {
  if (complete) {
    return (
      <svg
        className={className || 'to-do complete'}
        height="20px"
        version="1.1"
        viewBox="0 0 20 20"
        width="20px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnssketch="http://www.bohemiancoding.com/sketch/ns"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title />
        <desc />
        <defs />
        <g
          fill="none"
          fillRule="evenodd"
          id="Page-1"
          stroke="none"
          strokeWidth="1"
        >
          <g
            fill="#000000"
            id="Core"
            transform="translate(-128.000000, -86.000000)"
          >
            <g
              id="check-circle-outline"
              transform="translate(128.000000, 86.000000)"
            >
              <path
                d="M5.9,8.1 L4.5,9.5 L9,14 L19,4 L17.6,2.6 L9,11.2 L5.9,8.1 L5.9,8.1 Z M18,10 C18,14.4 14.4,18 10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C10.8,2 11.5,2.1 12.2,2.3 L13.8,0.7 C12.6,0.3 11.3,0 10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 L18,10 L18,10 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }

  return (
    <svg
      className={className || 'to-do incomplete'}
      height="20px"
      version="1.1"
      viewBox="0 0 20 20"
      width="20px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnssketch="http://www.bohemiancoding.com/sketch/ns"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title />
      <desc />
      <defs />
      <g
        fill="none"
        fillRule="evenodd"
        id="Page-1"
        stroke="none"
        strokeWidth="1"
      >
        <g
          fill="#000000"
          id="Core"
          transform="translate(-170.000000, -86.000000)"
        >
          <g
            id="check-circle-outline-blank"
            transform="translate(170.000000, 86.000000)"
          >
            <path
              d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C14.4,2 18,5.6 18,10 C18,14.4 14.4,18 10,18 L10,18 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  )
})
