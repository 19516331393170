/* eslint-disable react/prop-types */
import React from 'react'
import { Form } from 'semantic-ui-react'
import { DescriptionField, HelpField, RawErrors } from './partials'
import InputBar from 'components/styled/InputBar'
import { getSemanticProps, MaybeWrap } from '../util'
import styled from 'styled-components'
import { colors } from '../../../../styles'
import myxss from '../FormElements/myxss'

const ConditionalFieldWrapper = ({ wrap = false, children, className }) =>
  wrap ? <div className={className}>{children}</div> : children

const FieldTemplate = ({
  id,
  children,
  className, // pass className for styling libs (like styled-components)
  classNames,
  displayLabel,
  label,
  rawErrors = [],
  rawHelp,
  rawDescription,
  ...props
}) => {
  const semanticProps = getSemanticProps(props)
  const { wrapLabel, wrapContent, errorOptions } = semanticProps

  const fieldType =
    typeof props.uiSchema['ui:widget'] === 'string'
      ? props.uiSchema['ui:widget']
      : typeof props.uiSchema['ui:widget'] === 'function'
      ? props.uiSchema['ui:widget'].name
      : props.schema.type || ''

  let showLabel = true
  if (fieldType === 'boolean') {
    showLabel = false
  }

  return (
    <FormGroup
      key={id}
      widths="equal"
      grouped
      className={`form-group ${fieldType}${props.required ? ' required' : ''}${
        props.readonly ? ' readonly disabled' : ''
      } ${id}`}
    >
      <MaybeWrap wrap={wrapContent} className="sui-field-content">
        {showLabel && (
          <label
            dangerouslySetInnerHTML={{
              __html: myxss.process(props.schema.title),
            }}
          />
        )}
        <ConditionalFieldWrapper
          wrap={
            props &&
            props.schema &&
            props.schema.format &&
            props.schema.format === 'email'
          }
          className={`${props.required ? 'required ' : ''}field`}
        >
          {children}
        </ConditionalFieldWrapper>
        <InputBar className="input-bar" />
        {displayLabel && rawDescription && (
          <MaybeWrap wrap={wrapLabel} className="sui-field-label">
            {rawDescription && (
              <DescriptionField description={rawDescription} />
            )}
          </MaybeWrap>
        )}
        <HelpField helpText={rawHelp} id={id} />
        <RawErrors errors={rawErrors} options={errorOptions} />
      </MaybeWrap>
    </FormGroup>
  )
}

export default FieldTemplate

const FormGroup = styled(Form.Group)`
  position: relative;

  input:focus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 0.2s ease;
  }

  .form-group:not(.radio):not(.boolean) .field:focus-within + .input-bar {
    &::before,
    &::after {
      width: calc(50% - 1px);
    }
  }

  &.needs-to-do::before {
    content: '';
    background: ${colors.warning};
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }

  &.boolean > .field > label {
    display: none;
  }

  // Hide radio / checkbox required indicators
  & .required.fields:not(.grouped) > .field > label:after,
  & .required.fields.grouped > label:after,
  .ui.form .required.field > label:after,
  .ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
  & .required.field > .checkbox:after {
    display: none;
  }

  // Hide repeater fields required indicators
  & .array-item .form-group.object.required > label::after {
    display: none;
  }

  &.readonly.disabled {
    & > label,
    & > .field {
      opacity: 1 !important;
    }

    & > .field > .ui.selection.dropdown > i.dropdown.icon {
      display: none;
    }
  }

  & > .field input {
    border-radius: 0.28571429rem;
  }

  & > .field > .ui.dropdown {
    height: auto !important;
  }

  // Searchable select
  & > .field > .ui.dropdown i.dropdown.icon {
    position: absolute;
    right: calc(1rem + 6px);
  }
`
