import React, { PureComponent, Fragment } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import WidgetToggle from '../../../partials/WidgetToggle'
import DownloadIcon from '../../../icons/DownloadIcon'
import ErrorsList from '../../../partials/ErrorsList'
import {
  startListeningToClientAuth,
  stopListeningToClientAuth,
  signInClientUser,
  signOutClientUser,
} from '../../../../actions/clientAuthActions'
import './TemplateForm.scss'
import {
  getFirmDetails,
  stopListeningToFirmDetails,
  getFirmAdvisors,
  stopListeningToFirmAdvisors,
} from '../../../../actions/clientDbActions'
import { validEmailRegex } from '../../../../config/regexPatterns'
import { FormFields } from '../partials'
import { clientInitialState } from '../../../../config/iacConfig'
import AddNewFirmClientsModule from '../../../dashboards/partials/AddNewFirmClientsModule'
import { firmIDs } from '../../../../config/firmConfig'
import Collapse from 'react-collapse'
import styled from 'styled-components'
import Field from '../../Field'
import colors from '../../../../styles/colors'

class TemplateForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
      accordion_basic: false,
      accordion_financial: false,
      accordion_disclosures: false,
      accordion_investment_profile: false,
      accordion_investment_profile_quiz: false,
      accordion_investment_profile_know: false,
      show_iac: false,
      iacReviewType: null,
      accept_disclosures: false,
      firm: {
        advisors: null,
        name: '',
        email: '',
        isInGoodStanding: null,
        currentPeriodEnd: null,
      },
      advisors: null,
      password: '',
      uid: '',
      client: clientInitialState,
      advisor: this.props.advisor,
      errors: [],
      addSuccess: false,
      userAddSuccess: false,
      docSubmitSuccess: false,
      includeSecondInvestor: '',
    }

    this.attemptContinue = this.attemptContinue.bind(this)
    this.handleClientLogin = this.handleClientLogin.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.hideIAC = this.hideIAC.bind(this)
    this.increaseStep = this.increaseStep.bind(this)
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.validateStep = this.validateStep.bind(this)
  }

  componentDidMount() {
    const {
      location,
      firm,
      firmObject,
      firmAdvisors,
      advisor,
      advisorID,
      client,
      clientID,
    } = this.props

    if (location !== 'dashboard' && location !== 'client-profile') {
      signOutClientUser()
      startListeningToClientAuth(this)
    }

    if (firm.length) {
      const firmID = firm

      this.setState({
        client: {
          ...this.state.client,
          firm: firm,
        },
      })

      if (!firmObject) {
        getFirmDetails(firmID, this)
      } else {
        this.setState({
          firm: firmObject,
        })
      }

      if (!firmAdvisors) {
        getFirmAdvisors(firmID, this)
      } else {
        this.setState({
          advisors: firmAdvisors,
        })
      }
    }

    if (advisorID && advisorID.length) {
      this.setState({
        client: {
          ...this.state.client,
          advisor: advisorID,
        },
      })
    }

    if (advisor) {
      this.setState({
        advisor: advisor,
      })
    }

    if (location === 'client-profile' && client && clientID) {
      var clientObject = client
      delete clientObject['id']

      this.setState(
        {
          userAddSuccess: true,
          uid: clientID,
          client: Object.assign({}, this.state.client, clientObject),
        },
        () => {
          this.setState({
            tabIndex: 1,
          })
        }
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, setSavingClientID, setClientAdvisorID } = this.props

    // Update ClientForm with new UID on client add
    if (!prevState.uid && this.state.uid) {
      setSavingClientID(this.state.uid)
    }

    // Set advisor ID to listen to details/disclosures in ClientForm
    if (!prevState.client.advisor && this.state.client.advisor) {
      setClientAdvisorID(this.state.client.advisor)
    }

    // Update advisor details on props change
    if (this.props.advisor !== this.state.advisor) {
      this.setState({
        advisor: this.props.advisor,
      })
    }

    // Increase step on successful user add
    if (
      location === 'dashboard' &&
      !prevState.userAddSuccess &&
      this.state.userAddSuccess
    ) {
      this.setState((prevState) => {
        return { tabIndex: prevState.tabIndex + 1 }
      })
    }

    // Sign out client user after 10 seconds on add success
    if (this.state.addSuccess) {
      setTimeout(function () {
        signOutClientUser()
      }, 10000)
    }

    // Add firm to client user if it is overwritten
    if (!this.state.client.firm && this.props.firm.length) {
      this.setState({
        client: {
          ...this.state.client,
          firm: this.props.firm,
        },
      })
    }

    // Add email to client user if it is overwritten
    if (
      prevState.client.email &&
      prevState.client.email.length &&
      !this.state.client.email.length
    ) {
      this.setState({
        client: {
          ...this.state.client,
          email: prevState.client.email,
        },
      })
    }
  }

  componentWillUnmount() {
    const { location, firm, firmObject, firmAdvisors } = this.props

    if (location !== 'dashboard' && location !== 'client-profile') {
      signOutClientUser()

      stopListeningToClientAuth()
    }

    if (firm.length) {
      if (!firmObject) {
        stopListeningToFirmDetails()
      }

      if (!firmAdvisors) {
        stopListeningToFirmAdvisors()
      }
    }
  }

  attemptContinue(event) {
    event.preventDefault()

    this.validateStep(this.state.tabIndex)
  }

  handleClientLogin() {
    if (this.state.errors.length === 0) {
      signInClientUser(this.state.client.email, this.state.password, this)
    }
  }

  handleInputChange(event) {
    const target = event.target
    console.log(
      'event',
      event,
      'event.type',
      event.type,
      'event.name',
      event.name
    )
    if (!target) {
      console.log('No target defined, returning.')
      return
    }
    const value = target?.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'email' && !this.state.client.email.length) {
      // Start signup timer and update email
      this.setState({
        client: {
          ...this.state.client,
          [name]: value,
          dateStarted: new Date(),
        },
      })
    } else if (
      name === 'accept_disclosures' ||
      name === 'password' ||
      name === 'includeSecondInvestor'
    ) {
      // Top level state changes
      this.setState({
        [name]: value,
      })
    } else if (name === 'advisor') {
      // Advisor state change
      this.setState({
        client: {
          ...this.state.client,
          [name]: value,
        },
      })
    } else {
      // Client state changes
      this.setState(
        {
          client: {
            ...this.state.client,
            [name]: value,
          },
        },
        function () {
          // Client legal name changes
          if (
            name === 'legal_name_first' ||
            name === 'legal_name_mi' ||
            name === 'legal_name_last'
          ) {
            var fullLegalName = this.state.client.legal_name_first + ' '
            if (this.state.client.legal_name_mi.length > 0) {
              fullLegalName += this.state.client.legal_name_mi + ' '
            }
            fullLegalName += this.state.client.legal_name_last

            this.setState({
              client: {
                ...this.state.client,
                legal_name: fullLegalName,
              },
            })
          }

          // Second Investor legal name changes
          if (
            name === 'second_investor_legal_name_first' ||
            name === 'second_investor_legal_name_mi' ||
            name === 'second_investor_legal_name_last'
          ) {
            var fullSecondLegalName =
              this.state.client.second_investor_legal_name_first + ' '
            if (this.state.client.second_investor_legal_name_mi.length > 0) {
              fullSecondLegalName +=
                this.state.client.second_investor_legal_name_mi + ' '
            }
            fullSecondLegalName += this.state.client
              .second_investor_legal_name_last

            this.setState({
              client: {
                ...this.state.client,
                second_investor_legal_name: fullSecondLegalName,
              },
            })
          }
        }
      )
    }
  }

  hideIAC(event) {
    event.preventDefault()

    this.setState({
      show_iac: false,
    })
  }

  increaseStep() {
    const {
      saveClientToDB,
      addAdditionalDocuments,
      firmDisclosures,
      advisorDisclosures,
    } = this.props
    const { uid, tabIndex, errors, client } = this.state

    if (tabIndex === 0) {
      this.handleClientLogin()
      return
    }

    if (!errors.length) {
      if (tabIndex === 1) {
        saveClientToDB(client, uid)
        addAdditionalDocuments([firmDisclosures, advisorDisclosures])
      }

      this.setState((prevState) => {
        return { tabIndex: prevState.tabIndex + 1 }
      })
    }
  }

  toggleAccordion(event) {
    event.preventDefault()

    const target = event.target
    const name = target.name

    this.setState({
      [name]: !this.state[name],
    })
  }

  validateStep(step) {
    const { client, password, accept_disclosures } = this.state

    const firmID = this.props.firm

    var errors = []

    switch (step) {
      case 0:
        // Email
        if (!validEmailRegex.test(client.email)) {
          errors.push('Please enter a valid email address.')
        }

        // Password
        if (password.length < 1) {
          errors.push(
            'Please enter the password given to you by ' +
              this.state.firm.name +
              '.'
          )
        }

        break
      case 1:
        // // Set validation to exclude certain fields for certain firms
        // var fieldsToExclude = null
        // switch (firmID) {
        //   case firmIDs['centennial']:
        //     // Centennial
        //     fieldsToExclude = ['retirement_needs', 'retirement_income']
        //     break
        //   case firmIDs['jalinski']:
        //     // Jalinski
        //     fieldsToExclude = [
        //       'fee_type',
        //       'fee_fixed',
        //       'retirement_needs',
        //       'retirement_income',
        //       'current_investment_assets_managed',
        //       'investment_profiles',
        //       'portfolio_value',
        //       'portfolio_value_qualified',
        //       'portfolio_value_non_qualified',
        //       'tax_bracket',
        //     ]
        //     break
        //   default:
        //     break
        // }

        // Client fields
        // errors = errors.concat(
        //   validateClientFields(
        //     client,
        //     this.state.firm.name,
        //     null,
        //     fieldsToExclude,
        //     this.state.firm
        //   )
        // )

        // Jalinski customizations
        if (firmID === firmIDs['jalinski']) {
          if (
            client.tactical_strategy &&
            client.tactical_strategy !== 'None' &&
            client.risk_tolerance &&
            client.risk_tolerance === 'Conservative'
          ) {
            errors.push(
              'You cannot select a Conservative Risk Tolerance with a Tactical Strategy.'
            )
          }
        }

        // Accept Disclosures
        if (!accept_disclosures) {
          errors.push('Please accept disclosures.')
        }

        break
      default:
        break
    }

    // Set state and increase step
    this.setState(
      {
        errors: errors,
      },
      () => {
        this.increaseStep()
      }
    )
  }

  render() {
    const {
      location,
      children,
      advisorID,
      firmDisclosures,
      advisorDisclosures,
      templateID,
    } = this.props
    const {
      uid,
      password,
      firm,
      client,
      advisors,
      errors,
      docSubmitSuccess,
    } = this.state

    var mainClass = 'add-iac'
    if (
      location &&
      (location === 'dashboard' || location === 'client-profile')
    ) {
      mainClass += ' embedded'
    } else if (firm && firm.module && firm.module.embedded) {
      mainClass += ' embedded'
    } else {
      mainClass += ' widget'
    }

    var advisorsObject = {}
    if (advisors && Object.keys(advisors).length) {
      advisorsObject = advisors
    }

    if (docSubmitSuccess) {
      return (
        <div className={mainClass}>
          {location !== 'dashboard' && location !== 'client-profile' ? (
            <WidgetToggle />
          ) : (
            ''
          )}
          <div className="register-wrapper">
            <div className="register-container">
              <div className="signup-success">
                <h2>Welcome to {firm.name}!</h2>
                <p>Signup was successful. Please check your email!</p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    this.firm_disclosure_documents_list_items = ''
    if (firmDisclosures && Object.keys(firmDisclosures).length) {
      this.firm_disclosure_documents_list_items = Object.keys(
        firmDisclosures
      ).map((disclosure, key) => (
        <li key={disclosure}>
          <DocumentDownloadLink
            title="Download"
            target="_blank"
            rel="noopener noreferrer"
            href={firmDisclosures[disclosure].link}
          >
            <DownloadIcon />
            {firmDisclosures[disclosure].name}
          </DocumentDownloadLink>
        </li>
      ))
    }

    this.advisor_disclosure_documents_list_items = ''
    if (advisorDisclosures && Object.keys(advisorDisclosures).length) {
      this.advisor_disclosure_documents_list_items = Object.keys(
        advisorDisclosures
      ).map((disclosure, key) => (
        <li key={disclosure}>
          <DocumentDownloadLink
            title="Download"
            target="_blank"
            rel="noopener noreferrer"
            href={advisorDisclosures[disclosure].link}
          >
            <DownloadIcon />
            {advisorDisclosures[disclosure].name}
          </DocumentDownloadLink>
        </li>
      ))
    }

    if (firmDisclosures === null && advisorDisclosures === null) {
      this.firm_disclosure_documents_list_items = 'No documents!'
    }

    if (firmDisclosures === undefined && advisorDisclosures === undefined) {
      this.firm_disclosure_documents_list_items = 'No documents!'
    }

    const firmID = this.props.firm

    const tabs = ['1. Profile', '2. Info', '3. Agreement']

    console.log(templateID)

    return (
      <Tabs
        selectedIndex={this.state.tabIndex}
        onSelect={(tabIndex) => this.setState({ tabIndex })}
      >
        <TabList>
          {tabs.map((tab, idx) => (
            <Tab key={idx} disabled={true}>
              {tab}
            </Tab>
          ))}
        </TabList>

        <TabPanel>
          {location !== 'dashboard' && location !== 'client-profile' && (
            <h2>
              Get set up with <br />
              {firm.name} <br />
              in minutes!
            </h2>
          )}

          <div className="step-contents">
            {location !== 'dashboard' && location !== 'client-profile' ? (
              <Fragment>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    value={client.email}
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="email"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    value={password}
                    type="password"
                    placeholder="Password"
                    name="password"
                    id="password"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group submit-section">
                  <ErrorsList errors={errors} />

                  <button
                    className="continue"
                    onClick={this.attemptContinue}
                    type="button"
                  >
                    Create account
                  </button>
                </div>
              </Fragment>
            ) : (
              <AddNewFirmClientsModule
                firmID={this.props.firm}
                advisorID={advisorID}
                type="Client"
                onboardingModule={this}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          {uid.length > 0 ? (
            <form id="register-client">
              <h2>
                {location !== 'dashboard' && location !== 'client-profile'
                  ? 'Your'
                  : 'Client'}{' '}
                Details
              </h2>

              <div className="step-contents">
                <h3>Advisor</h3>
                <Field
                  type="select"
                  id="advisor"
                  required={true}
                  options={Object.keys(advisorsObject).map((advisor) => {
                    const advisorObject = {
                      value: advisor,
                      label: advisorsObject[advisor].name,
                    }
                    return advisorObject
                  })}
                  label="Who are you working with?"
                  value={client.advisor}
                  handleInputChange={this.handleInputChange}
                />

                <FormFields
                  templateID={templateID}
                  firmID={firmID}
                  value={client}
                  handleInputChange={this.handleInputChange}
                />
                <AccordionButton
                  className={
                    this.state.accordion_disclosures
                      ? 'accordion-toggle active'
                      : 'accordion-toggle'
                  }
                  name="accordion_disclosures"
                  onClick={this.toggleAccordion}
                >
                  Disclosures
                </AccordionButton>
                <Collapse isOpened={this.state.accordion_disclosures}>
                  {firmDisclosures && Object.keys(firmDisclosures).length ? (
                    <ul className="disclosure-documents">
                      {this.firm_disclosure_documents_list_items}
                      {this.advisor_disclosure_documents_list_items}
                    </ul>
                  ) : (
                    <small className="no-clients">
                      No disclosures uploaded!
                    </small>
                  )}

                  <div className="form-inline">
                    <div className="form-group disclosures-accept">
                      <input
                        value={this.state.accept_disclosures}
                        name="accept_disclosures"
                        id="accept_disclosures"
                        type="checkbox"
                        onChange={this.handleInputChange}
                      />
                      <label htmlFor="accept_disclosures">
                        <small>
                          I agree that I have read and received these
                          disclosures.
                        </small>
                      </label>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="form-group submit-section">
                <ErrorsList errors={errors} sticky={true} />

                <div className="instructional info-correct">
                  <small>
                    I agree all of the information is correct to the best of my
                    knowledge.
                  </small>
                </div>
                <button
                  className="continue"
                  onClick={this.attemptContinue}
                  type="button"
                >
                  Continue
                </button>
              </div>
            </form>
          ) : (
            'There was an error. Please contact Bitsy. [RC-NUID]'
          )}
        </TabPanel>
        <TabPanel>{children}</TabPanel>
      </Tabs>
    )
  }
}

export default TemplateForm

const AccordionButton = styled.button``

const DocumentDownloadLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  > svg {
    max-width: 16px;
    max-height: 16px;
    fill: ${colors.primary};
    margin-right: 10px;
  }

  :hover > svg {
    fill: ${colors.blue};
  }
`
