export const getRecipientSignerID = (recipientID) => {
  let recipientSignerID

  switch (recipientID) {
    case 'client_1':
      recipientSignerID = '1'
      break
    case 'client_2':
      recipientSignerID = '2'
      break
    case 'advisor':
      recipientSignerID = '11'
      break
    case 'firm':
      recipientSignerID = '21'
      break
    default:
      recipientSignerID = '1'
      break
  }

  return recipientSignerID
}
