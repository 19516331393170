import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import {
  firebaseApiKey,
  firebaseMessagingSenderId,
  firebaseAppId,
  pentestFirebaseApiKey,
  pentestFirebaseMessagingSenderId,
  pentestFirebaseAppId,
} from './firebaseKeys'

let config = {
  apiKey: firebaseApiKey,
  authDomain: 'bitsy-advisor-82001.firebaseapp.com',
  databaseURL: 'https://bitsy-advisor-82001.firebaseio.com',
  projectId: 'bitsy-advisor-82001',
  storageBucket: 'gs://bitsy-advisor-82001.appspot.com',
  messagingSenderId: firebaseMessagingSenderId,
  appId: firebaseAppId,
}

if (window.location.hostname.includes('pentest')) {
  config = {
    apiKey: pentestFirebaseApiKey,
    authDomain: 'bitsyadvisor-pentest.firebaseapp.com',
    projectId: 'bitsyadvisor-pentest',
    storageBucket: 'bitsyadvisor-pentest.appspot.com',
    messagingSenderId: pentestFirebaseMessagingSenderId,
    appId: pentestFirebaseAppId,
  }
}

export const firebaseApp = firebase.initializeApp(config)

export const firebaseAuth = firebaseApp.auth()

export const firebaseDB = firebase.firestore()

export const firebaseStorage = firebase.storage()

export const firebaseFunctions = firebase.functions()

let functionsCustomDomain
if (window.location.hostname !== 'localhost') {
  functionsCustomDomain = window.location.origin
}

export const firebaseFunctionsCustomDomain = firebaseApp.functions(
  functionsCustomDomain
)

// Use Firebase Emulators on localhost
if (window.location.hostname === 'localhost') {
  firebaseAuth.useEmulator('http://localhost:9099')
  firebaseDB.useEmulator('localhost', 8080)
  firebaseFunctions.useEmulator('localhost', 5000)
  firebaseFunctionsCustomDomain.useEmulator('localhost', 5000)
}

// Secondary app

export const secondaryApp = firebase.initializeApp(config, 'Secondary')

export const secondaryAuth = secondaryApp.auth()

// Client app

export const clientApp = firebase.initializeApp(config, 'Client')

export const clientAuth = clientApp.auth()

export const clientDB = clientApp.firestore()

export const clientStorage = clientApp.storage()

// Prospect app

export const prospectApp = firebase.initializeApp(config, 'Prospect')

export const prospectAuth = prospectApp.auth()

export const prospectDB = prospectApp.firestore()

// export const firebaseAdmin = admin

export const storageKey = 'KEY_FOR_LOCAL_STORAGE'

// export const isAuthenticated = () => {
//   return !!firebaseAuth.currentUser || !!localStorage.getItem(storageKey)
// }

export const functionsBase = window.location.hostname.includes('pentest')
  ? 'https://us-central1-bitsyadvisor-pentest.cloudfunctions.net'
  : 'https://us-central1-bitsy-advisor-82001.cloudfunctions.net'
