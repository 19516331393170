import React, { memo } from 'react'
import Card from 'components/dashboards/partials/LegacyCard'
import DisclosureUploadForm from 'components/forms/DisclosureUploadForm'
import { useUser, useFirm } from 'contexts'
import { Row, ColMd8 } from 'components/styled'

export default memo(function DocumentsTab() {
  const { userType } = useUser()
  const { firm } = useFirm()

  if (!firm) {
    return ''
  }

  const isFirm = userType === 'firm' || userType === 'firm-admin'

  return (
    <Row>
      {isFirm && (
        <ColMd8>
          <Card title="Firm Disclosures">
            <DisclosureUploadForm type="firm" />
          </Card>
        </ColMd8>
      )}

      {(userType === 'advisor' ||
        (userType === 'firm' && firm.adminIsAdvisor)) && (
        <ColMd8>
          <Card
            title={
              userType === 'firm'
                ? 'Admin/Advisor Disclosures'
                : 'Advisor Disclosures'
            }
          >
            <DisclosureUploadForm type="advisor" />
          </Card>
        </ColMd8>
      )}

      {/* {firm.hasServices.autoADV &&
        <ColMd4>
        <Card title="Auto-ADV">
          <button onClick={showADVEditor}>Edit ADV 2A</button>
        </Card>}
        </ColMd4> */}
    </Row>
  )
})
