import { firebaseDB } from '../../config/firebaseConfig'

let unsubscribeUserTasks
export function listenToUserTasks(
  type,
  uid,
  state = null,
  status = 'incomplete'
) {
  const db = firebaseDB

  const collectionRef = db.collection(`${type}s`).doc(uid).collection('tasks')

  let query = collectionRef
  if (status) {
    query = collectionRef.where('status', '==', status)
  }

  unsubscribeUserTasks = query.onSnapshot((querySnapshot) => {
    var tasks = {}
    querySnapshot.forEach((doc) => {
      tasks[doc.id] = doc.data()
    })

    state.setState({
      ...state.state,
      tasks: tasks,
    })
  })
}
export function stopListeningToUserTasks() {
  // Stop listening to changes
  unsubscribeUserTasks()
}

export const updateUserTaskStatus = (userType, userID, taskID, status) => {
  if (!userType || !userID || !taskID || !status) {
    return null
  }

  console.log(`Updating ${userType}s/${userID}/tasks/${taskID} as ${status}...`)

  let db = firebaseDB

  let updatedTaskDoc = {
    status: status,
  }

  if (status === 'complete') {
    updatedTaskDoc['dateCompleted'] = new Date()
  }

  db.collection(userType + 's')
    .doc(userID)
    .collection('tasks')
    .doc(taskID)
    .update(updatedTaskDoc)
    .then(() => {
      // console.log('Task updated')
    })
    .catch((error) => {
      // console.error('Error updating task status: ', error)
    })
}
