/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Form, Label } from 'semantic-ui-react'
import { getSemanticProps } from '../util'
import styled from 'styled-components'

function TextWidget({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  name,
  label,
  schema,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  formContext,
}) {
  const semanticProps = getSemanticProps({ formContext, options })
  // eslint-disable-next-line no-shadow
  const _onChange = ({ target: { value } }) =>
    onChange(value === '' ? options.emptyValue : value)
  const _onBlur = () => onBlur && onBlur(id, value)
  const _onFocus = () => onFocus && onFocus(id, value)
  const inputType = schema.type === 'string' ? 'text' : `${schema.type}`
  const { jsonSchemaOptions } = schema

  const labelBefore =
    jsonSchemaOptions && jsonSchemaOptions.labelBefore
      ? jsonSchemaOptions.labelBefore
      : options && options.labelBefore
      ? options.labelBefore
      : null
  const labelAfter =
    jsonSchemaOptions && jsonSchemaOptions.labelAfter
      ? jsonSchemaOptions.labelAfter
      : options && options.labelAfter
      ? options.labelAfter
      : null

  return (
    <StyledInput
      key={id}
      id={id}
      placeholder={placeholder}
      type={inputType}
      label={schema.title || label}
      required={required}
      autoFocus={autofocus}
      disabled={disabled || readonly}
      name={name}
      {...semanticProps}
      value={value || value === 0 ? value : ''}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      data-haslabelbefore={labelBefore ? true : false}
      data-haslabelafter={labelAfter ? true : false}
    >
      {labelBefore && (
        <StyledLabel position="before">{labelBefore}</StyledLabel>
      )}
      <input />
      {labelAfter && <StyledLabel position="after">{labelAfter}</StyledLabel>}
    </StyledInput>
  )
}

TextWidget.defaultProps = {
  options: {
    semantic: {
      fluid: true,
      inverted: false,
    },
  },
}

TextWidget.propTypes = {
  options: PropTypes.object,
}

export default TextWidget

const StyledInput = styled(Form.Input)`
  &&& {
    & input {
      border-top-left-radius: ${(props) =>
        props.haslabelbefore ? 'none' : 'inherit'};
      border-top-right-radius: ${(props) =>
        props.haslabelafter ? 'none' : 'inherit'};
      border-bottom-right-radius: ${(props) =>
        props.haslabelafter ? 'none' : 'inherit'};
      border-bottom-left-radius: ${(props) =>
        props.haslabelbefore ? 'none' : 'inherit'};
    }
  }
`

const StyledLabel = styled(Label)`
  &&& {
    display: inline-flex;
    align-items: center;
    font-size: 1.5em;
    margin: 0;
    border-top-left-radius: ${(props) =>
      props.position === 'before' ? 'none' : 'inherit'};
    border-top-right-radius: ${(props) =>
      props.position === 'after' ? 'none' : 'inherit'};
    border-bottom-right-radius: ${(props) =>
      props.position === 'after' ? 'none' : 'inherit'};
    border-bottom-left-radius: ${(props) =>
      props.position === 'before' ? 'none' : 'inherit'};
  }
`
