import React, { PureComponent } from 'react'
import { Form, Button, Message } from 'semantic-ui-react'
import { updateUserInfo } from 'actions/dbActions'
import styled from 'styled-components'

//eslint-disable-next-line
const validCalendlyLinkRegex = /https:\/\/(www\.)?calendly.com{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

class CalendlyDetailsForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      calendly_url: '',
      calendly_url_phone: '',
      calendly_url_meeting: '',
      errors: [],
      addSuccess: null,
    }

    this.attemptSave = this.attemptSave.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.saveIntegrationDetails = this.saveIntegrationDetails.bind(this)
    this.validate = this.validate.bind(this)
  }

  attemptSave(event) {
    event.preventDefault()

    this.validate()
  }

  componentDidMount() {
    const { user } = this.props

    if (user && user.calendly) {
      if (user.calendly.url) {
        this.setState({
          calendly_url: user.calendly.url,
        })
      }

      if (user.calendly.url_phone) {
        this.setState({
          calendly_url_phone: user.calendly.url_phone,
        })
      }

      if (user.calendly.url_meeting) {
        this.setState({
          calendly_url_meeting: user.calendly.url_meeting,
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { addSuccess } = this.state

    if (!prevState.addSuccess && addSuccess) {
      var _this = this

      setTimeout(function () {
        _this.setState({
          addSuccess: null,
        })
      }, 3000)
    }
  }

  handleInputChange(event, { value }) {
    const target = event.target
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  saveIntegrationDetails() {
    const { uid, userType } = this.props

    if (this.state.errors.length === 0) {
      var details = {}

      details['url'] = this.state.calendly_url
      details['url_phone'] = this.state.calendly_url_phone
      details['url_meeting'] = this.state.calendly_url_meeting

      // Update publicly-readable info
      updateUserInfo(userType + 's', uid, 'calendly', details, this)
    }
  }

  validate() {
    const {
      calendly_url,
      calendly_url_phone,
      calendly_url_meeting,
    } = this.state

    var errors = []

    // General Meeting Link
    if (
      calendly_url_phone.length > 0 &&
      !validCalendlyLinkRegex.test(calendly_url)
    ) {
      errors.push(
        "That URL doesn't look right! Please make sure your Calendly URL for general meetings has been copied correctly and contact Bitsy if the problem continues."
      )
    }

    // Phone Meeting Link
    if (calendly_url_phone.length < 1) {
      errors.push('Please enter your Calendly URL for phone meetings.')
    } else {
      if (!validCalendlyLinkRegex.test(calendly_url_phone)) {
        errors.push(
          "That URL doesn't look right! Please make sure your Calendly URL for phone meetings has been copied correctly and contact Bitsy if the problem continues."
        )
      }
    }

    // In-Person Meeting Link
    if (calendly_url_meeting.length < 1) {
      errors.push('Please enter your Calendly URL for in-person meetings.')
    } else {
      if (!validCalendlyLinkRegex.test(calendly_url_meeting)) {
        errors.push(
          "That URL doesn't look right! Please make sure your Calendly URL for in-person meetings has been copied correctly and contact Bitsy if the problem continues."
        )
      }
    }

    // Set state and login
    this.setState(
      {
        errors: errors,
      },
      () => {
        this.saveIntegrationDetails()
      }
    )
  }

  render() {
    const {
      calendly_url,
      calendly_url_phone,
      calendly_url_meeting,
      errors,
      addSuccess,
    } = this.state

    var headerText = null

    return (
      <StyledForm
        id={'integration-details-calendly'}
        className={'integration-details integration-details-calendly'}
        onSubmit={this.attemptSave}
        size="big"
      >
        {headerText && <h2>{headerText}</h2>}

        <Form.Field>
          <Form.Input
            label="Calendly URL - General Meeting"
            placeholder="https://calendly.com/YOURLINK"
            id="calendly_url"
            name="calendly_url"
            value={calendly_url}
            type="text"
            onChange={this.handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label="Calendly URL - Phone Meeting"
            placeholder="https://calendly.com/YOURLINK/PHONE"
            id="calendly_url_phone"
            name="calendly_url_phone"
            value={calendly_url_phone}
            type="text"
            onChange={this.handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label="Calendly URL - In-Person Meeting"
            placeholder="https://calendly.com/YOURLINK/IN-PERSON"
            id="calendly_url_meeting"
            name="calendly_url_meeting"
            value={calendly_url_meeting}
            type="text"
            onChange={this.handleInputChange}
          />
        </Form.Field>

        {errors && !!errors.length && (
          <Message negative>
            <Message.Header>Please correct the following</Message.Header>
            <Message.List>
              {errors.map((error, idx) => (
                <Message.Item key={idx}>{error}</Message.Item>
              ))}
            </Message.List>
          </Message>
        )}

        <div className="form-group submit-section">
          {addSuccess && <Message positive>Details saved!</Message>}

          <Button primary size="big" type="submit">
            Connect
          </Button>
        </div>
      </StyledForm>
    )
  }
}

export default CalendlyDetailsForm

const StyledForm = styled(Form)`
  &&& {
    margin-top: 30px;
  }
`
