import React from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

const FormBuilderStepperButtons = ({ SW, submitTemplate }) => {
  return (
    <>
      {SW && (
        <Wrapper>
          {SW.currentStep !== 1 && (
            <>
              <Button
                content="Back"
                type="button"
                circular
                icon="left arrow"
                onClick={SW.previousStep}
                labelPosition="left"
                size="big"
              />
              <Spacer />
            </>
          )}
          {SW.currentStep < SW.totalSteps && (
            <Button
              content="Save and continue"
              type="submit"
              circular
              icon="right arrow"
              primary
              onClick={SW.nextStep}
              labelPosition="right"
              size="big"
            />
          )}
          {SW.currentStep === SW.totalSteps && (
            <Button
              content="Save and close"
              type="submit"
              circular
              icon="right arrow"
              primary
              onClick={submitTemplate}
              labelPosition="right"
              size="big"
            />
          )}
        </Wrapper>
      )}
    </>
  )
}

export default FormBuilderStepperButtons

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px;
  margin-bottom: 60px;
`

const Spacer = styled.div`
  width: 30px;
  display: inline-block;
`
