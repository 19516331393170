import { firebaseDB as db } from '../../../config/firebaseConfig'
import { standardFields } from '../../../components/forms/FormBuilder/templates'

export const getTemplate = async ({ firmID, templateID, setTemplate }) => {
  db.doc(`templates/${firmID}/templates/${templateID}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        if (setTemplate) {
          setTemplate({ id: doc.id, ...doc.data() })
        }
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

export const createNewTemplate = async ({ firmID, creator, setTemplate }) => {
  try {
    const dateCreatedMs = new Date().getTime()
    const dateCreated = new Date(dateCreatedMs).toUTCString()
    const newTemplate = {
      title: 'Untitled ' + new Date().toUTCString(),
      fields: standardFields,
      // documentId: null,
      dateCreated,
      dateCreatedMs,
      creator,
      routing: 'one-way',
      dsFieldMapping: {},
      includeAdvisor: true,
      includeTaxID: true,
      includeDisclosures: true,
      sendDisclosuresWhen: 'with-contract',
      includedFirmDisclosures: [],
      showDisclosuresPage: true,
      useForOnboardingDate: false,
      config: {
        sandbox: {
          dsTemplateId: '',
        },
        prod: {
          dsTemplateId: '',
        },
      },
    }

    const docRef = await db.collection(`templates/${firmID}/templates`).doc()

    await docRef.set({
      ...newTemplate,
      id: docRef.id,
    })

    setTemplate &&
      setTemplate({
        templateId: docRef.id,
        ...newTemplate,
      })
    return docRef.id
  } catch (e) {
    console.error(e)
    console.log('Could not return new template, see error above.')
  }
}

export const cloneTemplate = async ({ firmID, template }) => {
  try {
    const dateCreatedMs = new Date().getTime()
    const dateCreated = new Date(dateCreatedMs).toUTCString()
    const newTemplate = {
      ...template,
      dateCreated,
      dateCreatedMs,
      title: `${template.title} Copy`,
    }

    const docRef = await db.collection(`templates/${firmID}/templates`).doc()

    await docRef.set({
      ...newTemplate,
      id: docRef.id,
    })

    return docRef.id
  } catch (e) {
    console.error(e)
    console.log('Could not return new template, see error above.')
  }
}

export const updateFormFields = async ({
  firmID,
  templateID,
  template,
  setSuccess = null,
}) => {
  if (!firmID || !templateID) {
    if (setSuccess) {
      setSuccess(false)
    }
    return false
  }
  console.log('updating form fields', { firmID, templateID, template })
  db.doc(`templates/${firmID}/templates/${templateID}`)
    .update(template)
    .then(() => {
      // console.log("Template successfully updated!");
      if (setSuccess) {
        setSuccess(true)
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

export const updateIntegrationFieldMapping = async ({
  uid,
  fieldId,
  newValue,
  service,
}) => {
  return db
    .doc(`integrations/${uid}`)
    .set(
      {
        [service]: {
          fields: {
            [fieldId]: newValue,
          },
        },
      },
      { merge: true }
    )
    .catch((err) => {
      console.error(err)
    })
}

export const updateIntegrationsOrder = async (uid, services) => {
  return db
    .doc(`integrations/${uid}`)
    .set({ order: services }, { merge: true })
    .catch((err) => {
      console.error(err)
    })
}

export const deleteTemplate = async ({ firmID, templateID }) => {
  return db
    .doc(`templates/${firmID}/templates/${templateID}`)
    .delete()
    .then(() => {
      return true
    })
    .catch((err) => {
      console.error(err)
      return false
    })
}
