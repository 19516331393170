import React, { Component } from 'react'

class WidgetToggle extends Component {
  toggleWidget(event) {
    event.preventDefault()

    const div = document.getElementById('bitsy-register-client')

    if (div.classList.contains('opened')) {
      // Close div
      div.classList.remove('opened')
    } else {
      // Open div
      div.classList.add('opened')
    }
  }

  render() {
    return (
      <div className="widget-toggle">
        <button onClick={this.toggleWidget}>New IAC</button>
      </div>
    )
  }
}

export default WidgetToggle
