import React, { memo, Fragment } from 'react'
import Card from '../partials/LegacyCard'
import Table from '../../partials/LegacyTable'
import Field from '../../forms/Field'
import AdvisorClientsList from './partials/AdvisorClientsList'
import { Row, ColSm12, ColSm6ColMd4 } from 'components/styled'

export default memo(function AdvisorProfile({
  profile = null,
  uid = null,
  onClientSelect = null,
  onAdvisorStatusChange = null,
}) {
  if (!profile || !uid) {
    return ''
  }

  return (
    <div className="profile">
      <Row className="row">
        <ColSm6ColMd4>
          <Card title="Contact">
            <Table
              rows={[
                {
                  header: 'Email',
                  content: (
                    <Fragment>
                      <a
                        href={'mailto:' + profile.email}
                        title={'Email ' + profile.name}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {profile.email}
                      </a>
                    </Fragment>
                  ),
                },
                {
                  header: 'CRD',
                  content: profile.crd,
                },
                {
                  header: 'Status',
                  content: profile.inactive ? 'Inactive' : 'Active',
                },
              ]}
            />
          </Card>
        </ColSm6ColMd4>
      </Row>

      <Row className="row">
        <ColSm12>
          <Card
            title="Clients list"
            headerActionLink={{
              title: 'View All',
              action: null,
              className: 'd-md-none',
            }}
            type="table"
          >
            <AdvisorClientsList uid={uid} onClientSelect={onClientSelect} />
          </Card>
        </ColSm12>
      </Row>

      {onAdvisorStatusChange && (
        <Row className="row">
          <ColSm6ColMd4>
            <Card title="Advisor Status">
              <Field
                type="switch"
                id="advisor_status"
                label="Make inactive?"
                value={profile.inactive}
                onChange={onAdvisorStatusChange}
              />
            </Card>
          </ColSm6ColMd4>
        </Row>
      )}
    </div>
  )
})
