import React, { memo } from 'react'
import CopyableInput from 'components/dashboards/partials/CopyableInput'
import { appUrlBase } from 'config/appConfig'
import { useFirm } from 'contexts'

const BrandedLoginLink = memo(({ advisorID = null }) => {
  const { firmID } = useFirm()

  return (
    <CopyableInput
      text={`${appUrlBase}/login/${firmID}${advisorID ? `--${advisorID}` : ''}`}
    />
  )
})

export default BrandedLoginLink
