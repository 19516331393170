import { firebaseFunctionsCustomDomain } from '../../../config/firebaseConfig'

const integrationsDocusignSendEnvelope = firebaseFunctionsCustomDomain.httpsCallable(
  'integrationsDocusignSendEnvelope'
)

export default async function sendDocument(
  templateID,
  docType,
  clientID,
  client,
  advisor,
  firm,
  additionalDocuments,
  setDocSubmitSuccess,
  preview,
  setErrors = null
) {
  try {
    const result = await integrationsDocusignSendEnvelope({
      templateID,
      docType,
      clientID,
      client,
      advisor,
      firm,
      additionalDocuments,
      preview,
    })

    if (result.data.status === 'sent' && setDocSubmitSuccess) {
      setDocSubmitSuccess(true)
    }

    return result.data
  } catch (err) {
    console.error(err)
    if (setErrors) {
      setErrors([err])
    }
  }

  return null
}
