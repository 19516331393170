import React, { memo, useState, useEffect } from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import Card from 'components/dashboards/partials/LegacyCard'
import Modal from 'components/partials/LegacyModal'
import FormBuilder from 'components/forms/FormBuilder'
import FormsTable from 'components/dashboards/partials/FormsTable'
import { useUser, useFirm, useTemplates } from 'contexts'
import { createNewTemplate, cloneTemplate } from 'actions/db/templates'
import { Row, ColMd8 } from 'components/styled'

const FormsTab = memo(() => {
  const { uid } = useUser()
  const { firmID } = useFirm()
  const { templates } = useTemplates()

  const [editingForm, setEditingForm] = useState(null)

  // Close Form Builder when a form has been deleted while it is open
  useEffect(() => {
    if (
      templates &&
      editingForm &&
      editingForm.id &&
      !templates[editingForm.id]
    ) {
      setEditingForm(null)
    }
  }, [templates, editingForm])

  return (
    <Row>
      <ColMd8>
        <ButtonsContainer>
          <Button
            primary
            content="Add new"
            icon="plus"
            labelPosition="left"
            onClick={() =>
              createNewTemplate({ firmID, creator: uid }).then((id) =>
                setEditingForm({ id })
              )
            }
          />
        </ButtonsContainer>

        <Card title="Form/Document Templates">
          <FormsTable
            setEditingForm={setEditingForm}
            cloneTemplate={cloneTemplate}
            firmID={firmID}
          />
        </Card>

        <FormBuilderModal
          title={`${editingForm && editingForm.id ? 'Edit' : 'Add'} a Form`}
          fullScreen={true}
          isOpen={editingForm}
          handleClose={() => setEditingForm(null)}
        >
          <FormBuilder
            firmID={uid}
            templateID={editingForm && editingForm.id ? editingForm.id : null}
            handleSubmit={() => setEditingForm(null)}
          />
        </FormBuilderModal>
      </ColMd8>
    </Row>
  )
})

export default FormsTab

const ButtonsContainer = styled.div`
  text-align: right;
  margin-bottom: 10px;
`

const FormBuilderModal = styled(Modal)`
  &&& {
    .modal-body {
      background: #f9f9f9;
    }

    & .card {
      padding: 20px 30px;
    }
  }
`
