import React, { memo, useState, useMemo } from 'react'
import { Button } from 'semantic-ui-react'
import Table from 'components/partials/Table'
import ErrorsList from 'components/partials/ErrorsList'
import { formatCompositeInvestorNames } from 'actions/helpers'
import integrationsExportEnvelopes from 'actions/integrations/export/envelopes'
import {
  useFirm,
  useEnvelopes,
  useUser,
  usePeople,
  useTemplates,
} from 'contexts'
import styled from 'styled-components'
import DownloadExportButton from '../DownloadExportButton'
import { DeliveryMethodIcon } from 'components/icons'

const EnvelopesExportTable = memo(() => {
  const { userType, uid } = useUser()
  const { firmID } = useFirm()
  const { envelopes } = useEnvelopes()
  const { clients, advisors } = usePeople()
  const { templates } = useTemplates()

  const [selectedEnvelopes, setSelectedEnvelopes] = useState([])
  const [exporting, setExporting] = useState(false)
  const [exportSuccess, setExportSuccess] = useState(null)
  const [errors, setErrors] = useState([])

  const preppedColumns = [
    {
      Header: 'Client',
      accessor: 'client_name',
      headerClassName: 'text-center',
      className: 'text-center',
    },
  ]

  if (userType !== 'advisor') {
    preppedColumns.push({
      Header: 'Advisor',
      accessor: 'advisor_name',
      headerClassName: 'text-center',
      className: 'text-center',
    })
  }

  preppedColumns.push({
    Header: 'Document Type',
    accessor: 'template_name',
    headerClassName: 'text-center',
    className: 'text-center',
  })
  preppedColumns.push({
    Header: 'Delivery Method',
    headerClassName: 'text-center',
    Cell: (d) => {
      const row = d.cell.row.original
      return <DeliveryMethodIcon deliveryMethod={row.deliveryMethod} />
    },
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => preppedColumns, [])

  const data = useMemo(
    () =>
      envelopes && Object.keys(envelopes) && Object.keys(envelopes).length
        ? Object.keys(envelopes).map((envelopeID) => {
            const envelope = envelopes[envelopeID]
            const envelopeClient = clients[envelope.client]
            const envelopeAdvisor = advisors[envelope.advisor]
            const envelopeTemplate = templates[envelope.type]

            return {
              ...envelope,
              id: envelopeID,
              client_name: envelopeClient
                ? formatCompositeInvestorNames({ client: envelopeClient })
                : envelope.client,
              advisor_name: envelopeAdvisor
                ? advisors[envelope.advisor].name
                : envelope.advisor,
              template_name: envelopeTemplate
                ? envelopeTemplate.title
                : envelope.type.toUpperCase(),
            }
          })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleInitiateExport = async () => {
    setExportSuccess(false)
    setErrors([])
    const envelopesToExport = selectedEnvelopes.map((envelope) => {
      const {
        id,
        client_name,
        template_name,
        dateSent,
        status,
      } = envelope.original

      return {
        id,
        fileName: `${client_name} ${template_name} - ${dateSent.toDate()} [${status}].pdf`,
      }
    })

    if (envelopesToExport.length > 50) {
      setErrors([
        `You can only export a maximum of 50 envelopes at a time. You currently have ${envelopesToExport.length} envelopes selected. Please deselect some and try again.`,
      ])
      setExportSuccess(null)
      return null
    }

    let exportArgs = {
      firmID,
      envelopesToExport,
      setExporting,
      setExportSuccess,
      setErrors,
    }
    if ('advisor' === userType && uid) {
      exportArgs.advisorID = uid
    }

    const exportID = await integrationsExportEnvelopes(exportArgs)

    return exportID
  }

  return (
    <>
      <Table
        columns={columns}
        data={data}
        handleRowSelect={(selectedRows) => setSelectedEnvelopes(selectedRows)}
        selectedRows={selectedEnvelopes}
      />
      {exportSuccess ? (
        <>
          <p className="text-center text-success mt-4">
            Export successful! Please click below to download.
          </p>
          <StyledDownloadExportButton
            size="huge"
            primary
            content="Download Envelopes"
            exportID={exportSuccess}
          />
          <p className="text-center mt-4 instructional">
            <small>
              <em>
                If you're having issues, try selecting less envelopes and
                exporting in multiple batches.
                <br />
                Please contact Bitsy if the problem persists.
              </em>
            </small>
          </p>
        </>
      ) : (
        <InitiateExportButton
          size="huge"
          primary
          disabled={exporting}
          loading={exporting}
          onClick={handleInitiateExport}
        >
          Export Envelopes
        </InitiateExportButton>
      )}
      <ErrorsList errors={errors} />
    </>
  )
})

export default EnvelopesExportTable

const InitiateExportButton = styled(Button)`
  &&& {
    display: block;
    margin: 30px auto;
  }
`

const StyledDownloadExportButton = styled(DownloadExportButton)`
  &&& {
    display: block;
    margin: 30px auto;
  }
`
