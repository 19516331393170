export const assetsHeldAwayLabels = {
  stocks: 'Stocks',
  cash_value_life: 'Cash Value Life',
  bonds: 'Bonds',
  annuities: 'Annuities',
  options: 'Options',
  commodities: 'Commodities',
  mutual_funds: 'Mutual Funds',
  other: 'Other',
}

export const initialClient = {
  legal_name: '',
  legal_name_first: '',
  legal_name_last: '',
  email: '',
  tax_id: null,
  tax_id_type: null,
}
