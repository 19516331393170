import { useState, useEffect } from 'react'
import { listenToItem } from '../actions/db/dbActions'

const useItem = ({ itemType, uid, load = true, objectToReturn = null }) => {
  const [item, setItem] = useState(null)

  useEffect(() => {
    if (load && uid) {
      function handleItemChange(newItem) {
        setItem(newItem)
      }
      const unsubscribe = listenToItem(itemType, uid, handleItemChange)
      // Specify how to clean up after this effect:
      return function cleanup() {
        unsubscribe && unsubscribe()
      }
    } else if (objectToReturn) {
      setItem(objectToReturn)
    }
  }, [itemType, uid, load, objectToReturn])

  useEffect(() => {
    if (!uid && item) {
      setItem(null)
    }
  }, [uid, item])

  return item
}

export default useItem
