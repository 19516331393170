import React, { useContext } from 'react'

export const AdvisorContext = React.createContext({
  advisorID: '',
  advisor: null,
  setAdvisorID: () => {},
  // setAdvisorInfo: () => { }
})

export const useAdvisor = () => useContext(AdvisorContext)
