import { firebaseDB } from '../../config/firebaseConfig'

export function getItemDetails(type, uid, state = null, appState = null) {
  var db = firebaseDB

  var collection = type + 's'

  db.collection(collection)
    .doc(uid)
    .get()
    .then((doc) => {
      // console.log(doc)
      if (state) {
        state.setState({
          ...state.state,
          [type]: doc.data(),
        })
      }

      if (appState) {
        appState.setState({
          ...appState.state,
          [type]: doc.data(),
        })

        // console.log(appState.state)
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

export function listenToItem(type, uid, setItem = null) {
  if (!type || !uid || !uid.length) {
    return null
  }

  var db = firebaseDB

  var collection = type + 's'

  const loggingQuery = `${collection}/${uid}`
  console.log(`Listening to doc ${loggingQuery}...`)

  const unsubscribeItem = db
    .collection(collection)
    .doc(uid)
    .onSnapshot((doc) => {
      if (doc.exists && doc.data()) {
        if (setItem) {
          setItem(doc.data())
        }
      }
    })

  return unsubscribeItem
}

export function listenToItems(
  type,
  userType,
  uid,
  setItems = null,
  isUserItemList = null,
  where1 = null,
  where2 = null,
  where3 = null,
  where4 = null,
  orderByKey = null,
  orderByDirection = null,
  where5,
  where6,
  where7,
  where8,
  where,
  orderBy
) {
  if (!type || !userType || !uid || !uid.length) {
    return null
  }

  var db = firebaseDB

  var collection = type + 's'

  let loggingQuery = `${collection} where ${userType} == ${uid}`
  var query = db.collection(collection).where(userType, '==', uid)
  if (isUserItemList) {
    loggingQuery = `${userType}s/${uid}/${collection}`
    query = db
      .collection(userType + 's')
      .doc(uid)
      .collection(collection)
  }
  if (where1 && where2) {
    loggingQuery += ` where ${where1} == ${where2}`
    query = query.where(where1, '==', where2)
  }
  if (where3 && where4) {
    loggingQuery += ` where ${where3} == ${where4}`
    query = query.where(where3, '==', where4)
  }
  if (where5 && where6) {
    loggingQuery += ` where ${where5} == ${where6}`
    query = query.where(where5, '==', where6)
  }
  if (where7 && where8) {
    loggingQuery += ` where ${where7} == ${where8}`
    query = query.where(where7, '==', where8)
  }
  if (where && where.length) {
    where.forEach((clause) => {
      const { field, comparator, value } = clause

      loggingQuery += ` where ${field} ${comparator} ${value}`
      query = query.where(field, comparator, value)

      if (
        ['<', '<=', '!=', 'not-in', '>', '>='].includes(comparator) &&
        (!orderBy ||
          !orderBy.length ||
          (orderBy &&
            orderBy.length &&
            !orderBy.find((clause) => clause.field === field)))
      ) {
        query = query.orderBy(field)
      }
    })
  }
  if (orderBy && orderBy.length) {
    orderBy.forEach((clause) => {
      const { field, direction } = clause

      loggingQuery += ` ordering by ${field} in direction ${direction}`
      query = query.orderBy(field, direction)
    })
  }
  if (orderByKey && orderByDirection) {
    loggingQuery += ` ordering by ${orderByKey} in direction ${orderByDirection}`
    query = query.orderBy(orderByKey, orderByDirection)
  }
  if (userType === 'admin' || userType === 'super-admin') {
    loggingQuery = `${collection}`
    query = db.collection(collection)
  }

  console.log(`Listening to ${loggingQuery}...`)

  const unsubscribeItems = query.onSnapshot(
    (querySnapshot) => {
      var docs = null
      if (querySnapshot && querySnapshot.docs && querySnapshot.docs.length) {
        docs = {}
        querySnapshot.docs.map((doc) => {
          docs[doc.id] = doc.data()
          return doc
        })
      }

      if (setItems) {
        setItems(docs)
      }

      // state.setState({
      //   ...state.state,
      //   firm: doc.data()
      // })
    },
    (err) => {
      console.error(`Error listening to ${loggingQuery}: `, err)
    }
  )

  return unsubscribeItems
}

export function updateItemInfo(
  type,
  uid,
  field,
  value,
  state = null,
  returnRes = null
) {
  var collection = type + 's'

  return firebaseDB
    .collection(collection)
    .doc(uid)
    .update({
      [field]: value,
    })
    .then((docRef) => {
      if (state) {
        state.setState({
          addSuccess: true,
        })
      }

      if (returnRes) {
        return { type: 'success' }
      }
    })
    .catch((error) => {
      if (state) {
        state.setState({
          errors: [error],
        })
      }

      if (returnRes) {
        return { type: 'error', error: error }
      }
    })
}
