import React, { memo } from 'react'
import { Feed, Popup, Button, Label, Visibility } from 'semantic-ui-react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useUser, useEnvelopes, useTemplates } from 'contexts'
import { useItemList } from 'hooks'
import { getEnv } from 'helpers/_utils'
import { prettyTemplateName } from 'helpers/envelopes'
import { markNotificationAsRead } from 'actions/db/userNotifications'
import styled from 'styled-components'

dayjs.extend(relativeTime)

const NotificationsList = memo(() => {
  const { userType, uid } = useUser()
  const { envelopes } = useEnvelopes()
  const { templates } = useTemplates()

  const notifications = useItemList({
    itemType: 'notification',
    userType,
    uid,
    isUserItem: true,
    where1: 'env',
    where2: getEnv(),
    orderBy: [
      {
        field: 'date',
        direction: 'desc',
      },
    ],
  })

  const events =
    notifications &&
    Object.keys(notifications) &&
    Object.keys(notifications).length
      ? Object.keys(notifications).map((notificationID) => {
          const notification = notifications[notificationID]
          const { message, options, read } = notification

          // Format date
          let date = dayjs(notification.date.toDate())
          const oneWeekBefore = date.subtract(1, 'year')
          if (date.isAfter(oneWeekBefore)) {
            date = date.fromNow()
          } else {
            date = date.format('MMMM D, YYYY')
          }

          let icon
          let summary
          let extraText
          let meta
          switch (notification.type) {
            case 'envelope-completed':
              icon = 'check'
              if (options) {
                const { envelopeID } = options

                const envelope =
                  envelopes && envelopes[envelopeID]
                    ? envelopes[envelopeID]
                    : null

                let clientName = ''
                if (envelope && envelope.recipients) {
                  if (envelope.recipients.client_1) {
                    clientName += envelope.recipients.client_1.name
                  }
                  if (envelope.recipients.client_2) {
                    clientName += ` & ${envelope.recipients.client_2.name}`
                  }
                }

                summary = `${
                  envelope
                    ? `${clientName || 'Someone'}'s ${prettyTemplateName({
                        envelope,
                        templates,
                      })}`
                    : `The envelope with ID: ${envelopeID}`
                } has been completed.`
              }
              break
            case 'envelope-declined':
              icon = 'cancel'
              if (options) {
                const { envelopeID } = options

                const envelope =
                  envelopes && envelopes[envelopeID]
                    ? envelopes[envelopeID]
                    : null

                let clientName = ''
                if (envelope && envelope.recipients) {
                  if (envelope.recipients.client_1) {
                    clientName += envelope.recipients.client_1.name
                  }
                  if (envelope.recipients.client_2) {
                    clientName += ` & ${envelope.recipients.client_2.name}`
                  }
                }

                summary = `${
                  envelope
                    ? `${clientName || 'Someone'}'s ${prettyTemplateName({
                        envelope,
                        templates,
                      })}`
                    : `The envelope with ID: ${envelopeID}`
                } has been declined.`
              }
              break
            case 'envelope-signed':
              icon = 'pencil'
              if (options) {
                const { envelopeID, signer } = options
                const { email, signerID } = signer

                const envelope =
                  envelopes && envelopes[envelopeID]
                    ? envelopes[envelopeID]
                    : null

                let signerName =
                  envelope &&
                  envelope.recipients &&
                  envelope.recipients[signerID] &&
                  envelope.recipients[signerID].name
                    ? envelope.recipients[signerID].name
                    : null

                summary = `${signerName || email || signerID} has signed ${
                  envelope
                    ? `their ${prettyTemplateName({ envelope, templates })}`
                    : `the envelope with ID: ${envelopeID}`
                }.`
              }
              break
            default:
              break
          }

          if (!summary) {
            summary = message
          }

          return {
            id: notificationID,
            icon,
            date,
            summary,
            extraText,
            meta,
            read: read.toString(),
          }
        })
      : []

  const unreadEvents = events.filter((event) => event.read === 'false')

  // Mark events as read after they are viewed
  const handleEventsVisible = () => {
    unreadEvents.forEach(async (event) => {
      await markNotificationAsRead(userType, uid, event.id)
    })
  }

  return (
    <StyledPopup
      trigger={
        <ButtonContainer>
          <BorderlessButton basic icon="bell" size="big" compact />
          {!!unreadEvents.length && (
            <Label circular floating color="red" size="mini">
              {unreadEvents.length}
            </Label>
          )}
        </ButtonContainer>
      }
      on="click"
      position="bottom right"
      pinned
    >
      {events.length ? (
        <Visibility fireOnMount onOnScreen={handleEventsVisible}>
          <StyledFeed events={events} />
        </Visibility>
      ) : (
        <NoNotificationsMessage className="text-center">
          <small>
            You're all caught up! <br />
            We'll email you with any updates.
          </small>
        </NoNotificationsMessage>
      )}
    </StyledPopup>
  )
})

export default NotificationsList

const StyledPopup = styled(Popup)`
  &&& {
    padding: 0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
`

const BorderlessButton = styled(Button)`
  &&& {
    box-shadow: none !important;
    margin-right: 0;

    &:focus {
      background: transparent !important;
    }
  }
`

const StyledFeed = styled(Feed)`
  &&& {
    .event {
      padding: 0.833em 1em;
    }

    .event[read='true'] {
      background: transparent;
      transition: 0.2s all;
      transition-delay: 400ms;
    }

    .event[read='false'] {
      background: rgba(0, 0, 0, 0.05);
    }
  }
`

const NoNotificationsMessage = styled.div`
  padding: 15px;
`
