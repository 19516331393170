import React from 'react'
import { Tab, Button } from 'semantic-ui-react'
import DetailsTable from '../DetailsTable'
import {
  generateInvestmentExperienceItems,
  generateInvestmentProfileItems,
} from 'actions/iacHelpers'
import { generateGwnInvestmentExperienceItems } from 'helpers/firms/gwn'
import { firmIDs } from 'config/firmConfig'

const ClientProfileTabs = ({
  client = {},
  clientProfile,
  formsTabContent = '',
  actionsTabContent = '',
}) => {
  const panes = [
    {
      menuItem: {
        key: 'first_investor',
        icon: 'user',
        content: 'First Investor',
      },
      render: () => (
        <Tab.Pane>
          <DetailsTable
            rows={[
              { header: 'Legal Name', content: client.legal_name },
              {
                header: 'Email',
                content: (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <a
                      href={'mailto:' + client.email}
                      title={'Email ' + client.legal_name}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {client.email}
                    </a>
                    <Button
                      size="tiny"
                      style={{
                        marginLeft: '10px',
                        padding: '0.05em',
                        marginRight: '0',
                        background: 'transparent',
                      }}
                      icon="pencil"
                      onClick={() => {
                        clientProfile.setState({
                          showEditEmailModal: true,
                        })
                      }}
                    />
                  </div>
                ),
              },
              {
                header: 'Phone',
                content: client.phone,
              },
              {
                header: 'Address',
                content: (
                  <>
                    {client.firm === firmIDs['gwn'] ? (
                      <>
                        {client.address_mailing_street_2 ? (
                          <>
                            {client.address_mailing_street_2}
                            <br />
                            {client.address_mailing_city_2}{' '}
                            {client.address_mailing_city_2 &&
                            client.address_mailing_state_2
                              ? ', '
                              : ''}
                            {client.address_mailing_state_2}{' '}
                            {client.address_mailing_zip_2}
                          </>
                        ) : (
                          'Same as mailing address'
                        )}
                      </>
                    ) : (
                      <>
                        {client.address_street}
                        <br />
                        {client.address_city}{' '}
                        {client.address_city && client.address_state
                          ? ', '
                          : ''}
                        {client.address_state} {client.address_zip}
                      </>
                    )}
                  </>
                ),
              },
              {
                header: 'Mailing Address',
                content:
                  client.has_same_mailing_address === 'Yes' ? (
                    <small>
                      <em>Same as above</em>
                    </small>
                  ) : (
                    <>
                      {client.address_mailing_street}
                      <br />
                      {client.address_mailing_city}{' '}
                      {client.address_mailing_city &&
                      client.address_mailing_state
                        ? ', '
                        : ''}
                      {client.address_mailing_state}{' '}
                      {client.address_mailing_zip}
                    </>
                  ),
              },
              {
                header: 'Birthdate',
                content: client.birthdate,
              },
              client.firm === firmIDs['gwn']
                ? {
                    header: 'Employment Status',
                    content: client.employment_status,
                  }
                : {
                    header: 'Occupation',
                    content: client.occupation,
                  },
            ]}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 'second_investor',
        icon: 'user plus',
        content: 'Second Investor',
      },
      render: () => (
        <Tab.Pane>
          {client.second_investor_legal_name ? (
            <DetailsTable
              rows={[
                {
                  header: 'Legal Name',
                  content: client.second_investor_legal_name,
                },
                {
                  header: 'Preferred',
                  content: client.second_investor_preferred_name,
                },
                {
                  header: 'Relation',
                  content:
                    client.second_investor_relation === 'Other' &&
                    client.second_investor_relation_details
                      ? `Other: ${client.second_investor_relation_details}`
                      : client.second_investor_relation,
                },
                {
                  header: 'Email',
                  content: (
                    <>
                      <a
                        href={'mailto:' + client.second_investor_email}
                        title={'Email ' + client.second_investor_legal_name}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {client.second_investor_legal_name}
                      </a>
                    </>
                  ),
                },
                {
                  header: 'Phone',
                  content: client.second_investor_phone,
                },
                client.firm === firmIDs['gwn']
                  ? []
                  : {
                      header: 'Address',
                      content:
                        client.second_investor_has_same_address === 'Yes' ? (
                          <small>
                            <em>Same as First Investor</em>
                          </small>
                        ) : (
                          <>
                            {client.second_investor_address_street}
                            <br />
                            {client.second_investor_address_city}{' '}
                            {client.second_investor_address_city &&
                            client.second_investor_address_state
                              ? ', '
                              : ''}
                            {client.second_investor_address_state}{' '}
                            {client.second_investor_address_zip}
                          </>
                        ),
                    },
                client.firm === firmIDs['gwn']
                  ? []
                  : {
                      header: 'Mailing Address',
                      content:
                        client.second_investor_has_same_mailing_address ===
                        'Yes' ? (
                          <small>
                            <em>Same as above</em>
                          </small>
                        ) : (
                          <>
                            {client.second_investor_address_mailing_street}
                            <br />
                            {client.second_investor_address_mailing_city}{' '}
                            {client.second_investor_address_mailing_city &&
                            client.second_investor_address_mailing_state
                              ? ', '
                              : ''}
                            {client.second_investor_address_mailing_state}{' '}
                            {client.second_investor_address_mailing_zip}
                          </>
                        ),
                    },
                {
                  header: 'Birthdate',
                  content: client.second_investor_birthdate,
                },
                client.firm === firmIDs['gwn']
                  ? []
                  : {
                      header: 'Occupation',
                      content: client.second_investor_occupation,
                    },
                client.firm === firmIDs['gwn']
                  ? []
                  : {
                      header: 'Retirement Age',
                      content: client.second_investor_retirement_age,
                    },
              ]}
            />
          ) : (
            <p>
              <em>There is no second investor specified.</em>
            </p>
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 'forms_documents',
        icon: 'file alternate',
        content: 'Forms & Documents',
      },
      render: () => <Tab.Pane>{formsTabContent}</Tab.Pane>,
    },
    {
      menuItem: {
        key: 'financials',
        icon: 'dollar',
        content: 'Financials',
      },
      render: () => (
        <Tab.Pane>
          <DetailsTable
            rows={
              client.firm === firmIDs['gwn']
                ? [
                    { header: 'Annual Income', content: client.annual_income },
                    {
                      header: 'Net Worth',
                      content: client.net_worth,
                    },
                    {
                      header: 'Liquid Net Worth',
                      content: client.liquid_net_worth,
                    },
                    {
                      header: 'Tax Rate',
                      content: client.tax_rate,
                    },
                    {
                      header: 'Annual Expenses',
                      content: client.annual_expenses,
                    },
                    {
                      header: 'Special Expenses',
                      content: client.special_expenses,
                    },
                    {
                      header: 'Special Expenses Time frame',
                      content: client.special_expenses_time_frame,
                    },
                  ]
                : [
                    {
                      header: 'Net Worth',
                      content: client.net_worth,
                    },
                    {
                      header: 'Liquid Net Worth',
                      content: client.net_worth_liquid,
                    },
                    {
                      header: 'Federal Income Tax Bracket',
                      content: client.tax_bracket,
                    },
                    {
                      header: 'Investable Assets',
                      content: client.investable_assets,
                    },
                    {
                      header: 'Income Range',
                      content: client.income_range,
                    },
                    {
                      header: 'Portfolio Value',
                      content: client.portfolio_value,
                    },
                    {
                      header: 'Portfolio Value',
                      subheader: '(Qualified)',
                      content: client.portfolio_value_qualified,
                    },
                    {
                      header: 'Portfolio Value',
                      subheader: '(Non-Qualified)',
                      content: client.portfolio_value_non_qualified,
                    },
                    {
                      header: 'Residence Value',
                      content: client.residence_value,
                    },
                    {
                      header: 'Jurisdiction',
                      content: client.jurisdiction,
                    },
                  ]
            }
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 'profile_experience',
        icon: 'folder open',
        content: 'Profile & Experience',
      },
      render: () => (
        <Tab.Pane>
          <DetailsTable
            rows={[
              client.firm === firmIDs['gwn']
                ? {
                    header: 'Investment Objective',
                    content: client.investment_objective,
                  }
                : {
                    header: 'Investment Profiles',
                    content: (
                      <ul className="investment-profiles">
                        {generateInvestmentProfileItems(
                          client.investment_profiles
                        )}
                      </ul>
                    ),
                  },
              client.firm === firmIDs['gwn']
                ? {
                    header: 'Risk Tolerance',
                    content: client.subtotal_risk_tolerance,
                  }
                : [],
              client.firm === firmIDs['gwn']
                ? {
                    header: 'Time Horizon',
                    content: client.subtotal_time_horizon,
                  }
                : [],
              client.firm === firmIDs['gwn']
                ? {
                    header: 'Time Horizon',
                    content: client.subtotal_time_horizon,
                  }
                : [],
              {
                header: 'Investment Experience',
                content: (
                  <ul className="investment-experience">
                    {client.firm === firmIDs['gwn']
                      ? generateGwnInvestmentExperienceItems(client)
                      : generateInvestmentExperienceItems(
                          client.investment_experience_items
                        )}
                  </ul>
                ),
              },
            ]}
          />
        </Tab.Pane>
      ),
    },
    client.firm === firmIDs['gwn']
      ? []
      : {
          menuItem: {
            key: 'retirement',
            icon: 'road',
            content: 'Retirement',
          },
          render: () => (
            <Tab.Pane>
              <DetailsTable
                rows={[
                  {
                    header: 'Retirement Age',
                    content: client.retirement_age,
                  },
                  {
                    header: 'Needs',
                    content: client.retirement_needs,
                  },
                  {
                    header: 'Desired Income',
                    content: client.retirement_income,
                  },
                ]}
              />
            </Tab.Pane>
          ),
        },
    {
      menuItem: {
        key: 'actions',
        icon: 'briefcase',
        content: 'Actions',
      },
      render: () => <Tab.Pane>{actionsTabContent}</Tab.Pane>,
    },
  ]

  return (
    <Tab
      menu={{ fluid: true, vertical: true, tabular: false, borderless: true }}
      panes={panes}
    />
  )
}

export default ClientProfileTabs
