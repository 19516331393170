export const firmIDs = {
  centennial: 'eIp3cJkIekfaAytB7wuw24N2yxB2',
  gwn: 'IANRj3JYK5Mt0ydynGQx5jJlBtC2',
  hobart: 'dkrmXUK1wLUn8zGwIxy0pmGJIPi1',
  jalinski: 'aWUSNWBsYiajVQ3ar6boZsqkvQe2',
  mentzer: 'YDyRm0UX6QR1iOEGBCjoCiLwTk92',
  oneday: 'fcUAw9wms4MQcHcjY2WaFkmD95E2',

  // Test Firms
  aat: 'rk7jhCCYZVelrSGT4QqNil7GoAv2',
  agt: 'JokWWgTKqmXtJPWcfBBURTuBY8o2',
  dgt: 'jb8ngtrCVCegQd1YJY11GARDl0i1',
  lvt: 'T0MAKjLVJTPR15z5O0wYQLQvk9p1',
  mtg: 'MkYniMcmY6gdfIjF2fwT2tIU93l2',
  test: 'VzgDTb7mUZaCVhD6X8ELuNQE6QY2',
  DG: 'jb8ngtrCVCegQd1YJY11GARDl0i1',
}

const devFirmIDs = [
  'TEST_FIRM',
  firmIDs['aat'],
  firmIDs['agt'],
  firmIDs['dgt'],
  firmIDs['lvt'],
  firmIDs['mtg'],
  firmIDs['test'],
  firmIDs['DG'],
]

export const isDevFirm = (firmID) => {
  if (firmID && devFirmIDs.includes(firmID)) {
    return true
  }

  return false
}

const legacyFirmIDs = [
  firmIDs['centennial'],
  firmIDs['hobart'],
  firmIDs['jalinski'],
  firmIDs['mentzer'],
  firmIDs['oneday'],
  firmIDs['DG'],
  firmIDs['test'],
]

export const isLegacyFirm = (firmID) => {
  if (firmID && legacyFirmIDs.includes(firmID)) {
    return true
  }

  return false
}

export const isDemoFirm = (firmID) => {
  if (firmID === firmIDs['test']) {
    return true
  }

  return false
}
