import { firebaseDB } from '../../config/firebaseConfig'
import { updateUserTaskStatus } from './userTaskActions'
// import { deleteUser } from '../auth/authActions'

export const addPendingClientFieldsToDB = (
  client,
  suitability,
  state,
  uid,
  taskDetails = null
) => {
  var db = firebaseDB

  db.collection('clients')
    .doc(uid)
    .update({
      pendingChanges: {
        client: client,
        suitability: suitability,
      },
    })
    .then(function (docRef) {
      if (taskDetails) {
        updateUserTaskStatus('client', uid, taskDetails.id, 'complete')
      }

      // state.setState({
      //   'addSuccess': true
      // })
      // console.log("Client updated with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding client: ", error)
    })
}
