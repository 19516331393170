import React, { Component } from 'react'
import { availableServices } from '../../../config/appConfig'
import { updateFirmServiceStatus } from '../../../actions/adminDbActions'
import Field from '../../forms/Field'
import { Row } from 'components/styled'

class FirmProfile extends Component {
  constructor(props) {
    super(props)

    this.handleFirmServiceChange = this.handleFirmServiceChange.bind(this)
  }

  handleFirmServiceChange(event) {
    event.preventDefault()

    const { profile } = this.props

    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    updateFirmServiceStatus(name, value, profile.id)
  }

  render() {
    const { profile } = this.props

    if (profile) {
      var advisorCount = profile.advisors.length

      // Add additional advisor to count if admin is advisor
      if (profile.adminIsAdvisor) {
        advisorCount += 1
      }

      var profileHasServices = {}
      Object.keys(availableServices).map((service_key, service_idx) => {
        if (
          profile.hasServices &&
          (profile.hasServices[service_key] === true ||
            profile.hasServices[service_key] === false)
        ) {
          // Handle set values
          profileHasServices[service_key] = profile.hasServices[service_key]
        } else {
          // Handle undefined/null values
          profileHasServices[service_key] = false
        }

        return service_key
      })

      return (
        <div className="profile">
          <h1>{profile.name}</h1>
          <Row className="row">
            <h2>
              <span className="label">Status: </span>{' '}
              {profile.inactive ? 'Inactive' : 'Active'}
            </h2>
            <div className="date-added">
              <span className="label">CRD: </span>
              {profile.crd}
            </div>
          </Row>
          <div className="module">
            <h3>Contact</h3>
            <p>
              <a
                href={'mailto:' + profile.email}
                title={'Email ' + profile.contact_name}
                target="_blank"
                rel="noopener noreferrer"
              >
                {profile.email}
              </a>
            </p>
            <p>
              {profile.contact_name}
              <br />
              {profile.address_street}
              <br />
              {profile.address_city}, {profile.address_state}{' '}
              {profile.address_zip}
            </p>
          </div>

          <div className="module">
            <h3>Advisors</h3>
            <p>{advisorCount}</p>
          </div>

          <div className="module">
            <h3>Services</h3>

            <form id="firm-services">
              {Object.keys(profileHasServices).map((service_key) => (
                <Field
                  key={service_key}
                  type="switch"
                  id={service_key}
                  value={profileHasServices[service_key]}
                  label={availableServices[service_key]}
                  handleInputChange={this.handleFirmServiceChange}
                />
              ))}
            </form>
          </div>
        </div>
      )
    } else {
      return ''
    }
  }
}

export default FirmProfile
