// ? This file is a replica of https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/semantic-ui/src/RawErrors/RawErrors.js for internal use

/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import { Label, List } from 'semantic-ui-react'
import myxss from 'components/forms/tf-fields/FormElements/myxss'

/**
 *
 * @param errors
 * @param displayError
 * @returns {*}
 * @constructor
 * @return {null}
 */
function RawErrors({ errors, options }) {
  const { pointing, size } = options
  if (errors && errors.length > 0) {
    return (
      <Label
        color="red"
        pointing={pointing || 'above'}
        size={size || 'small'}
        basic
      >
        <List bulleted>
          {errors.map((error) => (
            <List.Item
              key={shortid.generate()}
              content={
                <span
                  dangerouslySetInnerHTML={{
                    __html: myxss.process(error),
                  }}
                />
              }
            />
          ))}
        </List>
      </Label>
    )
  }
  return null
}

RawErrors.defaultProps = {
  options: {
    pointing: 'above',
    size: 'small',
  },
}

RawErrors.propTypes = {
  options: PropTypes.object,
  errors: PropTypes.array,
}

export default RawErrors
