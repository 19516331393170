import React, { memo } from 'react'
import styled from 'styled-components'

const Avatar = memo(({ user, className, ...props }) => {
  if (!user) {
    return ''
  }

  let name = ''
  if (user.name) {
    name = user.name
  } else if (user.legal_name) {
    name = user.name
  }

  let initials = ''
  if (name) {
    initials = name.match(/\b\w/g) || []
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }

  return (
    <StyledAvatar {...props}>
      {user.image ? (
        <img src={user.image} alt={name} />
      ) : user.logo ? (
        <img src={user.logo} alt={name} />
      ) : (
        <span className="initials">{initials}</span>
      )}
    </StyledAvatar>
  )
})

export default Avatar

const StyledAvatar = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 42px;
  height: 42px;
  border: 1px solid #e3e3e3;
  background: #fff;
  border-radius: 50%;
  font-weight: bold;
  overflow: hidden;

  img {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  span.initials {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
