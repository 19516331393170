import React, { memo } from 'react'
import { FluidContainer, Col, Row } from 'components/styled'
import styled from 'styled-components'
import { colors } from 'styles'

export default memo(function TabHeader(props) {
  const { children, heading, subhead, className } = props

  return (
    <StyledHeader>
      <FluidContainer>
        <StyledRow>
          <Col className={className ? className : 'col-sm-12'}>
            {heading && <Heading>{heading}</Heading>}
            {subhead && <Subhead>{subhead}</Subhead>}

            {children}
          </Col>
        </StyledRow>
      </FluidContainer>
    </StyledHeader>
  )
})

const StyledHeader = styled.div`
  padding: 15px 0.5rem;

  @media (min-width: 1200px) {
    padding: 27px 66px 22px;
  }
`

const StyledRow = styled(Row)`
  margin-bottom: 0.5rem;
`

const Heading = styled.h1`
  font-size: 42px;
  line-height: 56px;
  color: #000;
  margin-bottom: 6px;
  font-weight: 500;
  margin: 0;
`

const Subhead = styled.p`
  color: ${colors.grayHeadline};
  font-size: 18px;
  line-height: 23px;
  margin-top: 15px;
  margin-bottom: 0;
`
