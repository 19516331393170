import React, { memo, useState, useEffect } from 'react'
import { Button } from 'semantic-ui-react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useUser, useFirm } from 'contexts'
import { useItemList } from 'hooks'

const DownloadExportButton = memo(
  ({ exportID, exportDownloadUrl, content = 'Download', ...props }) => {
    const { userType, uid } = useUser()
    const { firmID } = useFirm()
    const [downloadUrl, setDownloadUrl] = useState(exportDownloadUrl)

    const exports = useItemList({
      itemType: 'export',
      userType: userType === 'firm-admin' ? 'firm' : userType,
      uid: userType === 'firm-admin' ? firmID : uid,
    })

    useEffect(() => {
      if (
        !downloadUrl &&
        exportID &&
        exports &&
        exports[exportID] &&
        exports[exportID].signedUrl
      ) {
        setDownloadUrl(exports[exportID].signedUrl)
      }
    }, [exportID, exports, downloadUrl])

    const buttonProps = props
    delete buttonProps.downloadUrl

    const downloadFileName = `Bitsy_Export_${dayjs().format(
      'YYYY-MM-DDTHHmmss'
    )}`

    return (
      <ButtonContainer>
        <Button
          {...buttonProps}
          as="a"
          href={downloadUrl}
          download={downloadFileName}
          disabled={!!downloadUrl ? false : true}
          loading={!!downloadUrl ? false : true}
        >
          {content}
        </Button>
      </ButtonContainer>
    )
  }
)

export default DownloadExportButton

const ButtonContainer = styled.div`
  text-align: center;

  & > a {
    display: inline-block !important;
  }
`
