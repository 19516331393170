import React, { memo } from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

const SubmitSection = memo(
  ({ handleSubmit = null, handleBack = null, loading }) => (
    <StyledSubmitSection>
      {handleBack && (
        <>
          <Button
            content="Back"
            type="button"
            circular
            icon="left arrow"
            data-id="client-details-form-back"
            onClick={handleBack}
            loading={loading}
            disabled={loading}
            labelPosition="left"
          />
          <Spacer />
        </>
      )}

      <Button
        content="Next"
        type="submit"
        circular
        icon="right arrow"
        primary
        data-id="client-details-form-next"
        onClick={handleSubmit}
        loading={loading}
        disabled={loading}
        labelPosition="right"
      />
    </StyledSubmitSection>
  )
)

export default SubmitSection

const StyledSubmitSection = styled.div`
  text-align: center;
`

const Spacer = styled.div`
  width: 30px;
  display: inline-block;
`
