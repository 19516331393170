// Custodians
export const custodian_options = {
  etrade: {
    id: 'etrade',
    preferred_name: 'E*Trade',
    legal_name: 'E*Trade Bank',
    address_street: '671 North Glebe Road, 16th Floor',
    address_city: 'Arlington',
    address_state: 'VA',
    address_zip: '22203',
    phone: '800-387-2331',
  },
  fidelity: {
    id: 'fidelity',
    preferred_name: 'Fidelity',
    legal_name: 'Fidelity Investments',
    address_street: '100 Crosby Parkway KC1H',
    address_city: 'Covington',
    address_state: 'KY',
    address_zip: '41015-0037',
    phone: '800-972-2155',
  },
  schwab: {
    id: 'schwab',
    preferred_name: 'Schwab',
    legal_name: 'Charles Schwab & Co.',
    address_street: '211 Main Street',
    address_city: 'San Francisco',
    address_state: 'CA',
    address_zip: '94105',
    phone: '866-855-9102',
  },
  tdameritrade: {
    id: 'tdameritrade',
    preferred_name: 'TD Ameritrade',
    legal_name:
      'TD Ameritrade Institutional, a division of TD Ameritrade, Inc. Member FINRA/SIPC',
    address_street: '200 S 108th Ave',
    address_city: 'Omaha',
    address_state: 'NE',
    address_zip: '68154',
    phone: '800-669-3900',
  },
  vanguard: {
    id: 'vanguard',
    preferred_name: 'Vanguard',
    legal_name: 'Vanguard',
    address_street: '455 Devon Park Drive',
    address_city: 'Wayne',
    address_state: 'PA',
    address_zip: '19087-1815',
    phone: '800-349-5814',
  },
  other: {
    id: 'other',
    preferred_name: 'Other Custodian',
    legal_name: '',
    address_street: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    phone: '',
  },
}

// Investment Profile Quiz Questions
export const quiz_questions = [
  {
    question: 'I plan to use money from my investments in:',
    options: [
      { description: 'Less than 3 years', pts: 3, termPts: 3 },
      { description: '3-5 years', pts: 5, termPts: 5 },
      { description: '6-10 years', pts: 7, termPts: 7 },
      { description: '11 years or more', pts: 10, termPts: 10 },
    ],
  },
  {
    question:
      'Once I start spending money from my investments, I plan to use all of the money:',
    options: [
      { description: 'Less than 2 years', pts: 3, termPts: 3 },
      { description: '2-5 years', pts: 5, termPts: 5 },
      { description: '6-10 years', pts: 7, termPts: 7 },
      { description: '11 years or more', pts: 10, termPts: 10 },
    ],
  },
  {
    question: 'As an investor, I feel:',
    options: [
      { description: 'Clueless', pts: 3 },
      { description: 'Limited', pts: 5 },
      { description: 'Capable', pts: 7 },
      { description: 'Confident', pts: 10 },
    ],
  },
  {
    question:
      'Consider this:  The stock market as a whole just declined 25%, as well as some or all of your positions, what do you do?',
    options: [
      { description: 'Sell everything, move to cash', pts: 3 },
      { description: 'Sell some things, move some to cash', pts: 5 },
      { description: 'Do nothing', pts: 7 },
      { description: 'Buy more', pts: 10 },
    ],
  },
  {
    question: 'As an investor, my main objective is:',
    options: [
      { description: 'Not losing money', pts: 3 },
      {
        description: 'Equally weighted with making money & not losing money',
        pts: 5,
      },
      { description: 'Making money', pts: 7 },
    ],
  },
  {
    question: 'When investing, which risk/reward potential do you prefer?',
    options: [
      { description: 'Low risk/low reward', pts: 3 },
      { description: 'Moderate risk/moderate reward', pts: 5 },
      { description: 'High risk/high reward', pts: 7 },
    ],
  },
  {
    question:
      'Pick one of the following best/worst case scenarios that suit your preference.  You’re willing to:',
    options: [
      {
        description: 'Lose 5% in hopes of making 10%, on average you make 5%',
        pts: 3,
      },
      {
        description: 'Lose 9% in hopes of making 14%, on average you make 8%',
        pts: 5,
      },
      {
        description: 'Lose 12% in hopes of making 22%, on average you make 12%',
        pts: 7,
      },
      {
        description: 'Lose 20% in hopes of making 35%, on average you make 16%',
        pts: 10,
      },
    ],
  },
]
