import { firebaseFunctionsCustomDomain } from '../../../config/firebaseConfig'

const integrationsDocusignFinalizeEnvelope = firebaseFunctionsCustomDomain.httpsCallable(
  'integrationsDocusignFinalizeEnvelope'
)

export default async function finalizeEnvelope({
  envelopeID,
  signers,
  firmID,
  deliveryMethod,
  setProcessing,
  nextStep,
}) {
  try {
    const result = await integrationsDocusignFinalizeEnvelope({
      envelopeID,
      signers,
      firm: firmID,
      deliveryMethod,
    })
    setProcessing({ inFlight: false, finalizeType: deliveryMethod })
    nextStep && nextStep()
    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
