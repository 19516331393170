import React, { memo } from 'react'
import styled from 'styled-components'

const BrandLogo = memo(({ brand, ...props }) => {
  if (!brand) {
    return ''
  }

  return (
    <LogoContainer {...props}>
      {brand.logo ? (
        <ImageLogo src={brand.logo} alt={brand.name} />
      ) : (
        <TextLogo>{brand.name}</TextLogo>
      )}
    </LogoContainer>
  )
})

export default BrandLogo

const LogoContainer = styled.div``

const ImageLogo = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center center;
`

const TextLogo = styled.div``
