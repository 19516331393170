import React, { memo, useState, useEffect } from 'react'
import ClientFormSaveOptions from './ClientFormSaveOptions'
import FirmLogo from '../../partials/FirmLogo'
import LegacyTemplateSelector from './LegacyTemplateSelector'
import TemplateSelector from '../TemplateSelector/TemplateSelector'
import { IACForm, K401Form, SuitabilityForm, TemplateForm } from './forms'
import Loader from '../../partials/Loader'
import { SuccessIcon } from '../../icons'
import { useFirm, useDisclosures } from 'contexts'
import { clientUpdateClient } from '../../../actions/clientDbActions'
import { updateClient } from '../../../actions/dbActions'
import { addTaskToDB } from '../../../actions/db/taskActions'
import {
  sendDocument,
  signDocument,
} from '../../../actions/integrations/docusign'
import { dateNow } from '../../../actions/helpers'
import { useItem, useItemList } from '../../../hooks'
import { firmIDs, isDemoFirm } from '../../../config/firmConfig'
import { isLegacyFirm } from '../../../config/firmConfig'
import './ClientForm.scss'

const ClientForm = memo(
  ({
    type = null,
    location = null,
    client = null,
    clientID = null,
    // firm = null,
    firmID = null,
    firmAdvisors = null,
    advisor = null,
    advisorID = null,
    reviewType = null,
    taskDetails = null,
    setAddSuccess = null,
    passedAdditionalDocuments = null,
  }) => {
    const { firm } = useFirm()
    const { firmDisclosures } = useDisclosures()

    const [docType, setDocType] = useState(type)
    const [additionalDocuments, setAdditionalDocuments] = useState([])
    const [envelopeID, setEnvelopeID] = useState(null)
    const [signersLoading, setSignersLoading] = useState(null)
    const [awaitingFirmSignature, setAwaitingFirmSignature] = useState(null)
    const [docSending, setDocSending] = useState(null)
    const [docSubmitSuccess, setDocSubmitSuccess] = useState(null)
    const [savingClient, setSavingClient] = useState(client)
    const [savingClientID, setSavingClientID] = useState(clientID)
    const [clientAdvisorID, setClientAdvisorID] = useState(advisorID)
    const [template, setTemplate] = useState(null)
    const [docTypeName, setDocTypeName] = useState(null)

    const clientAdvisorDetails = useItem({
      itemType: 'advisor',
      uid: clientAdvisorID,
      load: clientAdvisorID,
    })

    const advisorDisclosures = useItemList({
      itemType: 'disclosure',
      userType: 'advisor',
      uid: clientAdvisorID,
      isUserItem: true,
      load: clientAdvisorID,
    })

    const handleUpdateDocTypeName = () => {
      // Set up docTypeName
      var docTypeNameTemp
      switch (docType) {
        case 'iac':
          docTypeNameTemp = 'IAC'
          break
        case 'k401':
          docTypeNameTemp = '401(k) Agreement'
          break
        case 'suitability':
          docTypeNameTemp = 'Suitability Form'
          break
        case 'suitability-save':
          docTypeNameTemp = 'Suitability Form'
          break
        default:
          docTypeNameTemp = template?.title
          break
      }
      console.log('template.title', template?.title)
      setDocTypeName(docTypeNameTemp)
    }

    useEffect(() => {
      ;(async () => {
        // await getTemplate({ firmID, templateID: docType, setTemplate })
        console.log('docType changed', docType)
        console.log('template.title', template?.title)
        handleUpdateDocTypeName()
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, docType])

    useEffect(() => {
      if (envelopeID && envelopeID.length) {
        setSignersLoading(false)
      }
    }, [envelopeID])

    useEffect(() => {
      if (docSubmitSuccess) {
        setDocSending(false)
      }
    }, [docSubmitSuccess])

    // Get advisor details
    var advisorObject = advisor
    var getAdvisorObject = false
    if (!advisor && clientAdvisorID && clientAdvisorID.length) {
      getAdvisorObject = true
    }
    advisorObject = useItem({
      itemType: 'advisor',
      uid: clientAdvisorID,
      load: getAdvisorObject,
      objectToReturn: advisor,
    })

    // Get envelope details
    var loadEnvelopeDoc = false
    if (envelopeID && envelopeID.length) {
      loadEnvelopeDoc = true
    }
    const envelopeDoc = useItem({
      itemType: 'envelope',
      uid: envelopeID,
      load: loadEnvelopeDoc,
    })

    // Set doc submit success if all signers have signed
    useEffect(() => {
      if (envelopeDoc && envelopeDoc.recipients) {
        const signers = envelopeDoc.recipients
        const signerKeys = Object.keys(signers)
        const signersCount = signerKeys.length
        const signersSigned = signerKeys.filter(
          (signerID) => signers[signerID].status === 'Signed'
        )
        const firmSigner = signerKeys.filter(
          (signerID) => signers[signerID].signerID === 'firm'
        )
        if (signersSigned.length === signersCount) {
          setDocSubmitSuccess(true)
        } else if (
          signersSigned.length === signersCount - 1 &&
          firmSigner.length === 1
        ) {
          // Set doc submit success with awaiting firm signing message
          setAwaitingFirmSignature(true)
          setDocSubmitSuccess(true)
        }
      }
    }, [envelopeDoc])

    const addAdditionalDocuments = (additionalDocumentsArray) => {
      // Documents must come in as { documentID: { documentContents } }
      var newAdditionalDocuments = additionalDocuments
      if (additionalDocumentsArray && additionalDocumentsArray.length) {
        additionalDocumentsArray.forEach((documents) => {
          if (
            documents &&
            Object.keys(documents) &&
            Object.keys(documents).length
          ) {
            Object.keys(documents).forEach((documentID) => {
              newAdditionalDocuments.push({
                documentID: documentID,
                document: documents[documentID],
              })
            })
          }
        })
      }
      setAdditionalDocuments(newAdditionalDocuments)
    }

    // Add additional documents if passed in props (passedAdditionalDocuments)
    useEffect(() => {
      if (passedAdditionalDocuments && passedAdditionalDocuments.length) {
        addAdditionalDocuments(passedAdditionalDocuments)
      }
    })

    if (!firm || firm.isInGoodStanding === null) {
      return ''
    }

    const handleSaveOptionSelect = (saveType = 'sign') => {
      let savingDocType = docType
      if (docType === 'suitability-save') {
        savingDocType = 'suitability'
      }

      switch (saveType) {
        case 'sign':
          setSignersLoading(true)
          signDocument(
            template ? template.id : null,
            template ? null : savingDocType,
            savingClientID,
            savingClient,
            advisorObject,
            firm,
            additionalDocuments,
            false,
            setEnvelopeID
          )
          break
        case 'send':
          setDocSending(true)
          sendDocument(
            template ? template.id : null,
            template ? null : savingDocType,
            savingClientID,
            savingClient,
            advisorObject,
            firm,
            additionalDocuments,
            setDocSubmitSuccess
          )
          break
        default:
          setDocSubmitSuccess(true)
          break
      }
    }

    const saveClientToDB = (clientObject, generatedClientUID) => {
      // Add dateAdded for new clients
      if (!client) {
        clientObject['dateAdded'] = new Date()
      }

      setSavingClient(clientObject)
      setSavingClientID(generatedClientUID)

      // var source = "register"

      // // Set up firm name
      // var firmName = firm.name
      // if (firmID && firmID === firmIDs['hobart']) {
      //   firmName = "Hobart Wealth"
      // }

      // // Email pending document to client
      // emailDocumentPending('iac', client.firm, client.legal_name, client.email, advisor.name, advisor.email, firmName, firm.executed_contract_email, firmDisclosures, advisorDisclosures)

      // TODO: Set pendingIAC for clients?
      // clientObject['hasPendingIAC'] = true

      // if (source === 'register') {
      //   // Add client to database
      //   if (location !== 'dashboard' && location !== 'client-profile' && location !== 'advisor-dashboard') {
      //     clientAddOnboardedClientToDB(clientObject, this, savingClientID)
      //   } else {
      //     addOnboardedClientToDB(clientObject, this, savingClientID)
      //   }
      // } else {

      let removePendingChanges = false
      let removePendingSuitabilityForm = false
      if (type === 'suitability-save') {
        removePendingChanges = true
        if (firmID === firmIDs['jalinski']) {
          removePendingSuitabilityForm = true
        }
      }

      // Update client in database
      if (
        location !== 'dashboard' &&
        location !== 'client-profile' &&
        location !== 'advisor-dashboard'
      ) {
        clientUpdateClient(clientObject, this, generatedClientUID)
      } else {
        updateClient(
          clientObject,
          this,
          generatedClientUID,
          removePendingChanges,
          removePendingSuitabilityForm
        )
      }

      // Add upsertClientToIntegrations task if not an in-progress suitability form
      if (type !== 'suitability') {
        const taskOptions = {
          firmID: firmID,
          advisorID: clientAdvisorID,
          client: clientObject,
        }
        addTaskToDB('integrationsUpsertClient', new Date(), taskOptions)
      }

      // }

      // switch (saveType) {
      //   case 'send':

      //     var source = "register"

      //     // // Set up firm name
      //     // var firmName = firm.name
      //     // if (firmID && firmID === firmIDs['hobart']) {
      //     //   firmName = "Hobart Wealth"
      //     // }

      //     // // Email pending document to client
      //     // emailDocumentPending('iac', client.firm, client.legal_name, client.email, advisor.name, advisor.email, firmName, firm.executed_contract_email, firmDisclosures, advisorDisclosures)

      //     var clientObject = client

      //     var pendingDocName = 'hasPending'
      //     switch (type) {
      //       case 'iac':
      //         pendingDocName += 'IAC'
      //         break
      //       case 'suitability-form':
      //         pendingDocName += 'SuitabilityForm'
      //         break
      //       default:
      //         pendingDocName += type
      //         break
      //     }

      //     clientObject[pendingDocName] = true

      //     if (source === 'register') {
      //       // Add client to database
      //       if (location !== 'dashboard' && location !== 'client-profile' && location !== 'advisor-dashboard') {
      //         clientAddOnboardedClientToDB(clientObject, this, uid)
      //       } else {
      //         addOnboardedClientToDB(clientObject, this, uid)
      //       }
      //     } else {
      //       // Update client in database
      //       if (location !== 'dashboard' && location !== 'client-profile' && location !== 'advisor-dashboard') {
      //         clientUpdateClient(clientObject, this, uid)
      //       } else {
      //         updateClient(clientObject, this, uid)
      //       }
      //     }
      //     break
      //   default:
      //     // var database = 'main'

      //     // if (type === 'advisor-sign') {

      //     //   // // Set up firm name
      //     //   // var firmName = firm.name
      //     //   // if (firmID && firmID === 'dkrmXUK1wLUn8zGwIxy0pmGJIPi1') {
      //     //   //   firmName = "Hobart Wealth"
      //     //   // }

      //     //   // // Save PDF
      //     //   // saveDocument('suitability-form', this, client.legal_name, client.email, advisor.name, advisor.email, firmName, firm.executed_contract_email, firm.executed_contract_email_body, firmDisclosures, advisorDisclosures, client.firm, uid, database, client.suitability_forms, taskDetails)
      //     // }

      //     if (type === 'client-sign') {
      //       // Add pending client fields to database
      //       addPendingClientFieldsToDB(client, suitability, this, uid, taskDetails)
      //     } else if (source === 'register') {
      //       // Add client to database
      //       if (location !== 'dashboard' && location !== 'client-profile' && location !== 'advisor-dashboard') {
      //         clientAddOnboardedClientToDB(client, this, uid)
      //       } else {
      //         addOnboardedClientToDB(client, this, uid)
      //       }
      //     } else {

      //       // var removePendingChanges = false
      //       // if (type === 'advisor-sign') {
      //       //   removePendingChanges = true
      //       // }

      //       // Update client in database
      //       if (location !== 'dashboard' && location !== 'client-profile' && location !== 'advisor-dashboard') {
      //         clientUpdateClient(client, this, uid)
      //       } else {
      //         updateClient(client, this, uid, removePendingChanges)
      //       }
      //     }
      // }
    }

    var mainClass = `client-form`
    if (docType) {
      mainClass += ` add-${docType}`
    }
    if (
      location &&
      (location === 'dashboard' || location === 'client-profile')
    ) {
      mainClass += ' embedded'
    } else if (firm && firm.module && firm.module.embedded) {
      mainClass += ' embedded'
    } else {
      mainClass += ' widget'
    }

    if (!docType) {
      mainClass += ' doc-type-selector'

      return (
        <div className={mainClass}>
          <div className="register-wrapper">
            <div className="register-container">
              <h2>
                What kind of document would you like to make for this client?
              </h2>
              {isLegacyFirm(firmID) ? (
                <>
                  <LegacyTemplateSelector setDocType={setDocType} />
                  {!!(isDemoFirm(firmID) || firmID === firmIDs['jalinski']) && (
                    <TemplateSelector
                      setTemplateID={setDocType}
                      setTemplate={setTemplate}
                      hideNoneText={true}
                    />
                  )}
                </>
              ) : (
                <TemplateSelector
                  setTemplateID={setDocType}
                  setTemplate={setTemplate}
                />
              )}
            </div>
          </div>
        </div>
      )
    }

    if (docSubmitSuccess === true) {
      mainClass += ' doc-submit-success'

      return (
        <div className={mainClass}>
          {/* {location !== 'dashboard' && location !== 'client-profile' &&
          <WidgetToggle />} */}
          <div className="register-wrapper">
            <div className="register-container">
              <div className="icon-container">{SuccessIcon}</div>
              <h2>
                Welcome to
                <br />
                {firm.name}!
              </h2>

              <p>
                Signup was successful.{' '}
                {awaitingFirmSignature
                  ? `${firm.name} will now review and we will email you the final documents once they are completed!`
                  : 'Please check your email!'}
              </p>
            </div>
          </div>
        </div>
      )
    }

    const saveOptions = (
      <ClientFormSaveOptions
        clientID={savingClientID}
        advisorID={
          savingClient && savingClient.advisor ? savingClient.advisor : null
        }
        handleSelect={handleSaveOptionSelect}
        signers={(envelopeDoc && envelopeDoc.recipients) || null}
        signersLoading={signersLoading}
        envelopeID={envelopeID}
        docTypeName={docTypeName}
        signingEvent={
          firmID === firmIDs['jalinski'] && type === 'suitability-save'
            ? false
            : true
        }
      />
    )

    // Set up service logic
    var serviceName = docType
    // var serviceType = camelCase(serviceName)
    var serviceForm = null
    switch (docType) {
      case 'iac':
        serviceName = 'onboarding module'
        // serviceType = camelCase(serviceName)
        serviceForm = (
          <IACForm
            firm={firmID}
            firmObject={firm}
            firmAdvisors={firmAdvisors}
            client={client}
            clientID={savingClientID}
            advisor={clientAdvisorDetails}
            advisorID={clientAdvisorID}
            firmDisclosures={firmDisclosures}
            advisorDisclosures={advisorDisclosures}
            location={location}
            saveClientToDB={saveClientToDB}
            addAdditionalDocuments={addAdditionalDocuments}
            setSavingClientID={setSavingClientID}
            setClientAdvisorID={setClientAdvisorID}
          >
            <h2>Investment Advisory Contract</h2>
            {docSending ? (
              <Loader message="Sending! Please wait..." />
            ) : (
              saveOptions
            )}
          </IACForm>
        )
        break
      case 'k401':
        serviceName = '401(k) Agreement'
        // serviceType = 'k401'
        serviceForm = (
          <K401Form
            firm={firmID}
            firmObject={firm}
            firmAdvisors={firmAdvisors}
            client={client}
            clientID={savingClientID}
            advisor={clientAdvisorDetails}
            advisorID={clientAdvisorID}
            firmDisclosures={firmDisclosures}
            advisorDisclosures={advisorDisclosures}
            location={location}
            saveClientToDB={saveClientToDB}
            addAdditionalDocuments={addAdditionalDocuments}
            setSavingClientID={setSavingClientID}
            setClientAdvisorID={setClientAdvisorID}
          >
            <h2>401(k) Agreement</h2>
            {docSending ? (
              <Loader message="Sending! Please wait..." />
            ) : (
              saveOptions
            )}
          </K401Form>
        )
        break
      case 'suitability':
        serviceName = 'suitability form'
        // serviceType = camelCase(serviceName)
        serviceForm = (
          <SuitabilityForm
            firmID={firmID}
            firm={firm}
            firmAdvisors={firmAdvisors}
            client={client}
            clientID={savingClientID}
            advisor={clientAdvisorDetails}
            advisorID={clientAdvisorID}
            location={location}
            saveClientToDB={saveClientToDB}
            addAdditionalDocuments={addAdditionalDocuments}
            reviewType={reviewType}
            taskDetails={taskDetails}
            setAddSuccess={setAddSuccess}
            setClientAdvisorID={setClientAdvisorID}
          >
            <h2>Suitability Form</h2>
            {docSending ? (
              <Loader message="Sending! Please wait..." />
            ) : (
              saveOptions
            )}
          </SuitabilityForm>
        )
        break
      case 'suitability-save':
        serviceName = 'suitability form'
        // serviceType = camelCase(serviceName)
        serviceForm = (
          <div className="suitability-save">
            <h2>Suitability Form</h2>
            {docSending ? (
              <Loader message="Sending! Please wait..." />
            ) : (
              saveOptions
            )}
          </div>
        )
        break
      default:
        serviceName = docTypeName
        // serviceType = camelCase(docType)
        serviceForm = (
          <TemplateForm
            templateID={docType}
            firm={firmID}
            firmObject={firm}
            firmAdvisors={firmAdvisors}
            client={client}
            clientID={savingClientID}
            advisor={clientAdvisorDetails}
            advisorID={clientAdvisorID}
            firmDisclosures={firmDisclosures}
            advisorDisclosures={advisorDisclosures}
            location={location}
            saveClientToDB={saveClientToDB}
            addAdditionalDocuments={addAdditionalDocuments}
            setSavingClientID={setSavingClientID}
            setClientAdvisorID={setClientAdvisorID}
          >
            <h2>{serviceName}</h2>
            {docSending ? (
              <Loader message="Sending! Please wait..." />
            ) : (
              saveOptions
            )}
          </TemplateForm>
        )
        break
    }

    return (
      <div id="bitsy-register-client" className={mainClass}>
        {/* {location !== 'dashboard' && location !== 'client-profile' &&
        <WidgetToggle />} */}

        <div className="register-wrapper">
          <div className="register-container">
            {location !== 'dashboard' &&
              location !== 'client-profile' &&
              docType !== 'suitability-save' && <FirmLogo firm={firm} />}

            {firm &&
              firm.currentPeriodEnd < dateNow &&
              "We couldn't load this! Please contact your administrator."}

            {/*{isLegacyFirm(firmID) ? (*/}
            {/* <>*/}
            {/*   {firm &&*/}
            {/*   firm.hasServices &&*/}
            {/*   !firm.hasServices[serviceType] &&*/}
            {/*   `You don't currently have the ${serviceName} service. Please contact Bitsy Advisor to get set up!`}*/}

            {/*   {firm &&*/}
            {/*   firm.hasServices &&*/}
            {/*   firm.hasServices[serviceType] &&*/}
            {/*   firm.currentPeriodEnd >= dateNow &&*/}
            {/*   serviceForm}*/}
            {/* </>*/}
            {/*) : (*/}
            {/* serviceForm*/}
            {/*)}*/}
            {serviceForm}
          </div>
        </div>
      </div>
    )
  }
)

export default ClientForm
