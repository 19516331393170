import React, { Component } from 'react'
import Field from './Field'
// import './AddressFields.scss'

export default class AddressFields extends Component {
  render() {
    const { labelFor, label, required, values, handleInputChange } = this.props

    return (
      <div className="bitsy-address-fields">
        <div className="main-label">
          <label htmlFor={labelFor}>{label}</label>
        </div>
        <Field
          type="text"
          id={values.street.id}
          required={required}
          label="Street Address"
          value={values.street.value}
          handleInputChange={handleInputChange}
        />
        <Field
          type="text"
          id={values.city.id}
          required={required}
          label="City"
          value={values.city.value}
          handleInputChange={handleInputChange}
        />
        <Field
          type="select"
          id={values.state.id}
          required={required}
          label="State"
          options="state"
          value={values.state.value}
          handleInputChange={handleInputChange}
        />
        <Field
          type="text"
          id={values.zip.id}
          required={required}
          label="Zip"
          value={values.zip.value}
          handleInputChange={handleInputChange}
        />
      </div>
    )
  }
}
