import React, { memo, useState, useEffect, useCallback } from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import StepWizard from 'react-step-wizard'
import { equals } from 'ramda'
import Loader from 'components/partials/Loader'
import { TemplateFormTopBar } from './partials'
import TemplateSelector from '../TemplateSelector'
import {
  AddClientPage,
  DetailsPage,
  FinalizePage,
  RoundTripSaveProgressPage,
  SuccessPage,
} from './pages'
import { BsPersonFill } from 'react-icons/bs'
import { FaSignature, FaTelegramPlane } from 'react-icons/fa'
import { ImProfile } from 'react-icons/im'
import { useFirm, useUser, useDisclosures } from 'contexts'
import { useItemList } from 'hooks'
import { initialClient } from 'config/clientConfig'
import { fonts } from 'styles'
import { getDoc } from 'actions/db/_helpers'
import { getTemplate } from 'actions/db/templates'
import { generateDisclosuresArray } from 'helpers/disclosures'
import { SmallHeading } from 'styles/_mixins'

const Name = ({ client }) => {
  let name = ''

  if (client) {
    name = client.legal_name
      ? client.legal_name
      : client && client.legal_name_first && client.legal_name_last
      ? `${client.legal_name_first} ${client.legal_name_last}`
      : 'this client'
  }

  return name
}

const templateFormSteps = {
  'one-way': {
    create: {
      steps: [
        {
          idx: 0,
          icon: (color) => <BsPersonFill color={color} size={48} />,
          label: 'Profile',
          page: AddClientPage,
        },
        {
          idx: 1,
          icon: (color) => <ImProfile color={color} size={48} />,
          label: 'Details',
          page: DetailsPage,
        },
        {
          idx: 2,
          icon: (color) => <FaSignature color={color} size={48} />,
          label: 'Finalize',
          page: FinalizePage,
        },
      ],
      successPageDetails: {
        heading: 'All set!',
        message: 'Signup was successful. Please check your email!',
      },
    },
    edit: {
      steps: [
        {
          idx: 0,
          icon: (color) => <ImProfile color={color} size={48} />,
          label: 'Details',
          page: DetailsPage,
        },
        {
          idx: 1,
          icon: (color) => <FaSignature color={color} size={48} />,
          label: 'Finalize',
          page: FinalizePage,
        },
      ],
      successPageDetails: {
        heading: 'All set!',
        message: 'Updates were successful. Please check your email!',
      },
    },
  },
  'round-trip': {
    create: {
      steps: [
        {
          idx: 0,
          icon: (color) => <BsPersonFill color={color} size={48} />,
          label: 'Profile',
          page: AddClientPage,
        },
        {
          idx: 1,
          icon: (color) => <ImProfile color={color} size={48} />,
          label: 'Details',
          page: DetailsPage,
        },
        {
          idx: 2,
          icon: (color) => <FaTelegramPlane color={color} size={48} />,
          label: 'Send to Client',
          page: RoundTripSaveProgressPage,
        },
      ],
      successPageDetails: {
        heading: 'All set!',
        message:
          'Your client has received an email to complete their details. We will email you when this is ready for review!',
      },
    },
    edit: {
      steps: [
        {
          idx: 0,
          icon: (color) => <ImProfile color={color} size={48} />,
          label: 'Details',
          page: DetailsPage,
        },
        {
          idx: 1,
          icon: (color) => <FaTelegramPlane color={color} size={48} />,
          label: 'Send to Client',
          page: RoundTripSaveProgressPage,
        },
      ],
      successPageDetails: {
        heading: 'All set!',
        message:
          'Your client has received an email to complete their details. We will email you when this is ready for review!',
      },
    },
    'client-review': {
      steps: [
        {
          idx: 0,
          icon: (color) => <ImProfile color={color} size={48} />,
          label: 'Details',
          page: DetailsPage,
        },
        {
          idx: 1,
          icon: (color) => <FaTelegramPlane color={color} size={48} />,
          label: 'Return to Advisor',
          page: RoundTripSaveProgressPage,
        },
      ],
      successPageDetails: {
        message:
          'Your advisor will now review your details. We will email you with next steps!',
        showWelcomeToFirm: true,
        isClient: true,
        showLoader: true,
      },
    },
    'final-review': {
      steps: [
        {
          idx: 0,
          icon: (color) => <ImProfile color={color} size={48} />,
          label: 'Details',
          page: DetailsPage,
        },
        {
          idx: 1,
          icon: (color) => <FaSignature color={color} size={48} />,
          label: 'Finalize',
          page: FinalizePage,
        },
      ],
      successPageDetails: {
        heading: 'Success!',
        message: 'Client updated successfully!',
        showLoader: true,
      },
    },
  },
}

const TemplateForm = memo(
  ({
    templateID: passedTemplateID = null,
    template: passedTemplate = null,
    flow: passedFlow = 'create',
    clientID: passedClientID = null,
    client: passedClient = initialClient,
    advisorID = '',
    formID = null,
    taskID,
    handleClose,
  }) => {
    const { userType } = useUser()
    const { firmID, firm } = useFirm()
    const { firmDisclosures } = useDisclosures()

    const [templateID, setTemplateID] = useState(passedTemplateID)
    const [template, setTemplate] = useState(passedTemplate)
    const [activeStep, setActiveStep] = useState(0)
    const [state, updateState] = useState({})
    const [client, setClient] = useState(passedClient)
    const [clientID, setClientID] = useState(passedClientID)
    const [advisor, setAdvisor] = useState(null)
    const [additionalDocuments, setAdditionalDocuments] = useState([])
    const [
      docSigningSessionEnvelopeID,
      setDocSigningSessionEnvelopeID,
    ] = useState(null)
    const [finalizingAtEnd, setFinalizingAtEnd] = useState(
      userType === 'client' ? false : null
    )

    console.log('TemplateForm client: ', client)

    const advisorDisclosures = useItemList({
      itemType: 'disclosure',
      userType: 'advisor',
      uid: client && client.advisor ? client.advisor : null,
      isUserItem: true,
      load: client && client.advisor,
    })

    // Get existing client details if a clientID has been passed with no client object
    useEffect(() => {
      if (
        passedClientID &&
        (!passedClient || equals(passedClient, initialClient))
      ) {
        const prefillExistingClientDetails = async () => {
          const clientDoc = await getDoc('client', passedClientID)
          if (clientDoc) {
            setClient(clientDoc)
          }
          return clientDoc
        }

        prefillExistingClientDetails()
      }
    }, [passedClientID, passedClient])

    // Get template once an ID is set
    useEffect(() => {
      const fetchTemplate = async () => {
        const templateDoc = await getTemplate({
          firmID,
          templateID,
          setTemplate,
        })
        return templateDoc
      }

      if (templateID && !template) {
        fetchTemplate()
      }
    }, [templateID, template, firmID, setTemplate])

    useEffect(() => {
      if (template && template.includeDisclosures) {
        // Only include firm disclosures that were selected in form settings
        let includedFirmDisclosures = {}
        if (
          template.includedFirmDisclosures &&
          template.includedFirmDisclosures.length
        ) {
          Object.keys(firmDisclosures || {}).forEach((disclosureID) => {
            if (template.includedFirmDisclosures.includes(disclosureID)) {
              includedFirmDisclosures[disclosureID] =
                firmDisclosures[disclosureID]
            }
          })
        }

        const newAdditionalDocuments = generateDisclosuresArray({
          firmDisclosures: includedFirmDisclosures,
          advisorDisclosures,
        })

        setAdditionalDocuments(newAdditionalDocuments)
      }
    }, [firmDisclosures, advisorDisclosures, template])

    useEffect(() => {
      if (firmID && client && !client.firm) {
        setClient({ ...client, firm: firmID })
      }
    }, [client, firmID])

    useEffect(() => {
      if (advisorID && (!client.advisor || client.advisor !== advisorID)) {
        setClient({ ...client, advisor: advisorID })
      }
    }, [advisorID, client])

    const handleSetClient = useCallback((data) => {
      setClient((initialClient) => ({ ...initialClient, ...data }))
    }, [])

    if (!templateID) {
      return (
        <Wrapper>
          <Header>
            What kind of document would you like to make for{' '}
            <Name client={client} />?
          </Header>
          <TemplateSelector
            setTemplateID={setTemplateID}
            setTemplate={setTemplate}
          />
        </Wrapper>
      )
    }

    if (!template) {
      return <Loader message="Loading form... please wait." />
    }

    const handleSetClientID = (id) => {
      setClientID(id)
    }

    const handleSetAdvisor = (data) => {
      setAdvisor(data)
    }

    const renderPages = (flow, saveAsPendingChanges, removePendingChanges) => {
      if (!flow) {
        return ''
      }

      const flowObject = templateFormSteps[template.routing][flow]
      const steps = flowObject['steps']

      if (!steps || !steps.length) {
        return ''
      }

      // Append success page
      const updatedStep = [
        ...steps,
        {
          idx: steps.length,
          page: SuccessPage,
        },
      ]

      const props = {
        templateID,
        template,
        clientID,
        client,
        advisor,
        firmID,
        firm,
        formID,
        taskID,
        additionalDocuments,
        handleSetClient,
        handleSetClientID,
        handleSetAdvisor,
        successPageDetails: flowObject.successPageDetails,
        saveAsPendingChanges,
        removePendingChanges,
        flow,
        docSigningSessionEnvelopeID,
        setDocSigningSessionEnvelopeID,
        handleClose,
        finalizingAtEnd,
      }

      return updatedStep.map((step, pidx) => {
        const PageComponent = step.page
        return (
          <PageComponent
            key={`${step.label}-${pidx}`}
            label={step.label}
            {...props}
          />
        )
      })
    }

    const setInstance = (SW) => {
      updateState({
        ...state,
        SW,
      })
    }

    const onStepChange = async (stats) => {
      setActiveStep(stats.activeStep)
      setDocSigningSessionEnvelopeID(null)

      if (document.querySelectorAll('div.modal-body')[1]) {
        document.querySelectorAll('div.modal-body')[1].scrollTop = 20
      } else if (document.querySelectorAll('div.modal-body')[0]) {
        document.querySelectorAll('div.modal-body')[0].scrollTop = 20
      } else if (document.querySelectorAll('.scrolling.content')[0]) {
        document.querySelectorAll('.scrolling.content')[0].scrollTop = 20
      }
    }

    const { SW } = state

    let flowTemp = passedFlow
    let saveAsPendingChanges = false
    let removePendingChanges = false
    if (template && template.routing) {
      switch (template.routing) {
        case 'one-way':
          if (passedClientID) {
            flowTemp = 'edit'
          }
          break
        case 'round-trip':
          if (userType === 'client') {
            flowTemp = 'client-review'
            saveAsPendingChanges = true
          } else {
            if ('final-review' === passedFlow) {
              flowTemp = 'final-review'
              removePendingChanges = true
            } else {
              if (passedClientID) {
                flowTemp = 'edit'
              }
            }
          }
          break
        default:
          if (passedClientID) {
            flowTemp = 'edit'
          }
          break
      }
    }

    if (
      template &&
      template.routing &&
      template.routing === 'round-trip' &&
      ['create', 'edit'].includes(flowTemp) &&
      finalizingAtEnd === null
    ) {
      return (
        <Wrapper>
          <Header>
            Would you like to finalize this form at the end of this session?
          </Header>
          <Button.Group vertical size="large">
            <FinalizeOptionButton
              primary
              content="Yes, final client data will be input now."
              onClick={() => setFinalizingAtEnd(true)}
            />
            <FinalizeOptionButton
              primary
              content="No, information will need to go to the client for completion and
              review."
              onClick={() => setFinalizingAtEnd(false)}
            />
          </Button.Group>
        </Wrapper>
      )
    }

    return (
      <>
        <TemplateTitle>{template.title}</TemplateTitle>
        <StepWizardStyled
          onStepChange={onStepChange}
          nav={
            <TemplateFormTopBar
              SW={SW}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={templateFormSteps[template.routing][flowTemp]['steps']}
            />
          }
          instance={setInstance}
        >
          {renderPages(flowTemp, saveAsPendingChanges, removePendingChanges)}
        </StepWizardStyled>
      </>
    )
  }
)

export default TemplateForm

const Wrapper = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  ${fonts.heading};
  font-style: italic;
  text-align: center;
  margin: 20px;
`

const TemplateTitle = styled.h2`
  &&& {
    ${SmallHeading()};
    font-size: 150%;
    margin-top: 50px;
    text-align: center;
  }
`

const StepWizardStyled = styled(StepWizard)`
  min-height: 300px;
  margin: 50px auto;
  margin-top: 25px;
  width: 100%;
`

const FinalizeOptionButton = styled(Button)`
  &&& {
  }
`
