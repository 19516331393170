import { firebaseFunctions } from '../../../config/firebaseConfig'

const apiClientsDelete = firebaseFunctions.httpsCallable('apiClientsDelete')

export default async function deleteClient(clientID) {
  try {
    const result = await apiClientsDelete({ clientID: clientID })
    console.log(result)
    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
