import { clientAuth } from '../config/firebaseConfig'
import { getClientDoc } from './clientDbActions'
import { clientInitialState } from '../config/iacConfig'

let unsubscribeClientAuth
export function startListeningToClientAuth(state) {
  unsubscribeClientAuth = clientAuth.onAuthStateChanged(function (user) {
    if (user) {
      // User is signed in.
      state.setState(
        {
          uid: user.uid,
        },
        async function () {
          var userDoc = await getClientDoc(user.uid)

          console.log(userDoc)

          if (userDoc) {
            state.setState({
              client: Object.assign({}, clientInitialState, userDoc),
            })
          }
        }
      )
    } else {
      // User is signed out.
      state.setState({
        uid: '',
        userType: '',
        client: clientInitialState,
      })
      // console.log('noUser');
    }
  })
}
export function stopListeningToClientAuth() {
  // Stop listening to changes
  unsubscribeClientAuth()
}

export function signInClientUser(email, password, moduleState) {
  clientAuth
    .signInWithEmailAndPassword(email, password)
    .then(function () {
      moduleState.setState({
        tabIndex: 1,
        errors: [],
      })
    })
    .catch(function (error) {
      // Handle errors
      var errors = []
      var errorMessage = error.message

      if (error.code === 'auth/user-not-found') {
        errorMessage = 'No account was found with that email.'
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'Wrong password.'
      }

      errors.push(errorMessage)

      moduleState.setState({
        errors: errors,
      })
    })
}

export function signOutClientUser() {
  clientAuth
    .signOut()
    .then(function () {
      // Sign-out successful.
    })
    .catch(function (error) {
      // An error happened.
    })
}
