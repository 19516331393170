import React from 'react'
import {
  Card,
  Divider,
  Icon,
  Form,
  Input,
  Radio,
  Select,
} from 'semantic-ui-react'
import Loader from '../../../partials/Loader'
import styled from 'styled-components'
import fonts from '../../../../styles/fonts'
import VSpacer from '../../../partials/VSpacer'
import Tip from 'components/partials/Tip'
import TemplateJsonEditor from '../partials/TemplateJsonEditor'
import ExportTemplateButton from '../partials/ExportTemplateButton'
import DeleteTemplateButton from '../partials/DeleteTemplateButton'
import { useDisclosures, useEnvelopes } from 'contexts'

const InfoPage = ({ template, handleSetTemplate }) => {
  const { firmDisclosures } = useDisclosures()
  const { envelopes } = useEnvelopes()

  if (!template) {
    return <Loader message="Loading form... please wait." />
  }

  const routingOptions = [
    {
      title: 'Advisor',
      description: 'Advisor inputs everything',
      inputValue: 'one-way',
      icon: 'long arrow alternate right',
    },
    {
      title: 'Advisor & Client',
      description: 'Advisor and Client collaborate',
      inputValue: 'round-trip',
      icon: 'arrows alternate horizontal',
    },
  ]

  const disclosureSendOptions = [
    {
      title: 'When they are added',
      description: 'Send as soon as an email is entered',
      inputValue: 'on-add',
      icon: 'plus',
    },
    {
      title: 'With the contract',
      description: 'Include in the contract to sign',
      inputValue: 'with-contract',
      icon: 'newspaper',
    },
    {
      title: 'After contract completion',
      description: 'Send after contract is signed by all parties',
      inputValue: 'on-contract-complete',
      icon: 'flag checkered',
    },
  ]

  const firmDisclosureOptions = Object.keys(firmDisclosures || {}).map(
    (disclosureID) => {
      const disclosure = firmDisclosures[disclosureID]
      return {
        key: disclosureID,
        value: disclosureID,
        text: disclosure.name,
      }
    }
  )

  let canDelete = false
  if (
    envelopes &&
    Object.values(envelopes).length &&
    !Object.values(envelopes).find((env) =>
      [template.id, template.legacyDocType].includes(env.type)
    )
  ) {
    canDelete = true
  }

  return (
    <StyledCard>
      <Header>Let's go over the basics</Header>
      <VSpacer />

      <FormField required>
        <label>What will this document be called?</label>
        <Input
          placeholder="Search..."
          value={template.title}
          onChange={(e) => handleSetTemplate({ title: e.target.value })}
        />
      </FormField>

      <VSpacer />
      <Divider horizontal>
        <Header as="h3">
          <Icon name="setting" />
          Form Settings
        </Header>
      </Divider>
      <VSpacer />

      <FormField>
        <label>Who fills out this form?</label>
        <Select
          placeholder="Select a routing method"
          fluid
          value={template.routing}
          options={routingOptions.map((routingOption) => {
            const { inputValue, title, description, icon } = routingOption
            return {
              key: inputValue,
              value: inputValue,
              text: title,
              description: description,
              icon: icon,
            }
          })}
          onChange={(e, { value }) => handleSetTemplate({ routing: value })}
        />
      </FormField>

      <FormField>
        <Radio
          toggle
          label="Include Disclosures?"
          checked={template.includeDisclosures}
          onChange={() => {
            handleSetTemplate({
              includeDisclosures: !template.includeDisclosures,
            })
          }}
        />
        <TipContainer>
          <Tip
            header=""
            content="If toggled off, disclosures will need to be sent manually. Disclosures will always be able to be sent manually in the Client Profile."
          />
        </TipContainer>
      </FormField>
      {template.includeDisclosures && (
        <Subsection>
          <SubsectionTitle>Disclosures Settings</SubsectionTitle>
          <FormField>
            <label>When should the client receive disclosures?</label>
            <Select
              placeholder="Select one"
              fluid
              value={template.sendDisclosuresWhen}
              options={disclosureSendOptions.map((sendOption) => {
                const { inputValue, title, description, icon } = sendOption
                return {
                  key: inputValue,
                  value: inputValue,
                  text: title,
                  description: description,
                  icon: icon,
                }
              })}
              onChange={(e, { value }) =>
                handleSetTemplate({ sendDisclosuresWhen: value })
              }
            />
          </FormField>

          <FormField>
            <label>
              Which disclosures should be included?
              <TipContainer>
                <Tip
                  header=""
                  content="All disclosures uploaded by the selected advisor will be included automatically."
                />
              </TipContainer>
            </label>

            <Select
              placeholder="Select disclosures"
              fluid
              multiple
              value={template.includedFirmDisclosures || []}
              options={firmDisclosureOptions}
              onChange={(e, { value }) =>
                handleSetTemplate({ includedFirmDisclosures: value })
              }
            />
          </FormField>

          <FormField>
            <Radio
              toggle
              label="Show Disclosures Agreement page?"
              checked={template.showDisclosuresPage}
              onChange={() => {
                handleSetTemplate({
                  showDisclosuresPage: !template.showDisclosuresPage,
                })
              }}
            />
            <TipContainer>
              <Tip
                header=""
                content="If toggled off, the Disclosures Agreement page will not show when filling out a form."
              />
            </TipContainer>
          </FormField>
        </Subsection>
      )}

      <FormField>
        <Radio
          toggle
          label="Include Tax ID?"
          checked={template.includeTaxID}
          onChange={() => {
            handleSetTemplate({ includeTaxID: !template.includeTaxID })
          }}
        />
      </FormField>
      <FormField>
        <Radio
          toggle
          label="Signing event?"
          checked={template.signingEvent}
          onChange={() => {
            handleSetTemplate({ signingEvent: !template.signingEvent })
          }}
        />
      </FormField>
      {template.signingEvent && (
        <Subsection>
          <SubsectionTitle>Signing Settings</SubsectionTitle>
          <FormField>
            <Radio
              toggle
              label="Include Advisor during signing?"
              checked={
                typeof template.includeAdvisor === 'undefined'
                  ? true
                  : template.includeAdvisor
              }
              onChange={() => {
                handleSetTemplate({
                  includeAdvisor: !template.includeAdvisor ? true : false,
                })
              }}
            />
            <TipContainer>
              <Tip
                header=""
                content="If toggled on, the advisor will be asked to signed this document after the client has signed."
              />
            </TipContainer>
          </FormField>
        </Subsection>
      )}

      <FormField>
        <Radio
          toggle
          label="Use for onboarding date?"
          checked={template.useForOnboardingDate}
          onChange={() => {
            handleSetTemplate({
              useForOnboardingDate: !template.useForOnboardingDate,
            })
          }}
        />
        <TipContainer>
          <Tip
            header=""
            content="If toggled on, the client's onboarding date will be set when this type of form is finalized for the first time."
          />
        </TipContainer>
      </FormField>

      <FormField>
        <Radio
          toggle
          label="Inactive?"
          checked={template.inactive}
          onChange={() => {
            handleSetTemplate({ inactive: !template.inactive })
          }}
        />
      </FormField>

      <CardActions extra>
        <TemplateJsonEditor
          templateID={template.id}
          template={template}
          handleSetTemplate={handleSetTemplate}
        />
        <ExportTemplateButton template={template} />
        {canDelete && <DeleteTemplateButton templateID={template.id} />}
      </CardActions>
    </StyledCard>
  )
}

export default InfoPage

const Header = styled.div`
  ${fonts.heading};
  font-style: italic;
  text-align: center;
  margin: 20px;
`

const StyledCard = styled(Card)`
  &&& {
    max-width: 600px;
    margin: auto;
  }
`

const FormField = styled(Form.Field)`
  padding: 0.25em 0;

  & > .checkbox {
    font-size: inherit;

    & > label {
      font-size: 0.92857143em;
    }
  }
`

const TipContainer = styled.div`
  display: inline-block;
  margin-left: 6px;
`

const Subsection = styled.div`
  background: rgba(0, 0, 0, 0.02);
  padding: 30px;
  margin-bottom: 30px;

  & > .field > label,
  & > .field > .checkbox > label {
    font-size: 80% !important;
  }
`

const SubsectionTitle = styled.div`
  ${fonts.heading};
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const CardActions = styled(Card.Content)`
  &&&& {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    & > * {
      margin: 5px 0;
      margin-left: auto;
    }
  }
`
