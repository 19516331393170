import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import {
  authenticateSalesforce,
  deauthenticateSalesforce,
} from 'actions/integrations/salesforce/authActions'
import styled from 'styled-components'

class SalesforceIntegrationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.authenticate = this.authenticate.bind(this)
    this.deauthenticate = this.deauthenticate.bind(this)
  }

  authenticate() {
    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          await authenticateSalesforce(this.props.uid)
        } catch (err) {
          // TODO: [BTS-328] Handle failure to authenticate with Salesforce
        }
      }
    )
  }

  deauthenticate() {
    this.setState(
      {
        loading: true,
      },
      () => {
        deauthenticateSalesforce(this.props.uid)
        this.setState({ loading: false })
      }
    )
  }

  render() {
    const { integration } = this.props
    const { loading } = this.state

    return (
      <div className={'integration-details integration-details-salesforce'}>
        {integration && (
          <p>You are authenticated with Salesforce! We'll take it from here.</p>
        )}
        <SubmitSection className="form-group submit-section">
          {integration ? (
            <Button
              negative
              className="continue remove"
              type="button"
              onClick={this.deauthenticate}
              loading={loading}
              disabled={loading}
              size="big"
            >
              Remove integration
            </Button>
          ) : (
            <Button
              primary
              className="continue"
              type="button"
              onClick={this.authenticate}
              loading={loading}
              disabled={loading}
              size="big"
            >
              Continue
            </Button>
          )}
        </SubmitSection>
      </div>
    )
  }
}

export default SalesforceIntegrationForm

const SubmitSection = styled.div`
  margin-top: 30px;
`
