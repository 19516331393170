import React, { memo, useState, useEffect } from 'react'
import { Radio } from 'semantic-ui-react'
import Card from 'components/dashboards/partials/LegacyCard'
import Field from 'components/forms/Field'
import AddressFields from 'components/forms/AddressFields'
import ErrorsList from 'components/partials/ErrorsList'
import UpdatePasswordForm from 'components/forms/UpdatePasswordForm'
import BrandedLoginLink from 'components/dashboards/partials/BrandedLoginLink'
import { useUser, useFirm, useAdvisor } from 'contexts'
import './SettingsTab.scss'
import useItem from 'hooks/useItem'
import { updateCPS, updateFirmModuleSetting } from 'actions/dbActions'
import { updateItemInfo } from 'actions/db/dbActions'
import redirectToCustomerPortal from 'actions/integrations/stripe/customerPortal'
import SigningReminderSettings from 'components/dashboards/partials/SigningReminderSettings'
import EmailNotificationSettings from 'components/dashboards/partials/EmailNotificationSettings'
import EmailSettingsEditor from 'components/dashboards/partials/EmailSettingsEditor'
import { userIsFirm } from 'helpers/users'
import { ColMd12, ColMd4, ColMd8, Row } from 'components/styled'
import Tip from 'components/partials/Tip'
import styled from 'styled-components'
import BrandLogo from 'components/partials/BrandLogo'
import FileUpload from 'components/forms/fields/FileUpload'
import DeleteDocFileButton from 'components/dashboards/partials/DeleteDocFileButton'
import { addDocFile } from 'actions/db/_helpers'

export default memo(() => {
  const { userType, uid } = useUser()
  const { firmID, firm } = useFirm()
  const { advisorID, advisor } = useAdvisor()

  const isFirm = userIsFirm(userType)

  // TODO: Deprecate CPS
  const [cpsPending, setCPSPending] = useState('')
  const [cpsErrors, setCPSErrors] = useState([])
  const cps = useItem({ itemType: 'cp', uid: isFirm ? firmID : uid })

  useEffect(() => {
    if (cps && cps.p && cps.p.length) {
      setCPSPending(cps.p)
    }
  }, [cps])

  useEffect(() => {
    if (cpsPending.length < 8) {
      setCPSErrors(['Client Password must be at least 8 characters.'])
    } else {
      setCPSPending(cpsPending)
      setCPSErrors([])
      if (cpsErrors.length < 1 && uid) {
        // Update firm CPS
        updateCPS(uid, cpsPending)
      }
    }
  }, [cpsPending, cpsErrors.length, uid])
  // END Deprecate CPS

  if ((isFirm && !firm) || (userType === 'advisor' && !firm)) {
    return ''
  }

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'embedded') {
      // Module changes
      updateFirmModuleSetting(uid, firm.module, name, value)
    } else if (name === 'cps') {
      // TODO: Deprecate CPS
      setCPSPending(value)
    } else {
      // Firm/Advisor changes
      updateItemInfo(userType, uid, name, value)
    }
  }

  return (
    <>
      <Card
        title="Account Settings"
        type="settings"
        className="col-md-8 mx-auto"
      >
        <h4>Update Password</h4>
        <UpdatePasswordForm />
      </Card>

      <Card title="Firm Settings" type="settings" className="col-md-8 mx-auto">
        <Row>
          {isFirm && (
            <>
              <ColMd12>
                <h4>Firm Details</h4>
                <div id="firm-details">
                  <Field
                    type="text"
                    label="Admin Name"
                    showLabel={true}
                    id="contact_name"
                    value={firm.contact_name}
                    handleInputChange={handleInputChange}
                  />
                  <Field
                    type="text"
                    label="Master Email"
                    showLabel={true}
                    id="email"
                    value={firm.email}
                    handleInputChange={handleInputChange}
                    readOnly={true}
                    tooltip="Please contact Bitsy to change this!"
                  />
                  <AddressFields
                    labelFor="firm-address"
                    label="Firm Address"
                    required={true}
                    values={{
                      street: {
                        id: 'address_street',
                        value: firm.address_street,
                      },
                      city: {
                        id: 'address_city',
                        value: firm.address_city,
                      },
                      state: {
                        id: 'address_state',
                        value: firm.address_state,
                      },
                      zip: {
                        id: 'address_zip',
                        value: firm.address_zip,
                      },
                    }}
                    handleInputChange={handleInputChange}
                  />

                  <Field
                    type="text"
                    label="Client Password"
                    showLabel={true}
                    instructional="Set a password clients will use to sign up (at least 8 characters)"
                    id="cps"
                    value={cpsPending}
                    handleInputChange={handleInputChange}
                  />
                  <ErrorsList errors={cpsErrors} />
                </div>
              </ColMd12>
            </>
          )}
        </Row>
      </Card>

      <Card
        title="Branding Settings"
        type="settings"
        className="col-md-8 mx-auto"
      >
        <Row>
          <ColMd12>
            <>
              {userType === 'firm' && firm && firm.adminIsAdvisor ? (
                <>
                  <h4>Branded Login Links</h4>
                  You can use the login links below to provide your clients with
                  a branded login experience.
                </>
              ) : (
                <>
                  <h4>Branded Login Link</h4>
                  You can use the login link below to provide your clients with
                  a branded login experience.
                </>
              )}
            </>
          </ColMd12>
        </Row>

        {isFirm && (
          <BrandedLinksRow className="row">
            <ColMd4>
              <LogoContainer>
                <StyledDeleteDocFileButton
                  userType="firm"
                  uid={firmID}
                  property="logo"
                  basic
                  compact
                  content=""
                />
                <StyledBrandLogo brand={firm} />
                <FileUpload
                  id="firm-logo-upload"
                  handleUpload={(file, setUploadComplete) => {
                    addDocFile({
                      file,
                      userType: 'firm',
                      uid: firmID,
                      property: 'logo',
                      setUploadComplete,
                    })
                  }}
                />
              </LogoContainer>
            </ColMd4>
            <ColMd8>
              <BrandedLinkDetails>
                <h5>Firm</h5>
                <BrandedLoginLink />
              </BrandedLinkDetails>
            </ColMd8>
          </BrandedLinksRow>
        )}

        {(userType === 'advisor' ||
          (userType === 'firm' && firm && firm.adminIsAdvisor)) && (
          <BrandedLinksRow className="row">
            <ColMd4>
              <LogoContainer>
                <StyledDeleteDocFileButton
                  userType="advisor"
                  uid={advisorID}
                  property="logo"
                  basic
                  compact
                  content=""
                />
                <StyledBrandLogo brand={advisor} />
                <FileUpload
                  id="advisor-logo-upload"
                  handleUpload={(file, setUploadComplete) => {
                    addDocFile({
                      file,
                      userType: 'advisor',
                      uid: advisorID,
                      property: 'logo',
                      setUploadComplete,
                    })
                  }}
                />
              </LogoContainer>
            </ColMd4>
            <ColMd8>
              <BrandedLinkDetails>
                <h5>{userType === 'firm' ? 'Firm Admin' : ''} Advisor</h5>
                <BrandedLoginLink advisorID={advisorID} />
              </BrandedLinkDetails>
            </ColMd8>
          </BrandedLinksRow>
        )}
      </Card>

      {isFirm && (
        <>
          <Card
            title="Fee Settings"
            type="settings"
            className="col-md-8 mx-auto"
            headerActionLink={{
              title: 'Change billing settings',
              action: () => {
                redirectToCustomerPortal({
                  customerId: firm.customerID,
                })
              },
              className: 'text-right',
            }}
          >
            <Row>
              <ColMd4>
                {/* // TODO: Percentage Fee / Fixed Fee toggles */}

                <h4>Fee Min/Max</h4>
                <Field
                  type="percentage"
                  label="Minimum Fee"
                  id="fee_schedule_minimum"
                  value={firm.fee_schedule_minimum}
                  handleInputChange={handleInputChange}
                />
                <Field
                  type="percentage"
                  label="Maximum Fee"
                  id="fee_schedule_maximum"
                  value={firm.fee_schedule_maximum}
                  handleInputChange={handleInputChange}
                />
              </ColMd4>
              <ColMd8>
                <h4>Fee Schedule</h4>
                {/* // TODO: Fee schedule */}
                <small className="instructional">Coming soon!</small>

                <h4>Fixed Fee</h4>
                {/* // TODO: Fixed fee */}
                <small className="instructional">Coming soon!</small>
              </ColMd8>
            </Row>
          </Card>

          {/* // TODO: [BTS-275] Add additional settings */}

          {/* // TODO: [BTS-709] FundAllocator model builder */}
        </>
      )}

      {isFirm && (
        <Card
          title="Dashboard Settings"
          type="settings"
          className="col-md-8 mx-auto"
        >
          <Row>
            <ColMd12>
              <h4>Client Dashboards</h4>
              <StyledRadio
                toggle
                label="Hide disclosures on client dashboards?"
                checked={firm.hideDisclosuresOnClientProfile}
                onChange={() =>
                  updateItemInfo(
                    'firm',
                    firmID,
                    'hideDisclosuresOnClientProfile',
                    !firm.hideDisclosuresOnClientProfile
                  )
                }
              />
              <Tip content="Toggle this on if you and your advisors are not using our Disclosures upload feature" />
            </ColMd12>
          </Row>
        </Card>
      )}

      <Card
        title="Notification Settings"
        type="settings"
        className="col-md-8 mx-auto"
      >
        <Row>
          <ColMd12>
            <h4>Emails</h4>
            <EmailNotificationSettings />
          </ColMd12>
        </Row>
        {isFirm && (
          <Row>
            <ColMd12>
              <h4>Signing Reminders</h4>
              <SigningReminderSettings />
            </ColMd12>
          </Row>
        )}
      </Card>

      {isFirm && (
        <Card
          title="Email Settings"
          type="settings"
          className="col-md-8 mx-auto"
        >
          <Row>
            <ColMd12>
              <EmailSettingsEditor />
            </ColMd12>
          </Row>
        </Card>
      )}
    </>
  )
})

const StyledRadio = styled(Radio)`
  &&& {
    margin-right: 10px;
  }
`

const BrandedLinksRow = styled.div`
  &&& {
    padding-top: 1rem;
    padding-bottom: 1rem;

    & > div {
      display: flex;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    h5 {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
`

const LogoContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;

  .ui.form {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledDeleteDocFileButton = styled(DeleteDocFileButton)`
  &&&& {
    margin-right: 0 !important;
    padding: 0 !important;
    position: absolute;
    right: -10px;

    &,
    &:hover,
    &:focus {
      box-shadow: none !important;
    }

    i {
      margin-right: 0 !important;
    }
  }
`

const StyledBrandLogo = styled(BrandLogo)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;

  img {
    max-height: 100px;
  }
`

const BrandedLinkDetails = styled.div`
  width: 100%;

  .ui.input {
    width: 100%;
  }
`
