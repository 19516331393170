import React, { Component } from 'react'
import './InitialsField.scss'

class InitialsField extends Component {
  render() {
    const {
      id,
      signer,
      value,
      required,
      handleInputChange,
      handleKeyPress,
    } = this.props

    return (
      <span className="form-group initials-group">
        <label htmlFor={id}>
          {signer === 'Accepted by Advisor'
            ? signer
            : signer
            ? signer + ' Initials'
            : 'Client Initials'}
          :{' '}
        </label>
        <input
          disabled={!required}
          value={value}
          type="text"
          name={id}
          id={id}
          placeholder="AA"
          onChange={handleInputChange}
          className={required && !value ? 'to-do' : ''}
          onKeyPress={handleKeyPress}
        />
      </span>
    )
  }
}

export default InitialsField
