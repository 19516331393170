import React, { memo } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { ToDoIcon } from 'components/icons'

const dateFormatFull = 'MM/DD/YYYY [at] HH:mm:ss A'

const FormReviewersList = memo(({ reviewers = {}, form = {}, formID }) => {
  const { dateSent: rawDateSent } = form
  const dateSent = dayjs(rawDateSent.toDate())

  const sortedReviewerIDs = ['advisor', 'client', 'advisor']

  return (
    <StyledList>
      {sortedReviewerIDs.map((reviewerID, idx) => {
        const reviewer = reviewers[reviewerID]

        let dateReviewed = null
        if (reviewer.dateReviewed) {
          dateReviewed = dayjs(reviewer.dateReviewed.toDate())
        }

        return (
          <li key={formID + reviewerID + idx}>
            <ToDoIcon complete={idx === 0 || reviewer.status === 'Reviewed'} />
            <small>
              <strong>{reviewer.name}</strong>
              <br />
              <em>
                {idx === 0
                  ? `Initial review on ${dateSent.format(dateFormatFull)}`
                  : reviewer.status === 'Reviewed' && dateReviewed
                  ? `Reviewed ${dateReviewed.format(dateFormatFull)}`
                  : reviewer.status === 'Reviewed'
                  ? 'Reviewed'
                  : idx === sortedReviewerIDs.length - 1
                  ? 'Awaiting review and finalization'
                  : 'Awaiting review'}
              </em>
            </small>
          </li>
        )
      })}
    </StyledList>
  )
})

export default FormReviewersList

const StyledList = styled.ul`
  padding-left: 15px;

  li {
    display: flex;
    align-items: center;
    margin: 8px 0;
    line-height: 1.3;

    .to-do {
      margin: 6px 0;
      margin-right: 6px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
`
