import React, { useState, useEffect, useCallback } from 'react'
import Error from '../../partials/Error'
import Loader from '../../partials/Loader'
import { ToDoIcon } from '../../icons'
import { useFirm } from '../../../contexts'
import { getSigningUrl } from '../../../actions/integrations/docusign'

const signingOrder = {
  client_1: 1,
  client_2: 2,
  advisor: 3,
}

const legacyRecipientIdMap = {
  client_1: '1',
  client_2: '2',
  advisor: '11',
}

const ClientFormSaveOptions = ({
  clientID = null,
  advisorID = null,
  handleSelect,
  signersLoading,
  signers,
  envelopeID,
  docTypeName = 'IAC',
  signingEvent = true,
}) => {
  const { firmID } = useFirm()
  const [signingWindows, setSigningWindow] = useState({})
  const [currentSigner, setCurrentSigner] = useState()
  const [urlLoading, setUrlLoading] = useState(false)
  const [successfulSigners, setSuccessfulSigners] = useState({})

  const openSigningWindow = (signerKey, name, signerId) => {
    setUrlLoading(true)
    setCurrentSigner(signerId)

    const signerEmailKey = signers[signerKey].email || signerKey // Legacy email signer keys
    const recipient = {
      recipientId:
        signers[signerKey].recipientId || legacyRecipientIdMap[signerId],
      name,
      signerId: signerKey,
      email: signerEmailKey,
      firmId: firmID,
      advisorId: advisorID,
    }
    getSigningUrl({
      envelopeID,
      recipient,
      setSigningUrl: (url) => {
        const currentWindow = window.open(
          url,
          signerKey,
          'scrollbars,width=' +
            parseInt(window.innerWidth) * 0.8 +
            ',height=' +
            parseInt(window.innerHeight) * 0.8
        )
        setSigningWindow((oldSigningWindows) => ({
          ...oldSigningWindows,
          [signerKey]: currentWindow,
        }))
        setUrlLoading(false)
      },
    })
  }

  const closePopup = useCallback(
    (event) => {
      const { data, origin: popupOrigin } = event
      if (
        popupOrigin !== window.origin ||
        !data ||
        data.source === 'react-devtools-bridge' ||
        data.source === 'react-devtools-content-script'
      ) {
        return
      }

      try {
        const { signerId } = JSON.parse(data)
        if (signingWindows[signerId] && !signingWindows[signerId].closed) {
          signingWindows[signerId].close()
        }

        setSuccessfulSigners((oldSuccessfulSigner) => ({
          ...oldSuccessfulSigner,
          [signerId]: true,
        }))
      } catch (err) {
        console.log(err)
      }
    },
    [signingWindows]
  )

  useEffect(() => {
    window.addEventListener('message', closePopup)

    return () => {
      window.removeEventListener('message', closePopup)
    }
  }, [closePopup])

  if (currentSigner && signers && Object.keys(signers).length) {
    Object.keys(signers).forEach((signerKey) => {
      const signer = signers[signerKey]
      if (signer.signerID === currentSigner && signer.status === 'Signed') {
        if (signingWindows[signerKey] && !signingWindows[signerKey].closed) {
          signingWindows[signerKey].close()
        }
        setCurrentSigner(null)
      }
    })
  }

  if (!clientID || (clientID && !clientID.length)) {
    return (
      <Error
        message="There was an error. Please contact Bitsy."
        code="RC-NUID"
        level="warning"
        errorInfo={{
          Location: 'Client Form',
          Details: 'No clientID passed to save options',
        }}
        // userInfo={{ 'username': advisor.name, 'email': advisor.email, 'Firm': firm.name, 'Firm ID': firmID }}
      />
    )
  }

  const getDescriptionFromSignerID = (id) => {
    var name = 'Signer'

    switch (id) {
      case 'client_1':
        name = 'First Investor'
        break
      case 'client_2':
        name = 'Second Investor'
        break
      case 'advisor':
        name = 'Advisor'
        break
      case 'firm':
        name = 'Firm'
        break
      default:
        break
    }

    return name
  }

  var signerButtons = null
  if (signers && Object.keys(signers).length) {
    let enable = true
    signerButtons = Object.keys(signers)
      .filter((signerID) => {
        const signer = signers[signerID]
        return signer.signerID !== 'firm'
      })
      .sort((emailA, emailB) => {
        const signerA = signers[emailA]
        const signerB = signers[emailB]
        return signingOrder[signerA.signerID] - signingOrder[signerB.signerID]
      })
      .map((signerID) => {
        const signer = signers[signerID]
        const completedSigner =
          signer.status === 'Signed' || successfulSigners[signerID]
        let disableSigner = true
        if (!completedSigner && enable) {
          disableSigner = false
          enable = false
        }
        return (
          <div key={signerID}>
            {urlLoading && signer.signerID === currentSigner ? (
              <Loader message="Loading! Please wait..." />
            ) : (
              <button
                disabled={disableSigner}
                onClick={() => {
                  openSigningWindow(signerID, signer.name, signer.signerID)
                }}
                className={
                  signer.status === 'Signed' || successfulSigners[signerID]
                    ? 'complete'
                    : ''
                }
              >
                <ToDoIcon
                  complete={
                    signer.status === 'Signed' || successfulSigners[signerID]
                  }
                />
                <div className="signer-info">
                  {signer.name}
                  <br />
                  <small>{getDescriptionFromSignerID(signer.signerID)}</small>
                </div>
              </button>
            )}
          </div>
        )
      })
  }

  return (
    <div className="step-contents">
      {signersLoading ? (
        <Loader message="Loading! Please wait..." />
      ) : !signersLoading && signerButtons ? (
        <div className="signer-buttons">
          <p className="text-center instructional">
            Please have the following parties click below to sign:
          </p>
          {signerButtons}
        </div>
      ) : (
        <>
          {signingEvent ? (
            <>
              <button
                className="save-button"
                onClick={() => handleSelect('sign')}
              >
                Sign {docTypeName} Now
              </button>
              <button
                className="save-button"
                onClick={() => handleSelect('send')}
              >
                Send {docTypeName} Now
              </button>
            </>
          ) : (
            <button
              className="save-button"
              onClick={() => handleSelect('save-only')}
            >
              Save changes and exit
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default ClientFormSaveOptions
