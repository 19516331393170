import React, { memo } from 'react'
import { Button } from 'semantic-ui-react'
import { SigningIcon } from '../../pages/FinalizePage'

const FinalizeSigningEventOptions = memo(
  ({ processing, finalizeType, handleFinalize }) => {
    return (
      <>
        <Button
          content="Preview"
          icon="eye"
          primary
          size="big"
          onClick={() => handleFinalize('preview')}
          disabled={processing}
          loading={processing && finalizeType && finalizeType === 'preview'}
          data-tip="Preview the signing experience for clients."
        />
        <Button
          content="Send for signing"
          icon="send"
          primary
          size="big"
          onClick={() => handleFinalize('send')}
          disabled={processing}
          loading={processing && finalizeType && finalizeType === 'send'}
          data-tip="Email the final document for signing and update the client in any integrations"
        />
        <Button
          content="Sign now"
          icon={
            <i aria-hidden="true" className="icon">
              <SigningIcon />
            </i>
          }
          primary
          size="big"
          onClick={() => handleFinalize('sign')}
          disabled={processing}
          loading={processing && finalizeType && finalizeType === 'sign'}
          data-tip="Initiate an in-person signing session and update the client in any integrations"
        />
      </>
    )
  }
)

export default FinalizeSigningEventOptions
