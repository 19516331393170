import { removeKey } from 'helpers/_utils'

// Remove certain fields before saving a new/updated client to the database
export const prepClientForDB = (client, savingAsPending) => {
  let dbClient = { ...client }

  // Sensitive fields like Tax ID - we never save SSNs/Tax IDs!
  dbClient = removeKey(dbClient, 'tax_id')
  dbClient = removeKey(dbClient, 'tax_id_type')
  if (
    client.beneficiary_designations &&
    client.beneficiary_designations.length
  ) {
    dbClient.beneficiary_designations = client.beneficiary_designations.map(
      ({ ssn, ...bene }) => bene
    )
  }

  // Fields that are used as utils when completing a form
  dbClient = removeKey(dbClient, 'repeaters')

  // Fields that should not prepopulate unless they are pending changes (for back and forth during round-trip forms)
  if (!savingAsPending) {
    dbClient = removeKey(dbClient, 'gwn_funds_allocated')
    dbClient = removeKey(dbClient, 'gwn_fund_allocation_individual_information')
  }

  return dbClient
}
