import React, { memo } from 'react'
import { Modal, Header } from 'semantic-ui-react'
import TabHeader from '../partials/TabHeader'
import LogoutButton from '../partials/LogoutButton'
import Card from '../partials/LegacyCard'
import TaskList from '../partials/TaskList'
import ExecutedContractsModule from '../partials/ExecutedContractsModule'
import TemplateFormsExecutedContractsModules from '../partials/TemplateFormsExecutedContractsModules'
import CalendlyWidget from 'components/partials/CalendlyWidget'
import { generateInvestmentProfileItems } from 'actions/iacHelpers'
import UpdatePasswordForm from 'components/forms/UpdatePasswordForm'
import { ClientContext } from 'contexts/ClientContext'
import { isLegacyFirm } from 'config/firmConfig'
import DisclosuresList from 'components/partials/DisclosuresList'
import BrandLogo from 'components/partials/BrandLogo'
import PoweredByBitsy from 'components/partials/PoweredByBitsy'
import {
  useUser,
  useFirm,
  useAdvisor,
  useDisclosures,
  useTasks,
} from 'contexts'
import { useItem } from 'hooks'
import styled from 'styled-components'
import { colors } from 'styles'
import { Row, ColSm12ColMd8 } from 'components/styled'

const ClientDashboard = memo(() => {
  const { uid } = useUser()
  const { firm, firmID } = useFirm()
  const { advisor } = useAdvisor()
  const { firmDisclosures, advisorDisclosures } = useDisclosures()
  const { tasks } = useTasks()

  const client = useItem({ itemType: 'client', uid })

  if (!client) {
    return ''
  }

  let firmName = 'Your firm'
  if (firm && (firm.name || firm.legal_name)) {
    firmName = firm.name || firm.legal_name
  }

  var greetingName = client.legal_name
  if (client.legal_name_first) {
    greetingName = client.legal_name_first
  }

  const investment_profile_items = generateInvestmentProfileItems(
    client.investment_profiles
  )

  return (
    <ClientContext.Provider value={{ clientID: uid, client }}>
      <ClientDashboardWrapper className="dashboard">
        <StyledTabHeader
          heading={
            (advisor || firm) && <StyledBrandLogo brand={advisor || firm} />
          }
          subhead={`Welcome back, ${greetingName}!`}
          className="col-sm-12 col-md-8"
        >
          <LogoutButton
            className="sm-button btn-sm primary d-block ml-auto"
            color={
              firm &&
              firm.styles &&
              firm.styles.colors &&
              firm.styles.colors.accent
                ? firm.styles.colors.accent
                : ''
            }
          />
        </StyledTabHeader>

        <Row>
          <ColSm12ColMd8>
            <Card
              title="Current Tasks"
              noneYet={
                !tasks || !Object.keys(tasks).length
                  ? "There are no outstanding items for you at this time. We'll email you with any updates!"
                  : ''
              }
            >
              <TaskList
                tasks={tasks}
                uid={uid}
                client={client}
                firm={firm}
                advisor={advisor}
                location="client-dashboard"
                automaticOpenTask
              />
            </Card>
          </ColSm12ColMd8>
        </Row>

        <Row>
          <ColSm12ColMd8>
            <StyledCard
              className="client-documents"
              title="Important Documents"
            >
              {firmID && isLegacyFirm(firmID) && (
                <>
                  <ExecutedContractsModule
                    type="iac"
                    title="Advisory Agreements"
                    documents={client.iacs}
                    document={client.iac_file}
                    clientID={uid}
                  />

                  <Card
                    title="Investor Profile"
                    noneYet={
                      !client.investment_profiles ||
                      !client.investment_profiles.length ||
                      !investment_profile_items.props.children.length
                        ? 'No profile yet!'
                        : ''
                    }
                  >
                    <ul className="investment-profiles">
                      {investment_profile_items}
                    </ul>
                  </Card>

                  <ExecutedContractsModule
                    type="suitability"
                    title="Suitability Forms"
                    documents={client.suitability_forms}
                    noneYet={
                      !client.suitability_forms ||
                      !client.suitability_forms.length
                        ? 'No documents yet!'
                        : ''
                    }
                    clientID={uid}
                    hasPendingSuitabilityForm={
                      client.hasPendingSuitabilityForm
                        ? 'Your advisor is reviewing your Suitability Form and will send a request to sign shortly.'
                        : null
                    }
                  />
                </>
              )}

              <TemplateFormsExecutedContractsModules
                clientID={uid}
                hideIfEmpty={true}
              />

              {firm && !firm.hideDisclosuresOnClientProfile && (
                <div>
                  <Card
                    title="Disclosures"
                    noneYet={
                      (!firmDisclosures ||
                        !Object.keys(firmDisclosures) ||
                        !Object.keys(firmDisclosures).length) &&
                      (!advisorDisclosures ||
                        !Object.keys(advisorDisclosures) ||
                        !Object.keys(advisorDisclosures).length)
                        ? 'Your firm and advisor have not uploaded any disclosures yet!'
                        : ''
                    }
                    className="executed-contracts-module"
                  >
                    <DisclosuresList
                      firmDisclosures={firmDisclosures}
                      advisorDisclosures={advisorDisclosures}
                      hideIfEmpty={true}
                    />
                  </Card>
                </div>
              )}
            </StyledCard>
          </ColSm12ColMd8>
        </Row>

        {advisor ? (
          <Row>
            <ColSm12ColMd8>
              {advisor.name && advisor.calendly && advisor.calendly.url && (
                <Card title={advisor.name + "'s Calendar"}>
                  <CalendlyWidget url={advisor.calendly.url} prefill={client} />
                </Card>
              )}
            </ColSm12ColMd8>
          </Row>
        ) : (
          ''
        )}

        <UpdatePasswordModal open={!client.hasSetPassword}>
          <StyledHeader icon>
            <WelcomeModalBrandLogo brand={advisor || firm} />
            Welcome to your forms wizard!
          </StyledHeader>
          <ModalContent>
            <p>
              Your financial professional would like to collaborate with you
              regarding the information necessary to help open your account. All
              information is private and only utilized for the purpose of
              establishing your account using this secure system powered by
              Bitsy. If you have any questions please contact your financial
              professional.
            </p>
            <p>
              First, please enter a unique, secure password below that will be
              used along with your email address (<b>{client.email}</b>) for
              logging into this portal.
            </p>
            <p>
              This will not change any passwords you have with{' '}
              {firmName === 'Your firm' ? firmName.toLowerCase() : firmName}.
            </p>
            <UpdatePasswordForm firstTime={!client.hasSetPassword} />
          </ModalContent>
          <ModalActions>
            <StyledPoweredByBitsy />
          </ModalActions>
        </UpdatePasswordModal>

        <Footer>
          <StyledPoweredByBitsy link="https://support.bitsyadvisor.com/docs/for-clients/welcome" />
        </Footer>
      </ClientDashboardWrapper>
    </ClientContext.Provider>
  )
})

export default ClientDashboard

const ClientDashboardWrapper = styled.div`
  .row {
    justify-content: center;

    &:nth-of-type(1) {
      h1 {
        margin-bottom: 15px;
      }
    }
  }

  ul.tasks {
    li {
      margin-bottom: 30px;

      .message {
        margin-bottom: 15px;
      }

      button {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }
`

const StyledBrandLogo = styled(BrandLogo)`
  text-align: center;
`

const UpdatePasswordModal = styled(Modal)`
  &&& {
    font-size: 120%;

    #update-password {
      position: relative;
      text-align: center;
      margin-top: 2.75rem;
    }

    #update-password button[type='submit'] {
      font-size: 100%;
    }
  }
`

const StyledTabHeader = styled(TabHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;

  & > h1 {
    flex-basis: 100%;
    margin-bottom: 45px;
  }
`

const StyledHeader = styled(Header)`
  &&&& {
    color: ${colors.primary};
    border-bottom: none;
    padding: 2.5rem 3rem;
  }
`

const WelcomeModalBrandLogo = styled(BrandLogo)`
  font-size: 150%;
  line-height: 150%;
  margin-bottom: 30px;
`

const ModalContent = styled(Modal.Content)`
  &&& {
    padding: 0 3rem;
    padding-bottom: 2rem;
  }
`

const ModalActions = styled(Modal.Actions)`
  &&& {
    padding: 1rem 2rem;
  }
`

const StyledPoweredByBitsy = styled(PoweredByBitsy)`
  position: relative !important;
  margin-left: auto;
`

const StyledCard = styled(Card)`
  & > .card-body > * {
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .executed-contracts-module > .card-body > .cards > .card {
    width: 100%;
  }
`

const Footer = styled.div`
  position: relative;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
`
