import React, { Component, Fragment } from 'react'
import dayjs from 'dayjs'
import { clientInitialState } from '../../config/iacConfig'
import {
  removeEmptyObjects,
  cleanObject,
} from '../../actions/formattingHelpers'
import {
  getFirmDetails,
  stopListeningToFirmDetails,
} from '../../actions/dbActions'
import {
  prospectAddProspectToDB,
  prospectUpdateProspectInDB,
} from '../../actions/db/prospectActions'
import { getAdvisorDetails } from '../../actions/db/advisorActions'
import { addTaskToDB } from '../../actions/db/taskActions'
import CalendlyWidget from '../partials/CalendlyWidget'
import './PreMeetingQuestionnaire.scss'
import {
  Question,
  Prompt,
  InfoPrompt,
  AddSuccessSlideContents,
} from './partials'

class PreMeetingQuestionnaire extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firm: null,
      client: clientInitialState,
      prospectID: null,
      promptContinue: null,
      bookNow: false,
      addSuccess: false,
      errors: [],
    }

    this.handleSelect = this.handleSelect.bind(this)
    this.handleSelectMulti = this.handleSelectMulti.bind(this)
    this.handleClientInputChange = this.handleClientInputChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTextMultipleChange = this.handleTextMultipleChange.bind(this)
    this.handleContinue = this.handleContinue.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { inviteCode } = this.props

    // Parse invite code
    var firmID = ''
    var advisorID = ''
    var prospectID = ''
    if (inviteCode.length) {
      var inviteCodeSplit = inviteCode.split('-')
      firmID = inviteCodeSplit[0]
      advisorID = inviteCodeSplit[1]
      prospectID = inviteCodeSplit[2]

      // Handle firm
      if (firmID.length) {
        this.setState({
          client: {
            ...this.state.client,
            firm: firmID,
          },
        })

        // Get firm details
        getFirmDetails(firmID, this)
      }

      // Handle advisor
      if (advisorID && advisorID.length) {
        this.setState({
          client: {
            ...this.state.client,
            advisor: advisorID,
          },
        })

        // // Get advisor details
        // getFirmDetails(firmID, this)
      }

      // Handle prospectID
      if (prospectID && prospectID.length) {
        this.setState({
          prospectID: prospectID,
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Handle bookNow
    if (
      !prevState.bookNow &&
      this.state.client.prospect.how_serve ===
        "I'd actually like to book a meeting now"
    ) {
      this.setState({
        bookNow: true,
      })
    }
  }

  componentWillUnmount() {
    stopListeningToFirmDetails()
  }

  addClasses(target) {
    const parent = target.closest('.slideContents')
    var sibling = parent.nextSibling
    if (target.name === 'infoprompt-submit') {
      sibling = parent.nextElementSibling
    }

    // Add classes
    if (parent.classList.contains('active')) {
      parent.classList.remove('active')
      parent.classList.add('completed')
      if (sibling && sibling.classList) {
        sibling.classList.add('active')
      } else {
        // TO DO: Add handling for last

        setTimeout(function () {
          const parent = target.closest('.slideContents')
          var sibling = parent.nextSibling
          if (target.name === 'infoprompt-submit') {
            sibling = parent.nextElementSibling
          }
          if (sibling && sibling.classList) {
            sibling.classList.add('active')
          }
        }, 100)
      }
    } else {
      parent.classList.add('active')
    }
  }

  handleSelect(event, name, value) {
    event.preventDefault()

    const target = event.target

    // TO DO: Add validation

    if (name === 'prompt_continue') {
      // Prompt Continue changes
      this.setState(
        {
          promptContinue: value,
        },
        this.addClasses(target)
      )
    } else {
      this.setState(
        {
          client: {
            ...this.state.client,
            prospect: {
              ...this.state.client.prospect,
              [name]: value,
            },
          },
        },
        this.addClasses(target)
      )
    }

    // // Add classes
    // this.addClasses(target)
  }

  handleSelectMulti(event, name, value) {
    event.preventDefault()

    var joinedAnswers = this.state.client.prospect[name]
    if (!event.target.classList.contains('selected')) {
      // Handle add
      joinedAnswers = this.state.client.prospect[name].concat([value])
    } else {
      // Handle remove
      joinedAnswers = this.state.client.prospect[name].filter(
        (e) => e !== value
      )
    }

    this.setState({
      client: {
        ...this.state.client,
        prospect: {
          ...this.state.client.prospect,
          [name]: joinedAnswers,
        },
      },
    })
  }

  handleClientInputChange(event) {
    const name = event.target.name
    const value = event.target.value

    if (name.startsWith('occupation_details-')) {
      const detailName = name.replace('occupation_details-', '')

      // Occupation Details changes
      this.setState({
        client: {
          ...this.state.client,
          occupation_details: {
            ...this.state.client.occupation_details,
            [detailName]: value,
          },
        },
      })
    } else if (name.startsWith('personal_advisors-')) {
      const nameSplit = name.replace('personal_advisors-', '').split('-')
      const advisorType = nameSplit[0]
      const detailName = nameSplit[1]

      // Personal Advisors changes
      this.setState({
        client: {
          ...this.state.client,
          personal_advisors: {
            ...this.state.client.personal_advisors,
            [advisorType]: {
              ...this.state.client.personal_advisors[advisorType],
              [detailName]: value,
            },
          },
        },
      })
    } else if (
      name === 'current_saving' ||
      name === 'current_contributions' ||
      name === 'current_insurance_plans'
    ) {
      const selectedOptionsArray = Array.from(event.target.selectedOptions)
      const joinedAnswers = selectedOptionsArray.map((option) => option.value)

      // Select Multiple changes
      this.setState({
        client: {
          ...this.state.client,
          [name]: joinedAnswers,
        },
      })
    } else {
      this.setState(
        {
          client: {
            ...this.state.client,
            [name]: value,
          },
        },
        function () {
          // Prospect legal name changes
          if (name === 'legal_name_first' || name === 'legal_name_last') {
            this.setState({
              client: {
                ...this.state.client,
                legal_name:
                  this.state.client.legal_name_first +
                  ' ' +
                  this.state.client.legal_name_last,
              },
            })
          }
        }
      )
    }
  }

  handleInputChange(event) {
    const name = event.target.name
    const value = event.target.value

    // if (name === 'prompt_continue') {

    //   // Prompt Continue changes
    //   this.setState({
    //     promptContinue: value
    //   })

    // } else {
    this.setState({
      client: {
        ...this.state.client,
        prospect: {
          ...this.state.client.prospect,
          [name]: value,
        },
      },
    })
  }

  handleTextMultipleChange(id, fields) {
    this.setState({
      client: {
        ...this.state.client,
        [id]: fields,
      },
    })
  }

  handleContinue(event) {
    const target = event.target

    // TO DO: Add validation

    if (target.name === 'infoprompt-submit') {
      this.handleSubmit(event)
    }

    // Add classes
    this.addClasses(target)
  }

  async handleSubmit(event) {
    event.preventDefault()

    const { prospectID, client, firm } = this.state

    if (prospectID) {
      // Update database
      prospectUpdateProspectInDB(client, prospectID, this)
    } else {
      // Add to database
      prospectAddProspectToDB(client, this)

      // this.setState({
      //   errors: ['There was an error. Please contact Bitsy Advisor. [PMQ-NPID]']
      // })
    }

    // Add follow-up contact reminder task
    if (client.prospect.how_serve && client.prospect.how_serve.length) {
      // Set up performAt
      var performAt = dayjs()
      switch (client.prospect.how_serve) {
        case 'They can contact me to meet in 3-6 months':
          performAt = performAt.add(3, 'month')
          break
        case 'They can contact me to meet in 1-2 weeks':
          performAt = performAt.add(1, 'week')
          break
        default:
      }
      performAt = performAt.toDate()

      // Set up options
      var options = {
        firm: {
          name: firm.name,
          email: firm.email,
        },
        prospect: {
          name: client.legal_name,
          email: client.email,
          details: removeEmptyObjects(cleanObject(client)),
        },
      }

      // Add advisor to options if provided
      if (client.advisor && client.advisor.length) {
        const advisorDetails = await getAdvisorDetails(client.advisor)

        if (advisorDetails && advisorDetails.name && advisorDetails.email) {
          options['advisor'] = {
            name: advisorDetails.name,
            email: advisorDetails.email,
          }
        }
      }

      if (options.firm && options.prospect) {
        addTaskToDB(
          'emailSendProspectContactPrompt',
          performAt,
          options,
          this,
          'prospect'
        )
      }
    }

    return
  }

  render() {
    // const { firm } = this.props
    const {
      firm,
      advisor,
      client,
      promptContinue,
      bookNow,
      addSuccess,
    } = this.state

    if (!firm) {
      return ''
    }

    var calendlyURLField = 'url'
    if (client.prospect.booked_meeting_type) {
      calendlyURLField += '_' + client.prospect.booked_meeting_type
    }

    var calendlyURL = null
    if (firm && firm.calendly && firm.calendly[calendlyURLField]) {
      calendlyURL = firm.calendly[calendlyURLField]
    }
    if (advisor && advisor.calendly && advisor.calendly[calendlyURLField]) {
      calendlyURL = advisor.calendly[calendlyURLField]
    }

    return (
      <div className="premeeting-questionnaire">
        <div className="slide">
          {!client.prospect.interest_level ? (
            <Question
              type="select"
              name="interest_level"
              question={
                'What were you hoping to learn about ' + firm.name + '?'
              }
              options={[
                {
                  value: 'low',
                  answer: "My interest is low, I'm just researching for now",
                },
                {
                  value: 'high',
                  answer:
                    'My interest is higher than normal, \nI need to hire someone and/or make a change',
                },
              ]}
              handleSelect={this.handleSelect}
            />
          ) : (
            ''
          )}

          <Question
            type="select"
            name="referral"
            question="How'd you hear about us?"
            options={[
              { answer: 'Personal friend/referral' },
              { answer: 'Instagram/Facebook' },
              { answer: 'Email I received' },
              { answer: 'Other' },
            ]}
            handleSelect={this.handleSelect}
          />

          {client.prospect.referral === 'Personal friend/referral' ? (
            <Question
              type="text"
              name="referred_by"
              question="Who were you referred by?"
              handleInputChange={this.handleInputChange}
              handleContinue={this.handleContinue}
            />
          ) : (
            ''
          )}

          {!promptContinue ? (
            <Prompt
              isActive={
                client.prospect.referral &&
                client.prospect.referral.length &&
                client.prospect.referral !== 'Personal friend/referral'
              }
              prompt={
                client.prospect.interest_level === 'high'
                  ? "Thanks for visiting, we'll ask you a few questions so that we can be prepared to serve you well and be most efficient with your time.  This will take at least 10 minutes, sound good?"
                  : 'Thanks for visiting, we just have 7 quick questions for you, sound reasonable?'
              }
              handleSelect={this.handleSelect}
            />
          ) : (
            ''
          )}

          {client.prospect.interest_level ? (
            <Fragment>
              <Question
                type="paragraph"
                name="money_importance"
                question="What is important about money to you?"
                handleInputChange={this.handleInputChange}
                handleContinue={this.handleContinue}
              />
              <Question
                type="paragraph"
                name="vision"
                question={
                  client.prospect.interest_level === 'high'
                    ? 'What is your vision for the future?  Please share as much as possible.'
                    : 'What is your vision for the future? Please share to your comfort level.'
                }
                handleInputChange={this.handleInputChange}
                handleContinue={this.handleContinue}
              />
              <Question
                type="paragraph"
                name="experience"
                question="What is your experience to date with financial planning/planners?"
                handleInputChange={this.handleInputChange}
                handleContinue={this.handleContinue}
              />

              {client.prospect.interest_level === 'high' ? (
                <Question
                  type="select"
                  name="TO_DO"
                  question="QUESTION TO DO?"
                  options={[
                    {
                      answer:
                        'I feel great about it right now, but still want to explore your firm',
                    },
                    { answer: 'I feel good about it, but open to suggestion' },
                    {
                      answer:
                        'I started one but need help finishing and implementing it',
                    },
                    { answer: "I don't have one and need one fast" },
                  ]}
                  handleSelect={this.handleSelect}
                />
              ) : (
                <Question
                  type="select"
                  name="current_feelings"
                  question="How do you feel about your current financial plan?"
                  options={[
                    { answer: "I'd rather not say" },
                    {
                      answer:
                        "I'd like to know how you can benefit me before saying",
                    },
                    { answer: 'I feel pretty good about it' },
                    { answer: 'It could use some improvement' },
                  ]}
                  handleSelect={this.handleSelect}
                />
              )}

              <Question
                type="paragraph"
                name="expectations"
                question={
                  'If you hired ' +
                  firm.name +
                  ', what would you expect from them?'
                }
                handleInputChange={this.handleInputChange}
                handleContinue={this.handleContinue}
              />

              <Question
                type="select"
                name="move_forward"
                question={
                  'If you met with ' +
                  firm.name +
                  ', and they impressed you, how would you hope to move forward?'
                }
                options={
                  client.prospect.interest_level === 'high'
                    ? [
                        {
                          answer:
                            'I would request to have another meeting shortly thereafter',
                        },
                        {
                          answer:
                            'I would likely hire them in some form or fashion',
                        },
                        {
                          answer:
                            "If I'm acceptable, I would hire them on the spot",
                        },
                      ]
                    : [
                        {
                          answer:
                            'It would be hard to move forward, given existing relationships',
                        },
                        { answer: 'I would be open to at least meeting again' },
                        { answer: 'I would request another meeting quickly' },
                        {
                          answer:
                            'I would likely hire them in some form or fashion',
                        },
                      ]
                }
                handleSelect={this.handleSelect}
              />

              {client.prospect.interest_level === 'low' ? (
                <Fragment>
                  <Question
                    type="select_multi"
                    name="interested_in"
                    question={
                      "I'm interested in talking with " + firm.name + ' about?'
                    }
                    options={[
                      { answer: 'Investment Planning' },
                      { answer: 'My Business Plan' },
                      { answer: 'Insurance Planning' },
                      {
                        answer:
                          "I'm only interested in a casual conversation for now",
                      },
                    ]}
                    handleSelect={this.handleSelectMulti}
                    handleContinue={this.handleContinue}
                    currentSelections={client.prospect.interested_in}
                  />
                  <Question
                    type="select"
                    name="how_serve"
                    question={'How can ' + firm.name + ' serve you now?'}
                    options={[
                      { answer: 'They can contact me to meet in 3-6 months' },
                      { answer: 'They can contact me to meet in 1-2 weeks' },
                      {
                        answer: 'They can contact me today, to setup a meeting',
                      },
                      { answer: "I'd actually like to book a meeting now" },
                    ]}
                    handleSelect={this.handleSelect}
                  />

                  {bookNow ? (
                    <Fragment>
                      <Question
                        type="select"
                        name="booked_meeting_type"
                        question="How would you like to discuss?"
                        options={[
                          {
                            value: 'phone',
                            answer:
                              'I just want a few minutes on the phone for now',
                          },
                          {
                            value: 'meeting-maybe',
                            answer:
                              "Actually, I'd prefer to meet 1-1 in person",
                          },
                        ]}
                        handleSelect={this.handleSelect}
                      />

                      {client.prospect.booked_meeting_type ===
                      'meeting-maybe' ? (
                        <Fragment>
                          <Question
                            type="select"
                            name="booked_meeting_type"
                            question="A 1-1 meeting request needs a bit more information from you, just so we're efficient with your time, how does that sound?"
                            options={[
                              { value: 'meeting', answer: 'Sounds good' },
                              {
                                value: 'phone',
                                answer:
                                  "No, I'll just stick with a phone call for now",
                              },
                            ]}
                            handleSelect={this.handleSelect}
                          />
                        </Fragment>
                      ) : (
                        ''
                      )}

                      {client.prospect.booked_meeting_type === 'phone' ? (
                        <InfoPrompt
                          depth="basic"
                          client={client}
                          handleInputChange={this.handleClientInputChange}
                          handleTextMultipleChange={
                            this.handleTextMultipleChange
                          }
                          handleSubmit={this.handleContinue}
                        />
                      ) : (
                        ''
                      )}

                      {client.prospect.booked_meeting_type === 'meeting' ? (
                        <InfoPrompt
                          depth="more"
                          client={client}
                          handleInputChange={this.handleClientInputChange}
                          handleTextMultipleChange={
                            this.handleTextMultipleChange
                          }
                          handleSubmit={this.handleContinue}
                        />
                      ) : (
                        ''
                      )}

                      {client.prospect.booked_meeting_type &&
                      client.prospect.booked_meeting_type.length &&
                      client.prospect.booked_meeting_type !==
                        'meeting-maybe' ? (
                        <div className="slideContents calendlyWidget">
                          {addSuccess ? (
                            <AddSuccessSlideContents meeting={true} />
                          ) : (
                            ''
                          )}
                          <CalendlyWidget url={calendlyURL} prefill={client} />
                        </div>
                      ) : (
                        ''
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {addSuccess ? (
                        <div className="slideContents active">
                          <AddSuccessSlideContents />
                        </div>
                      ) : (
                        <Fragment>
                          {client.prospect.interest_level === 'low' &&
                          client.prospect.how_serve ? (
                            <InfoPrompt
                              depth="basic"
                              client={client}
                              handleInputChange={this.handleClientInputChange}
                              handleTextMultipleChange={
                                this.handleTextMultipleChange
                              }
                              handleSubmit={this.handleSubmit}
                            />
                          ) : (
                            ''
                          )}

                          {client.prospect.interest_level === 'high' &&
                          client.prospect.how_serve ? (
                            <InfoPrompt
                              depth="more"
                              client={client}
                              handleInputChange={this.handleClientInputChange}
                              handleTextMultipleChange={
                                this.handleTextMultipleChange
                              }
                              handleSubmit={this.handleSubmit}
                            />
                          ) : (
                            ''
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                ''
              )}

              {client.prospect.interest_level === 'high' &&
              client.prospect.move_forward ? (
                <Fragment>
                  {addSuccess ? (
                    <div className="slideContents active">
                      <AddSuccessSlideContents />
                    </div>
                  ) : (
                    <InfoPrompt
                      depth="more"
                      client={client}
                      handleInputChange={this.handleClientInputChange}
                      handleTextMultipleChange={this.handleTextMultipleChange}
                      handleSubmit={this.handleSubmit}
                    />
                  )}
                </Fragment>
              ) : (
                ''
              )}
            </Fragment>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}

export default PreMeetingQuestionnaire
