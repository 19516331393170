import React, { memo } from 'react'
import { FluidContainer } from 'components/styled'
import styled from 'styled-components'

export default memo(function TabContents(props) {
  const { children } = props

  return (
    <Content>
      <FluidContainer>{children}</FluidContainer>
    </Content>
  )
})

const Content = styled.div`
  padding: 0 0.5rem;

  @media (min-width: 1200px) {
    padding: 0 66px 30px;
  }
`
