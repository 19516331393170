import React, { memo, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Form as SUIForm, Message } from 'semantic-ui-react'
import styled from 'styled-components'
import { VisuallyHidden } from 'styles/_mixins'

const Form = memo(
  ({ fields, submitButtonText = 'Submit', onSubmit, children, ...props }) => {
    const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
      setValue,
    } = useForm()

    const [submitErrors, setSubmitErrors] = useState([])

    useEffect(() => {
      fields.forEach((field) => {
        register(field.name, {
          required:
            field.required && field.required.message
              ? field.required.message
              : field.required
              ? `${field.label} is required`
              : false,
          pattern: field.pattern,
          minLength: field.minLength,
        })
      })
    }, [fields, register])

    const submitHandler = (data) => onSubmit(data, setSubmitErrors)

    return (
      <StyledForm size="big" onSubmit={handleSubmit(submitHandler)} {...props}>
        {fields.map((field) =>
          field.type === 'checkbox' ? (
            <FormCheckbox
              key={field.name}
              type={field.type}
              name={field.name}
              id={field.name}
              label={field.label}
              onChange={(e, { name, checked }) => setValue(name, checked)}
              error={errors[field.name] ? errors[field.name].message : null}
            />
          ) : (
            <SUIForm.Input
              key={field.name}
              type={field.type}
              name={field.name}
              id={field.name}
              label={field.label}
              placeholder={field.placeholder || field.label}
              defaultValue={field.defaultValue}
              icon={field.icon}
              iconPosition="left"
              onChange={(e, { name, value }) => setValue(name, value)}
              error={errors[field.name] ? errors[field.name].message : null}
              readOnly={field.readOnly}
            />
          )
        )}
        {children}
        {submitErrors && !!submitErrors.length && (
          <ErrorMessages error>
            <Message.Header>Something went wrong</Message.Header>
            <Message.List>
              {submitErrors.map((error, idx) => (
                <Message.Item key={idx}>{error}</Message.Item>
              ))}
            </Message.List>
          </ErrorMessages>
        )}
        <SubmitButton
          primary
          fluid
          size="big"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {submitButtonText}
        </SubmitButton>
      </StyledForm>
    )
  }
)

export default Form

const StyledForm = styled(SUIForm)`
  &&& {
    *:not(.ui.checkbox) > label {
      ${VisuallyHidden()}
    }

    .ui.prompt[role='alert'] {
      display: block;
      text-align: center;
    }
  }
`

const FormCheckbox = styled(SUIForm.Checkbox)`
  &&& {
    label {
      font-weight: 400;
      letter-spacing: 0;
      line-height: 1.4;

      &::before {
        margin-top: 1px;
      }
    }
  }
`

const ErrorMessages = styled(Message)`
  &&& {
    display: block !important;
    margin-top: 2em;
    font-size: 85%;
  }
`

const SubmitButton = styled(SUIForm.Button)`
  &&& {
    margin-top: 2em;
  }
`
