/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import { Message } from 'semantic-ui-react'
import myxss from 'components/forms/tf-fields/FormElements/myxss'

/**
 *
 * @param errors
 * @returns {*}
 * @constructor
 */
function ErrorList({ errors }) {
  return (
    <Message negative>
      <Message.Header>Errors</Message.Header>
      <Message.List>
        {errors.map((error, index) => {
          let message = error.stack

          // Don't show field names
          if (message.split(':') && message.split(':').length > 1) {
            message = message.split(/:(.+)/)[1]
          }

          return (
            <Message.Item
              key={`error-${index}`}
              dangerouslySetInnerHTML={{
                __html: myxss.process(message),
              }}
            />
          )
        })}
      </Message.List>
    </Message>
  )
}

ErrorList.propTypes = {
  errors: PropTypes.array,
}

export default ErrorList
