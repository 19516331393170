import React, { memo, useState } from 'react'
import { Button, Confirm } from 'semantic-ui-react'
import ErrorsList from 'components/partials/ErrorsList'
import { deleteTemplate } from 'actions/db/templates'
import { useFirm } from 'contexts'
import styled from 'styled-components'
import { colors } from 'styles'

const DeleteTemplateButton = memo(({ templateID }) => {
  const { firmID } = useFirm()

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [errors, setErrors] = useState([])

  const handleDeleteTemplate = async () => {
    const deleteRes = await deleteTemplate({ firmID, templateID })
    if (!deleteRes) {
      setErrors(
        'There was an error deleting the template. Please try again and contact Bitsy if the problem persists.'
      )
    }
  }

  return (
    <>
      <Button
        type="button"
        negative
        content="Delete template"
        size="tiny"
        icon="trash"
        onClick={() =>
          setConfirmModalOpen((confirmModalOpen) => !confirmModalOpen)
        }
      />
      <NegativeConfirm
        open={confirmModalOpen}
        content={
          <ConfirmContents>
            <p>
              <strong>This will permanently delete this template.</strong>
            </p>
            <p>Are you sure?</p>
            <ErrorsList errors={errors} />
          </ConfirmContents>
        }
        confirmButton="Yes, delete it."
        onConfirm={handleDeleteTemplate}
        cancelButton="Never mind!"
        onCancel={() => setConfirmModalOpen(false)}
      />
    </>
  )
})

export default DeleteTemplateButton

const NegativeConfirm = styled(Confirm)`
  &&& {
    button.primary {
      background: ${colors.negative};

      &:hover,
      &:focus {
        background: ${colors.negativeHover};
      }
    }
  }
`

const ConfirmContents = styled.div`
  padding: 30px;
`
