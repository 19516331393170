import React, { memo, useMemo } from 'react'
import { Icon } from 'semantic-ui-react'
import Table from 'components/partials/Table'
import { useTemplates } from 'contexts'
import styled from 'styled-components'
import { colors } from 'styles'

const FormsTable = memo(({ setEditingForm, cloneTemplate, firmID }) => {
  const { templates } = useTemplates()

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Visibility',
        accessor: 'inactive',
        Cell: (d) => {
          const row = d.cell.row.original
          const { inactive } = row

          if (inactive) {
            return <StyledIcon name="eye slash" />
          }

          return ''
        },
        headerClassName: 'text-center',
        className: 'text-center',
      },
      {
        Header: 'Details',
        Cell: (d) => {
          const row = d.cell.row.original
          const { id } = row

          return (
            <EditButton onClick={() => setEditingForm({ id, content: row })}>
              Edit
            </EditButton>
          )
        },
        headerClassName: 'text-center',
        className: 'text-center',
      },
      {
        Header: 'Clone',
        Cell: (d) => {
          const row = d.cell.row.original

          return (
            <EditButton
              onClick={() => cloneTemplate({ template: row, firmID })}
            >
              Clone
            </EditButton>
          )
        },
        headerClassName: 'text-center',
        className: 'text-center',
      },
    ],
    [setEditingForm, cloneTemplate, firmID]
  )

  const data = useMemo(() => {
    let preppedData = []
    if (templates && Object.keys(templates) && Object.keys(templates).length) {
      preppedData = Object.keys(templates).map((templateID) => {
        const template = templates[templateID]

        return {
          ...template,
          id: templateID,
        }
      })
    }

    return preppedData
  }, [templates])

  return (
    <Table
      columns={columns}
      data={data}
      sortBy={[
        {
          id: 'title',
          desc: false,
        },
      ]}
      noRowsFoundText="No forms yet. Please add one above!"
    />
  )
})

export default FormsTable

const StyledIcon = styled(Icon)`
  &&& {
    margin: 0 auto;
    display: block;
  }
`

const EditButton = styled.button`
  &&& {
    border-radius: 15px;
    background-color: #e9f1f0;
    color: ${colors.primary};
    font-size: 13px;
    line-height: 17px;
    padding: 5px 16px;
    border-color: #e9f1f0;
    white-space: nowrap;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-weight: normal;

    &:hover {
      color: #fff;
      background-color: ${colors.primary};
      border-color: ${colors.primary};
    }
  }
`
