import React from 'react'
import PoweredByBitsy from 'components/partials/PoweredByBitsy'
import { useFirm } from '../../contexts'

const EsignCallback = () => {
  // const location = useLocation()
  const { firm } = useFirm()

  // const searchParams = qs.parse(location.search.substr(1))
  // const isEmailFlow = searchParams.emailFlow === 'true'

  // // Set firm ID
  // if ((!firmID || !firmID.length) && searchParams.firmId) {
  //   setFirmInfo('firmID', searchParams.firmId)
  // }

  return (
    <div className="esign-page esign-access-documents">
      <div className="content">
        <h1>
          {firm && firm.name ? `Welcome to ${firm.name}!` : 'Welcome to Bitsy!'}
        </h1>

        <h2>It looks like your advisor sent you some documents.</h2>
        <p>
          We use DocuSign for this. Please click the button below to be
          redirected to DocuSign, where you can access your documents using the
          code from your email.
        </p>

        <a
          className="button"
          href="https://www.docusign.net/Member/authenticate.aspx"
          target="_blank"
          rel="noopener noreferrer"
          title="Access Documents on DocuSign"
        >
          Access Documents
        </a>
      </div>

      <PoweredByBitsy />
    </div>
  )
}

export default EsignCallback
