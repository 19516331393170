import React, { Component } from 'react'
import { quiz_questions } from '../config/options'
import './InvestmentProfileQuiz.scss'

class InvestmentProfileQuiz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      [this.props.idx + 'answers']: null,
      [this.props.idx + 'profile']: '',
      [this.props.idx + 'time_frame']: '',
      [this.props.idx + 'error']: '',
    }

    this.calculateRiskProfile = this.calculateRiskProfile.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount() {
    // Set up initial answers
    var initialAnswers = {}
    quiz_questions.map((question_group, qidx) => {
      var prettyQIdx = qidx + 1
      initialAnswers[this.props.idx + 'q' + prettyQIdx] = ''

      return initialAnswers
    })

    this.setState({
      [this.props.idx + 'answers']: initialAnswers,
    })
  }

  calculateRiskProfile(event) {
    event.preventDefault()

    const { idx } = this.props
    const answers = this.state[this.props.idx + 'answers']

    // Set up answer variables
    var pts = 0
    var termPts = 0

    Object.keys(answers).map((question, aidx) => {
      const answer = quiz_questions[aidx].options[answers[question]]

      if (answer) {
        pts += answer.pts

        if (answer.termPts) {
          termPts += answer.termPts
        }
      }

      return answers
    })

    // Translate result
    var result = ''

    if (pts >= 21 && pts <= 35) {
      // Conservative
      result = 'Conservative'
    } else if (pts >= 36 && pts <= 42) {
      // Moderate
      result = 'Moderate'
    } else if (pts >= 43 && pts <= 58) {
      // Aggressive
      result = 'Aggressive'
    } else if (pts >= 59 && pts <= 64) {
      // Speculative
      result = 'Speculative'
    }

    // Translate time frame
    var timeFrame = ''

    if (termPts >= 6 && termPts <= 10) {
      // Short Term
      timeFrame = 'Short Term'
    } else if (termPts >= 11 && termPts <= 15) {
      // Mid Term
      timeFrame = 'Mid Term'
    } else if (termPts >= 16 && termPts <= 20) {
      // Long Term
      timeFrame = 'Long Term'
    }

    // TO DO: Something with time frame?

    // Show result and send to form
    if (result !== '') {
      this.setState(
        {
          [idx + 'profile']: result,
          [idx + 'time_frame']: timeFrame,
          [idx + 'answers']: answers,
          [idx + 'error']: '',
        },
        function () {
          // Send result to form
          this.props.onResult(this.state, idx)
        }
      )
    } else {
      this.setState({
        error: "Please make sure you've answered all questions",
      })
    }
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      ...this.state,
      [this.props.idx + 'answers']: {
        ...this.state[this.props.idx + 'answers'],
        [name]: value,
      },
    })
  }

  render() {
    const { idx } = this.props
    const answers = this.state[idx + 'answers']
    const profile = this.state[idx + 'profile']
    const error = this.state[idx + 'error']

    if (answers) {
      this.quiz_questions = quiz_questions.map((question_group, qidx) => {
        var prettyQIdx = qidx + 1
        var fullQIdx = idx + 'q' + prettyQIdx

        this.options = question_group.options.map((option, oidx) => {
          var prettyOIdx = oidx + 1
          var fullOIdx = fullQIdx + 'option' + prettyOIdx

          return (
            <div key={fullOIdx} className="form-group">
              <input
                name={fullQIdx}
                id={fullOIdx}
                value={oidx}
                type="radio"
                onChange={this.handleInputChange}
                checked={answers[fullQIdx] === oidx + ''}
              />
              <label htmlFor={fullOIdx}>{option.description}</label>
            </div>
          )
        })

        return (
          <li key={fullQIdx}>
            <p>{question_group.question}</p>

            {this.options}
          </li>
        )
      })

      return (
        <div className="investment-profile-quiz">
          <ol>{this.quiz_questions}</ol>

          <div className="form-group">
            {error ? (
              <ul className="errors">
                <li>{error}</li>
              </ul>
            ) : (
              ''
            )}

            <button
              className="calculate-button"
              onClick={this.calculateRiskProfile}
            >
              Calculate!
            </button>

            {profile !== '' ? (
              <div className="result">
                You are a{profile === 'Aggressive' ? 'n' : ''} <br />
                <strong>{profile}</strong> <br />
                investor for{' '}
                {this.props.account !== 'All Accounts' ? 'your ' : ''}
                <br />
                {this.props.account}.
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )
    }

    return ''
  }
}

export default InvestmentProfileQuiz
