import React, { memo } from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { getFirmAccentColor } from 'actions/helpers'

const BrandedButton = memo(({ firm, ...props }) => (
  <StyledButton
    color="teal"
    $customColor={getFirmAccentColor(firm)}
    {...props}
  />
))

export default BrandedButton

const StyledButton = styled(Button)`
  &&& {
    &,
    &:hover,
    &:focus,
    &:active,
    &:visited {
      background-color: ${(props) => props.$customColor};
    }

    &:hover,
    &:focus {
      filter: ${(props) => props.$customColor && 'brightness(0.85)'};
      transition: 0.1s filter ease;
    }
  }
`
