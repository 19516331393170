import React, { Component } from 'react'
import styled from 'styled-components'
import { Row } from 'components/styled'
import { SmallHeading } from 'styles/_mixins'

export default class LegacyTable extends Component {
  render() {
    const { title, type, headerRow, rows } = this.props

    return (
      <BitsyTable
        className={`bitsy-table${
          type === 'columns' ? 'bitsy-columns-table' : ''
        }`}
        role="table"
        aria-label={title ? title : ''}
      >
        {title && <Row className="row title">{title}</Row>}

        {type === 'columns' ? (
          <>
            {headerRow && headerRow.length && (
              <Row className="flex-table row header" role="rowgroup">
                {headerRow.map((column, idx) => (
                  <div
                    key={idx}
                    className={idx === 0 ? 'flex-row first' : 'flex-row'}
                    role="columnheader"
                    style={{ width: 'calc(100% / ' + headerRow.length + ')' }}
                  >
                    {column.label}
                  </div>
                ))}
              </Row>
            )}

            {rows &&
              rows.map((row, ridx) => (
                <Row key={ridx} className="flex-table row" role="rowgroup">
                  {headerRow.map((column, idx) => (
                    <div
                      key={idx}
                      className={idx === 0 ? 'flex-row first' : 'flex-row'}
                      role="cell"
                      style={{ width: 'calc(100% / ' + headerRow.length + ')' }}
                    >
                      {row[column.id]}
                    </div>
                  ))}
                </Row>
              ))}
          </>
        ) : (
          <>
            {rows &&
              rows.map((row, idx) => (
                <Row className="row" key={idx}>
                  <div className="header">
                    {row.header}:
                    {row.headerNotes && (
                      <div className="notes">
                        <small className="instructional">
                          {row.headerNotes}
                        </small>
                      </div>
                    )}
                  </div>
                  <div className="content">{row.content}</div>
                </Row>
              ))}
          </>
        )}
      </BitsyTable>
    )
  }
}

const BitsyTable = styled.div`
  .row {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    margin-left: 0;
    margin-right: 0;

    & > * {
      padding: 6px;
    }

    & > .header,
    & > .title {
      ${SmallHeading()};
      flex: 0 0 40%;
      margin-bottom: 0 !important;
    }

    & > .title {
      text-align: center;
      justify-content: center;
      padding: 6px;
      font-size: 14px;
    }

    .header {
      flex: 1 0 40%;
    }

    .content {
      flex: 1 0 60%;
    }
  }

  &.bitsy-columns-table {
    display: flex;
    flex-flow: row wrap;

    .header,
    .row {
      width: 100%;
      flex-basis: 100%;

      & > div {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        padding: 15px;
        text-align: center;
      }
    }

    .header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      & > div {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 80%;
      }
    }
  }
`
