import React, { memo, useState } from 'react'
import { Button } from 'semantic-ui-react'
import Select from 'react-select'
import { useUser, useFirm } from 'contexts'
import { useItemList } from 'hooks'
import { userIsFirm } from 'helpers/users'
import sendDocuments from 'actions/email/sendDocuments'

const SendDocumentsDropdown = memo(
  ({
    client,
    advisor = null,
    advisorID,
    firmDisclosures,
    advisorDisclosures,
  }) => {
    const { userType } = useUser()
    const { firmID, firm } = useFirm()

    const [forms, setForms] = useState([])
    const [sendingEmail, setSendingEmail] = useState(false)
    const [emailSent, setEmailSent] = useState(false)

    const firmDisclosuresOptions = Object.values(firmDisclosures || {}).map(
      (e) => ({
        value: e.name,
        label: e.name,
        link: e.link,
      })
    )

    const clientAdvisorDisclosures = useItemList({
      itemType: 'disclosure',
      userType: 'advisor',
      uid: advisorID,
      isUserItem: true,
      load: advisorID && userIsFirm(userType),
    })

    const advisorDisclosuresOptions = Object.values(
      clientAdvisorDisclosures || advisorDisclosures || {}
    ).map((e) => ({
      value: e.name,
      label: e.name,
      link: e.link,
    }))

    const groupedOptions = [
      {
        label: 'Firm disclosures',
        options: firmDisclosuresOptions,
      },
    ]

    if (advisorDisclosuresOptions.length) {
      groupedOptions.push({
        label: 'Advisor disclosures',
        options: advisorDisclosuresOptions,
      })
    }

    const handleDisclosureChange = (newValue) => {
      setForms(newValue)
      setEmailSent(false)
    }

    const handleSendDocuments = async () => {
      setSendingEmail(true)

      await sendDocuments({
        client,
        advisor:
          advisorID && advisor
            ? {
                ...advisor,
                uid: advisorID,
              }
            : null,
        firm: {
          name: firm.name,
          email: firm.email,
          uid: firmID,
        },
        forms: forms.map((e) => ({
          name: e.value,
          link: e.link,
        })),
        subject: firm.emailSettings?.disclosures?.subject,
        body: firm.emailSettings?.disclosures?.body,
      })

      setForms([])
      setSendingEmail(false)
      setEmailSent(true)
    }

    return (
      <>
        <Select
          isMulti
          value={forms}
          isLoading={sendingEmail}
          isDisabled={sendingEmail}
          options={groupedOptions}
          onChange={handleDisclosureChange}
        />

        <div className="send-button-container">
          <Button
            primary
            content={emailSent ? 'Sent' : 'Send'}
            loading={sendingEmail}
            disabled={!forms.length || sendingEmail}
            onClick={handleSendDocuments}
            icon={emailSent ? 'checkmark' : 'send'}
            labelPosition="right"
          />
        </div>
      </>
    )
  }
)

export default SendDocumentsDropdown
