/**
 * The contents of this directory are adapted from the react-form-builder2 library - see https://www.npmjs.com/package/react-form-builder2
 * They are used in the "frontend" TemplateForm only. FormBuilder uses its own elements from the bitsy-form-builder library.
 */

// eslint-disable-next-line max-classes-per-file
import React from 'react'
import Select from 'react-select'
import SignaturePad from 'react-signature-canvas'
import ReactBootstrapSlider from 'react-bootstrap-slider'
import styled from 'styled-components'
import { colors } from '../../../../styles'

import StarRating from './star-rating'
// import HeaderBar from './header-bar'
// import DatePicker from './date-picker'
import ComponentHeader from './component-header'
import ComponentLabel from './component-label'
import myxss from './myxss'

import DatePicker from './DatePicker'
import RadioButtons from './RadioButtons'
import FundAllocator from '../FundAllocator'

const FormElements = {}

class Header extends React.Component {
  render() {
    // const headerClasses = `dynamic-input ${this.props.options.data.element}-input`;
    let classNames = 'static'
    if (this.props.options.data.bold) {
      classNames += ' bold'
    }
    if (this.props.options.data.italic) {
      classNames += ' italic'
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <h3
          className={classNames}
          dangerouslySetInnerHTML={{
            __html: myxss.process(this.props.options.data.content),
          }}
        />
      </div>
    )
  }
}

class Paragraph extends React.Component {
  render() {
    let classNames = 'static'
    if (this.props.options.data.bold) {
      classNames += ' bold'
    }
    if (this.props.options.data.italic) {
      classNames += ' italic'
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <p
          className={classNames}
          dangerouslySetInnerHTML={{
            __html: myxss.process(this.props.options.data.content),
          }}
        />
      </div>
    )
  }
}

class Label extends React.Component {
  render() {
    let classNames = 'static'
    if (this.props.options.data.bold) {
      classNames += ' bold'
    }
    if (this.props.options.data.italic) {
      classNames += ' italic'
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <label
          className={classNames}
          dangerouslySetInnerHTML={{
            __html: myxss.process(this.props.options.data.content),
          }}
        />
      </div>
    )
  }
}

class LineBreak extends React.Component {
  render() {
    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <hr />
      </div>
    )
  }
}

class TextInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }

    this.inputField = React.createRef()
  }

  componentDidMount() {
    if (this.props.options && this.props.options.value) {
      this.setState({
        value: this.props.options.value,
      })
    }
  }

  render() {
    const props = {}
    props.type = 'text'
    props.className = 'form-control'
    props.name = this.props.options.data.field_name
    props.value = this.state.value
    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue
      props.ref = this.inputField
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }
    if (props.value) {
      baseClasses += ' has-value'
    }

    if (this.props.read_only) {
      props.disabled = 'disabled'
    }

    if (this.props.options && this.props.options.onBlur) {
      props.onBlur = () => this.props.options.onBlur(this)
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <FormGroup className="form-group">
          <ComponentLabel {...this.props} />
          <input
            {...props}
            onChange={(e) => this.setState({ value: e.target.value })}
          />
          <InputBar className="input-bar" />
        </FormGroup>
      </div>
    )
  }
}

class NumberInput extends React.Component {
  constructor(props) {
    super(props)
    this.inputField = React.createRef()
  }

  render() {
    const props = {}
    props.type = 'number'
    props.className = 'form-control'
    props.name = this.props.options.data.field_name

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue
      props.ref = this.inputField
    }

    if (this.props.read_only) {
      props.disabled = 'disabled'
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <FormGroup className="form-group">
          <ComponentLabel {...this.props} />
          <input {...props} />
          <InputBar className="input-bar" />
        </FormGroup>
      </div>
    )
  }
}

class TextArea extends React.Component {
  constructor(props) {
    super(props)
    this.inputField = React.createRef()
  }

  render() {
    const props = {}
    props.className = 'form-control'
    props.name = this.props.options.data.field_name

    if (this.props.read_only) {
      props.disabled = 'disabled'
    }

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue
      props.ref = this.inputField
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <FormGroup className="form-group">
          <ComponentLabel {...this.props} />
          <textarea {...props} />
          <InputBar className="input-bar" />
        </FormGroup>
      </div>
    )
  }
}

class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }

    this.inputField = React.createRef()
  }

  componentDidMount() {
    if (this.props.options && this.props.options.value) {
      this.setState({
        value: this.props.options.value,
      })
    }
  }

  render() {
    const props = {}
    props.className = 'form-control'
    props.name = this.props.options.data.field_name
    props.value = this.state.value

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue
      props.ref = this.inputField
    }

    if (this.props.read_only) {
      props.disabled = 'disabled'
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    if (this.props.options && this.props.options.onBlur) {
      props.onBlur = () => this.props.options.onBlur(this)
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <select
            {...props}
            onChange={(e) => this.setState({ value: e.target.value })}
          >
            <option value="" key="preview_placeholder" disabled={true}>
              Select one
            </option>
            {this.props.options.data.options.map((option) => {
              const this_key = `preview_${option.key}`
              return (
                <option value={option.text} key={this_key}>
                  {option.text}
                </option>
              )
            })}
          </select>
        </div>
      </div>
    )
  }
}

class Signature extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultValue: props.defaultValue,
    }
    this.inputField = React.createRef()
    this.canvas = React.createRef()
  }

  clear = () => {
    if (this.state.defaultValue) {
      this.setState({ defaultValue: '' })
    } else if (this.canvas.current) {
      this.canvas.current.clear()
    }
  }

  render() {
    const { defaultValue } = this.state
    let canClear = !!defaultValue
    const props = {}
    props.type = 'hidden'
    props.name = this.props.options.data.field_name

    if (this.props.mutable) {
      props.defaultValue = defaultValue
      props.ref = this.inputField
    }
    const pad_props = {}
    // umd requires canvasProps={{ width: 400, height: 150 }}
    if (this.props.mutable) {
      pad_props.defaultValue = defaultValue
      pad_props.ref = this.canvas
      canClear = !this.props.read_only
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    let sourceDataURL
    if (defaultValue && defaultValue.length > 0) {
      sourceDataURL = `data:image/png;base64,${defaultValue}`
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          {this.props.read_only === true || !!sourceDataURL ? (
            <img src={sourceDataURL} alt="signature" />
          ) : (
            <SignaturePad {...pad_props} />
          )}
          {canClear && (
            <i
              className="fas fa-times clear-signature"
              onClick={this.clear}
              title="Clear Signature"
            ></i>
          )}
          <input {...props} />
        </div>
      </div>
    )
  }
}

class Tags extends React.Component {
  constructor(props) {
    super(props)
    this.inputField = React.createRef()
    const { defaultValue, options } = props
    this.state = {
      value: this.getDefaultValue(defaultValue, options.data.options),
    }
  }

  getDefaultValue(defaultValue, options) {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        const vals = defaultValue.split(',').map((x) => x.trim())
        return options.filter((x) => vals.indexOf(x.value) > -1)
      }
      return options.filter((x) => defaultValue.indexOf(x.value) > -1)
    }
    return []
  }

  // state = { value: this.props.defaultValue !== undefined ? this.props.defaultValue.split(',') : [] };

  handleChange = (e) => {
    this.setState({ value: e })
  }

  render() {
    const options = this.props.options.data.options.map((option) => {
      option.label = option.text
      return option
    })
    const props = {}
    props.isMulti = true
    props.name = this.props.options.data.field_name
    props.onChange = this.handleChange

    props.options = options
    if (!this.props.mutable) {
      props.value = options[0].text
    } // to show a sample of what tags looks like
    if (this.props.mutable) {
      props.isDisabled = this.props.read_only
      props.value = this.state.value
      props.ref = this.inputField
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <Select {...props} />
        </div>
      </div>
    )
  }
}

class Checkboxes extends React.Component {
  constructor(props) {
    super(props)
    this.options = {}
  }

  render() {
    const self = this
    let classNames = 'custom-control custom-checkbox'
    if (this.props.options.data.inline) {
      classNames += ' option-inline'
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel className="form-label" {...this.props} />
          {this.props.options.data.options.map((option) => {
            const this_key = `preview_${option.key}`
            const props = {}
            props.name = `option_${option.key}`

            props.type = 'checkbox'
            props.value = option.value
            if (self.props.mutable) {
              props.defaultChecked =
                self.props.defaultValue !== undefined &&
                self.props.defaultValue.indexOf(option.key) > -1
            }
            if (this.props.read_only) {
              props.disabled = 'disabled'
            }
            return (
              <div className={classNames} key={this_key}>
                <input
                  id={'fid_' + this_key}
                  className="custom-control-input"
                  ref={(c) => {
                    if (c && self.props.mutable) {
                      self.options[`child_ref_${option.key}`] = c
                    }
                  }}
                  {...props}
                />
                <label
                  className="custom-control-label"
                  htmlFor={'fid_' + this_key}
                >
                  {option.text}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

class Image extends React.Component {
  render() {
    const style = this.props.options.data.center
      ? { textAlign: 'center' }
      : null

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses} style={style}>
        {/* {!this.props.mutable && (
          <HeaderBar
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            data={this.props.options.data}
            onDestroy={this.props._onDestroy}
            onEdit={this.props.onEdit}
            required={this.props.options.data.required}
          />
        )} */}
        {this.props.options.data.src && (
          <img
            src={this.props.options.data.src}
            width={this.props.options.data.width}
            height={this.props.options.data.height}
            alt="displayed"
          />
        )}
        {!this.props.options.data.src && (
          <div className="no-image">No Image</div>
        )}
      </div>
    )
  }
}

class Rating extends React.Component {
  constructor(props) {
    super(props)
    this.inputField = React.createRef()
  }

  render() {
    const props = {}
    props.name = this.props.options.data.field_name
    props.ratingAmount = 5

    if (this.props.mutable) {
      props.rating =
        this.props.defaultValue !== undefined
          ? parseFloat(this.props.defaultValue, 10)
          : 0
      props.editing = true
      props.disabled = this.props.read_only
      props.ref = this.inputField
    }

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <StarRating {...props} />
        </div>
      </div>
    )
  }
}

class HyperLink extends React.Component {
  render() {
    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <a
            target="_blank"
            href={this.props.options.data.href}
            rel="noopener noreferrer"
          >
            {this.props.options.data.content}
          </a>
        </div>
      </div>
    )
  }
}

class Download extends React.Component {
  render() {
    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <a
            href={`${this.props.download_path}?id=${this.props.options.data.file_path}`}
          >
            {this.props.options.data.content}
          </a>
        </div>
      </div>
    )
  }
}

class Camera extends React.Component {
  constructor(props) {
    super(props)
    this.state = { img: null }
  }

  displayImage = (e) => {
    const self = this
    const target = e.target
    let file
    let reader

    if (target.files && target.files.length) {
      file = target.files[0]
      // eslint-disable-next-line no-undef
      reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = () => {
        self.setState({
          img: reader.result,
        })
      }
    }
  }

  clearImage = () => {
    this.setState({
      img: null,
    })
  }

  render() {
    let baseClasses = 'SortableItem rfb-item'
    const name = this.props.options.data.field_name
    const fileInputStyle = this.state.img ? { display: 'none' } : null
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }
    let sourceDataURL
    if (
      this.props.read_only === true &&
      this.props.defaultValue &&
      this.props.defaultValue.length > 0
    ) {
      if (this.props.defaultValue.indexOf(name > -1)) {
        sourceDataURL = this.props.defaultValue
      } else {
        sourceDataURL = `data:image/png;base64,${this.props.defaultValue}`
      }
    }
    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          {this.props.read_only === true &&
          this.props.defaultValue &&
          this.props.defaultValue.length > 0 ? (
            <div>
              <img src={sourceDataURL} alt="uploaded" />
            </div>
          ) : (
            <div className="image-upload-container">
              <div style={fileInputStyle}>
                <input
                  name={name}
                  type="file"
                  accept="image/*"
                  capture="camera"
                  className="image-upload"
                  onChange={this.displayImage}
                />
                <div className="image-upload-control">
                  <div className="btn btn-default btn-school">
                    <i className="fas fa-camera"></i> Upload Photo
                  </div>
                  <p>Select an image from your computer or device.</p>
                </div>
              </div>

              {this.state.img && (
                <div>
                  <img
                    src={this.state.img}
                    height="100"
                    className="image-upload-preview"
                    alt="upload preview"
                  />
                  <br />
                  <div
                    className="btn btn-school btn-image-clear"
                    onClick={this.clearImage}
                  >
                    <i className="fas fa-times"></i> Clear Photo
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

class Range extends React.Component {
  constructor(props) {
    super(props)
    this.inputField = React.createRef()
    this.state = {
      value:
        props.defaultValue !== undefined
          ? parseInt(props.defaultValue, 10)
          : parseInt(props.options.data.default_value, 10),
    }
  }

  changeValue = (e) => {
    const { target } = e
    this.setState({
      value: target.value,
    })
  }

  render() {
    const props = {}
    const name = this.props.options.data.field_name

    props.type = 'range'
    props.list = `tickmarks_${name}`
    props.min = this.props.options.data.min_value
    props.max = this.props.options.data.max_value
    props.step = this.props.options.data.step

    props.value = this.state.value
    props.change = this.changeValue

    if (this.props.mutable) {
      props.ref = this.inputField
    }

    const datalist = []
    for (
      let i = parseInt(props.min_value, 10);
      i <= parseInt(props.max_value, 10);
      i += parseInt(props.step, 10)
    ) {
      datalist.push(i)
    }

    const oneBig = 100 / (datalist.length - 1)

    const _datalist = datalist.map((d, idx) => (
      <option key={`${props.list}_${idx}`}>{d}</option>
    ))

    const visible_marks = datalist.map((d, idx) => {
      const option_props = {}
      let w = oneBig
      if (idx === 0 || idx === datalist.length - 1) {
        w = oneBig / 2
      }
      option_props.key = `${props.list}_label_${idx}`
      option_props.style = { width: `${w}%` }
      if (idx === datalist.length - 1) {
        option_props.style = { width: `${w}%`, textAlign: 'right' }
      }
      return <label {...option_props}>{d}</label>
    })

    let baseClasses = 'SortableItem rfb-item'
    if (this.props.options.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <div className="range">
            <div className="clearfix">
              <span className="float-left">
                {this.props.options.data.min_label}
              </span>
              <span className="float-right">
                {this.props.options.data.max_label}
              </span>
            </div>
            <ReactBootstrapSlider {...props} />
          </div>
          <div className="visible_marks">{visible_marks}</div>
          <input name={name} value={this.state.value} type="hidden" />
          <datalist id={props.list}>{_datalist}</datalist>
        </div>
      </div>
    )
  }
}

class FundAllocatorElement extends React.Component {
  render() {
    const props = {}
    props.type = 'text'
    props.className = 'form-control'
    props.name = this.props.uiSchema['ui:options'].data.field_name

    let baseClasses = 'SortableItem rfb-item'

    if (this.props.read_only) {
      props.disabled = 'disabled'
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <FormGroup className="form-group">
          <FundAllocator
            {...props}
            id={props.name}
            value={this.props.formData}
            onLocalChange={(val) => {
              this.props.onChange(val)
            }}
          />
        </FormGroup>
      </div>
    )
  }
}

FormElements.Header = Header
FormElements.Paragraph = Paragraph
FormElements.Label = Label
FormElements.LineBreak = LineBreak
FormElements.TextInput = TextInput
FormElements.NumberInput = NumberInput
FormElements.TextArea = TextArea
FormElements.Dropdown = Dropdown
FormElements.Signature = Signature
FormElements.Checkboxes = Checkboxes
FormElements.DatePicker = DatePicker
FormElements.RadioButtons = RadioButtons
FormElements.Image = Image
FormElements.Rating = Rating
FormElements.Tags = Tags
FormElements.HyperLink = HyperLink
FormElements.Download = Download
FormElements.Camera = Camera
FormElements.Range = Range
FormElements.FundAllocator = FundAllocatorElement

export default FormElements

const FormGroup = styled.div`
  position: relative;

  & input:focus + .input-bar,
  & textarea:focus + .input-bar {
    &::before,
    &::after {
      width: 50%;
    }
  }
`

const InputBar = styled.div`
  display: block;
  position: relative;
  top: 0;

  &::before,
  &::after {
    background: ${colors.blue};
    bottom: 0;
    content: '';
    height: 0.125rem;
    position: absolute;
    transition: all 0.2s ease;
    width: 0;
  }

  &::before {
    left: 50%;
  }

  &::after {
    right: 50%;
  }
`
