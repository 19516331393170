import React, { Fragment } from 'react'
import { numberWithCommas } from './helpers'
import { validZipRegex, validEmailRegex } from '../config/regexPatterns'
import { assetsHeldAwayLabels } from '../config/clientConfig'
import { firmIDs } from '../config/firmConfig'

export function calculateAcceptableLoss(investmentProfile) {
  var acceptableLoss = ''
  if (investmentProfile === 'Conservative') {
    acceptableLoss = '5-7%'
  } else if (investmentProfile === 'Moderate') {
    acceptableLoss = '7-9%'
  } else if (investmentProfile === 'Aggressive') {
    acceptableLoss = '9-11%'
  } else if (investmentProfile === 'Speculative') {
    acceptableLoss = '11-14%'
  }

  return acceptableLoss
}

export function calculateAnnualFee(portfolioValue, annualFees) {
  var annualFeeRate = ''

  // Find portfolio value min
  var portfolioValueMin = ''
  if (portfolioValue.includes(' - ')) {
    portfolioValueMin = portfolioValue.split(' - ')[0]
  } else if (portfolioValue.includes('+')) {
    portfolioValueMin = portfolioValue.split('+')[0]
  }

  // Find annual fee for portfolio value
  annualFeeRate = annualFees.map(function (annualFee) {
    if (portfolioValueMin === annualFee.portfolio_min) {
      return annualFee.fee + ''
    } else {
      return ''
    }
  })

  return annualFeeRate
}

export function calculateClientAge(birthdate) {
  var birthday = new Date(birthdate)
  var ageDifMs = Date.now() - birthday.getTime()
  var ageDate = new Date(ageDifMs) // miliseconds from epoch
  var currentAge = Math.abs(ageDate.getUTCFullYear() - 1970)

  // Return empty string if not a number
  if (isNaN(currentAge)) {
    currentAge = ''
  }

  return currentAge
}

export function calculateNetWorthIncludingResidence(netWorth, residenceValue) {
  // Find net worth min
  var netWorthMin = ''
  var netWorthMax = ''
  if (netWorth.includes(' - ')) {
    netWorthMin = parseFloat(
      netWorth.split(' - ')[0].split('$')[1].replace(',', '')
    )
    netWorthMax = parseFloat(
      netWorth.split(' - ')[1].split('$')[1].replace(',', '')
    )
  } else if (netWorth.includes('+')) {
    netWorthMin = parseFloat(
      netWorth.split('+')[0].split('$')[1].replace(',', '')
    )
  }

  // Set default value
  var netWorthRes = ''

  // Calculate net worth with residence
  if (residenceValue) {
    var residenceValueVal = parseFloat(
      residenceValue.split('$')[1].replace(',', '')
    )

    var netWorthResMin = netWorthMin + residenceValueVal
    netWorthRes += '$' + numberWithCommas(netWorthResMin)

    if (netWorthMax) {
      var netWorthResMax = netWorthMax + residenceValueVal
      netWorthRes += ' - $' + numberWithCommas(netWorthResMax)
    }
  } else {
    netWorthRes = netWorth
  }

  return netWorthRes
}

export function calculateROR(investmentProfile) {
  var ror = ''
  if (investmentProfile === 'Conservative') {
    ror = '3-5%'
  } else if (investmentProfile === 'Moderate') {
    ror = '6-8%'
  } else if (investmentProfile === 'Aggressive') {
    ror = '9-12%'
  } else if (investmentProfile === 'Speculative') {
    ror = '13%+'
  }

  return ror
}

export function calculateTaxRate(incomeRange) {
  var taxRate = ''
  if (incomeRange === '<$20,000') {
    taxRate = '0% - 10%'
  } else if (incomeRange === '$20,001 - $49,999') {
    taxRate = '12%'
  } else if (incomeRange === '$50,000 - $99,999') {
    taxRate = '22%'
  } else if (incomeRange === '$100,000 - $249,999') {
    taxRate = '24% - 35%'
  } else if (incomeRange === '$250,000 - $500,000') {
    taxRate = '35% - 37%'
  } else if (incomeRange === '$500,001+') {
    taxRate = '37%'
  }

  return taxRate
}

export function calculateTimeHorizon(birthdate, retirement_age_range) {
  if (
    !birthdate ||
    !birthdate.length ||
    !retirement_age_range ||
    !retirement_age_range.length
  ) {
    return ''
  }

  var currentAge = calculateClientAge(birthdate)

  if (isNaN(currentAge)) {
    return ''
  }

  // Find lowest retirement age
  var lowestRetirementAge = ''
  if (retirement_age_range.includes('-')) {
    lowestRetirementAge = retirement_age_range.split('-')[0]
  } else if (retirement_age_range.includes('<')) {
    lowestRetirementAge = retirement_age_range.split('<')[1]
  } else if (retirement_age_range.includes('+')) {
    lowestRetirementAge = retirement_age_range.split('+')[0]
  }

  // Calculate time horizon
  var timeHorizonYears = parseInt(lowestRetirementAge) - currentAge
  var timeHorizon = timeHorizonYears + ' years'

  return timeHorizon
}

export function calculateTotalAdvisoryFee(client, firm) {
  var totalAdvisoryFee = ''
  var totalAdvisoryFeeVal = 0

  // Calculate firm advisory fee
  var firmClientFee = null
  if (
    client.fee_type === 'Fixed Fee' &&
    client.fee_fixed &&
    client.fee_fixed.length
  ) {
    firmClientFee = client.fee_fixed
  } else {
    calculateAnnualFee(client.portfolio_value, firm.annual_fees).filter(
      (el) => {
        if (el !== '') {
          firmClientFee = el
        }
        return el
      }
    )
  }
  if (firmClientFee) {
    firmClientFee = parseFloat(firmClientFee.replace('%'))
    totalAdvisoryFeeVal += firmClientFee
  }

  // Add third party fees
  if (firm.third_party_fees && firm.third_party_fees.length) {
    firm.third_party_fees.map((feeRow) => {
      totalAdvisoryFeeVal += parseFloat(feeRow.fee.replace('%', ''))

      return feeRow
    })
  }

  // Create final string
  totalAdvisoryFee = totalAdvisoryFeeVal + '%'

  return totalAdvisoryFee
}

export function currentDate() {
  const date = new Date()
  const formattedDate = date.toLocaleString('default', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  })

  return formattedDate
}

export function generateAssetsHeldAwayItems(items) {
  if (!items || items.length < 1) {
    return 'The client has no assets held away.'
  }

  var assets_held_away_items = []

  Object.keys(items).map((itemKey, idx) => {
    const assets_label = assetsHeldAwayLabels[itemKey]
    const assets_value = items[itemKey]

    if (assets_value) {
      assets_held_away_items.push(
        <li key={idx}>
          <div className="label">{assets_label}</div>
          <div className="value">{assets_value}</div>
        </li>
      )
    }

    return assets_value
  })

  return <Fragment>{assets_held_away_items}</Fragment>
}

export function generateInvestmentExperienceItems(investmentExperienceItems) {
  if (!investmentExperienceItems || investmentExperienceItems.length < 1) {
    return 'The client has no investment experience.'
  }

  var investment_experience_items = []

  investmentExperienceItems.map((investment_item, idx) => {
    if (investment_item.type && investment_item.years) {
      investment_experience_items.push(
        <li key={idx}>
          <div className="label">
            {investment_item.type === 'Other'
              ? investment_item.type + ': ' + investment_item.details
              : investment_item.type}
          </div>
          <div className="value">{investment_item.years}</div>
        </li>
      )
    }

    return investment_item
  })

  return <Fragment>{investment_experience_items}</Fragment>
}

export function generateInvestmentProfileItems(investmentProfileItems) {
  if (!investmentProfileItems || investmentProfileItems.length < 1) {
    return 'The client has no investment profiles specified.'
  }

  var investment_profile_items = []

  investmentProfileItems.map((investment_profile, idx) => {
    if (investment_profile.account && investment_profile.profile) {
      investment_profile_items.push(
        <li key={idx}>
          <div className="label">{investment_profile.account}</div>
          <div className="value">{investment_profile.profile}</div>
        </li>
      )
    }

    return investment_profile
  })

  return <Fragment>{investment_profile_items}</Fragment>
}

function validateClientField(field, client, firmName, firmObject = null) {
  var errors = []

  // Get firm fee schedule min/max
  const feeScheduleMinimum =
    firmObject && firmObject.fee_schedule_minimum
      ? parseFloat(firmObject.fee_schedule_minimum.replace(/%/g, ''))
      : null
  const feeScheduleMaximum =
    firmObject && firmObject.fee_schedule_maximum
      ? parseFloat(firmObject.fee_schedule_maximum.replace(/%/g, ''))
      : null

  switch (field) {
    case 'legal_name':
    case 'legal_name_first':
    case 'legal_name_last':
      // 1st Investor Legal Name
      if (client[field].length < 1) {
        errors.push("Please enter 1st investor's full legal name.")
      }
      break
    case 'second_investor_legal_name':
    case 'second_investor_legal_name_first':
    case 'second_investor_legal_name_last':
      // 2nd Investor Legal Name
      if (
        client.second_investor_preferred_name.length > 0 ||
        client.second_investor_legal_name_first.length > 1 ||
        client.second_investor_legal_name_last.length > 1
      ) {
        if (client[field].length < 1) {
          errors.push("Please enter 2nd investor's full legal name.")
        }
      }
      break
    case 'address_street':
      // Home Address
      if (client[field].length < 1) {
        errors.push('Please enter home address.')
      }
      break
    case 'address_city':
      // City
      if (client[field].length < 1) {
        errors.push('Please enter a city.')
      }
      break
    case 'address_state':
      // State
      if (client[field].length < 1) {
        errors.push('Please enter a state.')
      }
      break
    case 'address_zip':
      // Zip
      if (!validZipRegex.test(client[field])) {
        errors.push('Please enter a valid zip code.')
      }
      break
    case 'address_mailing_street':
      // Mailing Street
      if (
        client.has_same_mailing_address === 'No' &&
        client[field].length < 1
      ) {
        errors.push('Please enter mailing address.')
      }
      break
    case 'address_mailing_city':
      // Mailing City
      if (
        client.has_same_mailing_address === 'No' &&
        client[field].length < 1
      ) {
        errors.push('Please enter mailing address city.')
      }
      break
    case 'address_mailing_state':
      // Mailing State
      if (
        client.has_same_mailing_address === 'No' &&
        client[field].length < 1
      ) {
        errors.push('Please enter mailing address state.')
      }
      break
    case 'address_mailing_zip':
      // Mailing Zip
      if (
        client.has_same_mailing_address === 'No' &&
        !validZipRegex.test(client[field])
      ) {
        errors.push('Please enter mailing address zip.')
      }
      break
    case 'second_investor_address_street':
      // Home Address
      if (
        client.second_investor_legal_name.length > 0 &&
        client.second_investor_has_same_address === 'No' &&
        client[field].length < 1
      ) {
        errors.push("Please enter 2nd investor's legal home address.")
      }
      break
    case 'second_investor_address_city':
      // City
      if (
        client.second_investor_legal_name.length > 0 &&
        client.second_investor_has_same_address === 'No' &&
        client[field].length < 1
      ) {
        errors.push("Please enter a city for 2nd investor's legal address.")
      }
      break
    case 'second_investor_address_state':
      // State
      if (
        client.second_investor_legal_name.length > 0 &&
        client.second_investor_has_same_address === 'No' &&
        client[field].length < 1
      ) {
        errors.push("Please enter a state for 2nd investor's legal address.")
      }
      break
    case 'second_investor_address_zip':
      // Zip
      if (
        client.second_investor_legal_name.length > 0 &&
        client.second_investor_has_same_address === 'No' &&
        !validZipRegex.test(client[field])
      ) {
        errors.push(
          "Please enter a valid zip code for 2nd investor's legal address."
        )
      }
      break
    case 'second_investor_address_mailing_street':
      // Mailing Street
      if (
        client.second_investor_legal_name.length > 0 &&
        client.second_investor_has_same_mailing_address === 'No' &&
        client[field].length < 1
      ) {
        errors.push("Please enter 2nd investor's mailing address.")
      }
      break
    case 'second_investor_address_mailing_city':
      // Mailing City
      if (
        client.second_investor_legal_name.length > 0 &&
        client.second_investor_has_same_mailing_address === 'No' &&
        client[field].length < 1
      ) {
        errors.push('Please enter mailing address city for 2nd investor.')
      }
      break
    case 'second_investor_address_mailing_state':
      // Mailing State
      if (
        client.second_investor_legal_name.length > 0 &&
        client.second_investor_has_same_mailing_address === 'No' &&
        client[field].length < 1
      ) {
        errors.push('Please enter mailing address state for 2nd investor.')
      }
      break
    case 'second_investor_address_mailing_zip':
      // Mailing Zip
      if (
        client.second_investor_legal_name.length > 0 &&
        client.second_investor_has_same_mailing_address === 'No' &&
        !validZipRegex.test(client[field])
      ) {
        errors.push('Please enter mailing address zip for 2nd investor.')
      }
      break
    case 'second_investor_relation':
      // Second Investor Relation
      if (
        client.second_investor_legal_name.length > 0 &&
        client[field].length < 1
      ) {
        errors.push('Please enter the relation to 2nd investor.')
      }
      break
    case 'second_investor_email':
      // Second Investor Email
      if (client.second_investor_legal_name.length > 0) {
        if (client[field].length < 1) {
          errors.push("Please enter 2nd investor's email.")
        } else if (!validEmailRegex.test(client[field])) {
          console.log(client[field])
          errors.push('Please enter a valid email address for 2nd investor.')
        }
      }
      break
    case 'second_investor_marital_status':
      if (
        client.second_investor_legal_name.length > 0 &&
        client[field].length < 1
      ) {
        errors.push("Please select 2nd investor's marital status.")
      }
      break
    case 'birthdate':
      // Birthdate
      var clientBirthdate = Date.parse(client[field])

      if (client[field].length < 1) {
        errors.push('Please enter a birthdate.')
      } else if (clientBirthdate > Date.now()) {
        errors.push('Please enter a birthdate in the past.')
      }
      break
    case 'phone':
      if (client[field].length < 1) {
        errors.push('Please enter a phone number.')
      }
      break
    case 'marital_status':
      if (client[field].length < 1) {
        errors.push('Please select a marital status.')
      }
      break
    case 'second_investor_birthdate':
      // 2nd Investor Birthdate
      if (client.second_investor_legal_name.length > 0) {
        var secondInvestorBirthdate = Date.parse(client[field])

        if (client[field].length < 1) {
          errors.push('Please enter a birthdate for 2nd investor.')
        } else if (secondInvestorBirthdate > Date.now()) {
          errors.push('Please enter a birthdate for 2nd investor in the past.')
        }
      }
      break
    case 'second_investor_phone':
      if (
        client.second_investor_legal_name.length > 0 &&
        client[field].length < 1
      ) {
        errors.push("Please enter 2nd investor's phone number.")
      }
      break
    case 'income_range':
      // Income Range
      if (client[field].length < 1) {
        errors.push('Please enter an income range.')
      }
      break
    case 'net_worth':
      // Net Worth
      if (client[field].length < 1) {
        errors.push('Please enter a net worth.')
      }
      break
    case 'net_worth_liquid':
      // Liquid Net Worth
      if (client[field].length < 1) {
        errors.push('Please enter a liquid net worth.')
      }
      break
    case 'investable_assets':
      // Investable Assets
      if (client[field].length < 1) {
        errors.push('Please enter investable assets.')
      }
      break
    case 'portfolio_value':
    case 'portfolio_value_qualified':
    case 'portfolio_value_non_qualified':
      // Portfolio Value
      if (client.portfolio_value.length < 1) {
        errors.push('Please enter value of investments with ' + firmName + '.')
      }

      // Portfolio Value - Qualified
      if (client.portfolio_value_qualified.length < 1) {
        errors.push(
          'Please enter value of qualified investments with ' + firmName + '.'
        )
      }

      // Portfolio Value - Non-Qualified
      if (client.portfolio_value_non_qualified.length < 1) {
        errors.push(
          'Please enter value of non-qualified investments with ' +
            firmName +
            '.'
        )
      }

      // Portfolio Value - Qualified & Non-Qualified are not outside of range
      if (
        client.portfolio_value.length >= 1 &&
        client.portfolio_value_qualified.length >= 1 &&
        client.portfolio_value_non_qualified.length >= 1
      ) {
        var portfolioValueMin = null
        let portfolioValueMinRaw = null
        var portfolioValueMax = null
        let portfolioValueMaxRaw = null

        if (client.portfolio_value.includes(' - ')) {
          portfolioValueMinRaw = client.portfolio_value.split(' - ')[0]
          if (portfolioValueMinRaw.includes('$')) {
            portfolioValueMinRaw = portfolioValueMinRaw.split('$')[1]
          }
          portfolioValueMaxRaw = client.portfolio_value.split(' - ')[1]
          if (portfolioValueMaxRaw.includes('$')) {
            portfolioValueMaxRaw = portfolioValueMaxRaw.split('$')[1]
          }

          portfolioValueMin = parseFloat(portfolioValueMinRaw.replace(/,/g, ''))
          portfolioValueMax = parseFloat(portfolioValueMaxRaw.replace(/,/g, ''))
        } else {
          portfolioValueMinRaw = client.portfolio_value.split('+')[0]
          if (portfolioValueMinRaw.includes('$')) {
            portfolioValueMinRaw = portfolioValueMinRaw.split('$')[1]
          }

          portfolioValueMin = parseFloat(portfolioValueMinRaw.replace(/,/g, ''))
          portfolioValueMax = 99999999999999999999999999999999999999999999
        }

        var qualifiedVal = parseFloat(
          client.portfolio_value_qualified.split('$')[1].replace(/,/g, '')
        )
        var nonQualifiedVal = parseFloat(
          client.portfolio_value_non_qualified.split('$')[1].replace(/,/g, '')
        )
        var qualifiedSum = qualifiedVal + nonQualifiedVal

        if (
          qualifiedSum < portfolioValueMin ||
          qualifiedSum > portfolioValueMax
        ) {
          errors.push(
            'Please make sure qualified and non-qualified portfolio values are in the portfolio value range.'
          )
        }
      }
      break
    case 'residence_value':
      // Residence Value
      if (client[field].length < 1) {
        errors.push('Please enter residence value.')
      }
      break
    case 'retirement_age':
      // Retirement Age
      if (client[field].length < 1) {
        errors.push('Please select a retirement age range.')
      }
      break
    case 'second_investor_retirement_age':
      // Second Investor Retirement Age
      if (
        client.second_investor_legal_name.length > 0 &&
        client[field].length < 1
      ) {
        errors.push('Please select a retirement age range for 2nd investor.')
      }
      break
    case 'retirement_needs':
      // Retirement Needs
      if (client[field].length < 1) {
        errors.push('Please enter estimated retirement needs.')
      }
      break
    case 'retirement_income':
      // Retirement Income
      if (client[field].length < 1) {
        errors.push('Please enter desired monthly retirement income.')
      }
      break
    case 'investment_experience_general':
      // General Investment Experience
      if (client[field].length < 1) {
        errors.push('Please select general investment experience.')
      }
      break
    case 'investment_experience_items':
      // Investment Experiences
      if (client[field].length > 0) {
        client[field].map(function (experienceItem) {
          if (
            experienceItem.type === 'Other' &&
            experienceItem.years.length > 0 &&
            experienceItem.details.length < 1
          ) {
            errors.push(
              'Please provide details for "Other" investment experience items.'
            )
          }
          return errors
        })
      }
      break
    case 'advisor':
      // Advisor
      if (client[field].length < 1) {
        errors.push('Please select an advisor.')
      }
      break
    case 'fee_type':
      // Advisor
      if (client[field].length < 1) {
        errors.push('Please select a fee type.')
      }
      break
    case 'fee_fixed':
      // Fixed Fee
      if (client.fee_type === 'Fixed Fee') {
        const fixedFee = client[field]

        if (!fixedFee) {
          errors.push('Please enter fixed fee.')
        } else if (
          parseFloat(fixedFee.replace(/%/g, '')) < feeScheduleMinimum ||
          parseFloat(fixedFee.replace(/%/g, '')) > feeScheduleMaximum
        ) {
          errors.push(
            `Please make sure fixed fee is within the firm's range of ${
              feeScheduleMinimum || '0'
            }% to ${feeScheduleMaximum + '%' || 'unlimited'}.`
          )
        }
      }
      break
    case 'annual_fees':
      // Client Annual Fees
      if (client.fee_type === 'Percentage Fee' && client[field]) {
        const annualFees = client[field]

        let feesBelowMinimum
        let feesAboveMaximum

        if (feeScheduleMinimum) {
          feesBelowMinimum = annualFees.filter(
            (line) =>
              parseFloat(line.fee.replace(/%/g, '')) < feeScheduleMinimum
          )
        }

        if (feeScheduleMaximum) {
          feesAboveMaximum = annualFees.filter(
            (line) =>
              parseFloat(line.fee.replace(/%/g, '')) > feeScheduleMaximum
          )
        }

        if (
          (feesBelowMinimum && feesBelowMinimum.length) ||
          (feesAboveMaximum && feesAboveMaximum.length)
        ) {
          errors.push(
            `Please make sure all client fee schedule fees are within the firm's range of ${
              feeScheduleMinimum || '0'
            }% to ${feeScheduleMaximum + '%' || 'unlimited'}.`
          )
        }
      }
      break
    case 'fee_401k':
      // 401(k) Fee
      if (!client[field].length) {
        errors.push('Please enter a 401(k) fee.')
      }
      break
    case 'jurisdiction':
      // Jurisdiction / Account Management
      if (client[field].length < 1) {
        errors.push('Please select who is managing the account.')
      }
      break
    case 'custodian':
      // Custodian
      if (
        client.jurisdiction === 'Non-Discretionary' &&
        client[field].id.length < 1
      ) {
        errors.push('Please select a custodian.')
      }
      break
    case 'investment_profiles':
      // Investment Profiles
      if (client[field].length < 1) {
        errors.push('Please select an investment profile.')
      }

      client[field].map(function (investmentProfile) {
        if (
          investmentProfile.account.length < 1 ||
          investmentProfile.profile.length < 1
        ) {
          errors.push(
            'Please make sure both an account type and a profile are selected for each investment profile.'
          )
        }
        return errors
      })
      break
    case 'occupation':
      if (client[field].length < 1) {
        errors.push('Please enter an occupation.')
      }
      break
    case 'second_investor_occupation':
      if (
        client.second_investor_legal_name.length > 0 &&
        client[field].length < 1
      ) {
        errors.push("Please enter 2nd investor's occupation.")
      }
      break
    case 'tax_bracket':
      if (client[field].length < 1) {
        errors.push('Please enter an tax bracket.')
      }
      break
    case 'expenses':
      if (client[field].length < 1) {
        errors.push('Please select an expenses range.')
      }
      break
    case 'expenses_special':
      if (client[field].length < 1) {
        errors.push('Please select a special expenses range.')
      }
      break
    case 'expenses_special_timeframe':
      if (client['expenses_special'].length > 0 && client[field].length < 1) {
        errors.push('Please select a special expenses timeframe.')
      }
      break
    case 'investment_objectives':
      if (client[field].length < 1) {
        errors.push('Please select an investment objective.')
      }
      break
    case 'investment_objectives_other':
      if (
        client.investment_objectives === 'Other' &&
        client[field].length < 1
      ) {
        errors.push('Please enter investment objective details.')
      }
      break
    case 'investment_objectives_time_horizon':
      if (
        client['investment_objectives_time_horizon'].length > 0 &&
        client[field].length < 1
      ) {
        errors.push('Please enter investment objectives time horizon.')
      }
      break
    case 'risk_score':
      if (client[field].length < 1) {
        errors.push('Please enter a risk score.')
      }
      break
    default:
  }

  return errors
}

export function validateClientFields(
  client,
  firmName = 'this firm',
  fieldsToValidate = null,
  fieldsToExclude = null,
  firmObject = null
) {
  var errors = []

  if (!fieldsToValidate) {
    fieldsToValidate = [
      'legal_name',
      'legal_name_first',
      'legal_name_last',
      'second_investor_legal_name',
      'second_investor_legal_name_first',
      'second_investor_legal_name_last',
      'address_street',
      'address_city',
      'address_state',
      'address_zip',
      'address_mailing_street',
      'address_mailing_city',
      'address_mailing_state',
      'address_mailing_zip',
      'second_investor_address_street',
      'second_investor_address_city',
      'second_investor_address_state',
      'second_investor_address_zip',
      'second_investor_address_mailing_street',
      'second_investor_address_mailing_city',
      'second_investor_address_mailing_state',
      'second_investor_address_mailing_zip',
      'second_investor_relation',
      'second_investor_birthdate',
      'second_investor_email',
      'second_investor_phone',
      'second_investor_marital_status',
      'birthdate',
      'phone',
      'marital_status',
      'income_range',
      'net_worth',
      'net_worth_liquid',
      'tax_bracket',
      'investable_assets',
      'portfolio_value',
      'portfolio_value_qualified',
      'portfolio_value_non_qualified',
      'residence_value',
      'retirement_age',
      'second_investor_retirement_age',
      'investment_objectives',
      'retirement_needs',
      'retirement_income',
      'investment_experience_general',
      'investment_experience_items',
      'advisor',
      'fee_type',
      'fee_fixed',
      'annual_fees',
      'jurisdiction',
      'custodian',
      'investment_profiles',
    ]
  }

  fieldsToValidate.map((field) => {
    if (
      !fieldsToExclude ||
      (fieldsToExclude &&
        fieldsToExclude.length &&
        !fieldsToExclude.includes(field))
    ) {
      var validatedFieldResult = validateClientField(
        field,
        client,
        firmName,
        firmObject
      )

      if (validatedFieldResult) {
        errors = errors.concat(validatedFieldResult)
      }
    }

    return field
  })

  // Filter out duplicates
  errors = Array.from(new Set(errors))

  return errors
}

export const legacyValidateFields = (
  templateID,
  firmID,
  firm,
  client,
  reviewType = null
) => {
  let errors = []

  let fieldsToInclude
  let fieldsToExclude = null

  switch (templateID) {
    case 'iac':
      // Set validation to exclude certain fields for certain firms
      switch (firmID) {
        case firmIDs['centennial']:
          // Centennial
          fieldsToExclude = ['retirement_needs', 'retirement_income']
          break
        case firmIDs['jalinski']:
          // Jalinski
          fieldsToExclude = [
            'fee_type',
            'fee_fixed',
            'retirement_needs',
            'retirement_income',
            'current_investment_assets_managed',
            'investment_profiles',
            'portfolio_value',
            'portfolio_value_qualified',
            'portfolio_value_non_qualified',
            'tax_bracket',
          ]
          break
        default:
          break
      }

      errors = errors.concat(
        validateClientFields(client, firm.name, null, fieldsToExclude, firm)
      )

      // Jalinski customizations
      if (firmID === firmIDs['jalinski']) {
        if (
          client.tactical_strategy &&
          client.tactical_strategy !== 'None' &&
          client.risk_tolerance &&
          client.risk_tolerance === 'Conservative'
        ) {
          errors.push(
            'You cannot select a Conservative Risk Tolerance with a Tactical Strategy.'
          )
        }
      }
      break
    case 'k401':
      fieldsToInclude = [
        'legal_name',
        'legal_name_first',
        'legal_name_last',
        'address_street',
        'address_city',
        'address_state',
        'address_zip',
        'advisor',
        'fee_401k',
      ]

      errors = errors.concat(
        validateClientFields(
          client,
          firm.name,
          fieldsToInclude,
          fieldsToExclude
        )
      )
      break
    case 'suitability':
      let firmName = 'this firm'
      if (firm && firm.name) {
        firmName = firm.name
      }

      fieldsToInclude = [
        'legal_name_first',
        'legal_name_last',
        'legal_name',
        ...(firmID !== firmIDs['jalinski'] ? ['birthdate'] : []),
        ...(firmID !== firmIDs['jalinski'] ? ['risk_score'] : []),
        'advisor',
        'second_investor_legal_name',
        'second_investor_legal_name_first',
        'second_investor_legal_name_last',
        'second_investor_birthdate',
      ]
      if (reviewType === 'client-sign') {
        fieldsToInclude = fieldsToInclude.concat([
          ...(firmID === firmIDs['jalinski'] ? ['birthdate'] : []),
          'occupation',
          'second_investor_occupation',
          'income_range',
          'net_worth',
          'net_worth_liquid',
          'tax_bracket',
          'expenses',
          'expenses_special',
          'expenses_special_timeframe',
          'investment_experience_items',
          ...(firmID !== firmIDs['jalinski'] ? ['investment_objectives'] : []),
          'investment_objectives_time_horizon',
          'investment_objectives_other',
        ])
      }

      errors = errors.concat(
        validateClientFields(client, firmName, fieldsToInclude)
      )

      // Jalinski customizations
      if (firmID === firmIDs['jalinski']) {
        if (
          client.tactical_strategy &&
          client.tactical_strategy !== 'None' &&
          client.risk_tolerance &&
          client.risk_tolerance === 'Conservative'
        ) {
          errors.push(
            'You cannot select a Conservative Risk Tolerance with a Tactical Strategy.'
          )
        }

        // Trusted contacts
        if (reviewType === 'client-sign') {
          if (
            !client.authorized_contacts ||
            !client.authorized_contacts.length
          ) {
            errors.push('Please enter at least one trusted contact.')
          }
          if (client.authorized_contacts && client.authorized_contacts.length) {
            let hasAuthorizedContactErrors = false
            if (!hasAuthorizedContactErrors) {
              client.authorized_contacts.forEach((authorizedContact) => {
                if (
                  !authorizedContact.name ||
                  !authorizedContact.address ||
                  !authorizedContact.phone ||
                  !authorizedContact.email
                ) {
                  hasAuthorizedContactErrors = true
                }
              })
            }
            if (hasAuthorizedContactErrors) {
              errors.push(
                'Please enter a name, address, phone, and email for all trusted contacts.'
              )
            }
          }
        }
      }
      break
    default:
      break
  }

  return errors
}
