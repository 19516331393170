import React, { Component } from 'react'
import FirmLogo from '../../../partials/FirmLogo'
import Table from '../../../partials/LegacyTable'
import InitialsField from '../../../forms/InitialsField'
import SignatureRow from '../../partials/SignatureRow'
import NextLink from '../../../partials/NextLink'
// import '../../../../styles/iac.scss'
import DocumentSubmit from '../../partials/DocumentSubmit'
import { getInitials } from '../../../../actions/helpers'
import {
  calculateTotalAdvisoryFee,
  currentDate,
} from '../../../../actions/iacHelpers'
import './HobartIAC.scss'

class HobartIAC extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // advisor: this.props.advisor,
      updateDate: '1/24/2020',
      clientSig1: false,
      secondSig1: false,
      clientSig2: false,
      secondSig2: false,
    }
  }

  clientSig1 = {}
  secondSig1 = {}
  clientSig2 = {}
  secondSig2 = {}

  render() {
    const {
      type,
      firm,
      advisor,
      client,
      client_name,
      doc,
      errors,
      handleIACSubmit,
      handleInputChange,
      nextLinkRef,
      handleKeyPress,
      handleSignatureConfirm,
      handleSignatureRetry,
    } = this.props
    const { updateDate } = this.state

    this.currentDate = <div className="value">{currentDate()}</div>

    this.next_link = ''
    if (type === 'send') {
      this.next_link = 'iac-submit'
    } else if (
      client.jurisdiction === 'Discretionary' &&
      doc.initials.discretionary_client.length < 1
    ) {
      this.next_link = 'ini_discretionary_client'
    } else if (
      client.second_investor_legal_name.length &&
      client.jurisdiction === 'Discretionary' &&
      doc.initials.discretionary_second.length < 1
    ) {
      this.next_link = 'ini_discretionary_second'
    } else if (
      client.jurisdiction === 'Non-Discretionary' &&
      doc.initials.nondiscretionary_client.length < 1
    ) {
      this.next_link = 'ini_nondiscretionary_client'
    } else if (
      client.second_investor_legal_name.length &&
      client.jurisdiction === 'Non-Discretionary' &&
      doc.initials.nondiscretionary_second.length < 1
    ) {
      this.next_link = 'ini_nondiscretionary_second'
    } else if (doc.initials.advisory_fee_client.length < 1) {
      this.next_link = 'ini_advisory_fee_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.advisory_fee_second.length < 1
    ) {
      this.next_link = 'ini_advisory_fee_second'
    } else if (!doc.signatures.sig_1_client) {
      this.next_link = 'sig_1_client'
    } else if (
      client.second_investor_legal_name.length &&
      !doc.signatures.sig_1_second
    ) {
      this.next_link = 'sig_1_second'
    } else if (doc.initials.schedule_a_client.length < 1) {
      this.next_link = 'ini_schedule_a_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.schedule_a_second.length < 1
    ) {
      this.next_link = 'ini_schedule_a_second'
    } else if (doc.initials.no_management_services_client.length < 1) {
      this.next_link = 'ini_no_management_services_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.no_management_services_second.length < 1
    ) {
      this.next_link = 'ini_no_management_services_second'
    } else if (doc.initials.schedule_b_client.length < 1) {
      this.next_link = 'ini_schedule_b_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.schedule_b_second.length < 1
    ) {
      this.next_link = 'ini_schedule_b_second'
    } else if (doc.initials.schedule_c_client.length < 1) {
      this.next_link = 'ini_schedule_c_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.schedule_c_second.length < 1
    ) {
      this.next_link = 'ini_schedule_c_second'
    } else if (doc.initials.schedule_d_client.length < 1) {
      this.next_link = 'ini_schedule_d_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.schedule_d_second.length < 1
    ) {
      this.next_link = 'ini_schedule_d_second'
    } else if (!doc.signatures.sig_2_client) {
      this.next_link = 'sig_2_client'
    } else if (
      client.second_investor_legal_name.length &&
      !doc.signatures.sig_2_second
    ) {
      this.next_link = 'sig_2_second'
    } else if (doc.initials.fee_billing_client.length < 1) {
      this.next_link = 'ini_fee_billing_client'
    } else if (
      client.second_investor_legal_name.length &&
      doc.initials.fee_billing_second.length < 1
    ) {
      this.next_link = 'ini_fee_billing_second'
    } else if (!doc.signatures.sig_3_client) {
      this.next_link = 'sig_3_client'
    } else if (
      client.second_investor_legal_name.length &&
      !doc.signatures.sig_3_second
    ) {
      this.next_link = 'sig_3_second'
    } else {
      this.next_link = 'iac-submit'
    }

    if (firm && advisor && client) {
      const advisorInitials = getInitials(advisor.name)

      return (
        <div className="document-container iac-container">
          <NextLink next_link={this.next_link} nextLinkRef={nextLinkRef} />

          <div id="iac" className="document iac hobart-iac">
            <div className="page cover">
              {firm.logo ? <FirmLogo firm={firm} /> : ''}

              <h1>{firm.legal_name}</h1>
              <h2>Investment Advisory Contract</h2>

              <div className="client-info">
                <h3>Contract for:</h3>
                <div className="name">{client_name}</div>
                <div className="address">
                  <span>{client.address_street}</span>
                  <span>
                    {client.address_city}, {client.address_state}{' '}
                    {client.address_zip}
                  </span>
                </div>
                <div className="update-date">Updated {updateDate}</div>
              </div>
            </div>

            <div className="page-break"></div>
            <div className="contract">
              <h3>Investment Advisory Agreement</h3>

              <p>
                This Investment Advisory Agreement, plus any Schedules attached
                hereto (collectively, the “Agreement”) is made by and between{' '}
                <strong>
                  Hobart Private Capital, LLC (hereinafter, "Advisor")
                </strong>{' '}
                and: <strong>{client_name} (hereinafter, "Client")</strong>.
              </p>

              <p>
                WHEREAS, Client desires to appoint Advisor as its investment
                adviser with authority and responsibility to provide the
                services identified below;
              </p>

              <p>
                WHEREAS, Advisor desires to act as investment adviser on the
                terms and conditions hereinafter set forth;
              </p>

              <p>
                NOW, therefore, the parties hereto, for good and valuable
                consideration, the receipt and sufficiency of which is hereby
                acknowledged, agree as follows:
              </p>

              <ol>
                <li>
                  <p>
                    <strong>Scope of Services.</strong> Client engages Advisor
                    to perform the services indicated by Client’s initials below
                    in accordance with the financial circumstances, investment
                    objectives, and risk tolerance of Client.
                  </p>
                  <ul className="management-options">
                    <li>
                      <p>
                        <span
                          className={
                            client.jurisdiction === 'Discretionary'
                              ? 'box checked'
                              : 'box'
                          }
                        />
                        <strong>Discretionary Investment Management.</strong>{' '}
                        Client hereby grants Advisor discretionary authority in
                        connection with an account or accounts to be established
                        in the name of Client, either jointly or individually,
                        whether or not on a tax-qualified basis (hereinafter
                        “Account(s)”) and in such Account(s) to purchase or sell
                        securities or other investment products, to sell,
                        manage, and reinvest the amount or type of assets or
                        investments, whether employing margin or otherwise, to
                        instruct the broker-dealer, trustee, or Custodian of
                        assets to receive, accept and deliver securities or
                        other assets, and to implement any investment decisions
                        for the Account(s), all without prior consultation with
                        Client. This discretionary authority includes the
                        authority to hire or retain other investment advisers to
                        exercise any authority granted to Advisor under this
                        Agreement, to remove, terminate or replace any such
                        adviser, and to allocate assets belonging to Client and
                        subject to this Agreement between or among any other
                        such advisers. This discretionary authority is subject
                        to such other written limitations that the Client may
                        impose. Client may revoke discretionary authority at any
                        time by submitting a written notice to Advisor. Except
                        as otherwise provided herein, Advisor shall have no
                        authority under this Agreement to take or have
                        possession of any assets in the Account(s) nor to direct
                        delivery of any securities or payment of funds to itself
                        or direct the disposition of such securities or funds to
                        any person other than Client.
                      </p>
                      <InitialsField
                        signer="First Investor"
                        required={client.jurisdiction === 'Discretionary'}
                        id="ini_discretionary_client"
                        value={doc.initials.discretionary_client}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                      <InitialsField
                        signer="Second Investor"
                        required={
                          client.second_investor_legal_name.length &&
                          client.jurisdiction === 'Discretionary'
                        }
                        id="ini_discretionary_second"
                        value={doc.initials.discretionary_second}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                    </li>
                    <li>
                      <p>
                        <span
                          className={
                            client.jurisdiction === 'Non-Discretionary'
                              ? 'box checked'
                              : 'box'
                          }
                        />
                        <strong>
                          Non-Discretionary Investment Management.
                        </strong>{' '}
                        In connection with an account or accounts to be
                        established in the name of Client, either jointly or
                        individually, whether or not on a tax-qualified basis
                        (hereinafter “Account(s)”), Advisor will from time to
                        time make investment recommendations with respect to the
                        Account(s) but will not exercise discretionary authority
                        with respect to the Account(s) or transactions. Client
                        will provide oral or written approval of any transaction
                        prior to implementation and Advisor will comply with all
                        Client instructions.
                      </p>
                      <InitialsField
                        signer="First Investor"
                        required={client.jurisdiction === 'Non-Discretionary'}
                        id="ini_nondiscretionary_client"
                        value={doc.initials.nondiscretionary_client}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                      <InitialsField
                        signer="Second Investor"
                        required={
                          client.second_investor_legal_name.length &&
                          client.jurisdiction === 'Non-Discretionary'
                        }
                        id="ini_nondiscretionary_second"
                        value={doc.initials.nondiscretionary_second}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                    </li>
                  </ul>
                  <p>
                    Advisor shall provide the investment management services
                    designated by Client above on a continuous and regular
                    basis.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Investment Objective; Client Responsibilities.
                    </strong>{' '}
                    The Advisor will obtain from the Client information to
                    determine the Client’s financial situation and investment
                    objective. At or around the time of execution of this
                    Agreement, Client will be requested to complete a
                    suitability profile. Unless otherwise directed in writing,
                    Advisor will recommend investment strategies and investments
                    consistent with Client’s financial circumstances and other
                    information derived from analysis of the suitability
                    profile. Advisor will abide by any reasonable limitations,
                    special instructions, or requests of Client. Initially, such
                    limitations are set forth on Schedule A, but Client may
                    change any such limitations or instructions from time to
                    time by notifying Advisor in writing. Client is responsible
                    for informing Advisor of updates or changes to financial
                    status or circumstances so that Advisor can make any
                    necessary changes to the management of or advice provided
                    with respect to the Account(s).
                  </p>
                  <p>
                    Client recognizes that the value and usefulness of the
                    investment advisory services of the Advisor will depend upon
                    the accuracy and completeness of the information that Client
                    provides and upon the active participation by the Client in
                    the relationship with the Advisor. The Client therefore
                    agrees to provide complete and accurate information and
                    documents that Advisor requires, in Advisor’s sole judgment,
                    in order to provide the services hereunder.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Custody.</strong> Advisor will not maintain physical
                    custody of Client’s funds and securities. Client’s funds and
                    securities shall be held for safekeeping by a custodian
                    meeting the requirements of qualified custodian which in
                    most cases will be a registered securities broker-dealer or
                    a trust company (hereinafter, “Custodian”). All checks for
                    investment purposes shall be made payable to the Custodian.
                    Advisor shall not be liable to Client for any act, conduct,
                    or omission by Custodian.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Custodian Recommendations.</strong> The Advisor may
                    recommend a Custodian for the Account(s). In recommending
                    Custodians, Advisor will generally take into account such
                    relevant factors as (a) prices charged for transactions and
                    similar fees; (b) the Custodian’s facilities, reliability
                    and financial responsibility; (c) the ability of the
                    Custodian to effect transactions, particularly with regard
                    to such aspects as timing, order size and execution of
                    order; (d) the research and related services provided by
                    such Custodians to the Advisor, notwithstanding that the
                    Account(s) may not be the direct or exclusive beneficiary of
                    such services; and (e) any other factors the Advisor
                    considers to be relevant. Advisor and Client shall use their
                    best efforts to agree upon a Custodian. Advisor will assist
                    Client with establishing an account with the Custodian(s)
                    selected.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Expenses and Other Fees.</strong> Brokerage
                    commissions, transaction charges, handling fees, custodial
                    fees, service charges, ticket charges and other similar
                    charges incurred in connection with transactions for the
                    Account(s) are paid to the Custodian by Advisor or
                    Third-Party Adviser.
                  </p>
                  <p>
                    Client agrees to be responsible for all additional fees and
                    charges for which Client becomes obligated under any
                    separate agreement with the Custodian, including any
                    transaction fees and charges incurred when placing
                    unsolicited orders directly on the Custodian’s website. Said
                    expenses will be paid out of the assets in the Account(s) or
                    billed separately to the Client at Advisor’s sole
                    discretion.
                  </p>
                  <p>
                    Mutual funds and similar investment vehicles pay managers to
                    manage the assets of the fund, and the expenses of the fund,
                    including said management fees, are deducted from all of the
                    fund assets, are chargeable against the net asset value of
                    fund shares owned by the Client, and are therefore borne
                    separately by the Client. Other fees and expenses that the
                    Client may pay outside of this Agreement include retirement
                    plan fees, mutual fund sales loads, 12(b)-1 marketing fees
                    charged by mutual funds, contingent deferred sales charges,
                    mark‐ups and mark‐downs, spreads paid to market makers, wire
                    transfer fees, ADR fees, overnight check fees, account
                    close-out fees, activity assessment fees, taxes on brokerage
                    accounts and securities transactions, annuity fees including
                    mortality and expense charges, and surrender charges. A
                    description of the types of fees and expenses actually
                    charged by a particular investment are described in the
                    prospectus or contract, as applicable, of the investment.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Confirmation of Trades.</strong> The executing
                    broker-dealer or Custodian shall forward and deliver to the
                    Client a confirmation of transaction in the Account(s).
                    Except to the extent the Client directs otherwise, through
                    custodial agreements or as required by law, Advisor will not
                    be responsible for forwarding confirmations of any
                    transactions effected for the Client.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Third Party Investment Advisers.</strong> Advisor
                    may recommend that all or a portion of the assets in the
                    Account(s) be managed by a third-party investment adviser,
                    sub-adviser or co-adviser (“Third-Party Adviser”). Fees
                    charged by any such Third-Party Adviser and/or the provider
                    of any platform through which the Third-Party Adviser is
                    made available will be fully disclosed to Client on a
                    separate disclosure document or in a separate agreement
                    between the Client and the Third- Party Adviser.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Advisory Fees.</strong> For its services hereunder,
                    Advisor shall receive an annual investment management fee
                    (“Advisory Fee”) as compensation for providing its
                    investment advisory services to Client. The Advisory Fee is
                    negotiable, and not all clients of Advisor will be charged
                    the same Advisory Fee or be charged according to the same
                    fee schedule.
                  </p>
                  <p>
                    Unless otherwise provided in this Section, the Advisory Fee
                    will be paid quarterly in advance on or near the first day
                    of each calendar quarter, and will be calculated by
                    multiplying one-fourth of the annual Advisory Fee by the
                    market value of the assets under Advisor’s management as of
                    the last business day of the preceding quarter. Fees for
                    services during the initial quarter in which the Account is
                    opened shall be a prorated fee calculated according to the
                    days remaining in the quarter when the Account is opened.
                    Fees on amounts deposited during a quarter are assessed at
                    the beginning of the month following the month in which the
                    deposit was made, and are calculated by dividing the number
                    of days remaining in the quarter by the total days in the
                    quarter and multiplying the quotient by the quarterly fee
                    derived by the process described above. Credits for
                    withdrawals made during a quarter will be issued in the same
                    manner. Without limiting the Advisor’s ability to amend any
                    part of this Agreement as provided in the Section below
                    titled “Amendment,” a separate agreement signed by both
                    Advisor and Client may amend or modify the Advisory Fee and
                    terms and conditions relating to its calculation and method
                    of payment.
                  </p>
                  <p>
                    The total annual Advisory Fee due to Advisor shall consist
                    of a base amount for services provided by Advisor (the “HPC
                    Advisory Fee”) plus, where applicable, an amount charged to
                    Advisor by any Third-Party Adviser(s) or platform by which
                    the Third-Party Advisers are made available (the
                    “Third-Party Advisory Fee”). The Third-Party Advisory Fee
                    shall be paid to any such Third-Party Adviser by the
                    Advisor. Third-Party Advisory Fees shall not exceed 1.00%
                    per annum and shall apply only to those assets of Client
                    allocated to a Third-Party Adviser.
                  </p>
                  <p>
                    Client hereby agrees to pay to the Advisor an annual HPC
                    Advisory Fee of {calculateTotalAdvisoryFee(client, firm)}
                    <br />
                    <InitialsField
                      signer="First Investor"
                      required={true}
                      id="ini_advisory_fee_client"
                      value={doc.initials.advisory_fee_client}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    <InitialsField
                      signer="Second Investor"
                      required={client.second_investor_legal_name.length}
                      id="ini_advisory_fee_second"
                      value={doc.initials.advisory_fee_second}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                  </p>
                  <p>
                    Advisory Fees for Accounts managed by AE Wealth Management,
                    LLC will be paid monthly in arrears. Advisory Fees are based
                    on each Account's average daily balance during the prior
                    calendar month. Fees are prorated for Accounts opened during
                    the month. Fees shall become payable on the last day of the
                    calendar month, or, in the event of termination of services,
                    fees shall become payable on the day following termination.
                    If the management of Account(s) commences at any other time
                    than the beginning of the month, the first monthly fee shall
                    be prorated based on the portion of such month remaining.
                  </p>
                  <p>
                    Advisor’s compensation is not based upon a share of capital
                    gain or capital appreciation of any portion of Client’s
                    assets or funds.
                  </p>
                  <p>
                    The assets shown on{' '}
                    <span className="underline">Schedule B</span>, if initialed
                    by the Client and Advisor, shall be excluded from
                    calculation of the Advisory Fee. Client and Advisor agree
                    that Advisor shall have no responsibility over the
                    management of said assets.
                  </p>
                  <p>
                    The assets shown on{' '}
                    <span className="underline">Schedule C</span>, if initialed
                    by the Client and Advisor, shall be subject to the HPC
                    Advisory Fee specified on{' '}
                    <span className="underline">Schedule C</span> and not the
                    HPC Advisory Fee specified above.
                  </p>
                  <p>
                    If for any reason Client is not satisfied with Advisor’s
                    services provided hereunder during the first year of
                    service, based on the date the Agreement is signed, Client
                    may, upon written notice by Client received by Advisor
                    within twelve months from the commencement date of this
                    Agreement, receive a refund of all HPC Advisory Fees paid
                    during the first year of service for services provided up to
                    the date of notice. No other fees shall be refunded pursuant
                    to this paragraph, including, without limitation, fees
                    charged by any third party such as internal mutual fund
                    fees, custodial fees, third- party manager fees, sub-adviser
                    fees, or fees charged by issuers of any insurance products
                    including annuities. Likewise, without limitation, any
                    commissions paid to Advisor’s representatives or affiliates
                    on securities or insurance products will not be refunded.
                    Any investment losses will not be refunded, and Client
                    acknowledges and understands that investment accounts are
                    not insured by the FDIC or any other program and may lose
                    value. Any HPC Advisory Fees paid after refunds are made
                    under this paragraph are non-refundable except as described
                    in the following paragraph.
                  </p>
                  <p>
                    Upon termination of this Agreement prepaid but unearned
                    Advisory Fees shall be refunded to the Clients within a
                    reasonable time not to exceed thirty (30) days.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Payment.</strong> Advisory Fees assessed pursuant to
                    this Agreement will be deducted directly from Client’s
                    Account(s) by Custodian and automatically remitted to
                    Advisor and/or a Third-Party Adviser. At Advisor’s
                    discretion, the Advisory Fees may be remitted in full to a
                    Third-Party Adviser, and such Third-Party Adviser will in
                    turn remit Advisor’s remaining Advisory Fees less any amount
                    due to such Third-Party Adviser. Client hereby consents and
                    agrees to pay Advisory Fees due hereunder by direct debit
                    from the Account(s). Client and Advisor agree that in order
                    to pay such fees, if cash is not available at time payment
                    is due, Advisor may liquidate Client assets as reasonably
                    determined by Advisor. Custodian will send Client an account
                    statement at least quarterly, showing total deductions from
                    Account(s), including Advisory Fees paid to Advisor. Client
                    is responsible for verifying fees.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Reports of Account.</strong> The Custodian will send
                    statements to the Client at least quarterly. Clients should
                    compare those statements to any reports received from the
                    Advisor, and should ask the Advisor for clarification if
                    there is any discrepancy.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>ERISA Representations.</strong> This Section applies
                    if any assets of the Client include a (i) pension or other
                    employee benefit plan (including any 401(k) plan) governed
                    by the Employee Retirement Income Security Act of 1974, as
                    amended ("ERISA"); (ii) tax-qualified retirement plan
                    (including a Keogh plan) under Section 401(a) of the
                    Internal Revenue Code, as amended (the "Code"), and not
                    covered by ERISA; or (iii) an individual retirement account
                    ("IRA") under Section 408 of the Code such as a Simple, SEP,
                    or Rollover IRA. Traditional IRAs are not governed by or
                    subject to ERISA. If certain Client assets are for a plan
                    subject to ERISA, the Client appoints the Advisor, and the
                    Advisor accepts its appointment, as an "investment manager"
                    for purposes of ERISA and the Code, and the Advisor
                    acknowledges that it is a "fiduciary" within the meaning of
                    Section 3(21) of ERISA and Section 4957(e) (3) of the Code
                    (but only with respect to the provision of services
                    described in Section 1 of this Agreement).
                  </p>
                  <p>
                    If requested by Advisor, the Client agrees to provide the
                    Advisor with true and complete copies of all documents
                    establishing and governing the plans and evidencing the
                    Client's authority to retain the Advisor. If the Account(s)
                    contains assets that represent only a portion of the plan's
                    assets, the Client understands that the Advisor will have no
                    responsibility for the diversification of all the plan's
                    assets, and that the Advisor will have no duty,
                    responsibility or liability for plan assets that are not
                    invested in the Account. The Client further represents that
                    a fidelity bond meeting the requirements Section 412 of
                    ERISA and the regulations issued thereunder is currently
                    maintained and that Advisor will be added as a fiduciary
                    covered by such fidelity bond. The Client agrees to provide
                    satisfactory evidence of such coverage if requested by
                    Advisor.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Proxies, Legal Proceedings, and Class Action Lawsuits.
                    </strong>{' '}
                    Client understands and agrees that Client retains the right
                    to vote all proxies that are solicited for securities held
                    in the Account(s). Advisor will not take any action or
                    render any advice with respect to the voting of proxies
                    solicited by or with respect to the issuers of securities in
                    which assets of the Account(s) may be invested from time to
                    time. In addition, Advisor will not take any action or
                    render any advice with respect to Client’s participation in,
                    or election regarding, any class action lawsuits,
                    bankruptcies or other legal proceedings relating to
                    securities held or formerly held in the Account(s). Advisor
                    will, however, forward to Client any information received by
                    Advisor regarding class action legal matters involving any
                    security held in the Account(s). Notwithstanding the
                    foregoing, Advisor shall have discretion to appoint
                    Third-Party Advisers who will accept the responsibility in
                    Client’s place and stead, to vote proxies or take action
                    regarding class actions, bankruptcies or other such
                    proceedings, and said Third-Party Advisers may rely upon the
                    authority of Advisor to make any such appointment for that
                    purpose unless or until such appointment is revoked by
                    Client or by Advisor. No such appointments shall be
                    effective until thirty (30) days after notice to the Client.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Communications.</strong> Client updates or changes
                    to financial circumstances with respect to the Account(s)
                    may be communicated to Advisor orally and, where deemed
                    necessary, may be confirmed in writing as soon as
                    practicable thereafter. Other notices required to be given
                    under this Agreement shall be made in accordance with the
                    Section of this Agreement below with the heading “Notices.”
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Confidentiality.</strong> Except as provided herein
                    or otherwise agreed to in writing or as required or
                    permitted by law or permitted by Advisor’s Privacy Policy, a
                    copy of which has been provided to Client, Advisor will keep
                    all information regarding Client’s personal and financial
                    affairs confidential. Client authorizes Advisor to give a
                    copy of this Agreement to any Custodian or other party to
                    transactions for the Account(s) as evidence of the Advisor’s
                    authority to issue instructions, request information about
                    the Account(s) from Custodian and to act for Client. Client
                    also authorizes Advisor to disclose any personal or
                    financial information to service providers including without
                    limitation Custodians or other broker-dealers and their
                    affiliates, Third-Party Advisers and their affiliates,
                    account display, performance, or aggregation service
                    providers, and parties maintaining or servicing customer
                    relationship management software or websites. This Section
                    is subject to the Authorization of Disclosure Addendum that
                    may be executed by Client at Client’s discretion.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Non-Exclusive Contract.</strong> Client acknowledges
                    that Advisor provides advisory services to more than one
                    client, and may give advice, and take action, with respect
                    to any of its other clients which may differ from the advice
                    given, or the timing or nature of action taken, with respect
                    to Client’s Account(s). From time to time, Advisor may group
                    or aggregate orders for the benefit of multiple clients. In
                    these situations, it is Advisor's policy, to the extent
                    practicable, to allocate investment opportunities to the
                    Client’s Account(s) over a period of time and on an
                    equitable basis relative to other clients. Advisor has no
                    obligation to disclose to Client the purchase or sale of any
                    security which Advisor, its principals, affiliates, or
                    employees may purchase or sell for its (their) own
                    account(s) or for the accounts of other clients. Client
                    acknowledges that the Advisory Fee charged to Client may be
                    higher or lower than that which Advisor charges other
                    clients for the same or similar services, and that lower
                    fees for similar services may be available from other
                    sources.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Term and Termination.</strong> This Agreement shall
                    commence on the date of acceptance by Advisor as shown below
                    and shall continue until terminated in accordance with the
                    provisions of this Section. Either party may terminate
                    Agreement at any time upon written notice. If Client
                    terminates within five (5) days of the date the Agreement is
                    signed, then Advisor will not charge an Advisory Fee. If the
                    Advisory Fee is not paid from the Account(s) prior to
                    account closure and/or transfer, invoices for Advisory Fees
                    will be mailed to Client within fourteen (14) business days
                    of receipt of termination notice. All custodial termination
                    and transfer fees, if any, assessed by Custodian will be the
                    responsibility of Client. Termination of this Agreement will
                    not affect (a) the validity of any action previously taken
                    by Advisor under this Agreement; (b) liabilities or
                    obligations of the parties from transactions initiated
                    before termination of this Agreement; or (c) Client’s
                    obligation to pay Advisory Fees (pro-rated through the date
                    of termination). Upon termination of this Agreement, Advisor
                    shall perform no functions whatsoever with respect to the
                    managing of the Account(s), and further management of those
                    accounts shall be the sole responsibility of Client.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Death or Disability.</strong> If Client is a natural
                    person, the death, disability, or incompetence of Client
                    will not terminate or change the terms of Agreement.
                    However, Client's executor, guardian, attorney-in-fact, or
                    other authorized representative may terminate Agreement by
                    giving written notice to Advisor.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Mediation/Arbitration.</strong> To the extent
                    permitted by law, any controversy or dispute which may arise
                    between the Client and the Advisor concerning any
                    transaction or the construction, performance or breach of
                    this Agreement shall be settled by arbitration. Any
                    arbitration shall be pursuant to the rules, then applying,
                    of the American Arbitration Association. The parties agree
                    that any arbitration proceeding pursuant to this provision
                    shall be held in a location as determined by the rules of
                    the American Arbitration Association. The award of the
                    arbitrators shall be final and binding on the parties, and
                    judgment upon the award rendered may be entered into in any
                    court, state or federal, having jurisdiction. The Client
                    acknowledges the following:
                  </p>
                  <p>
                    <strong>
                      Arbitration is final and binding on all parties.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      The parties are waiving their right to seek remedies in
                      court, including the right to jury trial, except to the
                      extent such a waiver would violate applicable law.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Pre-arbitration discovery is generally more limited than
                      and different from court proceedings.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      The arbitrators' award is not required to include factual
                      findings or legal reasoning and any party's right to
                      appeal or to seek modification of rulings by the
                      arbitrators is strictly limited.
                    </strong>
                  </p>
                  <p>
                    This Agreement to arbitrate does not entitle you to obtain
                    arbitration of claims that would be barred by the relevant
                    statute of limitations if such claims were brought in a
                    court of competent jurisdiction. If at the time a demand for
                    arbitration is made or an election or notice of intention to
                    arbitrate is served, the claims sought to be arbitrated
                    would have been barred by the relevant statute of
                    limitations or other time bar, any party to this Agreement
                    may assert the limitations as a bar to the arbitration by
                    applying to any court of competent jurisdiction, and you
                    expressly agree that any issues relating to the application
                    of a statute of limitations or other time bar, are referable
                    to such a court. The failure to assert such bar by
                    application to a court, however, shall not preclude its
                    assertion before the arbitrators.
                  </p>
                  <p>
                    Notwithstanding the foregoing, this binding arbitration
                    clause in no way limits or affects the Client’s rights under
                    the Investment Adviser’s Act or related state securities
                    laws. With respect to controversies or disputes which may
                    arise between Client and Advisor concerning matters
                    involving alleged violations of applicable federal and state
                    securities laws, breach of common law or statutory duty,
                    this mediation and arbitration clause does not constitute a
                    waiver of any legal rights provided under the aforementioned
                    laws, including the right to choose a forum, whether by
                    arbitration or adjudication, in which to seek the resolution
                    of disputes.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Risk Acknowledgement.</strong> Advisor will use its
                    best judgment and good faith efforts in rendering services
                    to Client. Although Advisor will consider tax considerations
                    in making recommendations, Advisor’s investment selections
                    on behalf of Client shall not constitute legal or tax
                    advice, analysis or opinion. Advisor does not guarantee
                    future performance or any specific performance, success of
                    any investment decision or strategy that Advisor may use, or
                    success of Advisor's overall management of the Account(s).
                    Client understands that investment decisions by Advisor are
                    subject to various market, currency, economic, political,
                    and business risks, and investment decisions will not always
                    be profitable. Advisor will provide advice only with respect
                    to the securities, cash, and other investments held in the
                    Account(s).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Legal Proceedings.</strong> Client agrees that
                    Advisor will not advise or act for Client in any legal
                    proceedings, including bankruptcies or class actions,
                    involving securities held or previously held by Client in
                    the Account(s) or by issuers of securities.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Advisor's Registration Status.</strong> Advisor is
                    registered with the U.S. Securities and Exchange Commission
                    (“SEC”) as an investment adviser under the federal
                    Investment Advisers Act of 1940 (“Advisers Act”).
                    Registration does not imply a certain level of skill or
                    training. The validity of this Agreement shall be unaffected
                    in the event that Advisor ceases to be registered with the
                    SEC, as long as Advisor is registered as required by
                    applicable state and federal laws.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Client Authority.</strong> If this Agreement is
                    entered into by more than one Client, Advisor’s services
                    shall be based upon the collective goals as communicated to
                    by all Clients to this Agreement. Therefore, Advisor is
                    authorized to rely upon instructions and/or information
                    Advisor receives from either Client, unless and until such
                    authorization is revoked in writing (signed by all Clients)
                    and provided to Advisor. If Client is a Corporation,
                    Partnership, or Limited Liability Company, the person
                    signing this Agreement warrants and represents that he or
                    she has been authorized to do so by appropriate resolution,
                    agreement, or article. If a trustee or other fiduciary
                    enters into the Agreement, the trustee or fiduciary
                    represents that Advisor's investment management or
                    supervisory services are authorized under the applicable
                    plan, trust, or law and that the person signing this
                    Agreement has the authority to negotiate and enter into this
                    or subsequent agreements. Client shall inform Advisor of any
                    event that might affect this authority or the propriety of
                    this Agreement. Advisor shall not be responsible for any
                    claims or damages resulting from such reliance or from any
                    change in the status of the relationship between the Clients
                    who have entered into this Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Assignment.</strong> This Agreement is binding and
                    for the benefit of the parties to Agreement, their
                    successors, and permitted assigns, except that Agreement may
                    not be assigned (within the meaning of the Advisers Act and
                    applicable state securities statutes) by either party
                    without consent of the other party.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Captions.</strong> The captions in this Agreement
                    are included for convenience of reference only and in no way
                    define or limit any of the provisions hereof or otherwise
                    affect their construction or effect.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Governing Law.</strong> This Agreement is governed
                    by and construed in accordance with the laws of the State of
                    North Carolina without giving effect to any conflict or
                    choice of law provisions of the State, provided that nothing
                    in Agreement will be construed in any manner inconsistent
                    with the North Carolina Investment Advisers Act, Advisers
                    Act, or any orders issued, or rules and regulations
                    promulgated pursuant to the foregoing.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Notices.</strong> Any notice or report to be given
                    to Advisor under this Agreement will be delivered in person,
                    U.S. mail, overnight courier (postage prepaid), or sent by
                    facsimile (with a hard copy sent by U.S. mail) to Advisor as
                    addressed below and to the attention of its Principal or at
                    such other address as Advisor may designate in writing.
                    Client authorizes Advisor to send, and agrees to receive,
                    disclosure documents, policies updates, and notices through
                    electronic mail transmissions (i.e., via email) to email
                    addresses designated by Client, except in instances
                    otherwise prohibited by law. Notices may also be given to
                    Client at the address set forth in the Client Profile or at
                    such other address as Client may designate in writing.
                  </p>
                  <p>
                    Hobart Private Capital, LLC
                    <br />
                    7733 Ballantyne Commons Parkway, Ste. 101
                    <br />
                    Charlotte, NC 28277
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Amendment.</strong> This Agreement contains the
                    entire understanding between Client and Advisor concerning
                    the subject matter of this Agreement and may only be amended
                    by a written amendment signed by both parties, or by Advisor
                    sending notice of a proposed amendment to the Client at
                    Client's last known address, provided Client does not object
                    to said proposed amendment within thirty (30) days of the
                    date of the notice.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Miscellaneous.</strong> If any provision of this
                    Agreement is or should become inconsistent with any law or
                    rule of any governmental or regulatory body having
                    jurisdiction over the subject matter, the provision will be
                    deemed to be rescinded or modified in accordance with any
                    such law or rule. In all other aspects, this Agreement will
                    continue and remain in full force and effect. Advisor's
                    failure to insist at any time on strict compliance with this
                    Agreement or with any terms of this Agreement or any
                    continued course of such conduct on its part will not
                    constitute or be considered a waiver by Advisor of any of
                    its rights or privileges.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Disclosure Statements.</strong> Client acknowledges
                    receipt of the Advisor’s Firm Brochure (Form ADV Part 2A),
                    Wrap Fee Brochure (Appendix 1) and Brochure Supplement(s) as
                    required by SEC Rule 204-3(b) and analogous state
                    regulations, no later than the date of execution of this
                    Agreement. Client also acknowledges receipt of Advisor’s
                    Privacy Notice and the Custodian’s pricing schedule of
                    charges. If applicable, Client further acknowledges receipt
                    of the Firm Brochure (ADV Part 2A), Wrap Fee Brochure
                    (Appendix 1), Brochure Supplement(s) and Privacy Notice of
                    the Third-Party Adviser designated on{' '}
                    <span className="underline">Schedule D</span>.
                  </p>
                </li>
              </ol>

              <div className="page-break"></div>
              <div className="signatures">
                <p>
                  <strong>IN WITNESS WHEREOF,</strong> the Parties indicate
                  agreement and acceptance of all terms and provisions herein by
                  execution of this Agreement as of the dates indicated below.
                </p>
                <p>
                  THIS AGREEMENT CONTAINS A BINDING MEDIATION/ARBITRATION
                  PROVISION THAT MAY BE ENFORCED BY THE PARTIES.
                </p>

                <ul className="parties">
                  <li>
                    <SignatureRow
                      title="Client Signature"
                      id="sig_1_client"
                      signerType="Client"
                      description={client.legal_name + ', First Investor'}
                      signature={doc.signatures.sig_1_client}
                      textSignature={{
                        id: 'sig_1_client_text',
                        value: doc.signatures.sig_1_client_text,
                        label: '(or) Print Name as your Signature:',
                        placeholder: 'Print Name',
                      }}
                      handleInputChange={handleInputChange}
                      handleSignatureConfirm={handleSignatureConfirm}
                      handleSignatureRetry={handleSignatureRetry}
                      allowEdit={type !== 'send'}
                      signatureType={firm.signatureType}
                    />
                  </li>
                  <li>
                    <SignatureRow
                      title="Second Signature"
                      id="sig_1_second"
                      signerType="Client"
                      description={
                        client.second_investor_legal_name.length
                          ? client.second_investor_legal_name +
                            ', Second Investor'
                          : 'Second Investor'
                      }
                      signature={doc.signatures.sig_1_second}
                      textSignature={{
                        id: 'sig_1_second_text',
                        value: doc.signatures.sig_1_second_text,
                        label: '(or) Print Name as your Signature:',
                        placeholder: 'Print Name',
                      }}
                      handleInputChange={handleInputChange}
                      handleSignatureConfirm={handleSignatureConfirm}
                      handleSignatureRetry={handleSignatureRetry}
                      allowEdit={type !== 'send'}
                      signatureType={firm.signatureType}
                    />
                  </li>
                  <li>
                    <SignatureRow
                      title="Hobart Private Capital, LLC"
                      id="advisor_sig"
                      signerType="Advisor"
                      description={advisor.name + ', Advisor'}
                      signature={advisor.signature}
                      // textSignature={{
                      //   id: 'second_text',
                      //   value: doc.signatures.second_text,
                      //   label: '(or) Print Name as your Signature:',
                      //   placeholder: 'Print Name'
                      // }}
                      handleInputChange={handleInputChange}
                      handleSignatureConfirm={handleSignatureConfirm}
                      handleSignatureRetry={handleSignatureRetry}
                    />
                  </li>
                </ul>
              </div>

              <div className="page-break"></div>
              <div className="articles schedules">
                <ol>
                  <li>
                    <h3>Schedule A</h3>
                    <p>
                      All special instructions and limitations regarding the
                      investment and management of the Account(s) are set forth
                      below.
                    </p>
                    <div className="schedule-a-text">{client.schedule_a}</div>
                    <ul className="initials-groups">
                      <li>
                        <InitialsField
                          signer="First Investor"
                          required={true}
                          id="ini_schedule_a_client"
                          value={doc.initials.schedule_a_client}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                      <li>
                        <InitialsField
                          signer="Second Investor"
                          required={client.second_investor_legal_name.length}
                          id="ini_schedule_a_second"
                          value={doc.initials.schedule_a_second}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                      <li>
                        <InitialsField
                          signer="Accepted by Advisor"
                          id="ini_schedule_a_advisor"
                          value={advisorInitials}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                    </ul>
                  </li>

                  <div className="page-break"></div>
                  <li>
                    <h3>Schedule B</h3>
                    <h3>ASSETS EXCEPTED FROM ADVISORY FEE</h3>
                    <h3>Hobart Private Capital, LLC</h3>
                    <p>
                      In accordance with the Investment Advisory Agreement
                      executed on {currentDate()}, the Client and Hobart Private
                      Capital, LLC agree that the assets listed below, by
                      sub-account, will be exempted from the management fee
                      charged by Advisor:
                    </p>
                    <Table
                      type="columns"
                      headerRow={[
                        { id: 'account', label: 'Account Title' },
                        { id: 'account_number', label: 'Account Number' },
                      ]}
                      rows={client.assets_excepted_from_advisory_fee}
                    />
                    <p>
                      Client acknowledges and agrees that Advisor will not
                      provide any investment management services for the
                      securities or sub-accounts listed above.
                    </p>
                    <InitialsField
                      signer="First Investor"
                      required={true}
                      id="ini_no_management_services_client"
                      value={doc.initials.no_management_services_client}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    <div className="date">
                      <div className="label">Date</div>
                      {this.currentDate}
                    </div>
                    <InitialsField
                      signer="Second Investor"
                      required={client.second_investor_legal_name.length}
                      id="ini_no_management_services_second"
                      value={doc.initials.no_management_services_second}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                    <div className="date">
                      <div className="label">Date</div>
                      {this.currentDate}
                    </div>

                    <ul className="initials-groups">
                      <li>
                        <InitialsField
                          signer="First Investor"
                          required={true}
                          id="ini_schedule_b_client"
                          value={doc.initials.schedule_b_client}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                      <li>
                        <InitialsField
                          signer="Second Investor"
                          required={client.second_investor_legal_name.length}
                          id="ini_schedule_b_second"
                          value={doc.initials.schedule_b_second}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                      <li>
                        <InitialsField
                          signer="Accepted by Advisor"
                          id="ini_schedule_b_advisor"
                          value={advisorInitials}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                    </ul>
                  </li>

                  <div className="page-break"></div>
                  <li>
                    <h3>Schedule C</h3>
                    <h3>ASSETS SUBJECT TO CUSTOM ADVISORY FEE</h3>
                    <h3>Hobart Private Capital, LLC</h3>
                    <p>
                      In accordance with the Investment Advisory Agreement
                      executed on {currentDate()}, the Client and Hobart Private
                      Capital, LLC agree that the assets listed below, either by
                      security or by sub-account or by both, will be charged the
                      HPC Advisory Fee shown below, charged quarterly in
                      advance, as specified in Paragraph 8 of this Agreement.
                    </p>
                    <Table
                      type="columns"
                      headerRow={[
                        { id: 'account', label: 'Account Title' },
                        { id: 'account_number', label: 'Account Number' },
                        { id: 'fee', label: 'HPC Advisory Fee' },
                      ]}
                      rows={client.assets_subject_to_custom_fee}
                    />
                    <ul className="initials-groups">
                      <li>
                        <InitialsField
                          signer="First Investor"
                          required={true}
                          id="ini_schedule_c_client"
                          value={doc.initials.schedule_c_client}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                      <li>
                        <InitialsField
                          signer="Second Investor"
                          required={client.second_investor_legal_name.length}
                          id="ini_schedule_c_second"
                          value={doc.initials.schedule_c_second}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                      <li>
                        <InitialsField
                          signer="Accepted by Advisor"
                          id="ini_schedule_c_advisor"
                          value={advisorInitials}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                    </ul>
                  </li>

                  <div className="page-break"></div>
                  <li>
                    <h3>Schedule D</h3>
                    <h3>THIRD-PARTY ADVISER</h3>
                    <h3>Hobart Private Capital, LLC</h3>
                    <p>
                      The Third-Party Adviser(s) to be used for management of
                      the Account(s) are set forth below.
                    </p>
                    <Table
                      type="columns"
                      headerRow={[
                        { id: 'account', label: 'Account Title' },
                        { id: 'account_number', label: 'Account Number' },
                        {
                          id: 'third_party_adviser',
                          label: 'Third-Party Adviser',
                        },
                      ]}
                      rows={client.third_party_advisers}
                    />
                    <ul className="initials-groups">
                      <li>
                        <InitialsField
                          signer="First Investor"
                          required={true}
                          id="ini_schedule_d_client"
                          value={doc.initials.schedule_d_client}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                      <li>
                        <InitialsField
                          signer="Second Investor"
                          required={client.second_investor_legal_name.length}
                          id="ini_schedule_d_second"
                          value={doc.initials.schedule_d_second}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                      <li>
                        <InitialsField
                          signer="Accepted by Advisor"
                          id="schedule_d_advisor"
                          value={advisorInitials}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                        <div className="date">
                          <div className="label">Date</div>
                          {this.currentDate}
                        </div>
                      </li>
                    </ul>
                  </li>

                  <div className="page-break"></div>
                  <li>
                    <h3>
                      Current Sub-Advisory Pricing for Hobart Private Capital
                      Clients
                    </h3>
                    <p>
                      The following is the current pricing for the Sub-advisors
                      we may use to manage your account:
                    </p>
                    <Table
                      type="columns"
                      headerRow={[
                        { id: 'name', label: 'Sub-Adviser' },
                        { id: 'fee', label: 'Annual Advisory Fee' },
                      ]}
                      rows={[
                        {
                          name: 'Global Financial Private Capital',
                          fee: '.40%',
                        },
                      ]}
                    />
                    <p>
                      By my signature below, I acknowledge receipt of this
                      document.
                    </p>
                    <div className="signatures">
                      <ul className="parties">
                        <li>
                          <SignatureRow
                            title="Client Signature"
                            id="sig_2_client"
                            signerType="Client"
                            description={client.legal_name + ', First Investor'}
                            signature={doc.signatures.sig_2_client}
                            textSignature={{
                              id: 'sig_2_client_text',
                              value: doc.signatures.sig_2_client_text,
                              label: '(or) Print Name as your Signature:',
                              placeholder: 'Print Name',
                            }}
                            handleInputChange={handleInputChange}
                            handleSignatureConfirm={handleSignatureConfirm}
                            handleSignatureRetry={handleSignatureRetry}
                            allowEdit={type !== 'send'}
                            signatureType={firm.signatureType}
                          />
                        </li>
                        <li>
                          <SignatureRow
                            title="Second Signature"
                            id="sig_2_second"
                            signerType="Client"
                            description={
                              client.second_investor_legal_name.length
                                ? client.second_investor_legal_name +
                                  ', Second Investor'
                                : 'Second Investor'
                            }
                            signature={doc.signatures.sig_2_second}
                            textSignature={{
                              id: 'sig_2_second_text',
                              value: doc.signatures.sig_2_second_text,
                              label: '(or) Print Name as your Signature:',
                              placeholder: 'Print Name',
                            }}
                            handleInputChange={handleInputChange}
                            handleSignatureConfirm={handleSignatureConfirm}
                            handleSignatureRetry={handleSignatureRetry}
                            allowEdit={type !== 'send'}
                            signatureType={firm.signatureType}
                          />
                        </li>
                      </ul>
                    </div>
                  </li>

                  <div className="page-break"></div>
                  <li>
                    <h3>Fee Billing Addendum - Invoiced Fee</h3>
                    <p>
                      Notwithstanding anything else to the contrary in the
                      Agreement, Advisor will not deduct the Advisory Fees
                      directly from Client’s Account(s). Rather, Advisor shall
                      send Client an invoice for all Advisory Fees due under
                      this Agreement. Client shall pay all invoices within
                      thirty (30) days.
                    </p>
                    <p>
                      Client and Advisor agree by their initials below that this
                      constitutes an amendment to the Investment Advisory
                      Agreement and do hereby consent to said amendment.
                    </p>
                    <div className="date">
                      <div className="label">Date</div>
                      {this.currentDate}
                    </div>
                    <ul className="initials-groups">
                      <li>
                        <InitialsField
                          signer="First Investor"
                          required={true}
                          id="ini_fee_billing_client"
                          value={doc.initials.fee_billing_client}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                      </li>
                      <li>
                        <InitialsField
                          signer="Second Investor"
                          required={client.second_investor_legal_name.length}
                          id="ini_fee_billing_second"
                          value={doc.initials.fee_billing_second}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                      </li>
                      <li>
                        <InitialsField
                          signer="Accepted by Advisor"
                          id="ini_fee_billing_advisor"
                          value={advisorInitials}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />
                      </li>
                    </ul>
                  </li>

                  <div className="page-break"></div>
                  <li>
                    <h3>Authorization of Disclosure Addendum</h3>
                    <p>
                      THIS ADDENDUM to the Investment Advisory Agreement dated{' '}
                      {currentDate()}, is made as of the date written below, by
                      and between Hobart Private Capital, LLC (hereinafter,
                      “Advisor”) and:{' '}
                      <span className="underline">{client_name}</span>{' '}
                      (hereinafter, “Client”).
                    </p>
                    <p>
                      WHEREAS, Advisor and Client have previously entered into
                      an Investment Advisory Agreement (“Agreement”) wherein
                      Advisor was appointed Client’s investment advisor with
                      authority and responsibility to provide the investment
                      advisory services identified in the Agreement; and
                    </p>
                    <p>
                      WHEREAS, pursuant to that Agreement, Advisor agreed to
                      keep all information regarding Client’s personal and
                      financial affairs confidential, subject to a written
                      addendum providing otherwise; and
                    </p>
                    <p>
                      WHEREAS, Client desires to authorize disclosure of
                      information relating to his or her personal and financial
                      affairs known by the Advisor to the authorized third party
                      listed below.
                    </p>
                    <p>
                      NOW, THEREFORE, in consideration of the foregoing and the
                      mutual covenants set forth herein, the parties hereby
                      agree as follows:
                    </p>
                    <ol className="section-subsection">
                      <li>
                        <span className="underline">
                          AUTHORIZATION OF DISCLOSURE TO DESIGNATED THIRD-PARTY
                        </span>
                        <ol>
                          <li>
                            Client hereby authorizes Advisor to disclose
                            confidential information regarding his or her
                            account and his or her personal and financial
                            affairs to{' '}
                            <span className="authorized-third-party">
                              {client.authorized_third_party}
                            </span>{' '}
                            (hereinafter, “Authorized Third Party”).
                          </li>
                          <li>
                            Advisor agrees that any disclosures made to
                            Authorized Third Party will be done solely with the
                            intention of furthering Client’s interests and will
                            be related to the management of Client’s account.
                          </li>
                          <li>
                            Advisor agrees that all disclosures made to
                            Authorized Third Party will be done discreetly and
                            in a manner designed to protect the confidential
                            nature of the protected information.
                          </li>
                        </ol>
                      </li>

                      <li>
                        <span className="underline">MISCELLANEOUS</span>
                        <ol>
                          <li>
                            All provisions of the Investment Advisory Agreement
                            that are not expressly modified or amended hereby
                            shall remain in full force and effect.
                          </li>
                        </ol>
                      </li>
                    </ol>

                    <div className="signatures">
                      <p>
                        IN WITNESS WHEREOF, the parties, through their duly
                        authorized representatives, have executed this Addendum
                        as of the date written below.
                      </p>

                      <ul className="parties">
                        <li>
                          <SignatureRow
                            title="Hobart Private Capital, LLC"
                            id="advisor_sig"
                            signerType="Advisor"
                            description={advisor.name + ', Advisor'}
                            signature={advisor.signature}
                            // textSignature={{
                            //   id: 'second_text',
                            //   value: doc.signatures.second_text,
                            //   label: '(or) Print Name as your Signature:',
                            //   placeholder: 'Print Name'
                            // }}
                            handleInputChange={handleInputChange}
                            handleSignatureConfirm={handleSignatureConfirm}
                            handleSignatureRetry={handleSignatureRetry}
                          />
                        </li>
                        <li>
                          <SignatureRow
                            title="Client Signature"
                            id="sig_3_client"
                            signerType="Client"
                            description={client.legal_name + ', First Investor'}
                            signature={doc.signatures.sig_3_client}
                            textSignature={{
                              id: 'sig_3_client_text',
                              value: doc.signatures.sig_3_client_text,
                              label: '(or) Print Name as your Signature:',
                              placeholder: 'Print Name',
                            }}
                            handleInputChange={handleInputChange}
                            handleSignatureConfirm={handleSignatureConfirm}
                            handleSignatureRetry={handleSignatureRetry}
                            allowEdit={type !== 'send'}
                            signatureType={firm.signatureType}
                          />
                        </li>
                        <li>
                          <SignatureRow
                            title="Second Signature"
                            id="sig_3_second"
                            signerType="Client"
                            description={
                              client.second_investor_legal_name.length
                                ? client.second_investor_legal_name +
                                  ', Second Investor'
                                : 'Second Investor'
                            }
                            signature={doc.signatures.sig_3_second}
                            textSignature={{
                              id: 'sig_3_second_text',
                              value: doc.signatures.sig_3_second_text,
                              label: '(or) Print Name as your Signature:',
                              placeholder: 'Print Name',
                            }}
                            handleInputChange={handleInputChange}
                            handleSignatureConfirm={handleSignatureConfirm}
                            handleSignatureRetry={handleSignatureRetry}
                            allowEdit={type !== 'send'}
                            signatureType={firm.signatureType}
                          />
                        </li>
                      </ul>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <DocumentSubmit errors={errors} handleSubmit={handleIACSubmit} />
        </div>
      )
    } else {
      return <h4>Populating...</h4>
    }
  }
}

export default HobartIAC
