import { firebaseStorage } from '../../config/firebaseConfig'
import { addDisclosureToDB } from '../db/disclosureActions'

export const uploadDisclosure = (
  type,
  uid,
  disclosureFile,
  setUploadComplete = null
) => {
  var storage = firebaseStorage

  const storageRef = storage.ref().child(uid).child('disclosures')
  const mainFile = storageRef.child(disclosureFile.name)

  mainFile.put(disclosureFile).then((snapshot) => {
    mainFile.getDownloadURL().then((url) => {
      // Add disclosure reference to Firestore
      addDisclosureToDB(type, disclosureFile.name, url, uid, setUploadComplete)
    })
  })
}
