import styled from 'styled-components'
import { Card } from 'semantic-ui-react'

export const AuthPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  min-height: 100vh;
  height: auto;
`

export const AuthBox = styled.div`
  width: 100%;
  max-width: 480px;
`

export const AuthLogo = styled.div`
  margin-top: 30px;
  margin-bottom: 24px;

  img {
    max-height: 100px;
    margin: 0 auto;
    display: block;
  }
`

export const AuthCard = styled(Card)``

export const AuthCardContent = styled(Card.Content)`
  &&& {
    padding: 2em;
  }
`

export const AuthCardActions = styled(AuthCardContent)`
  text-align: right;
`

export const AuthHeader = styled.div`
  &&& {
    h1 {
      margin-top: 0;
      margin-bottom: 0.25em;

      &:last-child {
        margin-bottom: 28px;
      }
    }

    p:last-child {
      margin-bottom: 28px;
    }
  }
`

export const AuthButton = styled.button`
  &&& {
    margin: 1em 0;
    margin-left: auto;
    display: block;
    font-size: 80%;
  }
`

export const AuthExtra = styled.div`
  text-align: center;
  margin: 2.5em auto;
`
