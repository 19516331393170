import React, { memo } from 'react'

export default memo(function MoneyBagIcon() {
  return (
    <svg
      width="41px"
      height="39px"
      viewBox="0 0 41 39"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M28.3148 29.358L40.2177 29.358C40.5571 29.358 40.8323 29.6329 40.8323 29.9726C40.8323 30.3123 40.5571 30.5872 40.2177 30.5872L25.2397 30.5872C22.5838 31.2369 19.4517 31.2388 16.4828 31.2388C13.6611 31.2388 10.5546 31.2369 7.8781 30.5872L0.614574 30.5872C0.275176 30.5872 0 30.3123 0 29.9726C0 29.6329 0.275176 29.358 0.614574 29.358L4.75758 29.358C2.58227 28.0429 1.13694 25.8092 1.13694 22.0079C1.13694 15.3459 5.13783 6.91397 10.0974 3.01758C9.70411 2.71029 9.45212 2.23091 9.45212 1.69623C9.45212 0.762066 10.2081 0 11.1545 0L21.3749 0C21.6023 0 21.8173 0.0430263 22.014 0.129073C21.5654 1.21688 21.2888 2.46445 21.2396 3.87796C21.0922 6.48375 22.0693 9.11415 23.9376 11.0931C25.6462 12.9061 27.8893 14 30.3231 14.209C31.2941 16.9254 31.8288 19.6725 31.8288 22.0079C31.8288 25.8092 30.4439 28.0429 28.3148 29.358ZM7.73132 16.5935C7.73132 21.4241 11.6585 25.3512 16.4829 25.3512C21.3073 25.3512 25.2344 21.4425 25.2344 16.5935C25.2344 11.7691 21.3134 7.84198 16.4829 7.84198C11.6585 7.84198 7.73132 11.7691 7.73132 16.5935Z"
          transform="translate(0 7.31955)"
          fill="#0DC4B6"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M1.09208 6.09042L12.4802 6.09042L13.5126 2.18788C13.7953 1.12465 13.0394 0.0184188 11.9393 2.72926e-16L11.9086 2.72926e-16C11.0666 2.72926e-16 10.366 0.553092 10.2062 1.29059C10.0341 0.553098 9.33354 0 8.49158 0C7.64958 0 6.94899 0.553092 6.7892 1.29059C6.62325 0.553098 5.91036 0 5.08066 0C4.2387 0 3.53807 0.553092 3.37832 1.29059C3.20622 0.553098 2.49333 0 1.66363 0L1.63291 0C0.532832 0.0184188 -0.223119 1.12465 0.0595972 2.18788L1.09208 6.09042Z"
          transform="translate(9.478516 0)"
          id="Path"
          fill="#0DC4B6"
          stroke="none"
        />
        <path
          d="M0 7.52493C0 3.37595 3.37565 0 7.52463 0C11.6739 0 15.0496 3.37595 15.0496 7.52493C15.0496 11.6633 11.6992 15.0493 7.52463 15.0493C3.37566 15.0493 0 11.6739 0 7.52493ZM8.15511 12.6588L8.14655 12.03C9.28069 11.7498 10.1273 10.7309 10.1273 9.5115C10.1273 8.0771 8.95964 6.91036 7.52464 6.91036C6.76752 6.91036 6.15144 6.29459 6.15144 5.53777C6.15144 4.78096 6.76692 4.16518 7.52373 4.16518C8.28055 4.16518 8.89602 4.78096 8.89602 5.53777C8.89602 5.87747 9.1712 6.15235 9.5106 6.15235C9.84999 6.15235 10.1252 5.87747 10.1252 5.53777C10.1252 4.31346 9.27214 3.29044 8.13114 3.01507L8.1224 2.37368C8.1179 2.03398 7.84302 1.77471 7.49943 1.7675C7.16003 1.7723 6.88876 2.05078 6.89325 2.39048L6.90181 3.01957C5.76831 3.30029 4.9223 4.31878 4.9223 5.53777C4.9223 6.97218 6.08963 8.13951 7.52464 8.13951C8.28205 8.13951 8.89812 8.75468 8.89812 9.51149C8.89812 10.2683 8.28265 10.8841 7.52584 10.8841C6.76902 10.8841 6.15325 10.2683 6.15325 9.51149C6.15325 9.1718 5.87807 8.89692 5.53867 8.89692C5.19927 8.89692 4.9241 9.1718 4.9241 9.51149C4.9241 10.7354 5.7766 11.758 6.91722 12.0339L6.92596 12.6756C6.93046 13.0123 7.20474 13.2818 7.54023 13.2818L7.54894 13.2818C7.88834 13.277 8.15961 12.9985 8.15511 12.6588Z"
          transform="translate(8.95874 16.39005)"
          fill="#0DC4B6"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M17.5167 8.67342C17.5167 13.5643 13.494 17.4311 8.75901 17.4311C3.67478 17.4311 -0.261621 13.064 0.0135974 8.35999C0.216413 2.51538 4.77043 -0.0781058 8.8942 0.00178883C13.0487 0.0693858 17.5167 2.81655 17.5167 8.67342ZM8.12767 3.54234L8.13622 4.17144C7.00273 4.45217 6.15671 5.47066 6.15671 6.68964C6.15671 8.12405 7.32405 9.29138 8.75906 9.29138C9.51647 9.29138 10.1325 9.90716 10.1325 10.664C10.1325 11.4208 9.51707 12.036 8.76025 12.036C8.00344 12.036 7.38797 11.4208 7.38797 10.664C7.38797 10.3243 7.11279 10.0494 6.77339 10.0494C6.43399 10.0494 6.15882 10.3243 6.15882 10.664C6.15882 11.8879 7.01128 12.91 8.15164 13.1857L8.16038 13.8275C8.16488 14.1642 8.43916 14.4336 8.77465 14.4336L8.78336 14.4336C9.12275 14.4288 9.39403 14.1504 9.38953 13.8107L9.38097 13.182C10.5151 12.9019 11.3617 11.8834 11.3617 10.664C11.3617 9.22956 10.1941 8.06223 8.75905 8.06223C8.00193 8.06223 7.38586 7.44646 7.38586 6.68964C7.38586 5.93282 8.00133 5.31705 8.75815 5.31705C9.51496 5.31705 10.1304 5.93282 10.1304 6.68964C10.1304 7.02933 10.4056 7.30421 10.745 7.30421C11.0844 7.30421 11.3596 7.02933 11.3596 6.68964C11.3596 5.46533 10.5066 4.4423 9.36555 4.16694L9.35682 3.52554C9.35232 3.18584 9.07114 2.93257 8.73384 2.91937C8.39444 2.92417 8.12317 3.20265 8.12767 3.54234Z"
          transform="translate(22.4552 2.911285)"
          fill="#0DC4B6"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  )
})
