import { transformCustomErrors } from 'helpers/rjsf'
import { firmIDs } from 'config/firmConfig'
import { calculateGwnClient, validateGwnClient } from './gwn'

export const doFirmCalculations = (client) => {
  let calculatedClient = client

  // Do firm-specific calculations based on firm ID
  switch (calculatedClient.firm) {
    case firmIDs['gwn']:
    case 'TEST_FIRM': // TODO: [BTS-841] Remove TEST_FIRM from doFirmCalculations
      calculatedClient = calculateGwnClient(calculatedClient)
      break
    default:
      break
  }

  return calculatedClient
}

export const doFirmValidations = ({
  client,
  errors,
  stepProperties,
  stepDependencies,
  templateId,
}) => {
  console.log('Validating against firm rules...')

  // Do firm-specific calculations based on firm ID
  switch (client.firm) {
    case firmIDs['gwn']:
    case 'TEST_FIRM':
      validateGwnClient(client, errors, templateId)
      break
    default:
      break
  }

  return transformCustomErrors({ errors, stepProperties, stepDependencies })
}
