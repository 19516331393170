import qs from 'querystring'

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
}

export const deepSearchByKey = (object, originalKey, matches = []) => {
  if (object != null) {
    if (Array.isArray(object)) {
      for (let arrayItem of object) {
        deepSearchByKey(arrayItem, originalKey, matches)
      }
    } else if (typeof object === 'object') {
      for (let key of Object.keys(object)) {
        if (key === originalKey) {
          matches.push(object)
        } else {
          deepSearchByKey(object[key], originalKey, matches)
        }
      }
    }
  }

  return matches
}

export const formatArrayString = (array, separator = 'and') => {
  if (!array || !array.length) {
    return ''
  }

  if (array.length === 1) {
    return array.join()
  }

  return array.slice(0, -1).join(', ') + ` ${separator} ` + array.slice(-1)
}

export const getEnv = (allowLocal = false) => {
  const host = window.location.href

  if (allowLocal && host.includes('localhost')) {
    return 'local'
  }

  if (
    host.includes('localhost') ||
    host.includes('staging') ||
    host.includes('pentest')
  ) {
    return 'sandbox'
  }

  return 'prod'
}

export const getParams = (window) => {
  const { location } = window
  return qs.parse(location.search.substr(1))
}

export const isTrueParam = (param) => {
  if (
    !param ||
    (param &&
      ('false' === param || param === 'undefined' || 0 === parseInt(param)))
  ) {
    return false
  }

  return true
}

export const isValidJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const removeKey = (obj, propToDelete) => {
  const { [propToDelete]: deleted, ...objectWithoutDeletedProp } = obj
  return objectWithoutDeletedProp
}
