import { firebaseFunctions } from '../../../config/firebaseConfig'

const integrationsPractifiCheckIntegration = firebaseFunctions.httpsCallable(
  'integrationsPractifiCheckIntegration'
)

export async function checkIntegration(uid) {
  try {
    const result = await integrationsPractifiCheckIntegration({ uid })
    return result.data
  } catch (err) {
    return false
  }
}
