import React, { useRef } from 'react'
// import { filter, uniqWith, isEqual } from 'lodash'
import Form from '@rjsf/semantic-ui'

const CustomForm = (props) => {
  const myRef = useRef(null)

  // const blurHandler = (...args) => {
  //   const $this = myRef.current
  //   const field = args[0].split(/_(.+)/)[1]
  //   const { formData, uiSchema, errors, errorSchema } = $this.state

  //   if (uiSchema[field] && uiSchema[field].validateOnBlur) {
  //     const { errors: _errors, errorSchema: _errorSchema } = $this.validate(
  //       formData
  //     )

  //     const prevOtherFieldErrors = filter(
  //       errors,
  //       (error) => error['property'] !== `.${field}`
  //     )

  //     const fieldErrors = filter(_errors, ['property', `.${field}`])

  //     const fieldErrorSchema = _errorSchema[field]

  //     $this.setState({
  //       errors: uniqWith([...prevOtherFieldErrors, ...fieldErrors], isEqual),
  //       errorSchema: { ...errorSchema, [field]: fieldErrorSchema },
  //     })
  //   }
  // }

  // return <Form {...props} ref={myRef} onBlur={blurHandler} />

  return <Form {...props} ref={myRef} />
}

export default CustomForm
