import {
  aamaLogo,
  absoluteCapitalLogo,
  americanFundsLogo,
  blackrockLogo,
  frontierAssetLogo,
  gwnSelectLogo,
  gwnSelectEtfLogo,
  iconLogo,
  mainManagementLogo,
  oceanParkLogo,
  portfolioStrategiesLogo,
  russellLogo,
  toewsLogo,
  vanguardLogo,
  verityLogo,
  weDonoghueLogo,
} from '../../../../assets/images/platforms'

export const platforms = {
  'Absolute Capital': {
    logo: absoluteCapitalLogo,
    models: {
      31839: 'Asset Allocator Aggressive Growth',
      31828: 'Asset Allocator Growth',
      31830: 'Asset Allocator Core',
      31833: 'Asset Allocator Conservative',
      31837: 'Asset Allocator Income',
      23424: 'Portfolio Protector Bond',
      23425: 'Portfolio Protector Multi-Fund',
    },
  },
  'Advanced Asset Management Advisors': {
    logo: aamaLogo,
    models: {
      12085: 'Aggressive Growth',
      12079: 'Growth',
      12087: 'Strategic Balanced',
      12083: 'Global Aggressive Growth',
      12080: 'Global Growth',
      12081: 'Global Strategic Balanced',
    },
  },
  'American Funds': {
    logo: americanFundsLogo,
    models: {
      78004: 'Global Growth',
      78005: 'Growth',
      78008: 'Moderate Growth',
      78007: 'Growth & Income',
      78009: 'Moderate Growth & Income',
      78000: 'Conservative Growth & Income',
      78012: 'Retirement Income - Enhanced',
      78013: 'Retirement Income - Moderate',
      78011: 'Retirement Income - Conservative',
      78003: 'Conservative Income',
      78010: 'Preservation',
    },
  },
  BlackRock: {
    logo: blackrockLogo,
    models: {
      84702: 'Multi-Asset Income Growth',
      84700: 'Multi-Asset Income Moderate',
      84698: 'Multi-Asset Income Conservative',
      51834: 'BlackRock Strategic 100/0 - (% Equity/Fixed)',
      51833: 'BlackRock Strategic 80/20 - (% Equity/Fixed)',
      51830: 'BlackRock Strategic 60/40 - (% Equity/Fixed)',
      51841: 'Aggressive Income',
      51840: 'High Income',
      51839: 'Moderate Income',
      51838: 'Core Income',
    },
  },
  'Frontier Asset Management': {
    logo: frontierAssetLogo,
    models: {
      23328: 'Absolute Return',
      23333: 'Absolute Return Plus',
      27764: 'Balanced',
      23334: 'Focused Opportunities',
      27760: 'Global Opportunities',
      27765: 'Income Profile',
      27762: 'Long Term Growth',
    },
  },
  'GWN Select': {
    logo: gwnSelectLogo,
    models: {
      12099: 'Maximum Appreciation',
      12059: 'Growth Appreciation',
      12089: 'Moderate',
      12098: 'Balanced',
      12095: 'Conservative',
    },
  },
  'GWN Select ETF': {
    logo: gwnSelectEtfLogo,
    models: {
      27464: 'Dynamic ETF Maximum Appreciation',
      27462: 'Dynamic ETF Growth Appreciation',
      27468: 'Dynamic ETF Moderate',
      27461: 'Dynamic ETF Balanced',
      27467: 'Dynamic ETF Conservative',
      11989: 'Flexible Strategy ETF',
      23051: 'Strategic ETF Maximum Appreciation',
      23049: 'Strategic ETF Growth Appreciation',
      23046: 'Strategic ETF Moderate',
      23045: 'Strategic ETF Balanced',
      23044: 'Strategic ETF Conservative',
    },
  },
  Icon: {
    logo: iconLogo,
    models: {
      12066: 'U.S. Growth Portfolio',
      12063: 'U.S. Moderate Portfolio',
      12064: 'U.S. Conservative Portfolio',
      22572: 'U.S. Income Portfolio',
      12082: 'Global Growth Portfolio',
      12067: 'Global Moderate Portfolio',
      12072: 'Global Conservative Portfolio',
      12065: 'International Growth Portfolio',
      32398: 'SBI Global Growth Portfolio',
      32399: 'SBI Global Moderate Portfolio',
      32400: 'SBI Global Conservative Portfolio',
    },
  },
  'Main Management': {
    logo: mainManagementLogo,
    models: {
      82563: 'Main Active Sector Rotation ETF',
      82562: 'Main BuyWrite',
    },
  },
  'Ocean Park Asset Management': {
    logo: oceanParkLogo,
    models: {
      11991: 'High Yield Corporate Bond Program',
      11990: 'Balanced Program',
    },
  },
  'Portfolio Strategies Investment Managers': {
    logo: portfolioStrategiesLogo,
    models: {
      12413: 'Managed Volatility',
      32293: 'Growth Plus',
      13501: 'Tactical Opportunity Growth',
      13499: 'Tactical Opportunity Moderate Growth',
      13498: 'Tactical Opportunity Moderate',
      13497: 'Tactical Opportunity Moderate Conservative',
      13496: 'Tactical Opportunity Conservative',
      12411: 'Tactical Diversified Equity',
    },
  },
  'Russell Investments': {
    logo: russellLogo,
    models: {
      12075: 'Equity Growth Strategy',
      12078: 'Growth Strategy',
      12074: 'Balanced Strategy',
      12101: 'Moderate Strategy',
      12073: 'Conservative Strategy',
    },
  },
  Toews: {
    logo: toewsLogo,
    models: {
      34815: 'All Equity (formerly Aggressive Growth)',
      34814: 'Growth',
      34807: 'Balanced Growth',
      34805: 'Balanced',
      34803: 'Balanced Income',
      34802: 'Capital Preservation',
      34818: 'Dynamic Fixed Income-High Income',
      34817: 'Dynamic Fixed Income-Moderate Income',
      34816: 'Dynamic Fixed Income-Conservative Income',
      55050: 'Defensive Alpha - Equity',
    },
  },
  Vanguard: {
    logo: vanguardLogo,
    models: {
      80009: 'Vanguard Core ETF 100/0 - (% Equity/Fixed)',
      80011: 'Vanguard Core ETF 80/20 - (% Equity/Fixed)',
      80012: 'Vanguard Core ETF 60/40 - (% Equity/Fixed)',
      80014: 'Vanguard Core ETF 40/60 - (% Equity/Fixed)',
      80015: 'Vanguard Core ETF 20/80 - (% Equity/Fixed)',
      80017: 'Vanguard Core ETF 0/100 - (% Equity/Fixed)',
    },
  },
  'Verity Asset Management': {
    logo: verityLogo,
    models: {
      61651: 'Opportunistic Income ETF Strategy',
      61650: 'Conservative Total Return ETF Strategy',
    },
  },
  'W.E. Donoghue & Co.': {
    logo: weDonoghueLogo,
    models: {
      23337: 'Power Income',
      32510: 'Power Dividend & Yield',
      32513: 'Power Growth & Income',
    },
  },
}
