import React, { memo } from 'react'
import { getInitials } from '../../actions/helpers'

const FirmLogo = memo(({ firm, initialsOnly }) => {
  if (!firm) {
    return ''
  }

  if (initialsOnly && !firm.logo) {
    const initials = getInitials(firm.name)

    return (
      <div className="firm-logo">
        <span className="initials">{initials}</span>
      </div>
    )
  }

  return (
    <div className="firm-logo">
      {firm.logo ? (
        <img src={firm.logo} alt={firm.name} className="mx-auto" />
      ) : (
        <span className="name">{firm.name}</span>
      )}
    </div>
  )
})

export default FirmLogo
