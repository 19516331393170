import React, { Component, memo, useState } from 'react'
import styled from 'styled-components'
import fonts from '../../../../styles/fonts'
import AddNewFirmClientsModule from '../../../dashboards/partials/AddNewFirmClientsModule'
import { useUser, useFirm, useAdvisor } from '../../../../contexts'
import sendDocuments from 'actions/email/sendDocuments'
import Loader from 'components/partials/Loader'

// TODO: [BTS-634] Deprecate AddNewFirmClientsModule
// ? The following AddClientModule is a temporary bridge
// ? until AddNewFirmClientsModule gets reworked
class AddClientModule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      client: this.props.client,
      uid: null,
      userAddSuccess: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { handleSetClient, handleSetClientID, nextStep } = this.props
    const { client, uid } = this.state

    if (client !== prevState.client) {
      // Client has been updated
      handleSetClient(client)
    }

    if (uid !== prevState.uid) {
      // Client ID has been updated
      handleSetClientID(uid)
    }

    if (uid && client && (!prevState.client || !prevState.uid)) {
      nextStep(uid)
    }
  }

  render() {
    const { firmID, advisorID } = this.props
    return (
      <AddNewFirmClientsModule
        firmID={firmID}
        advisorID={advisorID}
        type="Client"
        onboardingModule={this}
      />
    )
  }
}

const AddClientPage = memo(
  ({
    client,
    handleSetClient,
    handleSetClientID,
    nextStep,
    label,
    template,
    additionalDocuments,
  }) => {
    const { userType } = useUser()
    const { firmID, firm } = useFirm()
    const { advisorID, advisor } = useAdvisor()

    const [sendingDisclosures, setSendingDisclosures] = useState(false)

    const handleNextStep = async (clientID) => {
      // Send disclosures
      if (
        template &&
        template.sendDisclosuresWhen &&
        'on-add' === template.sendDisclosuresWhen &&
        additionalDocuments.length
      ) {
        setSendingDisclosures(true)
        await sendDocuments({
          client: {
            name: client.legal_name || client.email,
            email: client.email,
            uid: clientID,
          },
          advisor:
            'advisor' === userType
              ? {
                  name: advisor.name,
                  email: advisor.email,
                  uid: advisorID,
                }
              : null,
          firm: {
            name: firm.name,
            email: firm.email,
            uid: firmID,
          },
          forms: additionalDocuments.map((e) => ({
            name: e.document.name,
            link: e.document.link,
          })),
          subject: firm.emailSettings?.disclosures?.subject,
          body: firm.emailSettings?.disclosures?.body,
        })
        setSendingDisclosures(false)
      }

      nextStep()
    }

    return (
      <Wrapper data-step={label}>
        <Header>Let's get started</Header>
        <AddClientModule
          firmID={firmID}
          advisorID={advisorID}
          client={client}
          handleSetClient={handleSetClient}
          handleSetClientID={handleSetClientID}
          nextStep={handleNextStep}
        />

        {sendingDisclosures && <Loader message="Sending disclosures..." />}
      </Wrapper>
    )
  }
)

export default AddClientPage

const Header = styled.div`
  ${fonts.heading};
  font-style: italic;
  text-align: center;
  margin: 20px;
`

const Wrapper = styled.div`
  margin: auto;
  justify-content: center;
  align-content: center;
  max-width: 600px;
  align-self: center;
  height: 100%;
  padding: 25px 0;
`
