import React, { memo, useState, useEffect } from 'react'
import { Button, Message } from 'semantic-ui-react'
import styled from 'styled-components'
import { fonts } from 'styles'
import Loader from 'components/partials/Loader'
import { LoaderHeader } from './FinalizePage'
import Error from 'components/partials/Error'
import updateClientInDB from 'actions/db/clients/update'
import { addForm, updateForm } from 'actions/db/forms'
import { prepClientForDB } from 'helpers/db'
import { updateUserTaskStatus } from 'actions/db/userTaskActions'
import { getEnv } from 'helpers/_utils'
import FinalizeSigningEventOptions from '../partials/FinalizeSigningEventOptions'
import FinalizeSigningPage from './FinalizeSigningPage'
import { handleDocusignFinalize } from 'actions/forms/handleFinalize'

const RoundTripSaveProgressPage = memo(
  ({
    templateID,
    template,
    clientID,
    client,
    firmID,
    advisor,
    firm,
    additionalDocuments,
    flow,
    formID,
    nextStep,
    label,
    taskID,
    successPageDetails,
    finalizingAtEnd,
    docSigningSessionEnvelopeID,
    setDocSigningSessionEnvelopeID,
    removePendingChanges = false,
  }) => {
    const [processing, setProcessing] = useState(false)
    const [processingMessage, setProcessingMessage] = useState('Please wait...')
    const [formFinalizeType, setFormFinalizeType] = useState(null)
    const [finalizeType, setFinalizeType] = useState(null)
    const [formSaveSuccess, setFormSaveSuccess] = useState(null)
    const [saveSuccess, setSaveSuccess] = useState(null)
    const [docSubmitSuccess, setDocSubmitSuccess] = useState(null)
    const [
      docSigningSessionSignersLoading,
      setDocSigningSessionSignersLoading,
    ] = useState(null)
    const [docSignSuccess, setDocSignSuccess] = useState(null)
    const [formSendSuccess, setFormSendSuccess] = useState(null)
    const [errors, setErrors] = useState([])

    // Increase step to confirmation page (for form finalization options)
    useEffect(() => {
      switch (formFinalizeType) {
        case 'send':
          if (formSaveSuccess && formSendSuccess) {
            nextStep()
          }
          break
        case 'save':
          if (formSaveSuccess) {
            nextStep()
          }
          break
        default:
          break
      }
    }, [formFinalizeType, formSaveSuccess, formSendSuccess, nextStep])

    // Handle signers loading for signing sessions
    useEffect(() => {
      if (docSigningSessionEnvelopeID) {
        setDocSigningSessionSignersLoading(false)
      }
    }, [docSigningSessionEnvelopeID])

    // Increase step to confirmation page (for finalization options)
    useEffect(() => {
      if (finalizingAtEnd) {
        switch (finalizeType) {
          case 'send':
            if (saveSuccess && docSubmitSuccess) {
              nextStep()
            }
            break
          case 'sign':
          case 'finalize-preview':
            if (saveSuccess && docSignSuccess) {
              nextStep()
            }
            break
          case 'save':
            if (saveSuccess) {
              nextStep()
            }
            break
          default:
            break
        }
      }
    }, [
      docSignSuccess,
      docSubmitSuccess,
      finalizeType,
      finalizingAtEnd,
      nextStep,
      saveSuccess,
    ])

    const handleFormFinalize = async (type) => {
      setFormFinalizeType(type)
      setProcessing(true)
      setErrors([])

      await handleSave()

      switch (type) {
        case 'send':
          await handleSendForm()
          break
        case 'save':
          break
        default:
          break
      }

      setProcessing(false)
    }

    const handleSendForm = async () => {
      let formDoc
      switch (label) {
        case 'Send to Client':
          console.log('Advisor completed initial input!')
          console.log(`Sending to client for additional details and review...`)

          // Add in-progress form to db
          formDoc = {
            templateID,
            clientID,
            advisorID: client.advisor,
            firmID,
            dateSent: new Date(),
            reviewers: {
              client: {
                name: client.legal_name,
                email: client.email,
              },
              advisor: {
                name: advisor.name,
                email: advisor.email,
              },
            },
            currentReviewer: 'client',
            status: 'Sent',
            env: getEnv(true),
          }

          const addFormRes = await addForm({
            formDoc,
            setFormSendSuccess,
            setErrors,
          })

          if (addFormRes) {
            const { status: resStatus } = addFormRes
            if (resStatus && 'sent' === resStatus) {
              setFormSendSuccess(true)
            } else {
              setFormSendSuccess(false)
            }
          }

          break
        case 'Return to Advisor':
          console.log('Client completed their review and additional details!')
          console.log(`Sending to advisor for review and finalization...`)

          // Update signer status in DB
          formDoc = {
            currentReviewer: 'advisor',
          }

          const updateFormRes = await updateForm({
            formID,
            formDoc,
            setFormSendSuccess,
            setErrors,
          })

          if (updateFormRes) {
            setFormSendSuccess(true)

            // TODO: [BTS-806] Improve marking user task as complete
            // Mark task as complete
            setTimeout(async () => {
              await updateUserTaskStatus('client', clientID, taskID, 'complete')
            }, 10000)
          } else {
            setFormSendSuccess(false)
          }
          break
        default:
          break
      }
    }

    const handleSave = async () => {
      // Save to the database
      console.log('Saving pending client to the database...')

      // Remove tax ID and repeaters before saving to DB/upserting
      const dbClient = prepClientForDB(client, true)

      const updateClientInDBRes = await updateClientInDB({
        clientID,
        client: dbClient,
        pending: true,
      })

      if (updateClientInDBRes) {
        const { type: resType, error: resError } = updateClientInDBRes
        if ('success' === resType) {
          console.log('Success!')
          setFormSaveSuccess(true)
        }

        if ('error' === resType) {
          setFormSaveSuccess(false)
          if (resError) {
            console.error(`Error updating pending client in DB: ${resError}`)
            setErrors([resError])
          }
        }
      }

      return updateClientInDBRes
    }

    const handleFinalize = (
      type,
      envelopeIDFromPreview = null,
      updateEnvelope = null
    ) => {
      handleDocusignFinalize({
        type,
        envelopeIDFromPreview,
        updateEnvelope,
        templateID,
        template,
        flow,
        clientID,
        client,
        advisor,
        firm,
        formID,
        taskID,
        additionalDocuments,
        setFinalizeType,
        setProcessing,
        setProcessingMessage,
        setErrors,
        setDocSigningSessionSignersLoading,
        setDocSigningSessionEnvelopeID,
        setDocSubmitSuccess,
        setRoundTripFinalizeSuccess: () => {},
        setSaveSuccess,
        removePendingChanges,
      })
    }

    const handleSetDocSignSuccess = (data) => {
      setDocSignSuccess(data)
    }

    if (docSigningSessionEnvelopeID) {
      return (
        <FinalizeSigningPage
          envelopeID={docSigningSessionEnvelopeID}
          signersLoading={docSigningSessionSignersLoading}
          handleSetDocSignSuccess={handleSetDocSignSuccess}
          preview={finalizeType === 'preview'}
          nextStep={nextStep}
          handleFinalizePageFinalize={handleFinalize}
        />
      )
    }

    let offerSaveAndExit = true
    if (successPageDetails && successPageDetails.isClient) {
      offerSaveAndExit = false
    }

    return (
      <Wrapper data-step={label}>
        {processing ? (
          <>
            <Loader message={processingMessage} fullScreen>
              <LoaderHeader as="h2">You did it!</LoaderHeader>
              <p>
                We are generating your document now. This might take a minute.
              </p>
            </Loader>
          </>
        ) : (
          <>
            <Header>What would you like to do next?</Header>

            <ButtonWrapper>
              <ButtonGroup vertical labeled icon data-id="finalize-buttons">
                <Button
                  content="Send for review"
                  icon="send"
                  primary
                  size="big"
                  onClick={() => handleFormFinalize('send')}
                  disabled={processing}
                  loading={
                    processing &&
                    formFinalizeType &&
                    formFinalizeType === 'send'
                  }
                />
                {finalizingAtEnd && (
                  <FinalizeSigningEventOptions
                    processing={processing}
                    finalizeType={finalizeType}
                    handleFinalize={handleFinalize}
                  />
                )}
                {offerSaveAndExit && (
                  <Button
                    content="Save and exit"
                    icon="check"
                    primary
                    size="big"
                    onClick={() => handleFormFinalize('save')}
                    disabled={processing}
                    loading={
                      processing &&
                      formFinalizeType &&
                      formFinalizeType === 'save'
                    }
                  />
                )}
              </ButtonGroup>
            </ButtonWrapper>

            {!!(errors && errors.length) &&
              errors.map((error, eidx) => (
                <Message key={eidx} negative>
                  <Message.Header>
                    We're sorry, something went wrong.
                  </Message.Header>
                  <p>
                    Please try again and contact Bitsy if the problem persists.
                  </p>

                  <p>
                    <small>
                      <strong>Details:</strong>
                    </small>
                  </p>
                  <Error
                    message={error.message}
                    code={`TMPL-${templateID}`}
                    level="error"
                    errorInfo={{
                      Location: 'Template Form Round Trip Save Progress Page',
                      Details: error,
                    }}
                    userInfo={{
                      'Template ID': templateID,
                      'Firm ID': client.firm,
                      'Advisor ID': client.advisor,
                      'Client ID': clientID,
                      Client: client,
                    }}
                  />
                </Message>
              ))}
          </>
        )}
      </Wrapper>
    )
  }
)

export default RoundTripSaveProgressPage

const Header = styled.div`
  ${fonts.heading};
  font-style: italic;
  text-align: center;
  margin: 20px;
`

const Wrapper = styled.div`
  margin: auto;
  justify-content: center;
  align-content: center;
  max-width: 600px;
  align-self: center;
  height: 100%;
  padding: 25px 0;
`

const ButtonWrapper = styled.div`
  text-align: center;
`

const ButtonGroup = styled(Button.Group)`
  margin: 0 auto !important;
`
