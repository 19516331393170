import { firebaseFunctionsCustomDomain } from '../../../config/firebaseConfig'

const integrationsDocusignSignEnvelope = firebaseFunctionsCustomDomain.httpsCallable(
  'integrationsDocusignSignEnvelope'
)

export default async function signDocument(
  templateID,
  docType,
  clientID,
  client,
  advisor,
  firm,
  additionalDocuments,
  preview = false,
  setEnvelopeID,
  setErrors = null
) {
  try {
    const result = await integrationsDocusignSignEnvelope({
      templateID,
      docType,
      clientID,
      client,
      advisor,
      firm,
      additionalDocuments,
      preview,
    })
    setEnvelopeID(result.data.envelopeId)
    return result.data
  } catch (err) {
    console.error(err)
    if (setErrors) {
      setErrors([err])
    }
  }

  return null
}
