import React, { memo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { RetryButton } from '../'
import { RetryIcon } from 'components/icons'
import { resendSigningEmail } from 'actions/integrations/docusign'
import { getRecipientSignerID } from 'actions/integrations/docusign/_utils'
import { useFirm, useUser } from 'contexts'

export default memo(function ResendSigningEmailButton({
  envelopeID,
  signerRole,
  recipient,
  signerStatus,
  disabledByOrder,
}) {
  const { userType } = useUser()
  const { firmID } = useFirm()

  const [resendRequested, setResendRequested] = useState(false)
  const [resent, setResent] = useState(null)

  const handleResendSigningEmail = async () => {
    setResendRequested(true)

    let recipientObject = recipient
    recipientObject['recipientId'] =
      recipient.recipientId || getRecipientSignerID(signerRole)

    const resendResult = await resendSigningEmail({
      envelopeID,
      recipient: recipientObject,
      firm: firmID,
    })

    setResent(resendResult)

    return resendResult
  }

  if (
    signerStatus === 'Signed' ||
    (userType === 'client' &&
      signerRole !== 'client_1' &&
      signerRole !== 'client_2')
  ) {
    return ''
  }

  return (
    <>
      <RetryButton
        onClick={handleResendSigningEmail}
        disabled={
          disabledByOrder ||
          (resendRequested && resent === null) ||
          (resendRequested && resent)
        }
        data-tip={`Lost the signing link email for ${recipient.name}? Click here to send a new one.`}
        loading={resendRequested && !resent}
        compact
        basic
      >
        {resent ? 'Sent!' : <RetryIcon />}
      </RetryButton>
      <ReactTooltip place="right" type="info" effect="solid" />
    </>
  )
})
