import React, { memo, useState, useEffect } from 'react'
import {
  Button,
  Checkbox,
  Select,
  Table as SemanticTable,
  Icon,
} from 'semantic-ui-react'
import {
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  useFilters,
} from 'react-table'
import { generateSelectedRowIds, useNoRowSelect } from './_helpers'
import styled from 'styled-components'
import { colors } from 'styles'

const RowSelectCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <CheckboxContainer>
        <Checkbox ref={resolvedRef} {...rest} />
      </CheckboxContainer>
    )
  }
)

const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

const Table = memo(
  ({
    className = '',
    columns = [],
    data = [],
    showPagination = true,
    handleRowSelect = () => {},
    selectedRows = [],
    noRowsFoundText = 'Nothing is here yet!',
    noRowsFoundAction = null,
    pageSize: initialPageSize = 10,
    sortBy = [],
  }) => {
    const getColumnStyle = (column) => column.style || {}

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )

    const showRowSelection =
      handleRowSelect &&
      typeof handleRowSelect === 'function' &&
      handleRowSelect.toString() !== '() => {}' &&
      handleRowSelect.toString() !== 'function(){}'

    const tableInstance = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          pageSize: initialPageSize,
          selectedRowIds: generateSelectedRowIds(selectedRows),
          sortBy,
        },
      },
      useFilters,
      useSortBy,
      usePagination,
      showRowSelection ? useRowSelect : useNoRowSelect,
      (hooks) => {
        if (showRowSelection) {
          hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <RowSelectCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                  label="Select All"
                />
              ),
              Cell: ({ row }) => (
                <RowSelectCheckbox {...row.getToggleRowSelectedProps()} />
              ),
              style: {
                maxWidth: '150px !important',
              },
            },
            ...columns,
          ])
        }
      }
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      pageOptions,
      state: { pageIndex, pageSize },
      previousPage,
      nextPage,
      setPageSize,
      canPreviousPage,
      canNextPage,
      selectedFlatRows,
    } = tableInstance

    useEffect(() => {
      if (showRowSelection) {
        handleRowSelect(selectedFlatRows)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFlatRows])

    const [tableHasFilters, setTableHasFilters] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    useEffect(() => {
      let newTableHasFilters = false
      if (!newTableHasFilters && headerGroups) {
        headerGroups.forEach((headerGroup) => {
          if (!newTableHasFilters) {
            headerGroup.headers.forEach((column) => {
              if (!newTableHasFilters && column.canFilter) {
                newTableHasFilters = true
              }
            })
          }
        })
      }

      if (newTableHasFilters) {
        setTableHasFilters(newTableHasFilters)
      }
    }, [headerGroups])

    return (
      <TableContainer className={className}>
        {tableHasFilters && (
          <TableOptions>
            <FilterButton
              icon="filter"
              compact
              basic
              onClick={() =>
                setShowFilters((prevShowFilters) => !prevShowFilters)
              }
            />
          </TableOptions>
        )}
        <StyledTable basic {...getTableProps()}>
          <TableHeader>
            {headerGroups.map((headerGroup) => (
              <HeaderTableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <SemanticTable.HeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${column.getHeaderProps().className || ''} ${
                      column.headerClassName || ''
                    }`}
                    style={{
                      ...column.getHeaderProps.style,
                      ...getColumnStyle(column),
                    }}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SortIcon name="triangle down" />
                        ) : (
                          <SortIcon name="triangle up" />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                    {/* Render the columns filter UI */}
                    {showFilters && (
                      <FilterContainer>
                        {column.canFilter ? column.render('Filter') : null}
                      </FilterContainer>
                    )}
                  </SemanticTable.HeaderCell>
                ))}
              </HeaderTableRow>
            ))}
          </TableHeader>

          <SemanticTable.Body {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps({
                          className: cell.column.className,
                        })}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
            {page.length === 0 && (
              <NoRowsFoundRow>
                <TableCell
                  colSpan={
                    headerGroups &&
                    headerGroups[0] &&
                    headerGroups[0].headers &&
                    headerGroups[0].headers.length
                      ? headerGroups[0].headers.length
                      : 100
                  }
                >
                  <span className="instructional">
                    <small>{noRowsFoundText}</small>
                  </span>

                  {noRowsFoundAction && (
                    <>
                      <br />
                      <small>{noRowsFoundAction}</small>
                    </>
                  )}
                </TableCell>
              </NoRowsFoundRow>
            )}
          </SemanticTable.Body>
          {showPagination && pageOptions && pageOptions.length > 1 && (
            <SemanticTable.Footer>
              <PaginationRow>
                <PaginationCell colSpan={columns.length}>
                  <Pagination>
                    <div>
                      <Button
                        content="Previous"
                        icon="arrow left"
                        labelPosition="left"
                        size="tiny"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      />
                      <div>
                        Page{' '}
                        <em>
                          {pageIndex + 1} of {pageOptions.length}
                        </em>
                      </div>
                      <Button
                        content="Next"
                        icon="arrow right"
                        labelPosition="right"
                        size="tiny"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      />
                    </div>
                    <PaginationSelect
                      placeholder="Select items per page"
                      search
                      value={pageSize}
                      options={[10, 20, 30, 40, 50].map((pageSize) => ({
                        key: pageSize,
                        value: pageSize,
                        text: `Show ${pageSize} per page`,
                      }))}
                      onChange={(e, { value }) => {
                        setPageSize(Number(value))
                      }}
                    />
                  </Pagination>
                </PaginationCell>
              </PaginationRow>
            </SemanticTable.Footer>
          )}
        </StyledTable>
      </TableContainer>
    )
  }
)

export default Table

const TableContainer = styled.div`
  margin-top: 16px;
`

const TableOptions = styled.div`
  text-align: right;
`

const FilterButton = styled(Button)`
  &&&& {
    box-shadow: none !important;

    &:hover,
    &:focus {
      box-shadow: none !important;
    }
  }
`

const StyledTable = styled(SemanticTable)`
  &&& {
    table-layout: fixed;
    font-size: 16px;
    border: none;
    color: ${colors.grayHeadline};
    margin-top: 0;

    & th {
      text-align: center;
      text-transform: uppercase;
    }
  }
`

const TableHeader = styled(SemanticTable.Header)`
  &&& {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`

// TODO: [BTS-700] Generalize TableRow styling
const TableRow = styled(SemanticTable.Row)`
  &&&& {
    display: table-row;
    font-size: 16px;
    overflow-x: auto;

    & th,
    & td {
      display: table-cell;
      vertical-align: middle;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media only screen and (max-width: 767px) {
      box-shadow: none !important;
      border-bottom-color: #f0f0f0 !important;
      text-align: center;

      button {
        text-align: center;
      }

      & td:empty {
        padding: 0 !important;
      }
    }
  }
`

const HeaderTableRow = styled(TableRow)`
  &&& {
    & th {
      border-top: 0;
      text-transform: uppercase;
      font-size: 12px;
      color: #000;
      letter-spacing: 1px;
      font-weight: bold;
      margin-bottom: 10px !important;
      text-align: left;
    }
  }
`

const SortIcon = styled(Icon)`
  &&& {
    margin-left: 3px;
  }
`

const FilterContainer = styled.div`
  margin-top: 3px;
`

const TableCell = styled(SemanticTable.Cell)`
  &&& {
    overflow: visible;
  }
`

const NoRowsFoundRow = styled(TableRow)`
  text-align: center;
`

const PaginationRow = styled(SemanticTable.Row)`
  &&& {
    display: table-row;

    & th {
      border-top-color: rgba(0, 0, 0, 0.1);
    }
  }
`

const PaginationCell = styled(SemanticTable.HeaderCell)``

const Pagination = styled.div`
  display: block !important;
  text-align: center;
  font-size: 14px;

  & > * {
    margin-bottom: 10px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 25%;

      &:nth-child(2) {
        flex-basis: 50%;
        flex-grow: 1;
      }
    }
  }

  &.ui.form > select {
    display: inline-block !important;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
`

const PaginationSelect = styled(Select)`
  &&& {
    width: auto;
  }
`

const CheckboxContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  display: block;
  justify-content: center;
  max-width: 150px;
`
