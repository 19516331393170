import React, { PureComponent } from 'react'
import { Button } from 'semantic-ui-react'
import Modal from 'components/partials/LegacyModal'
import Card from 'components/dashboards/partials/LegacyCard'
import { PersonIcon } from 'components/icons'
import {
  getFirmAdvisors,
  stopListeningToFirmAdvisors,
} from 'actions/clientDbActions'
import { updateClient } from 'actions/dbActions'
import { custodian_options } from 'config/options'
import { validEmailRegex } from 'config/regexPatterns'
import { clientInitialState } from 'config/iacConfig'
import DocumentReviewModal from 'components/partials/LegacyDocumentReviewModal'
import { validateClientFields } from 'actions/iacHelpers'
import ExecutedContractsModule from 'components/dashboards/partials/ExecutedContractsModule'
// import { deleteClient } from 'actions/db/clientActions'
import deleteClient from 'actions/db/clients/delete'
import ClientForm from 'components/forms/LegacyClientForm'
import TemplateFormsExecutedContractsModules from 'components/dashboards/partials/TemplateFormsExecutedContractsModules'
import TemplateForm from 'components/forms/TemplateForm'
import { formatFirestoreDateString } from 'actions/db/_utils'
import { isLegacyFirm, isDevFirm } from 'config/firmConfig'
import styled from 'styled-components'
import SendDocumentsDropdown from './partials/SendDocumentsDropdown'
import UpdateClientEmailConfirm from './partials/UpdateClientEmailConfirm'
import { userIsFirm } from 'helpers/users'
import { Row, ColSm12, ColSm6ColLg4, ColSm12ColLg8 } from 'components/styled'
import ClientProfileTabs from './partials/ClientProfileTabs'
import { formatCompositeInvestorNames } from 'actions/helpers'

const initialState = {
  advisors: null,
  client: null,
  editing: false,
  editingType: null,
  hasEdited: false,
  show_iac: false,
  iacReviewType: null,
  show_suitability: false,
  suitabilityReviewType: null,
  errors: [],
  addSuccess: false,
  showAddSuccess: false,
  suitabilityAddSuccess: false,
  openDeleteConfirmModal: false,
  deleteMessage: '',
  editingTemplateID: '',
  editingTemplate: null,
  showEditEmailModal: false,
}
class ClientProfile extends PureComponent {
  constructor(props) {
    super(props)
    this.state = initialState

    // this.handleCancel = this.handleCancel.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.showIAC = this.showIAC.bind(this)
    this.hideIAC = this.hideIAC.bind(this)
    this.showSuitability = this.showSuitability.bind(this)
    this.hideSuitability = this.hideSuitability.bind(this)
    this.setEditingTemplate = this.setEditingTemplate.bind(this)
    this.toggleEditor = this.toggleEditor.bind(this)
    this.validate = this.validate.bind(this)
  }

  componentDidMount() {
    if (!this.props.advisors) {
      getFirmAdvisors(this.props.profile.firm, this)
    } else {
      this.setState({
        advisors: this.props.advisors,
      })
    }
  }

  componentWillUnmount() {
    if (!this.props.advisors) {
      stopListeningToFirmAdvisors()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { addSuccess, suitabilityAddSuccess } = this.state

    // Stop editing on add success
    if (
      (addSuccess && !prevState.addSuccess) ||
      (suitabilityAddSuccess && !prevState.suitabilityAddSuccess)
    ) {
      const _this = this

      this.setState(
        {
          editing: false,
          editingType: null,
          addSuccess: false,
          showAddSuccess: true,
          iacReviewType: null,
          show_iac: false,
        },
        function () {
          setTimeout(function () {
            _this.setState({
              showAddSuccess: false,
            })
          }, 3000)
        }
      )
    }
  }

  async deleteClient(uid) {
    const result = await deleteClient(uid)

    console.log(result)
    if (!result) {
      console.error('Error deleting client, check the logs')
      return
    }

    if (result.type === 'failure') {
      console.error(result.message)
      // setNotifications([
      //   ...notifications,
      //   { type: 'error', message: result.message }
      // ])
    }

    this.setState({
      openDeleteConfirmModal: false,
      deleteMessage: result.message,
    })

    // setNotifications([
    //   ...notifications,
    //   { type: 'success', message: 'Client deleted successfully' }
    // ])
  }

  // handleCancel(event) {

  //   this.setState({
  //     client: this.props.client,
  //     iac: initialIACState,
  //     errors: []
  //   }, function() {
  //     this.setState({
  //       editing: false
  //     })
  //   })
  // }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'custodian') {
      // Custodian state changes
      this.setState({
        client: {
          ...this.state.client,
          custodian: custodian_options[value],
        },
      })
    } else {
      // Client state changes
      this.setState({
        client: {
          ...this.state.client,
          [name]: value,
        },
      })
    }
  }

  handleSave(saveType) {
    if (this.state.errors.length === 0) {
      const { client, editingType } = this.state
      const clientID = this.props.uid

      // IAC sign / send
      if (editingType === 'iac') {
        if (saveType === 'sign') {
          this.showIAC('sign')
        }

        if (saveType === 'send') {
          this.showIAC('send')
        }
      }

      // Suitability send
      if (editingType === 'suitability') {
        this.showSuitability('send')
      }

      if (saveType === 'save') {
        // Update client in database
        updateClient(client, this, clientID)
      }
    }

    return
  }

  showIAC(type) {
    this.setState({
      show_iac: true,
      iacReviewType: type,
    })
  }

  hideIAC(event) {
    event.preventDefault()

    this.setState({
      show_iac: false,
    })
  }

  showSuitability(type) {
    this.setState({
      show_suitability: true,
      suitabilityReviewType: type,
    })
  }

  hideSuitability(event) {
    event.preventDefault()

    this.setState({
      show_suitability: false,
    })
  }

  setEditingTemplate({ templateID, template }) {
    this.setState({
      editingTemplateID: templateID,
      editingTemplate: template,
    })
  }

  toggleEditor(event, type = 'iac') {
    const { profile } = this.props

    var editingType = type
    // Reset editing type if already editing
    if (this.state.editing && this.state.editingType) {
      editingType = null
    }

    if (type === 'suitability') {
      this.setState((prevState) => ({
        editingType: editingType,
        hasEdited: true,
        client: Object.assign({}, clientInitialState, profile),
        errors: [],
      }))
    } else {
      this.setState((prevState) => ({
        editing: !this.state.editing,
        editingType: editingType,
        hasEdited: true,
        client: Object.assign({}, clientInitialState, profile),
        errors: [],
      }))
    }
  }

  validate(event, saveType) {
    event.preventDefault()

    const { client } = this.state
    const { firm } = this.props

    var errors = []

    // Email
    if (!validEmailRegex.test(client.email)) {
      errors.push('Please enter a valid email address.')
    }

    // IAC
    if (saveType === 'sign' || saveType === 'send') {
      // Validate all fields
      errors = errors.concat(validateClientFields(client, firm.name))
    }

    // Set state and increase step
    this.setState(
      {
        errors: errors,
      },
      () => {
        this.handleSave(saveType)
      }
    )
  }

  render() {
    const {
      advisor,
      profile,
      uid,
      userType,
      firmDisclosures,
      firm,
      advisorDisclosures,
    } = this.props
    const {
      advisors,
      client,
      editing,
      editingType,
      errors,
      editingTemplateID,
      editingTemplate,
    } = this.state

    this.errors = errors.map((error, key) => <li key={key}>{error}</li>)

    if (profile && advisors) {
      var advisorName = ''
      if (advisors[profile.advisor]) {
        advisorName = advisors[profile.advisor].name
      } else if (advisor) {
        advisorName = advisor
      } else if (profile.advisor_name) {
        advisorName = profile.advisor_name
      }

      var portfolio_value_options = []
      if (firm) {
        portfolio_value_options = firm.annual_fees
      }

      if (portfolio_value_options) {
        this.portfolio_value_choices = portfolio_value_options.map(function (
          annualFee,
          key
        ) {
          var range = annualFee.portfolio_min + ' - ' + annualFee.portfolio_max

          if (annualFee.portfolio_max === '+') {
            range = annualFee.portfolio_min + '+'
          }

          return (
            <option key={key} value={range}>
              {range}
            </option>
          )
        })
      } else {
        this.portfolio_value_choices = ''
      }

      if (advisors && Object.keys(advisors).length > 0) {
        this.advisor_choices = Object.keys(advisors).map(function (
          advisor,
          key
        ) {
          return (
            <option key={advisor} value={advisor}>
              {advisors[advisor].name}
            </option>
          )
        })
      } else {
        this.advisor_choices = ''
      }

      var editingTypeName = ''
      if (editingType === 'iac') {
        editingTypeName = 'IAC'
      }
      if (editingType === 'suitability') {
        editingTypeName = 'Suitability Form'
      }

      if (userType === 'super-admin') {
        console.log(uid)
      }

      const firmID = profile.firm

      return (
        <div className={editing ? 'profile editing' : 'profile'}>
          <Row className="row">
            <ColSm12ColLg8>
              <Card
                title="Client"
                counter={formatCompositeInvestorNames({ client: profile })}
                icon={<PersonIcon />}
                type="statistic"
              />
            </ColSm12ColLg8>
          </Row>
          <Row className="row">
            <ColSm6ColLg4>
              <Card
                title="Advisor"
                counter={advisorName}
                icon={<PersonIcon />}
                type="statistic"
              />
            </ColSm6ColLg4>
            <ColSm6ColLg4>
              <Card
                title="Date Onboarded"
                counter={
                  profile.dateAdded &&
                  formatFirestoreDateString(profile.dateAdded)
                }
                icon={<PersonIcon />}
                type="statistic"
              />
            </ColSm6ColLg4>
            {/* {advisorName.length && <h2><span className="label">Advisor: </span>{advisorName}</h2>} */}
            {/* {profile.dateAdded && <div className="date-added"><span className="label">Onboarded: </span>{}</div>} */}
          </Row>

          <Row className="row">
            <ColSm12ColLg8>
              <ClientProfileTabs
                client={profile}
                clientProfile={this}
                formsTabContent={
                  <ExecutedContractsModuleRow className="row">
                    {firmID && isLegacyFirm(firmID) && (
                      <>
                        <ExecutedContractsModule
                          type="iac"
                          title={'Executed Contracts, Disclosures Delivery'}
                          documents={profile.iacs}
                          document={profile.iac_file}
                          clientID={uid}
                          wrapperClassName="col-sm-12 col-md-6"
                        >
                          <AddFormButton
                            primary
                            className="edit"
                            onClick={(e) => this.toggleEditor(e)}
                          >
                            Edit
                          </AddFormButton>
                        </ExecutedContractsModule>

                        <ExecutedContractsModule
                          type="suitability"
                          title={'Suitability Forms'}
                          documents={profile.suitability_forms}
                          clientID={uid}
                          hasPendingSuitabilityForm={
                            profile.hasPendingSuitabilityForm
                              ? 'This client has a pending suitability form.'
                              : null
                          }
                          wrapperClassName="col-sm-12 col-md-6"
                        >
                          <AddFormButton
                            primary
                            className="edit"
                            onClick={(e) => this.toggleEditor(e, 'suitability')}
                          >
                            Edit
                          </AddFormButton>
                        </ExecutedContractsModule>

                        <ExecutedContractsModule
                          type="k401"
                          title={
                            'Executed 401(k) Contracts, Disclosures Delivery'
                          }
                          clientID={uid}
                          wrapperClassName="col-sm-12 col-md-6"
                        >
                          <AddFormButton
                            primary
                            className="edit"
                            onClick={(e) => this.toggleEditor(e, 'k401')}
                          >
                            Edit
                          </AddFormButton>
                        </ExecutedContractsModule>
                      </>
                    )}

                    <TemplateFormsExecutedContractsModules
                      clientID={uid}
                      setEditingTemplate={this.setEditingTemplate}
                      wrapperClassName="col-sm-12 col-md-6"
                    />
                  </ExecutedContractsModuleRow>
                }
                actionsTabContent={
                  <>
                    {(firmDisclosures || advisorDisclosures) && (
                      <SendDocumentsRow className="row">
                        <ColSm12>
                          <Card title="Send Documents">
                            <SendDocumentsDropdown
                              client={{
                                name: profile.legal_name,
                                email: profile.email,
                                uid,
                              }}
                              advisor={
                                userIsFirm(userType)
                                  ? null
                                  : {
                                      name: advisors[profile.advisor].name,
                                      email: advisors[profile.advisor].email,
                                    }
                              }
                              advisorID={profile.advisor}
                              firmDisclosures={firmDisclosures}
                              advisorDisclosures={advisorDisclosures}
                            />
                          </Card>
                        </ColSm12>
                      </SendDocumentsRow>
                    )}

                    {(userType === 'super-admin' || isDevFirm(firmID)) && (
                      <DeleteClientRow>
                        <ColSm12>
                          <Card title="Delete Client">
                            {this.state.deleteMessage ? (
                              <p>{this.state.deleteMessage}</p>
                            ) : (
                              <>
                                <p>
                                  Permanently delete the client from Bitsy. This
                                  is not reversible!
                                </p>
                                <Button
                                  negative
                                  className="ml-auto inline-block"
                                  onClick={(e) =>
                                    this.setState({
                                      openDeleteConfirmModal: true,
                                    })
                                  }
                                  size="small"
                                  compact
                                >
                                  Delete Client
                                </Button>

                                <Modal
                                  isOpen={this.state.openDeleteConfirmModal}
                                  handleClose={() =>
                                    this.setState({
                                      openDeleteConfirmModal: false,
                                    })
                                  }
                                >
                                  <p>
                                    Are you sure you want to delete{' '}
                                    <strong>
                                      {profile.legal_name || profile.email}
                                    </strong>
                                    ?
                                  </p>
                                  <div className="ml-auto">
                                    <button
                                      className="btn-danger"
                                      onClick={() => this.deleteClient(uid)}
                                    >
                                      Yes, delete
                                    </button>
                                    <button
                                      onClick={() =>
                                        this.setState({
                                          openDeleteConfirmModal: false,
                                        })
                                      }
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </Modal>
                              </>
                            )}
                          </Card>
                        </ColSm12>
                      </DeleteClientRow>
                    )}
                  </>
                }
              />
            </ColSm12ColLg8>
          </Row>

          <div className="modules">
            {/* {editing ?
              <div className="save-options">

                {errors.length > 0 ?
                  <div className="error-container">
                    <ul className="errors">
                      {this.errors}
                    </ul>
                  </div>
                  : ''}

                {editingType === 'iac' ?
                  <button className="save" onClick={(event) => this.validate(event, 'sign')}>
                    Save changes and Sign{editingTypeName && editingTypeName.length ? ' ' + editingTypeName : ''}
                  </button>
                  : ''}

                <button className="save" onClick={(event) => this.validate(event, 'send')}>
                  Save changes and Send{editingTypeName && editingTypeName.length ? ' ' + editingTypeName : ''}
                </button>

                <button className="save" onClick={(event) => this.validate(event, 'save')}>
                  Save changes only (don't regenerate{editingTypeName && editingTypeName.length ? ' ' + editingTypeName : ''})
                    </button>

                <button className="cancel" onClick={this.toggleEditor}>
                  Cancel
                </button>
              </div>
              : ''} */}
          </div>

          <DocumentReviewModal
            documentType="iac"
            documentTypeName={editingTypeName}
            isOpen={editing && this.state.show_iac}
            handleModalClose={this.hideIAC}
            source="profile"
            location="dashboard"
            type={this.state.iacReviewType}
            firmID={profile.firm}
            firm={firm}
            advisor={advisors[profile.advisor]}
            client={client}
            firmDisclosures={firmDisclosures}
            advisorDisclosures={advisorDisclosures}
            uid={uid}
            state={this}
          />

          <Modal
            className="add-success"
            isOpen={this.state.showAddSuccess}
            handleClose={() => this.setState({ showAddSuccess: false })}
          >
            Client updated!
          </Modal>

          <Modal
            title={`Add an IAC for ${profile.legal_name}`}
            isOpen={editingType === 'iac'}
            handleClose={() =>
              this.setState({ editing: false, editingType: null })
            }
          >
            <ClientForm
              type="iac"
              location="client-profile"
              firm={firm}
              firmID={profile.firm}
              firmAdvisors={advisors}
              client={profile}
              clientID={uid}
              advisorID={profile.advisor}
            />
          </Modal>

          <Modal
            title={`Add a Suitability Form for ${profile.legal_name}`}
            isOpen={editingType === 'suitability'}
            handleClose={() =>
              this.setState({ editing: false, editingType: null })
            }
          >
            <ClientForm
              type="suitability"
              location="client-profile"
              firm={firm}
              firmID={profile.firm}
              firmAdvisors={advisors}
              client={profile}
              clientID={uid}
              advisorID={profile.advisor}
              reviewType="send"
            />
          </Modal>

          <Modal
            title={`Add a 401(k) Agreement for ${profile.legal_name}`}
            isOpen={editingType === 'k401'}
            handleClose={() =>
              this.setState({ editing: false, editingType: null })
            }
          >
            <ClientForm
              type="k401"
              location="client-profile"
              firm={firm}
              firmID={profile.firm}
              firmAdvisors={advisors}
              client={profile}
              clientID={uid}
              advisorID={profile.advisor}
            />
          </Modal>

          <Modal
            title={`Add a ${editingTemplate?.title || 'document'} for ${
              client?.legal_name || client?.email || 'this client'
            }`}
            fullScreen={true}
            isOpen={editingTemplateID && editingTemplate ? true : false}
            handleClose={() =>
              this.setEditingTemplate({ templateID: '', template: null })
            }
            closeOnDimmerClick={false}
            closeOnEscape={false}
          >
            <TemplateForm
              templateID={editingTemplateID}
              template={editingTemplate}
              clientID={uid}
              client={profile}
              handleClose={() =>
                this.setEditingTemplate({ templateID: '', template: null })
              }
            />
          </Modal>

          <UpdateClientEmailConfirm
            open={this.state.showEditEmailModal}
            handleClose={() => this.setState({ showEditEmailModal: false })}
            clientID={uid}
          />
        </div>
      )
    } else {
      return ''
    }
  }
}

export default ClientProfile

const ExecutedContractsModuleRow = styled(Row)`
  &&& {
    .card {
      box-shadow: none;
    }

    .card .card > .content {
      padding: 0;
    }
  }
`

const SendDocumentsRow = styled(Row)`
  margin-left: auto;
  margin-right: auto;
  justify-content: flex-start !important;

  .card {
    margin-left: -15px;
    margin-right: -15px;
    box-shadow: none;
  }

  .send-button-container {
    margin-top: 20px;
    text-align: right;

    button {
      margin-right: 0;
    }
  }
`

const DeleteClientRow = styled(Row)`
  text-align: left;

  .card {
    box-shadow: none;
  }
`

const AddFormButton = styled(Button)`
  &&& {
    display: block;
    margin-left: auto;
    margin-top: 30px;
    margin-right: 0;
  }
`
