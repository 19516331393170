import React, { Component } from 'react'
import Field from '../forms/Field'

class FeeSchedule extends Component {
  render() {
    const { rows, fixedFee, editable, handleInputChange } = this.props

    this.feeScheduleRows = null
    if (fixedFee) {
      this.feeScheduleRows = (
        <li>
          <div>Fixed Fee</div>
          <div>{fixedFee}</div>
        </li>
      )
    } else {
      this.feeScheduleRows = rows.map((line, key) => (
        <li key={key}>
          {line.portfolio_max === '+' ? (
            <div>{line.portfolio_min}+</div>
          ) : (
            <div>
              {line.portfolio_min} - {line.portfolio_max}
            </div>
          )}
          <div>
            {editable ? (
              <Field
                type="percentage"
                id={'annual_fees-' + key}
                value={line.fee}
                handleInputChange={handleInputChange}
              />
            ) : (
              line.fee
            )}
          </div>
        </li>
      ))
    }

    return (
      <ul className="feeSchedule">
        <li className="header">
          <div>Assets Under Management</div>
          <div>Annual Fee</div>
        </li>
        {this.feeScheduleRows}
      </ul>
    )
  }
}

export default FeeSchedule
