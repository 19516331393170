import ReactGA from 'react-ga'
import { gaTrackingId } from '../config/analyticsConfig'

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID)
}

export const PageView = (
  pathname = window.location.pathname + window.location.search,
  userId
) => {
  initGA(gaTrackingId)
  ReactGA.set({ page: pathname, userId: userId })
  ReactGA.pageview(pathname)
}

export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  })
}
