export const sortRecipients = (recipients) => {
  const sortOrder = ['client_1', 'client_2', 'advisor', 'firm']

  const sortedRecipients = Object.keys(recipients).sort((a, b) => {
    return (
      sortOrder.indexOf(recipients[a].signerID) -
      sortOrder.indexOf(recipients[b].signerID)
    )
  })

  return sortedRecipients
}

export const prettyRecipientName = (recipientID) => {
  let prettyName

  switch (recipientID) {
    case 'client_1':
      prettyName = 'First Investor'
      break
    case 'client_2':
      prettyName = 'Second Investor'
      break
    case 'advisor':
      prettyName = 'Advisor'
      break
    case 'firm':
      prettyName = 'Firm'
      break
    default:
      prettyName = recipientID
      break
  }

  return prettyName
}

export const prettyTemplateName = ({ envelope, templates = null }) => {
  let name

  const envelopeTemplate =
    templates && templates[envelope.type] ? templates[envelope.type] : null

  if (envelopeTemplate) {
    name = envelopeTemplate.title
  } else {
    // Handle legacy templates
    switch (envelope.type) {
      default:
        name = envelope.type.toUpperCase()
        break
    }
  }

  return name
}
