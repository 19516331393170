import React, { memo } from 'react'
import { Menu, Button } from 'semantic-ui-react'
import TabIcon from './partials/TabIcon'
import BrandLogo from 'components/partials/BrandLogo'
import styled from 'styled-components'
import { colors } from 'styles'
import { useFirm, useAdvisor } from 'contexts'

export default memo(function DashboardSidebar({
  tabs,
  tabIndex,
  setTabIndex,
  setShowAddClientModule,
}) {
  const { firm } = useFirm()
  const { advisor } = useAdvisor()

  return (
    <SidebarContainer>
      <LogoLink href="/">
        <StyledBrandLogo brand={advisor || firm} />
      </LogoLink>

      <SidebarMain>
        <AddClientButton
          content="Add client"
          icon="plus circle"
          onClick={() => setShowAddClientModule(true)}
          primary
          size="large"
        />

        <Menu vertical inverted as="nav">
          {tabs.map((tab, tidx) => (
            <MenuItem
              key={tidx}
              className={tabIndex === tidx ? 'active' : null}
              data-id={`mainnav-${tab}`}
              onClick={() => setTabIndex(tidx)}
            >
              <TabIcon tab={tab} />
              <p>{tab}</p>
            </MenuItem>
          ))}
        </Menu>
      </SidebarMain>
    </SidebarContainer>
  )
})

const SidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const LogoLink = styled.a`
  background-color: #002f4b;
  display: block;
  margin: -1em;
  margin-bottom: 0;
  padding: 0.8125rem 0.5rem;
  padding-left: 35px;
  height: 54px;
  transition: width 0.3s ease-in-out;

  @media (min-width: 768px) {
    height: 70px;
  }

  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  &:hover > div {
    opacity: 0.8;
    transition: 0.3s all;
  }
`

const StyledBrandLogo = styled(BrandLogo)`
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  object-fit: contain;
  height: 100%;
  width: 100%;

  img {
    object-position: left center;
  }
`

const SidebarMain = styled.div`
  &&& {
    margin: -1em;
    margin-top: 0;
    padding: 2em 1.5em;
    padding-bottom: 0;
    background: ${colors.dark};
    flex-grow: 1;

    .menu {
      background: transparent;
      width: 100%;
    }
  }
`

const AddClientButton = styled(Button)`
  &&& {
    display: block;
    margin: 1em 0;
    margin-bottom: 3em;
    display: block;
    width: 100%;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
`

const MenuItem = styled(Menu.Item)`
  &&&& {
    display: flex;
    align-items: center;
    margin: 1.25em 0;
    border-radius: 6px !important;
    font-size: 18px;
    padding: 0.6rem 1rem;

    &,
    &:hover,
    &:focus,
    &:active {
      color: hsla(0, 0%, 100%, 0.460751);
    }

    &:first-child {
      margin-top: 0;
    }

    &::before {
      display: none;
    }

    svg {
      font-size: 1.2rem;
      text-align: center;
      width: 1.6rem;
      margin: 0 24px 0 0;
      fill: hsla(0, 0%, 100%, 0.460751);
    }

    &:hover,
    &:focus,
    &.active {
      background: #053553 !important;

      svg {
        fill: ${colors.primary};
      }
    }

    p {
      margin: 0;
    }
  }
`
