import React, { memo } from 'react'
import { quiz_questions } from '../../config/options'
import './InvestmentProfileQuizResponses.scss'

export default memo(function InvestmentProfileQuizResponses({
  investmentProfiles = null,
}) {
  if (!investmentProfiles) {
    return ''
  }

  return investmentProfiles.map((investment_profile, idx) => {
    var profile_quiz_answers = ''
    if (investment_profile.answers) {
      profile_quiz_answers = Object.keys(investment_profile.answers).map(
        (question_idx) => {
          const quiz_question_idx = question_idx.split('q')[1] - 1
          const quiz_question_object = quiz_questions[quiz_question_idx]

          return (
            <li key={question_idx}>
              <div className="question">{quiz_question_object.question}</div>
              <div className="answer">
                {
                  quiz_question_object.options[
                    investment_profile.answers[question_idx]
                  ].description
                }
              </div>
            </li>
          )
        }
      )
    }

    return (
      <div
        key={idx}
        className="quiz-questions-list investment-profile-quiz-responses"
      >
        <h6>{investment_profile.account}</h6>

        {investment_profile.answers ? (
          <ol className="quiz-questions">{profile_quiz_answers}</ol>
        ) : (
          <div className="no-quiz">
            Client waived taking the risk profile questionnaire and selected{' '}
            <br />
            <strong>{investment_profile.profile}</strong>
          </div>
        )}
      </div>
    )
  })
})
