import React, { memo, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Card from 'components/dashboards/partials/LegacyCard'
import styled from 'styled-components'
import { Button, Icon } from 'semantic-ui-react'
import Modal from 'components/partials/LegacyModal'
import Tip from 'components/partials/Tip'
import IntegrationModal from 'components/dashboards/partials/IntegrationModal'
import { CheckIcon } from 'components/icons'
import {
  integrationServices,
  renderServiceLogo,
} from 'config/integrationsConfig'
import { useFirm, useUser } from 'contexts'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useItem } from 'hooks'
import { updateIntegrationsOrder } from 'actions/db/templates'
import { isLegacyFirm } from 'config/firmConfig'
import CRMFieldMapper from 'components/forms/CRMFieldMapper'
import { ColSm6ColMd3, Row } from 'components/styled'
import { ButtonsGrid } from 'styles/_mixins'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default memo(function IntegrationsTab() {
  const { uid, userType } = useUser()
  const { firmID } = useFirm()

  const [mappingCrmFields, setMappingCrmFields] = useState(false)
  const [integrationModal, setIntegrationModal] = useState(null)

  const integrations = useItem({
    itemType: 'integration',
    uid,
    load: userType !== 'firm-admin',
  })
  const [localServices, setLocalServices] = useState([])

  useEffect(() => {
    if (integrations && integrations.order && !localServices.length) {
      setLocalServices(integrations.order)
    } else if (integrations && !integrations.order && !localServices.length) {
      setLocalServices(Object.keys(integrationServices))
    }
  }, [integrations, localServices.length])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const newOrder = reorder(
      localServices,
      result.source.index,
      result.destination.index
    )

    setLocalServices(newOrder)
    updateIntegrationsOrder(uid, newOrder)
  }

  return (
    <>
      {!isLegacyFirm(firmID) && (
        <ButtonsContainer>
          <Button
            content="CRM Field Mapper"
            icon="map signs"
            labelPosition="left"
            onClick={() => setMappingCrmFields(true)}
            disabled={userType === 'firm-admin'}
          />
        </ButtonsContainer>
      )}
      <IntegrationsCard title="Integrations" className="mb-0 buttons-grid">
        {userType === 'firm-admin' && (
          <div className="text-center instructional">
            <p>
              We are working on adding support for firm admins to update
              integrations!
              <br />
              In the meantime, please contact your main firm admin for any
              updates to integrations.
            </p>
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="grid">
            {(provided) => (
              <Row ref={provided.innerRef} {...provided.droppableProps}>
                {localServices.map((serviceID, index) => {
                  const service = integrationServices[serviceID]
                  let isActivated = integrations && serviceID in integrations
                  if (
                    isActivated &&
                    serviceID === 'Redtail' &&
                    !integrations['Redtail'].userKey
                  ) {
                    isActivated = false
                  } else if (
                    isActivated &&
                    serviceID === 'Salesforce' &&
                    !integrations['Salesforce'].token
                  ) {
                    isActivated = false
                  }
                  var className = `button-grid-button button-grid-button-${serviceID}`
                  if (isActivated) {
                    className += ' activated'
                  }

                  return (
                    <Draggable
                      key={serviceID}
                      draggableId={serviceID}
                      index={index}
                    >
                      {(provided2) => (
                        <ButtonContainer
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                        >
                          <IntegrationButton
                            className={className}
                            data-tip={
                              !service.available ? 'Coming soon!' : null
                            }
                            onClick={
                              service.available
                                ? () => setIntegrationModal(serviceID)
                                : null
                            }
                          >
                            {renderServiceLogo(service)}
                            {isActivated && (
                              <span className="badge">
                                <CheckIcon />
                              </span>
                            )}
                          </IntegrationButton>

                          {!service.available && (
                            <ReactTooltip
                              place="bottom"
                              type="info"
                              effect="solid"
                            />
                          )}

                          <div className="moveButtonContainer">
                            <Icon
                              className="moveButton"
                              name="expand arrows alternate"
                            />
                          </div>
                        </ButtonContainer>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </Row>
            )}
          </Droppable>
        </DragDropContext>

        <TipContainer>
          <Tip content="Drag and drop the services above to determine how they should be prioritized when importing client details." />
        </TipContainer>

        {integrationModal && (
          <IntegrationModal
            service={integrationModal}
            handleClose={() => {
              setIntegrationModal(null)
            }}
            passedIntegrations={integrations}
          />
        )}
      </IntegrationsCard>
      <Modal
        title={`Map CRM Fields`}
        fullScreen={true}
        isOpen={mappingCrmFields}
        handleClose={() => setMappingCrmFields(false)}
      >
        <CRMFieldMapper firmID={firmID} integrations={integrations} />
      </Modal>
    </>
  )
})

const ButtonsContainer = styled.div`
  text-align: right;
  margin-bottom: 10px;
`

const ButtonContainer = styled(ColSm6ColMd3)`
  & .moveButtonContainer {
    display: none;
    cursor: grab;
    flex-basis: 100%;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 20px;
    opacity: 0.4;
  }

  &:hover .moveButtonContainer,
  &:focus .moveButtonContainer {
    display: block;
  }
`

const IntegrationsCard = styled(Card)`
  ${ButtonsGrid()};

  .modal {
    .content {
      margin: 0 auto;
      max-width: 620px;

      img,
      .service-name {
        margin: 0 auto;
        margin-bottom: 50px;
        max-width: 360px;
      }

      form {
        max-width: 500px;
        margin: 0 auto;

        &.authForm label {
          @include visually-hidden;
        }

        .submit-section {
          button.continue {
            @include button-primary;
            margin: 0 auto;
          }
        }
      }
    }
  }
`

const IntegrationButton = styled.button`
  background: #fff;
`

const TipContainer = styled.div`
  text-align: right;
  margin-top: 30px;
`
