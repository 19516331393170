import { firebaseFunctions } from '../../config/firebaseConfig'

const emailSendFirmRegistrationConfirmations = firebaseFunctions.httpsCallable(
  'emailSendFirmRegistrationConfirmations'
)

export default async function sendFirmRegistrationConfirmations(
  firmName,
  firmEmail,
  firmContactName
) {
  try {
    const result = await emailSendFirmRegistrationConfirmations({
      firmName,
      firmEmail,
      firmContactName,
    })

    return result.data
  } catch (err) {
    console.error(err)
  }

  return null
}
