import _ from 'lodash'
import { quiz_questions } from 'config/options'

export const legacyCalculateInvestmentProfiles = ({
  form,
  value,
  handleInputChange,
}) => {
  const { schema, formData } = form

  if (
    schema &&
    schema.properties &&
    Object.keys(schema.properties) &&
    Object.keys(schema.properties).includes('investmentProfileQuizOrKnow') &&
    formData &&
    formData.investmentProfileQuizOrKnow &&
    formData.investmentProfileQuizOrKnow === "I'd like to take a quiz" &&
    formData.investment_profiles_quizzes
  ) {
    const quizzes = formData.investment_profiles_quizzes

    if (quizzes) {
      quizzes.forEach((quiz) => {
        const { account, answers } = quiz

        // Check that the quiz line an account specified and the user has answered all questions
        if (
          account &&
          Object.keys(answers) &&
          Object.keys(answers).length === quiz_questions.length
        ) {
          console.log('Calculating legacy investment profiles...')

          // Set up answer variables
          let pts = 0
          let termPts = 0

          Object.keys(answers).forEach((question, qidx) => {
            const answer = quiz_questions[qidx].options[answers[question]]

            if (answer) {
              pts += answer.pts

              if (answer.termPts) {
                termPts += answer.termPts
              }
            }
          })

          // Translate profile result
          let profile = ''
          if (pts >= 21 && pts <= 35) {
            profile = 'Conservative'
          } else if (pts >= 36 && pts <= 42) {
            profile = 'Moderate'
          } else if (pts >= 43 && pts <= 58) {
            profile = 'Aggressive'
          } else if (pts >= 59 && pts <= 64) {
            profile = 'Speculative'
          }

          // Translate time frame
          let time_frame = ''
          if (termPts >= 6 && termPts <= 10) {
            time_frame = 'Short Term'
          } else if (termPts >= 11 && termPts <= 15) {
            time_frame = 'Mid Term'
          } else if (termPts >= 16 && termPts <= 20) {
            time_frame = 'Long Term'
          }

          // Prep the new investment profiles
          let newInvestmentProfiles
          if (
            value &&
            value.investment_profiles &&
            value.investment_profiles.length
          ) {
            newInvestmentProfiles = value.investment_profiles

            const indexOfExistingAccountProfile = value.investment_profiles.findIndex(
              (profileLine) =>
                profileLine.account && profileLine.account === account
            )

            newInvestmentProfiles[indexOfExistingAccountProfile] = {
              account,
              profile,
              time_frame,
            }
          } else {
            newInvestmentProfiles = [{ account, profile, time_frame }]
          }

          const newInvestmentProfileQuizzes = _.merge(
            formData.investment_profiles_quizzes,
            newInvestmentProfiles
          )

          // Update form and local client state
          if (
            value &&
            value.investmentProfileQuizOrKnow !== "I'd like to take a quiz"
          ) {
            handleInputChange(
              'investmentProfileQuizOrKnow',
              "I'd like to take a quiz"
            )
          }

          handleInputChange(
            'investment_profiles_quizzes',
            newInvestmentProfileQuizzes
          )

          handleInputChange('investment_profiles', newInvestmentProfiles)
        }
      })
    }
  }
}

const legacyConstructClientAccountOptions = ({ client }) => {
  console.log('Calculating legacy client account options...')

  let accountOptions = ['All Accounts']

  const nonRetirementAccounts = client.accounts_non_retirement || []
  const retirementAccounts = client.accounts_retirement || []

  const clientAccounts = nonRetirementAccounts.concat(retirementAccounts)
  if (clientAccounts.length) {
    accountOptions = accountOptions.concat(clientAccounts)
  }

  return accountOptions
}

export const legacyProcessSchemaDependenciesForClientAccounts = ({
  properties,
  dependencies,
  client,
}) => {
  let customSchemaDependencies = _.cloneDeep(dependencies)

  if (
    properties &&
    Object.keys(properties) &&
    Object.keys(properties).includes('investmentProfileQuizOrKnow') &&
    dependencies &&
    dependencies.investmentProfileQuizOrKnow.oneOf &&
    dependencies.investmentProfileQuizOrKnow.oneOf.length &&
    client &&
    ((client.accounts_non_retirement &&
      client.accounts_non_retirement.length) ||
      (client.accounts_retirement && client.accounts_retirement.length))
  ) {
    customSchemaDependencies.investmentProfileQuizOrKnow.oneOf.forEach(
      (s, sidx) => {
        const scenario =
          customSchemaDependencies.investmentProfileQuizOrKnow.oneOf[sidx]

        const possibleFieldIds = [
          'investment_profiles_quizzes',
          'investment_profiles',
        ]

        possibleFieldIds.forEach((fieldId) => {
          const accountField =
            scenario.properties[fieldId] &&
            scenario.properties[fieldId].items &&
            scenario.properties[fieldId].items.properties &&
            scenario.properties[fieldId].items.properties.account
              ? scenario.properties[fieldId].items.properties.account
              : null

          if (accountField) {
            customSchemaDependencies.investmentProfileQuizOrKnow.oneOf[
              sidx
            ].properties[
              fieldId
            ].items.properties.account.enum = legacyConstructClientAccountOptions(
              {
                client,
              }
            )
          }
        })
      }
    )
  }

  return customSchemaDependencies
}

export const legacySetInitialInvestmentExperienceItems = ({
  schema,
  value,
  handleInputChange,
}) => {
  if (
    schema &&
    Object.keys(schema) &&
    Object.keys(schema).includes('investment_experience_items') &&
    (!value.investment_experience_items ||
      (value.investment_experience_items &&
        !value.investment_experience_items.length))
  ) {
    console.log('Setting initial legacy investment experience items...')

    const initialInvestmentExperienceItems = [
      { type: 'Mutual Funds', years: '', details: '', knowledge: '' },
      { type: 'Stocks', years: '', details: '', knowledge: '' },
      { type: 'Bonds', years: '', details: '', knowledge: '' },
      { type: 'Annuities', years: '', details: '', knowledge: '' },
      { type: 'ETFs', years: '', details: '', knowledge: '' },
      { type: 'REITs', years: '', details: '', knowledge: '' },
      { type: 'Limited Partnerships', years: '', details: '', knowledge: '' },
      { type: 'Other', years: '', details: '', knowledge: '' },
    ]

    handleInputChange(
      'investment_experience_items',
      initialInvestmentExperienceItems
    )
  }
}
