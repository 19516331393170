import React from 'react'
import { Table, Header } from 'semantic-ui-react'
import styled from 'styled-components'
import { SmallHeading } from 'styles/_mixins'
import Tip from 'components/partials/Tip'

const DetailsTable = ({ rows = [] }) => {
  if (!rows || !rows.length) return ''

  return (
    <>
      <Table basic="very">
        <Table.Body>
          {rows.flatMap((row, idx) => {
            if (!row.header && !row.content) {
              return []
            }

            return (
              <Table.Row key={idx}>
                <HeaderCell>
                  <StyledHeader content={row.header} />
                  {row.subheader && <StyledSubheader content={row.subheader} />}
                </HeaderCell>
                <Table.Cell content={row.content} />
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <TipContainer>
        <Tip content="Not seeing some details? We're working on adding support for adding more of your custom fields to the client profile!" />
      </TipContainer>
    </>
  )
}

export default DetailsTable

const HeaderCell = styled(Table.Cell)`
  &&& {
    & > *:last-child {
      margin-bottom: 0;
    }
  }
`

const StyledHeader = styled(Header)`
  &&& {
    ${SmallHeading()};
  }
`

const StyledSubheader = styled(Header.Subheader)`
  &&& {
    ${SmallHeading()};
    font-size: 80%;
    line-height: 80%;
    font-style: italic;
  }
`

const TipContainer = styled.div`
  text-align: right;
`
