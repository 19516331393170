import React, { Component } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { PDFExport } from '@progress/kendo-react-pdf'
import ReactHtmlParser from 'react-html-parser'
import FirmLogo from '../../../partials/FirmLogo'
import FeeSchedule from '../../../partials/FeeSchedule'
import InitialsField from '../../../forms/InitialsField'
import NextLink from '../../../partials/NextLink'
import InvestmentProfileQuizResponses from '../../../partials/InvestmentProfileQuizResponses'
import CheckIcon from '../../../icons/CheckIcon'
import RetryIcon from '../../../icons/RetryIcon'
// import '../../../../styles/iac.scss'
import DocumentSubmit from '../../partials/DocumentSubmit'
import {
  calculateAcceptableLoss,
  calculateAnnualFee,
  calculateClientAge,
  calculateNetWorthIncludingResidence,
  calculateROR,
  calculateTaxRate,
  calculateTimeHorizon,
  currentDate,
  generateInvestmentExperienceItems,
} from '../../../../actions/iacHelpers'

class MentzerIAC extends Component {
  constructor(props) {
    super(props)
    this.state = {
      advisor: this.props.advisor,
      updateDate: '12/6/2019',
      clientSig1: false,
      secondSig1: false,
      clientSig2: false,
      secondSig2: false,
    }

    this.handleSignatureConfirm = this.handleSignatureConfirm.bind(this)
    this.handleSignatureRetry = this.handleSignatureRetry.bind(this)
  }

  clientSig1 = {}
  secondSig1 = {}
  clientSig2 = {}
  secondSig2 = {}

  handleSignatureConfirm(event, ref, refName) {
    event.preventDefault()

    const { iac } = this.props

    var image = null

    if (!iac.signatures[refName]) {
      image = ref.getCanvas().toDataURL('image/png')
    }

    this.props.handleSignatureConfirm(refName, image)
  }

  handleSignatureRetry(event, ref, refName) {
    event.preventDefault()

    if (this[refName]) {
      this[refName].clear()
    }

    this.props.handleSignatureRetry(refName)
  }

  render() {
    const {
      type,
      firm,
      advisor,
      client,
      client_name,
      iac,
      errors,
      handleIACSubmit,
      handleInputChange,
      iacRef,
      nextLinkRef,
      handleKeyPress,
    } = this.props
    const { updateDate } = this.state

    this.currentDate = <div className="value">{currentDate()}</div>

    var custodian = firm.custodian

    if (
      client &&
      client.jurisdiction &&
      client.jurisdiction === 'Non-Discretionary' &&
      client.custodian
    ) {
      custodian = client.custodian
    }

    if (
      firm.account_minimum &&
      firm.account_minimum !== '0' &&
      firm.account_minimum !== '$0'
    ) {
      this.account_minimum_text =
        'There is an account minimum of <strong>' +
        firm.account_minimum +
        '</strong>.'
    } else {
      this.account_minimum_text = 'There is no account minimum.'
    }

    this.risk_tolerance_boxes = client.investment_profiles.map(
      (investment_profile, idx) => (
        <div key={idx} className="risk-tolerance-box">
          <h6>{investment_profile.account}</h6>
          <ul className="inline">
            <li>
              <div className="label">
                Acceptable Percentage of Principal Investment Loss in a Short
                Term Period:
              </div>
              <div className="value">
                {calculateAcceptableLoss(investment_profile.profile)}
              </div>
            </li>
            <li>
              <div className="label">Target Rate of Return:</div>
              <div className="value">
                {calculateROR(investment_profile.profile)}
              </div>
            </li>
          </ul>

          <div className="investment-profile">
            <span className="label">Account Type: </span>
            <span className="value">{investment_profile.profile}</span>
          </div>
        </div>
      )
    )

    this.next_link = ''
    if (type === 'send') {
      this.next_link = 'iac-submit'
    } else if (
      client.jurisdiction === 'Discretionary' &&
      iac.initials.discretionary.length < 1
    ) {
      this.next_link = 'discretionary'
    } else if (
      client.jurisdiction === 'Non-Discretionary' &&
      iac.initials.nondiscretionary.length < 1
    ) {
      this.next_link = 'nondiscretionary'
    } else if (iac.initials.adv_receipt.length < 1) {
      this.next_link = 'adv_receipt'
    } else if (iac.initials.electronic_consent.length < 1) {
      this.next_link = 'electronic_consent'
    } else if (!iac.signatures.clientSig1) {
      this.next_link = 'client_sig_1'
    } else if (iac.initials.advisor_obtained_records.length < 1) {
      this.next_link = 'advisor_obtained_records'
    } else if (!iac.signatures.clientSig2) {
      this.next_link = 'client_sig_2'
    } else {
      this.next_link = 'iac-submit'
    }

    if (firm && advisor && client) {
      return (
        <div className="document-container iac-container">
          <NextLink next_link={this.next_link} nextLinkRef={nextLinkRef} />

          <PDFExport
            paperSize={'Letter'}
            fileName={firm.name + ' IAC - ' + client_name + '.pdf'}
            title={firm.name + ' IAC - ' + client_name}
            author={advisor.name}
            creator={firm.name}
            subject=""
            keywords=""
            margin="2cm"
            scale={0.6}
            ref={iacRef}
          >
            <div id="iac" className="document iac">
              <div className="page cover">
                {firm.logo ? <FirmLogo firm={firm} /> : ''}

                <h1>{firm.legal_name}</h1>
                <h2>Investment Advisory Contract</h2>

                <div className="client-info">
                  <h3>Contract for:</h3>
                  <div className="name">{client_name}</div>
                  <div className="address">
                    <span>{client.address_street}</span>
                    <span>
                      {client.address_city}, {client.address_state}{' '}
                      {client.address_zip}
                    </span>
                  </div>
                </div>
              </div>

              <div className="page-break"></div>
              <div className="contract">
                <h3>Investment Agreement</h3>

                <p>
                  The undersigned (“Client”), being duly authorized, has
                  established an Account/Relationship (the “Account”), and
                  hereby agrees to engage {firm.legal_name} (“IA”) on the
                  following terms and conditions.
                </p>

                <ol className="upper-roman terms">
                  <li>
                    <h4>Appointment of {firm.legal_name}</h4>
                    <p>
                      The Client hereby appoints IA as investment advisor for
                      the Account. IA shall supervise and direct the investments
                      of and for the Account, subject to the objectives,
                      limitations and restrictions listed in the Client’s
                      Written Investment Policy, which is attached as Exhibit I.
                      The persons authorized to act on behalf of the Client with
                      respect to the Account are identified in Exhibit IV.
                      Client agrees to promptly notify IA in writing of any
                      changes to its investment policy, and any changes to the
                      restrictions or limitations applicable to the Account, and
                      to provide IA with prior written notice of any changes in
                      the identity of persons authorized to act on behalf of the
                      Client with respect to the Account.
                    </p>
                  </li>
                  <li>
                    <h4>Services by IA.</h4>
                    <p>
                      By execution of this Agreement, IA hereby accepts the
                      appointment as investment advisor for the Account and
                      agrees from and after the effective date, as referred to
                      in the Schedule of Fees attached hereto as Exhibit II,
                    </p>
                    <ol className="lower-alpha-parens">
                      <li>
                        to supervise and direct the investments of the Account
                        in accordance with the investment objectives of Client
                        as listed on the attached Exhibit I, and as communicated
                        hereafter in writing to IA from time to time;
                      </li>
                      <li>
                        to appraise and review, at least quarterly during the
                        period of this Agreement investments of the Account, as
                        initially accepted by IA, together with all additions,
                        substitutions and alterations thereto; and
                      </li>
                      <li>
                        to render to Client at least quarterly a written
                        statement of the investments of the Account. This
                        statement may come directly from a Custodian. It is
                        understood and agreed that IA, in the maintenance of
                        records for its own purposes, or in making such records
                        or the information contained therein available to Client
                        or any other person at the direction of Client, does not
                        assume responsibility for the accuracy of information
                        furnished by Client or any other person, firm or
                        corporation.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>Procedure.</h4>
                    <p>
                      All transactions authorized by this Agreement shall be
                      consummated by payment to or delivery by Client or
                      Custodian. IA will have no custody, at any time, of client
                      funds and/or securities; and all funds/securities will be
                      delivered between Client and Custodian only. Instructions
                      of IA to Client or the Custodian with respect to
                      investments shall be made in writing or orally, and
                      confirmed in writing as soon as practicable thereafter.
                      The Custodian at the time this Agreement is executed is
                      identified in the attached Exhibit III. If the identity of
                      the Client’s Custodian changes, the Client will provide IA
                      with prompt, written notice of the change. Client hereby
                      authorizes IA to receive from the Custodian a copy of any
                      Custody Agreement in effect at any time with respect to
                      the Account.
                    </p>
                  </li>
                  <li>
                    <div className="page-break"></div>
                    <h4>Service to Other Clients.</h4>
                    <p>
                      It is understood that IA performs investment advisory
                      services for various clients. Client agrees that IA may
                      give advice and take action in the performance of its
                      duties with respect to any of its other clients which may
                      differ with the advice given or action taken with respect
                      to the Account, so long as it is the IA’s policy, to the
                      extent practical, to allocate investment opportunities to
                      the Account over a period of time on a fair and equitable
                      basis relative to other clients. Nothing in this Agreement
                      shall be deemed to confer upon IA any obligation to
                      acquire for the Account a position in any security which
                      IA, its principals or employees may acquire for its or
                      their own accounts or for the account of any other client,
                      if in the sole and absolute discretion of IA it is not for
                      any reason practical or desirable to acquire a position in
                      such security for the Account. IA shall not be held
                      responsible for any loss incurred by reason of any act or
                      omission of any broker or the custodian for the Account.
                    </p>
                  </li>
                  <li>
                    <h4>Client Accounts.</h4>
                    <p>
                      Client agrees to open an account with{' '}
                      {custodian.preferred_name} (the “Custodian”) for the
                      execution of securities transactions and custodial
                      services. IA may receive certain administrative benefits
                      from the Custodian that enable the IA to provide the
                      Client with advisory services. Client understands that by
                      instructing IA to execute all transactions on behalf of
                      the Account through the Custodian, Client may not
                      necessarily obtain commission rates and execution as
                      favorable as those that would be obtained if IA was able
                      to place transactions with other broker-dealers. Client
                      may also forego benefits that Advisor may be able to
                      obtain for its other clients through, for example, the
                      negotiation of volume discounts or block trades.
                    </p>
                  </li>
                  <li>
                    <h4>Inside Information.</h4>
                    <p>
                      IA shall have no obligation to seek to obtain any material
                      nonpublic (“inside”) information about any issuer of
                      securities, or to purchase or sell, or to recommend for
                      purchase or sale, for the Account the securities of any
                      issuer on the basis of any such information as may come
                      into its possession.
                    </p>
                  </li>
                  <li>
                    <h4>Proxies.</h4>
                    <p>
                      IA will not be required to take any action or render any
                      advice with respect to the voting of proxies solicited by
                      or with respect to the issuers of securities in which
                      assets of the Account may be invested from time to time
                      except as may be directed by the Client and except as may
                      be otherwise required by law.
                    </p>
                  </li>
                  <li>
                    <h4>Fees.</h4>
                    <p>
                      The compensation of IA for its services rendered hereunder
                      shall be calculated in accordance with the Schedule of
                      Fees attached hereto as Exhibit II. Client shall be given
                      thirty (30) days’ prior written notice of any increase in
                      fees.
                    </p>
                    <div className="page-break"></div>
                    <ul>
                      <li>
                        <h5>Investment Supervisory Services</h5>
                        <p>
                          Fees for these services will be based on a percentage
                          of Assets Under Management as follows:
                        </p>
                        <FeeSchedule rows={firm.annual_fees} />
                        <p>
                          These fees are negotiable and the final fee schedule
                          will be attached as Exhibit II. Fees are paid
                          quarterly in arrears, and clients may terminate their
                          account with thirty days written notice. Because fees
                          are charged in arrears, no refund policy is necessary.
                          Clients may terminate their accounts without penalty,
                          for full refund, within 5 business days of signing the
                          advisory contract. IA will withdraw advisory fees
                          directly from the client’s accounts with written
                          authorization.{' '}
                          {ReactHtmlParser(this.account_minimum_text)}
                        </p>
                      </li>

                      <li>
                        <h5>Investment Advice Not Involving Supervision</h5>

                        <ul>
                          <li>
                            <h6>Financial Plans</h6>
                            <p>
                              Financial plans and financial planning may
                              include, but are not limited to: life insurance;
                              tax concerns; retirement planning; investment
                              planning; college planning; and debt/credit
                              planning. These services will be based on hourly
                              fees and the final fee structure will be
                              documented in Exhibit II.
                            </p>
                          </li>
                          <li>
                            <h6>Hourly Fees</h6>
                            <p>
                              Depending upon the complexity of the situation and
                              the needs of the client, the hourly fee for these
                              services is <strong>{firm.hourly_fee}</strong>.
                              The fees are negotiable and the final fee schedule
                              will be attached as Exhibit II. Fees are paid in
                              arrears upon completion. Because fees are charged
                              in arrears, no refund is necessary. Clients may
                              terminate their accounts without penalty, for full
                              refund, within 5 business days of signing the
                              advisory contract.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h4>Valuation.</h4>
                    <p>
                      In computing the market value of any investment of the
                      Account, each security listed on any national securities
                      exchange or otherwise subject to current last-sale
                      reporting shall be valued at the last sale price on the
                      valuation date. Such securities which are not traded nor
                      subject to last- sale reporting shall be valued at the
                      latest available bid price reflected by quotations
                      furnished to IA by such sources as it may deem
                      appropriate. Any other security shall be valued in such
                      manner as shall be determined in good faith by IA to
                      reflect its fair market value.
                    </p>
                  </li>
                  <li>
                    <div className="page-break"></div>
                    <h4>Representations by Client.</h4>
                    <p>
                      The execution and delivery of this Agreement by Client
                      shall constitute the representations by Client that the
                      terms hereof do not violate any obligation by which Client
                      is bound, whether arising by contract, operation of law or
                      otherwise; that if Client is an entity other than a
                      natural person (a) this Agreement has been duly authorized
                      by appropriate action and is binding upon Client in
                      accordance with its terms and (b) Client will deliver to
                      IA such evidence of such authority as IA may reasonably
                      require, whether by way of a certified corporate
                      resolution or otherwise; (c) that if the Client is subject
                      to the requirements of the Employee Retirement Income
                      Security Act of 1974 as amended (“ERISA”), the Client has
                      notified IA of such fact in writing, and the Client
                      representatives who have executed this Agreement are
                      “named fiduciaries” of the Client. Client will indemnify
                      IA from any liability or expense resulting from a breach
                      of Client’s representations or from actions of plan
                      fiduciaries of any ERISA plan other than IA. IA is
                      responsible only for the Account and not for the
                      diversification or prudent investment of any outside
                      assets of the Client.
                    </p>
                  </li>
                  <li>
                    <h4>Representations by IA.</h4>
                    <p>
                      By execution of this Agreement, IA represents and confirms
                      that it is registered as an investment advisor pursuant to
                      state law.
                    </p>
                  </li>
                  <li>
                    <h4>Termination.</h4>
                    <p>
                      This Agreement shall continue in effect until terminated
                      by either party by giving to the other 30 days written
                      notice; provided that the Client may at any time, upon
                      delivery of written notice to IA, terminate the
                      discretionary authority of IA. No assignment of the
                      Agreement by IA shall be effective without the prior
                      written consent of Client. Client may terminate the
                      Agreement within five (5) business days of signing,
                      without penalty, and with full refund.
                    </p>
                  </li>
                  <li>
                    <h4>Bond.</h4>
                    <p>
                      If a bond is required in connection with an Account,
                      Client agrees to obtain and maintain for the period of
                      this Agreement the bond in the amount specified by ERISA
                      or other applicable law and agrees to include IA among
                      those covered by such bond.
                    </p>
                  </li>
                  <li>
                    <h4>Notices.</h4>
                    <p>
                      All notices and other communications contemplated by this
                      Agreement shall be deemed duly given if transmitted to IA
                      at: {firm.address_street}, {firm.address_city},{' '}
                      {firm.address_state}, {firm.address_zip}, to the attention
                      of its President, and to Client at the address appearing
                      below, or at such other address or addresses as shall be
                      specified, in each case, in a written notice similarly
                      given.
                    </p>
                  </li>
                  <li>
                    <h4>Governing Law.</h4>
                    <p>
                      The validity of this Agreement and the rights and
                      liabilities of the parties hereunder shall be determined
                      in accordance with the laws of the State of{' '}
                      {firm.address_state} except to the extent preempted by
                      ERISA.
                    </p>
                  </li>
                  <li>
                    <div className="page-break"></div>
                    <h4>Exhibits.</h4>
                    <p>
                      The following Exhibits are attached, and are part of this
                      Agreement:
                    </p>

                    <p>
                      <strong>Attached:</strong>
                    </p>
                    <ol className="exhibit-upper-roman">
                      <li>Client’s Written Investment Policy</li>
                      <li>Schedule of Fees</li>
                      <li>Identification of Custodian</li>
                      <li>Identification of Authorized Firm Persons</li>
                    </ol>
                  </li>
                  <li>
                    <h4>Authority.</h4>
                    <p>
                      When necessary, IA shall authorize the payment of
                      transaction costs from the Client’s account. No
                      commissions will be paid to IA or any IA affiliate.
                    </p>

                    <div className="management-options">
                      <p>
                        Except as otherwise set forth in this Agreement, Client
                        authorizes IA to investigate, purchase, and sell on
                        behalf of Client, various securities and investments. IA
                        is authorized to execute purchases and sales of
                        securities on Client’s behalf without consulting Client
                        regarding each sale or purchase.
                      </p>
                      <h5>(Discretionary Investment Management)</h5>
                      <InitialsField
                        required={client.jurisdiction === 'Discretionary'}
                        id="discretionary"
                        value={iac.initials.discretionary}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />

                      <p className="uppercase">
                        <strong>or</strong>
                      </p>

                      <p>
                        IA is authorized to execute purchases and sales of
                        securities only{' '}
                        <strong>
                          <em>after</em>
                        </strong>{' '}
                        consulting with Client regarding each transaction.
                      </p>
                      <h5>(Non-Discretionary Investment Management)</h5>
                      <InitialsField
                        required={client.jurisdiction === 'Non-Discretionary'}
                        id="nondiscretionary"
                        value={iac.initials.nondiscretionary}
                        handleInputChange={handleInputChange}
                        handleKeyPress={handleKeyPress}
                      />
                    </div>

                    <p>
                      Nothing in this Agreement grants IA or any “person
                      associated with” IA (as defined in Section 202(a)(17) of
                      the Investment Advisors Act of 1940, as amended (the
                      “Act”)) the authority to take custody or possession of any
                      of Client’s assets. For purposes of this Agreement
                      “Account” shall be defined as follows: all accounts at any
                      agreed Custodian for which Client has given IA a limited
                      power of attorney.
                    </p>
                  </li>
                  <li>
                    <div className="page-break"></div>
                    <h4>
                      Receipt of Form ADV Part 2A and 2B, and Privacy Policy.
                    </h4>
                    <p>
                      Client acknowledges receipt of Part 2A and Part 2B of Form
                      ADV, and Privacy Policy. If Part 2A and Part 2B of Form
                      ADV were not delivered to the client at least 48 hours
                      prior to the client entering into any written or oral
                      advisory contract with this investment adviser, then the
                      client has the right to terminate the contract without
                      penalty within five business days after entering into the
                      contract. For the purposes of this provision, a contract
                      is considered entered into when all parties to the
                      contract have signed the contract, or, in the case of an
                      oral contract, otherwise signified their acceptance, any
                      other provisions of this contract notwithstanding.
                    </p>
                    <InitialsField
                      required={true}
                      id="adv_receipt"
                      value={iac.initials.adv_receipt}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                  </li>
                  <li>
                    <h4>Consent to Electronic Delivery.</h4>
                    <p>
                      Client hereby consents to receive via e-mail or other
                      electronic delivery method for various communications,
                      documents, and notifications from {firm.legal_name}. These
                      items may include but are not limited to: all statements
                      or reports produced by The Mentzer Group; trade
                      confirmations; billing invoices; all Client brochures
                      (Form ADV, Wrap Brochure, etc.); privacy policy
                      statements; and any other notices or documentation that
                      the IA chooses to provide on an ongoing or occasional
                      basis. Client agrees to immediately notify the IA of any
                      changes to Client’s e-mail address shown below or other
                      electronic delivery address.
                    </p>
                    <InitialsField
                      required={true}
                      id="electronic_consent"
                      value={iac.initials.electronic_consent}
                      handleInputChange={handleInputChange}
                      handleKeyPress={handleKeyPress}
                    />
                  </li>
                  <li>
                    <h4>Assignment.</h4>
                    <p>
                      No assignment of the contract may be made by the
                      investment advisor without the written consent of the
                      client.
                    </p>
                  </li>
                  <li>
                    <h4>Confidential Relationship.</h4>
                    <p>
                      All information and advice furnished by either party to
                      the other shall be treated as confidential and shall not
                      be disclosed to third parties except as required by law.
                    </p>
                  </li>
                  <li>
                    <h4>Title to Assets.</h4>
                    <p>
                      Except to the extent Client has notified, or in the future
                      notifies, IA in writing, Client represents that assets in
                      the Account belong to Client free and clear of any lien or
                      encumbrances.
                    </p>
                  </li>
                  <li>
                    <h4>Minimum.</h4>
                    <p>{ReactHtmlParser(this.account_minimum_text)}</p>
                  </li>
                  <li>
                    <div className="page-break"></div>
                    <h4>Market Conditions.</h4>
                    <p>
                      Client acknowledges that IA’s past performance and advice
                      regarding the client’s account cannot guarantee future
                      results.{' '}
                      <strong>
                        <em>
                          <span className="red">
                            AS WITH ALL MARKET INVESTMENTS, CLIENT INVESTMENTS
                            CAN APPRECIATE OR DEPRECIATE.
                          </span>
                        </em>
                      </strong>{' '}
                      IA does not guarantee or warranty that services offered
                      will result in profit.
                    </p>
                  </li>
                </ol>

                <div className="page-break"></div>
                <div className="signatures">
                  <p>
                    IN WITNESS THEREOF, the parties have executed this Agreement
                    on the date stated below.
                  </p>
                  <p>ACCEPTED BY:</p>

                  <ul className="parties">
                    <li>
                      <h6>Client</h6>
                      <div className="row signature-row">
                        <div className="signature" id="client_sig_1">
                          {iac.signatures.clientSig1 &&
                          iac.signatures.clientSig1.length > 1 ? (
                            <img
                              src={iac.signatures.clientSig1}
                              alt="Client Signature"
                            />
                          ) : (
                            <SignatureCanvas
                              ref={(ref) => {
                                this.clientSig1 = ref
                              }}
                              className="to-do"
                            />
                          )}

                          <div className="signature-buttons">
                            <button
                              className="signatureRetry"
                              onClick={(event) =>
                                this.handleSignatureRetry(
                                  event,
                                  this.clientSig1,
                                  'clientSig1'
                                )
                              }
                            >
                              <RetryIcon />
                            </button>
                            <button
                              className="signatureConfirm"
                              onClick={(event) =>
                                this.handleSignatureConfirm(
                                  event,
                                  this.clientSig1,
                                  'clientSig1'
                                )
                              }
                            >
                              <CheckIcon />
                            </button>
                          </div>
                          <div className="label">Client Signature</div>
                        </div>
                        <div className="date">
                          {this.currentDate}
                          <div className="label">Date</div>
                        </div>
                      </div>
                      <div className="row title">
                        <div className="value">{client.legal_name}</div>
                        <div className="label">First Investor</div>
                      </div>

                      <div className="row signature-row">
                        <div className="signature">
                          {iac.signatures.secondSig1 &&
                          iac.signatures.secondSig1.length > 1 ? (
                            <img
                              src={iac.signatures.secondSig1}
                              alt="Second Signature"
                            />
                          ) : (
                            <SignatureCanvas
                              ref={(ref) => {
                                this.secondSig1 = ref
                              }}
                            />
                          )}
                          <div className="signature-buttons">
                            <button
                              className="signatureRetry"
                              onClick={(event) =>
                                this.handleSignatureRetry(
                                  event,
                                  this.secondSig1,
                                  'secondSig1'
                                )
                              }
                            >
                              <RetryIcon />
                            </button>
                            <button
                              className="signatureConfirm"
                              onClick={(event) =>
                                this.handleSignatureConfirm(
                                  event,
                                  this.secondSig1,
                                  'secondSig1'
                                )
                              }
                            >
                              <CheckIcon />
                            </button>
                          </div>
                          <div className="label">Second Signature</div>
                        </div>
                        <div className="date">
                          {this.currentDate}
                          <div className="label">Date</div>
                        </div>
                      </div>
                      <div className="row title">
                        <div className="value">
                          {client.second_investor_legal_name
                            ? client.second_investor_legal_name
                            : ''}
                        </div>
                        <div className="label">Second Investor</div>
                      </div>
                    </li>
                    <li>
                      <h6>Investment Advisory (IA) Firm</h6>
                      <div className="row signature-row">
                        <div className="signature signing-party">
                          <img
                            src={firm.signing_party.signature}
                            alt="Signing Party Signature"
                          />
                          <div className="label">{firm.signing_party.name}</div>
                        </div>
                        <div className="date">
                          {this.currentDate}
                          <div className="label">Date</div>
                        </div>
                      </div>
                      <div className="row title">
                        <div className="value">{firm.signing_party.title}</div>
                        <div className="label">Title</div>
                      </div>
                    </li>
                  </ul>

                  <div className="page-break"></div>
                  <div className="client-address">
                    {client_name}
                    <br />
                    {client.address_street}
                    <br />
                    {client.address_city}, {client.address_state}{' '}
                    {client.address_zip}
                  </div>
                </div>
              </div>

              <div className="articles">
                <ol>
                  <li>
                    <h3>Exhibit I</h3>
                    <div className="ips-box">
                      <h3>Written Investment Policy Statement</h3>
                      <div>
                        Contract Updated:{' '}
                        <div className="value">{updateDate}</div>
                      </div>
                      <div>Creation Date: {this.currentDate}</div>
                    </div>

                    <ul>
                      <li>
                        <h4>Explanation and Overview:</h4>
                        <p>
                          The following Investment Policy Statement (IPS) is
                          designed to capture an initial picture and evaluation
                          of the Client’s current financial situation including
                          their investment portfolio and assets. From this and
                          other information obtained through Client/Advisor
                          interviews and meetings, the Advisor will provide
                          guidance and make recommendations to assist the Client
                          in deciding what changes, if any, may be needed
                          regarding their assets, allocations of their assets,
                          and investment portfolio(s). The IPS will serve as a
                          “document of understanding” between the Advisor and
                          the Client which will need to be updated periodically
                          in order to remain relevant.
                        </p>
                        <p>The IPS is designed to do the following:</p>

                        <ol className="number">
                          <li>
                            Define the Client’s current financial situation,
                          </li>
                          <li>
                            Gather Client’s investment profile information
                            including risk/reward tolerances, goals, and
                            expectations.
                          </li>
                          <li>
                            Define the duties and responsibilities of the
                            Client, the Advisor, the Custodian, and the
                            Investment Manager or Investment Committee (if
                            different from Advisor).
                          </li>
                          <li>
                            State, in writing, the Client’s investment goals,
                            objectives, and constraints.
                          </li>
                          <li>
                            Describe proposed investment strategies and styles
                            to be used by Advisor if applicable.
                          </li>
                          <li>
                            Establish guidelines for portfolio rebalancing if
                            applicable.
                          </li>
                        </ol>

                        <p>
                          It is the <strong>duty of the Client</strong> to
                          provide the Advisor with all requested current
                          financial and/or other information to the best of
                          his/her/their abilities. The Advisor will use this
                          information to develop this IPS and the investment
                          recommendations or strategy used for the Client’s
                          portfolios. The Client will also be expected to update
                          the Advisor with any changes to the requested
                          information that occurs in the future. The Advisor
                          cannot be held liable for any inaccurate information
                          provided by the Client.
                        </p>
                        <p>
                          It is the <strong>duty of the Advisor</strong> to
                          treat the Client with a Fiduciary standard of care –
                          meaning the Client’s interests will always be at the
                          forefront, ahead of any individual advisor
                          representative or the Advisor. The Advisor will use
                          various methods including this IPS and Client
                          interviews, conversations, and meetings to collect the
                          information needed to create this IPS document and to
                          recommend an action plan of investment strategies
                          and/or portfolio investments that are designed to
                          accomplish the Client’s goals and objectives.
                        </p>
                        <p>
                          As stated above this IPS will be used to gather
                          statistical information about the Client to help the
                          Advisor structure portfolios that are consistent with
                          the Client’s policies and goals as delineated in
                          discussions between the Advisor and the Client.
                        </p>
                      </li>
                      <li>
                        <div className="page-break"></div>
                        <h4>IPS – Information and Assumptions:</h4>

                        <h5>
                          <strong>
                            <em>Client Profile Information</em>
                          </strong>
                        </h5>

                        <div className="client-information-box">
                          <ul className="informationTable">
                            <li></li>
                            <li>
                              <span className="label">Client Age: </span>
                              <span className="value">
                                {calculateClientAge(client.birthdate)}
                              </span>
                            </li>
                            <li></li>
                            <li>
                              <span className="label">
                                Current Annual Income:{' '}
                              </span>
                              <span className="value">
                                {client.income_range}
                              </span>
                            </li>
                            <li>
                              <span className="label">
                                Income Tax Bracket:{' '}
                              </span>
                              <span className="value">
                                {calculateTaxRate(client.income_range)}
                              </span>
                            </li>
                            <li>
                              <span className="label">
                                Desired Retirement Age:{' '}
                              </span>
                              <span className="value">
                                {client.retirement_age}
                              </span>
                            </li>
                            <li>
                              <span className="label">
                                Net Worth (Including Residence):{' '}
                              </span>
                              <span className="value">
                                {calculateNetWorthIncludingResidence(
                                  client.net_worth,
                                  client.residence_value
                                )}
                              </span>
                            </li>
                            <li>
                              <span className="label">
                                Net Worth (Excluding Residence):{' '}
                              </span>
                              <span className="value">{client.net_worth}</span>
                            </li>
                            <li>
                              <span className="label">Liquid Net Worth: </span>
                              <span className="value">
                                {client.net_worth_liquid}
                              </span>
                            </li>
                            <li>
                              <span className="label">Portfolio Value: </span>
                              <span className="value">
                                {client.portfolio_value}
                              </span>
                            </li>
                            <li>
                              <span className="label">Annual Fee: </span>
                              <span className="value">
                                {calculateAnnualFee(
                                  client.portfolio_value,
                                  firm.annual_fees
                                )}
                              </span>
                            </li>
                            <li>
                              <span className="label">
                                Capital Gains Rate:{' '}
                              </span>
                              <span className="value">est. 15-35*</span>
                            </li>
                          </ul>
                        </div>

                        <div className="text-center">
                          <strong>Current Investment Holdings:</strong>
                        </div>

                        <div className="client-information-box">
                          <ul className="inline">
                            <li>
                              <span className="label">Qualified: </span>
                              <span className="value">
                                {client.portfolio_value_qualified}
                              </span>
                            </li>
                            <li>
                              <span className="label">Non-Qualified: </span>
                              <span className="value">
                                {client.portfolio_value_non_qualified}
                              </span>
                            </li>
                          </ul>
                        </div>

                        <p>
                          Advisor Obtained Client’s Financial Records (Balance
                          Sheet, Income Statement, Tax Returns, etc.)
                        </p>
                        <InitialsField
                          required={true}
                          id="advisor_obtained_records"
                          value={iac.initials.advisor_obtained_records}
                          handleInputChange={handleInputChange}
                          handleKeyPress={handleKeyPress}
                        />

                        <h5>
                          <strong>
                            <em>Investment Objective(s) Information</em>
                          </strong>
                        </h5>

                        <div className="client-information-box">
                          <ul className="inline">
                            <li>
                              <span className="label">Retirement Needs: </span>
                              <span className="value">
                                {client.retirement_needs}
                              </span>
                            </li>
                            <li>
                              <span className="label">
                                Desired Monthly Retirement Income:{' '}
                              </span>
                              <span className="value">
                                {client.retirement_income} per month
                              </span>
                            </li>
                            <li>
                              <span className="label">
                                Estimated Annual Inflation Rate Between Now and
                                Retirement:{' '}
                              </span>
                              <span className="value">3%</span>
                            </li>
                            <li>
                              <span className="label">
                                Time Horizon for Proposed Investment Portfolio:{' '}
                              </span>
                              <span className="value">
                                {calculateTimeHorizon(
                                  client.birthdate,
                                  client.retirement_age
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="page-break"></div>
                        <h4>Risk Tolerance:</h4>
                        {this.risk_tolerance_boxes}

                        <p>
                          Specific Investment Objectives and Goals (be specific
                          and provide details):
                        </p>

                        <p className="underline">
                          <strong>
                            Achieve good growth while moderating risk. Primary
                            objective is growth of assets followed by
                            preservation. Set up assets to be as tax-efficient
                            as possible and plan for the distributions of assets
                            to beneficiaries in the most tax efficient way at
                            death.
                          </strong>
                        </p>

                        <InvestmentProfileQuizResponses
                          investmentProfiles={client.investment_profiles}
                        />
                      </li>
                      <li>
                        <h4>Investment Experience:</h4>

                        <p>The Client has owned each of the following:</p>

                        <ul className="informationTable">
                          {generateInvestmentExperienceItems(
                            client.investment_experience_items
                          )}
                        </ul>
                      </li>
                      <li>
                        <h4>
                          Advisor Proposed Investment Strategies and/or Modules:
                        </h4>
                        <p>See Attached Portfolio</p>
                      </li>
                      <li>
                        <div className="page-break"></div>
                        <h4>Client and Advisor Adoption Signatures:</h4>
                        <p>
                          Client and Advisor both jointly adopt this Investment
                          Policy Statement and agree that it is a work in
                          progress that must be updated frequently in order to
                          remain relevant and appropriate.
                        </p>

                        <div className="signatures">
                          <div className="client">
                            <div className="signature" id="client_sig_2">
                              {iac.signatures.clientSig2 &&
                              iac.signatures.clientSig2.length > 1 ? (
                                <img
                                  src={iac.signatures.clientSig2}
                                  alt="Second Signature"
                                />
                              ) : (
                                <SignatureCanvas
                                  ref={(ref) => {
                                    this.clientSig2 = ref
                                  }}
                                  className="to-do"
                                />
                              )}
                              <div className="signature-buttons">
                                <button
                                  className="signatureRetry"
                                  onClick={(event) =>
                                    this.handleSignatureRetry(
                                      event,
                                      this.clientSig2,
                                      'clientSig2'
                                    )
                                  }
                                >
                                  <RetryIcon />
                                </button>
                                <button
                                  className="signatureConfirm"
                                  onClick={(event) =>
                                    this.handleSignatureConfirm(
                                      event,
                                      this.clientSig2,
                                      'clientSig2'
                                    )
                                  }
                                >
                                  <CheckIcon />
                                </button>
                              </div>
                              <div className="label">Investor</div>
                              <div className="form-group signature-text">
                                {client.legal_name}
                              </div>
                            </div>
                            <div className="signature">
                              {iac.signatures.secondSig2 &&
                              iac.signatures.secondSig2.length > 1 ? (
                                <img
                                  src={iac.signatures.secondSig2}
                                  alt="Second Signature"
                                />
                              ) : (
                                <SignatureCanvas
                                  ref={(ref) => {
                                    this.secondSig2 = ref
                                  }}
                                />
                              )}
                              <div className="signature-buttons">
                                <button
                                  className="signatureRetry"
                                  onClick={(event) =>
                                    this.handleSignatureRetry(
                                      event,
                                      this.secondSig2,
                                      'secondSig2'
                                    )
                                  }
                                >
                                  <RetryIcon />
                                </button>
                                <button
                                  className="signatureConfirm"
                                  onClick={(event) =>
                                    this.handleSignatureConfirm(
                                      event,
                                      this.secondSig2,
                                      'secondSig2'
                                    )
                                  }
                                >
                                  <CheckIcon />
                                </button>
                              </div>
                              <div className="label">Second Investor</div>
                              <div className="form-group signature-text inline">
                                {client.second_investor_legal_name
                                  ? client.second_investor_legal_name
                                  : ''}
                              </div>
                            </div>
                          </div>

                          <div className="advisor row signature-row">
                            <div className="signature advisor-signature">
                              <img
                                src={advisor.signature}
                                alt="Advisor Signature"
                              />
                              <div className="label">Advisor</div>
                            </div>
                            <div className="date">
                              {this.currentDate}
                              <div className="label">Date</div>
                            </div>
                          </div>

                          {firm.chief_compliance_officer ? (
                            <div className="chief-compliance-officer row signature-row">
                              <div className="signature chief-compliance-officer-signature">
                                <img
                                  src={firm.chief_compliance_officer.signature}
                                  alt="Chief Compliance Officer Signature"
                                />
                                <div className="label">
                                  Chief Compliance Officer
                                </div>
                              </div>
                              <div className="date">
                                {this.currentDate}
                                <div className="label">Date</div>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li className="fee-schedule">
                    <div className="page-break"></div>
                    <h3>Exhibit II</h3>
                    <h3>Fee Schedule</h3>

                    <p>
                      The following are the fees charged by Investment Advisory
                      Firm (IA) for services provided:
                    </p>

                    <h4>Investment Supervisory Services</h4>
                    <p>
                      Fees for these services will be based on a percentage of
                      Assets Under Management as follows:
                    </p>
                    <FeeSchedule rows={firm.annual_fees} />
                    <p>
                      These fees are negotiable and the final fee schedule will
                      be attached as Exhibit II. Fees are paid quarterly in
                      arrears, and clients may terminate their account with
                      thirty days written notice. Because fees are charged in
                      arrears, no refund policy is necessary. Clients may
                      terminate their accounts without penalty, for full refund,
                      within 5 business days of signing the advisory contract.
                      IA will withdraw advisory fees directly from the client’s
                      accounts with written authorization.{' '}
                      {ReactHtmlParser(this.account_minimum_text)}
                    </p>

                    <h4>Investment Advice Not Involving Supervision</h4>

                    <h5>Financial Plans</h5>
                    <p>
                      Financial plans and financial planning may include, but
                      are not limited to: life insurance; tax concerns;
                      retirement planning; investment planning; college
                      planning; and debt/credit planning. These services will be
                      based on hourly fees and the final fee structure will be
                      documented in Exhibit II.
                    </p>

                    <h5>Hourly Fees</h5>
                    <p>
                      Depending upon the complexity of the situation and the
                      needs of the client, the hourly fee for these services is{' '}
                      <strong>{firm.hourly_fee}</strong>. The fees are
                      negotiable and the final fee schedule will be attached as
                      Exhibit II. Fees are paid in arrears upon completion.
                      Because fees are charged in arrears, no refund is
                      necessary. Clients may terminate their accounts without
                      penalty, for full refund, within 5 business days of
                      signing the advisory contract.
                    </p>
                  </li>
                  <li>
                    <div className="page-break"></div>
                    <h3>Exhibit III</h3>
                    <h3>Identification of Custodian</h3>

                    <p>The Custodian for the Account is:</p>

                    <div className="custodian-info">
                      <div className="row">
                        <div className="label">Name:</div>
                        <div className="value">{custodian.preferred_name}</div>
                      </div>
                      <div className="row">
                        <div className="label">Mailing Address:</div>
                        <div className="value">
                          <span>{custodian.address_street}</span>
                          <span>
                            {custodian.address_city}, {custodian.address_state}{' '}
                            {custodian.address_zip}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="label">Telephone:</div>
                        <div className="value">{custodian.phone}</div>
                      </div>
                    </div>
                    <p className="red italic exhibit-3-disclaimer">
                      A copy of the Custody Agreement is not attached as part of
                      this Exhibit III.
                    </p>
                  </li>

                  <li>
                    <h3>Exhibit IV</h3>
                    <h3>Identification of Authorized Firm Persons</h3>

                    <p>
                      The following persons at the IA are authorized to act on
                      behalf of Client with respect to the Account.
                    </p>
                    <p>
                      Client will provide IA with prior written notice of any
                      changes in the persons at IA so authorized.
                    </p>

                    <strong>{firm.signing_party.name}</strong>
                    {firm.signing_party.credentials.length > 0 ? (
                      <strong>, {firm.signing_party.credentials}</strong>
                    ) : (
                      ''
                    )}
                    <br />
                    <strong>
                      {firm.signing_party.title}, Investment Advisor
                      Representative
                    </strong>
                    <br />
                    <br />
                    <strong>Jonathan Wilkins</strong>
                    <br />
                    <strong>Director of Operations and Client Services</strong>

                    <div className="signatures">
                      <div className="advisor row signature-row">
                        <div className="signature advisor-signature">
                          <img
                            src={advisor.signature}
                            alt="Advisor Signature"
                          />
                          <div className="label">Advisor</div>
                        </div>
                        <div className="date">
                          {this.currentDate}
                          <div className="label">Date</div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </PDFExport>

          <DocumentSubmit errors={errors} handleSubmit={handleIACSubmit} />
        </div>
      )
    } else {
      return <h4>Populating...</h4>
    }
  }
}

export default MentzerIAC
