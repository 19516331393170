import { firebaseDB } from '../../config/firebaseConfig'

export const addDisclosureToDB = (
  type,
  name,
  url,
  uid,
  setUploadComplete = null
) => {
  var db = firebaseDB

  var collection = `${type}s`

  db.collection(collection)
    .doc(uid)
    .collection('disclosures')
    .add({
      name: name,
      link: url,
    })
    .then((docRef) => {
      // console.log("Disclosure added with ID: ", docRef.id)
      if (setUploadComplete) {
        setUploadComplete()
      }
    })
    .catch((error) => {
      // console.error("Error adding disclosure: ", error)
    })
}

export const deleteDisclosure = (type, uid, disclosure) => {
  firebaseDB
    .collection(`${type}s`)
    .doc(uid)
    .collection('disclosures')
    .doc(disclosure)
    .delete()

  // TODO: [BTS-269] Delete disclosure from storage
}
