import React, { Component } from 'react'

class CalendlyWidget extends Component {
  render() {
    const { url, prefill } = this.props

    // Create prefilled URL
    var prefilledURL = url
    if (prefill) {
      prefilledURL += '?'
      if (prefill.legal_name_first) {
        prefilledURL +=
          '&first_name=' + encodeURIComponent(prefill.legal_name_first)
        prefilledURL +=
          '&firstName=' + encodeURIComponent(prefill.legal_name_first)
      }

      if (prefill.legal_name_last) {
        prefilledURL +=
          '&last_name=' + encodeURIComponent(prefill.legal_name_last)
        prefilledURL +=
          '&lastName=' + encodeURIComponent(prefill.legal_name_last)
      }

      if (prefill.legal_name) {
        prefilledURL += '&full_name=' + encodeURIComponent(prefill.legal_name)
        prefilledURL += '&fullName=' + encodeURIComponent(prefill.legal_name)
        prefilledURL += '&name=' + encodeURIComponent(prefill.legal_name)
      }

      if (prefill.email) {
        prefilledURL += '&email=' + encodeURIComponent(prefill.email)
      }
    }

    return (
      <div className="calendly-widget" style={{ height: '800px' }}>
        <iframe
          title="calendly-widget"
          src={prefilledURL}
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
      </div>
    )
  }
}

export default CalendlyWidget
