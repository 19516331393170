import React, { memo, useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import Loader from '../../partials/Loader'
import {
  createNewTemplate,
  getTemplate,
  updateFormFields,
} from '../../../actions/db/templates'
import StepWizard from 'react-step-wizard'
import { useUser, useFirm } from '../../../contexts'
import { InfoPage, FieldsPage, DocumentPage } from './pages'
import FormBuilderTopBar from './FormBuilderTopBar'
import { FaInfoCircle } from 'react-icons/fa'
import { CgFileDocument } from 'react-icons/cg'
import { TiThMenu } from 'react-icons/ti'
import FormBuilderStepperButtons from './FormBuilderStepperButtons'
import styled from 'styled-components'

const FormBuilder = memo(({ templateID = 'suitability', handleSubmit }) => {
  const { firmID } = useFirm()

  const [template, setTemplate] = useState(null)
  const [saving, setSaving] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [state, updateState] = useState({})
  const creator = useUser().uid

  const steps = [
    {
      idx: 0,
      icon: (color) => <FaInfoCircle color={color} size={48} />,
      label: 'Info',
      page: InfoPage,
    },
    {
      idx: 1,
      icon: (color) => <TiThMenu color={color} size={48} />,
      label: 'Fields',
      page: FieldsPage,
    },
  ]

  if (template && template.signingEvent) {
    steps.push({
      idx: 2,
      icon: (color) => <CgFileDocument color={color} size={48} />,
      label: 'Document',
      page: DocumentPage,
    })
  }

  useEffect(() => {
    if (firmID && templateID) {
      console.log('getting template', templateID)
      getTemplate({ firmID, templateID, setTemplate })
    }
  }, [firmID, templateID])

  useEffect(() => {
    if (saving && saveSuccess) {
      setSaving(false)
    }
  }, [saving, saveSuccess])

  useEffect(() => {
    ;(async () => {
      if (!template && !templateID) {
        console.log('templateID useEffect', templateID)
        await createNewTemplate({ firmID, creator, setTemplate })
      }
    })()
  }, [creator, firmID, template, templateID])

  if (!template && !templateID) {
    return <Loader message="Loading form... please wait." />
  }

  const handleSetTemplate = (data) => {
    setTemplate((oldTemplate) => ({
      ...oldTemplate,
      ...data,
    }))
  }

  const handleSave = async () => {
    // e.preventDefault()
    setSaving(true)

    await updateFormFields({
      firmID,
      templateID,
      template,
      setSaveSuccess,
    })
  }

  const handleSubmitTemplate = async () => {
    await handleSave()
    console.log('Submitting!')
    handleSubmit()
  }

  const renderPages = () => {
    const props = {
      template,
      handleSetTemplate,
      firmID,
    }

    return steps.map((step, pidx) => {
      const PageComponent = step.page
      return <PageComponent key={`${step.label}-${pidx}`} {...props} />
    })
  }

  const setInstance = (SW) =>
    updateState({
      ...state,
      SW,
    })

  const onStepChange = async (stats) => {
    setActiveStep(stats.activeStep)
    await handleSave()
    if (document.querySelectorAll('div.modal-body')[0]) {
      document.querySelectorAll('div.modal-body')[0].scrollTop = 10
    }
  }

  const { SW } = state
  return (
    template && (
      <StyledForm size="huge">
        <StepWizardStyled
          onStepChange={onStepChange}
          nav={
            <FormBuilderTopBar
              SW={SW}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={steps}
            />
          }
          instance={setInstance}
        >
          {renderPages()}
        </StepWizardStyled>
        <ButtonContainer>
          {SW && (
            <FormBuilderStepperButtons
              SW={SW}
              saveTemplate={handleSave}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={steps}
              submitTemplate={handleSubmitTemplate}
            />
          )}
        </ButtonContainer>
      </StyledForm>
    )
  )
})
export default FormBuilder

const ButtonContainer = styled.div`
  margin: auto;
`

const StyledForm = styled(Form)`
  width: 100%;
`

const StepWizardStyled = styled(StepWizard)`
  min-height: 300px;
  padding: 50px;
`
