import { firebaseDB } from '../config/firebaseConfig'
import { navigate } from '@reach/router'
// import { signOutUser } from './authActions'
// import { emailFirmAdvisors } from './emailActions'
import sendFirmRegistrationConfirmations from './email/sendFirmRegistrationConfirmations'
import { addTaskToDB } from './db/taskActions'

// USERS
function addUserToDB(type, uid, state) {
  firebaseDB
    .collection('users')
    .doc(uid)
    .set({ type: type })
    .then(function (docRef) {
      // console.log("User added with ID: ", docRef.id)

      if (type === 'firm') {
        navigate('/')
      }

      if (type === 'advisor') {
        navigate('/')
      }
    })
    .catch(function (error) {
      // console.error("Error adding user: ", error)
    })
}

// CPS
export function getCPS(uid, state) {
  return firebaseDB
    .collection('cps')
    .doc(uid)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        state.setState({
          errors: ['There was a CPS error! Please contact Bitsy Advisor.'],
        })
      } else {
        return doc.data().p
      }
    })
    .catch((err) => {
      state.setState({
        errors: ['There was a CPS error! ' + err],
      })
      // console.log('Error getting document', err);
    })

  // 	function(doc) {
  // 	if (doc.data()) {

  // 		return doc.data().p
  // 		// state.setState({
  //   //    	password: doc.data().p
  //   //    })
  // 	} else {

  // 	}
  // })
}

export function getDashboardCPS(uid, state) {
  firebaseDB
    .collection('cps')
    .doc(uid)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        state.setState({
          cpsErrors: ['There was a CPS error! Please contact Bitsy Advisor.'],
        })
      } else {
        state.setState({
          cps: doc.data().p,
        })
      }
    })
    .catch((err) => {
      state.setState({
        cpsErrors: ['There was a CPS error! ' + err],
      })
      // console.error('Error getting document', err)
    })
}

export function addCPSToDB(uid, cps) {
  firebaseDB
    .collection('cps')
    .doc(uid)
    .set({ p: cps })
    .then(function (docRef) {
      // console.log("CPS added with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding CPS: ", error)
    })
}

export function updateCPS(uid, cps, state) {
  firebaseDB
    .collection('cps')
    .doc(uid)
    .update({ p: cps })
    .then(function () {
      // console.log("CPS updated")
    })
    .catch(function (error) {
      // console.error("Error updating CPS: ", error)
    })
}

// CLIENTS
export function addClientToDB(client, uid, state) {
  client.dateAdded = new Date()

  firebaseDB
    .collection('clients')
    .doc(uid)
    .set(client)
    .then(function (docRef) {
      state.setState(
        {
          addSuccess: true,
          addedUID: uid,
        },
        function () {
          addUserToDB('client', uid, state)
        }
      )
      // console.log("Client added with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding client: ", error)
      state.setState({
        errors: ['Error adding client: ' + error],
      })
    })
}

export function addLegacyClients(
  firmID,
  clients,
  advisorID,
  advisorName,
  state
) {
  // Get a new write batch
  var batch = firebaseDB.batch()

  Object.keys(clients).map(function (clientID) {
    var clientObject = clients[clientID]
    clientObject['firm'] = firmID

    if (advisorID) {
      clientObject['advisor'] = advisorID
    }

    if (advisorName) {
      clientObject['advisor_name'] = advisorName
    }

    var clientRef = firebaseDB.collection('clients').doc(clientID)
    batch.set(clientRef, clientObject)

    return ''
  })

  // Commit the batch
  batch.commit().then(function () {
    state.setState({
      addSuccess: true,
    })
  })
}

let unsubscribeClientDetails
export function getClientDetails(client, state) {
  unsubscribeClientDetails = firebaseDB
    .collection('clients')
    .doc(client)
    .onSnapshot(function (doc) {
      if (doc.data()) {
        state.setState({
          client: doc.data(),
        })
      }
    })
}
export function stopListeningToClientDetails() {
  // Stop listening to changes
  if (unsubscribeClientDetails) {
    unsubscribeClientDetails()
  }
}

export function addClientUploadedIACToDB(name, url, uid) {
  // firebaseDB.collection("clients").doc(uid).update({
  // 	iac_uploaded: {
  // 		name: name,
  // 		link: url
  // 	}
  // })
  // 	.then(function(docRef) {
  // 	    // console.log("Client IAC updated with ID: ", docRef.id)
  // 	})
  // 	.catch(function(error) {
  // 	    // console.error("Error adding client IAC: ", error)
  // 	})
}

export function setIntegrationContactID(service, clientID, contactID) {
  firebaseDB
    .collection('clients')
    .doc(clientID)
    .update({
      ['integrations.' + service + '.contactID']: contactID,
      ['integrations.' + service + '.contactIDPending']: null,
    })
    .then(function (docRef) {
      // state.setState({
      // 	'addSuccess': true
      // })
      // console.log("Client updated with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding client: ", error)
    })
}

// ADVISORS
export function addAdvisorToDB(advisor, uid, state) {
  advisor.dateAdded = new Date()

  firebaseDB
    .collection('advisors')
    .doc(uid)
    .set(advisor)
    .then(function (docRef) {
      addUserToDB('advisor', uid, state)
      // console.log("Advisor added with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding advisor: ", error)
      // console.error(error)
    })
}

export function addAdminAdvisorToDB(advisor, uid) {
  firebaseDB
    .collection('advisors')
    .doc(uid)
    .set(advisor)
    .then(function (docRef) {
      // console.log("Advisor added with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding advisor: ", error)
      // console.error(error)
    })
}

// export function updateAdvisorStatus(advisorID, value) {
// 	firebaseDB.collection("advisors").doc(advisorID).update({
// 		inactive: value
// 	})
// 		.then(function (docRef) {
// 			// console.log("Logo added with ID: ", docRef.id)
// 		})
// 		.catch(function (error) {
// 			// console.error("Error adding logo: ", error)
// 		})
// }

let unsubscribeAdvisorClients
export function getAdvisorClients(advisor, state) {
  unsubscribeAdvisorClients = firebaseDB
    .collection('clients')
    .where('advisor', '==', advisor)
    .onSnapshot(function (querySnapshot) {
      var clients = {}
      querySnapshot.forEach(function (doc) {
        clients[doc.id] = doc.data()
      })

      state.setState({
        clients: clients,
      })
    })
}
export function stopListeningToAdvisorClients() {
  // Stop listening to changes
  unsubscribeAdvisorClients()
}

export function addAdvisorDisclosureToDB(name, url, uid) {
  firebaseDB
    .collection('advisors')
    .doc(uid)
    .collection('disclosures')
    .add({
      name: name,
      link: url,
    })
    .then(function (docRef) {
      // console.log("Disclosure added with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding disclosure: ", error)
    })
}

// FIRMS
export function addFirmToDB(firm, uid, state, email) {
  firm.dateAdded = new Date()
  firm.email = email

  // Change '$0' portfolio max to '+'
  firm.annual_fees.map(function (annual_fee, idx) {
    if (annual_fee.portfolio_max === '$0') {
      annual_fee.portfolio_max = '+'
    }
    return annual_fee
  })

  firebaseDB
    .collection('firms')
    .doc(uid)
    .set(firm)
    .then(function (docRef) {
      addUserToDB('firm', uid, state)

      sendFirmRegistrationConfirmations(
        firm.name,
        firm.email,
        firm.contact_name
      )

      if (firm.advisors && firm.advisors.length) {
        const taskOptions = {
          advisors: firm.advisors,
          firmID: uid,
          firmName: firm.name,
        }
        addTaskToDB('emailSendAdvisorInvites', new Date(), taskOptions)
      }

      // emailFirmAdvisors(uid, firm.name, firm.advisors)

      // Create an advisor doc for admin advisor
      if (firm.adminIsAdvisor) {
        const adminAdvisor = {
          firm: uid,
          name: firm.contact_name,
          crd: firm.admin_advisor_crd,
          inactive: false,
          // signature: firm.signing_party.signature,
          email: firm.admin_advisor_email,
          dateAdded: new Date(),
        }

        addAdminAdvisorToDB(adminAdvisor, uid)
      }

      // console.log("Firm added with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding firm: ", error)
    })
}

let unsubscribeFirmClients
export function getFirmClients(firm, state) {
  unsubscribeFirmClients = firebaseDB
    .collection('clients')
    .where('firm', '==', firm)
    .onSnapshot(function (querySnapshot) {
      const changes = querySnapshot.docChanges()

      if (changes.length > 0) {
        var clients = {}

        querySnapshot.forEach(function (doc) {
          clients[doc.id] = doc.data()
        })

        state.setState({
          clients: clients,
        })
      }
    })
}
export function stopListeningToFirmClients() {
  // Stop listening to changes
  unsubscribeFirmClients()
}

let unsubscribeFirmIntegrations
export function getFirmIntegrations(firm, state) {
  unsubscribeFirmIntegrations = firebaseDB
    .collection('integrations')
    .doc(firm)
    .onSnapshot(function (doc) {
      if (doc.data()) {
        state.setState({
          integrations: doc.data(),
        })
      }
    })
}
export function stopListeningToFirmIntegrations() {
  // Stop listening to changes
  unsubscribeFirmIntegrations()
}

export function addFirmDisclosureToDB(name, url, uid) {
  firebaseDB
    .collection('firms')
    .doc(uid)
    .collection('disclosures')
    .add({
      name: name,
      link: url,
    })
    .then(function (docRef) {
      // console.log("Disclosure added with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding disclosure: ", error)
    })
}

export function updateFirmModuleSetting(uid, oldSettings, field, value) {
  var newModule = oldSettings

  newModule[field] = value

  firebaseDB
    .collection('firms')
    .doc(uid)
    .update({
      module: newModule,
    })
    .then(function (docRef) {
      // console.log("Logo added with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding logo: ", error)
    })
}

// ADVs
export function getDefaultADVCopy(adv, editorState) {
  var docRef = firebaseDB.collection('default').doc('advCopy')

  docRef
    .get()
    .then(function (doc) {
      if (doc.exists) {
        editorState.setState({
          defaultCopy: doc.data(),
        })
      } else {
        // doc.data() will be undefined in this case
        console.error(
          "Sorry, can't find default copy currently! Please contact support."
        )
      }
    })
    .catch(function (error) {
      console.error('Error getting default info:', error)
    })
}

// CLIENTS

export function addOnboardedClientToDB(client, state, uid) {
  client.dateAdded = new Date()

  firebaseDB
    .collection('clients')
    .doc(uid)
    .update(client)
    .then(function (docRef) {
      state.setState({
        addSuccess: true,
      })
      //  console.log("Client added with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding client: ", error)
    })
}

export function addIACToDB(file, uid, iacTitle, iacDate, allIACs) {
  var allIACsArray = []
  if (allIACs) {
    allIACsArray = allIACs
  }

  allIACsArray.push({
    link: file,
    name: iacTitle,
    date: iacDate,
  })

  firebaseDB
    .collection('clients')
    .doc(uid)
    .update({
      hasPendingIAC: false,
      iac: file,
      iacs: allIACsArray,
    })
    .then(function (docRef) {
      // console.log("IAC added with ID: ", docRef)
    })
    .catch(function (error) {
      // console.error("Error adding IAC: ", error)
    })
}

export function getClientDoc(uid) {
  // console.log('getClientDoc')
  // console.log(uid)

  return firebaseDB
    .collection('clients')
    .doc(uid)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        var docData = doc.data()

        if (!docData.legal_name) {
          docData['legal_name'] =
            docData.legal_name_first + ' ' + docData.legal_name_last
        }

        return docData
      } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!")
        return null
      }
    })
    .catch(function (error) {
      console.error('Error getting clientdoc:', error)
      return null
    })
}

export function updateClient(
  client,
  state = null,
  uid,
  removePendingChanges = false,
  removePendingSuitabilityForm = false,
  saveOnlySuccess = false
) {
  client.dateUpdated = new Date()

  if (removePendingChanges) {
    client.pendingChanges = null
  }

  if (removePendingSuitabilityForm) {
    client.hasPendingSuitabilityForm = false
  }

  firebaseDB
    .collection('clients')
    .doc(uid)
    .update(client)
    .then(function (docRef) {
      if (state) {
        state.setState({
          [saveOnlySuccess ? 'saveOnlySuccess' : 'addSuccess']: true,
        })
      }

      // console.log("Client updated with ID: ", docRef.id)
    })
    .catch(function (error) {
      // console.error("Error adding client: ", error)
    })
}

// ADVISORS

let unsubscribeFirmAdvisors
export function getFirmAdvisors(firm, state) {
  unsubscribeFirmAdvisors = firebaseDB
    .collection('advisors')
    .where('firm', '==', firm)
    .onSnapshot(function (querySnapshot) {
      var advisors = {}
      querySnapshot.forEach(function (doc) {
        advisors[doc.id] = doc.data()
      })

      state.setState({
        ...state.state,
        advisors: advisors,
      })
    })
}
export function stopListeningToFirmAdvisors() {
  // Stop listening to changes
  unsubscribeFirmAdvisors()
}

let unsubscribeAdvisorDetails
export function getAdvisorDetails(advisor, state) {
  unsubscribeAdvisorDetails = firebaseDB
    .collection('advisors')
    .doc(advisor)
    .onSnapshot(function (doc) {
      state.setState({
        ...state.state,
        advisor: doc.data(),
      })
    })
}
export function stopListeningToAdvisorDetails() {
  // Stop listening to changes
  unsubscribeAdvisorDetails()
}

let unsubscribeAdvisorDisclosures
export function getAdvisorDisclosures(advisor, state) {
  unsubscribeAdvisorDisclosures = firebaseDB
    .collection('advisors')
    .doc(advisor)
    .collection('disclosures')
    .onSnapshot(function (querySnapshot) {
      var disclosures = {}
      querySnapshot.forEach(function (doc) {
        disclosures[doc.id] = doc.data()
      })

      state.setState({
        advisorDisclosures: disclosures,
      })
    })
}
export function stopListeningToAdvisorDisclosures() {
  // Stop listening to changes
  unsubscribeAdvisorDisclosures()
}

// FIRMS

let unsubscribeFirmDetails
export function getFirmDetails(firm, state) {
  unsubscribeFirmDetails = firebaseDB
    .collection('firms')
    .doc(firm)
    .onSnapshot(function (doc) {
      state.setState({
        ...state.state,
        firm: doc.data(),
      })
    })
}
export function stopListeningToFirmDetails() {
  // Stop listening to changes
  unsubscribeFirmDetails()
}

let unsubscribeFirmDisclosures
export function getFirmDisclosures(firm, state) {
  unsubscribeFirmDisclosures = firebaseDB
    .collection('firms')
    .doc(firm)
    .collection('disclosures')
    .onSnapshot(function (querySnapshot) {
      var disclosures = {}
      querySnapshot.forEach(function (doc) {
        disclosures[doc.id] = doc.data()
      })

      state.setState({
        firmDisclosures: disclosures,
      })
    })
}
export function stopListeningToFirmDisclosures() {
  // Stop listening to changes
  unsubscribeFirmDisclosures()
}

// Generic - user info
export function updateUserInfo(userType, uid, field, value, state = null) {
  var collection = userType
  if (userType === 'firm') {
    collection += 's'
  }
  if (userType === 'client') {
    collection += 's'
  }

  firebaseDB
    .collection(collection)
    .doc(uid)
    .update({
      [field]: value,
    })
    .then(function (docRef) {
      if (state) {
        state.setState({
          addSuccess: true,
        })
      }
    })
    .catch(function (error) {
      if (state) {
        state.setState({
          errors: [error],
        })
      }
    })
}
