export const cleanObject = (obj) => {
  var newObj = {}

  const objectArray = Object.entries(obj)

  objectArray.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      var newArray = []
      if (value.length) {
        newArray.push(cleanObject(value))
        return
      }
      newObj[key] = newArray
    } else if (typeof value === 'object') {
      if (value && typeof value !== 'undefined') {
        newObj[key] = cleanObject(value)
        return
      }
    } else if (value !== '') {
      newObj[key] = value
    }

    return
  })

  return newObj
}

export const removeEmptyObjects = (obj) => {
  var newObj = {}

  const objectArray = Object.entries(obj)

  objectArray.forEach(([key, value]) => {
    if (Object.keys(value).length === 0 && obj.constructor === Object) {
      return
    }
    newObj[key] = value
  })

  return newObj
}
