import React, { memo, useState, useEffect } from 'react'
import { equals } from 'ramda'
import { Button, Icon, Label } from 'semantic-ui-react'
import Modal from 'components/partials/LegacyModal'
import Card from 'components/dashboards/partials/LegacyCard'
import Field from 'components/forms/Field'
import { platforms } from './_config'
import styled from 'styled-components'
import { colors } from 'styles'
import { ButtonsGrid } from 'styles/_mixins'
import { ColSm6ColMd3, Row } from 'components/styled'

export const renderPlatformLogo = (platform, platformName) => {
  if (!platform) {
    return ''
  }

  if (platform.logo) {
    return <img src={platform.logo} alt={platformName} />
  }
  return <div className="platform-name">{platformName}</div>
}

const createInitialFundsAllocationFields = () => {
  let fundsAllocationFields = {}

  platforms &&
    Object.keys(platforms).forEach((platformName) => {
      const platform = platforms[platformName]

      fundsAllocationFields[platformName] = {}

      platform &&
        platform.models &&
        Object.keys(platform.models) &&
        Object.keys(platform.models).forEach((modelNumber, midx) => {
          fundsAllocationFields[platformName][modelNumber] = '0%'
        })
    })

  return fundsAllocationFields
}

const FundAllocator = memo(
  ({ id, value, setClientFundsAllocationFields, onLocalChange }) => {
    const [fundsAllocationFields, setFundsAllocationFields] = useState(
      createInitialFundsAllocationFields()
    )
    const [remainingFundsPercent, setRemainingFundsPercent] = useState(100)
    const [remainingFundsStatus, setRemainingFundsStatus] = useState(null)
    const [windowOpen, setWindowOpen] = useState(false)
    const [completedWindowOpen, setCompletedWindowOpen] = useState(false)
    const [selectedPlatform, setSelectedPlatform] = useState(null)

    useEffect(() => {
      if (value && Object.keys(value) && Object.keys(value).length) {
        setFundsAllocationFields(value)
      }
    }, [value])

    // Open completed window at 100% allocation
    useEffect(() => {
      if (remainingFundsPercent === 0) {
        setCompletedWindowOpen(true)
      }
    }, [remainingFundsPercent])

    useEffect(() => {
      let remainingFunds = 100
      if (fundsAllocationFields && Object.keys(fundsAllocationFields)) {
        Object.keys(fundsAllocationFields).forEach((platformName) => {
          if (
            fundsAllocationFields[platformName] &&
            Object.keys(fundsAllocationFields[platformName])
          ) {
            Object.keys(fundsAllocationFields[platformName]).forEach(
              (modelNumber) => {
                const modelNumberPercentage =
                  fundsAllocationFields[platformName][modelNumber]

                if (modelNumberPercentage && modelNumberPercentage !== '0%') {
                  remainingFunds -= parseFloat(
                    modelNumberPercentage.replace('%', '')
                  )
                }
              }
            )
          }
        })
      }
      setRemainingFundsPercent(remainingFunds)

      switch (Math.sign(remainingFunds)) {
        case -1:
          setRemainingFundsStatus('danger')
          break
        case 0:
        case -0:
          setRemainingFundsStatus('success')
          break
        default:
          setRemainingFundsStatus(null)
          break
      }
    }, [fundsAllocationFields])

    const handleClose = () => {
      setClientFundsAllocationFields &&
        setClientFundsAllocationFields(id, fundsAllocationFields)
      setWindowOpen(false)
    }

    const handleInputChange = (e) => {
      let newFundsAllocationFields = fundsAllocationFields

      const nameSplit = e.target.name.split('-')
      const platformName = nameSplit[0].replace('_', ' ')
      const modelNumber = nameSplit[1]

      newFundsAllocationFields[platformName][modelNumber] = e.target.value

      setFundsAllocationFields((initialFundsAllocationFields) =>
        Object.assign(
          {},
          initialFundsAllocationFields,
          newFundsAllocationFields
        )
      )

      onLocalChange && onLocalChange(newFundsAllocationFields)
    }

    const handleContinue = () => {
      setSelectedPlatform(null)
      handleClose()
      setCompletedWindowOpen(false)
    }

    const initialFundsAllocationFields = createInitialFundsAllocationFields()

    // TODO: [BTS-710] FundAllocator preset logic

    return (
      <>
        <Button
          content="Allocate Models"
          primary
          size="huge"
          className="d-block mx-auto"
          onClick={(e) => {
            e.preventDefault()
            setWindowOpen(true)
          }}
        />

        <FundAllocatorModal
          title={`Model Allocation - ${100 - remainingFundsPercent}% allocated`}
          isOpen={windowOpen}
          fullScreen={true}
          className="fund-allocator-modal"
          handleClose={handleClose}
        >
          <p className="instructional text-center">
            Build your client portfolio below
          </p>

          <Row>
            {platforms &&
              Object.keys(platforms).map((platformName, idx) => {
                const platform = platforms[platformName]
                const hasFunds = !equals(
                  fundsAllocationFields[platformName],
                  initialFundsAllocationFields[platformName]
                )

                let allocatedFundsTotal = 0
                if (hasFunds && fundsAllocationFields[platformName]) {
                  Object.keys(fundsAllocationFields[platformName]).forEach(
                    (modelName) => {
                      const modelPercentage =
                        fundsAllocationFields &&
                        fundsAllocationFields[platformName] &&
                        fundsAllocationFields[platformName][modelName]
                          ? fundsAllocationFields[platformName][modelName]
                          : null

                      if (modelPercentage && modelPercentage !== '0%') {
                        const modelPercentageNum = parseFloat(
                          modelPercentage.replace('%', '')
                        )

                        allocatedFundsTotal += modelPercentageNum
                      }
                    }
                  )
                }

                let className = `button-grid-button button-grid-button-${platformName}`
                if (hasFunds) {
                  className += ' activated'
                }

                return (
                  <ColSm6ColMd3 key={idx}>
                    <button
                      className={className}
                      onClick={(e) => {
                        e.preventDefault()
                        setSelectedPlatform(platformName)
                      }}
                    >
                      {renderPlatformLogo(platform, platformName)}
                      {hasFunds && (
                        <Badge
                          icon="check"
                          content={`${allocatedFundsTotal}%`}
                          primary="true"
                          circular
                          floating
                        />
                      )}
                    </button>
                  </ColSm6ColMd3>
                )
              })}
          </Row>
          <SummaryAmount className="instructional text-center">
            Total Funds Allocated in Models:{' '}
            <b>{100 - remainingFundsPercent}%</b>
          </SummaryAmount>
          <ContinueButton className="instructional text-center">
            <Button
              content="All set, continue"
              size="huge"
              type="submit"
              circular
              icon="right arrow"
              primary
              onClick={handleClose}
              labelPosition="right"
            />
          </ContinueButton>
        </FundAllocatorModal>

        <FundAllocatorPlatformModal
          title={`Allocate Models at ${selectedPlatform}`}
          isOpen={selectedPlatform}
          handleClose={() => setSelectedPlatform(null)}
        >
          {renderPlatformLogo(platforms[selectedPlatform], selectedPlatform)}
          {platforms[selectedPlatform] &&
          platforms[selectedPlatform].models &&
          Object.keys(platforms[selectedPlatform].models)
            ? Object.keys(platforms[selectedPlatform].models).map(
                (modelNumber, midx) => {
                  const modelName =
                    platforms[selectedPlatform].models[modelNumber]

                  return (
                    <div key={`${midx}-${modelNumber}`} className="model-row">
                      <div className="name">{modelName}</div>
                      <div className="number">{modelNumber}</div>

                      <Field
                        type="percentage"
                        placeholder="(ex. 1.5%)"
                        id={`${selectedPlatform.replace(
                          ' ',
                          '_'
                        )}-${modelNumber}`}
                        label="Percentage"
                        value={
                          fundsAllocationFields[selectedPlatform][modelNumber]
                        }
                        handleInputChange={handleInputChange}
                      />
                    </div>
                  )
                }
              )
            : 'No models are specified for this platform yet. Please contact Bitsy to add some!'}
          <div className="text-center">
            <Button
              content="Next"
              type="submit"
              circular
              icon="right arrow"
              primary
              onClick={() => setSelectedPlatform(null)}
              labelPosition="right"
            />
          </div>
          <div
            className={`total-funds-remaining${
              remainingFundsStatus ? ` text-${remainingFundsStatus}` : ''
            }`}
          >
            {remainingFundsPercent}% of total funds remain
          </div>
        </FundAllocatorPlatformModal>

        <CompletedModal
          title={'Model Allocation is complete!'}
          isOpen={completedWindowOpen}
          handleClose={() => setCompletedWindowOpen(false)}
        >
          <Icon color="teal" size="huge" name="pie chart" />
          <h2>You did it!</h2>
          <p>Model allocation is now complete.</p>
          <Button
            content="Continue"
            primary
            size="large"
            onClick={handleContinue}
          />
        </CompletedModal>

        {remainingFundsPercent !== 100 && platforms && (
          <Summary>
            <SummaryTitle>Summary of Models:</SummaryTitle>
            {Object.keys(platforms).map((platformName, idx) => {
              const hasFunds = !equals(
                fundsAllocationFields[platformName],
                initialFundsAllocationFields[platformName]
              )

              const models =
                platforms[platformName] && platforms[platformName].models

              if (
                hasFunds &&
                fundsAllocationFields[platformName] &&
                models &&
                Object.keys(models)
              ) {
                return (
                  <Card key={idx} title={platformName}>
                    {Object.keys(models).map((modelNumber, midx) => {
                      const modelName = models[modelNumber]
                      const amount =
                        fundsAllocationFields[platformName][modelNumber]

                      if (amount === '0%') {
                        return ''
                      }

                      return (
                        <SummaryModelRow
                          key={`${midx}-${modelNumber}`}
                          className="model-row"
                        >
                          <div className="number">{modelNumber}</div>
                          <div className="name">{modelName}</div>
                          <div className="amount">
                            <Field
                              type="percentage"
                              placeholder="(ex. 1.5%)"
                              id={`${platformName.replace(
                                ' ',
                                '_'
                              )}-${modelNumber}`}
                              label="Percentage"
                              value={
                                fundsAllocationFields[platformName][modelNumber]
                              }
                              handleInputChange={handleInputChange}
                            />
                          </div>
                        </SummaryModelRow>
                      )
                    })}
                  </Card>
                )
              }

              return ''
            })}
          </Summary>
        )}
      </>
    )
  }
)

export default FundAllocator

const StyledModal = styled(Modal)`
  &&& {
    h4.modal-title {
      color: inherit;
      text-transform: inherit;
      font-size: inherit;
      letter-spacing: inherit;
      line-height: inherit;
    }
  }
`

const FundAllocatorModal = styled(StyledModal)`
  ${ButtonsGrid()};

  .modal-body {
    padding: 30px !important;
  }
`

const FundAllocatorPlatformModal = styled(StyledModal)`
  width: auto !important;
  margin-top: 30px !important;

  .content {
    max-width: 640px;
  }

  img {
    max-width: 400px;
    margin: 20px auto;
  }

  .modal-body {
    padding: 30px !important;
  }

  .model-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 580px;
    margin: 10px auto;
    width: 100%;

    & > * {
      flex-grow: 0;
      flex-shrink: 0;
      padding: 4px;
    }

    .name {
      flex-grow: 1;
      flex-basis: 40%;
      font-weight: bold;
    }

    .number {
      flex-basis: 20%;
      text-align: center;
    }

    .bitsy-form-group {
      flex-basis: 25%;
      margin-bottom: 0;
    }

    .bitsy-form-group input {
      font-weight: bold;
      text-align: center;
    }
  }

  .total-funds-remaining {
    text-align: right;
    font-style: italic;
    max-width: 572px;
    margin: 20px auto;
    opacity: 0.7;
  }
`

const Badge = styled(Label)`
  &&& {
    background: ${colors.primary};
    color: #fff;
    text-align: center;
    min-width: 36px;

    .icon {
      margin-right: 0;
    }
  }
`

const SummaryAmount = styled.p`
  margin: 30px auto !important;
  font-size: 150%;
  font-style: initial;
  opacity: 1;
`

const ContinueButton = styled.p`
  margin: 30px auto !important;
`

const CompletedModal = styled(Modal)`
  &&& {
    text-align: center;

    & .modal-body {
      padding: 4rem 1rem;
    }

    & .icon {
      margin: 0 auto;
    }
  }
`

const Summary = styled.div`
  margin: 60px auto;
`

const SummaryTitle = styled.div`
  font-size: 140%;
  font-style: italic;
  opacity: 0.7;
  margin: 30px auto;
  text-align: center;
`

const SummaryModelRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 120%;

  .name {
    font-weight: bold;
  }

  .number,
  .amount {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .number {
    flex-basis: 100px;
    text-align: center;
  }

  .amount {
    flex-basis: 100px;
    text-align: center;

    .form-group {
      margin-bottom: 0;
    }

    .form-group label {
      display: none;
    }
  }
`
