import React, { memo } from 'react'
import { Select } from 'semantic-ui-react'

const FieldSelector = memo(
  ({ value, options, handleChange, loading, service }) => {
    return (
      <Select
        placeholder="Select a Field"
        fluid
        search
        clearable
        value={value}
        options={options}
        onChange={(e, { value }) => {
          handleChange(service, value)
        }}
        loading={loading}
      />
    )
  }
)

export default FieldSelector
