import React, { memo } from 'react'
import { useProfile } from 'contexts'
import styled from 'styled-components'

const ProfileLinkButton = memo(({ content, profile, ...props }) => {
  const { setProfile } = useProfile()

  const openProfile = () => {
    setProfile(profile)
  }

  return (
    <StyledButton
      {...props}
      className={`link ${props.className || ''}`}
      onClick={openProfile}
    >
      {content}
    </StyledButton>
  )
})

export default ProfileLinkButton

const StyledButton = styled.button`
  text-align: left;
`
