import React, { memo, useState } from 'react'
import Field from 'components/forms/Field'
import ErrorsList from 'components/partials/ErrorsList'
import Error from 'components/partials/Error'
import { validEmailRegex } from 'config/regexPatterns'
import { updateItemInfo } from 'actions/db/dbActions'
import { addTaskToDB } from 'actions/db/taskActions'
import styled from 'styled-components'

const AdvisorInviteForm = memo(
  ({
    advisors = [{ email: '' }],
    isInRegistrationFlow = false,
    handleInputChange = null,
    firmAdminAdvisorEmail = null,
    firmID = null,
    firmName = null,
    allowedAdvisors = [],
    errors = [],
  }) => {
    const [inviteAdvisors, setInviteAdvisors] = useState(advisors)
    const [inviteErrors, setInviteErrors] = useState(errors)
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(null)

    const handleInviteAdvisorInputChange = (id, fields) => {
      setSuccess(null)

      if (isInRegistrationFlow && handleInputChange) {
        handleInputChange(id, fields)
      }

      setInviteAdvisors(fields)
    }

    const handleSubmit = async () => {
      setSubmitting(true)

      // Validate fields
      let errors = []
      inviteAdvisors.forEach((inviteAdvisor) => {
        const { email } = inviteAdvisor

        let errorMessage = ''
        if (!email) {
          errorMessage = 'Please enter a valid email for each advisor.'
        } else if (!validEmailRegex.test(email)) {
          errorMessage = `${email} is not a valid email.`
        }

        if (errorMessage) {
          errors.push(errorMessage)
        }
      })

      if (errors.length) {
        setInviteErrors(errors)
        setSubmitting(false)
      } else {
        setInviteErrors([])

        // Add to accepted firm.advisors array
        const allAllowedAdvisors = allowedAdvisors.concat(inviteAdvisors)
        await updateItemInfo('firm', firmID, 'advisors', allAllowedAdvisors)

        const taskOptions = {
          advisors: inviteAdvisors,
          firmID,
          firmName,
        }
        addTaskToDB(
          'emailSendAdvisorInvites',
          new Date(),
          taskOptions,
          null,
          null,
          setSuccess
        )
      }
    }

    const handleReset = () => {
      setInviteAdvisors([{ email: '' }])
      setInviteErrors([])
      setSubmitting(false)
      setSuccess(null)
    }

    return (
      <InviteFormContainer>
        <Field
          type="text-multiple"
          id="advisors"
          label="Advisors"
          value={inviteAdvisors}
          handleInputChange={handleInviteAdvisorInputChange}
          showLabel={true}
          listClassName="team-members"
          readOnly={submitting || success}
          textMultipleFields={[{ id: 'email', label: 'Email', type: 'email' }]}
          textMultipleInitialFields={
            firmAdminAdvisorEmail && (
              <li>
                <Field
                  type="email"
                  id="admin_advisor_email"
                  label="Email"
                  value={firmAdminAdvisorEmail}
                  handleInputChange={null}
                  readOnly={true}
                />
              </li>
            )
          }
        />

        <ErrorsList errors={inviteErrors} />

        {success ? (
          <>
            <div className="banner banner-success">
              Advisors invited successfully!
            </div>
            <button type="submit" onClick={handleReset}>
              Start over
            </button>
          </>
        ) : (
          <>
            {!handleInputChange && (
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={submitting ? true : false}
              >
                Invite
              </button>
            )}
            {success === false && (
              <Error
                message="There was an error. Please try again and contact Bitsy if the problem persists."
                code="INVADV"
                level="error"
                className="banner text-red"
                errorInfo={{
                  Location: 'Advisor Invite Form',
                  Details: { advisors: inviteAdvisors },
                }}
                userInfo={{ Firm: firmName, 'Firm ID': firmID }}
              />
            )}
          </>
        )}
      </InviteFormContainer>
    )
  }
)

export default AdvisorInviteForm

const InviteFormContainer = styled.div`
  & > .form-group {
    & > label {
      display: none;
    }

    & > .bitsy-input-wrapper > ul {
      padding-left: 0;
      list-style: none;
    }
  }
`
