import firebase from 'firebase/app'

import { firebaseDB } from '../../../config/firebaseConfig'

export function deauthenticateRiskalyze(userID) {
  firebaseDB
    .collection('integrations')
    .doc(userID)
    .update({ riskalyze: firebase.firestore.FieldValue.delete() })
}
