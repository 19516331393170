import React, { useState, useEffect, useRef } from 'react'
import { unescape } from 'lodash'
import StepWizard from 'react-step-wizard'
import IMask from 'imask'
import styled from 'styled-components'
import { Message, Step } from 'semantic-ui-react'
import BitsyErrorsList from 'components/partials/ErrorsList'
import CustomForm from '../CustomForm'
import FieldTemplate from 'components/forms/tf-fields/FieldTemplate'
import { customWidgets } from 'components/forms/tf-fields'
import ErrorList from '../ErrorList'
import SubmitSection from '../SubmitSection'
import {
  availableFields,
  requiredBitsyFields,
} from 'components/forms/tf-fields/_config'
import {
  generateFieldJsonSchemaProperties,
  generateFieldCustomUiSchema,
  processCustomDefinitions,
  processSchemaDependencies,
  transformErrors,
} from 'helpers/rjsf'
import { doFirmCalculations, doFirmValidations } from 'helpers/firms'
import {
  legacyCalculateInvestmentProfiles,
  legacySetInitialInvestmentExperienceItems,
} from 'helpers/legacy'
import { formatLegalName } from 'actions/helpers'
import AdvisorSelect from 'components/forms/tf-fields/AdvisorSelect'
import DisclosuresAgreement from 'components/forms/tf-fields/DisclosuresAgreement'
import TaxIDField from 'components/forms/tf-fields/TaxIDField'
import updateClientInDB from 'actions/db/clients/update'
import { colors } from 'styles'
// import { JalinskiAccountRegistrationsField } from '../../../../forms/tf-fields/jalinski'
import { useFirm, useUser } from 'contexts'
import './TemplateFormFields.scss' // TODO: [BTS-677] Utilize styled components for TemplateFormFields
import { prepClientForDB } from 'helpers/db'
import updateClientEmail from 'actions/db/clients/updateEmail'
import InputBar from 'components/styled/InputBar'
import { firmIDs } from 'config/firmConfig'

const TemplateFormFields = ({
  template,
  value,
  clientID,
  handleInputBlur,
  handleInputChange,
  handleContinue,
  selectedAdvisorID,
  acceptDisclosures,
  additionalDocuments,
  handleSelectAdvisor,
  handleDisclosuresAgreement,
  handleSetLocalClient,
  saveAsPendingChanges,
  flow,
  finalizingAtEnd,
}) => {
  const { user, uid } = useUser()
  const { firm } = useFirm()
  const [activeStep, setActiveStep] = useState(1)
  const latestStep = useRef(activeStep)
  const [wizardState, updateWizardState] = useState({})
  const [steps, setSteps] = useState([])
  const [stepTitles, setStepTitles] = useState([])
  const [completedStepTitles, setCompletedStepTitles] = useState([])
  const [schemaProperties, setSchemaProperties] = useState([])
  const [schemaRequired, setSchemaRequired] = useState([])
  const [schemaDependencies, setSchemaDependencies] = useState({})
  const [uiSchema, setUiSchema] = useState({})
  const [savingToDB, setSavingToDB] = useState(false)
  const [savingToDBErrors, setSavingToDBErrors] = useState([])
  const [
    allowUserToBypassValidation,
    setAllowUserToBypassValidation,
  ] = useState(false)
  const [theme, setTheme] = useState('default')

  useEffect(() => {
    if (user && [user.firm, uid].includes(firmIDs['gwn'])) {
      setTheme('high-contrast')
    }
  }, [user, uid])

  useEffect(() => {
    if (
      template &&
      template.routing &&
      template.routing === 'round-trip' &&
      ['create', 'edit'].includes(flow) &&
      !finalizingAtEnd
    ) {
      setAllowUserToBypassValidation(true)
    }
  }, [template, flow, finalizingAtEnd])

  useEffect(() => {
    if (template && template.fields && template.fields.length) {
      generateSchemaFieldData(template.fields, allowUserToBypassValidation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, allowUserToBypassValidation])

  // Set initial legacy investment_experience_items if none have been set
  useEffect(() => {
    legacySetInitialInvestmentExperienceItems({
      schema: schemaProperties[activeStep - 2],
      value,
      handleInputChange,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, schemaProperties, value])

  useEffect(() => {
    let allSteps = []

    // Add advisor select step
    allSteps.push({ title: 'Advisor', type: 'advisor-select' })

    if (schemaProperties && schemaProperties.length) {
      allSteps.push(...schemaProperties)
    }

    // Add tax ID
    if (
      template &&
      template.includeTaxID &&
      (template.routing !== 'round-trip' ||
        (template.routing === 'round-trip' && flow === 'final-review') ||
        (template.routing === 'round-trip' &&
          ['create', 'edit'].includes(flow) &&
          !allowUserToBypassValidation))
    ) {
      allSteps.push({ title: 'Tax ID', type: 'tax-id' })
    }

    // Add disclosures agreement
    if (
      template &&
      template.includeDisclosures &&
      template.showDisclosuresPage
    ) {
      allSteps.push({ title: 'Disclosures', type: 'disclosures-agreement' })
    }

    setSteps(allSteps)
  }, [schemaProperties, template, flow, allowUserToBypassValidation])

  useEffect(() => {
    if (stepTitles && stepTitles.length) {
      let newStepTitles = stepTitles

      // Add advisor select step title
      if (!stepTitles[0]) {
        let newStepTitles = stepTitles
        newStepTitles[0] = 'Advisor'
      }

      // Add Tax ID step title
      if (
        !newStepTitles.includes('Tax ID') &&
        template &&
        template.includeTaxID &&
        (template.routing !== 'round-trip' ||
          (template.routing === 'round-trip' && flow === 'final-review') ||
          (template.routing === 'round-trip' &&
            ['create', 'edit'].includes(flow) &&
            !allowUserToBypassValidation))
      ) {
        newStepTitles.push('Tax ID')
      }

      // Add disclosures step title
      if (
        template &&
        template.includeDisclosures &&
        template.showDisclosuresPage &&
        !newStepTitles.includes('Disclosures')
      ) {
        newStepTitles.push('Disclosures')
      }

      setStepTitles(newStepTitles)
    }
  }, [stepTitles, template, flow, allowUserToBypassValidation])

  // Handle completion when all steps have been completed
  useEffect(() => {
    if (steps.length && completedStepTitles.length === steps.length) {
      // Increase step
      console.log('Details complete, advancing...')
      handleContinue()
    }

    // TODO: [BTS-884] Add error/warning to complete all steps before continuing?
  }, [completedStepTitles, handleContinue, steps])

  // Apply input masks
  if (schemaProperties && schemaProperties.length) {
    const maskFormats = {
      phone: '(000) 000-0000',
      money: [
        {
          mask: '$num',
          blocks: {
            num: {
              mask: Number,
              scale: 2,
              radix: '.',
              mapToRadix: [','],
            },
          },
        },
      ],
      percentage: [
        { mask: '' },
        {
          mask: 'num%',
          lazy: false,
          blocks: {
            num: {
              mask: Number,
              scale: 3,
              radix: '.',
              mapToRadix: [','],
            },
          },
        },
      ],
    }

    schemaProperties.forEach((step, idx) => {
      const fieldIDsInStep = Object.keys(step)
      if (fieldIDsInStep && fieldIDsInStep.length) {
        const fieldIDsWithFormats = fieldIDsInStep.filter(
          (fieldID) => typeof step[fieldID].format === 'string'
        )

        if (fieldIDsWithFormats && fieldIDsWithFormats.length) {
          fieldIDsWithFormats.forEach((fieldID) => {
            const fieldFormat = step[fieldID].format
            const maskFormat = maskFormats[fieldFormat]
            const element = document.getElementById(`root_${fieldID}`)

            if (maskFormat && element) {
              const maskOptions = {
                mask: maskFormat,
              }
              IMask(element, maskOptions)
            }
          })
        }
      }
    })
  }

  if (!template || !template.fields || !template.fields.length) {
    return (
      <Wrapper>
        <Message
          compact
          header="We couldn't find any fields for this template."
          content="Please make sure some are set in the Form Builder."
        />
      </Wrapper>
    )
  }

  const generateSchemaFieldData = (
    fields,
    passedAllowUserToBypassValidation = false
  ) => {
    if (!fields || !fields.length) {
      return null
    }

    let stepTitles = []
    let stepSchemaProperties = [{}]
    let schemaRequired = [[]]
    let stepSchemaDependencies = [{}]
    let customUiSchema = {}

    // Start populating schema properties for initial step
    let currentStepForSchema = 0

    let justHadLineBreak = false

    customUiSchema['ui:order'] = fields.map(
      (field) => field.field_name || field.id
    )

    // Add repeaters to array
    const repeaterFields = fields
      .filter((field) => field.key && field.key.toLowerCase() === 'repeater')
      .map((f) => f.field_name || f.id)

    fields.forEach((field, fidx) => {
      const {
        id,
        key,
        field_name,
        element,
        required,
        content,
        displayRule,
      } = field

      // Add a new step if a Line Break is found that is not the first field
      if (element === 'LineBreak') {
        justHadLineBreak = true

        if (fidx !== 0 && fidx !== fields.length - 1) {
          stepTitles.push('')
          stepSchemaProperties.push({})
          schemaRequired.push([])
          stepSchemaDependencies.push({})
          currentStepForSchema++
        }
      } else if (element !== 'LineBreak') {
        // Set title of schema with first Label after a line break
        const titleElements = ['Header', 'Label']
        if (justHadLineBreak && titleElements.includes(element)) {
          stepTitles[currentStepForSchema + 1] = unescape(content)
        }
        justHadLineBreak = false

        // Generate schema for field
        const bitsyFieldElement = availableFields.find(
          (fieldElement) =>
            fieldElement.key === element || fieldElement.key === key
        )

        const fieldId = field_name || id

        // Always require fields needed for Bitsy
        let allowUserToBypassValidation = passedAllowUserToBypassValidation
        if (requiredBitsyFields.includes(fieldId)) {
          allowUserToBypassValidation = false
        }

        const fieldJsonSchemaProperties = generateFieldJsonSchemaProperties(
          field,
          bitsyFieldElement,
          allowUserToBypassValidation
        )

        if (
          !displayRule ||
          !Object.keys(displayRule).length ||
          !displayRule.key ||
          displayRule.value === '' ||
          !displayRule.comparator
        ) {
          stepSchemaProperties[currentStepForSchema][
            fieldId
          ] = fieldJsonSchemaProperties

          if (required && !allowUserToBypassValidation) {
            schemaRequired[currentStepForSchema].push(fieldId)
          }
        }

        // Add custom UI schema
        const fieldCustomUiSchema = generateFieldCustomUiSchema(
          customUiSchema[fieldId],
          bitsyFieldElement,
          field,
          value && value[fieldId] ? value[fieldId] : null,
          handleInputBlur,
          handleInputChange
        )
        if (
          fieldCustomUiSchema &&
          Object.keys(fieldCustomUiSchema) &&
          Object.keys(fieldCustomUiSchema).length
        ) {
          customUiSchema[fieldId] = fieldCustomUiSchema
        }

        // Add dependencies if other fields have display rules depending on this one
        let dependencies
        const dependentFields = fields.filter(
          (f) => f.displayRule && f.displayRule.key === fieldId
        )
        if (dependentFields && dependentFields.length) {
          let fieldOptions =
            fieldJsonSchemaProperties['enum'] ||
            (fieldJsonSchemaProperties.items &&
              fieldJsonSchemaProperties.items['enum']) ||
            dependentFields.flatMap((f) => {
              if (f.displayRule.comparator === 'array-includes') {
                if (element === 'NumberInput') {
                  return f.displayRule.value.split(',').map(Number)
                }

                return f.displayRule.value.split(',')
              }

              return element === 'NumberInput'
                ? parseInt(f.displayRule.value)
                : f.displayRule.value
            })

          if (fieldOptions) {
            fieldOptions = [...new Set(fieldOptions)]
            // Handle dropdown dependencies
            let fieldScenarios =
              fieldOptions && fieldOptions.length
                ? fieldOptions.map((fieldOption) => ({
                    properties: {
                      [fieldId]: {
                        enum: [fieldOption],
                      },
                    },
                  }))
                : []

            const schemaType =
              field.jsonSchemaType || bitsyFieldElement.jsonSchemaType
            // Handle array field scenarios to use `contains` from RJSF
            if (
              'Checkboxes' === field.element &&
              'array' === schemaType &&
              field.options.length > 1
            ) {
              fieldScenarios =
                fieldOptions && fieldOptions.length
                  ? fieldOptions.flatMap((fieldOption) => {
                      // Only add dependencies if there are dependent fields matching the value (to prevent cases overwriting eachother when multiple options are selected)
                      const dependentFieldsMatchingOptionValue = dependentFields.filter(
                        (df) =>
                          df.displayRule &&
                          String(df.displayRule.value) === String(fieldOption)
                      )

                      if (
                        !dependentFieldsMatchingOptionValue ||
                        !dependentFieldsMatchingOptionValue.length
                      ) {
                        return []
                      }

                      return {
                        properties: {
                          [fieldId]: {
                            contains: {
                              enum: [fieldOption],
                            },
                          },
                        },
                      }
                    })
                  : []
            }

            dependencies = {
              oneOf: fieldScenarios,
            }
          } else {
            dependencies = {
              properties: {},
            }
          }

          dependentFields.forEach((dependentField) => {
            const {
              id: dfId,
              field_name: dfFieldName,
              displayRule: dfDisplayRule,
              required: dfRequired,
              element: dfElement,
              key: dfKey,
            } = dependentField

            const dfBitsyFieldElement = availableFields.find(
              (fieldElement) =>
                fieldElement.key === dfElement || fieldElement.key === dfKey
            )

            const dependentFieldId = dfFieldName || dfId

            let matchingScenarios = []
            if (fieldOptions) {
              // The parent field is a multiple choice field (dropdown, radios, etc.)
              let matchingScenarioIdxs = []
              switch (dfDisplayRule.comparator) {
                case '!==':
                  // Comparator is `===`, there will be multiple matching scenarios
                  matchingScenarioIdxs = dependencies['oneOf'].reduce(
                    (a, s, sidx) => {
                      if (
                        s.properties[fieldId].enum &&
                        !s.properties[fieldId].enum
                          .map((e) => String(e))
                          .includes(String(dfDisplayRule.value))
                      ) {
                        a.push(sidx)
                      }

                      return a
                    },
                    []
                  )
                  break
                case 'array-includes':
                  dependencies['oneOf'].forEach((s, ind) => {
                    if (
                      dfDisplayRule.value
                        .split(',')
                        .includes(String(s.properties[fieldId].enum[0]))
                    ) {
                      matchingScenarioIdxs.push(ind)
                    }
                  })
                  break
                default:
                  // Comparator is `===`, there will be one matching scenario
                  const idx = dependencies['oneOf'].findIndex(
                    (s) =>
                      (s.properties[fieldId].contains &&
                        s.properties[fieldId].contains.enum &&
                        s.properties[fieldId].contains.enum
                          .map((e) => String(e))
                          .includes(String(dfDisplayRule.value))) ||
                      (s.properties[fieldId].enum &&
                        s.properties[fieldId].enum
                          .map((e) => String(e))
                          .includes(String(dfDisplayRule.value)))
                  )

                  if (idx >= 0) {
                    matchingScenarioIdxs.push(idx)
                  }
                  break
              }

              matchingScenarioIdxs.forEach((matchingScenarioIdx) => {
                matchingScenarios.push(
                  dependencies['oneOf'][matchingScenarioIdx]
                )
              })
            } else {
              // The parent field is not a multiple choice field, just display the dependent field if the parent has a value
              matchingScenarios.push(dependencies)
            }

            matchingScenarios.forEach((matchingScenario) => {
              matchingScenario.properties[
                dependentFieldId
              ] = generateFieldJsonSchemaProperties(
                dependentField,
                dfBitsyFieldElement,
                allowUserToBypassValidation
              )

              if (dfRequired) {
                let requireDependentField = true
                if (
                  template.routing === 'round-trip' &&
                  ['create', 'edit'].includes(flow) &&
                  ![
                    'second_investor_legal_name_first',
                    'second_investor_legal_name_last',
                  ].includes(dependentFieldId)
                ) {
                  requireDependentField = false
                }

                if (requireDependentField) {
                  if (matchingScenario.required) {
                    matchingScenario.required.push(dependentFieldId)
                  } else {
                    matchingScenario.required = [dependentFieldId]
                  }
                }
              }

              // Add custom UI schema
              const dfCustomUiSchema = generateFieldCustomUiSchema(
                customUiSchema[dependentFieldId],
                dfBitsyFieldElement,
                dependentField,
                value && value[dependentFieldId]
                  ? value[dependentFieldId]
                  : null,
                handleInputBlur,
                handleInputChange
              )
              if (
                dfCustomUiSchema &&
                Object.keys(dfCustomUiSchema) &&
                Object.keys(dfCustomUiSchema).length
              ) {
                customUiSchema[dependentFieldId] = dfCustomUiSchema
              }
            })
          })
        }

        if (dependencies) {
          stepSchemaDependencies[currentStepForSchema][fieldId] = dependencies
        }
      }
    })

    // Set form state with generated schema
    if (stepTitles && stepTitles.length) {
      setStepTitles(stepTitles.map((x, i) => (x ? x : `Step ${i + 1}`)))
    }

    if (
      stepSchemaProperties &&
      Object.keys(stepSchemaProperties) &&
      Object.keys(stepSchemaProperties).length
    ) {
      setSchemaProperties(stepSchemaProperties)
    }

    if (schemaRequired && schemaRequired.length) {
      setSchemaRequired(schemaRequired)
    }

    if (
      stepSchemaDependencies &&
      Object.keys(stepSchemaDependencies) &&
      Object.keys(stepSchemaDependencies).length
    ) {
      setSchemaDependencies(stepSchemaDependencies)
    }

    if (
      customUiSchema &&
      Object.keys(customUiSchema) &&
      Object.keys(customUiSchema).length
    ) {
      setUiSchema(Object.assign({}, uiSchema, customUiSchema))
    }

    if (repeaterFields && repeaterFields.length) {
      handleSetLocalClient({ repeaters: repeaterFields })
    }
  }

  const handleAdvisorSelectContinue = (e) => {
    e.preventDefault()

    setSavingToDBErrors([])

    // Validate advisor select field
    if (!value.advisor || !selectedAdvisorID) {
      console.error('Must select an advisor')
      setSavingToDBErrors(['Please select an advisor.'])
    }

    if (value.advisor) {
      handleSubmit({ formData: value })
    }
  }

  const handleTaxIDContinue = (e) => {
    e.preventDefault()

    setSavingToDBErrors([])

    let allowContinue = false

    // Validate tax ID field
    if (!value.tax_id) {
      console.error('Must enter a tax ID')
      setSavingToDBErrors(['Please enter a Tax ID.'])
    }

    if (!value.tax_id_type) {
      console.error('Must enter a tax ID type')
      setSavingToDBErrors(['Please enter a Tax ID Type.'])
    }

    if (value.tax_id && value.tax_id_type) {
      allowContinue = true
    }

    if (allowContinue) {
      // Update step marker
      if (!completedStepTitles.includes(stepTitles[activeStep - 1])) {
        setCompletedStepTitles((completedSteps) => [
          ...completedSteps,
          stepTitles[activeStep - 1],
        ])
      }

      // Increase step
      const { SW } = wizardState
      if (completedStepTitles.length >= stepTitles.length) {
        handleContinue()
      } else {
        for (let i = 0; i < stepTitles.length; i++) {
          if (
            ![...completedStepTitles, stepTitles[activeStep - 1]].includes(
              stepTitles[i]
            )
          ) {
            SW.goToStep(i + 1)
            break
          }
        }
      }
    }
  }

  const handleDisclosuresAgreementContinue = (e) => {
    e.preventDefault()

    setSavingToDBErrors([])

    // Validate disclosures agreement
    if (!acceptDisclosures) {
      console.error('Must accept disclosures')
      setSavingToDBErrors(['Please accept disclosures.'])
      return
    }

    // Update step marker
    if (
      acceptDisclosures &&
      !completedStepTitles.includes(stepTitles[activeStep - 1])
    ) {
      setCompletedStepTitles((completedSteps) => [
        ...completedSteps,
        stepTitles[activeStep - 1],
      ])
    }

    const { SW } = wizardState
    if (completedStepTitles.length >= stepTitles.length) {
      handleContinue()
    } else {
      for (let i = 0; i < stepTitles.length; i++) {
        if (
          ![...completedStepTitles, stepTitles[activeStep - 1]].includes(
            stepTitles[i]
          )
        ) {
          SW.goToStep(i + 1)
          break
        }
      }
    }
  }

  const handleSubmit = async (data, oldClient) => {
    setSavingToDBErrors([])
    setSavingToDB(true)

    console.log('Submitting...')
    console.log(data)

    const { formData } = data

    // Update local state with updated client (with local-only fields like tax ID)
    console.log('Updating local client state...')

    let localClient = formData

    // Perform firm-specific logic on calculated client fields
    localClient = doFirmCalculations(localClient)
    localClient['legal_name'] = formatLegalName({
      client: localClient,
      investor: 'first',
    })

    if (
      localClient.second_investor_legal_name_first ||
      localClient.second_investor_legal_name_mi ||
      localClient.second_investor_legal_name_last
    ) {
      localClient['second_investor_legal_name'] = formatLegalName({
        client: localClient,
        investor: 'second',
      })
    }

    handleSetLocalClient(localClient)

    if (localClient?.email && oldClient?.email !== localClient?.email) {
      console.log('Updating client email')
      await updateClientEmail({ clientID, email: formData.email })
    }

    // Remove tax ID and repeaters from client object before saving
    const dbClient = prepClientForDB(localClient, saveAsPendingChanges)

    // Save to DB (without integrations upsert)
    console.log('Saving client to DB...')
    const updateClientInDBRes = await updateClientInDB({
      clientID,
      client: dbClient,
      pending: saveAsPendingChanges,
    })

    if (updateClientInDBRes) {
      setSavingToDB(false)
      const { type: resType, error: resError } = updateClientInDBRes

      if ('success' === resType) {
        console.log('Save success!')

        // Update step marker
        if (!completedStepTitles.includes(stepTitles[latestStep.current - 1])) {
          setCompletedStepTitles((completedSteps) => [
            ...completedSteps,
            stepTitles[latestStep.current - 1],
          ])
        }

        // Increase step
        const { SW } = wizardState
        if (completedStepTitles.length >= stepTitles.length) {
          handleContinue()
        } else {
          for (let i = 0; i < stepTitles.length; i++) {
            if (
              ![...completedStepTitles, stepTitles[activeStep - 1]].includes(
                stepTitles[i]
              )
            ) {
              SW.goToStep(i + 1)
              break
            }
          }
        }
      }

      if ('error' === resType) {
        if (resError) {
          console.error(resError)

          let errorMessage
          switch (resError.message) {
            case 'INTERNAL':
              errorMessage =
                'There was an error updating the client. Please try again and contact Bitsy if the problem persists.'
              break
            default:
              errorMessage = resError.message
              break
          }
          setSavingToDBErrors([errorMessage])
        }
      }
    }

    return updateClientInDBRes
  }

  const customFormats = {
    phone: /\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/,
    money: /[+-]?\$[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?/,
  }

  const customDefinitions = processCustomDefinitions({ firm, client: value })

  const setInstance = (SW) => {
    updateWizardState({
      ...wizardState,
      SW,
    })
  }

  const onStepChange = async (stats) => {
    latestStep.current = stats.activeStep
    setActiveStep(stats.activeStep)

    if (document.querySelectorAll('div.modal-body')[1]) {
      document.querySelectorAll('div.modal-body')[1].scrollTop = 100
    } else if (document.querySelectorAll('div.modal-body')[0]) {
      document.querySelectorAll('div.modal-body')[0].scrollTop = 100
    } else if (document.querySelectorAll('.scrolling.content')[0]) {
      document.querySelectorAll('.scrolling.content')[0].scrollTop = 100
    } else {
      window.scroll({
        top: 200,
        behavior: 'smooth',
      })
    }
  }

  const handleBack = () => {
    // Increase step
    const { SW } = wizardState
    if (SW && SW.currentStep !== 1) {
      console.log('Decreasing step...')
      SW.goToStep(SW.currentStep - 1)
    }
  }

  const handleFormChange = (form) => {
    // Calculate legacy investment_profiles based on quiz answers
    legacyCalculateInvestmentProfiles({ form, value, handleInputChange })
  }

  const renderFormFieldsSteps = ({ className = '' }) =>
    steps.map((step, sidx) => {
      switch (step.type) {
        case 'advisor-select':
          return (
            <div key={step.type} className={className}>
              <StyledForm
                schema={{
                  title: step.title || '',
                  type: 'object',
                  properties: schemaProperties[sidx - 1],
                  required: schemaRequired[sidx - 1],
                }}
                uiSchema={uiSchema}
                customFormats={customFormats}
                formData={value}
                formContext={{
                  client: value,
                }}
                FieldTemplate={FieldTemplate}
                onSubmit={(data) => {
                  handleSubmit(data, value)
                }}
                className="hide-rjsf"
                theme={theme}
              >
                {/* TODO:  [BTS-715] Include AdvisorSelect as schema */}
                <div className="grouped equal width fields form-group object root">
                  <label></label>
                  <div className="grouped equal width fields form-group Label">
                    <label></label>
                    <div className="SortableItem rfb-item">
                      <div></div>
                      <label className="static">Advisor </label>
                    </div>
                    <InputBar className="input-bar" />
                  </div>
                  <div className="grouped equal width fields form-group string required root_includeSecondInvestor">
                    <StyledAdvisorSelect
                      selectedAdvisorID={selectedAdvisorID}
                      handleSelect={handleSelectAdvisor}
                      client={value}
                    />
                  </div>
                  <InputBar className="input-bar" />
                </div>
                <SubmitSection
                  handleSubmit={handleAdvisorSelectContinue}
                  loading={savingToDB}
                />
              </StyledForm>
            </div>
          )
        case 'disclosures-agreement':
          return (
            <div key={step.type} className={className}>
              <StyledForm
                schema={{
                  title: step.title || '',
                  type: 'object',
                  properties: schemaProperties[sidx - 1],
                  required: schemaRequired[sidx - 1],
                  definitions: customDefinitions,
                }}
                uiSchema={uiSchema}
                customFormats={customFormats}
                formData={value}
                formContext={{
                  client: value,
                }}
                FieldTemplate={FieldTemplate}
                onSubmit={(data) => {
                  handleSubmit(data, value)
                }}
                className="hide-rjsf"
                theme={theme}
              >
                {/* TODO: [BTS-716]  Include DisclosuresAgreement as schema */}
                <div className="grouped equal width fields form-group object root">
                  <label></label>
                  <div className="grouped equal width fields form-group Label">
                    <label></label>
                    <div className="SortableItem rfb-item">
                      <div></div>
                      <label className="static">Disclosures </label>
                    </div>
                    <InputBar className="input-bar" />
                  </div>
                  <div className="grouped equal width fields form-group string required root_includeSecondInvestor">
                    <DisclosuresAgreement
                      acceptDisclosures={acceptDisclosures}
                      additionalDocuments={additionalDocuments}
                      handleDisclosuresAgreement={handleDisclosuresAgreement}
                    />
                  </div>
                  <InputBar className="input-bar" />
                </div>
                <SubmitSection
                  handleSubmit={handleDisclosuresAgreementContinue}
                  handleBack={handleBack}
                  loading={savingToDB}
                />
              </StyledForm>
            </div>
          )
        case 'tax-id':
          return (
            <div key={step.type} className={className}>
              <StyledForm
                schema={{
                  title: step.title || '',
                  type: 'object',
                  properties: schemaProperties[sidx - 1],
                  required: schemaRequired[sidx - 1],
                }}
                uiSchema={uiSchema}
                customFormats={customFormats}
                formData={value}
                formContext={{
                  client: value,
                }}
                FieldTemplate={FieldTemplate}
                onSubmit={(data) => {
                  handleSubmit(data, value)
                }}
                className="hide-rjsf"
                theme={theme}
              >
                <div className="grouped equal width fields form-group object root">
                  <label></label>
                  <div className="grouped equal width fields form-group Label">
                    <label></label>
                    <div className="SortableItem rfb-item">
                      <div></div>
                      <label className="static">Tax ID </label>
                    </div>
                    <InputBar className="input-bar" />
                  </div>
                  <div className="grouped equal width fields form-group string required root_includeSecondInvestor">
                    <TaxIDField
                      client={value}
                      handleTaxIDInputChange={(taxID) =>
                        handleInputChange('tax_id', taxID)
                      }
                      handleTaxIDTypeInputChange={(taxIDType) =>
                        handleInputChange('tax_id_type', taxIDType)
                      }
                    />
                  </div>
                  <InputBar className="input-bar" />
                </div>
                <SubmitSection
                  handleSubmit={handleTaxIDContinue}
                  handleBack={handleBack}
                  loading={savingToDB}
                />
              </StyledForm>
            </div>
          )
        default:
          const stepProperties = schemaProperties[activeStep - 2]
          const stepDependencies = schemaDependencies[activeStep - 2]

          return (
            <div
              key={sidx}
              className={className}
              data-id={`template-form-page-${stepTitles[sidx]?.trim() || sidx}`}
            >
              <StyledForm
                key={Date.now()}
                schema={{
                  title: step.title || '',
                  type: 'object',
                  properties: schemaProperties[sidx - 1],
                  required: schemaRequired[sidx - 1],
                  dependencies: processSchemaDependencies({
                    properties: schemaProperties[sidx - 1],
                    dependencies: schemaDependencies[sidx - 1],
                    client: value,
                  }),
                  definitions: customDefinitions,
                }}
                uiSchema={uiSchema}
                customFormats={customFormats}
                widgets={customWidgets}
                formData={value}
                formContext={{
                  client: value,
                }}
                FieldTemplate={FieldTemplate}
                ErrorList={ErrorList}
                transformErrors={(errors) =>
                  transformErrors({
                    errors,
                    stepProperties,
                    stepDependencies,
                    allowUserToBypassValidation,
                    value,
                  })
                }
                validate={(client, errors) => {
                  if (allowUserToBypassValidation) return errors

                  return doFirmValidations({
                    client,
                    errors,
                    stepProperties,
                    stepDependencies,
                    templateId: template.id,
                  })
                }}
                onSubmit={(data) => {
                  handleSubmit(data, value)
                }}
                onChange={handleFormChange}
                theme={theme}
              >
                <SubmitSection loading={savingToDB} handleBack={handleBack} />
              </StyledForm>
            </div>
          )
      }
    })

  const renderFormFieldsStepsNav = ({ SW }) => {
    if (!SW) return null

    return (
      <StepNav vertical size="tiny">
        {steps.map((step, sidx) => (
          <Step
            key={sidx}
            completed={completedStepTitles.includes(stepTitles[sidx])}
            active={sidx === SW.currentStep - 1}
            icon={step.icon || 'circle'}
            title={stepTitles[sidx]}
            description={step.description}
            link
            onClick={() => {
              SW.goToStep(sidx + 1)
            }}
          />
        ))}
      </StepNav>
    )
  }

  const { SW } = wizardState

  return (
    <>
      <TemplateFormFieldsWizard
        onStepChange={onStepChange}
        instance={setInstance}
        nav={
          <StepNavContainer>
            {renderFormFieldsStepsNav({ SW })}
          </StepNavContainer>
        }
        isLazyMount
      >
        {renderFormFieldsSteps({ SW })}
      </TemplateFormFieldsWizard>

      {!!savingToDBErrors.length && (
        <BitsyErrorsList errors={savingToDBErrors} />
      )}
    </>
  )
}

// const TemplateFormFields = memo(
//   ({ firmID, template, value, handleInputChange }) => {
//     if (!template || !template.fields || !template.fields.length) {
//       return (
//         <Wrapper>
//           <Message
//             compact
//             header="We couldn't find any fields for this template."
//             content="Please make sure some are set in the Form Builder."
//           />
//         </Wrapper>
//       )
//     }

//     return (
//       <>
//         {template.fields.map((field, idx) => {
//           const {
//             key,
//             type,
//             label,
//             options,
//             textMultipleFields,
//             required,
//             showLabel,
//             instructional,
//             displayRule,
//           } = field

//           let display = true
//           if (displayRule) {
//             display = compare(
//               displayRule.operator,
//               value[displayRule.key],
//               displayRule.value
//             )
//           }

//           if (!display) {
//             return ''
//           }

//           if (type === 'heading') {
//             return <h3 key={key || idx}>{label}</h3>
//           }

//           if (type === 'subheading') {
//             return <h4 key={key || idx}>{label}</h4>
//           }

//           if (type === 'disclaimer') {
//             return <p key={key || idx}>{label}</p>
//           }

//           // Jalinski custom fields:
//           if (type === 'jalinski--account-registrations') {
//             return (
//               <JalinskiAccountRegistrationsField
//                 client={value}
//                 handleInputChange={handleInputChange}
//               />
//             )
//           }

//           let filteredOptions = options

//           return (
//             <Field
//               key={key || idx}
//               type={type}
//               id={key}
//               label={label}
//               value={value[key]}
//               options={filteredOptions}
//               textMultipleFields={textMultipleFields}
//               required={required}
//               showLabel={showLabel}
//               instructional={instructional}
//               handleInputChange={handleInputChange}
//             />
//           )
//         })}
//       </>
//     )
//   }
// )

export default TemplateFormFields

const Wrapper = styled.div`
  text-align: center;
`

const TemplateFormFieldsWizard = styled(StepWizard)`
  &&& {
    .rsw_2f {
      display: none;
    }

    .rsw_3G {
      display: block;
    }
  }
`

const StepNavContainer = styled.div`
  position: absolute;
  height: 100%;
`

const StepNav = styled(Step.Group)`
  &&& {
    position: sticky;
    top: 30px;
    margin-left: -240px;
    max-width: 210px;

    .step {
      padding: 0.5em 1em !important;
    }

    i.circle {
      color: ${colors.warning};
      font-size: 1em;
    }

    .active.step i {
      color: ${colors.primary};
    }
  }
`

const StyledForm = styled(CustomForm)`
  &&& {
    margin-top: 1rem;

    @media screen and (min-width: 46.25em) {
      margin-top: 4rem;
    }

    .ui.message.negative {
      position: fixed;
      right: 20px;
      top: 65px;
      z-index: 999999;
      max-width: 360px;

      li.content {
        display: flex;
        flex-direction: row;
        overflow: visible;

        & > strong {
          margin-right: 5px;
        }
      }
    }

    .fields {
      display: flex;
      flex-flow: column;
      margin-bottom: 1rem;
      position: relative;

      @media screen and (min-width: 46.25em) {
        margin-bottom: 2rem;
      }
    }

    .fields .field {
      margin: 0;
    }

    label,
    input,
    select,
    textarea,
    .search.dropdown,
    .search.dropdown .menu .item,
    .ui.scrolling.dropdown {
      transition: all 0.2s;
      touch-action: manipulation;
      font-size: 1.5rem;
      text-align: center;
    }

    label {
      text-align: left;
    }

    input,
    select,
    textarea,
    .ui.scrolling.dropdown {
      padding: 0.75rem 1rem;
    }

    .ui.selection.dropdown {
      min-height: 0;
    }

    .ui.scrolling.dropdown .menu {
      width: calc(100% + 2px) !important;
    }

    .search.dropdown,
    .search.dropdown .menu .item {
      padding: 0.5rem 1rem;
      height: auto;
    }

    .search.dropdown > input.search {
      height: 100%;
    }

    .search.dropdown i.icon {
      margin-top: 0.3em;
      z-index: 9;
    }

    textarea {
      resize: none;
    }

    label {
      display: block;
      width: 100%;
      position: relative;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.25;
    }

    .label-required {
      font-size: 0;
    }

    .label-required::before {
      top: 0px !important;
      right: -1px !important;
    }

    .has-value .label-required::before {
      display: none;
    }

    .ui.dividing.header {
      display: none;
    }

    .grouped.fields > label {
      font-size: 1.25rem;
      line-height: 1.25;
    }

    .grouped.fields > label + .field > label {
      display: none;
    }

    /* Header */
    h3.static {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 1;
      font-weight: 500;
      letter-spacing: -0.025em;
      margin-top: 0;

      @media (min-width: 46.25em) {
        font-size: 48px;
        font-size: 3rem;
        line-height: 1;
      }
    }

    /* Label */
    label.static {
      font-weight: 700;
      margin-bottom: 2rem;
      text-align: center;
      letter-spacing: 4px;
    }

    /* Paragraph */
    p.static {
      font-size: 17px;
      font-size: 1.25rem;
      line-height: 1.25;
      letter-spacing: -0.025em;
      color: ${colors.grayAlt};

      @media (min-width: 46.25em) {
        font-size: 17px;
        font-size: 1.25rem;
        line-height: 1.5;
      }
    }

    /* Repeater */
    .field-array .row {
      display: block;
      margin: 0;
    }

    .field-array .row .array-item {
      margin: 20px;
      box-shadow: 0px 16px 24px #ededed;
      padding: 20px;
    }

    .field-array + .input-bar {
      display: none;
    }

    @media screen and (min-width: 46.25em) {
      .field-array .field:last-child {
        margin-bottom: 0;
      }
    }

    &.hide-rjsf > .grouped.fields:first-child {
      display: none;
    }

    & input[type='date'] {
      height: auto;
    }

    & input[type='email'] {
      height: auto;

      &:not(:focus) {
        border-radius: inherit;
      }
    }

    & textarea {
      height: auto;
    }

    .ui.checkbox .box:before,
    .ui.checkbox label:before {
      width: 20px;
      height: 20px;
    }

    .ui.radio.checkbox .box:after,
    .ui.radio.checkbox label:after {
      width: 20px;
      height: 20px;
    }

    .ui.checkbox input:checked ~ .box:after,
    .ui.checkbox input:checked ~ label:after {
      font-size: 16px;
    }

    .ui.checkbox:not(.radio) input:checked ~ .box:after,
    .ui.checkbox:not(.radio) input:checked ~ label:after {
      left: 2px;
    }

    /* High Contrast Theme */
    ${({ theme }) =>
      theme === 'high-contrast' &&
      `
        input,
        select,
        textarea,
        .ui.dropdown {
          border: 1px solid #000;
          color: #000;
        }

        .field > label,
        label.static,
        p.static {
          color: #000;
        }

        .ui.checkbox .box:before,
        .ui.checkbox label:before {
          border-color: #000;
        }

        .ui.radio.checkbox .box:after,
        .ui.radio.checkbox label:after {
          background-color: #000;
        }

        .ui.checkbox input:checked ~ .box:after,
        .ui.checkbox input:checked ~ label:after {
          color: #000;
        }
      `}
  }
`

const StyledAdvisorSelect = styled(AdvisorSelect)`
  margin-bottom: 2rem !important;
`
