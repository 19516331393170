import { firebaseFunctionsCustomDomain } from '../../../config/firebaseConfig'

const apiClientsUpdate = firebaseFunctionsCustomDomain.httpsCallable(
  'apiClientsUpdate'
)

const updateClient = async ({ clientID, client, pending = false }) => {
  try {
    const result = await apiClientsUpdate({
      clientID,
      client,
      pending,
    })
    console.log(result)
    return {
      type: 'success',
      data: result.data,
    }
  } catch (err) {
    console.error(err)
    return {
      type: 'error',
      error: err,
    }
  }
}

export default updateClient
