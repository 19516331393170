import React, { Component } from 'react'
import {
  getAdvisorClients,
  stopListeningToAdvisorClients,
} from '../../../../actions/dbActions'
import PeopleTable from '../../partials/LegacyPeopleTable'

class AdvisorClientsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clients: null,
    }
  }

  componentDidMount() {
    const { uid } = this.props

    if (uid && uid.length) {
      getAdvisorClients(uid, this)
    }
  }

  componentWillUnmount() {
    const { uid } = this.props

    if (uid && uid.length) {
      stopListeningToAdvisorClients()
    }
  }

  render() {
    const { uid } = this.props
    const { clients } = this.state

    if (uid) {
      return (
        <div className="people-list clients-list">
          <PeopleTable
            type="clients"
            people={clients}
            pageSize={5}
            className="table dt-responsive nowrap clients-list w-100"
          />
        </div>
      )
    } else {
      return ''
    }
  }
}

export default AdvisorClientsList
