import React from 'react'
import {
  actions,
  makePropGetter,
  ensurePluginOrder,
  useGetLatest,
  useMountedLayoutEffect,
} from 'react-table'

const pluginName = 'useNoRowSelect'

// Actions
actions.resetSelectedRows = 'resetSelectedRows'
actions.toggleRowSelected = 'toggleRowSelected'

export const useNoRowSelect = (hooks) => {
  hooks.getToggleRowSelectedProps = [defaultGetToggleRowSelectedProps]
  hooks.stateReducers.push(reducer)
  hooks.useInstance.push(useInstance)
  hooks.prepareRow.push(prepareRow)
}

useNoRowSelect.pluginName = pluginName

const defaultGetToggleRowSelectedProps = (props, { instance, row }) => {
  const { manualRowSelectedKey = 'isSelected' } = instance
  let checked = false

  if (row.original && row.original[manualRowSelectedKey]) {
    checked = true
  } else {
    checked = row.isSelected
  }

  return [
    props,
    {
      onChange: (e) => {
        row.toggleRowSelected(e.target.checked)
      },
      style: {
        cursor: 'pointer',
      },
      checked,
      title: 'Toggle Row Selected',
    },
  ]
}

const reducer = (state, action, previousState, instance) => {
  if (action.type === actions.init) {
    return {
      selectedRowId: {},
      ...state,
    }
  }

  if (action.type === actions.resetSelectedRows) {
    return {
      ...state,
      selectedRowId: instance.initialState.selectedRowIds || {},
    }
  }

  if (action.type === actions.toggleRowSelected) {
    const { id, value: setSelected } = action
    const { rowsById } = instance

    // Join the ids of deep rows
    // to make a key, then manage all of the keys
    // in a flat object
    const row = rowsById[id]
    const isSelected = row.isSelected
    const shouldExist =
      typeof setSelected !== 'undefined' ? setSelected : !isSelected

    if (isSelected === shouldExist) {
      return state
    }

    return {
      ...state,
      selectedRowId: id,
    }
  }
}

const useInstance = (instance) => {
  const {
    data,
    rows,
    plugins,
    autoResetSelectedRows = true,
    state: { selectedRowId },
    selectSubRows = true,
    dispatch,
  } = instance

  ensurePluginOrder(
    plugins,
    ['useFilters', 'useGroupBy', 'useSortBy'],
    'useNoRowSelect'
  )

  const selectedFlatRows = React.useMemo(() => {
    const selectedFlatRows = []

    rows.forEach((row) => {
      const isSelected = selectSubRows
        ? getRowIsSelected(row, selectedRowId)
        : !!selectedRowId
      row.isSelected = !!isSelected
      row.isSomeSelected = isSelected === null

      if (isSelected) {
        selectedFlatRows.push(row)
      }
    })

    return selectedFlatRows
  }, [rows, selectSubRows, selectedRowId])

  const getAutoResetSelectedRows = useGetLatest(autoResetSelectedRows)

  useMountedLayoutEffect(() => {
    if (getAutoResetSelectedRows()) {
      dispatch({ type: actions.resetSelectedRows })
    }
  }, [dispatch, data])

  const toggleRowSelected = React.useCallback(
    (id, value) => dispatch({ type: actions.toggleRowSelected, id, value }),
    [dispatch]
  )

  Object.assign(instance, {
    selectedFlatRows,
    toggleRowSelected,
  })
}

const prepareRow = (row, { instance }) => {
  row.toggleRowSelected = (set) => instance.toggleRowSelected(row.id, set)

  row.getToggleRowSelectedProps = makePropGetter(
    instance.getHooks().getToggleRowSelectedProps,
    { instance: instance, row }
  )
}

const getRowIsSelected = (row, selectedRowId) => {
  return selectedRowId === row.id
}

export const generateSelectedRowIds = (selectedRows) => {
  let selectedRowIds = {}

  if (selectedRows && selectedRows.length) {
    selectedRows.forEach((selectedRow) => {
      selectedRowIds[selectedRow.id] = true
    })
  }

  return selectedRowIds
}
