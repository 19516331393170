import React, { Component } from 'react'
import Modal from '../../partials/LegacyModal'
import ClientForm from '../../forms/LegacyClientForm'
import { suitabilityInitialState } from 'config/suitabilityConfig'
// import { saveDocument } from 'actions/storage/docActions'
import { emailDocumentPending } from 'actions/email/docActions'
import {
  clientAddOnboardedClientToDB,
  clientUpdateClient,
} from 'actions/clientDbActions'
import {
  addOnboardedClientToDB,
  updateClient,
  getFirmDisclosures,
  stopListeningToFirmDisclosures,
  getAdvisorDisclosures,
  stopListeningToAdvisorDisclosures,
} from 'actions/dbActions'
import { addPendingClientFieldsToDB } from 'actions/db/clientActions'
import { getItemDetails } from 'actions/db/dbActions'
import { updateUserTaskStatus } from 'actions/db/userTaskActions'
import BitsySuitabilityForm from './templates/BitsySuitabilityForm'
import JalinskiSuitabilityForm from './templates/JalinskiSuitabilityForm'
import { firmIDs } from 'config/firmConfig'
import './SuitabilityForm.scss'

class SuitabilityForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firm: this.props.firm,
      suitability: suitabilityInitialState,
      errors: [],
      addSuccess: false,
      docGenerating: false,
      docErrors: false,
      showAdvisorDocumentSaveOptions: false,
      firmDisclosures: this.props.firmDisclosures,
      advisorDisclosures: this.props.advisorDisclosures,
    }

    this.nextLinkRef = React.createRef()

    this.attemptContinue = this.attemptContinue.bind(this)
    // this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSend = this.handleSend.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    // this.handleKeyPress = this.handleKeyPress.bind(this)
    // this.handleSignatureConfirm = this.handleSignatureConfirm.bind(this)
    // this.handleSignatureRetry = this.handleSignatureRetry.bind(this)
    this.validate = this.validate.bind(this)
  }

  componentDidMount() {
    const { doc, firmID, firm, type, client } = this.props
    const { firmDisclosures, advisorDisclosures } = this.state

    // Get firm details if not given in props
    if (!firm) {
      getItemDetails('firm', firmID, this)
    }

    // Add props doc status if given in props
    if (doc) {
      this.setState({
        suitability: Object.assign({}, suitabilityInitialState, doc),
      })
    }

    // Get firm disclosures if not given in props and type is advisor-sign
    if (type === 'advisor-sign') {
      if (!firmDisclosures) {
        getFirmDisclosures(firmID, this)
      }

      if (!advisorDisclosures && client && client.advisor) {
        const advisorID = client.advisor
        getAdvisorDisclosures(advisorID, this)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this.props

    // Set addSuccess on parent
    if (state && this.state.addSuccess && !prevState.addSuccess) {
      state.setState({
        addSuccess: true,
      })
    }
  }

  componentWillUnmount() {
    const { type, firmDisclosures, advisorDisclosures } = this.props

    // Stop listening to disclosures on advisor-sign
    if (type === 'advisor-sign') {
      if (!firmDisclosures) {
        stopListeningToFirmDisclosures()
      }

      if (!advisorDisclosures) {
        stopListeningToAdvisorDisclosures()
      }
    }
  }

  attemptContinue(event) {
    event.preventDefault()

    const { type } = this.props

    if (type !== 'send') {
      this.validate()
    } else {
      // Skip validation
      this.handleSend()
    }
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    // Suitability state changes
    this.setState({
      suitability: {
        ...this.state.suitability,
        signatures: {
          ...this.state.suitability.signatures,
          [name]: value,
        },
      },
    })
  }

  handleSend() {
    const {
      advisor,
      client,
      firmDisclosures,
      advisorDisclosures,
      uid,
      source,
      location,
      firmID,
    } = this.props
    const { firm } = this.state

    if (this.state.errors.length === 0) {
      // Set up firm name
      var firmName = firm.name
      if (firmID && firmID === 'dkrmXUK1wLUn8zGwIxy0pmGJIPi1') {
        firmName = 'Hobart Wealth'
      }

      // Email pending document to client
      emailDocumentPending(
        'suitability-form',
        client.firm,
        client.legal_name,
        client.email,
        advisor.name,
        advisor.email,
        firmName,
        firm.executed_contract_email,
        firmDisclosures,
        advisorDisclosures
      )

      var clientObject = client

      clientObject['hasPendingSuitabilityForm'] = true

      if (source === 'register') {
        // Add client to database
        if (
          location !== 'dashboard' &&
          location !== 'client-profile' &&
          location !== 'advisor-dashboard'
        ) {
          clientAddOnboardedClientToDB(clientObject, this, uid)
        } else {
          addOnboardedClientToDB(clientObject, this, uid)
        }
      } else {
        // Update client in database
        if (
          location !== 'dashboard' &&
          location !== 'client-profile' &&
          location !== 'advisor-dashboard'
        ) {
          clientUpdateClient(clientObject, this, uid)
        } else {
          updateClient(clientObject, this, uid)
        }
      }
    }
  }

  handleSubmit() {
    const {
      client,
      uid,
      source,
      location,
      type,
      taskDetails,
      firmID,
    } = this.props
    const { suitability, errors } = this.state

    if (!errors.length) {
      // var database = 'main'

      if (type === 'advisor-sign') {
        this.setState({
          showAdvisorDocumentSaveOptions: true,
        })

        // // Set up firm name
        // var firmName = firm.name
        // if (firmID && firmID === 'dkrmXUK1wLUn8zGwIxy0pmGJIPi1') {
        //   firmName = "Hobart Wealth"
        // }

        // const { addAdditionalDocuments } = this.props

        // // Process PDF with DocuSign
        // addAdditionalDocuments([firmDisclosures, advisorDisclosures])

        // // Save PDF
        // saveDocument('suitability-form', this, client.legal_name, client.email, advisor.name, advisor.email, firmName, firm.executed_contract_email, firm.executed_contract_email_body, firmDisclosures, advisorDisclosures, client.firm, uid, database, client.suitability_forms, taskDetails)
      }

      if (type === 'client-sign') {
        // Add pending client fields to database
        addPendingClientFieldsToDB(client, suitability, this, uid, taskDetails)
      } else if (source === 'register') {
        // Add client to database
        if (
          location !== 'dashboard' &&
          location !== 'client-profile' &&
          location !== 'advisor-dashboard'
        ) {
          clientAddOnboardedClientToDB(client, this, uid)
        } else {
          addOnboardedClientToDB(client, this, uid)
        }
      } else {
        let removePendingChanges = false
        let removePendingSuitabilityForm = false
        if (type === 'advisor-sign') {
          removePendingChanges = true
          if (firmID === firmIDs['jalinski']) {
            removePendingSuitabilityForm = true
          }
        }

        // Update client in database
        if (
          location !== 'dashboard' &&
          location !== 'client-profile' &&
          location !== 'advisor-dashboard'
        ) {
          clientUpdateClient(client, this, uid)
        } else {
          updateClient(
            client,
            this,
            uid,
            removePendingChanges,
            removePendingSuitabilityForm
          )
        }

        if (
          type === 'advisor-sign' &&
          taskDetails &&
          taskDetails.uid &&
          taskDetails.id
        ) {
          updateUserTaskStatus(
            'advisor',
            taskDetails.uid,
            taskDetails.id,
            'complete'
          )
        }
      }
    }
  }

  // handleKeyPress(event) {
  //   if (event.key === "Enter") {
  //     this.nextLinkRef.current.click()
  //   }
  // }

  // handleSignatureConfirm(refName, image) {
  //   this.setState({
  //     suitability: {
  //       ...this.state.suitability,
  //       signatures: {
  //         ...this.state.suitability.signatures,
  //         [refName]: image
  //       }
  //     }
  //   })
  // }

  // handleSignatureRetry(refName) {
  //   this.setState({
  //     suitability: {
  //       ...this.state.suitability,
  //       signatures: {
  //         ...this.state.suitability.signatures,
  //         [refName]: null
  //       }
  //     }
  //   })
  // }

  validate() {
    // const { client } = this.props
    // const { suitability } = this.state

    var errors = []

    // // Signature / Text Signature
    // if (!suitability.signatures.client_sig && !suitability.signatures.client_text) {
    //   errors.push("Please sign or print your name. If signing, click on the checkmark to confirm.")
    // }

    // // Second Investor Signature / Text Signature
    // if (client.second_investor_legal_name && client.second_investor_legal_name.length && !suitability.signatures.second_sig && !suitability.signatures.second_text) {
    //   errors.push("Please sign or print the second investor's name. If signing, click on the checkmark to confirm.")
    // }

    // Set state and increase step
    this.setState(
      {
        errors: errors,
      },
      () => {
        this.handleSubmit()
      }
    )
  }

  render() {
    const { type, advisor, client, location, firmID, uid } = this.props
    const {
      firm,
      errors,
      docGenerating,
      docErrors,
      addSuccess,
      firmDisclosures,
      advisorDisclosures,
      showAdvisorDocumentSaveOptions,
    } = this.state

    if (!firm) {
      return ''
    }

    // var client_name = ''
    // if (client) {
    //   client_name = client.legal_name

    //   if (client.second_investor_legal_name && client.second_investor_legal_name.length > 0) {
    //     client_name += ' & ' + client.second_investor_legal_name
    //   }
    // }

    // var clientForm = <BitsySuitabilityForm type={type} firm={firm} advisor={advisor} client={client} client_name={client_name} doc={suitability} errors={errors} handleSubmit={this.attemptContinue} handleInputChange={this.handleInputChange} handleSignatureConfirm={this.handleSignatureConfirm} handleSignatureRetry={this.handleSignatureRetry} docRef={(r) => this.suitability = r} nextLinkRef={this.nextLinkRef} handleKeyPress={this.handleKeyPress} />
    var clientForm = (
      <BitsySuitabilityForm
        firm={firm}
        advisor={advisor}
        client={client}
        errors={errors}
        handleSubmit={this.attemptContinue}
      />
    )
    if (firmID === firmIDs['jalinski']) {
      clientForm = (
        <JalinskiSuitabilityForm
          firm={firm}
          advisor={advisor}
          client={client}
          errors={errors}
          handleSubmit={this.attemptContinue}
        />
      )
    }

    return (
      <div className="document-overlay">
        {addSuccess ? (
          <div className="document-submit-success">
            <h2>
              {type === 'client-sign'
                ? 'Your changes have been submitted!'
                : 'Welcome to ' + firm.name + '!'}
            </h2>
            <p>
              {type === 'client-sign'
                ? 'Your advisor will review your changes, and we will email you once everything is finalized!'
                : 'Signup was successful. Please check your email!'}
            </p>
          </div>
        ) : (
          clientForm
        )}

        {docGenerating && (
          <div className="document-saving">
            <div className="message">
              Saving... Please do not leave this window!
            </div>
          </div>
        )}

        {docErrors && (
          <div className="document-submit-error">
            <div className="message">
              There was an error generating your Suitability Form. Please try
              again.
              <button onClick={this.setState({ docErrors: false })}>OK</button>
            </div>
          </div>
        )}

        <Modal
          isOpen={showAdvisorDocumentSaveOptions}
          handleClose={() =>
            this.setState({ showAdvisorDocumentSaveOptions: false })
          }
        >
          <ClientForm
            type="suitability-save"
            location={location}
            firm={firm}
            firmID={firmID}
            advisor={advisor}
            advisorID={client.advisor}
            client={client}
            clientID={uid}
            passedAdditionalDocuments={[firmDisclosures, advisorDisclosures]}
          />
        </Modal>
      </div>
    )
  }
}

export default SuitabilityForm
