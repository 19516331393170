import { firebaseFunctions } from '../../../config/firebaseConfig'

const integrationsStripeCustomerPortal = firebaseFunctions.httpsCallable(
  'integrationsStripeCustomerPortal'
)

export default async function redirectToCustomerPortal({
  customerId,
  returnUrl,
}) {
  try {
    const result = await integrationsStripeCustomerPortal({
      customerId,
      returnUrl,
    })

    window.location.href = result.data.url
  } catch (err) {
    console.error(err)
    throw err
  }
}
